import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router';

import {
  Button, Collapse, Grid, ListItem
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import useStyles from './style';

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  toggleMenu,
  ...rest
}) {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(openProp);
  const { ready, t } = useTranslation();

  useEffect(() => {
    setOpen(matchPath(location.pathname, { path: href, exact: false }));
  }, [location, href]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const navigateTo = (e) => {
    if (href.indexOf('/dimensions') >= 0) {
      toggleMenu(e);
      return;
    }

    window.location.href = href;
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="18"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {open ? (
            <ExpandLessIcon
              size="small"
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              size="small"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button
          className={clsx(classes.buttonLeaf, `depth-${depth}`, open ? classes.active : '')}
          style={style}
          onClick={(e) => navigateTo(e)}
        >
          {Icon && (
            <Icon
              className={classes.icon}
            />
          )}
          <span className={classes.title}>
            {ready && t(title)}
          </span>
          {Info && <Info className={classes.info} />}
        </Button>
      </Grid>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func,
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
