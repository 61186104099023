import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from 'src/Dimensions/store/actions/vatcode';
import { axiosHeaders } from 'src/Shared/utils/helpers';

export function* fetchVatCodesSaga(action) {
  yield put(actions.fetchVatCodesStart());
  const search = action.search || '';
  try {
    const response = yield axios.get(
      `${window.config.backend.procys_service}v1/dimensions/vatcodes/companies/${action.companyID}?search=${search}`,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    yield put(actions.fetchVatCodesSuccess(response.data.data, response.data.total));
  } catch (error) {
    yield put(actions.fetchVatCodesFail(error.response.data.message));
  }
}

export function* fetchVatCodeListSaga(action) {
  const { loadFullScreen } = action;
  if (loadFullScreen) {
    yield put(actions.fetchVatCodeListStart());
  }
  const page = action.page || 0;
  const limit = action.limit || 10;
  const search = action.search || '';
  try {
    const response = yield axios.get(
      `${window.config.backend.procys_service}v1/dimensions/vatcodes/companies/${action.companyID}?page=${page + 1}&limit=${limit}&search=${search}`,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    yield put(actions.fetchVatCodeListSuccess(response.data.data, response.data.total));
  } catch (error) {
    yield put(actions.fetchVatCodeListFail(error.response.data.message));
  }
}
