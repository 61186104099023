/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSelector } from 'react-redux';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import {
  Close as CloseIcon
} from '@material-ui/icons';

import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import {
  axiosHeaders, isActionPermitted, permissions
} from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from './style';
import CostCenterTwinfield from './CostCenterTwinfield';
import CostCenterStandard from './CostCenterStandard';

function CostCenterModal(props) {
  const {
    costCenterModalOpen,
    selectedCostCenter,
    handleClose,
    getAllCostCenters,
    onSuccess,
    className,
  } = props;
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [costCenter, setCostCenter] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCostCenterByCode = async (code) => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.costCenterByCode}/${encodeURIComponent(code)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setCostCenter(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user.permissions)) {
      if (selectedCostCenter) {
        getCostCenterByCode(selectedCostCenter);
      } else {
        setCostCenter(null);
      }
    }
  }, [selectedCostCenter]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasError = (fields, errors) => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        // Returning true until Twinfield integration
        return false;
      }
    }

    return false;
  };

  return (
    <Modal
      className={classes.modal}
      open={costCenterModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={costCenterModalOpen}>
        <div className={classes.halfPaper}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {!loading
                && (costCenter?.name
                  ? `${t('COST_CENTER')} - ${costCenter ? costCenter.name : ''} (${costCenter?.code})`
                  : t('COST_CENTER_ADD_NEW_COST_CENTER'))}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          {loading ? <LoadingScreen /> : (
            user.interfacePreference === 'twinfield_interface'
              ? (
                <CostCenterTwinfield
                  costCenter={costCenter}
                  getAllCostCenters={getAllCostCenters}
                  handleClose={handleClose}
                  hasError={hasError}
                  className={className}
                  onSuccess={onSuccess}
                />
              ) : (
                <CostCenterStandard
                  costCenter={costCenter}
                  getAllCostCenters={getAllCostCenters}
                  handleClose={handleClose}
                  hasError={hasError}
                  className={className}
                  onSuccess={onSuccess}
                />
              )
          )}
        </div>
      </Fade>
    </Modal>
  );
}

CostCenterModal.propTypes = {
  costCenterModalOpen: PropTypes.bool,
  selectedCostCenter: PropTypes.string,
  handleClose: PropTypes.func,
  getAllCostCenters: PropTypes.func,
  onSuccess: PropTypes.func,
  className: PropTypes.string,
};

export default withRouter(CostCenterModal);
