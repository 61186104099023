import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router';
import Axios from 'axios';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
  Box, Button, Grid, Typography
} from '@material-ui/core';
import FileSaver from 'file-saver';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';

import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function DocumentDisplay() {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fileResponse, setFileResponse] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);

  const match = useRouteMatch();

  const fileHandler = (fileObj) => {
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        localStorage.removeItem('PROCYS_PERSIST_URL');
        setError(true);
      } else {
        setError(false);
        const columns = [{ name: '', key: 0 }];
        resp.cols.forEach((item) => {
          columns.push({ name: item.name, key: item.key + 1 });
        });
        setCols(columns);
        setRows(resp.rows);
      }
    });
  };

  const csvToRowsNCols = (response) => {
    Papa.parse(response.data, {
      complete(results) {
        if (results.errors.length > 0) {
          localStorage.removeItem('PROCYS_PERSIST_URL');
          setError(true);
        } else {
          setError(false);
          const columns = [{ name: '', key: 0 }];
          results.data[0].forEach((item, index) => {
            columns.push({ name: item, key: index + 1 });
          });
          setCols(columns);
          setRows(results.data.slice(1));
        }
      }
    });
  };

  const getDoc = async (fileName) => {
    setError(false);
    const url = `${API.getAttachment}${fileName}`;
    const type = fileName.slice(fileName.indexOf('.') + 1);
    setFileType(type);
    try {
      const response = await Axios.get(
        `${url}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      let blob = null;
      switch (type) {
        case 'pdf': {
          blob = new Blob([response.data], { type: 'application/pdf' });
          setFileResponse(window.URL.createObjectURL(blob));
          break;
        }
        case 'png': case 'jpeg': case 'tiff': {
          blob = new Blob([response.data], { type: `image/${type}` });
          setFileResponse(window.URL.createObjectURL(blob));
          break;
        }
        case 'xlsx': case 'xls': {
          blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
          setFileResponse(window.URL.createObjectURL(blob));
          fileHandler(new Blob([response.data]));
          break;
        }
        case 'csv': {
          blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
          setFileResponse(window.URL.createObjectURL(blob));
          csvToRowsNCols(response);
          break;
        }
        default: {
          const blobFile = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
          FileSaver.saveAs(blobFile, fileName);
          window.open('about:blank', '_self');
          window.close();
          break;
        }
      }
      setLoading(false);
      localStorage.removeItem('PROCYS_PERSIST_URL');
    } catch (err) {
      setLoading(false);
      setError(true);
      if (document?.referrer?.indexOf('login/') >= 0) {
        localStorage.removeItem('PROCYS_PERSIST_URL');
      } else {
        localStorage.setItem('PROCYS_PERSIST_URL', window.location.href);
      }
      setTimeout(() => { window.location.href = '/dashboard'; }, 2000);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    setError(false);
    if (match?.params?.name) {
      getDoc(match.params.name);
    } else {
      setLoading(false);
      setError(true);
      localStorage.removeItem('PROCYS_PERSIST_URL');
      setTimeout(() => window.location.href('/dashboard'), 2000);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div className={classes.errorContainer}>
        <Box width={800}>
          <Typography align="center" variant="subtitle1" className={classes.errorText}>
            {ready && t('ATTACHMENT_ERROR')}
          </Typography>
        </Box>
      </div>
    );
  }

  const topBar = (
    <Grid className={classes.topBar}>
      <Typography className={classes.fileName}>{match?.params?.name}</Typography>
      <Button
        className={classes.buttonCls}
        onClick={() => FileSaver.saveAs(fileResponse, match?.params?.name)}
      >
        <DownloadIcon />
      </Button>
    </Grid>
  );

  return (
    <>
      {fileType === 'pdf' && (<iframe src={fileResponse} title="pdf" className={classes.pdfFrame} />)}
      {(fileType === 'png' || fileType === 'jpeg' || fileType === 'tiff')
        && (
          <Grid className={classes.root}>
            {topBar}
            <Grid className={classes.docContainer}>
              <img src={fileResponse} alt="" className={classes.imageDisplay} />
            </Grid>
          </Grid>
        )}
      {(fileType === 'xlsx' || fileType === 'xls' || fileType === 'csv')
        && (
          <Grid className={classes.root}>
            {topBar}
            <Grid className={classes.docContainer}>
              <OutTable
                data={rows}
                columns={cols}
                tableClassName={classes.excelTable2007}
                tableHeaderRowClass={classes.heading}
              />
            </Grid>
          </Grid>
        )}
    </>
  );
}

export default DocumentDisplay;
