const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  },
  container: {
    padding: 0
  }
});

export default useStyles;
