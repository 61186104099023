const useStyles = () => ({
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
  },
  topContainer: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40
  },
  logViewContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '6px 0px 6px 8px',
    border: 'solid rgba(0,0,0,0.075) 0px',
    borderBottomWidth: 1
  },
  label: {
    fontSize: 14,
    color: '#334D6E',
    minWidth: 140
  },
  value: {
    fontSize: 14,
    color: '#334D6E'
  },
  titleContainer: {
    backgroundColor: '#ffffff',
    padding: '24px 40px',
    boxShadow: '0px 1px 0px rgba(51, 77, 110, 0.1)',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08))'
  },
  backBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    color: '#3E8AFF',
    cursor: 'pointer',
    paddingLeft: 0,
    marginBottom: 16,
    textTransform: 'none'
  },
  icon: {
    marginRight: 12
  },
  assistantID: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: 'rgba(51, 77, 110, 0.5)',
  },
  subtitle: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: 'rgba(51, 77, 110, 0.5)',
    paddingBottom: 6
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '36px',
    color: '#334D6E',
  },
  userChip: {
    height: 19,
    marginLeft: 6
  },
  chipAvatar: {
    height: '19px!important',
    width: '19px!important',
    marginLeft: '0px!important'
  },
  chipLabel: {
    fontSize: 11,
    fontWeight: 500,
    color: '#334D6E',
    lineHeight: '16px',
    paddingBottom: 1
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  xmlView: {
    display: 'block',
    width: '100%',
    height: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    margin: '0 auto',
    lineHeight: '1.5',
    backgroundColor: 'white',
  },
  arrowBtn: {
    marginRight: 5
  }
});

export default useStyles;
