import React, {
  useState, useEffect, createRef, useContext
} from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box, Button, Card, Grid, Hidden, SvgIcon, Table, TableBody,
  TableCell, TablePagination, TableRow, Tooltip, Typography, useMediaQuery
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  AddCircle as PlusCircleIcon
} from '@material-ui/icons';

import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import authService from 'src/Shared/utils/services/authService';
import {
  axiosHeaders, isActionPermitted, permissions, validateToken
} from 'src/Shared/utils/helpers';
import images from 'src/Shared/assets/images';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function Billing(props) {
  const {
    className, header, pageLimit, parentPadding, subscription
  } = props;
  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [defaultCard, setDefaultCard] = useState();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(pageLimit || 10);

  const maxWidth = useMediaQuery('(max-width: 470px)');
  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const intervalID = createRef();

  const getAllCards = async (id) => {
    setLoading(true);
    try {
      const response = await Axios.get(
        API.userCards,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        let defCard = null;
        // const otherCards = [];
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].id === id) {
            defCard = response.data.data[i];
          // } else {
          //   otherCards.push(response.data.data[i]);
          }
        }

        // otherCards.reverse();

        if (defCard) {
          setCards([defCard]);
        // } else {
        //   setCards(otherCards);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const paymentProfileMethod = async () => {
    try {
      const response = await Axios.get(
        API.subscriptionDetails,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getAllCards(response.data.data[0].creditCard.id);
        setDefaultCard(response.data.data[0].creditCard.id);
      } else {
        getAllCards(null);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    paymentProfileMethod();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const onStartInterval = () => {
    let counter = 0;
    intervalID.current = setInterval(async () => {
      counter++;
      paymentProfileMethod();

      if (counter === 120) {
        clearInterval(intervalID.current);
      }
    }, 10000);
  };

  const handleAddCard = () => {
    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    // history.push('/account/cards');

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const width = screenWidth > 700 ? 700 : screenWidth - 20;
    const height = screenHeight > 700 ? 700 : screenHeight - 20;

    const excessH = screenHeight - height - 100;
    const top = excessH > 0 ? excessH / 2 : 10;

    const excessW = screenWidth - width;
    const left = excessW > 0 ? excessW / 2 : 10;

    window.open(subscription.selfServiceUrl, 'ProcysBillingInformation', `height=${height},width=${width},top=${top},left=${left}`);
    onStartInterval();
  };

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <>
      <Grid
        container
        className={clsx(classes.header, parentPadding ? classes.componentPadding : '')}
      >
        <Grid item xs={12} sm={4}>
          <Typography className={classes.headerTitle}>
            {ready && t(header || 'ACCOUNT_SETTINGS_BILLING_INVOICES_MY_CARDS')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.headerActions}>
          {
            cards && cards.length > 0 ? (
              <Tooltip title={isActionPermitted(permissions.subscriptionsEditCard, user.permissions)
                ? ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TOOLTIP')
                : ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TOOLTIP_PERMISSION')}
              >
                <span>
                  <Button
                    color="secondary"
                    type="submit"
                    variant="contained"
                    className={classes.addCardBtn}
                    onClick={handleAddCard}
                    disabled={!isActionPermitted(permissions.subscriptionsAddCard, user.permissions)}
                  >
                    {ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TITLE')}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={isActionPermitted(permissions.subscriptionsAddCard, user.permissions)
                ? ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD_TOOLTIP')
                : ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD_TOOLTIP_PERMISSION')}
              >
                <span>
                  <Button
                    color="secondary"
                    type="submit"
                    variant="contained"
                    className={classes.addCardBtn}
                    onClick={handleAddCard}
                    disabled={!isActionPermitted(permissions.subscriptionsAddCard, user.permissions)}
                  >
                    <SvgIcon
                      fontSize="small"
                      className={classes.actionIcon}
                    >
                      <PlusCircleIcon />
                    </SvgIcon>
                    {ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD')}
                  </Button>
                </span>
              </Tooltip>
            )
          }
        </Grid>
      </Grid>
      {
        cards && cards.length > 0
          ? (
            <Card className={clsx(classes.root, className)}>
              <PerfectScrollbar>
                <Box minWidth="100%">
                  <Table size="small">
                    <TableBody>
                      {cards.map((card) => (
                        <TableRow key={card.id} className={classes.row}>
                          <TableCell className={classes.cellText}>
                            <Grid className={classes.cardNumberRow}>
                              {card.cardType === 'master' && <img src={images.master} alt="" className={classes.cardType} />}
                              {card.cardType === 'visa' && <img src={images.visa} alt="" className={classes.cardType} />}
                              {card.cardType === 'american_express' && <img src={images.amex} alt="" className={classes.cardType} />}
                              {card.maskedCardNumber}
                              {
                                defaultCard === card.id
                                  ? (
                                    <Grid className={classes.defaultLabel}>
                                      <Hidden xsDown>
                                        {ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_DEFAULT')}
                                      </Hidden>
                                    </Grid>
                                  )
                                  : null
                              }
                            </Grid>
                          </TableCell>
                          <Hidden smDown>
                            <TableCell className={classes.cellText}>
                              {`${card.firstName || ''} ${card.lastName || ''}`}
                            </TableCell>
                          </Hidden>
                          <Hidden smDown>
                            <TableCell align="center" className={classes.cellText}>
                              {
                                ready && `${t('Exp.')}${card.expirationMonth.toString().length !== 2
                                  ? `0${card.expirationMonth}`
                                  : card.expirationMonth}/${card.expirationYear}`
                              }
                            </TableCell>
                          </Hidden>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <div className={classes.caption}>
                {
                  !maxWidth && `${cards.length} card${cards.length !== 1 ? 's' : ''} available`
                }
                <TablePagination
                  component="div"
                  classes={{
                    root: classes.paginationComp,
                    toolbar: classes.paginationToolbar,
                    caption: classes.paginationCaption,
                    selectRoot: classes.paginationCaption,
                    select: classes.paginationMenu,
                    menuItem: classes.paginationMenu,
                    actions: classes.paginationActions,
                  }}
                  count={cards.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
                />
              </div>
            </Card>
          )
          : <EmptyText str="ACCOUNT_SETTINGS_BILLING_NO_CARDS" />
      }
    </>
  );
}

Billing.propTypes = {
  className: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  subscription: PropTypes.shape({
    selfServiceUrl: PropTypes.string
  }),
  parentPadding: PropTypes.bool,
  header: PropTypes.string,
  pageLimit: PropTypes.number
};

export default withRouter(Billing);
