/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import clsx from 'clsx';
import Axios from 'axios';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Document, Page, pdfjs } from 'react-pdf';
import { connect, useSelector } from 'react-redux';
// import xmlbuilder from 'xmlbuilder';
import heic2any from 'heic2any';
import { withRouter } from 'react-router';

import {
  Button, Card, Checkbox, InputAdornment, IconButton, SvgIcon, TablePagination, TextField, Typography,
  Grid, Menu, MenuItem, CircularProgress, Hidden, useMediaQuery, Tooltip, Modal, Backdrop, Fade, Box,
  ButtonGroup, Popper, Grow, Paper, ClickAwayListener, MenuList, FormGroup, FormControlLabel, Link,
} from '@material-ui/core';
import {
  ViewAgendaOutlined as InvoiceIcon,
  Search as SearchIcon,
  ViewColumnOutlined as ViewColumnOutlinedIcon,
  DescriptionOutlined as LogsIcon,
  Close as CloseIcon,
  CalendarToday as CalendarIcon,
  Person as UserIcon,
  ArrowDropDown,
  Business as BusinessIcon,
} from '@material-ui/icons';

import {
  viewTypes, invoiceStatuses, recentFilterOptions, invoiceStatusesKeys, filterTimePeriod, maxUploadCount
} from 'src/config';
import {
  appendContactSupport, applyFilters, applyJiraFilters, axiosHeaders, axiosHeadersFormData, getDeliverFormat,
  getLocalisedErrorString, getMaskedCurrencyText, isActionPermitted, isFileMIMETypeHEIC, permissions,
  sendFeedback, sleep, validateEmail, validateToken
} from 'src/Shared/utils/helpers';
import authService from 'src/Shared/utils/services/authService';
import * as actions from 'src/Dashboard/store/actions/dashboard';
import { setUserData } from 'src/Auth/store/actions/auth';
import InvoicesTable from 'src/Dashboard/components/InvoicesListResults/InvoicesTable/InvoicesTable';
import InvoicesJira from 'src/Dashboard/components/InvoicesListResults/InvoicesJira/InvoicesJira';
import { isStatusChangeValid } from 'src/Shared/utils/invoiceStateChangeValidations';
import UserCreateForm from 'src/User/components/UserCreateForm/UserCreateForm';
import ChooseValidatorModal from 'src/Invoices/components/ChooseValidatorModal/ChooseValidatorModal';
import ExportToEmailModal from 'src/Invoices/components/ExportToEmailModal/ExportToEmailModal';
import ManageExportFieldMappings from 'src/Invoices/components/ManageExportFieldMappings/ManageExportFieldMappings';
import ConfigContext from 'src/Contexts';
import BulkOperations from '../BulkOperations/BulkOperations';
import useStyles from './style';
import CustomPeriodFilter from '../CustomPeriodFilter/CustomPeriodFilter';
import DeleteDuplicatesModal from '../DeleteDuplicatesModal/DeleteDuplicatesModal';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function InvoicesListResults({
  className, invoices, getInvoices, setTime, time, getArchivedInvoices, onGetAppInvoiceStats,
  updateUser, total, onStartInterval, onClearInterval, changeFilter, containsFailedToExport, containsPossibleDuplicate,
  containsIsDuplicate, history, ...rest
}) {
  const { LOGIN_PANEL_URL, API, BRAND_NAME } = useContext(ConfigContext);
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { ready, t } = useTranslation();

  const [displayInvoices, setDisplayInvoices] = useState(invoices || []);
  const [limitModalOpen, setLimitModalOpen] = useState(false);

  const [view, setView] = useState(viewTypes.grid);
  const [invoiceType, setInvoiceType] = useState(invoiceStatuses.all);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [jiraLimit, setJiraLimit] = useState(25);

  const [fields, setFields] = useState([]);
  const [lineFields, setLineFields] = useState([]);
  const [supplierFields, setSupplierFields] = useState([]);

  const [supplierFilterPage, setSupplierFilterPage] = useState(0);
  const [supplierFilterTotal, setSupplierFilterTotal] = useState(0);

  const [pdf, setPdf] = useState(null);
  const [appFile, setAppFile] = useState('');

  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({
    type: null,
    status: null,
    recent: null,
  });

  const [failedToExport, setFailedToExport] = useState(false);
  const [isThisDuplicate, setIsThisDuplicate] = useState(false);
  const [isPossibleDuplicate, setIsPossibleDuplicate] = useState(false);

  const dataFormatOptionsToEmailRef = useRef(null);
  const dataFormatOptionsToExportRef = useRef(null);

  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [emails, setEmails] = useState(user?.deliverEmails?.length > 0 ? user.deliverEmails.join(', ') : user.email);
  const [selectedDataFormatToEmail, setSelectedDataFormatToEmail] = useState(user?.deliverFormat);
  const [dataFormatOptionsToEmailOpen, setDataFormatOptionsToEmailOpen] = useState(false);
  const [openDeleteDuplicatesConf, setOpenDeleteDuplicatesConf] = useState(false);

  const [exportDataModalOpen, setExportDataModalOpen] = useState(false);
  const [selectedDataFormatToExport, setSelectedDataFormatToExport] = useState(user?.deliverFormat);
  const [dataFormatOptionsToExportOpen, setDataFormatOptionsToExportOpen] = useState(false);
  const [deliverFormats, setDeliverFormats] = useState([]);

  const maxWidth = useMediaQuery('(min-width: 1500px)');
  const maxWidthRows = useMediaQuery('(max-width: 525px)');
  const maxWidthFilter = useMediaQuery('(min-width: 690px)');
  const maxWidthUploadBtn = useMediaQuery('(min-width: 500px)');

  const [sortDirection, setSortDirection] = useState('desc');
  const [sortField, setSortField] = useState('');

  const [anchorElPeriod, setAnchorElPeriod] = useState(null);
  const [periodFilterApplied, setPeriodFilterApplied] = useState(false);
  const [anchorElCustomPeriod, setAnchorElCustomPeriod] = useState(null);
  const startTimeRef = useRef('');
  const endTimeRef = useRef('');

  const viewRef = useRef(null);
  const [viewOptionsOpen, setViewOptionsOpen] = useState(false);

  const [jiraFilteredInvoices, setJiraFilteredInvoices] = useState(applyJiraFilters(invoices, filters));

  const [supplierSearch, setSupplierSearch] = useState('');
  const suppliersRef = useRef(null);
  const [suppliersFilterOpen, setSuppliersFilterOpen] = useState(false);
  const [selectedSuppliersList, setSelectedSuppliersList] = useState([]);
  const [filteredSuppliersList, setFilteredSuppliersList] = useState([]);
  const [supplierFilterApplied, setSupplierFilterApplied] = useState(false);

  const [ownerSearch, setOwnerSearch] = useState('');
  const ownersRef = useRef(null);
  const [ownersFilterOpen, setOwnersFilterOpen] = useState(false);
  const [selectedOwnersList, setSelectedOwnersList] = useState([]);
  const [filteredOwnersList, setFilteredOwnersList] = useState([]);
  const [ownerFilterApplied, setOwnerFilterApplied] = useState(false);

  const [validatorUsers, setValidatorUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [chooseValidatorModalOpen, setChooseValidatorModalOpen] = useState(false);
  const [validatorOptionsOpen, setValidatorOptionsOpen] = useState(false);
  const validatorOptionsRef = useRef(null);
  const [selectedValidator, setSelectedValidator] = useState({
    id: '',
    firstname: '',
    lastname: '',
  });

  const [selectedInvoice, setSelectedInvoice] = useState({});

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [newlyAddedUser, setNewlyAddedUser] = useState(null);

  const isSupplierFilterRemoved = user.customisations.includes('supplierFilterRemoved');
  const isToValidateStatusAllowed = user.customisations.includes('toValidateStatus');

  const [menuAssistantID, setMenuAssistantID] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [deleteInvoiceID, setDeleteInvoiceID] = useState(null);
  const [openDeleteConf, setOpenDeleteConf] = useState(false);

  const [showExportFieldMapping, setShowExportFieldMapping] = useState(false);
  const [excelExportFieldMapping, setExcelExportFieldMapping] = useState([]);
  const [datExportFieldMapping, setDatExportFieldMapping] = useState([]);
  const [csvExportFieldMapping, setCsvExportFieldMapping] = useState([]);

  const isPassportOrID = user.documentType === 'id';

  useEffect(() => {
    setJiraFilteredInvoices(applyJiraFilters(invoices, filters));
  }, [invoices, filters]);

  const noSupplierObj = {
    creditorCode: '__EMPTY__',
    creditorName: 'INVOICE_LIST_NO_SUPPLIER_OPTION'
  };

  const handleValidatorOptionsToggle = () => {
    setValidatorOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleValidatorOptionsClose = (event) => {
    if (validatorOptionsRef.current && validatorOptionsRef.current.contains(event.target)) {
      return;
    }
    setValidatorOptionsOpen(false);
  };

  const handleValidatorClick = (validator) => {
    setSelectedValidator(validator);
    setValidatorOptionsOpen(false);
  };

  const handleAddUserClick = () => {
    if (user.usersCount < user.maxUsers) {
      setAddUserModalOpen(true);
    }
  };

  const handleChooseValidatorModalClose = () => {
    setSelectedInvoice({});
    setChooseValidatorModalOpen(false);
  };

  const handleSendEmailModalClose = () => {
    setSendEmailModalOpen(false);
  };

  const handleDeleteDuplicatesModalOpen = () => {
    setOpenDeleteDuplicatesConf(true);
  };

  const onCancelDeleteDuplicates = () => {
    setOpenDeleteDuplicatesConf(false);
  };

  const executeGetInvoices = (ty, p, l, q, tm, sp, ow, st, et, ef, dc, pd, sb, or) => {
    if (ty === invoiceStatusesKeys.archived) {
      const url = new URL(window.location.href);
      url.searchParams.delete('supplier_filter');
      url.searchParams.delete('duplicate_documents');
      url.searchParams.delete('possible_duplicates');
      setSelectedSuppliersList([]);
      setSupplierFilterApplied(false);
      window.sessionStorage.removeItem('selectedSuppliers');
      url.searchParams.delete('owner_filter');
      setSelectedOwnersList([]);
      setOwnerFilterApplied(false);
      window.sessionStorage.removeItem('selectedOwners');
      getArchivedInvoices(p, l, q, tm, st, et, sb, or);
    } else {
      getInvoices(p, l, q, ty, tm, sp, ow, st, et, ef, dc, pd, sb, or);
    }
  };

  const onDeleteDuplicates = async () => {
    setLoading(true);
    try {
      const response = await Axios.post(
        API.deleteDuplicates,
        { company: user.companyID, documents: selectedInvoices.map((i) => (i.isDuplicate ? parseInt(i.assistantID, 10) : null)) },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        setSelectedInvoices([]);
        executeGetInvoices(
          invoiceType,
          page,
          limit,
          query,
          time,
          selectedSuppliersList,
          selectedOwnersList,
          startTimeRef.current,
          endTimeRef.current,
          failedToExport,
          isThisDuplicate,
          isPossibleDuplicate,
          sortField,
          sortDirection,
        );
        enqueueSnackbar(t('PROCYS_DELETE_DUPLICATE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        setOpenDeleteDuplicatesConf(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('DELETE_DUPLICATES_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const getFields = async () => {
    const docType = isPassportOrID ? 'id' : 'invoice';
    try {
      const response = await Axios.get(
        `${API.fields}/${docType}/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setFields(response.data.data);
        } else {
          setFields([]);
        }
      }
    } catch (error) {
      setFields([]);
    }
  };

  const getLineFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/invoice-line/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setLineFields(response.data.data);
        } else {
          setLineFields([]);
        }
      }
    } catch (error) {
      setLineFields([]);
    }
  };

  const getSupplierFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/supplier/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setSupplierFields(response.data.data);
        } else {
          setSupplierFields([]);
        }
      }
    } catch (error) {
      setSupplierFields([]);
    }
  };

  const getDeliverFormats = async () => {
    try {
      const response = await Axios.get(
        API.getDeliverFormats,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDeliverFormats(response.data.data);
          if (user.deliverFormat) {
            setSelectedDataFormatToEmail(user.deliverFormat);
            setSelectedDataFormatToExport(user.deliverFormat);
          } else {
            setSelectedDataFormatToEmail(response.data.data.find((o) => (o.active && !o.dontExportViaEmail))?.key);
            setSelectedDataFormatToExport(response.data.data[0]?.key);
          }
        }
      }
    } catch (error) {
      setDeliverFormats([]);
    }
  };

  const getExcelExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}excel`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setExcelExportFieldMapping(response.data.data);
          return;
        }
      }
      setExcelExportFieldMapping([]);
    } catch (error) {
      setExcelExportFieldMapping([]);
    }
  };

  const getDatExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}dat`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDatExportFieldMapping(response.data.data);
          return;
        }
      }
      setDatExportFieldMapping([]);
    } catch (error) {
      setDatExportFieldMapping([]);
    }
  };

  const getCsvExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}csv`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCsvExportFieldMapping(response.data.data);
          return;
        }
      }
      setCsvExportFieldMapping([]);
    } catch (error) {
      setCsvExportFieldMapping([]);
    }
  };

  useEffect(() => {
    setTimeout(() => setDisplayInvoices(invoices), 100);
  }, [invoices]);

  const handlePeriodClick = (event) => {
    setAnchorElPeriod(event.currentTarget);
  };

  const handlePeriodClose = () => {
    setAnchorElPeriod(null);
  };

  const handleCustomPeriodClick = () => {
    setAnchorElCustomPeriod(document.getElementById('period-filter'));
  };

  const handleCustomPeriodClose = () => {
    setAnchorElCustomPeriod(null);
  };

  const handleViewOptionsOpen = () => {
    setViewOptionsOpen(true);
  };

  const handleViewOptionsClose = () => {
    setViewOptionsOpen(false);
  };

  const handleSuppliersFilterOpen = () => {
    setSuppliersFilterOpen(true);
  };

  const handleSupplierClick = (e, supplier) => {
    e.preventDefault();
    if (selectedSuppliersList.some((s) => s.creditorCode === supplier.creditorCode)) {
      setSelectedSuppliersList((prevState) => prevState.filter((s) => s.creditorCode !== supplier.creditorCode));
    } else {
      setSelectedSuppliersList((prevState) => [...prevState, supplier]);
    }
  };

  const handleChooseAllSuppliers = () => {
    setSelectedSuppliersList(filteredSuppliersList);
  };

  const handleRemoveAllSuppliers = () => {
    setSelectedSuppliersList([]);
  };

  const getSupplierLabel = (supplier) => {
    if (supplier.creditorCode === noSupplierObj.creditorCode) {
      return t(supplier.creditorName);
    }
    if (user.customisations.includes('supplierLabelWithVAT')) {
      if (supplier.vatNumber) {
        return `${supplier.vatNumber} | ${supplier.creditorName}`;
      }
      return supplier.creditorName;
    }
    return `${supplier.creditorCode} | ${supplier.creditorName}`;
  };

  const handleApplySupplierFilter = () => {
    const url = new URL(window.location.href);
    setPage(0);
    if (selectedSuppliersList.length) {
      setSupplierFilterApplied(true);
      url.searchParams.set('supplier_filter', JSON.stringify(true));
      window.sessionStorage.setItem('selectedSuppliers', JSON.stringify(selectedSuppliersList));
    } else {
      setSupplierFilterApplied(false);
      url.searchParams.delete('supplier_filter');
      window.sessionStorage.removeItem('selectedSuppliers');
    }
    setSuppliersFilterOpen(false);
    executeGetInvoices(
      invoiceType,
      0,
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection
    );
  };

  const handleSuppliersFilterClose = () => {
    const url = new URL(window.location.href);
    const supplierFilter = url.searchParams.has('supplier_filter');
    const selectedSuppliersFromSession = JSON.parse(window.sessionStorage.getItem('selectedSuppliers'));
    if (supplierFilter && selectedSuppliersFromSession !== null) {
      setSelectedSuppliersList(selectedSuppliersFromSession);
    } else {
      setSupplierFilterApplied(false);
      setSelectedSuppliersList([]);
    }
    setSuppliersFilterOpen(false);
  };

  const handleOwnersFilterOpen = () => {
    setOwnersFilterOpen(true);
  };

  const handleOwnerClick = (e, owner) => {
    e.preventDefault();
    if (selectedOwnersList.some((s) => s.email === owner.email)) {
      setSelectedOwnersList((prevState) => prevState.filter((s) => s.email !== owner.email));
    } else {
      setSelectedOwnersList((prevState) => [...prevState, owner]);
    }
  };

  const handleChooseAllOwners = () => {
    setSelectedOwnersList(filteredOwnersList);
  };

  const handleRemoveAllOwners = () => {
    setSelectedOwnersList([]);
  };

  const getOwnerLabel = (owner) => `${owner.firstname} ${owner.lastname}`;

  const handleApplyOwnerFilter = () => {
    const url = new URL(window.location.href);
    setPage(0);
    if (selectedOwnersList.length) {
      setOwnerFilterApplied(true);
      url.searchParams.set('owner_filter', JSON.stringify(true));
      window.sessionStorage.setItem('selectedOwners', JSON.stringify(selectedOwnersList));
    } else {
      setOwnerFilterApplied(false);
      url.searchParams.delete('owner_filter');
      window.sessionStorage.removeItem('selectedOwners');
    }
    setOwnersFilterOpen(false);
    executeGetInvoices(
      invoiceType,
      0,
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection
    );
  };

  const handleOwnersFilterClose = () => {
    const url = new URL(window.location.href);
    const ownerFilter = url.searchParams.has('owner_filter');
    const selectedOwnersFromSession = JSON.parse(window.sessionStorage.getItem('selectedOwners'));
    if (ownerFilter && selectedOwnersFromSession !== null) {
      setSelectedOwnersList(selectedOwnersFromSession);
    } else {
      setOwnerFilterApplied(false);
      setSelectedOwnersList([]);
    }
    setOwnersFilterOpen(false);
  };

  const handleSelectedInvoiceChange = (newlySelectedInvoices) => {
    setSelectedInvoices(newlySelectedInvoices);
    window.sessionStorage.setItem('selectedInvoices', JSON.stringify(newlySelectedInvoices));
  };

  const getAllSuppliers = async (search = '', sPage = 0) => {
    if (search !== '') {
      setSupplierSearch(search);
    }
    if (sPage !== 0) {
      setSupplierFilterPage(sPage);
    }
    try {
      const response = await Axios.get(
        `${API.findSuppliers}/${user.companyID}?search=${search}&limit=10&page=${sPage + 1}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSupplierFilterTotal(response.data.total ? response.data.total : 0);
        if (search !== '' || sPage !== 0) {
          setFilteredSuppliersList(response.data.data);
        } else {
          setFilteredSuppliersList([
            noSupplierObj,
            ...response.data.data]);
        }
      }
    } catch (error) {
      setFilteredSuppliersList([noSupplierObj]);
    }
  };

  const getUsers = async () => {
    try {
      const url = `${API.getUsersByCompanies}${encodeURIComponent(user.companyID)}`;
      const resp = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (resp.data.success && resp.data.data?.length > 0) {
        setUsers(resp.data.data.filter((u) => u.status === 'Active'));
        setFilteredOwnersList(resp.data.data.filter((u) => u.status === 'Active'));
      }
    } catch (error) {
      setValidatorUsers([]);
      setFilteredOwnersList([]);
    }
  };

  const getValidatorUsers = async () => {
    try {
      const url = `${API.getUsersByCompanies}${encodeURIComponent(user.companyID)}?permission=invoice_validate`;
      const resp = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (resp.data.success && resp.data.data?.length > 0) {
        setValidatorUsers(resp.data.data.filter((u) => u.status === 'Active'));
        setSelectedValidator(resp.data.data[0]);
      }
    } catch (error) {
      setValidatorUsers([]);
    }
  };

  const getAllUsers = async (newUser) => {
    setNewlyAddedUser(newUser);
    if (validatorUsers.length === 1) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('feedback-first-user', 'success');
      history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
    }
    await getValidatorUsers();
  };

  const handleSupplierSearch = (val) => {
    const searchVal = val.toLowerCase();
    getAllSuppliers(searchVal);
  };

  useEffect(() => {
    handleSupplierSearch(supplierSearch);
  }, [supplierSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOwnerSearch = (val) => {
    const searchVal = val.toLowerCase();
    if (searchVal) {
      const newFilteredOwners = users.filter((owner) => owner.firstname?.toLowerCase().includes(searchVal) || owner.lastname?.toLowerCase().includes(searchVal));
      setFilteredOwnersList(newFilteredOwners);
    } else {
      setFilteredOwnersList(users);
    }
  };

  useEffect(() => {
    handleOwnerSearch(ownerSearch);
  }, [ownerSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAllSuppliers();
    getDeliverFormats();
    getValidatorUsers();
    getUsers();
    getFields();
    getLineFields();
    getSupplierFields();
    getExcelExportFieldMapping();
    getDatExportFieldMapping();
    getCsvExportFieldMapping();
    const queryVal = new URLSearchParams(history.location.search);
    const viewJira = queryVal.get('viewJira');
    let filter = parseInt(queryVal.get('filters'), 10);
    const status = queryVal.get('status');
    const qury = queryVal.get('query');
    const pageNo = queryVal.get('page');
    const pagesLimit = queryVal.get('limit');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter'));
    const ownerFilter = JSON.parse(queryVal.get('owner_filter'));
    const documentsSelected = JSON.parse(queryVal.get('documents_selected'));
    const startTime = parseInt(queryVal.get('start_time'), 10);
    const endTime = parseInt(queryVal.get('end_time'), 10);
    const ifailedToExport = JSON.parse(queryVal.get('failed_to_export'));
    const duplicateDocumentFilter = JSON.parse(queryVal.get('duplicate_documents'));
    const possibleDuplicateFilter = JSON.parse(queryVal.get('possible_duplicates'));
    const sortBy = queryVal.get('sort_by');
    const order = queryVal.get('order');

    if (viewJira !== null && JSON.parse(viewJira)) {
      setView(viewTypes.jira);
    }

    const selectedInvoicesFromSession = JSON.parse(window.sessionStorage.getItem('selectedInvoices'));
    if (documentsSelected && selectedInvoicesFromSession !== null) {
      setSelectedInvoices(selectedInvoicesFromSession);
    } else if (!documentsSelected) {
      window.sessionStorage.removeItem('selectedInvoices');
    }

    let selectedSuppliersFromSession = JSON.parse(window.sessionStorage.getItem('selectedSuppliers'));
    if (supplierFilter && selectedSuppliersFromSession !== null) {
      setSelectedSuppliersList(selectedSuppliersFromSession);
      setSupplierFilterApplied(true);
    } else if (!supplierFilter) {
      selectedSuppliersFromSession = [];
      window.sessionStorage.removeItem('selectedSuppliers');
    }

    let selectedOwnersFromSession = JSON.parse(window.sessionStorage.getItem('selectedOwners'));
    if (ownerFilter && selectedOwnersFromSession !== null) {
      setSelectedOwnersList(selectedOwnersFromSession);
      setOwnerFilterApplied(true);
    } else if (!ownerFilter) {
      selectedOwnersFromSession = [];
      window.sessionStorage.removeItem('selectedOwners');
    }

    let tme = null;
    if (startTime !== null && endTime !== null && !Number.isNaN(startTime) && !Number.isNaN(endTime)) {
      filter = 99;
    }
    if (filter !== null) {
      if (filter !== 100 && !Number.isNaN(filter)) {
        setPeriodFilterApplied(true);
      }
      filterTimePeriod.forEach((item) => {
        if (item.index === filter) {
          setTime(item.value);
          tme = item.value;
        }
      });
      setFilters((prevState) => ({
        ...prevState,
        recent: parseInt(filter, 10),
        status
      }));
      if (filter === 99) {
        startTimeRef.current = startTime;
        endTimeRef.current = endTime;
      }
    }
    if (qury) {
      setQuery(qury);
      onClearInterval();
    }
    if (status) {
      setInvoiceType(status);
      changeFilter(status);
    }
    if (pageNo) {
      setPage(parseInt(pageNo, 10));
    }
    if (pagesLimit) {
      setLimit(parseInt(pagesLimit, 10));
    }
    if (ifailedToExport) {
      setFailedToExport(ifailedToExport);
    }
    if (duplicateDocumentFilter) {
      setIsThisDuplicate(duplicateDocumentFilter);
    }
    if (possibleDuplicateFilter) {
      setIsPossibleDuplicate(possibleDuplicateFilter);
    }
    if (sortBy) {
      setSortField(sortBy);
    }
    if (order) {
      setSortDirection(order);
    }

    executeGetInvoices(
      status,
      parseInt(pageNo, 10) || page,
      parseInt(pagesLimit, 10) || limit,
      qury,
      tme,
      selectedSuppliersFromSession || selectedSuppliersList,
      selectedOwnersFromSession || selectedOwnersList,
      startTime,
      endTime,
      ifailedToExport,
      duplicateDocumentFilter,
      possibleDuplicateFilter,
      sortBy || sortField,
      order || sortDirection
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.deliverEmails && user.deliverEmails.length > 0) {
      setEmails(user.deliverEmails.join(', '));
    }
  }, [user]);

  const onChangeType = (s) => {
    let value = s;

    if (invoiceType === s) {
      setInvoiceType(invoiceStatuses.all);
      changeFilter('');
      value = null;

      const url = new URL(window.location.href);
      if (url.searchParams.has('status')) {
        url.searchParams.delete('status');
      }

      url.toString();
      executeGetInvoices(
        value,
        0,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        false,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection
      );
      window.history.pushState({}, null, url);
    } else {
      setPage(0);
      setInvoiceType(s);
      changeFilter(s);

      executeGetInvoices(
        s,
        0,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        false,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection
      );
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
    setFailedToExport(false);
    setIsThisDuplicate(false);

    const url = new URL(window.location.href);
    if (value !== null) {
      if (url.searchParams.has('status')) {
        url.searchParams.set('status', value);
      } else {
        url.searchParams.append('status', value);
      }
    }
    if (url.searchParams.has('page')) {
      url.searchParams.delete('page');
    }
    if (url.searchParams.has('failed_to_export')) {
      url.searchParams.delete('failed_to_export');
    }
    if (url.searchParams.has('duplicate_documents')) {
      url.searchParams.delete('duplicate_documents');
    }
    if (url.searchParams.has('possible_duplicates')) {
      url.searchParams.delete('possible_duplicates');
    }
    url.toString();

    handleSelectedInvoiceChange([]);
    window.history.pushState({}, null, url);
  };

  const onChangeView = (v) => {
    onChangeType(invoiceType);
    setView(v);
    handleViewOptionsClose();
    setFilters((prevFilters) => ({
      ...prevFilters,
      recent: 100
    }));
    const url = new URL(window.location.href);
    if (url.searchParams.has('filter')) {
      url.searchParams.delete('filter');
    }
    if (url.searchParams.has('query')) {
      url.searchParams.delete('query');
    }
    if (url.searchParams.has('start_time')) {
      url.searchParams.delete('start_time');
    }
    if (url.searchParams.has('end_time')) {
      url.searchParams.delete('end_time');
    }
    if (url.searchParams.has('failed_to_export')) {
      url.searchParams.delete('failed_to_export');
    }
    if (url.searchParams.has('duplicate_documents')) {
      url.searchParams.delete('duplicate_documents');
    }
    if (url.searchParams.has('possible_duplicates')) {
      url.searchParams.delete('possible_duplicates');
    }
    if (url.searchParams.has('sort_by')) {
      url.searchParams.delete('sort_by');
    }
    if (url.searchParams.has('order')) {
      url.searchParams.delete('order');
    }
    setSortField('uploaded_date');
    setSortDirection('desc');
    setIsThisDuplicate(false);
    setIsPossibleDuplicate(false);
    setFailedToExport(false);
    setPeriodFilterApplied(false);
    setQuery('');
    if (v === viewTypes.jira) {
      getInvoices(0, jiraLimit);
      onClearInterval();
    } else {
      getInvoices(0, limit);
      onStartInterval();
      if (url.searchParams.has('viewJira')) {
        url.searchParams.delete('viewJira');
      }
    }
    url.toString();
    window.history.pushState({}, null, url);
  };

  const handleQueryChange = (event) => {
    const { value } = event.target;
    event.persist();
    setQuery(value);
    setPage(0);

    if (!value) {
      onStartInterval();
    } else {
      onClearInterval();
    }

    setDisplayInvoices([]);
    handleSelectedInvoiceChange([]);

    const url = new URL(window.location.href);
    if (value !== null) {
      if (url.searchParams.has('query')) {
        url.searchParams.set('query', value);
      } else {
        url.searchParams.append('query', value);
      }
    }
    url.toString();

    executeGetInvoices(
      invoiceType,
      0,
      limit,
      value,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection
    );
    window.history.pushState({}, null, url);
  };

  const handleCustomPeriodApply = (from, to) => {
    const fromTimeStamp = Math.floor(new Date(from).getTime() / 1000);
    const toTimeStamp = Math.floor(new Date(to).getTime() / 1000);
    setPeriodFilterApplied(true);
    handleSelectedInvoiceChange([]);
    startTimeRef.current = fromTimeStamp;
    endTimeRef.current = toTimeStamp;

    setTime(filterTimePeriod.find((f) => f.index === 99).value);

    setFilters((prevFilters) => ({
      ...prevFilters,
      recent: 99
    }));
    executeGetInvoices(
      invoiceType,
      0,
      limit,
      query,
      '',
      selectedSuppliersList,
      selectedOwnersList,
      fromTimeStamp,
      toTimeStamp,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection
    );

    const url = new URL(window.location.href);
    if (url.searchParams.has('filter')) {
      url.searchParams.delete('filter');
    }
    if (url.searchParams.has('start_time')) {
      url.searchParams.set('start_time', fromTimeStamp);
    } else {
      url.searchParams.append('start_time', fromTimeStamp);
    }
    if (url.searchParams.has('end_time')) {
      url.searchParams.set('end_time', toTimeStamp);
    } else {
      url.searchParams.append('end_time', toTimeStamp);
    }
    url.toString();
    handleCustomPeriodClose();

    window.history.pushState({}, null, url);
  };

  const handleRecentFilterChange = (filter) => {
    if (filter === 99) {
      handleCustomPeriodClick();
      handlePeriodClose();
      return;
    }
    startTimeRef.current = '';
    endTimeRef.current = '';
    if (filter === 100) {
      setPeriodFilterApplied(false);
    } else {
      setPeriodFilterApplied(true);
    }
    handleSelectedInvoiceChange([]);

    let value = null;
    if (filter !== 0) {
      value = filter;
    }

    filterTimePeriod.forEach((item) => {
      if (item.index === filter) {
        setTime(item.value);
        executeGetInvoices(
          invoiceType,
          0,
          limit,
          query,
          item.value,
          selectedSuppliersList,
          selectedOwnersList,
          '',
          '',
          failedToExport,
          isThisDuplicate,
          isPossibleDuplicate,
          sortField,
          sortDirection
        );
      }
    });

    setFilters((prevFilters) => ({
      ...prevFilters,
      recent: value
    }));

    const url = new URL(window.location.href);
    if (value !== null) {
      if (url.searchParams.has('filter')) {
        url.searchParams.set('filter', value);
      } else {
        url.searchParams.append('filter', value);
      }
    }
    if (url.searchParams.has('start_time')) {
      url.searchParams.delete('start_time');
    }
    if (url.searchParams.has('end_time')) {
      url.searchParams.delete('end_time');
    }
    url.toString();
    handlePeriodClose();

    window.history.pushState({}, null, url);
  };

  const handleSelectAllInvoices = (event) => {
    const selected = event.target.checked
      ? displayInvoices.map(
        (invoice) => ({
          assistantID: invoice.assistantID,
          status: invoice.status,
          id: invoice.id,
          isDuplicate: invoice.isDuplicate
        })
      )
      : [];
    handleSelectedInvoiceChange(selected);
  };

  const isSelected = (id) => selectedInvoices.some((i) => i.id === id);

  const handleSelectOneInvoice = (event, assistantID, status, id, exportedToGstock, isDuplicate) => {
    event.stopPropagation();
    if (selectedInvoices.length < 1 || selectedInvoices === undefined) {
      handleSelectedInvoiceChange([{
        assistantID, status, id, exportedToGstock, isDuplicate
      }]);
    } else if (!isSelected(id)) {
      handleSelectedInvoiceChange([...selectedInvoices, {
        assistantID, status, id, exportedToGstock, isDuplicate
      }]);
    } else {
      handleSelectedInvoiceChange(selectedInvoices.filter((i) => i.id !== id));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setDisplayInvoices([]);
    handleSelectedInvoiceChange([]);

    if (view === viewTypes.jira) {
      getInvoices(newPage, jiraLimit, query, null, time, [], [], startTimeRef.current, endTimeRef.current, isThisDuplicate, isPossibleDuplicate);
    } else {
      executeGetInvoices(
        invoiceType,
        newPage,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection
      );
    }

    const url = new URL(window.location.href);
    if (url.searchParams.has('page')) {
      url.searchParams.set('page', newPage);
    } else {
      url.searchParams.append('page', newPage);
    }
    url.toString();
    window.history.pushState({}, null, url);
  };

  const handleSupplierFilterPageChange = (event, newPage) => {
    setSupplierFilterPage(newPage);
    setFilteredSuppliersList([]);
    setSelectedSuppliersList([]);

    getAllSuppliers(supplierSearch, newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);

    executeGetInvoices(
      invoiceType,
      0,
      event.target.value,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection
    );

    const url = new URL(window.location.href);
    if (url.searchParams.has('limit')) {
      url.searchParams.set('limit', event.target.value);
    } else {
      url.searchParams.append('limit', event.target.value);
    }
    if (url.searchParams.has('page')) {
      url.searchParams.set('page', 0);
    } else {
      url.searchParams.append('page', 0);
    }
    url.toString();

    handleSelectedInvoiceChange([]);
    window.history.pushState({}, null, url);
  };

  const handleJiraLimitChange = (event) => {
    setJiraLimit(event.target.value);
    setPage(0);
    setDisplayInvoices([]);
    getInvoices(0, event.target.value, query, null, time, [], [], startTimeRef.current, endTimeRef.current);
  };

  const handleChangeFilterDuplicates = (event) => {
    setIsThisDuplicate(event.target.checked);
    setIsPossibleDuplicate(false);
    setFailedToExport(false);
    setPage(0);

    executeGetInvoices(
      invoiceType,
      0,
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      false,
      event.target.checked,
      false,
      sortField,
      sortDirection
    );

    const url = new URL(window.location.href);
    if (event.target.checked) {
      url.searchParams.append('duplicate_documents', 'true');
      url.searchParams.delete('possible_duplicates');
      url.searchParams.delete('failed_to_export');
    } else {
      url.searchParams.delete('duplicate_documents');
    }
    if (url.searchParams.has('page')) {
      url.searchParams.set('page', 0);
    } else {
      url.searchParams.append('page', 0);
    }
    url.toString();

    handleSelectedInvoiceChange([]);
    window.history.pushState({}, null, url);
  };

  const handleChangeFilterPossibleDuplicates = (event) => {
    setIsPossibleDuplicate(event.target.checked);
    setFailedToExport(false);
    setIsThisDuplicate(false);
    setPage(0);

    executeGetInvoices(
      invoiceType,
      0,
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      false,
      false,
      event.target.checked,
      sortField,
      sortDirection
    );

    const url = new URL(window.location.href);
    if (event.target.checked) {
      url.searchParams.append('possible_duplicates', 'true');
      url.searchParams.delete('duplicate_documents');
      url.searchParams.delete('failed_to_export');
    } else {
      url.searchParams.delete('possible_duplicates');
    }
    if (url.searchParams.has('page')) {
      url.searchParams.set('page', 0);
    } else {
      url.searchParams.append('page', 0);
    }
    url.toString();

    handleSelectedInvoiceChange([]);
    window.history.pushState({}, null, url);
  };

  const handleChangeFailedToExport = (event) => {
    setFailedToExport(event.target.checked);
    setIsThisDuplicate(false);
    setIsPossibleDuplicate(false);
    setPage(0);

    executeGetInvoices(
      invoiceType,
      0,
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      event.target.checked,
      false,
      false,
      sortField,
      sortDirection
    );

    const url = new URL(window.location.href);
    if (event.target.checked) {
      url.searchParams.append('failed_to_export', 'true');
      url.searchParams.delete('duplicate_documents');
      url.searchParams.delete('possible_duplicates');
    } else {
      url.searchParams.delete('failed_to_export');
    }
    if (url.searchParams.has('page')) {
      url.searchParams.set('page', 0);
    } else {
      url.searchParams.append('page', 0);
    }
    url.toString();

    handleSelectedInvoiceChange([]);
    window.history.pushState({}, null, url);
  };

  const validateDocument = (nameParts, file) => {
    if (nameParts[0].length > 200) {
      enqueueSnackbar(t('PROCYS_VALIDATE_FILE_NAME'), {
        variant: 'error',
        persist: true
      });
      return false;
    }

    if (file.size > 30 * 1024 * 1024) {
      enqueueSnackbar(t('PROCYS_VALIDATE_FILE_SIZE'), {
        variant: 'error',
        persist: true
      });
      return false;
    }

    return true;
  };

  const handleSort = (field) => {
    const newSortField = (sortField === field) && sortDirection === 'asc' ? '' : field;
    const order = newSortField === sortField ? 'asc' : 'desc';
    executeGetInvoices(
      invoiceType,
      page,
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      newSortField,
      order
    );
    setSortDirection(order);
    setSortField(newSortField);

    const url = new URL(window.location.href);
    if (newSortField) {
      if (url.searchParams.has('sort_by')) {
        url.searchParams.set('sort_by', field);
      } else {
        url.searchParams.append('sort_by', field);
      }
    } else {
      url.searchParams.delete('sort_by');
    }

    if (url.searchParams.has('order')) {
      url.searchParams.set('order', order);
    } else {
      url.searchParams.append('order', order);
    }

    url.toString();
    window.history.pushState({}, null, url);
  };

  const getHeader = (e) => {
    const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
    let header = '';
    for (let j = 0; j < arr.length; j++) {
      header += arr[j].toString(16);
    }

    return header;
  };

  const readFileAsync = async (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = async (e) => {
      const header = getHeader(e);
      resolve(header);
    };
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });

  const getConvertedFile = async (file) => {
    const res = await fetch(URL.createObjectURL(file));
    const blob = await res.blob();
    const conversionResult = await heic2any({ blob, toType: 'image/jpeg' });

    return conversionResult;
  };

  const sendInvoice = async (header, file = null) => {
    try {
      let f = file;
      if (f && isFileMIMETypeHEIC(header, f.type)) {
        f = await getConvertedFile(f);
      }

      const data = new FormData();
      data.append('datafile', f || appFile);
      data.append('customerId', user.id || 1111);

      const response = await Axios.post(
        API.uploadDocument,
        data,
        axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
      );
      if (response.data.success) {
        executeGetInvoices(
          invoiceType,
          page,
          limit,
          query,
          time,
          selectedSuppliersList,
          selectedOwnersList,
          startTimeRef.current,
          endTimeRef.current,
          failedToExport,
          isThisDuplicate,
          isPossibleDuplicate,
          sortField,
          sortDirection
        );
        onGetAppInvoiceStats();
        enqueueSnackbar(t('PROCYS_FILE_UPLOAD_SUCCESS'), {
          variant: 'success',
          persist: true
        });

        if (user.totalInvoices === 49) {
          const currentUrlParams = new URLSearchParams(window.location.search);
          currentUrlParams.set('feedback-upload-fifty', 'success');
          history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
        }
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'PROCYS_FILE_UPLOAD_FAILURE', t), t), {
        variant: 'error',
        persist: true
      });
      setLoading(false);
    }
  };

  const sendInvoiceBulk = async (file) => {
    try {
      const data = new FormData();
      data.append('datafile', file);
      data.append('customerId', user.id || 1111);

      const response = await Axios.post(
        API.uploadDocument,
        data,
        axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
      );
      return response;
    } catch (err) {
      //
    }
    return false;
  };

  const uploadDocument = async (file = null) => {
    try {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onloadend = async (e) => {
          const header = getHeader(e);
          await sendInvoice(header, file);
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        await sendInvoice('', null);
      }
    } catch (error) {
      enqueueSnackbar(appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'PROCYS_FILE_UPLOAD_FAILURE', t), t), {
        variant: 'error',
        persist: true
      });
      setLoading(false);
    }
  };

  const goToLogsViaSnackbar = (path) => {
    closeSnackbar('snack_with_link');
    history.push(path);
  };

  const uploadInvoice = async (e) => {
    setLoading(true);
    const { files } = e.target;

    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        setLoading(false);
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    let limitExceeded = false;
    if (user.maxInvoices > 0 && (user.maxInvoices <= user.invoicesCount || user.maxInvoices < user.invoicesCount + files.length)) {
      limitExceeded = true;
    }

    if (files && files.length === 1) {
      const file = files[0];
      setAppFile(file);

      const nameParts = file.name.split('.');
      const isValid = validateDocument(nameParts, file);
      if (!isValid) {
        setLoading(false);
        return;
      }

      if (file.type === 'application/pdf') {
        const url = URL.createObjectURL(file);
        setPdf(url);
        return;
      }

      uploadDocument(file);
    }

    if (files && files.length > maxUploadCount) {
      enqueueSnackbar(t('PROCYS_VALIDATE_FILE_COUNT'), {
        variant: 'error',
        persist: true
      });
      setLoading(false);
      return;
    }

    if (files && files.length > 1) {
      // const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        const nameParts = files[i].name.split('.');
        const isValid = validateDocument(nameParts, files[i]);
        if (!isValid) {
          setLoading(false);
          return;
        }
      }

      let uploadedCount = 0;
      for (let i = 0; i < files.length; i++) {
        enqueueSnackbar(t('PROCYS_UPLOADING_COUNT', { count: i + 1, total: files.length }), {
          variant: 'success',
          autoHideDuration: 2500
        });

        try {
          if (files[i]?.type === 'application/pdf') {
            const response = await sendInvoiceBulk(files[i]); // eslint-disable-line
            if (response.data.success) {
              uploadedCount++;
              if (i !== files.length - 1) {
                await sleep(500); // eslint-disable-line
              }
              // else {
              //   updateCount(uploadedCount);
              // }
            }
          } else {
            const header = await readFileAsync(files[i]); // eslint-disable-line
            let f = files[i];
            if (isFileMIMETypeHEIC(header, f.type)) {
              f = await getConvertedFile(f); // eslint-disable-line
            }

            const response = await sendInvoiceBulk(f); // eslint-disable-line
            if (response.data.success) {
              uploadedCount++;
              if (i !== files.length - 1) {
                await sleep(500); // eslint-disable-line
              }
              // else {
              //   updateCount(uploadedCount);
              // }
            }
          }
        } catch (error) {
          //
        }
      }

      if (uploadedCount <= 0) {
        enqueueSnackbar(appendContactSupport(window.config.support_email, t('PROCYS_NETWORK_ERROR_UPLOAD_INVOICE'), t), {
          variant: 'error',
          persist: true
        });
        setLoading(false);
        return;
      }

      enqueueSnackbar(
        <Typography className={classes.bulkUploadSuccessText}>
          <Trans i18nKey="PROCYS_FILES_UPLOAD_SUCCESS">
            Uploaded
            {' '}
            {{ uploadedCount }}
            {' '}
            out of
            {' '}
            {{ total: files.length }}
            {' '}
            documents successfully, check errors
            {' '}
            <Typography className={classes.bulkUploadSuccessLink} onClick={() => goToLogsViaSnackbar('/documents/logs')}>here</Typography>
          </Trans>
        </Typography>, {
          variant: 'success',
          persist: true,
          key: 'snack_with_link',
          className: classes.snackbarContainer,
        }
      );
      executeGetInvoices(
        invoiceType,
        page,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection,
      );
      onGetAppInvoiceStats();
      setLoading(false);
    }

    if (limitExceeded) {
      setLimitModalOpen(true);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    if (numPages > 250) {
      enqueueSnackbar(t('PROCYS_VALIDATE_FILE_PAGES'), {
        variant: 'error',
        persist: true
      });
      return;
    }

    setTimeout(() => {
      uploadDocument();
    }, 400);
  };

  const isChangeValid = (to) => {
    for (let i = 0; i < selectedInvoices.length; i++) {
      if (!isStatusChangeValid(selectedInvoices[i].status, to, isToValidateStatusAllowed)) {
        return false;
      }
    }

    return true;
  };

  const handleOpenMenu = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuAssistantID(id);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuAssistantID(null);
    setMenuAnchorEl(null);
  };

  const handleChangeBulkStatus = async (to) => {
    if (to === 501 && !chooseValidatorModalOpen) {
      setChooseValidatorModalOpen(true);
      return;
    }
    try {
      const body = {
        company: user.companyID,
        status: to.toString(),
        documents: selectedInvoices.filter((inv) => inv.assistantID !== null).map((inv) => inv.assistantID)
      };

      if (to === 501 && selectedValidator && selectedValidator.email) {
        body.assignee = selectedValidator.email;
      }

      const response = await Axios.post(
        API.bulkStatus,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        // Fetch invoices list and stats.
        if (filters.status === invoiceStatuses.archived || filters.status === invoiceStatusesKeys.archived) {
          getArchivedInvoices(0, limit, query, time, startTimeRef.current, endTimeRef.current, sortField, sortDirection);
        } else {
          executeGetInvoices(
            invoiceType,
            0,
            limit,
            query,
            time,
            selectedSuppliersList,
            selectedOwnersList,
            startTimeRef.current,
            endTimeRef.current,
            failedToExport,
            isThisDuplicate,
            isPossibleDuplicate,
            sortField,
            sortDirection,
          );
        }
        enqueueSnackbar(t(user.isDocunecta && to === 503 ? 'DOCUNECTA_EXPORT_SUCCESS' : 'PROCYS_STATUS_CHANGE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        handleSelectedInvoiceChange([]);
        setChooseValidatorModalOpen(false);
      }
    } catch (error) {
      executeGetInvoices(
        invoiceType,
        page,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection,
      );
      enqueueSnackbar(appendContactSupport(window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n
          || (user.isDocunecta && to === 503 ? 'DOCUNECTA_EXPORT_FAILURE' : 'INVOICE_STATUS_CHANGE_FAILED'), t),
        t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const onSelectMenuItem = async (event, code, id, iid, invoice) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    if (code === 900) {
      setOpenDeleteConf(true);
      setDeleteInvoiceID(iid);
      return;
    }

    if (code === 501 && !chooseValidatorModalOpen && invoice) {
      setSelectedInvoice(invoice);
      setChooseValidatorModalOpen(true);
      return;
    }

    setStatusChangeLoading(true);

    try {
      const body = {
        assistantID: parseInt(id, 10),
        toStatus: code.toString()
      };

      if (code === 501 && selectedValidator && selectedValidator.email && selectedValidator.email !== invoice.owner) {
        body.assignee = selectedValidator.email;
      }

      const response = await Axios.post(
        API.documentStatus,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        // Fetch invoices list and stats.
        executeGetInvoices(
          invoiceType,
          page,
          limit,
          query,
          time,
          selectedSuppliersList,
          selectedOwnersList,
          startTimeRef.current,
          endTimeRef.current,
          failedToExport,
          isThisDuplicate,
          isPossibleDuplicate,
          sortField,
          sortDirection,
        );
        let successMessage = t('PROCYS_STATUS_CHANGE_SUCCESS');
        if (code === 501) {
          successMessage = t('INVOICE_STATUS_CHANGE_TO_VALIDATE');
          sendFeedback(API.feedbackLogs, 'send to validate', user.email, user.companyID);
        }
        if (code === 502) {
          sendFeedback(API.feedbackLogs, 'validate invoice', user.email, user.companyID);
        }
        if (code === 503) {
          sendFeedback(API.feedbackLogs, 'invoice export', user.email, user.companyID);
          const currentUrlParams = new URLSearchParams(window.location.search);
          if (!user.exported || user.exported === 0) {
            currentUrlParams.set('feedback-first-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
          if (user.exported === 9) {
            currentUrlParams.set('feedback-tenth-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
        }
        enqueueSnackbar(successMessage, {
          variant: 'success',
          persist: true
        });
        setStatusChangeLoading(false);
        setSelectedInvoice({});
        setChooseValidatorModalOpen(false);
      }
    } catch (error) {
      setStatusChangeLoading(false);
      executeGetInvoices(
        invoiceType,
        page,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection,
      );
      enqueueSnackbar(appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_STATUS_CHANGE_FAILED', t), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const onSendToValidate = (e) => {
    if (Object.keys(selectedInvoice).length === 0 && selectedInvoices.length > 0) {
      handleChangeBulkStatus(501);
    } else {
      onSelectMenuItem(e, 501, selectedInvoice.assistantID, selectedInvoice.id, selectedInvoice);
    }
  };

  useEffect(() => {
    if (newlyAddedUser) {
      const newSelectedUser = validatorUsers?.find((u) => u.email === newlyAddedUser.email);
      if (newSelectedUser) {
        setSelectedValidator(newSelectedUser);
      }
      setNewlyAddedUser(null);
    } else {
      const newSelectedUser = validatorUsers?.find((u) => u?.email === selectedInvoice?.owner);
      if (newSelectedUser) {
        setSelectedValidator(newSelectedUser);
      }
    }
  }, [validatorUsers]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBuyExtraInvoices = async () => {
    try {
      const response = await Axios.get(API.buyExtraInvoicesPackage, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success) {
        enqueueSnackbar(
          t('SUBSCRIPTION_BUY_EXTRA_PACKAGE_SUCCESS', { ecLimit: user.ecLimit || 25 }),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
    } catch (err) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('SUBSCRIPTION_BUY_EXTRA_PACKAGE_FAILED'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const filterSelected = (index) => {
    for (let i = 0; i < filterTimePeriod.length; i++) {
      if (filterTimePeriod[i].value === time && filterTimePeriod[i].index === index) {
        return true;
      }
    }

    return false;
  };

  // Usually query is done on backend with indexing solutions
  const sortedInvoices = displayInvoices;
  const filteredInvoices = applyFilters(sortedInvoices, filters?.status !== 'INVOICE_STATUS_ALL' && filters);
  const paginatedInvoices = filteredInvoices;
  const selectedSomeInvoices = selectedInvoices.length > 0 && selectedInvoices.length < displayInvoices.length;
  const selectedAllInvoices = selectedInvoices.length === displayInvoices.length;

  const handleDataFormatToEmailClick = (dataFormat) => {
    setSelectedDataFormatToEmail(dataFormat.key);
    setDataFormatOptionsToEmailOpen(false);
  };

  const handleDataformatOptionsToEmailToggle = () => {
    setDataFormatOptionsToEmailOpen((prevOpen) => !prevOpen);
  };

  const handleDataformatOptionsToEmailClose = (event) => {
    if (dataFormatOptionsToEmailRef.current && dataFormatOptionsToEmailRef.current.contains(event.target)) {
      return;
    }
    setDataFormatOptionsToEmailOpen(false);
  };

  const handleSendToEmailClick = async () => {
    const aidInStr = [];
    for (let i = 0; i < selectedInvoices.length; i++) {
      if (selectedInvoices[i].assistantID) {
        aidInStr.push(selectedInvoices[i].assistantID.toString());
      }
    }

    if (!emails || !emails.trim()) {
      enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_EMPTY'), {
        variant: 'error',
        persist: true
      });
      return;
    }
    const emailsList = [];
    if (emails.indexOf(',') >= 0) {
      const emailsAr = emails.split(',');
      for (let i = 0; i < emailsAr.length; i++) {
        if (emailsAr[i] && emailsAr[i].trim()) {
          const resp = validateEmail(emailsAr[i].trim());
          if (!resp.isValid) {
            enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_INVALID'), {
              variant: 'error',
              persist: true
            });
            return;
          }

          emailsList.push(emailsAr[i]);
        }
      }
    } else {
      const resp = validateEmail(emails.trim());
      if (!resp.isValid) {
        enqueueSnackbar(`${t(resp.errorMessage)}`, {
          variant: 'error',
          persist: true
        });
        return;
      }

      emailsList.push(emails.trim());
    }

    try {
      setLoading(true);
      await Axios.post(
        `${API.exportToEmail}${selectedDataFormatToEmail}`,
        {
          assistantIDS: aidInStr,
          emails: emailsList
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      executeGetInvoices(
        invoiceType,
        page,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection,
      );
      sendFeedback(API.feedbackLogs, 'invoice export', user.email, user.companyID);
      handleSelectedInvoiceChange([]);
      enqueueSnackbar(t('INVOICE_SEND_TO_EMAIL_SUCCESS'), {
        variant: 'success',
        persist: true
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_SEND_TO_EMAIL_FAILURE', t), t), {
        variant: 'error',
        persist: true
      });
    }

    setSendEmailModalOpen(false);
  };

  const handleDataFormatToExportClick = async (dataFormat) => {
    setSelectedDataFormatToExport(dataFormat?.key);
    setDataFormatOptionsToExportOpen(false);
  };

  const handleDataformatOptionsToExportToggle = () => {
    setDataFormatOptionsToExportOpen((prevOpen) => !prevOpen);
  };

  const handleDataformatOptionsToExportClose = (event) => {
    if (dataFormatOptionsToExportRef.current && dataFormatOptionsToExportRef.current.contains(event.target)) {
      return;
    }
    setDataFormatOptionsToExportOpen(false);
  };

  const handleSendToExportClick = async (executeExport = false) => {
    setLoading(true);
    const aidInStr = [];
    for (let i = 0; i < selectedInvoices.length; i++) {
      if (selectedInvoices[i].assistantID) {
        aidInStr.push(selectedInvoices[i].assistantID.toString());
      }
    }

    const header = axiosHeaders(localStorage.getItem('PROCYS_accessToken'));

    try {
      if (((selectedDataFormatToExport === 'excel' && excelExportFieldMapping.length === 0)
        || (selectedDataFormatToExport === 'dat' && datExportFieldMapping.length === 0)
        || (selectedDataFormatToExport === 'csv' && csvExportFieldMapping.length === 0))
        && !executeExport) {
        setLoading(false);
        setExportDataModalOpen(false);
        setShowExportFieldMapping(true);
        return;
      }

      const response = await Axios.post(
        `${API.exportBulk}${selectedDataFormatToExport}`,
        { assistantIDS: aidInStr },
        {
          ...header,
          timeout: 300000
        }
      );

      if (response.data.success) {
        if (
          selectedDataFormatToExport !== 'gstock'
            && selectedDataFormatToExport !== 'quickbooks'
            && selectedDataFormatToExport !== 'holded'
        ) {
          enqueueSnackbar(
            <Typography className={classes.bulkUploadSuccessText}>
              <Trans i18nKey="DOCUMENT_BULK_EXPORT_STARTED_SUCCESS">
                The export is started. When it is finished, we&apos;ll send the exported file to your email. You can also find all your export requests with the files in
                {' '}
                <Link className={classes.bulkUploadSuccessLink} onClick={() => goToLogsViaSnackbar('/documents/export/logs')}>“Export logs”</Link>
              </Trans>
            </Typography>, {
              variant: 'success',
              persist: true,
              key: 'snack_with_link',
              className: classes.snackbarContainer,
            }
          );
        } else {
          enqueueSnackbar(
            <Typography className={classes.bulkUploadSuccessText}>
              {t('DOCUMENT_BULK_EXPORT_STARTED_WILL_UPDATE_DOCUMENTS')}
            </Typography>, {
              variant: 'success',
              className: classes.snackbarContainer,
            }
          );
        }
        executeGetInvoices(
          invoiceType,
          page,
          limit,
          query,
          time,
          selectedSuppliersList,
          selectedOwnersList,
          startTimeRef.current,
          endTimeRef.current,
          failedToExport,
          isThisDuplicate,
          isPossibleDuplicate,
          sortField,
          sortDirection,
        );
        sendFeedback(API.feedbackLogs, 'invoice export', user.email, user.companyID);
      }
      handleSelectedInvoiceChange([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_EXPORT_DATA_FAILURE', t), t), {
        variant: 'error',
        persist: true
      });
    }

    setExportDataModalOpen(false);
  };

  const handleExportFieldMappingClose = async (updated) => {
    setShowExportFieldMapping(false);
    if (updated) {
      setTimeout(async () => {
        await getExcelExportFieldMapping();
        await getDatExportFieldMapping();
        await getCsvExportFieldMapping();
        handleSendToExportClick(true);
      }, 1000);
    }
  };

  const selectedSuppliersCount = () => {
    if (selectedSuppliersList.some((s) => s.creditorCode === '__EMPTY__')) {
      if (selectedSuppliersList.length > 1) {
        return selectedSuppliersList.length - 1;
      }
      return 0;
    } if (selectedSuppliersList.length > 0) {
      return selectedSuppliersList.length;
    }
    return 0;
  };

  const getStatusElementClasses = (status) => {
    if (invoiceType === status) {
      return clsx(
        classes.statusElementSelected,
        classes[status],
        (containsFailedToExport && status === invoiceStatusesKeys.toexport) ? classes.statusElementContainsFailedToExport : classes.none
      );
    }
    return clsx(
      classes.statusElement,
      (containsFailedToExport && status === invoiceStatusesKeys.toexport) ? classes.statusElementContainsFailedToExport : classes.none
    );
  };

  const searchBar = (
    <Grid className={clsx(classes.searchBar, 'search-bar')}>
      <Tooltip title={t(isPassportOrID ? 'DASHBOARD_SEARCH_ID_SEARCH_TOOLTIP' : 'DASHBOARD_SEARCH_INVOICE_SEARCH_TOOLTIP')}>
        <TextField
          className={classes.queryField}
          onChange={handleQueryChange}
          placeholder={ready && t(isPassportOrID ? 'DASHBOARD_SEARCH_ID_SEARCH' : 'DASHBOARD_SEARCH_INVOICE_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginRight: 0 }}>
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: '#C2CFE0' }}
                >
                  <SearchIcon style={{ color: '#C2CFE0' }} />
                </SvgIcon>
              </InputAdornment>
            ),
            classes: {
              input: classes.searchInput,
              root: classes.searchBarInput
            }
          }}
        />
      </Tooltip>
    </Grid>
  );

  const invoiceStatusElement = (text, status) => (
    <Grid className={getStatusElementClasses(status)} onClick={() => onChangeType(status)}>
      <Typography
        className={invoiceType === status ? classes.statusTextSelected : classes.statusText}
      >
        {ready && t(text)}
      </Typography>
      {(containsFailedToExport && status === invoiceStatusesKeys.toexport) && <span className={classes.exportFailed}>!</span>}
    </Grid>
  );

  const titleText = (
    <Typography className={classes.title}>
      {ready && t('DASHBOARD_INVOICES')}
    </Typography>
  );

  const statusButtons = () => {
    if (view === viewTypes.grid) {
      return (
        <Grid className={classes.statusContainer}>
          {invoiceStatusElement('DASHBOARD_INVOICES_REVIEW', invoiceStatusesKeys.toreview)}
          {!isToValidateStatusAllowed && invoiceStatusElement('DASHBOARD_INVOICES_REVIEWED', invoiceStatusesKeys.reviewed)}
          {isToValidateStatusAllowed && invoiceStatusElement('DASHBOARD_INVOICES_VALIDATE', invoiceStatusesKeys.tovalidate)}
          {invoiceStatusElement('DASHBOARD_INVOICES_TO_EXPORT', invoiceStatusesKeys.toexport)}
          {invoiceStatusElement('DASHBOARD_INVOICES_EXPORTED', invoiceStatusesKeys.exported)}
          {invoiceStatusElement('DASHBOARD_INVOICES_REJECTED', invoiceStatusesKeys.rejected)}
          {invoiceStatusElement('DASHBOARD_INVOICES_ERRORED', invoiceStatusesKeys.errored)}
          {invoiceStatusElement('DASHBOARD_INVOICES_ARCHIVED', invoiceStatusesKeys.archived)}
        </Grid>
      );
    }

    return null;
  };

  const viewButtons = (
    <>
      <Grid className={classes.viewsContainer}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          ref={viewRef}
          className={classes.filterBtn}
          onClick={() => handleViewOptionsOpen()}
        >
          <SvgIcon fontSize="13" style={{ marginRight: 8, color: '#90A0B7' }}>
            {
              view === viewTypes.jira ? (
                <ViewColumnOutlinedIcon
                  className={classes.viewIcon}
                  style={{ fontSize: '1.8rem' }}
                />
              ) : (
                <InvoiceIcon className={classes.viewIcon} />
              )
            }
          </SvgIcon>
          {maxWidthFilter && ready && t('DASHBOARD_INVOICES_VIEW')}
        </Button>
      </Grid>
      <Menu
        id="views-simple-menu"
        anchorEl={viewRef.current}
        elevation={0}
        getContentAnchorEl={null}
        keepMounted
        open={viewOptionsOpen}
        onClose={handleViewOptionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.filterMenu
        }}
      >
        <MenuItem
          key={viewTypes.jira}
          className={clsx(classes.filterMenuItem, view === viewTypes.jira ? classes.selectedFilterMenuItem : '')}
          onClick={() => onChangeView(viewTypes.jira)}
        >
          <SvgIcon fontSize="13" style={{ marginRight: 8 }}>
            <ViewColumnOutlinedIcon
              className={view === viewTypes.jira ? classes.viewIconSelected : classes.viewIcon}
              style={{ fontSize: '1.8rem' }}
            />
          </SvgIcon>
          {ready && t('DASHBOARD_INVOICES_TYPE_1')}
        </MenuItem>
        <MenuItem
          key={viewTypes.grid}
          className={clsx(classes.filterMenuItem, view === viewTypes.grid ? classes.selectedFilterMenuItem : '')}
          onClick={() => onChangeView(viewTypes.grid)}
        >
          <SvgIcon fontSize="13" style={{ marginRight: 8 }}>
            <InvoiceIcon
              className={view === viewTypes.grid ? classes.viewIconSelected : classes.viewIcon}
            />
          </SvgIcon>
          {ready && t('DASHBOARD_INVOICES_TYPE_2')}
        </MenuItem>
      </Menu>
    </>
  );

  const gotoLogs = (
    <Tooltip title={ready && t('DASHBOARD_INVOICES_LOGS_TOOLTIP')}>
      <IconButton onClick={() => history.push('/documents/logs')}>
        <LogsIcon
          className={classes.viewIcon}
          style={{ fontSize: '1.8rem' }}
        />
      </IconButton>
    </Tooltip>
  );

  const periodFilter = (
    <Grid>
      <Button
        id="period-filter"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handlePeriodClick}
        className={periodFilterApplied ? classes.filterBtnSelected : classes.filterBtn}
      >
        <SvgIcon fontSize="13" style={{ marginRight: 8 }}>
          <CalendarIcon className={classes.filterIcon} />
        </SvgIcon>
        {maxWidthFilter && ready && t('DASHBOARD_INVOICES_PERIOD')}
      </Button>
      <Menu
        id="period-simple-menu"
        anchorEl={anchorElPeriod}
        elevation={0}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorElPeriod)}
        onClose={handlePeriodClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.filterMenu
        }}
      >
        {
          [...recentFilterOptions, {
            value: 99,
            label: 'DASHBOARD_INVOICES_PERIOD_CUSTOM'
          }].map((o) => (
            <MenuItem
              key={o.value}
              className={clsx(classes.filterMenuItem, filterSelected(o.value) ? classes.selectedFilterMenuItem : '')}
              onClick={() => handleRecentFilterChange(o.value)}
            >
              {t(o.label)}
            </MenuItem>
          ))
        }
      </Menu>
      <CustomPeriodFilter
        anchorElCustomPeriod={anchorElCustomPeriod}
        handleCustomPeriodClose={handleCustomPeriodClose}
        handleCustomPeriodApply={handleCustomPeriodApply}
      />
    </Grid>
  );

  const supplierFilter = (
    !isSupplierFilterRemoved && invoiceType !== invoiceStatusesKeys.archived && (
      <Grid>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          ref={suppliersRef}
          onClick={handleSuppliersFilterOpen}
          className={supplierFilterApplied ? classes.filterBtnSelected : classes.filterBtn}
        >
          <SvgIcon fontSize="small" style={{ marginRight: 8 }}>
            <BusinessIcon className={classes.filterIcon} />
          </SvgIcon>
          {maxWidthFilter && ready && t('DASHBOARD_INVOICES_SUPPLIERS')}
        </Button>
        <Popper
          open={suppliersFilterOpen}
          anchorEl={suppliersRef.current}
          role={undefined}
          transition
          style={{
            zIndex: 111111, width: 250, padding: '0px 12px',
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleSuppliersFilterClose}>
                  <Grid className={classes.supplierFilterContainer}>
                    <TextField
                      className={classes.supplierSearch}
                      onChange={(e) => setSupplierSearch(e.target.value)}
                      placeholder={ready && t('DASHBOARD_INVOICES_SUPPLIERS_SEARCH_PLACEHOLDER')}
                      value={supplierSearch}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" style={{ marginRight: 0 }}>
                            <SvgIcon
                              fontSize="small"
                              color="action"
                              style={{ fill: '#C2CFE0' }}
                            >
                              <SearchIcon style={{ color: '#C2CFE0' }} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                        classes: {
                          input: classes.searchInput,
                          root: classes.searchBarInput
                        }
                      }}
                    />
                    <Grid className={classes.supplierFilterActions}>
                      <Typography className={classes.supplierFilterText}>
                        {
                          selectedSuppliersCount() > 0
                            && ready
                            && `${selectedSuppliersCount()} ${selectedSuppliersCount() > 1
                              ? t('DASHBOARD_INVOICES_SUPPLIERS_SELECTED')
                              : t('DASHBOARD_INVOICES_ONE_SUPPLIER_SELECTED')
                            }`
                        }
                      </Typography>
                    </Grid>
                    <Grid className={classes.supplierFilterActions}>
                      <Typography className={classes.supplierFilterActionTextHidden} onClick={handleChooseAllSuppliers}>{ready && t('DASHBOARD_INVOICES_SUPPLIERS_CHOOSE_ALL')}</Typography>
                      <Typography className={classes.supplierFilterActionText} onClick={handleRemoveAllSuppliers}>{ready && t('DASHBOARD_INVOICES_SUPPLIERS_REMOVE_ALL')}</Typography>
                    </Grid>
                    <MenuList
                      id="suppliers-simple-menu"
                      classes={{
                        paper: classes.filterMenu,
                        root: classes.filterMenuRoot
                      }}
                    >
                      {
                        filteredSuppliersList.map((supplier) => (
                          <MenuItem
                            key={supplier.creditorCode}
                            className={classes.filterMenuItem}
                            onClick={(e) => handleSupplierClick(e, supplier)}
                          >
                            <FormGroup>
                              <FormControlLabel
                                classes={{ label: classes.supplierLabel, root: classes.supplierLabelRoot }}
                                control={(
                                  <Checkbox
                                    checked={selectedSuppliersList.some((s) => s.creditorCode === supplier.creditorCode)}
                                    className={classes.checkbox}
                                    classes={{ root: classes.checkboxRoot }}
                                  />
                                )}
                                label={getSupplierLabel(supplier)}
                              />
                            </FormGroup>
                          </MenuItem>
                        ))
                      }
                    </MenuList>
                    <TablePagination
                      component="div"
                      classes={{
                        caption: classes.paginationCaption,
                        selectRoot: classes.paginationCaption,
                        menuItem: classes.paginationCaption,
                      }}
                      count={supplierFilterTotal}
                      onPageChange={handleSupplierFilterPageChange}
                      page={supplierFilterPage}
                      rowsPerPage={10}
                      rowsPerPageOptions={[10]}
                      labelRowsPerPage=""
                    />
                    <Grid className={classes.supplierFilterActions}>
                      <Box mt={2} display="flex" justifyContent="center" className={classes.supplierBtnContainer}>
                        <Button
                          variant="outlined"
                          className={classes.cancelButtonSup}
                          type="submit"
                          onClick={handleSuppliersFilterClose}
                        >
                          {ready && t('DASHBOARD_INVOICES_CANCEL')}
                        </Button>
                        <Button
                          className={classes.applyButtonSup}
                          variant="contained"
                          color="secondary"
                          onClick={handleApplySupplierFilter}
                        >
                          {ready && t('DASHBOARD_INVOICES_APPLY')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    )
  );

  const ownerFilter = (
    invoiceType !== invoiceStatusesKeys.archived && (
      <Grid>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          ref={ownersRef}
          onClick={handleOwnersFilterOpen}
          className={ownerFilterApplied ? classes.filterBtnSelected : classes.filterBtn}
        >
          <SvgIcon fontSize="small" style={{ marginRight: 8 }}>
            <UserIcon className={classes.filterIcon} />
          </SvgIcon>
          {maxWidthFilter && ready && t('DASHBOARD_INVOICES_USERS_FILTER')}
        </Button>
        <Popper
          open={ownersFilterOpen}
          anchorEl={ownersRef.current}
          role={undefined}
          transition
          style={{
            zIndex: 111111, width: 250, padding: '0px 12px',
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleOwnersFilterClose}>
                  <Grid className={classes.supplierFilterContainer}>
                    <TextField
                      className={classes.supplierSearch}
                      onChange={(e) => setOwnerSearch(e.target.value)}
                      placeholder={ready && t('DASHBOARD_INVOICES_OWNERS_SEARCH_PLACEHOLDER')}
                      value={ownerSearch}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" style={{ marginRight: 0 }}>
                            <SvgIcon
                              fontSize="small"
                              color="action"
                              style={{ fill: '#C2CFE0' }}
                            >
                              <SearchIcon style={{ color: '#C2CFE0' }} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                        classes: {
                          input: classes.searchInput,
                          root: classes.searchBarInput
                        }
                      }}
                    />
                    <Grid className={classes.supplierFilterActions}>
                      <Typography className={classes.supplierFilterActionText} onClick={handleChooseAllOwners}>{ready && t('DASHBOARD_INVOICES_SUPPLIERS_CHOOSE_ALL')}</Typography>
                      <Typography className={classes.supplierFilterActionText} onClick={handleRemoveAllOwners}>{ready && t('DASHBOARD_INVOICES_SUPPLIERS_REMOVE_ALL')}</Typography>
                    </Grid>
                    <MenuList
                      id="owners-simple-menu"
                      classes={{
                        paper: classes.filterMenu,
                        root: classes.filterMenuRoot
                      }}
                    >
                      {
                      filteredOwnersList.map((owner) => (
                        <MenuItem
                          key={owner.email}
                          className={classes.filterMenuItem}
                          onClick={(e) => handleOwnerClick(e, owner)}
                        >
                          <FormGroup>
                            <FormControlLabel
                              classes={{ label: classes.supplierLabel, root: classes.supplierLabelRoot }}
                              control={(
                                <Checkbox
                                  checked={selectedOwnersList.some((s) => s.email === owner.email)}
                                  className={classes.checkbox}
                                  classes={{ root: classes.checkboxRoot }}
                                />
                              )}
                              label={getOwnerLabel(owner)}
                            />
                          </FormGroup>
                        </MenuItem>
                      ))
                    }
                    </MenuList>
                    <Grid className={classes.supplierFilterActions}>
                      <Box mt={2} display="flex" justifyContent="center" className={classes.supplierBtnContainer}>
                        <Button
                          variant="outlined"
                          className={classes.cancelButtonSup}
                          type="submit"
                          onClick={handleOwnersFilterClose}
                        >
                          {ready && t('DASHBOARD_INVOICES_CANCEL')}
                        </Button>
                        <Button
                          className={classes.applyButtonSup}
                          variant="contained"
                          color="secondary"
                          onClick={handleApplyOwnerFilter}
                        >
                          {ready && t('DASHBOARD_INVOICES_APPLY')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    )
  );

  const paginationTab = () => (
    <TablePagination
      component="div"
      classes={{
        caption: classes.paginationCaption,
        selectRoot: classes.paginationCaption,
        menuItem: classes.paginationCaption,
      }}
      count={total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
    />
  );

  const uploadButton = () => {
    if (loading) {
      return (
        <Grid className={classes.action}>
          <CircularProgress size={20} />
        </Grid>
      );
    }

    return (
      <Tooltip
        title={!isActionPermitted(permissions.invoiceUpload, user.permissions)
          ? ready && t('INVOICE_UPLOAD_NO_PERMISSION')
          : user.isUploadBlocked ? ready && t('INVOICE_UPLOAD_BLOCKED_LIMIT_REACHED_TOOLTIP')
            : ''}
      >
        <span>
          <Button
            color="secondary"
            variant="contained"
            component="label"
            className={classes.action}
            disabled={!isActionPermitted(permissions.invoiceUpload, user.permissions) || user.isUploadBlocked}
          >
            <input
              id="upload-input"
              accept="image/png,image/jpeg,image/jpg,image/tiff,application/pdf,image/heic"
              type="file"
              multiple
              onChange={uploadInvoice}
              style={{ display: 'none', zIndex: 111111111 }}
              required
            />
            {ready && t('DASHBOARD_INVOICES_UPLOAD_INVOICE')}
          </Button>
        </span>
      </Tooltip>
    );
  };

  return (
    <Grid>
      <Hidden only={['xs', 'sm', 'md']}>
        {
          maxWidth ? (
            <Grid className={clsx(classes.header, classes.headerMargin)}>
              {titleText}
              {statusButtons()}
              {searchBar}
              <Grid style={{ width: 12 }} />
              {periodFilter}
              {ownerFilter}
              {supplierFilter}
              {viewButtons}
              {gotoLogs}
              {uploadButton()}
            </Grid>
          ) : (
            <Grid className={clsx(classes.headerColumn, classes.headerMargin)}>
              <Grid className={classes.header}>
                {titleText}
                {statusButtons()}
              </Grid>
              <Grid className={classes.header}>
                {searchBar}
                <Grid style={{ width: 12 }} />
                {periodFilter}
                {ownerFilter}
                {supplierFilter}
                {viewButtons}
                {gotoLogs}
                {uploadButton()}
              </Grid>
            </Grid>
          )
        }
      </Hidden>
      <Hidden only={['xs', 'sm', 'lg', 'xl']}>
        <Grid className={clsx(classes.headerColumn, classes.headerMargin)}>
          <Grid className={classes.header}>
            {titleText}
            {statusButtons()}
          </Grid>
          <Grid className={classes.header}>
            {searchBar}
            <Grid style={{ width: 12 }} />
            {periodFilter}
            {ownerFilter}
            {supplierFilter}
            {viewButtons}
            {gotoLogs}
            {uploadButton()}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={['xs', 'md', 'lg', 'xl']}>
        <Grid className={clsx(classes.headerColumn, classes.headerMargin)}>
          <Grid className={classes.header}>
            {titleText}
          </Grid>
          <Grid className={classes.header}>
            {statusButtons()}
          </Grid>
          <Grid className={classes.header}>
            {searchBar}
            <Grid style={{ width: 12 }} />
            {periodFilter}
            {ownerFilter}
            {supplierFilter}
            {viewButtons}
            {gotoLogs}
            {uploadButton()}
          </Grid>
        </Grid>
      </Hidden>
      {maxWidthUploadBtn && (
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <Grid className={clsx(classes.headerColumn, classes.headerMargin)}>
            <Grid className={classes.header}>
              {titleText}
            </Grid>
            <Grid className={classes.header}>
              {statusButtons()}
            </Grid>
            <Grid className={classes.header}>
              {searchBar}
            </Grid>
            <Grid className={classes.headerSpace}>
              {periodFilter}
              {ownerFilter}
              {supplierFilter}
              {gotoLogs}
              {viewButtons}
              {uploadButton()}
            </Grid>
          </Grid>
        </Hidden>
      )}
      {!maxWidthUploadBtn && (
        <Grid className={clsx(classes.headerColumn, classes.headerMargin)}>
          <Grid className={classes.header}>
            {titleText}
          </Grid>
          <Grid className={classes.header}>
            {statusButtons()}
          </Grid>
          <Grid className={classes.header}>
            {searchBar}
          </Grid>
          <Grid className={classes.header}>
            {periodFilter}
            {ownerFilter}
            {supplierFilter}
            {gotoLogs}
            {viewButtons}
          </Grid>
          <Grid className={classes.headerSpace}>
            {uploadButton()}
          </Grid>
        </Grid>
      )}

      {
        pdf && (
          <Grid style={{ display: 'none' }}>
            <Document
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={1} />
            </Document>
          </Grid>
        )
      }
      {
        view === viewTypes.grid && (
          <Card
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div className={classes.bulkOperations}>
              <div className={classes.bulkActions}>
                <BulkOperations
                  selectedInvoices={selectedInvoices}
                  selectedAllInvoices={selectedAllInvoices}
                  selectedSomeInvoices={selectedSomeInvoices}
                  handleSelectAllInvoices={handleSelectAllInvoices}
                  isActionPermitted={isActionPermitted}
                  setExportDataModalOpen={setExportDataModalOpen}
                  setSendEmailModalOpen={setSendEmailModalOpen}
                  isChangeValid={isChangeValid}
                  handleChangeBulkStatus={handleChangeBulkStatus}
                  isToValidateStatusAllowed={isToValidateStatusAllowed}
                  limit={limit}
                  total={total}
                  loading={loading}
                  setLoading={setLoading}
                  setSelectedInvoices={setSelectedInvoices}
                  search={query}
                  filter={invoiceType}
                  time={time}
                  startTime={startTimeRef.current}
                  endTime={endTimeRef.current}
                  suppliers={selectedSuppliersList}
                  users={users}
                  containsFailedToExport={containsFailedToExport}
                  containsIsDuplicate={containsIsDuplicate}
                  containsPossibleDuplicate={containsPossibleDuplicate}
                  failedToExport={failedToExport}
                  handleChangeFailedToExport={handleChangeFailedToExport}
                  handleChangeFilterDuplicates={handleChangeFilterDuplicates}
                  handleChangeFilterPossibleDuplicates={handleChangeFilterPossibleDuplicates}
                  isDuplicate={isThisDuplicate}
                  isPossibleDuplicate={isPossibleDuplicate}
                  handleDeleteDuplicatesModalOpen={handleDeleteDuplicatesModalOpen}
                  getInvoices={() => getInvoices(
                    page,
                    limit,
                    query,
                    invoiceType,
                    time,
                    selectedSuppliersList,
                    selectedOwnersList,
                    startTimeRef.current,
                    endTimeRef.current,
                    failedToExport,
                    isThisDuplicate,
                    isPossibleDuplicate,
                    sortField,
                    sortDirection
                  )}
                />
                {paginationTab()}
              </div>
            </div>
            {
              filteredInvoices.length === 0 && <Grid className={classes.breakLine} />
            }
            <PerfectScrollbar>
              <InvoicesTable
                paginatedInvoices={paginatedInvoices}
                selectedInvoices={selectedInvoices}
                supplierFilter={supplierFilterApplied}
                ownerFilter={ownerFilterApplied}
                handleSelectOneInvoice={handleSelectOneInvoice}
                status={invoiceType}
                filters={filters}
                query={query}
                time={time}
                isDuplicate={isThisDuplicate}
                getInvoices={
                  () => getInvoices(
                    page,
                    limit,
                    query,
                    invoiceType,
                    time,
                    selectedSuppliersList,
                    selectedOwnersList,
                    startTimeRef.current,
                    endTimeRef.current,
                    failedToExport,
                    isThisDuplicate,
                    isPossibleDuplicate,
                    sortField,
                    sortDirection
                  )
                }
                getArchivedInvoices={() => getArchivedInvoices(page, limit, query, time, startTimeRef.current, endTimeRef.current, sortField, sortDirection)}
                updateUser={updateUser}
                page={page}
                limit={limit}
                total={total}
                isToValidateStatusAllowed={isToValidateStatusAllowed}
                onSelectMenuItem={onSelectMenuItem}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
                menuAnchorEl={menuAnchorEl}
                menuAssistantID={menuAssistantID}
                deleteInvoiceID={deleteInvoiceID}
                setDeleteInvoiceID={setDeleteInvoiceID}
                openDeleteConf={openDeleteConf}
                setOpenDeleteConf={setOpenDeleteConf}
                startTime={startTimeRef.current}
                endTime={endTimeRef.current}
                failedToExport={failedToExport}
                users={users}
                loading={loading}
                setLoading={setLoading}
                sortDirection={sortDirection}
                sortField={sortField}
                handleSort={handleSort}
              />
            </PerfectScrollbar>
            {paginatedInvoices?.length > 0 && paginationTab()}
          </Card>
        )
      }
      {
        view === viewTypes.jira && (
          <>
            <TablePagination
              component="div"
              classes={{
                caption: classes.paginationCaption,
                selectRoot: classes.paginationCaption,
                menuItem: classes.paginationCaption,
              }}
              count={total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleJiraLimitChange}
              page={page}
              rowsPerPage={jiraLimit}
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
            />
            <InvoicesJira
              paginatedInvoices={jiraFilteredInvoices}
              getInvoices={() => getInvoices(page, jiraLimit)}
              filters={filters}
              query={query}
              time={time}
              page={page}
              jiraLimit={jiraLimit}
              total={total}
              startTime={startTimeRef.current}
              endTime={endTimeRef.current}
            />
          </>
        )
      }
      <ChooseValidatorModal
        chooseValidatorModalOpen={chooseValidatorModalOpen}
        handleChooseValidatorModalClose={handleChooseValidatorModalClose}
        validatorOptionsRef={validatorOptionsRef}
        handleValidatorOptionsToggle={handleValidatorOptionsToggle}
        selectedValidator={selectedValidator}
        validatorOptionsOpen={validatorOptionsOpen}
        handleValidatorOptionsClose={handleValidatorOptionsClose}
        users={validatorUsers}
        handleValidatorClick={handleValidatorClick}
        handleAddUserClick={handleAddUserClick}
        statusChangeLoading={statusChangeLoading}
        onSendToValidate={onSendToValidate}
      />
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={addUserModalOpen}
        onClose={() => setAddUserModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={addUserModalOpen}>
          <div className={classes.halfPaper}>
            <Grid className={classes.modalHeader}>
              <Typography className={classes.headerTitle}>
                {ready && t('ACCOUNT_SETTINGS_USERS_ADD_NEW_USER')}
              </Typography>
              <IconButton onClick={() => setAddUserModalOpen(false)}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
            <UserCreateForm
              user={user}
              handleClose={() => setAddUserModalOpen(false)}
              updateUser={updateUser}
              onGetAllUsers={getAllUsers}
            />
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={limitModalOpen}
        onClose={() => setLimitModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={limitModalOpen}>
          <div className={classes.paper}>
            <Grid className={classes.modalHeader}>
              <Typography className={classes.headerTitle}>
                {ready && t('SUBSCRIPTION_INVOICES_LIMIT_REACH')}
              </Typography>
              <IconButton onClick={() => setLimitModalOpen(false)}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
            <Grid className={classes.deleteMessageText}>
              {
                user.subscription.toLowerCase() === 'free' && (
                  <Typography variant="caption">
                    {ready && t('FREE_PLAN_LIMIT_REACH_MESSAGE')}
                  </Typography>
                )
              }
              {
                user.subscription.toLowerCase() === 'starter' && (
                  <>
                    <Typography variant="caption">
                      {ready && t('OTHER_PLAN_LIMIT_REACH_MESSAGE')}
                    </Typography>
                    <ul className={classes.ulStyle}>
                      <li>
                        {ready && t('LIMIT_REACT_STARTER_LINE1')}
                      </li>
                      <li>
                        {ready && t('LIMIT_REACT_STARTER_LINE2')}
                      </li>
                    </ul>
                    <Typography variant="caption">
                      {ready && t('LIMIT_REACT_LINE3')}
                    </Typography>
                  </>
                )
              }
              {
                user.subscription.toLowerCase() === 'business' && (
                  <Grid className={classes.captionBody}>
                    <Typography variant="caption">
                      {ready && t('OTHER_PLAN_LIMIT_REACH_MESSAGE')}
                    </Typography>
                    <ul className={classes.ulStyle}>
                      <li>
                        {ready && t('LIMIT_REACT_BUSINESS_LINE1')}
                      </li>
                      <li>
                        {ready && t('LIMIT_REACT_BUSINESS_LINE2')}
                      </li>
                    </ul>
                    <Typography variant="caption">
                      {ready && t('LIMIT_REACT_LINE3')}
                    </Typography>
                  </Grid>
                )
              }
              {
                user.subscription.toLowerCase() === 'custom' && user.affiliate ? (
                  <>
                    <Typography variant="caption">
                      {ready && t('AFFILIATE_LIMIT_REACH_MESSAGE', { brand: BRAND_NAME })}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="caption">
                      {ready && t('OTHER_PLAN_LIMIT_REACH_MESSAGE')}
                    </Typography>
                    <ul className={classes.ulStyle}>
                      <li>
                        {ready && t('LIMIT_REACT_CUSTOM_LINE1')}
                      </li>
                      <li>
                        {ready && t('LIMIT_REACT_CUSTOM_LINE2', { var: user?.ecLimit || 25, var1: getMaskedCurrencyText((user?.ecCharge ? user.ecCharge : 0)?.toString()) || '12,50' })}
                      </li>
                    </ul>
                    <Typography variant="caption">
                      {ready && t('LIMIT_REACT_LINE3')}
                    </Typography>
                  </>
                )
              }
            </Grid>
            <Grid className={classes.userForm}>
              <Box mt={2} display="flex" justifyContent="center">
                {
                  (user.subscription.toLowerCase() === 'free' || user.subscription.toLowerCase() === 'starter') && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.submitBtn}
                      onClick={() => history.push('/account/subscription')}
                    >
                      {ready && t('SUBSCRIPTION_CHOOSE_PLAN')}
                    </Button>
                  )
                }
                {
                  (user.subscription.toLowerCase() === 'business' || (user.subscription.toLowerCase() === 'custom' && !user.affiliate)) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.submitBtn}
                      onClick={() => history.push('/account/subscription?open_modal=contact_us')}
                    >
                      {ready && t('SUBSCRIPTION_CONTACT_US')}
                    </Button>
                  )
                }
                {
                  (user.subscription.toLowerCase() === 'starter' || user.subscription.toLowerCase() === 'business' || (user.subscription.toLowerCase() === 'custom' && !user.affiliate)) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.submitBtn}
                      onClick={handleBuyExtraInvoices}
                    >
                      {ready && t('SUBSCRIPTION_BUY_EXTRA_PACKAGE')}
                    </Button>
                  )
                }
                {
                  user.subscription.toLowerCase() === 'custom' && user.affiliate && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.submitBtn}
                      onClick={() => history.push('/account/subscription')}
                    >
                      {ready && t('SUBSCRIPTION_ADD_CARD')}
                    </Button>
                  )
                }
              </Box>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <ExportToEmailModal
        sendEmailModalOpen={sendEmailModalOpen}
        handleSendEmailModalClose={handleSendEmailModalClose}
        emails={emails}
        setEmails={setEmails}
        dataFormatOptionsToEmailRef={dataFormatOptionsToEmailRef}
        handleDataformatOptionsToEmailToggle={handleDataformatOptionsToEmailToggle}
        getDeliverFormat={getDeliverFormat}
        selectedDataFormatToEmail={selectedDataFormatToEmail}
        deliverFormats={deliverFormats}
        dataFormatOptionsToEmailOpen={dataFormatOptionsToEmailOpen}
        handleDataformatOptionsToEmailClose={handleDataformatOptionsToEmailClose}
        handleDataFormatToEmailClick={handleDataFormatToEmailClick}
        handleSendToEmailClick={handleSendToEmailClick}
      />
      <Modal
        className={classes.modal}
        open={exportDataModalOpen}
        onClose={() => setExportDataModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={exportDataModalOpen}>
          <div className={classes.halfPaper}>
            <Grid className={classes.modalHeader}>
              <Typography className={classes.headerTitle}>
                {ready && t('EXPORT_DATA_TITLE')}
              </Typography>
              <IconButton onClick={() => setExportDataModalOpen(false)}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
            <Grid>
              <Grid className={classes.inputField}>
                <Typography variant="h6" className={classes.primaryTextColor}>
                  {ready && t('EXPORT_DATA_TITLE_FORMAT_INSTRUCTION')}
                </Typography>
                <Typography variant="h5" className={classes.inputField}>
                  {ready && t('EXPORT_DATA_FORMAT')}
                </Typography>
                <ButtonGroup variant="outlined" ref={dataFormatOptionsToExportRef} aria-label="split button" className={classes.btnGroup}>
                  <Button className={classes.groupBtn} onClick={handleDataformatOptionsToExportToggle}>
                    {getDeliverFormat(selectedDataFormatToExport, deliverFormats).label}
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={dataFormatOptionsToExportOpen}
                  anchorEl={dataFormatOptionsToExportRef.current}
                  role={undefined}
                  transition
                  style={{ zIndex: 111111, width: '90%', maxWidth: 450, }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleDataformatOptionsToExportClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {deliverFormats.map((option) => {
                              if (!option.exportViaApp) {
                                return null;
                              }

                              return (
                                <MenuItem
                                  key={option.id}
                                  selected={option.key === selectedDataFormatToExport}
                                  onClick={() => handleDataFormatToExportClick(option)}
                                  className={classes.groupBtn}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
            <Grid className={classes.userForm}>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  className={classes.cancelButton}
                  type="submit"
                  onClick={() => setExportDataModalOpen(false)}
                >
                  {ready && t('DASHBOARD_INVOICES_CANCEL')}
                </Button>
                <Button
                  className={classes.validateButton}
                  variant="contained"
                  color="secondary"
                  disabled={selectedDataFormatToExport === 'gstock' && selectedInvoices.some((invoice) => invoice.exportedToGstock)}
                  onClick={() => handleSendToExportClick()}
                >
                  {ready && t('DASHBOARD_INVOICES_SEND')}
                </Button>
              </Box>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <DeleteDuplicatesModal
        openDeleteDuplicatesConf={openDeleteDuplicatesConf}
        onDeleteDuplicates={onDeleteDuplicates}
        onCancelDeleteDuplicates={onCancelDeleteDuplicates}
      />
      <ManageExportFieldMappings
        format={{ key: selectedDataFormatToExport }}
        exportFieldMappingsOpen={showExportFieldMapping}
        onModalClose={handleExportFieldMappingClose}
        loading={loading}
        fields={fields}
        lineFields={lineFields}
        supplierFields={supplierFields}
        excelFieldMapping={excelExportFieldMapping}
        datFieldMapping={datExportFieldMapping}
        csvFieldMapping={csvExportFieldMapping}
      />
    </Grid>
  );
}

InvoicesListResults.propTypes = {
  className: PropTypes.string,
  getInvoices: PropTypes.func,
  getArchivedInvoices: PropTypes.func,
  changeFilter: PropTypes.func,
  onGetAppInvoiceStats: PropTypes.func,
  updateUser: PropTypes.func,
  time: PropTypes.number,
  setTime: PropTypes.func,
  total: PropTypes.number,
  onStartInterval: PropTypes.func,
  onClearInterval: PropTypes.func,
  invoices: PropTypes.array,
  containsFailedToExport: PropTypes.bool,
  containsPossibleDuplicate: PropTypes.bool,
  containsIsDuplicate: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({}),
      search: PropTypes.func,
    })
  }),
};

InvoicesListResults.defaultProps = {
  getInvoices: () => {},
  invoices: []
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  // onGetInvoiceStatsByUser: (payload) => dispatch(actions.getInvoiceStatsByUser(payload)),
  onGetAppInvoiceStats: (payload) => dispatch(actions.getInvoiceAppStatsByUser(payload)),
  updateUser: (payload) => dispatch(setUserData(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvoicesListResults));
