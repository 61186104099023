import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, TextField,
  Tooltip, IconButton
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  Close as DeleteIcon,
} from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import useStyles from '../style';

function GLAPeriodTable({
  onChangeAllCheckbox, period, isAllCheckboxSelected,
  onChangeValues, onOpenModal, isError, currentIndex,
}) {
  const { t, ready } = useTranslation();
  const classes = useStyles();

  return (
    <Grid>
      <Box minWidth="100%">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{ready && t('GLA_PERIODS_CELL_PERIOD')}</TableCell>
              <TableCell>{ready && t('GLA_PERIODS_CELL_DATE')}</TableCell>
              <TableCell>{ready && t('GLA_PERIODS_CELL_LABEL')}</TableCell>
              <TableCell>
                <Checkbox
                  checked={isAllCheckboxSelected}
                  className={classes.checkbox}
                  icon={<Grid className={classes.checkboxIcon} />}
                  onChange={(e) => onChangeAllCheckbox(e.target.checked)}
                />
                {ready && t('GLA_PERIODS_CELL_IS_OPEN')}
              </TableCell>
              <TableCell title="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              period.map((item, i) => (
                <TableRow key={item.id} hover>
                  <TableCell className={classes.todayText}>
                    <TextField
                      error={isError && currentIndex === i}
                      required
                      margin="dense"
                      variant="outlined"
                      type="number"
                      defaultValue={item?.position}
                      className={classes.inputField}
                      onChange={(e) => {
                        onChangeValues(i, 'position', e.target.value);
                      }}
                      helperText={(isError && currentIndex === i) && t('GLA_PERIOD_POSITION_ALREADY_EXISTS')}
                    />
                  </TableCell>
                  <TableCell className={classes.todayText}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="YYYY-MM-DD"
                      value={item?.toDate}
                      onChange={(val) => {
                        const date = `${val.year()}-${val.month() + 1}-${val.date()}`;
                        onChangeValues(i, 'toDate', date);
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.todayText}>
                    <TextField
                      required
                      variant="outlined"
                      defaultValue={item?.label}
                      className={classes.inputField}
                      onChange={(e) => onChangeValues(i, 'label', e.target.value)}
                    />
                  </TableCell>
                  <TableCell className={classes.todayText}>
                    <Checkbox
                      checked={item?.isOpen}
                      className={classes.checkbox}
                      icon={<Grid className={classes.checkboxIcon} />}
                      onChange={(e) => onChangeValues(i, 'isOpen', e.target.checked)}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={ready && t('GLA_PERIOD_DELETE_RECORD_TOOLTIP')}
                    >
                      <IconButton
                        variant="text"
                        className={classes.bulkDelete}
                        onClick={() => onOpenModal(i)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Box>
    </Grid>
  );
}

GLAPeriodTable.propTypes = {
  onOpenModal: PropTypes.func,
  onChangeValues: PropTypes.func,
  onChangeAllCheckbox: PropTypes.func,
  period: PropTypes.shape({
    map: PropTypes.func,
    indexOf: PropTypes.func,
  }),
  isAllCheckboxSelected: PropTypes.bool,
  isError: PropTypes.bool,
  currentIndex: PropTypes.number,
};

export default GLAPeriodTable;
