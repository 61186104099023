import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '80px !important'
  },
}));

const Page = forwardRef(({
  title,
  children,
  className,
  ...rest
}, ref) => {
  const classes = useStyles();
  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string
};

export default Page;
