import {
  DashboardOutlined as DashboardIcon,
  ViewAgendaOutlined as InvoiceIcon,
  PersonOutlineOutlined as UserIcon,
  BusinessCenterOutlined as DimensionsIcon,
  DeveloperBoardOutlined as IntegrationsIcon
} from '@material-ui/icons';

export const navConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'DASHBOARD_NAV_DASHBOARD',
        icon: DashboardIcon,
        href: '/dashboard'
      },
      {
        title: 'DASHBOARD_NAV_INVOICES',
        icon: InvoiceIcon,
        href: '/documents'
      },
      {
        title: 'DASHBOARD_NAV_DIMENSIONS',
        icon: DimensionsIcon,
        href: '/dimensions',
      },
      {
        title: 'DASHBOARD_NAV_APPS',
        icon: IntegrationsIcon,
        href: '/integrations',
      // },
      // {
      //   title: 'DASHBOARD_NAV_ACCOUNT_SETTING',
      //   href: '/account',
      //   icon: UserIcon
      }
    ]
  }
];

export const navConfigPROD = [
  {
    subheader: '',
    items: [
      {
        title: 'DASHBOARD_NAV_DASHBOARD',
        icon: DashboardIcon,
        href: '/dashboard'
      },
      {
        title: 'DASHBOARD_NAV_INVOICES',
        icon: InvoiceIcon,
        href: '/documents'
      },
      {
        title: 'DASHBOARD_NAV_ACCOUNT_SETTING',
        href: '/account',
        icon: UserIcon
      }
    ]
  }
];

export const navConfigCompany = [
  {
    subheader: '',
    items: [
      {
        title: 'DASHBOARD_NAV_DASHBOARD',
        icon: DashboardIcon,
        href: '/dashboard'
      }
    ]
  }
];
