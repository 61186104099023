import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  axiosHeaders, isActionPermitted, isShowManageFields, permissions
} from 'src/Shared/utils/helpers';
import { useTranslation } from 'react-i18next';
import {
  supplierData, vatCodeTypes
} from 'src/config';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import {
  Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  SettingsOutlined as SettingsIcon,
  Edit as EditIcon,
  AddBox as PlusIcon
} from '@material-ui/icons';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import ConfigContext from 'src/Contexts';
import ManageFields from 'src/Shared/components/ManageFields/ManageFields';
import useStyles from './style';

const VatCodeStandard = (props) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
    touched,
    values,
    vatCode,
    handleClose,
    className,
    hasError,
    handleChangeAutocomplete,
    getLabel,
    costCenters,
    glaccounts,
    onSuccess,
    handleEditCostCenter,
    handleAddCostCenter,
    newlyAddedVal,
    newlyAddingProp,
    ...rest
  } = props;
  const { API } = useContext(ConfigContext);

  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [vatFields, setVatFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const getVatFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/vat/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setVatFields(response.data.data.filter((f) => f.isAvailable));
          setLoading(false);
        } else {
          setVatFields([]);
          setLoading(false);
        }
      }
    } catch (error) {
      setVatFields([]);
      setLoading(false);
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } });
    }
  }, [newlyAddedVal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getVatFields();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFieldAvailable = (field) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };

  return (
    <>
      <form
        className={clsx(classes.root, className)}
        {...rest}
      >
        {loading ? (<LoadingScreen />) : (
          <Card style={{ boxShadow: 'none' }}>
            <CardContent style={{ padding: '20px 0px 0px 0px' }}>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12} key="name">
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && (ready && t(errors.name))}
                    label={ready && t('VAT_CODE_NAME')}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                    className={classes.inputField}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} key="ratePercentage">
                  <TextField
                    error={Boolean(touched.ratePercentage && errors.ratePercentage)}
                    fullWidth
                    helperText={touched.ratePercentage && (ready && t(errors.ratePercentage))}
                    label={ready && t('VAT_CODE_RATE_PERCENTAGE')}
                    name="ratePercentage"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.ratePercentage}
                    variant="outlined"
                    className={classes.inputField}
                  />
                </Grid>
                {
                  vatFields.map((f) => {
                    if (!isFieldAvailable(f)) {
                      return null;
                    }
                    switch (f.key) {
                      case 'type':
                        return (
                          <Grid item lg={6} md={6} xs={12} key={f.key}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onClick={() => setErrors({})}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                classes={{
                                  select: classes.selectStyle
                                }}
                              >
                                {
                                  vatCodeTypes && vatCodeTypes.length > 0
                                  && vatCodeTypes.map((s) => {
                                    if (s) {
                                      return (
                                        <MenuItem key={s.name} value={s.name}>
                                          {t(s.label)}
                                        </MenuItem>
                                      );
                                    }

                                    return null;
                                  })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'ruleCostCenter':
                        return (
                          <Grid item lg={6} md={6} xs={12} key={f.key}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <Autocomplete
                                fullWidth
                                name="ruleCostCenter"
                                onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'ruleCostCenter', v, handleChange)}
                                value={getLabel(costCenters, values.ruleCostCenter)}
                                options={costCenters}
                                getOptionLabel={
                                  (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                                }
                                getOptionSelected={(option) => option.code === (values.ruleCostCenter ? values.ruleCostCenter : ' ')}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    label={ready && t(f.label)}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {values[f.key] !== supplierData.defaultCostCenters[0].code && (
                                            <InputAdornment
                                              position="end"
                                              onClick={() => handleEditCostCenter(values[f.key])}
                                              className={classes.editIconContainer}
                                            >
                                              <EditIcon className={classes.editDimensionIcon} />
                                            </InputAdornment>
                                          )}
                                          <InputAdornment position="end" className={classes.plusIconContainer}>
                                            <PlusIcon className={classes.plusIcon} onClick={() => handleAddCostCenter(f.key)} />
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    className={classes.selectStyle}
                                  />
                                )}
                              />
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'ruleAccount':
                        return (
                          <Grid item lg={6} md={6} xs={12} key={f.key}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <Autocomplete
                                fullWidth
                                name="ruleAccount"
                                onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'ruleAccount', v, handleChange)}
                                value={getLabel(glaccounts, values.ruleAccount)}
                                options={glaccounts}
                                getOptionLabel={
                                  (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                                }
                                getOptionSelected={(option) => option.code === (values.ruleAccount ? values.ruleAccount : ' ')}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    label={ready && t(f.label)}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps
                                    }}
                                    className={classes.selectStyle}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        );
                      default:
                        return (
                          <Tooltip title={(!vatCode || f.key !== 'code') ? '' : t('DIMENSION_CODE_CANNOT_EDIT')}>
                            <Grid item lg={6} md={6} xs={12} key={f.key}>
                              <TextField
                                error={Boolean(touched[f.key] && errors[f.key])}
                                fullWidth
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                label={ready && t(f.label)}
                                name={f.key}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required={f.isRequired}
                                value={values[f.key]}
                                variant="outlined"
                                className={classes.inputField}
                                disabled={vatCode && f.key === 'code'}
                              />
                            </Grid>
                          </Tooltip>
                        );
                    }
                  })
                }
                {errors.submit && (
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                  </Grid>
                )}
                {isShowManageFields(user, 'applyParentFieldsVAT') && (
                  <Grid mt={2} item xs={12}>
                    <Button
                      variant="outlined"
                      className={classes.manageFieldsBtn}
                      onClick={() => setShowManageFields(true)}
                    >
                      <SettingsIcon className={classes.btnIcon} />
                      {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user.permissions)}
                      onClick={handleSubmit}
                    >
                      {ready && t('VAT_CODES_SAVE_CHANGES')}
                    </Button>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={handleClose}
                      className={classes.yearField}
                    >
                      {ready && t('VAT_CODES_CANCEL')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </form>
      <ManageFields
        open={showManageFields}
        entity="vat"
        fields={vatFields}
        fetchFields={getVatFields}
        closeModal={handleCloseManageFields}
        parameterCompany={user.companyID}
      />
    </>
  );
};

VatCodeStandard.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setErrors: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  handleEditSupplierClose: PropTypes.func,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  className: PropTypes.string,
  vatCode: PropTypes.object,
  hasError: PropTypes.func,
  handleChangeAutocomplete: PropTypes.func,
  getLabel: PropTypes.func,
  costCenters: PropTypes.array,
  glaccounts: PropTypes.array,
  onSuccess: PropTypes.func,
  handleEditCostCenter: PropTypes.func,
  handleAddCostCenter: PropTypes.func,
  newlyAddedVal: PropTypes.string,
  newlyAddingProp: PropTypes.string,
};

export default VatCodeStandard;
