import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import useStyles from './style';

function EmptyText({ str }) {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  return (
    <div className={classes.root}>
      <Box width={800}>
        <Typography
          align="center"
          variant="subtitle1"
        >
          {ready && t(str)}
        </Typography>
      </Box>
    </div>
  );
}

EmptyText.propTypes = {
  str: PropTypes.string,
};

export default EmptyText;
