import { makeStyles } from '@material-ui/core';
import { THEMES } from 'src/config';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: 'white'
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {},
    filter: 'drop-shadow(0px 2px 4px rgba(51, 77, 110, 0.1))'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 60
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  menuButton: {
    position: 'absolute',
    color: '#000000',
    padding: 6
  },
  icon: {
    color: '#818E9B'
  },
  notificationIcon: {
    color: '#90A0B7',
  },
  badge: {
    '& span': {
      backgroundColor: '#F7685B',
      top: '28%',
      right: '28%'
    }
  },
  parentList: {
    paddingBottom: 0
  },
  avatar: {
    cursor: 'pointer',
    width: 28,
    height: 28,
    borderRadius: '50%',
    marginRight: 8
  },
  profileSelect: {
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerStatsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 24
  },
  headerStats: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 16px'
  },
  headerSubStats: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 8px'
  },
  statIcon: {
    width: 20,
    height: 20,
    color: '#90A0B7',
    marginRight: 16
  },
  statIconError: {
    width: 20,
    height: 20,
    color: '#EB5757',
    marginRight: 16
  },
  subsIcon: {
    width: 14,
    height: 14,
    color: '#90A0B7',
    marginRight: 8
  },
  statValue: {
    fontSize: 16,
    fontWeight: 500
  },
  subStatValue: {
    fontSize: 10,
    fontWeight: 500
  },
  subscriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 16,
    marginRight: 8
  },
  subscriptionName: {
    fontSize: 10,
    fontWeight: 500,
    padding: 0,
    margin: 0,
    border: 0,
    marginLeft: 0,
    backgroundColor: '#fff',
    color: '#334D6E'
  },
  subsBottom: {
    left: 0,
    top: 0
  },
  version: {
    fontSize: 10,
    color: '#334D6E'
  },
  menu: {
    border: '1px solid #DDDDDD',
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 8%)',
  },
  menuIcon: {
    color: '#3E8AFF',
  },
  badgeTopBar: {
    marginLeft: '50%',
    padding: '8px 11px',
    fontSize: 10,
    borderRadius: 15,
    fontWeight: '600'
  },
  anchorOriginBottomLeftRectangle: {
    bottom: 12
  },
  anchorOriginBottomLeftRectangleMax: {
    bottom: 6
  },
  badgeTopBeta: {
    backgroundColor: '#E65100'
  },
  badgeTopBronze: {
    backgroundColor: '#DD1B23'
  },
  badgeTopSilver: {
    backgroundColor: '#006E9F'
  },
  badgeTopGold: {
    backgroundColor: '#3B4759'
  },
  menuContainer: {
    border: '1px solid #E1E4E8'
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  userInfoMenuitem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 16px',
    fontSize: 14,
    color: '#334D6E'
  },
  menuCompanyContainer: {
    fontSize: 14,
    minWidth: 300,
    padding: '24px 16px',
    border: '0px solid #E1E4E8',
    borderTopWidth: 1,
    backgroundColor: '#d3efff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  companyItems: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '260px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis'
  },
  moreIcon: {
    cursor: 'pointer',
    color: '#334d6f',
    marginRight: 2
  },
  menuActionContainer: {
    fontSize: 14,
    color: '#334D6E',
    border: '0px solid #E1E4E8',
    borderTopWidth: 1
  },
  menuActionContainerSelected: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    border: '0px solid #E1E4E8',
    borderTopWidth: 1
  },
  menuActionContainerTop: {
    fontSize: 14,
    color: '#334D6E'
  },
  menuName: {
    fontSize: 14,
    fontWeight: '500',
    color: '#334D6E',
    paddingLeft: 16
  },
  menuEmail: {
    fontSize: 10,
    color: 'rgba(51, 77, 110, 0.5)',
    paddingLeft: 16,
    textDecoration: 'none'
  },
  menuAction: {
    fontSize: 14,
    color: '#334D6E'
  },
  menuLogout: {
    fontSize: 14,
    color: '#334D6E',
    cursor: 'pointer'
  },
  menuCompany: {
    fontSize: 12,
    color: '#334D6E',
    fontWeight: '500'
  },
  menuCompanyName: {
    fontSize: 14,
    color: '#334D6E',
    fontWeight: '500'
  },
  logoutMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px 0px',
    border: '0px solid #E1E4E8',
    borderTopWidth: 1,
    fontSize: 14,
    color: '#334D6E'
  },
  officeMenuPadding: {
    padding: 0,
  },
  paper: {
    marginLeft: -2,
  },
  snackAction: {
    color: 'white'
  },
  profileBtnRoot: {
    maxWidth: '120px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis'
  },
  profileBtn: {
    display: 'flex',
    flexDirection: 'column',
  },
  companyName: {
    fontSize: 10,
    color: '#334D6E',
    lineHeight: '10px',
  },
  btnRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8
  },
  logoBadgeRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  procys: {
    maxWidth: 100,
    maxHeight: 60
  },
  brandLogo: {
    maxWidth: 120,
    maxHeight: 40
  },
  brandLogoWithTop: {
    maxWidth: 120,
    maxHeight: 40,
    position: 'relative',
    top: -6
  },
  subMenuIcon: {
    marginRight: 8
  },
  kbIcon: {
    color: '#90A0B7'
  }
}));

export default useStyles;
