import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  passwordHelper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  passwordHelperOkay: {
    color:'#f6b44d'
  },
  passwordHelperWeak: {
    color:'#ef4836'
  },
  passwordHelperGood: {
    color:'#2b90ef'
  },
  passwordHelperStrong: {
    color:'#25c281'
  },
  strengthBar: {
    marginTop: '-13px',
    textAlign: 'left!important',
    '& > div': {
      width: '100%',
      textAlign: 'left',
    },
    '& > div > div': {
      height: '4px !important',
      width: '0px!important',
      marginLeft: '0px',
      textAlign: 'left',
    },
  },
  scoreWordsStyle: {
    fontFamily: 'Poppins',
    fontSize: '12px!important',
    fontWeight: '600!important',
    lineHeight: '18px!important',
    letterSpacing: '0.2px',
    textAlign: 'left!important',
  },
  helperText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '16px'
  },
  helperIcon: {
    width: 18,
    height: 18,
    color: '#949499',
    fontSize: '11px',
    fontWeight: '400',
    padding: 2,
    marginLeft: 20,
    marginRight: 6,
    border: '1px solid #E1E2E3',
    borderRadius: 2,
    boxSizing: 'border-box',
  }
}));

export default useStyles;
