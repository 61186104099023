import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Backdrop, Grid, Modal, Typography, IconButton, Button, Switch, TextField, Box, CircularProgress, Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import PdfView from 'src/Invoices/components/PdfView/PdfView';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const SplitMenu = (props) => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    splitMenuOpen, handleClose, invoice, getInvoices
  } = props;

  const [loading, setLoading] = useState(false);
  const [fileResponse, setFileResponse] = useState(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const [imgName, setImgName] = useState('');

  const [fixedRange, setFixedRange] = useState(false);
  const [customRanges, setCustomRanges] = useState([{
    id: 0, start: 1, end: 1, error: null
  }]);
  const [fixedRangeValue, setFixedRangeValue] = useState(2);
  const [aiSplit, setAiSplit] = useState(false);
  const [numPages, setNumPages] = useState(0);

  const closeSplitMenu = () => {
    handleClose();
    setFixedRange(false);
    setCustomRanges([{
      id: 0, start: 1, end: 1, error: null
    }]);
    setFixedRangeValue(2);
    setAiSplit(false);
  };

  const getImage = async (name) => {
    if (!name) {
      return;
    }
    setLoading(true);
    setFetchingFile(true);
    try {
      const response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
      setFetchingFile(false);
      setLoading(false);
    } catch (err) {
      setFileResponse(null);
      setFetchingFile(false);
      setLoading(false);
    }
  };

  const cleanUp = () => {
    setFileResponse(null);
    setImgName('');
    setFixedRange(false);
    setCustomRanges([{
      id: 0, start: 1, end: 1, error: null
    }]);
    setFixedRangeValue(2);
    setAiSplit(false);
    setNumPages(0);
  };

  useEffect(() => {
    const nameParts = invoice?.documentFile?.split('.');
    let imageName = '';
    if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
      imageName = `${nameParts[0]}-display.pdf`;
      setImgName(`${nameParts[0]}-display.pdf`);
    } else {
      imageName = invoice.documentFile;
      setImgName(invoice.documentFile);
    }

    getImage(imageName);

    return cleanUp;
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRangeChange = (e, i, type) => {
    e.preventDefault();
    const newRanges = customRanges.map((r) => {
      const updatedRange = r;
      if (Number(r.end) >= Number(r.start)) {
        updatedRange.error = null;
      }
      return updatedRange;
    });
    if (i !== null || i !== undefined || i !== -1) {
      newRanges[i][type] = e.target.value;
      newRanges[i].error = null;
    }
    if (type === 'start') {
      if (newRanges[i].end !== '' && Number(e.target.value) > Number(newRanges[i].end)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (newRanges[i - 1] && Number(e.target.value) <= Number(newRanges[i - 1].end)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      }
    }
    if (type === 'end') {
      if (newRanges[i].start !== '' && Number(e.target.value) < Number(newRanges[i].start)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (newRanges[i + 1] && Number(e.target.value) >= Number(newRanges[i + 1].start)) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      } else if (Number(e.target.value) > numPages) {
        newRanges[i].error = 'SPLIT_MENU_RANGE_ERROR';
      }
    }
    setCustomRanges(newRanges);
  };

  const addNewRange = () => {
    const newRanges = [...customRanges];
    if (Number(newRanges[newRanges.length - 1].end) === numPages) {
      return;
    }
    newRanges.push({
      id: newRanges.length,
      start: Number(newRanges[newRanges.length - 1].end) + 1,
      end: Number(newRanges[newRanges.length - 1].end) + 1,
      error: null,
    });
    setCustomRanges(newRanges);
  };

  const handleRangeDelete = (i) => {
    if (customRanges.length === 1) {
      return;
    }
    const newRanges = [...customRanges];
    newRanges.splice(i, 1);
    setCustomRanges(newRanges);
  };

  const aiSplitClick = async () => {
    setAiSplit(false);
    setFixedRange(false);
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.aiSplit}${invoice.id.toString()}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        setAiSplit(true);
        const newRanges = [];
        response.data.data.split.forEach((r, i) => {
          newRanges.push({
            id: i,
            start: parseInt(r.start, 10) + 1,
            end: parseInt(r.end, 10),
            error: null,
          });
        });
        setCustomRanges(newRanges);
        setAiSplit(true);
      } else {
        setCustomRanges([{
          id: 0, start: 1, end: 1, error: null
        }]);
      }
    } catch (error) {
      setLoading(false);
      setCustomRanges([{
        id: 0, start: 1, end: 1, error: null
      }]);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t) || appendContactSupport(window.config.support_email, t('AI_SPLIT_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleDocumentSplit = async (splitType, pageCount, pageRange) => {
    setLoading(true);
    try {
      const body = {
        documentID: invoice.id.toString(),
        splitType,
        pageCount: pageCount?.toString() || '1',
        pageRange: pageRange || [],
      };

      const response = await Axios.post(
        API.splitDocument,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        getInvoices();
        enqueueSnackbar(t('SPLIT_DOCUMENT_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        closeSplitMenu();
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('SPLIT_DOCUMENT_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleConfirmClick = () => {
    if (fixedRange) {
      handleDocumentSplit('fixed', fixedRangeValue);
    } else {
      const ranges = customRanges.map((r) => ({
        start: parseInt(r.start, 10) - 1,
        end: parseInt(r.end, 10)
      }));
      handleDocumentSplit('range', '', ranges);
    }
  };

  const renderInvoicePDF = () => (
    <>
      <Box hidden={!isMobile} style={{ height: '100%' }}>
        <PdfView
          t={t}
          ready={ready}
          fileResponse={fileResponse}
          fetchingFile={fetchingFile}
          imgName={imgName}
          canOpenInNewTab
          setNumPages={setNumPages}
        />

      </Box>
      <Box hidden={isMobile} style={{ height: '100%' }}>
        <iframe src={fileResponse} title="pdf" className={classes.pdfFrame} />
      </Box>
    </>
  );

  const splitRequestButtons = (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        onClick={() => handleDocumentSplit('no')}
      >
        {t('INVOICE_SPLIT_REQUEST_DONT_SPLIT')}
      </Button>
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        onClick={() => handleDocumentSplit('fixed', 1)}
      >
        {t('INVOICE_SPLIT_REQUEST_SPLIT_PER_PAGE')}
      </Button>
      <Button
        className={classes.confirmBtn}
        variant="contained"
        color="secondary"
        onClick={() => handleConfirmClick()}
      >
        {t('SPLIT_MENU_CONFIRM')}
      </Button>
    </Box>
  );

  return (
    <>
      <Modal
        className={classes.modal}
        open={splitMenuOpen}
        onClose={() => closeSplitMenu()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <Grid container className={classes.modalHeader}>
            <Grid item className={classes.modalHeaderLeft}>
              <Typography className={classes.headerTitle}>
                {ready && t('SPLIT_MENU_TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={1} md={8} className={classes.modalHeaderRight}>
              <IconButton onClick={() => closeSplitMenu()}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container className={classes.modalContainer}>
            <Grid item xs={12} md={4} className={classes.leftContainer}>
              <Grid className={classes.optionsContainer}>
                <Grid className={classes.lineRow}>
                  <Grid className={classes.documentNameContainer}>
                    <Typography className={classes.documentNameLabel}>{t('SPLIT_MENU_DOCUMENT_NAME')}</Typography>
                    <Typography className={classes.documentName}>{invoice.fileName}</Typography>
                  </Grid>
                  <Grid className={classes.aiSplitContainer}>
                    <Button
                      variant="outlined"
                      className={classes.aiSplitBtn}
                      onClick={aiSplitClick}
                    >
                      {t('SPLIT_MENU_AI_SPLIT_BUTTON')}
                      <span className={classes.newLabel}>{ready && t('SPLIT_MENU_BETA')}</span>
                    </Button>
                  </Grid>
                </Grid>
                <Grid className={classes.lineRow}>
                  <Grid className={classes.lineLeftContainer}>
                    <Typography className={classes.rangeTitleText}>{ready && t('SPLIT_MENU_CUSTOM_RANGES')}</Typography>
                  </Grid>
                  <Switch
                    checked={fixedRange}
                    onChange={() => setFixedRange(!fixedRange)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Grid className={classes.lineRightContainer}>
                    <Typography className={classes.rangeTitleText}>{ready && t('SPLIT_MENU_FIXED_RANGES')}</Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.contentContainer}>
                  {fixedRange ? (
                    <Grid className={classes.rangesContainer}>
                      <Grid className={classes.rangeRow}>
                        <Typography className={classes.rangeText}>{ready && t('SPLIT_MENU_FIXED_RANGE_TITLE')}</Typography>
                        <TextField
                          value={fixedRangeValue}
                          onChange={(e) => setFixedRangeValue(e.target.value)}
                          variant="outlined"
                          className={classes.rangeInput}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid className={classes.rangesContainer}>
                      {aiSplit && (
                      <Grid className={classes.aiSplittedMsgContainer}>
                        <Typography className={classes.aiSplittedMsg}>{ready && t('SPLIT_MENU_AI_MSG')}</Typography>
                      </Grid>
                      )}
                      {customRanges.map((range, i) => (
                        <Grid className={classes.rangeItem} key={range.id}>
                          <Grid className={classes.rangeRow}>
                            <Typography className={classes.rangeText}>{i + 1}</Typography>
                            <Typography className={classes.rangeText}>{ready && t('SPLIT_MENU_FROM_PAGE')}</Typography>
                            <TextField
                              value={range.start}
                              onChange={(e) => handleRangeChange(e, i, 'start')}
                              variant="outlined"
                              className={classes.rangeInput}
                            />
                            <Typography className={classes.rangeText}>{ready && t('SPLIT_MENU_TO_PAGE')}</Typography>
                            <TextField
                              value={range.end}
                              onChange={(e) => handleRangeChange(e, i, 'end')}
                              variant="outlined"
                              className={classes.rangeInput}
                            />
                            <IconButton onClick={() => handleRangeDelete(i)}>
                              <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                          </Grid>
                          {range.error && <Typography className={classes.rangeErrorText}>{ready && t(range.error)}</Typography>}
                        </Grid>
                      ))}
                      <Grid className={classes.lineRow}>
                        <Tooltip title={Number(customRanges[customRanges.length - 1].end) === numPages
                          ? ready && t('SPLIT_MENU_ADD_RANGE_BUTTON_DISABLED_TOOLTIP')
                          : ''}
                        >
                          <span>
                            <Button
                              className={classes.addRangeBtn}
                              variant="contained"
                              color="secondary"
                              onClick={addNewRange}
                              disabled={Number(customRanges[customRanges.length - 1].end) === numPages}
                            >
                              {t('SPLIT_MENU_ADD_RANGE')}
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid className={classes.modalLeftFooter}>
                {splitRequestButtons}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className={classes.invoiceContainer}>
              {renderInvoicePDF()}
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

SplitMenu.propTypes = {
  splitMenuOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  invoice: PropTypes.object,
  getInvoices: PropTypes.func,
};

export default SplitMenu;
