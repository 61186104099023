import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: '24px 40px',
    backgroundColor: 'white',
    paddingTop: theme.spacing(3),
    paddingBottom: 30
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  settingsContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    // width: '100%'
  },
  settingItem: {
    minHeight: 160,
    maxWidth: 220,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #DDDDDD',
    margin: 16,
    padding: '20px 16px',
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      boxShadow: '0 0 6px 1px #3E8AFF',
      transition: 'all 200ms ease-out'
    }
  },
  settingsIcon: {
    width: 72,
    height: 72,
    color: '#3E8AFF',
    marginBottom: 16
  }
}));

export default useStyle;
