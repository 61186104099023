import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  topBar: {
    padding: '10px 40px 0px 40px',
    color: '#ffffff'
  },
  searchInput: {
    textAlign: 'center',
    color: '#fff!important',
    width: '50px',
    marginRight: '5px',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      backgroundColor: '#fcfcfb 0.5',
      color: 'white!important',
    },
    '& .MuiOutlinedInput-root': {
      height: '30px!important',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      },
    },
  },
  buttonCls: {
    cursor: 'pointer',
    marginRight: '2px',
    minWidth: 48,
    color: '#ffffff'
  },
  pdfContainer: {
    backgroundColor: 'gray',
    height: '94%',
    padding: '0px 12px',
    overflow: 'scroll',
    minHeight: '360px'
  },
  pdfContainerZoom: {
    display: 'flex',
    backgroundColor: 'gray',
    alignContent: 'center',
    alignItems: 'center',
    maxHeight: '100%',
    padding: '12px 0px'
  },
  pdfBackground: {
    padding: '12px 12px',
    overflow: 'scroll'
  },
  documentStyle: {
    height: '100%',
    width: '96%'
  },
}));

export default useStyles;
