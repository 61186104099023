import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: 36,
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  bulkOperations: {
    position: 'relative',
    minHeight: 46,
    boxShadow: 'inset 0px -1px 0px rgba(151, 151, 151, 0.25)',
    borderRadius: '4px 4px 0px 0px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  bulkActions: {
    paddingLeft: 16,
    paddingRight: 4,
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  bulkText: {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(51,77,110,0.5)',
    marginLeft: 15,
  },
  bulkDelete: {
    color: '#C2CFE0'
  },
  bulkEdit: {
    marginLeft: theme.spacing(2),
    color: '#C2CFE0'
  },
  actionSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardCont: {
    border: '1px solid #DDDDDD',
    borderRadius: 4,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    marginRight: 24
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  headerSpace: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12
  },
  headerMargin: {
    marginBottom: 12
  },
  tableRow: {
    height: 64,
    textDecoration: 'none',
  },
  pointer: {
    height: 64,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  checkbox: {},
  checkboxIcon: {
    width: 16,
    height: 16,
    border: '2px solid #D5D5D5',
    borderRadius: 3,
  },
  checkCell: {
    alignItems: 'center'
  },
  bottomPagi: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  addBtn: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 0,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)'
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  headerName: {
    color: '#192A3E'
  },
  creditorNameStyle: {
    fontSize: 15,
    color: '#334D6E',
    fontWeight: 500,
    lineHeight: '22.5px'
  },
  currencyText: {
    fontSize: 15,
    color: '#334D6E',
    lineHeight: '25.5px'
  },
  todayText: {
    fontSize: 16,
    color: '#334D6E',
    lineHeight: '24px'
  },
  paginationCaption: {
    fontSize: 13,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  logsIcon: {
    height: 16,
    color: '#C2CFE0'
  },
  paginationActions: {
    marginLeft: 0
  }
}));

export default useStyle;
