import React from 'react';
import { Container } from '@material-ui/core';
import Page from 'src/Shared/components/Page/Page';
import Header from 'src/User/components/CreateHeader/CreateHeader';
import UserCreateForm from 'src/User/components/UserCreateForm/UserCreateForm';

import useStyles from './style';

function UserCreateView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="User Create"
    >
      <Container maxWidth={false}>
        <Header title={'Create new card'}/>
        <UserCreateForm />
      </Container>
    </Page>
  );
}

export default UserCreateView;
