import { makeStyles, alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0!important',
    border: '1px solid #DDDDDD',
    borderRadius: 4,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
  },
  searchBar: {
    flex: 1
  },
  queryField: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    border: '0px solid rgba(194, 207, 224, 1)',
    borderRadius: 4,
    margin: '0px',
    width: '100%',
    minWidth: 120
  },
  statusField: {
    flexBasis: 200
  },
  breakLine: {
    border: '0px solid rgba(151, 151, 151, 0.25)',
    borderBottomWidth: 0
  },
  bulkOperations: {
    position: 'relative',
    minHeight: 46,
    boxShadow: 'inset 0px -1px 0px rgba(151, 151, 151, 0.25)',
    borderRadius: '4px 4px 0px 0px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  bulkActions: {
    paddingLeft: 16,
    paddingRight: 4,
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actionSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  userImage: {
    flexShrink: 0,
    height: 36,
    width: 36
  },
  bulkUBL: {
    marginLeft: 2,
    backgroundColor: 'white',
    padding: '3px 4px',
    fontSize: 13,
    fontWeight: 400,
    color: '#C2CFE0',
    border: 'none',
    boxShadow: 'none',
    minWidth: 30
  },
  bulkDelete: {
    color: '#C2CFE0'
  },
  bulkEdit: {
    marginLeft: theme.spacing(2),
    color: '#C2CFE0'
  },
  filterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  circularProgressIcon: {
    width: 20,
    height: 20
  },
  arrowCell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  pointer: {
    height: 64,
    cursor: 'pointer'
  },
  checkbox: {},
  checkboxIcon: {
    width: 16,
    height: 16,
    border: '2px solid #D5D5D5',
    borderRadius: 3,
  },
  arrow: {
    paddingLeft: 4
  },
  paginationCaption: {
    fontSize: 13,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerMargin: {
    marginBottom: 12
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: 12
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  headerSpace: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    marginRight: 24
  },
  statusElement: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4px 12px',
    opacity: '0.5',
    border: '1px solid #E1E4E8',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    marginTop: 4,
    marginBottom: 4,
    marginRight: 16
  },
  statusElementSelected: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4px 12px',
    border: '1px solid #E1E4E8',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    marginBottom: 8,
    marginRight: 16
  },
  statusText: {
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    whiteSpace: 'nowrap'
  },
  statusTextSelected: {
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    whiteSpace: 'nowrap'
  },
  backBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    color: '#3E8AFF',
    cursor: 'pointer',
    paddingLeft: 0,
    marginBottom: 16,
    textTransform: 'none'
  },
  icon: {
    marginRight: 12
  },
  digitized: {
    color: theme.palette.status.digitized,
    backgroundColor: alpha(theme.palette.statusbg.digitized, 0.3)
  },
  toreview: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  reviewed: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  tovalidate: {
    color: theme.palette.status.tovalidate,
    backgroundColor: alpha(theme.palette.statusbg.tovalidate, 0.3)
  },
  toexport: {
    color: theme.palette.status.toexport,
    backgroundColor: alpha(theme.palette.statusbg.toexport, 0.3)
  },
  exported: {
    color: theme.palette.status.exported,
    backgroundColor: alpha(theme.palette.statusbg.exported, 0.3)
  },
  rejected: {
    color: theme.palette.status.rejected,
    backgroundColor: alpha(theme.palette.statusbg.rejected, 0.3)
  },
  archived: {
    color: theme.palette.status.archived,
    backgroundColor: alpha(theme.palette.statusbg.archived, 0.3)
  },
  errored: {
    color: theme.palette.status.errored,
    backgroundColor: alpha(theme.palette.statusbg.errored, 0.3)
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    minWidth: 160,
    height: 42,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    textTransform: 'none',
    marginLeft: theme.spacing(2),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  searchInput: {
    '&::placeholder': {
      color: '#90A0B7',
      fontSize: 14,
      lineHeight: '21px'
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    paddingRight: 16
  },
  viewsContainer: {
  },
  viewIcon: {
    color: '#C2CFE0'
  },
  viewIconSelected: {
    color: '#3E8EFF'
  },
  filterBtn: {
    fontSize: 14,
    color: '#90A0B7',
    textTransform: 'none'
  },
  todayText: {
    color: '#323C47',
    fontWeight: 600
  },
  bulkText: {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(51,77,110,0.5)',
    marginLeft: 15,
  },
  currencyText: {
    fontWeight: 500
  },
  usernameText: {
    fontSize: 15,
    lineHeight: '22.5px',
    fontWeight: 400,
    color: '#707683'
  },
  moreIcon: {
    height: 16,
    color: '#C2CFE0',
    marginLeft: theme.spacing(2)
  },
  checkCell: {
    alignItems: 'center'
  },
  jiraContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  jiraColumn: {
  },
  columnHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  columnBody: {
    padding: 20,
    maxHeight: 700,
    backgroundColor: 'rgba(194, 207, 224, 0.1)',
    borderRadius: 8,
    overflow: 'scroll'
  },
  statItem: {
    fontSize: 14,
    fontWeight: 600,
    color: 'rgba(129, 142, 155, 1)'
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  filterMenu: {
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 8%)',
    border: '1px solid #DDDDDD'
  },
  filterMenuItem: {
    fontSize: 15,
    color: '#192A3E',
  },
  paginationActions: {
    marginLeft: 0
  }
}));

export default useStyles;
