import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
  Box, Container, Typography, LinearProgress, Grid, Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import Page from 'src/Shared/components/Page/Page';
import authService from 'src/Shared/utils/services/authService';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const InviteUserCallback = (props) => {
  const { BRAND_NAME, LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const { classes } = props;
  const { t, ready } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [loginReq, setLoginReq] = useState('');

  useEffect(() => {
    const { match } = props;
    const token = match?.params?.token || '';

    const executeCallback = async () => {
      setLoading(true);
      setMessage('');
      setLoginReq('');

      try {
        const response = await Axios.get(
          `${API.inviteUserCallback}${token}`,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          // Check if the user is already logged in.
          if (authService.isAuthenticated()) {
            authService.setSession(token, 'user', null);
            window.location.href = '/account';
          } else {
            authService.setSession(null);
            setLoginReq(true);
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.data) {
          setMessage(appendContactSupport(window.config.support_email, getLocalisedErrorString(e?.response?.data?.i18n || 'INVITE_USER_CALL_BACK_ERROR', t), t));
        }
      }
    };

    executeCallback();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const login = () => {
    window.location.href = `${LOGIN_PANEL_URL}?language=${i18next.language.split('-')[0]}`;
  };

  return (
    <Page
      className={classes.root}
      title={ready && t('INVITE_VERIFICATION_PAGE_TITLE', { brand: BRAND_NAME })}
    >
      {
        loading && ready && (
          <Container maxWidth="md">
            <Box
              mb={8}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Box mb={4} display="flex" alignItems="center">
                <Typography variant="h2" color="textPrimary" align="center">
                  {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_PROCESSING')}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="textSecondary" align="center">
                {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_PROCESSING_MESSAGE')}
              </Typography>
              <Box maxWidth={400} width="75%" className={classes.loadingIndicator}>
                <LinearProgress />
              </Box>
            </Box>
          </Container>
        )
      }
      {
        message && ready && (
          <Grid>
            <Typography className={classes.failTitle} align="center">
              {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_FAILED')}
            </Typography>
            <Typography className={classes.failMessage} align="center">
              {message}
            </Typography>
          </Grid>
        )
      }
      {
        loginReq && ready && (
          <Grid className={classes.successContainer}>
            <Typography className={classes.successTitle} align="center">
              {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_SUCCESS')}
            </Typography>
            <Typography className={classes.successMessage} align="center">
              {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_SUCCESS_MESSAGE')}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              className={classes.action}
              onClick={login}
            >
              {ready && t('INVITE_LOGIN')}
            </Button>
          </Grid>
        )
      }
    </Page>
  );
};

InviteUserCallback.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    loadingIndicator: PropTypes.string,
    failTitle: PropTypes.string,
    failMessage: PropTypes.string,
    successTitle: PropTypes.string,
    successMessage: PropTypes.string,
    action: PropTypes.string,
    successContainer: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string
    })
  })
};

InviteUserCallback.defaultProps = {};

export default withStyles(useStyles)(InviteUserCallback);
