import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Button, Grid, IconButton
} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from '../style';

const TwinfieldIntegration = () => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [authURL, setAuthURL] = useState('');
  const [saved, setSaved] = useState(false);
  const [coppied, setCoppied] = useState(false);

  const copyToClipboard = () => {
    copy(authURL, {
      onCopy: setCoppied(true)
    });
  };

  const [loading, setLoading] = useState(false);
  const [appError, setAppError] = useState(false);

  // const [clientID, setClientID] = useState('');
  // const [clientSecret, setClientSecret] = useState('');
  const [companyCode, setCompanyCode] = useState('');

  const getSavedDetails = async () => {
    // setLoading(true);
    try {
      const response = await Axios.get(
        `${API.savedDetailsTf}/${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setCompanyCode(response.data.data.officeCode);
        // setLoading(false);
      } else {
        setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
      }
    } catch (error) {
      setLoading(false);
      setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
    }
  };

  useEffect(() => {
    getSavedDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveNewDetails = async () => {
    // setLoading(true);
    try {
      const response = await Axios.post(
        `${API.savedDetailsTf}/${user.companyID}`,
        {
          officeCode: companyCode
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSaved(true);
        // setLoading(false);
      } else {
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_SAVE_DETAILS'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_SAVE_DETAILS'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onCheckConnection = async () => {
    // setLoading(true);
    try {
      const response = await Axios.get(
        `${API.savedDetailsTf}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_TF_CHECK_CONN_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        // setLoading(false);
      } else {
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('SOMETHING_WENT_WRONG'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onGenerateAuthURL = () => {
    // Save the details
    setAuthURL(
      `https://login.twinfield.com/auth/authentication/connect/authorize?client_id=PROCYS_APP
      &redirect_uri=${window.config.backend.procys_service}v1/integrations/twinfield/token/callback
      &response_type=code&scope=openid+twf.user+twf.organisation+twf.organisationUser+offline_access
      &nonce=qqq&state=${user.email}__BR__${user.companyID}`.replace(/ /g, '')
    );
  };

  if (loading) {
    return null;
  }

  if (appError) {
    return (
      <Grid className={classes.errorContainer}>
        <span className={classes.labelTextError}>
          {appError}
        </span>
      </Grid>
    );
  }

  return (
    <Grid className={classes.detailsContainer}>
      {/* <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>Client ID</span>
          {saved && <Grid className={classes.savedChip}>Saved</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          value={clientID}
          className={classes.inputField}
          onChange={(e) => setClientID(e.target.value)}
        />
      </Grid>
      <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>Client Secret</span>
          {saved && <Grid className={classes.savedChip}>Saved</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          value={clientSecret}
          className={classes.inputField}
          onChange={(e) => setClientSecret(e.target.value)}
        />
      </Grid> */}
      <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>{ready && t('INTEGRATIONS_TF_COMPANY_CODE')}</span>
          {saved && <Grid className={classes.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          value={companyCode}
          className={classes.inputField}
          onChange={(e) => setCompanyCode(e.target.value)}
          onBlur={(e) => saveNewDetails(e.target.value)}
        />
      </Grid>
      <span
        className={classes.linkButton}
        onClick={onGenerateAuthURL}
      >
        {ready && t('INTEGRATIONS_TF_GENERATE_AUTH_URL')}
      </span>
      {authURL && (
        <Grid className={classes.detailRow}>
          <span className={classes.labelText}>{ready && t('INTEGRATIONS_TF_AUTH_URL')}</span>
          <input
            fullWidth
            type="text"
            value={authURL}
            className={classes.inputField}
            disabled
          />
          <IconButton onClick={copyToClipboard}>
            <CopyIcon />
          </IconButton>
          {coppied && <CheckIcon className={classes.checkIcon} />}
        </Grid>
      )}
      <Grid>
        <Button
          variant="outlined"
          className={classes.checkConnectionBtn}
          onClick={onCheckConnection}
        >
          {ready && t('INTEGRATIONS_CHECK_CONNECTION')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TwinfieldIntegration;
