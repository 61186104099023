import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import i18next from 'i18next';

import {
  Box, Container, Typography, LinearProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import Page from 'src/Shared/components/Page/Page';
// import Logo from 'src/Shared/components/Logo/Logo';

import * as actions from 'src/Auth/store/actions/auth';
import authService from 'src/Shared/utils/services/authService';
import {
  loginVerificationFields
} from 'src/config';
import { withTranslation } from 'react-i18next';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

class VerifyLogin extends React.Component {
  async componentDidMount() {
    const { history, onSetUserData } = this.props;
    const { API, LOGIN_PANEL_URL } = this.context;

    if (history.location.search) {
      const search = history.location.search.substring(1);
      const paramsAr = search.split('&');
      const query = {};

      for (let i = 0; i < loginVerificationFields.length; i += 1) {
        for (let j = 0; j < paramsAr.length; j += 1) {
          if (paramsAr[j].indexOf('=') > 0) {
            const [key, val] = paramsAr[j].split('=');
            if (key === loginVerificationFields[i]) {
              query[loginVerificationFields[i]] = val;
            }
          }
        }
      }

      if (query.language) {
        i18next.changeLanguage(query.language);
      }

      if (query.token && query.token.trim() !== '') {
        authService.setSession(query.token, query.role, query.userRef);
        const response = await authService.loginInWithToken(API.verifyToken, query);
        if (response.success && response.data) {
          onSetUserData(response.data);
          if (response.data.avatarName && response.data.avatarName !== '') {
            onSetUserData({
              ...response.data,
              avatar: `${API.getProfileImage}${response.data.avatarName}`
            });
          }
        } else {
          authService.logout(LOGIN_PANEL_URL);
        }

        setTimeout(() => {
          const persistUrl = localStorage.getItem('PROCYS_PERSIST_URL');
          if (persistUrl) {
            window.location.href = persistUrl;
          }
          history.push('/');
        }, 1000);
      } else {
        authService.logout(LOGIN_PANEL_URL);
      }
    } else {
      authService.logout(LOGIN_PANEL_URL);
    }
  }

  render() {
    const { classes, t, tReady } = this.props;
    const { BRAND_NAME } = this.context;
    return (
      <Page
        className={classes.root}
        title={tReady && t('PROCYS_LOGIN_VERIFICATION_PAGE_TITLE')}
      >
        <Container maxWidth="md">
          <Box
            mb={8}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Box mb={4} display="flex" alignItems="center">
              {/* <Logo className={classes.logo} /> */}
              <Typography variant="h2" color="textPrimary" align="center">
                {tReady && t('PROCYS_LOGIN_VERIFICATION_TITLE')}
              </Typography>
            </Box>
            <Typography variant="subtitle1" color="textSecondary" align="center">
              {tReady && t('PROCYS_LOGIN_VERIFICATION_TEXT', { brand: BRAND_NAME })}
            </Typography>
            <Box maxWidth={400} width="75%" className={classes.loadingIndicator}>
              <LinearProgress />
            </Box>
          </Box>
        </Container>
      </Page>
    );
  }
}

VerifyLogin.contextType = ConfigContext;

VerifyLogin.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    logo: PropTypes.string,
    loadingIndicator: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string
    })
  }).isRequired,
  onSetUserData: PropTypes.func.isRequired,
  t: PropTypes.func,
  tReady: PropTypes.bool
};

VerifyLogin.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetUserData: (user) => dispatch(actions.setUserData(user))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(VerifyLogin);
