const useStyles = () => ({
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
  },
  topContainer: {
    paddingLeft: 40,
    paddingRight: 40
  }
});

export default useStyles;
