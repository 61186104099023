import * as actionTypes from 'src/User/store/actions/actionType';

export const updateSubscriptionStart = () => ({
  type: actionTypes.UPDATE_SUBSCRIPTION_START
});

export const updateSubscriptionSuccess = (subscription) => ({
  type: actionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
  subscription,
});

export const updateSubscriptionFail = (error) => ({
  type: actionTypes.UPDATE_SUBSCRIPTION_FAIL,
  error
});

export const updateSubscription = (subscription) => ({
  type: actionTypes.UPDATE_SUBSCRIPTION,
  subscription
});
