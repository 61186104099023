import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const SimpleSelect = ({
  filter, filterOptions, setFilter, label
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {
        label
          && <Typography className={classes.selectLabel}>{label}</Typography>
      }
      <Select
        className={classes.root}
        value={filter}
        onChange={setFilter}
        disableUnderline
      >
        {
          filterOptions && filterOptions.length > 0 && filterOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} className={classes.option}>
              {t(option.label)}
            </MenuItem>
          ))
        }
      </Select>
    </>
  );
};

SimpleSelect.propTypes = {
  filter: PropTypes.number.isRequired,
  filterOptions: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SimpleSelect;
