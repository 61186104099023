/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Formik } from 'formik';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { pdfjs } from 'react-pdf';
import { countries } from 'countries-list';
import {
  Backdrop,
  Box,
  Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useMediaQuery
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DownloadIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileSaver from 'file-saver';

import { useSelector } from 'react-redux';
import {
  amountRelatedFields, invoiceHelperText, invoiceRegex, invoiceTooltips, documentTypes, dateRelatedFields
} from 'src/config';
import {
  validateToken, axiosHeaders, appendContactSupport, getLocalisedErrorString, isOverlapping, formatAmountFields, axiosHeadersWithArrayBuffer, formatDateDDMMYYYY
} from 'src/Shared/utils/helpers';
import authService from 'src/Shared/utils/services/authService';
import { Autocomplete } from '@material-ui/lab';
import ManageFields from 'src/Shared/components/ManageFields/ManageFields';
import Canvas from 'src/XML/components/Canvas/Canvas';
import useIsInViewport from 'src/Shared/utils/hooks/useIsInViewport';
import ConfigContext from 'src/Contexts';
import InvoiceEditFormActionButtons from '../InvoiceEditFormActionButtons/InvoiceEditFormActionButtons';
import AttachedDocuments from '../AttachedDocuments/AttachedDocuments';
import PdfView from '../PdfView/PdfView';
import useStyles from './style';
import CDCMetrics from '../CDCMetrics/CDCMetrics';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CDCDocumentEditForm = (props) => {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const minHeight = useMediaQuery('(min-height: 800px)');
  const { user } = useSelector((state) => state.auth);

  const {
    className,
    invoice,
    setInvoice,
    xml,
    renderHeader,
    xmlReceived,
    handleSelectStatus,
    statusChangeLoading,
    setDuplicate,
    handleChooseValidatorModalOpen,
    isToValidateStatusAllowed,
    isAutoNavigationAllowed,
    previousInvoice,
    nextInvoice,
    navigateInvoice,
    parameterCompany,
    setStatusChangedRef,
    getInvoice,
    ...rest
  } = props;

  const [isSubmitClicked, setIsSubmitClicked] = useState(true);
  const moveToNextRef = useRef(null);
  const setMoveToNext = (val) => {
    moveToNextRef.current = val;
  };
  const [mouseLeftSplitScrnBtn, _setMouseLeftSplitScrnBtn] = useState(true);
  const mouseLeftSplitScrnBtnRef = useRef(mouseLeftSplitScrnBtn);
  const setMouseLeftSplitScrnBtn = (val) => {
    mouseLeftSplitScrnBtnRef.current = val;
    _setMouseLeftSplitScrnBtn(val);
  };
  const [mouseLeftAttachmentsTooltip, _setMouseLeftAttachmentsTooltip] = useState(true);
  const mouseLeftAttachmentsTooltipRef = useRef(mouseLeftAttachmentsTooltip);
  const setMouseLeftAttachmentsTooltip = (val) => {
    mouseLeftAttachmentsTooltipRef.current = val;
    _setMouseLeftAttachmentsTooltip(val);
  };

  const [xmlSelectedValue, setXmlSelectedValue] = useState('');
  const [textFieldSelectedValue, _setTextFieldSelectedValue] = useState('');
  const textFieldSelectedValueRef = useRef(textFieldSelectedValue);
  const setTextFieldSelectedValue = (val) => {
    textFieldSelectedValueRef.current = val;
    _setTextFieldSelectedValue(val);
  };
  const [textFieldSelectedKey, setTextFieldSelectedKey] = useState('');
  const [clickOnCanvasCount, setClickOnCanvasCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showManageFields, setShowManageFields] = useState(false);
  const [deliverFormats, setDeliverFormats] = useState([]);

  const [fileResponse, setFileResponse] = useState(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const splitButtonRef = useRef(null);
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [metricsOpen, setMetricsOpen] = useState(false);

  const [fields, setFields] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [gotoPage, setGotoPage] = useState(1);
  const [imgName, setImgName] = useState('');

  const [words, setWords] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [wordsForLines, setWordsForLines] = useState([]);
  const [page, setPage] = useState([]);
  const [imageBinary, setImageBinary] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const isISODateFormat = user.customisations.includes('isoDate');

  // Regex patterns
  // eslint-disable-next-line
  const dateFormat = /^(((0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d\d)|((19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])))$/;
  const docunectaDateFormat = /^(?:\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}|20\d{2}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})$/;
  const noWhiteSpace = /.*\S.*$/;
  const leadingWhiteSpace = /^\s+.+$/;
  const amountRegex = /^-?\d*[.]?\d+$/;

  const [countriesList, setCountriesList] = useState([]);

  const [detail, setDetail] = useState({});
  const [detailsXML, setDetailsXML] = useState({});
  const [detailsXMLi, setDetailsXMLi] = useState({});
  const [details, setDetails] = useState({
    company: invoice.company,
    email: invoice.email,
    owner: invoice.owner,
    assistantID: invoice.assistantID,
    documentNumber: invoice.documentNumber || '',
    documentDate: invoice.date || '',
    documentType: invoice.documentType || documentTypes.invoice,
    period: invoice.period || '',
    senderCompany: invoice.senderCompany || '',
    senderAddress: invoice.senderAddress || '',
    senderVatNumber: invoice.senderVatNumber || '',
    senderCountryCode: invoice.senderCountryCode || '',
    receiverCompany: invoice.receiverCompany || '',
    receiverAddress: invoice.receiverAddress || '',
    milkTotalVolume: invoice.milkTotalVolume || '',
    milkUnitPrice: invoice.milkUnitPrice || '',
    milkTotalPrice: invoice.milkTotalPrice || '',
    milkTotalAmountNovat: invoice.milkTotalAmountNovat || '',
    filePath: invoice.filePath || '',
    milkFat: invoice.milkFat || '',
    milkFatRef: invoice.milkFatRef || '',
    milkFatAdj: invoice.milkFatAdj || '',
    milkProt: invoice.milkProt || '',
    milkProtRef: invoice.milkProtRef || '',
    milkProtAdj: invoice.milkProtAdj || '',
    milkCell: invoice.milkCell || '',
    milkCellRef: invoice.milkCellRef || '',
    milkCellAdj: invoice.milkCellAdj || '',
    milkBact: invoice.milkBact || '',
    milkBactRef: invoice.milkBactRef || '',
    milkBactAdj: invoice.milkBactAdj || '',
    milkQualAdj: invoice.milkQualAdj || '',
    milkAaAdj: invoice.milkAaAdj || '',
    milkPclAdj: invoice.milkPclAdj || '',
    milkCuotaAdj: invoice.milkCuotaAdj || '',
    milkHomoAdj: invoice.milkHomoAdj || '',
    milkCompAdj: invoice.milkCompAdj || '',
    milkFrioAdj: invoice.milkFrioAdj || '',
    milkSaaAdj: invoice.milkSaaAdj || '',
    milkAssocAdj: invoice.milkAssocAdj || '',
    milkInhib: invoice.milkInhib || '',
    milkInhibAdj: invoice.milkInhibAdj || '',
    milkLoyalAdj: invoice.milkLoyalAdj || '',
    milkHygiAdj: invoice.milkHygiAdj || '',
    milkLabCost: invoice.milkLabCost || '',
    milkLabAdj: invoice.milkLabAdj || '',
    milkLabCostDeducted: invoice.milkLabCostDeducted || '',
    milkCuota: invoice.milkCuota || '',
    milkCuotaDelivered: invoice.milkCuotaDelivered || '',
    milkCuotaRemaining: invoice.milkCuotaRemaining || '',
  });

  const [renderedFields, setRenderedFields] = useState([]);

  const [fieldOpen, setFieldOpen] = useState({
    documentNumber: false,
    documentDate: false,
    period: false,
    senderCompany: false,
    senderAddress: false,
    senderVatNumber: false,
    receiverCompany: false,
    receiverAddress: false,
    milkTotalVolume: false,
    milkUnitPrice: false,
    milkTotalPrice: false,
    milkTotalAmountNovat: false,
    filePath: false,
    milkFat: false,
    milkFatRef: false,
    milkFatAdj: false,
    milkProt: false,
    milkProtRef: false,
    milkProtAdj: false,
    milkCell: false,
    milkCellRef: false,
    milkCellAdj: false,
    milkBact: false,
    milkBactRef: false,
    milkBactAdj: false,
    milkQualAdj: false,
    milkAaAdj: false,
    milkPclAdj: false,
    milkCuotaAdj: false,
    milkHomoAdj: false,
    milkCompAdj: false,
    milkFrioAdj: false,
    milkSaaAdj: false,
    milkAssocAdj: false,
    milkInhib: false,
    milkInhibAdj: false,
    milkLoyalAdj: false,
    milkHygiAdj: false,
    milkLabCost: false,
    milkLabAdj: false,
    milkLabCostDeducted: false,
    milkCuota: false,
    milkCuotaDelivered: false,
    milkCuotaRemaining: false,
  });

  const fieldRef = {
    documentNumber: useRef(null),
    documentDate: useRef(null),
    period: useRef(null),
    senderCompany: useRef(null),
    senderAddress: useRef(null),
    senderVatNumber: useRef(null),
    receiverCompany: useRef(null),
    receiverAddress: useRef(null),
    milkTotalVolume: useRef(null),
    milkUnitPrice: useRef(null),
    milkTotalPrice: useRef(null),
    milkTotalAmountNovat: useRef(null),
    filePath: useRef(null),
    milkFat: useRef(null),
    milkFatRef: useRef(null),
    milkFatAdj: useRef(null),
    milkProt: useRef(null),
    milkProtRef: useRef(null),
    milkProtAdj: useRef(null),
    milkCell: useRef(null),
    milkCellRef: useRef(null),
    milkCellAdj: useRef(null),
    milkBact: useRef(null),
    milkBactRef: useRef(null),
    milkBactAdj: useRef(null),
    milkQualAdj: useRef(null),
    milkAaAdj: useRef(null),
    milkPclAdj: useRef(null),
    milkCuotaAdj: useRef(null),
    milkHomoAdj: useRef(null),
    milkCompAdj: useRef(null),
    milkFrioAdj: useRef(null),
    milkSaaAdj: useRef(null),
    milkAssocAdj: useRef(null),
    milkInhib: useRef(null),
    milkInhibAdj: useRef(null),
    milkLoyalAdj: useRef(null),
    milkHygiAdj: useRef(null),
    milkLabCost: useRef(null),
    milkLabAdj: useRef(null),
    milkLabCostDeducted: useRef(null),
    milkCuota: useRef(null),
    milkCuotaDelivered: useRef(null),
    milkCuotaRemaining: useRef(null),
  };

  const fieldIds = [
    'canvasId2',
    'documentNumber',
    'documentDate',
    'period',
    'senderCompany',
    'senderAddress',
    'senderVatNumber',
    'receiverCompany',
    'receiverAddress',
    'milkTotalVolume',
    'milkUnitPrice',
    'milkTotalPrice',
    'milkTotalAmountNovat',
    'filePath',
    'milkFat',
    'milkFatRef',
    'milkFatAdj',
    'milkProt',
    'milkProtRef',
    'milkProtAdj',
    'milkCell',
    'milkCellRef',
    'milkCellAdj',
    'milkBact',
    'milkBactRef',
    'milkBactAdj',
    'milkQualAdj',
    'milkAaAdj',
    'milkPclAdj',
    'milkCuotaAdj',
    'milkHomoAdj',
    'milkCompAdj',
    'milkFrioAdj',
    'milkSaaAdj',
    'milkAssocAdj',
    'milkInhib',
    'milkInhibAdj',
    'milkLoyalAdj',
    'milkHygiAdj',
    'milkLabCost',
    'milkLabAdj',
    'milkLabCostDeducted',
    'milkCuota',
    'milkCuotaDelivered',
    'milkCuotaRemaining'
  ];

  const isInViewPort = {
    documentDate: useIsInViewport(fieldRef.documentDate),
    period: useIsInViewport(fieldRef.period),
    senderCompany: useIsInViewport(fieldRef.senderCompany),
    senderAddress: useIsInViewport(fieldRef.senderAddress),
    senderVatNumber: useIsInViewport(fieldRef.senderVatNumber),
    receiverCompany: useIsInViewport(fieldRef.receiverCompany),
    receiverAddress: useIsInViewport(fieldRef.receiverAddress),
    milkTotalVolume: useIsInViewport(fieldRef.milkTotalVolume),
    milkUnitPrice: useIsInViewport(fieldRef.milkUnitPrice),
    milkTotalPrice: useIsInViewport(fieldRef.milkTotalPrice),
    milkTotalAmountNovat: useIsInViewport(fieldRef.milkTotalAmountNovat),
    filePath: useIsInViewport(fieldRef.filePath),
    milkFat: useIsInViewport(fieldRef.milkFat),
    milkFatRef: useIsInViewport(fieldRef.milkFatRef),
    milkFatAdj: useIsInViewport(fieldRef.milkFatAdj),
    milkProt: useIsInViewport(fieldRef.milkProt),
    milkProtRef: useIsInViewport(fieldRef.milkProtRef),
    milkProtAdj: useIsInViewport(fieldRef.milkProtAdj),
    milkCell: useIsInViewport(fieldRef.milkCell),
    milkCellRef: useIsInViewport(fieldRef.milkCellRef),
    milkCellAdj: useIsInViewport(fieldRef.milkCellAdj),
    milkBact: useIsInViewport(fieldRef.milkBact),
    milkBactRef: useIsInViewport(fieldRef.milkBactRef),
    milkBactAdj: useIsInViewport(fieldRef.milkBactAdj),
    milkQualAdj: useIsInViewport(fieldRef.milkQualAdj),
    milkAaAdj: useIsInViewport(fieldRef.milkAaAdj),
    milkPclAdj: useIsInViewport(fieldRef.milkPclAdj),
    milkCuotaAdj: useIsInViewport(fieldRef.milkCuotaAdj),
    milkHomoAdj: useIsInViewport(fieldRef.milkHomoAdj),
    milkCompAdj: useIsInViewport(fieldRef.milkCompAdj),
    milkFrioAdj: useIsInViewport(fieldRef.milkFrioAdj),
    milkSaaAdj: useIsInViewport(fieldRef.milkSaaAdj),
    milkAssocAdj: useIsInViewport(fieldRef.milkAssocAdj),
    milkInhib: useIsInViewport(fieldRef.milkInhib),
    milkInhibAdj: useIsInViewport(fieldRef.milkInhibAdj),
    milkLoyalAdj: useIsInViewport(fieldRef.milkLoyalAdj),
    milkHygiAdj: useIsInViewport(fieldRef.milkHygiAdj),
    milkLabCost: useIsInViewport(fieldRef.milkLabCost),
    milkLabAdj: useIsInViewport(fieldRef.milkLabAdj),
    milkLabCostDeducted: useIsInViewport(fieldRef.milkLabCostDeducted),
    milkCuota: useIsInViewport(fieldRef.milkCuota),
    milkCuotaDelivered: useIsInViewport(fieldRef.milkCuotaDelivered),
    milkCuotaRemaining: useIsInViewport(fieldRef.milkCuotaRemaining),
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  const validation = (item, regex, regex2) => {
    let isError = false;
    if (item !== undefined && item !== '' && !!regex) {
      isError = !regex.test(item);
      if (regex2) {
        isError = (!regex.test(item) && !regex2.test(item));
      }
    }

    return isError;
  };

  const getFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/cdc-document/${encodeURIComponent(parameterCompany)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setFields(response.data.data);
        } else {
          setFields([]);
        }
      }
    } catch (error) {
      setFields([]);
    }
  };

  const getImage = async (name) => {
    try {
      setFetchingFile(true);
      const response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
      setFetchingFile(false);
    } catch (err) {
      setFileResponse(null);
      setFetchingFile(false);
    }
  };

  const updateDetailsXML = (obj, name, prop) => {
    if (obj[name] && invoice[prop]) {
      let found = false;
      for (let i = 0; i < obj[name].length; i++) {
        if (obj[name][i].value === invoice[prop]) {
          found = true;
          break;
        }
      }
      if (!found) {
        obj[name].unshift({ value: invoice[prop] });
      }
      return obj[name];
    }

    if (invoice[prop]) {
      return [{ value: invoice[prop] }];
    }

    if (!obj[name] && !invoice[prop]) {
      return [{ value: ' ' }];
    }

    return obj[name];
  };

  const getDeliverFormats = async () => {
    try {
      const response = await Axios.get(
        API.getDeliverFormats,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDeliverFormats(response.data.data);
        }
      }
    } catch (error) {
      setDeliverFormats([]);
    }
  };

  useEffect(() => {
    getFields();
    getDeliverFormats();

    const nameParts = invoice?.documentFile?.split('.');
    let imageName = '';
    if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
      setImgName(`${nameParts[0]}-display.pdf`);
      imageName = `${nameParts[0]}-display.pdf`;
    } else {
      setImgName(invoice.documentFile);
      imageName = invoice.documentFile;
    }

    getImage(imageName);

    const allCountries = Object.keys(countries).map((key) => ({
      isoCode: key,
      name: countries[key]?.name,
    }));
    setCountriesList(allCountries);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (xmlReceived && !isLoaded) {
      const d = {};
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(xml, 'text/html');

      setImageBinary(htmlDoc.getElementsByTagName('jpg_base64'));
      setPage(htmlDoc.getElementsByTagName('page'));

      const all = htmlDoc.getElementsByTagName('*');

      let pageNum = -1;
      const cands = [];
      // Filter the details of html
      for (let i = 0; i < all.length; i++) {
        const listItem = all[i];
        if (listItem.tagName === 'PAGE') {
          pageNum++;
        }
        if (listItem.tagName === 'CAND') {
          const atter = {};
          atter.value = listItem.innerHTML || ' ';
          atter.proba = listItem.getAttribute('proba') || 0;
          atter.xmax = listItem.getAttribute('xmax') || 0;
          atter.xmin = listItem.getAttribute('xmin') || 0;
          atter.ymax = listItem.getAttribute('ymax') || 0;
          atter.ymin = listItem.getAttribute('ymin') || 0;

          cands.push({ ...atter, name: listItem.getAttribute('resp_name'), page: pageNum });

          if (d.hasOwnProperty(listItem.getAttribute('resp_name'))) {
            d[listItem.getAttribute('resp_name')].push(atter);
          } else {
            d[listItem.getAttribute('resp_name')] = [];
            d[listItem.getAttribute('resp_name')].push(atter);
          }
        }
      }

      setCandidates(cands);
      setNumPages(pageNum + 1);

      pageNum = -1;
      let invoiceLine = 0;
      const blocks = [];

      for (let i = 0; i < all.length; i++) {
        const item = all[i];
        if (item.tagName === 'PAGE') {
          pageNum++;
          invoiceLine = 0;
        }
        if (item.tagName === 'INVOICELINE') {
          invoiceLine++;
        }
        if (item.tagName === 'BLOCK') {
          const lineItem = {};
          lineItem.page = pageNum;
          lineItem.lineNo = invoiceLine;
          lineItem.proba = item.getAttribute('resp_proba');
          lineItem.name = item.getAttribute('resp_name');
          lineItem.xmax = item.getAttribute('xmax');
          lineItem.xmin = item.getAttribute('xmin');
          lineItem.ymax = item.getAttribute('ymax');
          lineItem.ymin = item.getAttribute('ymin');
          lineItem.value = item.innerHTML;
          blocks.push(lineItem);
        }
      }

      setLineItems(blocks);

      const specialCands = [];

      cands.forEach((cand) => {
        if (cand.name === 'date') {
          if (!specialCands.some((o) => o.name === 'date')) {
            specialCands.push(cand);
          } else if (specialCands.filter((o) => o.name === 'date')[0]?.proba < cand.proba) {
            specialCands.push(cand);
          }
        } else if (cand.name === 'amount') {
          if (!specialCands.some((o) => o.name === 'amount')) {
            specialCands.push(cand);
          } else if (specialCands.filter((o) => o.name === 'amount')[0]?.proba < cand.proba) {
            specialCands.push(cand);
          }
        }
      });

      pageNum = -1;

      const coords = [];
      // Get the coordinates for the bounding boxes
      for (let i = 0; i < all.length; i++) {
        const item = all[i];
        if (item.tagName === 'PAGE') {
          pageNum++;
        }
        if (item.tagName === 'WORD') {
          const itemPresent = specialCands.find((elem) => isOverlapping(parseFloat(item.getAttribute('xmin')),
            parseFloat(item.getAttribute('ymin')),
            parseFloat(item.getAttribute('xmax')),
            parseFloat(item.getAttribute('ymax')),
            parseFloat(elem.xmin),
            parseFloat(elem.ymin),
            parseFloat(elem.xmax),
            parseFloat(elem.ymax)));

          if (!itemPresent) {
            const point = {};
            point.page = pageNum;
            point.xmax = item.getAttribute('xmax');
            point.xmin = item.getAttribute('xmin');
            point.ymax = item.getAttribute('ymax');
            point.ymin = item.getAttribute('ymin');
            point.value = item.innerHTML;
            coords.push(point);
          } else if (!coords.some((o) => o.name === itemPresent.name)) {
            coords.push(itemPresent);
          }
        }
      }

      setWords(coords);

      const specialLineItems = [];

      blocks.forEach((item) => {
        if (item.name === 'line_name') {
          specialLineItems.push(item);
        }
      });

      pageNum = -1;

      const coordsForLines = [];
      // Get the coordinates for the bounding boxes
      for (let i = 0; i < all.length; i++) {
        const item = all[i];
        if (item.tagName === 'PAGE') {
          pageNum++;
        }
        if (item.tagName === 'WORD') {
          // This is commented at the moment to draw boxes around all words in line edit/add form
          // const itemPresent = specialLineItems.find((elem) => (parseInt(item.getAttribute('xmin'), 10) <= (parseInt(elem.xmin, 10) + 2)
          //     && parseInt(item.getAttribute('xmax'), 10) >= (parseInt(elem.xmax, 10) - 2)
          //     && ((elem.ymin < item.getAttribute('ymin') && item.getAttribute('ymin') < elem.ymax)
          //       || (elem.ymin < item.getAttribute('ymax') && item.getAttribute('ymax') < elem.ymax)))
          //   || ((parseInt(elem.xmin, 10) - 2) <= parseInt(item.getAttribute('xmin'), 10)
          //     && (parseInt(elem.xmax, 10) + 2) >= parseInt(item.getAttribute('xmax'), 10)
          //     && ((item.getAttribute('ymin') < elem.ymin && elem.ymin < item.getAttribute('ymax'))
          //       || (item.getAttribute('ymin') < elem.ymax && elem.ymax < item.getAttribute('ymax')))));

          // if (!itemPresent) {
          const point = {};
          point.page = pageNum;
          point.xmax = item.getAttribute('xmax');
          point.xmin = item.getAttribute('xmin');
          point.ymax = item.getAttribute('ymax');
          point.ymin = item.getAttribute('ymin');
          point.value = item.innerHTML;
          coordsForLines.push(point);
          // }
        }
      }

      setWordsForLines(coordsForLines);

      Object.keys(d).forEach((attr) => {
        const arr = d[attr];
        Object.keys(arr).forEach(() => {
          arr.sort((a, b) => {
            const keyA = new Date(a.proba);
            const keyB = new Date(b.proba);

            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
        });
        detail[attr] = arr[0].value;
      });

      if (d.amount) {
        setIsLoaded(true);

        d.number = updateDetailsXML(d, 'number', 'documentNumber');
        d.date = updateDetailsXML(d, 'date', 'documentDate');
        d.period = updateDetailsXML(d, 'period', 'period');
        d.sender_company = updateDetailsXML(d, 'sender_company', 'senderCompany');
        d.sender_address = updateDetailsXML(d, 'sender_address', 'senderAddress');
        d.sender_VAT_number = updateDetailsXML(d, 'sender_VAT_number', 'senderVatNumber');
        d.receiver_company = updateDetailsXML(d, 'receiver_company', 'receiverCompany');
        d.receiver_address = updateDetailsXML(d, 'receiver_address', 'receiverAddress');
        d.milk_total_volume = updateDetailsXML(d, 'milk_total_volume', 'milkTotalVolume');
        d.milk_unit_price = updateDetailsXML(d, 'milk_unit_price', 'milkUnitPrice');
        d.milk_total_price = updateDetailsXML(d, 'milk_total_price', 'milkTotalPrice');
        d.milk_total_amount_novat = updateDetailsXML(d, 'milk_total_amount_novat', 'milkTotalAmountNovat');
        d.milk_fat = updateDetailsXML(d, 'milk_fat', 'milkFat');
        d.milk_fat_ref = updateDetailsXML(d, 'milk_fat_ref', 'milkFatRef');
        d.milk_fat_adj = updateDetailsXML(d, 'milk_fat_adj', 'milkFatAdj');
        d.milk_prot = updateDetailsXML(d, 'milk_prot', 'milkProt');
        d.milk_prot_ref = updateDetailsXML(d, 'milk_prot_ref', 'milkProtRef');
        d.milk_prot_adj = updateDetailsXML(d, 'milk_prot_adj', 'milkProtAdj');
        d.milk_cell = updateDetailsXML(d, 'milk_cell', 'milkCell');
        d.milk_cell_ref = updateDetailsXML(d, 'milk_cell_ref', 'milkCellRef');
        d.milk_cell_adj = updateDetailsXML(d, 'milk_cell_adj', 'milkCellAdj');
        d.milk_bact = updateDetailsXML(d, 'milk_bact', 'milkBact');
        d.milk_bact_ref = updateDetailsXML(d, 'milk_bact_ref', 'milkBactRef');
        d.milk_bact_adj = updateDetailsXML(d, 'milk_bact_adj', 'milkBactAdj');
        d.milk_qual_adj = updateDetailsXML(d, 'milk_qual_adj', 'milkQualAdj');
        d.milk_aa_adj = updateDetailsXML(d, 'milk_aa_adj', 'milkAaAdj');
        d.milk_pcl_adj = updateDetailsXML(d, 'milk_pcl_adj', 'milkPclAdj');
        d.milk_cuota_adj = updateDetailsXML(d, 'milk_cuota_adj', 'milkCuotaAdj');
        d.milk_homo_adj = updateDetailsXML(d, 'milk_homo_adj', 'milkHomoAdj');
        d.milk_comp_adj = updateDetailsXML(d, 'milk_comp_adj', 'milkCompAdj');
        d.milk_frio_adj = updateDetailsXML(d, 'milk_frio_adj', 'milkFrioAdj');
        d.milk_saa_adj = updateDetailsXML(d, 'milk_saa_adj', 'milkSaaAdj');
        d.milk_assoc_adj = updateDetailsXML(d, 'milk_assoc_adj', 'milkAssocAdj');
        d.milk_inhib = updateDetailsXML(d, 'milk_inhib', 'milkInhib');
        d.milk_inhib_adj = updateDetailsXML(d, 'milk_inhib_adj', 'milkInhibAdj');
        d.milk_loyal_adj = updateDetailsXML(d, 'milk_loyal_adj', 'milkLoyalAdj');
        d.milk_hygi_adj = updateDetailsXML(d, 'milk_hygi_adj', 'milkHygiAdj');
        d.milk_lab_cost = updateDetailsXML(d, 'milk_lab_cost', 'milkLabCost');
        d.milk_lab_adj = updateDetailsXML(d, 'milk_lab_adj', 'milkLabAdj');
        d.milk_lab_cost_deducted = updateDetailsXML(d, 'milk_lab_cost_deducted', 'milkLabCostDeducted');
        d.milk_cuota = updateDetailsXML(d, 'milk_cuota', 'milkCuota');
        d.milk_cuota_delivered = updateDetailsXML(d, 'milk_cuota_delivered', 'milkCuotaDelivered');
        d.milk_cuota_remaining = updateDetailsXML(d, 'milk_cuota_remaining', 'milkCuotaRemaining');

        setDetailsXML(d);
        setDetailsXMLi(d);
        setIsLoaded(true);
      }
    }
  }, [xmlReceived]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (value, prop) => {
    setIsSubmitClicked(false);
    setDetails({ ...details, [prop]: value });
  };

  const handleChangeMultiple = (values, names) => {
    setIsSubmitClicked(false);
    const newDetails = { ...details };
    for (let i = 0; i < values.length; i++) {
      newDetails[names[i]] = values[i];
    }
    setDetails(newDetails);
  };

  const handleChangeXML = (name, values) => {
    setIsSubmitClicked(false);
    // Note: Removed condition - detail.hasOwnProperty(name)
    if (values) {
      detail[name] = values.value;
      setDetail({
        ...detail,
        [name]: values.value
      });
    }
  };

  const handleChangeMain = async (event, prop, name, values) => {
    const formattedVal = { value: values ? values.value : event.target.value };
    if (leadingWhiteSpace.test(formattedVal.value)) {
      formattedVal.value = formattedVal.value.trimStart();
    }

    if (prop === 'documentDate' && !values) {
      handleChange('', prop);
      return;
    }

    if (values && values.value) {
      if (prop === 'documentDate') {
        const d = values.value;

        if (d && d.indexOf('-') === 2) {
          const parts = d.split('-');

          if (parts.length >= 3) {
            handleChangeMultiple([`${parts[2].substring(0, 4)}/${parts[1]}`, values.value], ['period', prop]);
          }
        }
      } else if (prop === 'senderVatNumber') {
        const firstTwo = formattedVal.value.substring(0, 2);
        if (countriesList.some((c) => c.isoCode === firstTwo.toUpperCase())) {
          handleChangeMultiple([firstTwo.toUpperCase(), formattedVal.value.substring(2)], ['senderCountryCode', prop]);
        } else {
          handleChange(formattedVal.value, prop);
        }
      } else {
        handleChange(formattedVal.value, prop);
      }

      handleChangeXML(name, formattedVal);
      return;
    }

    if ((prop === 'senderCountryCode') && values?.isoCode) {
      handleChange(values.isoCode, prop);
      return;
    }

    if (event.target.value === undefined) {
      handleChange('', prop);
      handleChangeXML(name, { value: '' });
    } else {
      handleChange(formattedVal.value, prop);
      handleChangeXML(name, { value: formattedVal.value });
    }
  };

  const handleChangeText = (prop, val, name) => {
    setIsSubmitClicked(false);
    let formattedVal = val;
    formattedVal = val.trimStart();
    if (formattedVal) {
      if (prop === 'date') {
        const d = val;
        let period = '';

        if (d && d.indexOf('-') === 2) {
          const parts = d.split('-');

          if (parts.length >= 3) {
            period = `${parts[2].substring(0, 4)}/${parts[1]}`;
          }
        }

        setDetails({ ...details, [name]: val, period });
        detail[prop] = val;
        setDetail({
          ...detail,
          [prop]: val,
        });
        setDetailsXML({
          ...detailsXML,
          [prop]: detailsXMLi[prop]
            ? [...detailsXMLi[prop], { value: val }]
            : [{ value: val }]
        });

        return;
      }

      setDetails({ ...details, [name]: formattedVal });
      detail[prop] = formattedVal;
      setDetail({
        ...detail,
        [prop]: formattedVal
      });
      setDetailsXML({
        ...detailsXML,
        [prop]: detailsXMLi[prop]
          ? [...detailsXMLi[prop], { value: formattedVal }]
          : [{ value: formattedVal }]
      });

      return;
    }

    setDetails({ ...details, [name]: val });
    detail[prop] = val;
    setDetail({
      ...detail,
      [prop]: val
    });
    setDetailsXML({
      ...detailsXML,
      [prop]: detailsXMLi[prop] ? detailsXMLi[prop] : ['']
    });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setTextFieldSelectedKey('');
      }
    };

    const handleClick = (e) => {
      if (!fieldIds.some((elemId) => elemId === e.target.id)) {
        setTextFieldSelectedKey('');
        if (textFieldSelectedValueRef.current === '') {
          setTextFieldSelectedValue(' ');
        } else {
          setTextFieldSelectedValue('');
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setRenderedFields(fields.filter((f) => f.section === 'header' && f.isAvailable && f.isActive).map((f) => f.key));
  }, [fields]);

  useEffect(() => {
    if (textFieldSelectedKey && xmlSelectedValue.trim() && xmlSelectedValue.trim() !== details[textFieldSelectedKey]) {
      setClickOnCanvasCount(0);
      if (amountRelatedFields.includes(textFieldSelectedKey) && !amountRegex.test(xmlSelectedValue.trim())) {
        handleChange(formatAmountFields(xmlSelectedValue.trim()), textFieldSelectedKey);
      } else if (textFieldSelectedKey === 'documentDate') {
        const d = formatDateDDMMYYYY(xmlSelectedValue);

        if (d && d.indexOf('-') === 2) {
          const parts = d.split('-');

          if (parts.length >= 3) {
            handleChangeMultiple([`${parts[2].substring(0, 4)}/${parts[1]}`, d], ['period', 'documentDate']);
          }
        } else {
          handleChange(xmlSelectedValue, 'documentDate');
        }
      } else if (textFieldSelectedKey === 'dueDate' || textFieldSelectedKey === 'deliveryDate') {
        handleChange(formatDateDDMMYYYY(xmlSelectedValue), textFieldSelectedKey);
      } else if (textFieldSelectedKey === 'senderVatNumber') {
        const firstTwo = xmlSelectedValue.trim().substring(0, 2);
        if (countriesList.some((c) => c.isoCode === firstTwo.toUpperCase())) {
          handleChangeMultiple([firstTwo.toUpperCase(), xmlSelectedValue.trim().substring(2)], ['senderCountryCode', textFieldSelectedKey]);
        } else {
          handleChange(xmlSelectedValue.trim(), textFieldSelectedKey);
        }
      } else {
        handleChange(xmlSelectedValue.trim(), textFieldSelectedKey);
      }
    } else if (!textFieldSelectedKey.trim() && xmlSelectedValue.trim()) {
      let selectedKey = '';
      Object.keys(details).forEach((k) => {
        if (details[k] === xmlSelectedValue.trim() && renderedFields.includes(k)) {
          selectedKey = k;
        }
      });
      setTextFieldSelectedKey(selectedKey);
      if (selectedKey === '') {
        if (clickOnCanvasCount > 0) {
          enqueueSnackbar(t('ANNOTATE_NO_FIELD_SELECTED'), {
            variant: 'warning',
            persist: true
          });
        }
        setClickOnCanvasCount((prevState) => prevState + 1);
      } else {
        setClickOnCanvasCount(0);
      }
    } else if (!xmlSelectedValue.trim()) {
      setTextFieldSelectedKey('');
      setClickOnCanvasCount(0);
    }
  }, [xmlSelectedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (textFieldSelectedKey !== '' && !isInViewPort[textFieldSelectedKey] && fieldRef[textFieldSelectedKey]) {
      fieldRef[textFieldSelectedKey].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [textFieldSelectedKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSplitBtnClick = () => {
    setAttachmentsOpen((prevState) => !prevState);
  };

  const handleAttachmentsClose = () => {
    setAttachmentsOpen(false);
  };

  const handleMouseLeaveSplitScrnBtn = () => {
    setMouseLeftSplitScrnBtn(true);
    setTimeout(() => {
      if (mouseLeftAttachmentsTooltipRef.current && mouseLeftSplitScrnBtnRef.current) {
        handleAttachmentsClose();
      }
    }, 2000);
  };

  const handleMouseLeaveAttachmentsTooltip = () => {
    setMouseLeftAttachmentsTooltip(true);
    setTimeout(() => {
      if (mouseLeftSplitScrnBtnRef.current && mouseLeftAttachmentsTooltipRef.current) {
        handleAttachmentsClose();
      }
    }, 2000);
  };

  const handleAttachmentClick = (attachment) => {
    window.open(`/files/attachment/${attachment.name}`);
    handleAttachmentsClose();
  };

  const isFormInvalid = () => fields.some((f) => f.isAvailable && f.isActive && f.isRequired && details[f.key] === '');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!validateToken()) {
        setLoading(false);
        enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
          variant: 'error',
          persist: true
        });
        setTimeout(() => {
          authService.logout(LOGIN_PANEL_URL);
        }, 2000);
        throw new Error(t('PROCYS_LOGIN_SESSION_EXPIRED'));
      }

      if (isFormInvalid()) {
        setLoading(false);
        enqueueSnackbar(t('INVOICE_EDIT_FORM_SAVE_FILL_REQUIRED'), {
          variant: 'error',
          persist: true
        });
        throw new Error(t('INVOICE_EDIT_FORM_SAVE_FILL_REQUIRED'));
      }

      const resp = await Axios.put(
        `${API.updateInvoice}`,
        details,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (resp.data.success) {
        setInvoice(resp.data.data);
        setDuplicate(resp.data.isDuplicate || false);
        enqueueSnackbar(t('INVOICE_EDIT_FORM_SAVE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        setStatusChangedRef(true);
        setLoading(false);
        if (moveToNextRef.current === -1) {
          navigateInvoice(previousInvoice, -1);
        } else if (moveToNextRef.current === 1 || (isAutoNavigationAllowed && nextInvoice !== null)) {
          navigateInvoice(nextInvoice, 1);
        }
        setMoveToNext(null);
      } else {
        setLoading(false);
        enqueueSnackbar(appendContactSupport(window.config.support_email, t('INVOICE_EDIT_FORM_SAVE_FAIL'), t), {
          variant: 'error',
          persist: true
        });
        throw new Error(t('INVOICE_EDIT_FORM_SAVE_FAIL'));
      }

      setIsSubmitClicked(true);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data) {
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_EDIT_FORM_SAVE_FAIL', t),
          t
        ), {
          variant: 'error',
          persist: true
        });
      }
      throw new Error(t('INVOICE_EDIT_FORM_SAVE_FAIL'));
    }
  };

  const onClickField = (e, prop) => {
    if (xmlReceived && xml === null) {
      return;
    }
    if (e.target.id === prop) {
      setTextFieldSelectedKey(prop);
      setTextFieldSelectedValue(details[prop]);
    }
  };

  const onClickGotoPage = (event) => {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();

      if (gotoPage <= numPages && gotoPage >= 1) {
        setPageNumber(parseInt(gotoPage - 1, 10));
        return;
      }
      setPageNumber(0);
      setGotoPage(1);
    }
  };

  const handleNavigateInvoice = async (toInvoice, direction) => {
    setMoveToNext(direction);
    if (!isSubmitClicked && (invoice.status === 500 || invoice.status === 501 || invoice.status === 509)) {
      try {
        await handleSubmit();
      } catch (error) {
        // do nothing
      }
    } else {
      setMoveToNext(null);
      navigateInvoice(toInvoice, direction);
    }
  };

  const handleAutoSaveInvoice = async (toStatus) => {
    if (!isSubmitClicked) {
      try {
        await handleSubmit();
        if (toStatus === 502) {
          handleSelectStatus(502, invoice.assistantID);
        } else {
          handleChooseValidatorModalOpen();
        }
        return;
      } catch (error) {
        return;
      }
    }
    if (toStatus === 502) {
      handleSelectStatus(502, invoice.assistantID, null);
    } else {
      handleChooseValidatorModalOpen();
    }
  };

  const scrollXMLViewBy = (amount) => {
    const scrollMaxY = document.getElementById('xmlContainer').scrollHeight - document.getElementById('xmlContainer').clientHeight;
    document.getElementById('xmlContainer').scrollBy({ top: amount, behavior: 'smooth' });
    if ((isMobile || !minHeight) && (amount - scrollMaxY) > 100) {
      document.getElementById('formContainer').scrollBy({ top: amount - scrollMaxY, behavior: 'smooth' });
    }
  };

  const nextPage = () => {
    if (numPages > pageNumber + 1) {
      setPageNumber(pageNumber + 1);
      setGotoPage(gotoPage + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber + 1 > 1) {
      setPageNumber(pageNumber - 1);
      setGotoPage(gotoPage - 1);
    }
  };

  // Move between pages with arrow keys and
  // Zoom in, out with minus, equal(plus) keys
  document.onkeydown = (e) => {
    if (e.code === 'ArrowRight') {
      nextPage();
    }
    if (e.code === 'ArrowLeft') {
      prevPage();
    }
  };

  const handleGotoPage = (toPage) => {
    if (toPage <= numPages && toPage >= 1) {
      setGotoPage(parseInt(toPage, 10));
      setPageNumber(parseInt(toPage - 1, 10));
      return;
    }
    setPageNumber(0);
    setGotoPage(1);
  };

  const handleDataFormatToExportClick = async (dataFormat) => {
    let exported = false;
    if (dataFormat.key === 'ubl23' || dataFormat.key === 'ubl21' || dataFormat.key === 'ubl21_be') {
      exported = true;
      try {
        setLoading(true);
        const response = await Axios.get(
          `${API.export}${dataFormat.key}/${invoice.assistantID}`,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        const fileName = details.assistantID.toString().concat('.').concat(dataFormat.fileExtension);
        const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, fileName);
        setStatusChangedRef(true);
        getInvoice();
        setLoading(false);
        if (isAutoNavigationAllowed) {
          handleNavigateInvoice(nextInvoice, 1);
        }
      } catch (error) {
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_EXPORT_DATA_FAILURE', t),
          t
        ), {
          variant: 'error',
          persist: true
        });
        setLoading(false);
      }
    }

    if (dataFormat.key === 'csv') {
      exported = true;
      try {
        setLoading(true);
        const response = await Axios.get(
          `${API.export}${dataFormat.key}/${invoice.assistantID}`,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        const newWindow = window.open(response.data, '_blank');
        const fileName = details.assistantID.toString().concat('.').concat(dataFormat.fileExtension);
        const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, fileName);
        newWindow.close();
        setStatusChangedRef(true);
        getInvoice();
        setLoading(false);
        if (isAutoNavigationAllowed) {
          handleNavigateInvoice(nextInvoice, 1);
        }
      } catch (error) {
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_EXPORT_DATA_FAILURE', t),
          t
        ), {
          variant: 'error',
          persist: true
        });
        setLoading(false);
      }
    }

    if (!exported) {
      try {
        setLoading(true);
        const response = await Axios.get(
          `${API.export}${dataFormat.key}/${invoice.assistantID}`,
          axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken'))
        );
        const fileName = details.assistantID.toString().concat(`.${dataFormat.fileExtension}`);
        const blob = new Blob([response.data], { type: 'blob' });
        FileSaver.saveAs(blob, fileName);
        setStatusChangedRef(true);
        getInvoice();
        setLoading(false);
        if (isAutoNavigationAllowed) {
          handleNavigateInvoice(nextInvoice, 1);
        }
      } catch (error) {
        const buffer = error.response.data;
        const view = new Uint8Array(buffer);
        const decodedObject = String.fromCharCode.apply(null, view);
        const errorObject = JSON.parse(decodedObject);

        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(errorObject?.i18n || 'INVOICE_EXPORT_DATA_FAILURE', t),
          t
        ), {
          variant: 'error',
          persist: true
        });
        getInvoice();
        setLoading(false);
      }
    }
  };

  const getIsError = (key) => {
    if (dateRelatedFields.includes(key)) {
      return validation(
        details[key],
        isISODateFormat ? docunectaDateFormat : dateFormat,
        !isISODateFormat && docunectaDateFormat
      );
    }
    let regexp = invoiceRegex[key] || noWhiteSpace;
    if (amountRelatedFields.includes(key)) {
      regexp = amountRegex;
    }
    const isError = validation(
      details[key],
      regexp
    );
    return isError;
  };

  const renderInvoiceXML = (_xmlSelectedValue, _setXmlSelectedValue, _textFieldSelectedValue, _scrollXMLViewBy, isLine, _textFieldSelectedKey) => (
    <>
      {
        numPages && (
          <Grid id="canvas-top-bar" container direction="row" alignItems="center" justifyContent="space-between" className={classes.topBar}>
            <Grid className={classes.xmlNavBtnsContainer}>
              <TextField
                type="number"
                variant="outlined"
                className={classes.searchInput}
                defaultValue={gotoPage}
                value={gotoPage}
                onChange={(e) => setGotoPage(e.target.value)}
                onKeyPress={(event) => onClickGotoPage(event)}
              />
              <>{`/${numPages}`}</>
              {
                numPages && numPages > 1 && (
                  <>
                    <Button className={classes.buttonCls} disabled={pageNumber === 0} onClick={() => prevPage()}>
                      <NavigateBeforeIcon />
                    </Button>
                    <Button className={classes.buttonCls} disabled={pageNumber === numPages} onClick={() => nextPage()}>
                      <NavigateNextIcon />
                    </Button>
                  </>
                )
              }
            </Grid>
            <Grid className={classes.pdfTop}>
              <Grid className={classes.instructionsContainer}>
                <Typography className={classes.instructions}>
                  {ready && t('ANNOTATE_INTRUCTIONS_LINE_1')}
                  <span className={classes.keyIcon}>shift</span>
                  {ready && t('ANNOTATE_INTRUCTIONS_LINE_2')}
                </Typography>
                <Typography className={classes.instructions}>
                  {ready && t('ANNOTATE_INTRUCTIONS_LINE_3')}
                  <span className={classes.keyIcon}>esc</span>
                  {ready && t('ANNOTATE_INTRUCTIONS_LINE_4')}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Button
                className={classes.buttonCls}
                onClick={() => FileSaver.saveAs(fileResponse, imgName)}
              >
                <DownloadIcon />
              </Button>
              <Button
                className={classes.buttonCls}
                onClick={() => window.open(`/files/image/${imgName}`)}
              >
                <OpenInNewIcon />
              </Button>
            </Grid>
          </Grid>
        )
      }
      <Grid id="xmlContainer" className={classes.pdfContainer}>
        <Box display="flex" justifyContent="center" className={classes.documentStyle}>
          <Canvas
            page={page}
            pageNumber={pageNumber}
            image={imageBinary[pageNumber] ? `data:image/jpeg;base64,${imageBinary[pageNumber]?.innerHTML}` : ''}
            words={isLine ? wordsForLines : words}
            xmlSelectedValue={_xmlSelectedValue}
            setXmlSelectedValue={_setXmlSelectedValue}
            candidates={candidates}
            textFieldSelectedValue={_textFieldSelectedValue}
            scrollXMLViewBy={_scrollXMLViewBy}
            isLine={isLine}
            lineItems={lineItems}
            isOverlapping={isOverlapping}
            topBarHeight={document.getElementById('canvas-top-bar')?.offsetHeight}
            handleGotoPage={handleGotoPage}
            textFieldSelectedKey={_textFieldSelectedKey}
          />
        </Box>
      </Grid>
    </>
  );

  const renderInvoicePDF = () => (
    isMobile ? (
      <PdfView
        t={t}
        ready={ready}
        fileResponse={fileResponse}
        fetchingFile={fetchingFile}
        imgName={imgName}
        canOpenInNewTab
      />
    ) : (
      <iframe src={fileResponse} title="pdf" className={classes.pdfFrame} />
    )
  );

  const renderButtons = (touched) => (
    <InvoiceEditFormActionButtons
      invoice={invoice}
      setSendEmailModalOpen={() => {}}
      isToValidateStatusAllowed={isToValidateStatusAllowed}
      touched={touched}
      handleAutoSaveInvoice={handleAutoSaveInvoice}
      handleSelectStatus={handleSelectStatus}
      statusChangeLoading={statusChangeLoading}
      deliverFormats={deliverFormats}
      handleDataFormatToExportClick={handleDataFormatToExportClick}
      isSubmitClicked={isSubmitClicked}
      details={details}
      isTwinfield={false}
      vatLines={[]}
      esLines={[]}
      handleSubmit={handleSubmit}
      renderedFields={renderedFields}
      getIsError={getIsError}
    />
  );

  const renderNextAndPrevious = () => (
    <Box mt={3} className={classes.navButtonContainer}>
      <Tooltip title={previousInvoice === null ? t('INVOICE_EDIT_FORM_NO_PREV_TOOLTIP') : ''}>
        <div>
          <Button
            variant="outlined"
            className={classes.navButton}
            disabled={previousInvoice === null}
            onClick={() => handleNavigateInvoice(previousInvoice, -1)}
          >
            <ArrowLeftIcon className={classes.btnIconArrowLeft} />
            {ready && t('INVOICE_EDIT_FORM_PREV_BUTTON')}
          </Button>
        </div>
      </Tooltip>
      <Tooltip title={nextInvoice === null ? t('INVOICE_EDIT_FORM_NO_NEXT_TOOLTIP') : ''}>
        <div>
          <Button
            variant="outlined"
            className={classes.navButton}
            disabled={nextInvoice === null}
            onClick={() => handleNavigateInvoice(nextInvoice, +1)}
          >
            {ready && t('INVOICE_EDIT_FORM_NEXT_BUTTON')}
            <ArrowRightIcon className={classes.btnIconArrowRight} />
          </Button>
        </div>
      </Tooltip>
    </Box>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={details}
    >
      {({
        errors,
        handleBlur,
        touched,
        values
      }) => (
        <form
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid id="formContainer" container className={classes.container}>
            <Grid item xs={12} md={4} className={(isMobile || !minHeight) ? classes.leftContainerMobile : classes.leftContainer}>
              {renderHeader()}
              <Grid className={classes.cardContent}>
                <Grid container className={clsx((isMobile || !minHeight) ? classes.inputContainerMobile : classes.inputContainer, 'show-scroll')}>
                  {fields.filter((f) => f.section === 'header').map(({
                    key, label, assistantKey, isRequired, isActive, isAvailable
                  }) => {
                    if (!(isAvailable && isActive)) {
                      return null;
                    }
                    switch (key) {
                      case 'documentType':
                        return (
                          <Tooltip title={t('INVOICE_EDIT_FORM_DOCUMENT_TYPE_TOOLTIP')}>
                            <Grid item xs={12} className={classes.inputItem}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="invoice-type-label">{ready && t('INVOICE_EDIT_FORM_DOCUMENT_TYPE')}</InputLabel>
                                <Select
                                  id="documentType"
                                  labelId="invoice-type-label"
                                  error={Boolean(touched.documentType && errors.documentType)}
                                  helperText={touched.documentType && errors.documentType}
                                  label={ready && t('INVOICE_EDIT_FORM_DOCUMENT_TYPE')}
                                  name="documentType"
                                  onBlur={handleBlur}
                                  onChange={(e) => handleChangeMain(e, 'documentType')}
                                  className={
                                    clsx(
                                      classes.inputText,
                                      (details.documentType === values.documentType || details.documentType === undefined) ? classes.root
                                        : classes.textFieldSelect
                                    )
                                  }
                                  value={details.documentType}
                                  disabled={invoice.status < 500 || !xmlReceived}
                                  required
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left'
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                  }}
                                >
                                  {
                                    [
                                      documentTypes.invoice,
                                      documentTypes.purchaseOrder,
                                      documentTypes.creditNote,
                                      documentTypes.attachment,
                                      documentTypes.receipt
                                    ].map((s) => (
                                      <MenuItem className={classes.inputText} key={s} value={s}>
                                        {t(`CDC_INVOICE_EDIT_FORM_INVOICE_TYPE_${s}`)}
                                      </MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                            </Grid>
                          </Tooltip>
                        );

                      case 'documentDate':
                        return (
                          <Grid item xs={12} className={classes.inputItem}>
                            <Autocomplete
                              id={key}
                              open={fieldOpen[key] && textFieldSelectedKey === key && !validation(
                                details[key],
                                isISODateFormat ? docunectaDateFormat : dateFormat,
                                !isISODateFormat && docunectaDateFormat
                              )}
                              onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                              onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                              required={isRequired}
                              fullWidth
                              name={key}
                              onChange={(e, v) => handleChangeMain(e, key, assistantKey, v)}
                              onBlur={handleBlur}
                              value={details[key]}
                              options={detailsXML[assistantKey] ? detailsXML[assistantKey] : [invoice[key] ? invoice[key] : '']}
                              getOptionLabel={(option) => (option && option.value ? option.value : option)}
                              getOptionSelected={(option) => option.value === (details[key] ? details[key] : detail[assistantKey] ? detail[assistantKey] : ' ')}
                              disabled={invoice.status < 500 || !xmlReceived}
                              renderInput={(params) => (
                                <TextField
                                  innerRef={fieldRef[key]}
                                  fullWidth
                                  error={
                                    validation(
                                      details[key],
                                      isISODateFormat ? docunectaDateFormat : dateFormat,
                                      !isISODateFormat && docunectaDateFormat
                                    )
                                  }
                                  helperText={
                                    (details[key] !== undefined
                                    && details[key] !== '')
                                    && ((touched[key] && errors[key])
                                    || (isISODateFormat
                                      ? (!docunectaDateFormat.test(details[key]) && t('INVALID_DOCUNECTA_DATE_FORMAT_HELPER_TEXT'))
                                      : ((!dateFormat.test(details[key]) && !docunectaDateFormat.test(details[key])) && t('INVALID_DATE_FORMAT_HELPER_TEXT'))))
                                  }
                                  {...params}
                                  label={ready && t(label)}
                                  required={isRequired}
                                  variant="outlined"
                                  onChange={(e) => handleChangeText(assistantKey, e.target.value, key)}
                                  onClick={(e) => onClickField(e, key)}
                                  InputProps={{
                                    ...params.InputProps
                                  }}
                                  className={
                                    textFieldSelectedKey === key ? classes.textFieldHighlighted
                                      : (details[key] === values[key] || details[key] === undefined) ? classes.root
                                        : classes.textFieldSelect
                                  }
                                />
                              )}
                            />
                          </Grid>
                        );

                      case 'senderVatNumber':
                        return (
                          <Grid item xs={12} className={clsx(classes.inputItem, classes.inputItemsContainerRow)}>
                            <Tooltip title={t('INVOICE_EDIT_FORM_SENDER_COUNTRY_CODE_TOOLTIP')}>
                              <Autocomplete
                                id="senderCountryCode"
                                required={isRequired}
                                disableClearable
                                className={classes.countryCodeField}
                                classes={{
                                  paper: classes.countryCodePaper,
                                }}
                                name="senderCountryCode"
                                onChange={(e, v) => handleChangeMain(e, 'senderCountryCode', 'sender_country_code', v)}
                                onBlur={handleBlur}
                                value={details.senderCountryCode ? countriesList.find((o) => o.isoCode === details.senderCountryCode) : null}
                                options={countriesList}
                                getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
                                getOptionSelected={(option) => option.isoCode === (details.senderCountryCode ? details.senderCountryCode : null)}
                                disabled={invoice.status < 500 || !xmlReceived}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    required={isRequired}
                                    variant="outlined"
                                    label={ready && t('INVOICE_EDIT_FORM_SENDER_COUNTRY_CODE')}
                                    InputProps={{
                                      ...params.InputProps
                                    }}
                                  />
                                )}
                              />
                            </Tooltip>
                            <Tooltip title={invoiceTooltips[key] ? t(invoiceTooltips[key]) : ''}>
                              <Autocomplete
                                id={key}
                                open={fieldOpen[key] && textFieldSelectedKey === key && !validation(
                                  details[key],
                                  invoiceRegex[key] || noWhiteSpace
                                )}
                                onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                                onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                                required={isRequired}
                                fullWidth
                                name={key}
                                onChange={(e, v) => handleChangeMain(e, key, assistantKey, v)}
                                onBlur={handleBlur}
                                value={details[key]}
                                options={detailsXML[assistantKey] ? detailsXML[assistantKey] : [invoice[key] ? invoice[key] : '']}
                                getOptionLabel={(option) => (option && option.value ? option.value : option)}
                                getOptionSelected={(option) => option.value === (details[key]
                                  ? details[key] : detail[assistantKey] ? detail[assistantKey] : ' ')}
                                disabled={invoice.status < 500 || !xmlReceived}
                                renderInput={(params) => (
                                  <TextField
                                    innerRef={fieldRef[key]}
                                    fullWidth
                                    error={
                                      validation(
                                        details[key],
                                        invoiceRegex[key] || noWhiteSpace
                                      )
                                    }
                                    helperText={(validation(
                                      details[key],
                                      invoiceRegex[key] || noWhiteSpace
                                    ) && t(invoiceHelperText[key] || 'NO_WHITE_SPACE_HELPER_TEXT'))}
                                    required={isRequired}
                                    {...params}
                                    label={ready && t(label)}
                                    variant="outlined"
                                    onChange={(e) => handleChangeText(assistantKey, e.target.value, key)}
                                    InputProps={{
                                      ...params.InputProps
                                    }}
                                    onClick={(e) => onClickField(e, key)}
                                    className={
                                      textFieldSelectedKey === key ? classes.textFieldHighlighted
                                        : (details[key] === values[key] || details[key] === undefined) ? classes.root
                                          : classes.textFieldSelect
                                    }
                                  />
                                )}
                              />
                            </Tooltip>
                          </Grid>
                        );

                      default:
                        return (
                          <Tooltip title={invoiceTooltips[key] ? t(invoiceTooltips[key]) : ''}>
                            <Grid item xs={12} className={classes.inputItem}>
                              <Autocomplete
                                id={key}
                                open={fieldOpen[key] && textFieldSelectedKey === key && !validation(
                                  details[key],
                                  invoiceRegex[key] || noWhiteSpace
                                )}
                                onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                                onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                                required={isRequired}
                                fullWidth
                                name={key}
                                onChange={(e, v) => handleChangeMain(e, key, assistantKey, v)}
                                onBlur={handleBlur}
                                value={details[key]}
                                options={detailsXML[assistantKey] ? detailsXML[assistantKey] : [invoice[key] ? invoice[key] : '']}
                                getOptionLabel={(option) => (option && option.value ? option.value : option)}
                                getOptionSelected={(option) => option.value === (details[key]
                                  ? details[key] : detail[assistantKey] ? detail[assistantKey] : ' ')}
                                disabled={invoice.status < 500 || !xmlReceived}
                                renderInput={(params) => (
                                  <TextField
                                    innerRef={fieldRef[key]}
                                    fullWidth
                                    error={
                                      validation(
                                        details[key],
                                        invoiceRegex[key] || noWhiteSpace
                                      )
                                    }
                                    helperText={(validation(
                                      details[key],
                                      invoiceRegex[key] || noWhiteSpace
                                    ) && t(invoiceHelperText[key] || 'NO_WHITE_SPACE_HELPER_TEXT'))}
                                    required={isRequired}
                                    {...params}
                                    label={ready && t(label)}
                                    variant="outlined"
                                    onChange={(e) => handleChangeText(assistantKey, e.target.value, key)}
                                    InputProps={{
                                      ...params.InputProps
                                    }}
                                    onClick={(e) => onClickField(e, key)}
                                    className={
                                      textFieldSelectedKey === key ? classes.textFieldHighlighted
                                        : (details[key] === values[key] || details[key] === undefined) ? classes.root
                                          : classes.textFieldSelect
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          </Tooltip>
                        );
                    }
                  })}
                </Grid>
                <Grid item xs={12} className={classes.manageFieldsContainer}>
                  {user?.interfacePreference !== 'twinfield_interface' && !user.customisations.includes('hideManageFields') && (
                    <Button
                      variant="outlined"
                      className={classes.manageFieldsBtn}
                      onClick={() => setShowManageFields(true)}
                    >
                      <SettingsIcon className={classes.btnIcon} />
                      {ready && t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                    </Button>
                  )}
                  {invoice?.attachedDocuments?.length > 0 && (
                    <AttachedDocuments
                      t={t}
                      handleSplitBtnClick={handleSplitBtnClick}
                      splitButtonRef={splitButtonRef}
                      setMouseLeftSplitScrnBtn={setMouseLeftSplitScrnBtn}
                      handleMouseLeaveSplitScrnBtn={handleMouseLeaveSplitScrnBtn}
                      invoice={invoice}
                      attachmentsOpen={attachmentsOpen}
                      setMouseLeftAttachmentsTooltip={setMouseLeftAttachmentsTooltip}
                      handleMouseLeaveAttachmentsTooltip={handleMouseLeaveAttachmentsTooltip}
                      handleAttachmentsClose={handleAttachmentsClose}
                      handleAttachmentClick={handleAttachmentClick}
                    />
                  )}
                  <Typography className={classes.metricsText} onClick={() => setMetricsOpen(true)}>
                    {ready && t('CDC_DOC_EDIT_FORM_METRICS')}
                  </Typography>
                </Grid>
                {renderButtons(touched)}
                {renderNextAndPrevious()}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className={classes.invoiceContainer}>
              {xmlReceived && (xml !== null
                ? renderInvoiceXML(xmlSelectedValue, setXmlSelectedValue, textFieldSelectedValue, scrollXMLViewBy, false, textFieldSelectedKey)
                : renderInvoicePDF())}
            </Grid>
          </Grid>
          <Backdrop className={classes.backdrop} open={!xmlReceived || loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <CDCMetrics
            metricsOpen={metricsOpen}
            handleMetricsClose={() => setMetricsOpen(false)}
            renderInvoiceXML={xmlReceived && xml !== null ? renderInvoiceXML : null}
            renderInvoicePDF={renderInvoicePDF}
            scrollXMLViewBy={scrollXMLViewBy}
            fields={fields}
            className={className}
            details={details}
            detailsXML={detailsXML}
            handleChangeMain={handleChangeMain}
            handleChangeText={handleChangeText}
            invoice={invoice}
            onClickField={onClickField}
            xmlSelectedValue={xmlSelectedValue}
            setXmlSelectedValue={setXmlSelectedValue}
            textFieldSelectedValue={textFieldSelectedValue}
            textFieldSelectedKey={textFieldSelectedKey}
            handleSubmit={handleSubmit}
            fieldOpen={fieldOpen}
            validation={validation}
            setFieldOpen={setFieldOpen}
            fieldRef={fieldRef}
          />
          <ManageFields
            open={showManageFields}
            entity="invoice"
            fields={fields.filter((f) => f.section === 'header')}
            fetchFields={getFields}
            closeModal={handleCloseManageFields}
            parameterCompany={parameterCompany}
          />
        </form>
      )}
    </Formik>
  );
};

CDCDocumentEditForm.propTypes = {
  className: PropTypes.string,
  xml: PropTypes.string,
  invoice: PropTypes.object.isRequired,
  setInvoice: PropTypes.func.isRequired,
  renderHeader: PropTypes.func,
  xmlReceived: PropTypes.bool,
  handleSelectStatus: PropTypes.func,
  statusChangeLoading: PropTypes.bool,
  setDuplicate: PropTypes.func,
  getInvoice: PropTypes.func,
  handleChooseValidatorModalOpen: PropTypes.func,
  isToValidateStatusAllowed: PropTypes.bool,
  isAutoNavigationAllowed: PropTypes.bool,
  previousInvoice: PropTypes.object,
  nextInvoice: PropTypes.object,
  navigateInvoice: PropTypes.func,
  parameterCompany: PropTypes.string,
  setStatusChangedRef: PropTypes.func
};

export default CDCDocumentEditForm;
