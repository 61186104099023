/* eslint-disable no-confusing-arrow */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Box, Button, Card, CardContent, Container, FormHelperText, Grid,
  Tooltip, Backdrop, TextField, FormGroup,
  FormControlLabel, SvgIcon, Modal, Fade, Typography, Checkbox,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon, AddCircle,
} from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { ERRORS, userRoles } from 'src/config';
import Page from 'src/Shared/components/Page/Page';
import Header from 'src/Dimensions/components/Header/Header';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import { axiosHeaders, getLocalisedErrorString, validateToken } from 'src/Shared/utils/helpers';
import authService from 'src/Shared/utils/services/authService';
import ConfigContext from 'src/Contexts';
import GLAPeriodTable from './GLAPeriodTable/GLAPeriodTable';
import useStyles from './style';

function EditGLAPeriod({
  className,
  history,
  match,
  ...rest
}) {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const classes = useStyles();
  const today = moment().format('yyyy-MM-DD');

  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(false);
  const [allChecks, setAllChecks] = useState(false);
  const [isError, setIsError] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [periodArr, setPeriodsArr] = useState([]);
  const [addRecord, setAddRecord] = useState({});

  const getPeriodsByCompany = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.glAPeriodsByCompany}/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        const { data } = response.data;
        data.forEach((item) => {
          if (item.isOpen === undefined) {
            item.isOpen = false; // eslint-disable-line no-param-reassign
          }
        });
        setPeriodsArr(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.accountingSystem === 'twinfield') {
      window.location.href = '/account';
    }

    getPeriodsByCompany();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const gotoCompanySettings = () => {
    history.push('/dimensions/settings');
  };

  const onOpenModal = () => {
    setOpen(true);
    setAddRecord({});
  };

  const onChangeVals = (id, key, value) => {
    const arrChange = [...periodArr];
    setCurrentIndex(id);

    if (isError) { setIsError(false); }

    if (key === 'isOpen' && value === false) {
      setAllChecks(false);
    }

    if (key === 'position') {
      periodArr.forEach((item) => {
        if (item?.position === parseInt(value, 10)) {
          setIsError(true);
        }
      });
    }

    arrChange[id][key] = value;
    setPeriodsArr(arrChange);
  };

  const addPositiondModal = (value) => {
    if (isError) { setIsError(false); }

    periodArr.forEach((item) => {
      if (item?.position === parseInt(value, 10)) {
        setIsError(true);
      }
    });

    setAddRecord({ ...addRecord, position: value });
  };

  const onChangeAllCheckbox = (value) => {
    setAllChecks(value);

    const arrChange = [...periodArr];
    arrChange.forEach((item) => {
      item.isOpen = value; // eslint-disable-line no-param-reassign
    });
    setPeriodsArr(arrChange);
  };

  const onAddPeriod = () => {
    const filter = (item) => (periodArr[periodArr.length - 1]?.[item] ? periodArr[periodArr.length - 1]?.[item] : 0) + 1;

    if (addRecord.position === undefined) {
      addRecord.position = filter('position');
    }
    if (addRecord.id === undefined) {
      addRecord.id = filter('id');
    }
    if (addRecord.toDate === undefined) {
      addRecord.toDate = today;
    }
    if (addRecord.isOpen === undefined) {
      addRecord.isOpen = true;
    }
    if (addRecord.isOpen === false) {
      setAllChecks(false);
    }

    setPeriodsArr((item) => [...item, addRecord]);
    setOpen(false);
  };

  const onOpenDelete = (value) => {
    setDeleteIndex(value);
    setOpenDelete(true);
  };

  const deleteRecord = (e) => {
    e.preventDefault();
    let arrChange = [...periodArr];
    arrChange = arrChange.filter((x, i) => i !== deleteIndex);
    setPeriodsArr(arrChange);
    setOpenDelete(false);
  };

  const deleteModal = (
    <Modal
      className={classes.modal}
      open={openDelete}
      onClose={() => setOpenDelete(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openDelete}>
        <div className={classes.paperModal}>
          <Grid className={classes.modalDeleteHeader}>
            <Typography className={classes.headerTitle}>
              {ready && t('GLA_PERIOD_DELETE_RECORD')}
            </Typography>
          </Grid>
          <Grid className={classes.deleteMessageText}>
            <Typography variant="caption">
              {ready && t('GLA_PERIOD_DELETE_CONFIRMATION_MESSAGE')}
            </Typography>
          </Grid>
          <Grid className={classes.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                className={classes.deleteBtn}
                onClick={deleteRecord}
              >
                {ready && t('GLA_PERIOD_DELETE_CONFIRM')}
              </Button>
              <Button
                variant="text"
                className={classes.closeBtn}
                onClick={() => setOpenDelete(false)}
              >
                {ready && t('GLA_PERIOD_DELETE_CANCEL')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );

  const addButton = (
    <Tooltip
      title={ready && t('GLAS_ADD_GLA_PERIOD_TOOLTIP')}
    >
      <Button
        color="secondary"
        type="submit"
        variant="contained"
        className={classes.addBtn}
        onClick={onOpenModal}
      >
        <SvgIcon
          fontSize="small"
          className={classes.actionIcon}
        >
          <AddCircle />
        </SvgIcon>
        {ready && t('GLAS_ADD_GLA_PERIOD')}
      </Button>
    </Tooltip>
  );

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Page
      className={classes.root}
      title="Settings - GLA Periods"
    >
      <Container maxWidth="xl">
        <Grid className={classes.titleContainer}>
          <Button className={classes.backBtn} onClick={gotoCompanySettings}>
            <ArrowBackIcon className={classes.icon} />
            {ready && t('BACK_TO_COMPANY_SETTINGS')}
          </Button>
        </Grid>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Header title={`Settings - ${ready && t('GENERAL_LEDGER_ACCOUNT_PERIODS')}`} />
          {addButton}
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            periods: periodArr,
            company: user.companyID,
          }}
          onSubmit={async (values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {
              if (!validateToken()) {
                enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                  variant: 'error',
                  persist: true
                });
                setTimeout(() => {
                  authService.logout(LOGIN_PANEL_URL);
                }, 2000);
                return;
              }

              const payload = [];

              values.periods.forEach((item) => {
                const periodData = {
                  position: parseInt(item.position, 10),
                  label: item.label,
                  toDate: item.toDate,
                  isOpen: item.isOpen,
                  company: item.company,
                };

                payload.push(periodData);
              });

              const body = {
                company: values.company,
                periods: payload,
              };

              const url = `${API.glAPeriodsByCompany}/${encodeURIComponent(values.company)}`;
              const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
              if (response?.data?.success) {
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar(ready && t('GLA_PERIODS_UPDATE_SUCCESS'), {
                  variant: 'success',
                  persist: true,
                  style: { maxWidth: 400 }
                });
                gotoCompanySettings();
              }
            } catch (error) {
              setStatus({ success: false });
              setSubmitting(false);
              if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
                const errParts = error.response.data.i18n.split('__TFMSG__');
                setErrors({
                  [errParts[0]]: errParts[1]
                });
              } else {
                setErrors({
                  submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
                });
              }
            }
          }}
        >
          {({
            errors,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card className={classes.cardCont}>
                <GLAPeriodTable
                  period={periodArr}
                  isError={isError}
                  currentIndex={currentIndex}
                  isAllCheckboxSelected={allChecks}
                  onOpenModal={onOpenDelete}
                  onChangeValues={onChangeVals}
                  onChangeAllCheckbox={onChangeAllCheckbox}
                />
                <CardContent style={{ padding: 32 }}>
                  <Grid container spacing={3}>
                    {/* Periods input area */}
                    <Grid
                      item
                      xs={12}
                    >
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <Box mt={2} display="flex" justifyContent="flex-end">
                        <Tooltip title={user.userRole.toLowerCase() !== userRoles.super ? ready && t('GLA_PERIODS_PERMISSIONS') : ''}>
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting || isError}
                            onClick={user.userRole.toLowerCase() !== userRoles.super ? () => {} : handleSubmit}
                          >
                            {ready && t('GLA_PERIODS_SAVE_CHANGES')}
                          </Button>
                        </Tooltip>
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={gotoCompanySettings}
                          className={classes.yearField}
                        >
                          {ready && t('GLA_PERIODS_CANCEL')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <Grid className={classes.modalHeader}>
              <Typography className={classes.headerTitle}>
                {ready && t('ADD_GLA_PERIOD')}
              </Typography>
            </Grid>
            <Grid className={classes.deleteMessageText}>
              <Grid className={classes.modalText}>
                <TextField
                  required
                  error={addRecord?.position === '' || isError}
                  label={ready && t('ADD_GLA_PERIOD_PERIOD')}
                  fullWidth
                  defaultValue={
                    (periodArr[periodArr.length - 1]?.position ? periodArr[periodArr.length - 1]?.position : 0) + 1
                  }
                  type="number"
                  variant="outlined"
                  value={addRecord?.position}
                  onChange={(event) => {
                    addPositiondModal(event.target.value);
                  }}
                  className={classes.inputField}
                  helperText={
                    (isError && t('GLA_PERIOD_POSITION_ALREADY_EXISTS'))
                    || (addRecord?.position === '' && t('GLA_PERIOD_POSITION_CANNOT_BE_EMPTY'))
                  }
                />
              </Grid>
              <Grid className={classes.modalText}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format="yyyy-MM-DD"
                  label={ready && t('ADD_GLA_PERIOD_DATE')}
                  InputAdornmentProps={{ position: 'end' }}
                  defaultValue={today}
                  value={addRecord?.toDate}
                  onChange={(val) => {
                    const date = `${val.year()}-${val.month() + 1}-${val.date()}`;
                    setAddRecord({ ...addRecord, toDate: date });
                  }}
                />
              </Grid>
              <Grid className={classes.modalText}>
                <TextField
                  fullWidth
                  label={ready && t('ADD_GLA_PERIOD_NAME')}
                  variant="outlined"
                  value={addRecord?.label}
                  onChange={(event) => {
                    setAddRecord({ ...addRecord, label: event.target.value });
                  }}
                  className={classes.inputField}
                />
              </Grid>
              <Grid className={classes.modalCheckbox}>
                <FormGroup>
                  <FormControlLabel
                    label={ready && t('ADD_GLA_PERIOD_OPEN')}
                    control={(
                      <Checkbox
                        defaultChecked
                        onChange={(event) => {
                          setAddRecord({ ...addRecord, isOpen: event.target.checked });
                        }}
                        className={classes.checkbox}
                        icon={<Grid className={classes.checkboxIcon} />}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid className={classes.userForm}>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  disabled={addRecord?.position === '' || isError}
                  variant="contained"
                  color="secondary"
                  className={classes.submitBtn}
                  onClick={onAddPeriod}
                >
                  {ready && t('ADD_GLA_PERIOD_SUBMIT')}
                </Button>
                <Button
                  variant="text"
                  className={classes.closeBtn}
                  onClick={() => setOpen(false)}
                >
                  {ready && t('ADD_GLA_PERIOD_CANCEL')}
                </Button>
              </Box>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {deleteModal}
    </Page>
  );
}

EditGLAPeriod.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      company: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  costCenter: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  })
};

export default withRouter(EditGLAPeriod);
