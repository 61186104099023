import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Backdrop, Button, Card, CardContent, Grid, IconButton, Modal, Paper, TextField, Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import Page from 'src/Shared/components/Page/Page';
import IntegrationBox from 'src/Integrations/components/IntegrationBox/IntegrationBox';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import SelectDocument from 'src/Shared/components/SelectDocument/SelectDocument';
import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import {
  appendContactSupport, axiosDeleteHeaders, axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions
} from 'src/Shared/utils/helpers';
import {
  exportFormatTypes, integrations, nonIdApps
} from 'src/config';
import SwitchToTFInterfaceModal from 'src/Integrations/components/SwitchToTFInterfaceModal/SwitchToTFInterfaceModal';
import { setUserData } from 'src/Auth/store/actions/auth';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const AppsView = ({ updateUser }) => {
  const { BRAND_NAME, API } = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [appName, setAppName] = useState('');
  const [details, setDetails] = useState('');
  const [appNameErr, setAppNameErr] = useState('');

  const [appIntegrations, setAppIntegrations] = useState(integrations);
  const [deliverFormats, setDeliverFormats] = useState([]);
  const [exportEmailPrefFormat, setExportEmailPrefFormat] = useState(user?.deliverFormat);
  const [savedExportEmailFormat, setSavedExportEmailFormat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appsError, setAppsError] = useState('');

  const [processing, setProcessing] = useState(false);
  const [processingAppName, setProcessingAppName] = useState(null);

  const [switchToTFModalOpen, setSwitchToTFModalOpen] = useState(false);

  const freeUser = user?.subscription && user.subscription.toLowerCase().indexOf('free') >= 0;
  const isPassportOrID = user.documentType === 'id';

  const getAllIntegrations = async (load) => {
    setLoading(load);
    try {
      const response = await Axios.get(
        `${API.integrations}?company=${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        const appsAr = [];
        for (let i = 0; i < integrations.length; i++) {
          const app = integrations[i];
          app.integrated = false;
          app.active = false;
          for (let j = 0; j < response.data.data.length; j++) {
            const dbApp = response.data.data[j];
            if (app.name === dbApp.app) {
              app.integrated = true;
              app.active = dbApp.active;
            }
          }
          if (!(isPassportOrID && nonIdApps.includes(app.name))) {
            appsAr.push(app);
          }
        }

        if (user.brand === 'schubec') {
          appsAr.unshift(appsAr.splice(1, 1)[0]);
        }

        setAppIntegrations(appsAr);
        setLoading(false);
      } else {
        setAppsError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS_ALL'), t));
      }
    } catch (error) {
      setLoading(false);
      setAppsError(appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS_ALL'), t));
    }
  };

  const getDeliverFormats = async () => {
    try {
      const response = await Axios.get(
        API.getDeliverFormats,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDeliverFormats(response.data.data);
        }
      }
    } catch (error) {
      setDeliverFormats([]);
    }
  };

  useEffect(() => {
    if (
      isActionPermitted(permissions.appsView, user.permissions)
      // && user && user.subscription && user.subscription.toLowerCase() !== 'free'
    ) {
      getAllIntegrations(true);
      getDeliverFormats();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const activeFormat = deliverFormats.find((o) => o.active);
    if (activeFormat) {
      setExportEmailPrefFormat(activeFormat.key);
    } else {
      setExportEmailPrefFormat(exportFormatTypes[0].key);
    }
  }, [deliverFormats]);

  const savePrefFormat = async (value) => {
    setExportEmailPrefFormat(value);

    if (value === '') {
      return;
    }

    try {
      const response = await Axios.post(
        `${API.saveDeliverFormat}`,
        {
          format: value,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSavedExportEmailFormat(true);
      } else {
        setSavedExportEmailFormat(false);
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('INTEGRATIONS_EXPORT_EMAIL_SAVE_ERROR'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setSavedExportEmailFormat(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('INTEGRATIONS_EXPORT_EMAIL_SAVE_ERROR'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const handleChangeFields = (value, prop, setter, errSetter) => {
    if (!value) {
      errSetter(t('INTEGRATIONS_PROP_CANNOT_BE_EMPTY', prop));
    } else {
      errSetter('');
    }

    setter(value);
  };

  const sendNotifiMeRequest = async (app) => {
    setProcessing(true);
    setProcessingAppName(app);
    try {
      const response = await Axios.post(
        API.sendNotifiRequest, { app, companyId: parseInt(user.companyID, 10) }, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_SEND_REQUEST_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setRequestModalOpen(false);
        setProcessing(false);
        setProcessingAppName(null);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('INTEGRATIONS_SEND_REQUEST_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = getLocalisedErrorString(error.response.data.i18n, t);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );

      setProcessing(false);
      setProcessingAppName(null);
    }
  };

  const sendAppRequest = async () => {
    if (!appName) {
      setAppNameErr(t('INTEGRATIONS_APP_CANNOT_BE_EMPTY'));
      return;
    }

    try {
      const body = {
        app: appName,
        details,
        companyId: parseInt(user.companyID, 10)
      };

      const response = await Axios.post(
        API.sendAppRequest, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_SEND_REQUEST_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setRequestModalOpen(false);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('INTEGRATIONS_SEND_REQUEST_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const disableAppRequest = async (name) => {
    setProcessing(true);
    setProcessingAppName(name);

    try {
      const response = await Axios.delete(
        API.integrations,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), { app: name })
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_APP_DELETE_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );

        for (let i = 0; i < appIntegrations.length; i++) {
          const integrationItem = appIntegrations[i];

          if (integrationItem.name === name) {
            integrationItem.active = false;
            integrationItem.integrated = false;
          }
        }

        setAppIntegrations(appIntegrations);

        const currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.delete('feedback-app');
        history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);

        setProcessing(false);
        setProcessingAppName(null);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('INTEGRATIONS_APP_DELETE_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );

      setProcessing(false);
      setProcessingAppName(null);
    }
  };

  const connectRequest = async (app, status, cIntegration, updateCheckBoxStatus, load) => {
    setProcessing(true);
    setProcessingAppName(app);
    try {
      const body = {
        app,
        active: status
      };

      const response = await Axios.post(
        API.integrations, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          status
            ? t('INTEGRATIONS_APP_CONNECT_SUCCESS', { app: t(cIntegration.title) })
            : t('INTEGRATIONS_APP_DISCONNECT_SUCCESS', { app: t(cIntegration.title) }),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );

        if (cIntegration.name === 'exportemail') {
          if (status && !cIntegration.integrated) {
            savePrefFormat(exportEmailPrefFormat);
          } else {
            setSavedExportEmailFormat(false);
          }
        }

        if (status) {
          const currentUrlParams = new URLSearchParams(window.location.search);
          currentUrlParams.set('feedback-app', 'success');
          history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);

          if (app === 'tf' && user.interfacePreference !== 'twinfield_interface') {
            setSwitchToTFModalOpen(true);
          }

          if (app === 'email') {
            const updatedUser = {
              ...user,
              ocrEmail: response.data.data.ocrEmail
            };
            updateUser(updatedUser);
          }
        }

        updateCheckBoxStatus();

        if (load) {
          getAllIntegrations(true);
        } else {
          getAllIntegrations(false);
        }

        setProcessing(false);
        setProcessingAppName(null);
      }
    } catch (error) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, status ? t('INTEGRATIONS_APP_CONNECT_FAILURE') : t('INTEGRATIONS_APP_DISCONNECT_FAILURE'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
      setProcessing(false);
      setProcessingAppName(null);
    }
  };

  const updateInterfacePreference = async () => {
    const updatedUser = {
      ...user,
      interfacePreference: 'twinfield_interface'
    };
    try {
      const response = await Axios.put(
        `${API.userProfile}?&application=procys`,
        updatedUser,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        updateUser(updatedUser);
        setLoading(false);
        setSwitchToTFModalOpen(false);
      } else {
        enqueueSnackbar(appendContactSupport(window.config.support_email, t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_FAILED'), t), {
          variant: 'error',
          persist: true
        });
        setLoading(false);
        setSwitchToTFModalOpen(false);
      }
    } catch (e) {
      enqueueSnackbar(appendContactSupport(window.config.support_email, t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_FAILED'), t), {
        variant: 'error',
        persist: true
      });
      setLoading(false);
      setSwitchToTFModalOpen(false);
    }
  };

  if (loading) {
    return <LoadingScreenText />;
  }

  if (appsError) {
    return <EmptyText str={appsError} />;
  }

  return (
    <Page
      className={classes.root}
      title={ready && t('INTEGRATIONS_APP_PAGE_TITLE')}
    >
      {
        !user.documentType && <SelectDocument open />
      }
      <Card className={classes.container}>
        <CardContent className={classes.wrapper}>
          <Typography className={classes.headerStyle}>
            {ready && t('INTEGRATIONS_APP_PAGE_HEADING')}
          </Typography>
          {
            freeUser && (
              <Grid className={classes.warnContainer}>
                <Typography className={classes.warnTitle}>{ready && t('INTEGRATIONS_SUBSCRIBE_TO_WORK')}</Typography>
                <Typography className={classes.warnText}>
                  {ready && t('INTEGRATIONS_SUITABLE_PLAN')}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push('/account/subscription')}
                  className={classes.warnBtn}
                >
                  {ready && t('INTEGRATIONS_CHOOSE_PLAN')}
                </Button>
              </Grid>
            )
          }
          {
            !isActionPermitted(permissions.appsView, user.permissions) && (
              <Grid className={classes.warnContainer}>
                <Typography className={classes.warnTitle}>{ready && t('INTEGRATIONS_NOT_AUTH')}</Typography>
                <Typography className={classes.warnText}>
                  {ready && t('INTEGRATIONS_NOT_AUTH_DESCRIPTION')}
                </Typography>
              </Grid>
            )
          }
          <Typography className={classes.description}>
            {ready && t('INTEGRATIONS_PAGE_DESCRIPTION', { brand: BRAND_NAME })}
          </Typography>
          {
            appIntegrations.map((i) => (
              <IntegrationBox
                key={i.id}
                integration={i}
                sendNotifyMeRequest={() => sendNotifiMeRequest(i.name)}
                connectRequest={connectRequest}
                deleteApp={() => disableAppRequest(i.name)}
                processing={processing}
                processingAppName={processingAppName}
                deliverFormats={deliverFormats}
                exportEmailPrefFormat={exportEmailPrefFormat}
                savePrefFormat={savePrefFormat}
                savedExportEmailFormat={savedExportEmailFormat}
              />
            ))
          }
          <Grid className={classes.requestContainer}>
            <Typography align="center" className={classes.requestTitle}>
              {ready && t('APPS_PAGE_SEND_REQUEST_TITLE')}
            </Typography>
            <Typography className={classes.requestText}>
              {ready && t('APPS_PAGE_SEND_REQUEST_CAPTION')}
            </Typography>
            <Grid>
              <Button
                variant="outlined"
                className={classes.requestBtn}
                onClick={() => setRequestModalOpen(true)}
              >
                {ready && t('APPS_PAGE_SEND_A_REQUEST')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Modal
        open={requestModalOpen}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.halfPaper}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton
              className={classes.closeBtn}
              onClick={() => setRequestModalOpen(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          </Grid>
          <Grid
            className={classes.modalBody}
          >
            <Typography
              variant="h2"
              className={classes.header}
            >
              {ready && t('INTEGRATIONS_REQUEST_TITLE')}
            </Typography>
            <TextField
              className={classes.fieldStyle}
              fullWidth
              label={ready && t('INTEGRATIONS_REQUEST_APP')}
              name="appName"
              onChange={(e) => handleChangeFields(e.target.value, t('INTEGRATIONS_REQUEST_APP'), setAppName, setAppNameErr)}
              type="text"
              value={appName}
              variant="outlined"
              required
              error={Boolean(appNameErr)}
              helperText={appNameErr}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="filled"
              label={ready && t('INTEGRATIONS_REQUEST_DETAILS')}
              placeholder={ready && t('INTEGRATIONS_REQUEST_DETAILS_PLACEHOLDER')}
              InputProps={{
                style: {
                  paddingTop: 30,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                },
                classes: {
                  input: classes.noPadding
                }
              }}
              onChange={(e) => setDetails(e.target.value)}
            />
            <Grid className={classes.buttonContainer}>
              <Button
                className={classes.sendBtn}
                variant="contained"
                color="secondary"
                onClick={sendAppRequest}
              >
                {ready && t('INTEGRATIONS_REQUEST_SEND')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <SwitchToTFInterfaceModal
        modalOpen={switchToTFModalOpen}
        setModalOpen={setSwitchToTFModalOpen}
        updateInterfacePreference={updateInterfacePreference}
      />
    </Page>
  );
};

AppsView.propTypes = {
  updateUser: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (payload) => dispatch(setUserData(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppsView));
