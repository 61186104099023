import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Button, Grid
} from '@material-ui/core';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import { userRoles } from 'src/config';
import useStyles from '../style';

const Holded = () => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [savedApikey, setSavedApikey] = useState(false);

  const [loading, setLoading] = useState(false);
  const [appError, setAppError] = useState(false);

  const [apikey, setApikey] = useState('');

  const getSavedDetails = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.savedDetailsHolded}/${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setApikey(response.data.data.apikey);
        setLoading(false);
      } else {
        setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
    }
  };

  useEffect(() => {
    getSavedDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveNewDetails = async (key) => {
    setLoading(true);
    try {
      const response = await Axios.post(
        `${API.savedDetailsHolded}/${user.companyID}`,
        {
          apikey: key,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setLoading(false);
        setSavedApikey(true);
        getSavedDetails();
      } else {
        setLoading(false);
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_SAVE_DETAILS'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_SAVE_DETAILS'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onCheckConnection = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.checkHoldedConnection}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_GSTOCK_CONN_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          t('INTEGRATIONS_HOLDED_ERR_CHECK_CONN'),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onSync = async () => {
    setLoading(true);
    try {
      await Axios.post(
        `${API.syncHolded}/${user.companyID}`,
        {},
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setTimeout(() => {
        enqueueSnackbar(
          t('INTEGRATIONS_SYNC_STARTED_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  if (appError) {
    return (
      <Grid className={classes.errorContainer}>
        <span className={classes.labelTextError}>
          {appError}
        </span>
      </Grid>
    );
  }

  return (
    <Grid className={classes.detailsContainer}>
      <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>{ready && t('INTEGRATIONS_HOLDED_API_KEY')}</span>
          {savedApikey && <Grid className={classes.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          value={apikey}
          className={classes.inputField}
          onChange={(e) => setApikey(e.target.value)}
          onBlur={(e) => saveNewDetails(e.target.value)}
          disabled={loading || user.userRole !== userRoles.admin}
        />
      </Grid>
      <Grid className={classes.checkContainer}>
        <Button
          variant="outlined"
          className={classes.checkConnectionBtn}
          onClick={onCheckConnection}
          disabled={loading}
        >
          {ready && t('INTEGRATIONS_CHECK_CONNECTION')}
        </Button>
        <Button
          variant="outlined"
          className={classes.syncBtn}
          onClick={onSync}
          disabled={loading}
        >
          {ready && t('INTEGRATIONS_QUICKBOOKS_SYNC')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Holded;
