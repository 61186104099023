/* eslint-disable no-nested-ternary */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Page from 'src/Shared/components/Page/Page';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';

import authService from 'src/Shared/utils/services/authService';
import {
  appendContactSupport, axiosDeleteHeaders, axiosHeaders, getLocalisedErrorString,
  validateToken, sendFeedback,
} from 'src/Shared/utils/helpers';
import { userRoles } from 'src/config';
import UserCreateForm from 'src/User/components/UserCreateForm/UserCreateForm';
import { setUserData } from 'src/Auth/store/actions/auth';
import InvoiceEditFormXML2 from 'src/Invoices/components/InvoiceEditFormXML2/InvoiceEditFormXML2';
import ChooseValidatorModal from 'src/Invoices/components/ChooseValidatorModal/ChooseValidatorModal';
import DeleteDocumentModal from 'src/Invoices/components/DeleteDocumentModal/DeleteDocumentModal';
import DocumentHeader from 'src/Invoices/components/DocumentHeader/DocumentHeader';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

class InvoiceEditXMLView extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;

    this.state = {
      invoice: null,
      users: [],
      xml: null,
      fields: [],
      openDeleteConf: false,
      invLoaded: false,
      xmlLoaded: false,
      statusChangeLoading: false,
      statusMenuOpen: false,
      usersMenuOpen: false,
      usersMenuAnchor: null,
      chooseValidatorOpen: false,
      validatorOptionsOpen: false,
      selectedValidator: {},
      newlyAddedUser: null,
      addUserOpen: false,
      previousInvoice: null,
      nextInvoice: null,
      isSupplierLabelWithVAT: false,
      isToValidateStatusAllowed: false,
      isAutoNavigationAllowed: false,
      customReceivers: [],
      parameterCompany: user.companyID,
      parameterDocCompany: user.companyID,
    };
    this.statusMenuRef = createRef();
    this.validatorOptionsRef = createRef();
    this.statusChangedRef = createRef();
  }

  componentDidMount() {
    const { enqueueSnackbar, user, t } = this.props;
    const { LOGIN_PANEL_URL } = this.context;

    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    this.setState({
      isSupplierLabelWithVAT: user.customisations.includes('supplierLabelWithVAT'),
      isToValidateStatusAllowed: user.customisations.includes('toValidateStatus'),
      isAutoNavigationAllowed: user.customisations.includes('autoNavigation'),
    });
    this.statusChangedRef.current = false;
    this.loadData();
  }

  updateSelectedValidator = (newValidator) => {
    this.setState({ selectedValidator: newValidator, newlyAddedUser: null });
  };

  setStatusChangedRef = (val) => {
    this.statusChangedRef.current = val;
  };

  loadData = async () => {
    const { user } = this.props;

    this.getXML();
    this.getFields(user.documentType);
    await this.getInvoice();
    this.fetchPrevAndNext();

    if (user.customisations.includes('receiverVatLimitedOptions')) {
      await this.getCustomReceivers();
    }

    await this.getUsers();
  }

  getInvoice = async () => {
    const {
      user, match, history, enqueueSnackbar, t
    } = this.props;
    const { API } = this.context;

    const id = match && match.params ? match.params.id : 0;
    try {
      const resp = await Axios.get(
        `${API.getInvoiceByID}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        if (user.userRole.toLowerCase() === userRoles.annotator) {
          this.setState({ parameterDocCompany: resp.data.data.company });
        }

        this.setState({
          invoice: resp.data.data,
          invLoaded: true
        });

        if (id !== resp.data?.data?.assistantID?.toString()) {
          window.location = window.location.pathname.replace(id, resp.data.data.assistantID);
          return;
        }

        if (resp.data.data.status === 500) {
          sendFeedback(API.feedbackLogs, 'work with invoice', user.email, user.companyID);
        }
      } else {
        history.push('/documents');
      }
    } catch (error) {
      if (error?.response?.data?.i18n) {
        enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
          variant: 'error',
          persist: true
        });
      }
      history.push('/documents');
    }
  };

  getFields = async (entity) => {
    const { API } = this.context;
    const { parameterCompany } = this.state;
    try {
      const response = await Axios.get(
        `${API.fields}/${entity || 'invoice'}/${encodeURIComponent(parameterCompany)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          this.setState({
            fields: response.data.data,
          });
        } else {
          this.setState({
            fields: [],
          });
        }
      }
    } catch (error) {
      this.setState({
        fields: [],
      });
    }
  };

  getInvoiceAfterStatusChange = async () => {
    const {
      match, history, enqueueSnackbar, t
    } = this.props;
    const { API } = this.context;

    const id = match && match.params ? match.params.id : 0;
    try {
      const resp = await Axios.get(
        `${API.getInvoiceByID}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        const { invoice } = this.state;
        const newInvoice = resp.data.data;
        this.setState({
          invoice: {
            ...invoice,
            status: newInvoice.status,
            owner: newInvoice.owner,
            ownerName: newInvoice.ownerName,
            exportFailReason: newInvoice.exportFailReason,
          },
        });
      } else {
        history.push('/documents');
      }
    } catch (error) {
      if (error?.response?.data?.i18n) {
        enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
          variant: 'error',
          persist: true
        });
      }
      history.push('/documents');
    }
  };

  getXML = async () => {
    const { match } = this.props;
    const { API } = this.context;

    const id = match && match.params ? match.params.id : 0;
    try {
      const xmlPage = await Axios.get(
        `${API.getXmlInvoice}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (xmlPage.data.success && xmlPage.data.data) {
        this.setState({
          xml: xmlPage.data.data.xml,
          xmlLoaded: true
        });
      } else {
        this.setState({
          xml: null,
          xmlLoaded: true
        });
      }
    } catch (error) {
      this.setState({
        xml: null,
        xmlLoaded: true
      });
    }
  };

  getCustomReceivers = async () => {
    const { API } = this.context;
    try {
      const response = await Axios.get(
        API.fieldsCustomReceivers,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          this.setState({ customReceivers: response.data.data });
        }
      }
    } catch (error) {
      this.setState({ customReceivers: [] });
    }
  };

  getUsers = async () => {
    const { user } = this.props;
    const { newlyAddedUser, invoice } = this.state;
    const { API } = this.context;
    try {
      const url = `${API.getUsersByCompanies}${encodeURIComponent(user.companyID)}?permission=invoice_validate`;
      const resp = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (resp.data.success) {
        this.setState({
          users: resp.data.data
        });
        const newUser = resp.data.data?.find((u) => u.email === newlyAddedUser?.email);
        const invoiceOwner = resp.data.data?.find((u) => u.email === invoice?.owner);
        if (newlyAddedUser && newUser) {
          this.updateSelectedValidator(newUser);
        } else if (invoiceOwner) {
          this.updateSelectedValidator(invoiceOwner);
        } else {
          this.updateSelectedValidator(resp.data.data[0]);
        }
      }
    } catch (error) {
      //
    }
  };

  getAllUsers = async (user) => {
    const { users } = this.state;
    const { history } = this.props;
    this.setState({ newlyAddedUser: user });
    if (users.length === 1) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('feedback-first-user', 'success');
      history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
    }
    await this.getUsers();
  };

  fetchPrevAndNext = async () => {
    const { history } = this.props;
    const queryVal = new URLSearchParams(history.location.search);
    const invoicePage = queryVal.get('document_page');
    const total = queryVal.get('total');
    if (invoicePage) {
      const currentInvoice = parseInt(invoicePage, 10);
      if (currentInvoice > 1) {
        const previousInvoice = await this.getOtherInvoice(currentInvoice - 1);
        this.setState({ previousInvoice });
      }
      if (currentInvoice < total) {
        const nextInvoice = await this.getOtherInvoice(currentInvoice + 1);
        this.setState({ nextInvoice });
      }
    }
  };

  getOtherInvoice = async (invoicePage) => {
    const { API } = this.context;
    const { history, user } = this.props;
    const queryVal = new URLSearchParams(history.location.search);
    const thisSearch = queryVal.get('query');
    const thisFilter = queryVal.get('status');
    const thisTime = queryVal.get('time');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter'));
    const suppliersList = JSON.parse(window.sessionStorage.getItem('selectedSuppliers'));
    const ownerFilter = JSON.parse(queryVal.get('owner_filter'));
    const ownersList = JSON.parse(window.sessionStorage.getItem('selectedOwners'));
    const thisStartTime = queryVal.get('start_time');
    const thisEndTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export'));
    const sortBy = queryVal.get('sort_by');
    const order = queryVal.get('order');
    let thisSuppliers = '';
    if (supplierFilter && Array.isArray(suppliersList)) {
      thisSuppliers = suppliersList.map((supplier) => supplier.creditorCode).join(',');
    } else if (supplierFilter && suppliersList) {
      thisSuppliers = suppliersList;
    }
    let thisOwners = '';
    if (ownerFilter && Array.isArray(ownersList)) {
      thisOwners = ownersList.map((owner) => owner.email).join(',');
    } else if (ownerFilter && ownersList) {
      thisOwners = ownersList;
    }
    try {
      let url = `${API.getAllInvoices}/${encodeURIComponent(user.companyID)}?page=${invoicePage}&limit=1${thisSearch !== null
        ? `&search=${thisSearch}` : ''}&order=${sortBy
        ? `${order}&sortby=${sortBy}` : 'desc'}${thisFilter !== null
        ? `&filter=${thisFilter}` : ''}${failedToExport
        ? '&failedToExport=true' : ''}`;
      if (thisStartTime !== null && thisEndTime !== null) {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime !== null ? `&time=${thisTime}` : ''}`;
      }
      const body = { suppliers: thisSuppliers, owners: thisOwners };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data.length > 0) {
        return response.data.data[0];
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  setInvoice = (value) => {
    this.setState({ invoice: value });
  }

  gotoInvoiceList = () => {
    const { history } = this.props;

    history.push(`/documents${history.location.search}`);
  };

  handleUserMenuOpen = (event) => {
    this.setState({
      usersMenuOpen: true,
      usersMenuAnchor: event.currentTarget
    });
  };

  handleUserMenuClose = () => {
    this.setState({
      usersMenuOpen: false,
      usersMenuAnchor: null
    });
  };

  handleChooseValidatorModalOpen = () => {
    const { chooseValidatorOpen } = this.state;
    this.setState({ chooseValidatorOpen: !chooseValidatorOpen });
  };

  handleValidatorOptionsToggle = () => {
    const { validatorOptionsOpen } = this.state;
    this.setState({ validatorOptionsOpen: !validatorOptionsOpen });
  };

  handleValidatorOptionsClose = (event) => {
    if (this.validatorOptionsRef.current && this.validatorOptionsRef.current.contains(event.target)) {
      return;
    }
    this.setState({ validatorOptionsOpen: false });
  };

  handleValidatorClick = (validator) => {
    this.setState({ selectedValidator: validator, validatorOptionsOpen: false });
  };

  handleAddUserClick = () => {
    const { user } = this.props;
    if (user.usersCount < user.maxUsers) {
      this.setState({ addUserOpen: true });
    }
  }

  onSendToValidate = () => {
    const { invoice } = this.state;
    this.handleStatusChange(501, invoice.assistantID);
  };

  navigateInvoice = (navTo, increment) => {
    const statusChanged = this.statusChangedRef.current;
    const { history } = this.props;
    const queryVal = new URLSearchParams(history.location.search);
    const viewJira = queryVal.get('viewJira');
    const status = queryVal.get('status');
    const filters = queryVal.get('filters');
    const query = queryVal.get('query');
    const time = queryVal.get('time');
    const thisPage = queryVal.get('page');
    const limit = queryVal.get('limit');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter'));
    const ownerFilter = JSON.parse(queryVal.get('owner_filter'));
    const startTime = queryVal.get('start_time');
    const endTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export'));
    const documentsSelected = JSON.parse(queryVal.get('documents_selected'));
    const invoicePage = JSON.parse(queryVal.get('document_page')) + increment + (increment > 0 && statusChanged && status !== 'INVOICE_STATUS_ALL' ? -1 : 0);
    const total = JSON.parse(queryVal.get('total')) + (statusChanged && status !== 'INVOICE_STATUS_ALL' ? -1 : 0);
    history.push(
      `/documents/${navTo.assistantID}/edit?${viewJira !== null ? `viewJira=${viewJira}` : ''}${status !== null
        ? `&status=${status}` : ''}${filters !== null ? `&filters=${filters}` : ''}${query !== null
        ? `&query=${query}` : ''}${time !== null ? `&time=${time}` : ''}${thisPage !== null ? `&page=${thisPage}` : ''}${limit !== null
        ? `&limit=${limit}` : ''}${supplierFilter ? `&supplier_filter=${supplierFilter}` : ''}${ownerFilter
        ? `&owner_filter=${ownerFilter}` : ''}${startTime !== null ? `&start_time=${startTime}` : ''}${endTime !== null
        ? `&end_time=${endTime}` : ''}${failedToExport ? '&failed_to_export=true' : ''}${documentsSelected
        ? '&documents_selected=true' : ''}&document_page=${invoicePage}&total=${total}`
    );
    history.go(0);
  };

  handleStatusChange = async (status, id, event) => {
    const {
      enqueueSnackbar, t, user, history
    } = this.props;
    const {
      chooseValidatorOpen, selectedValidator, invoice, nextInvoice, isAutoNavigationAllowed
    } = this.state;
    const { API } = this.context;
    this.handleStatusMenuClose(event);

    if (status === 900) {
      this.setState({
        openDeleteConf: true
      });
      return;
    }

    if (status === 501 && !chooseValidatorOpen) {
      this.setState({ chooseValidatorOpen: true });
      return;
    }
    if (chooseValidatorOpen) {
      this.setState({ chooseValidatorOpen: false });
    }

    this.setState({ statusChangeLoading: true });

    try {
      const body = {
        assistantID: parseInt(id, 10),
        toStatus: status.toString()
      };

      if (status === 501 && selectedValidator && selectedValidator.email && selectedValidator.email !== invoice.owner) {
        body.assignee = selectedValidator.email;
      }

      const response = await Axios.post(
        API.documentStatus,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        await this.getInvoice();
        enqueueSnackbar(status === 501
          ? t('INVOICE_STATUS_CHANGE_TO_VALIDATE')
          : (user.isDocunecta && status === 503) ? t('DOCUNECTA_EXPORT_SUCCESS')
            : t('PROCYS_STATUS_CHANGE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        if (status === 501) {
          sendFeedback(API.feedbackLogs, 'send to validate', user.email, user.companyID);
        }
        if (status === 502) {
          sendFeedback(API.feedbackLogs, 'validate invoice', user.email, user.companyID);
        }
        if (status === 503) {
          sendFeedback(API.feedbackLogs, 'invoice export', user.email, user.companyID);
          const currentUrlParams = new URLSearchParams(window.location.search);
          if (!user.exported || user.exported === 0) {
            currentUrlParams.set('feedback-first-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
          if (user.exported === 9) {
            currentUrlParams.set('feedback-tenth-export', 'success');
            history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
        }
        this.statusChangedRef.current = true;
        if (isAutoNavigationAllowed && nextInvoice) {
          this.navigateInvoice(nextInvoice, 1);
        }
      }

      this.setState({ statusChangeLoading: false });
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
        || appendContactSupport(window.config.support_email, t((user.isDocunecta && status === 503)
          ? 'DOCUNECTA_EXPORT_FAILURE' : 'INVOICE_STATUS_CHANGE_FAILED'), t),
      {
        variant: 'error',
        persist: true
      });
      this.getInvoiceAfterStatusChange();
      this.setState({ statusChangeLoading: false });
    }
  };

  onDeleteInvoice = async () => {
    const { invoice } = this.state;
    const {
      enqueueSnackbar, t, user, history
    } = this.props;
    const { API } = this.context;

    try {
      const body = {
        company: user.companyID,
        IDs: [invoice.id.toString()]
      };

      const response = await Axios.delete(
        API.updateInvoice,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body)
      );
      if (response.data.success) {
        enqueueSnackbar(t('INVOICE_DELETE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        history.push('/documents');
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('INVOICE_STATUS_CHANGE_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  onCancelDelete = () => {
    this.setState({ openDeleteConf: false });
  };

  handleStatusMenuToggle = () => {
    const { statusMenuOpen } = this.state;
    this.setState({ statusMenuOpen: !statusMenuOpen });
  };

  handleStatusMenuClose = (event) => {
    if (this.statusMenuRef.current && this.statusMenuRef.current.contains(event?.target)) {
      return;
    }
    this.setState({ statusMenuOpen: false });
  };

  handleChooseValidatorModalClose = () => {
    this.setState({ chooseValidatorOpen: false });
  }

  renderHeader = () => {
    const {
      isToValidateStatusAllowed, statusChangeLoading, users, statusMenuOpen, usersMenuOpen, usersMenuAnchor, invoice
    } = this.state;
    return (
      <DocumentHeader
        invoice={invoice}
        statusMenuOpen={statusMenuOpen}
        users={users}
        statusChangeLoading={statusChangeLoading}
        isToValidateStatusAllowed={isToValidateStatusAllowed}
        gotoInvoiceList={this.gotoInvoiceList}
        statusMenuRef={this.statusMenuRef}
        handleStatusMenuToggle={this.handleStatusMenuToggle}
        handleStatusMenuClose={this.handleStatusMenuClose}
        handleStatusChange={this.handleStatusChange}
        handleUserMenuOpen={this.handleUserMenuOpen}
        usersMenuOpen={usersMenuOpen}
        usersMenuAnchor={usersMenuAnchor}
        handleUserMenuClose={this.handleUserMenuClose}
        getInvoice={this.getInvoice}
      />
    );
  };

  render() {
    const {
      updateUser, classes, t, tReady, user, history
    } = this.props;
    const {
      invoice, fields, xml, invLoaded, xmlLoaded, openDeleteConf, users, statusChangeLoading, chooseValidatorOpen, validatorOptionsOpen,
      selectedValidator, addUserOpen, previousInvoice, nextInvoice, parameterCompany, isToValidateStatusAllowed,
      isAutoNavigationAllowed, isSupplierLabelWithVAT, customReceivers, parameterDocCompany
    } = this.state;

    if (invLoaded) {
      return (
        <Page
          className={classes.root}
          title={tReady && t('INVOICE_EDIT_PAGE_TITLE_XML')}
        >
          <InvoiceEditFormXML2
            renderHeader={this.renderHeader}
            invoice={invoice}
            history={history}
            headerFields={fields}
            getFields={this.getFields}
            xml={xml}
            setInvoice={this.setInvoice}
            gotoInvoiceList={this.gotoInvoiceList}
            xmlReceived={xmlLoaded}
            getInvoice={this.getInvoice}
            users={users}
            handleSelectStatus={this.handleStatusChange}
            statusChangeLoading={statusChangeLoading}
            updateUser={updateUser}
            handleChooseValidatorModalOpen={this.handleChooseValidatorModalOpen}
            isSupplierLabelWithVAT={isSupplierLabelWithVAT}
            isToValidateStatusAllowed={isToValidateStatusAllowed}
            isAutoNavigationAllowed={isAutoNavigationAllowed}
            previousInvoice={previousInvoice}
            nextInvoice={nextInvoice}
            navigateInvoice={this.navigateInvoice}
            parameterCompany={parameterCompany}
            parameterDocCompany={parameterDocCompany}
            setStatusChangedRef={this.setStatusChangedRef}
            customReceivers={customReceivers}
          />
          <ChooseValidatorModal
            chooseValidatorModalOpen={chooseValidatorOpen}
            handleChooseValidatorModalClose={this.handleChooseValidatorModalClose}
            validatorOptionsRef={this.validatorOptionsRef}
            handleValidatorOptionsToggle={this.handleValidatorOptionsToggle}
            selectedValidator={selectedValidator}
            validatorOptionsOpen={validatorOptionsOpen}
            handleValidatorOptionsClose={this.handleValidatorOptionsClose}
            users={users}
            handleValidatorClick={this.handleValidatorClick}
            handleAddUserClick={this.handleAddUserClick}
            statusChangeLoading={statusChangeLoading}
            onSendToValidate={this.onSendToValidate}
          />
          <DeleteDocumentModal
            openDeleteConf={openDeleteConf}
            onCancelDelete={this.onCancelDelete}
            onDeleteInvoice={this.onDeleteInvoice}
          />
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={addUserOpen}
            onClose={() => this.setState({ addUserOpen: false })}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={addUserOpen}>
              <div className={classes.halfPaper}>
                <Grid className={classes.modalHeader}>
                  <Typography className={classes.headerTitle}>
                    {tReady && t('ACCOUNT_SETTINGS_USERS_ADD_NEW_USER')}
                  </Typography>
                  <IconButton onClick={() => this.setState({ addUserOpen: false })}>
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </Grid>
                <UserCreateForm
                  user={user}
                  handleClose={() => this.setState({ addUserOpen: false })}
                  updateUser={updateUser}
                  onGetAllUsers={this.getAllUsers}
                />
              </div>
            </Fade>
          </Modal>
        </Page>
      );
    }
    return <LoadingScreenText />;
  }
}

InvoiceEditXMLView.contextType = ConfigContext;

InvoiceEditXMLView.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    statusElementSelected: PropTypes.string,
    statusText: PropTypes.string,
    statusTextDiv: PropTypes.string,
    moreIcon: PropTypes.string,
    modal: PropTypes.string,
    modalHeader: PropTypes.string,
    headerTitle: PropTypes.string,
    closeIcon: PropTypes.string,
    submitBtn: PropTypes.string,
    halfPaper: PropTypes.string,
    primaryTextColor: PropTypes.string,
    fieldContainerFirst: PropTypes.string,
    btnGroup: PropTypes.string,
    link: PropTypes.string,
    disabledLink: PropTypes.string,
    addUserDisabled: PropTypes.string,
    addUserDisabledText: PropTypes.string,
    warningBanner: PropTypes.string,
    cancelBtn: PropTypes.string,
    validateButton: PropTypes.string,
    dropdownBtn: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    go: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string
    })
  }),
  user: PropTypes.shape({
    userRole: PropTypes.string,
    company: PropTypes.string,
    companyID: PropTypes.string,
    exported: PropTypes.number,
    permissions: PropTypes.array,
    usersCount: PropTypes.number,
    maxUsers: PropTypes.number,
    email: PropTypes.string,
    customisations: PropTypes.array,
    isDocunecta: PropTypes.bool,
    documentType: PropTypes.string,
  }).isRequired,
  updateUser: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  t: PropTypes.func,
  tReady: PropTypes.bool,
};

InvoiceEditXMLView.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (payload) => dispatch(setUserData(payload))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles),
)(withSnackbar(withRouter(InvoiceEditXMLView)));
