import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    minHeight: '100vh',
    paddingTop: 36,
    paddingBottom: 36,
  },
  container: {
    minHeight: 'calc(100vh - 72px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContainer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    marginTop: 100,
    fontFamily: 'Poppins',
    fontSize: 20,
    color: '#333333',
    fontWeight: 300,
    textAlign: 'center',
  },
  logoBtn: {
    marginTop: 60,
  },
  logo: {
    width: 200,
    height: 50,
  },
  copyrightContainer: {
    textAlign: 'center',
    lineHeight: 24,
    fontStyle: 'italic',
    fontSize: 14,
    color: '#6a6a6a',
    display: 'flex',
    alignItems: 'flex-end'
  },
  copyrightLink: {
    textDecoration: 'none',
    color: '#38A0F1',
  },
  backdrop: {
    zIndex: 1111,
    color: '#ffffff',
  },
}));

export default useStyles;
