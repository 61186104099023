import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import moment from 'moment';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  Button, Grid, Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Page from 'src/Shared/components/Page/Page';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';

import authService from 'src/Shared/utils/services/authService';
import { axiosHeaders, validateToken } from 'src/Shared/utils/helpers';
import {
  glaMsg, vatMsg, cctMsg, prjtMsg, defaultMsg
} from 'src/config';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const DimensionLogView = (props) => {
  const {
    classes, enqueueSnackbar, match, history, location
  } = props;

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const { dim } = match.params;
  const { code, type, company } = location.state;
  const { t, ready } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [fetchingLog, setFetchingLog] = useState(true);

  const [log, setLog] = useState(null);

  let req; // Request;
  let resp; // Response;
  let title;
  let dimLogViewTitle;

  switch (dim) {
    case 'glaccounts':
      title = glaMsg.GLA_TITLE;
      dimLogViewTitle = glaMsg.GLA_LOG_VIEW_DIMENSION_CODE;
      break;
    case 'vatcodes':
      title = vatMsg.VAT_CODE_TITLE;
      dimLogViewTitle = vatMsg.VAT_CODE_LOG_VIEW_DIMENSION_CODE;
      break;
    case 'projects':
      title = prjtMsg.PROJECT_TITLE;
      dimLogViewTitle = prjtMsg.PROJECT_LOG_VIEW_DIMENSION_CODE;
      break;
    case 'costcenters':
      title = cctMsg.COST_CENTER_TITLE;
      dimLogViewTitle = cctMsg.COST_CENTER_LOG_VIEW_DIMENSION_CODE;
      break;
    default:
      dimLogViewTitle = defaultMsg.DEFAULT_LOG_VIEW_DIMENSION_CODE;
      break;
  }

  const gotoLogsList = () => {
    if (code !== undefined) {
      history.push(`/dimensions/logs/${dim}${type ? `/${type}` : ''}/code/${code}`);
      return;
    }
    history.push(`/dimensions/logs/${dim}${type ? `/${type}` : ''}/company/${company}`);
  };

  const getDimensionLogByID = async (id) => {
    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLog(true);

    try {
      const url = `${API.getDimensionLogsByCompany}/${dim}/${id}?code=${code !== undefined && code}&company=${company !== undefined && company}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data) {
            setLog(response.data.data);
          } else {
            gotoLogsList();
          }
        } else {
          gotoLogsList();
        }
      } else {
        gotoLogsList();
      }
    } catch (error) {
      gotoLogsList();
    }

    setFetchingLog(false);
    setLoading(false);
  };

  useEffect(() => {
    const id = match && match.params ? match.params.id : 0;

    if (id && id > 0) {
      getDimensionLogByID(id);
    } else {
      gotoLogsList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || fetchingLog) {
    return <LoadingScreenText />;
  }

  try {
    req = JSON.stringify(JSON.parse(log.request), null, 2);
  } catch (err) {
    req = log.request;
  }

  try {
    resp = JSON.stringify(JSON.parse(log.response), null, 2);
  } catch (err) {
    resp = log.response;
  }

  return (
    <Page
      className={classes.root}
      title={ready && t(title)}
    >
      {
        log && ready && (
          <>
            <Grid className={classes.titleContainer}>
              <Grid>
                <Button className={classes.backBtn} onClick={gotoLogsList}>
                  <ArrowBackIcon className={classes.icon} />
                  {ready && t('LOGS_VIEW_BACK_TO_OVERVIEW')}
                </Button>
              </Grid>
              {/* <Typography className={classes.assistantID}>{`${t('LOGS_VIEW_PROCYS_NUMBER')}: ${log.assistantID}`}</Typography> */}
              <Grid className={classes.titleBox}>
                <Typography className={classes.title}>{`${ready && t(dimLogViewTitle)}: ${log.code}`}</Typography>
              </Grid>
              {/* <Grid container direction="row" alignItems="center">
                <Grid className={classes.arrowBtn}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    component="label"
                    disabled={log.nextId === undefined || log.nextId === null}
                    className={classes.action}
                    onClick={() => getInvoiceLogByID(log.nextId, paramsID)}
                  >
                    <ArrowBackIosNewIcon fontSize="small" />
                  </Button>
                </Grid>
                <Grid className={classes.arrowBtn}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    component="label"
                    disabled={log.prevId === undefined || log.prevId === null}
                    className={classes.action}
                    onClick={() => getInvoiceLogByID(log.prevId, paramsID)}
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>
            <Grid className={classes.topContainer}>
              <Grid className={classes.logViewContainer}>
                <Grid className={classes.labelsContainer}>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>#</Typography>
                    <Typography className={classes.value}>{log.id || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIMENSION_CODE')}</Typography>
                    <Typography className={classes.value}>{log.code || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIMENSION_NAME')}</Typography>
                    <Typography className={classes.value}>{log.name || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIMENSION_COMPANY')}</Typography>
                    <Typography className={classes.value}>{log.company || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIMENSION_EMAIL')}</Typography>
                    <Typography className={classes.value}>{log.email || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIMENSION_ACTION')}</Typography>
                    <Typography className={classes.value}>{log.action || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIMENSION_MODIFIED_BY')}</Typography>
                    <Typography className={classes.value}>
                      {
                        log.firstname && log.lastname
                          ? `${log.firstname} ${log.lastname}`
                          : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIMENSION_MODIFIED_AT')}</Typography>
                    <Typography className={classes.value}>
                      {
                        log.timestamp
                          ? moment.unix(log.timestamp / 1000000000).format('DD/MM/YYYY hh:mm:ss')
                          : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIEMNSION_REQUEST')}</Typography>
                    <Typography className={classes.value}>{<pre className={classes.xmlView}>{req}</pre> || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{ready && t('LOG_VIEW_DIEMNSION_RESPONSE')}</Typography>
                    <Typography className={classes.value}>{<pre className={classes.xmlView}>{resp}</pre> || '-'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
    </Page>
  );
};

DimensionLogView.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    topContainer: PropTypes.string,
    logViewContainer: PropTypes.string,
    labelsContainer: PropTypes.string,
    itemContainer: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    titleContainer: PropTypes.string,
    backBtn: PropTypes.string,
    icon: PropTypes.string,
    assistantID: PropTypes.string,
    subtitle: PropTypes.string,
    titleBox: PropTypes.string,
    title: PropTypes.string,
    infoBox: PropTypes.string,
    chipLabel: PropTypes.string,
    chipAvatar: PropTypes.string,
    userChip: PropTypes.string,
    xmlView: PropTypes.string,
    action: PropTypes.string,
    arrowBtn: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    company: PropTypes.string
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      code: PropTypes.string,
      type: PropTypes.string,
      company: PropTypes.string,
    })
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      dim: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  enqueueSnackbar: PropTypes.func,
};

DimensionLogView.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = () => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(withSnackbar(withRouter(DimensionLogView)));
