import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        LocalStorageBackend,
        HttpApi
      ],
      backendOptions: [
        {
          expirationTime: 2 * 60 * 60 * 1000, // 2 hours
        },
        {
          loadPath: 'https://provider.procys.com/v1/admin/languages/json/procys/{{lng}}',
          crossDomain: true
        }
      ]
    },
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    debug: false,
    react: {
      wait: true,
      useSuspense: false
    }
  });

export default i18n;
