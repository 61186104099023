import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  filterMenu: {
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 8%)',
    border: '1px solid #DDDDDD'
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 10px',
    margin: '10px 0px 0px 0px'
  },
  text: {
    fontSize: '13px',
    color: '#334D6E',
    lineHeight: '32px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  cancelButtonSup: {
    width: '40%',
    height: 32,
    textTransform: 'none',
    color: '#334D6E',
    marginLeft: 10,
  },
  applyButtonSup: {
    width: '40%',
    height: 32,
    textTransform: 'none',
    marginLeft: 10,
  },
}));

export default useStyles;
