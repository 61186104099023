import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

const SwitchToTFInterfaceModal = (props) => {
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const {
    modalOpen,
    setModalOpen,
    updateInterfacePreference,
  } = props;

  return (
    <Modal
      className={classes.modal}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalOpen}>
        <div className={classes.halfPaper}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {ready && t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_TITLE')}
            </Typography>
            <IconButton onClick={() => setModalOpen(false)}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Grid>
            <Grid className={classes.fieldContainerFirst}>
              <Typography variant="caption" className={classes.primaryTextColor}>
                {ready && t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_DESCRIPTION')}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                className={classes.cancelBtn}
                onClick={() => setModalOpen(false)}
              >
                {ready && t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_CANCEL_BTN')}
              </Button>
              <Button
                className={classes.actionBtn}
                variant="contained"
                color="secondary"
                onClick={updateInterfacePreference}
              >
                {ready && t('APPS_PAGE_SWITCH_TO_TF_INTERFACE_PROCEED_BTN')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

SwitchToTFInterfaceModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  updateInterfacePreference: PropTypes.func
};

export default SwitchToTFInterfaceModal;
