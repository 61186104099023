import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '24px 0px'
  },
  titleText: {
    fontSize: 18,
    fontWeight: 500,
    color: '#334D6E',
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: '#334D6E',
    margin: '8px 0px'
  },
  domainContainer: {
    padding: '16px 0px',
  },
  domainRow: {
    padding: '0px 16px',
    display: 'flex',
  },
  domainItem: {
    padding: '8px 16px',
  },
  columnLabel: {
    color: '#334D6E',
    fontSize: 14,
    margin: '8px 0px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0px 24px'
  },
  checkConnectionButton: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
    margin: '6px 0px 6px 0px'
  },
}));

export default useStyles;
