import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Grid
} from '@material-ui/core';

import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import {
  appendContactSupport, axiosHeaders, validateEmail
} from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from '../style';

const ExportEmailIntegration = (props) => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [usedEmails, setUsedEmails] = useState(user?.deliverEmails?.join(', ') || '');
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  const [iUsedEmails, setIUsedEmails] = useState(user.deliverEmails || []);

  const {
    deliverFormats, prefFormat, savedFormat, savePrefFormat
  } = props;

  const saveNewDetails = async () => {
    const uEmails = usedEmails.split(',');

    const fullList = [];
    const addedEmails = [];
    const removedEmails = [];
    for (let i = 0; i < uEmails.length; i++) {
      if (uEmails[i] && uEmails[i].trim()) {
        fullList.push(uEmails[i].trim());
      }

      let isNew = true;
      for (let j = 0; j < iUsedEmails.length; j++) {
        if (uEmails[i] && iUsedEmails[j] && uEmails[i].trim() === iUsedEmails[j].trim()) {
          isNew = false;
          break;
        }
      }

      if (isNew && uEmails[i] && uEmails[i].trim()) {
        const resp = validateEmail(uEmails[i].trim());
        if (!resp.isValid) {
          enqueueSnackbar(
            t(resp.errorMessage),
            {
              variant: 'error',
              autoHideDuration: 5000
            }
          );
          return;
        }
        addedEmails.push(uEmails[i].trim());
      }
    }

    for (let i = 0; i < iUsedEmails.length; i++) {
      let isDeleted = true;
      for (let j = 0; j < uEmails.length; j++) {
        if (iUsedEmails[i] && uEmails[j] && iUsedEmails[i].trim() === uEmails[j].trim()) {
          isDeleted = false;
          break;
        }
      }

      if (isDeleted) {
        removedEmails.push(iUsedEmails[i].trim());
      }
    }

    if (addedEmails.length <= 0 && removedEmails.length <= 0) {
      return;
    }

    try {
      const response = await Axios.post(
        `${API.saveEmailDeliver}`,
        {
          new: addedEmails,
          old: removedEmails
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSaved(true);
        setIUsedEmails(fullList);
      } else {
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Grid className={classes.detailsContainer}>
      <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>{ready && t('INTEGRATIONS_EXPORT_EMAIL_EMAILS')}</span>
          {saved && <Grid className={classes.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          type="text"
          value={usedEmails}
          className={classes.inputField}
          onChange={(e) => setUsedEmails(e.target.value)}
          onBlur={(e) => saveNewDetails(e.target.value)}
        />
      </Grid>
      <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>{ready && t('INTEGRATIONS_EXPORT_EMAIL_FILE_TYPE')}</span>
          {savedFormat && <Grid className={classes.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <select
          type="text"
          value={prefFormat}
          className={clsx(classes.inputField, 'export-email-style')}
          onChange={(e) => savePrefFormat(e.target.value)}
        >
          {
            deliverFormats.map((format) => !format.dontExportViaEmail && (
              <option key={format.id} value={format.key}>{format.label}</option>
            ))
          }
        </select>
      </Grid>
    </Grid>
  );
};

ExportEmailIntegration.propTypes = {
  integration: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    byZapier: PropTypes.bool,
    available: PropTypes.bool,
    integrated: PropTypes.bool,
    active: PropTypes.bool,
    link: PropTypes.string,
    img: PropTypes.string
  }),
  deliverFormats: PropTypes.array,
  prefFormat: PropTypes.string,
  savedFormat: PropTypes.bool,
  savePrefFormat: PropTypes.func,
};

export default ExportEmailIntegration;
