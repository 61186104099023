import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    border: '1px solid rgba(221, 221, 221, 1)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    marginBottom: 20,
    borderRadius: 8,
    backgroundColor: 'white',
    cursor: 'pointer'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceNumber: {
    fontSize: 14,
    fontWeight: 600,
    color: '#334D6E'
  },
  moreIcon: {
    height: 16,
    color: '#C2CFE0',
    marginLeft: theme.spacing(2),
    cursor: 'pointer'
  },
  dateText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#818E9B'
  },
  avatar: {
    flexShrink: 0,
    height: 36,
    width: 36,
    marginTop: 8
  }
}));

export default useStyles;
