import * as actionTypes from 'src/Auth/store/actions/actionTypes';

export const logout = () => ({
  type: actionTypes.LOGOUT
});

export const logoutComplete = () => ({
  type: actionTypes.LOGOUT_COMPLETE
});

export const setUserData = (user) => ({
  type: actionTypes.SILENT_LOGIN,
  user
});
