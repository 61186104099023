import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  paper: {
    maxWidth: 600,
    width: '90%',
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px',
    marginRight: 24
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  snackAction: {
    color: 'white'
  },
  userForm: {
    width: '100%'
  },
  fieldContainer: {
    marginTop: 25
  },
  submitBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    textTransform: 'none',
    background: '#3E8AFF',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    borderRadius: 4
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    color: '#A0AEC1',
    textTransform: 'none',
    marginRight: 24
  },
  moreButton: {
    textTransform: 'none'
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: '#334D6E',
    fontSize: 13,
    lineHeight: '20px',
  }
}));

export default useStyles;
