import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Container } from '@material-ui/core';

import Page from 'src/Shared/components/Page/Page';
import InvoiceListView from 'src/Invoices/components/InvoiceListView/InvoiceListView';
import SelectDocument from 'src/Shared/components/SelectDocument/SelectDocument';
import useStyles from './style';

const InvoiceList = () => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { t, ready } = useTranslation();

  return (
    <Page
      className={classes.root}
      title={ready && t('INVOICE_LIST_PAGE_TITLE')}
    >
      {
        !user.documentType && <SelectDocument open />
      }
      <Container maxWidth={false} className={classes.topContainer}>
        <InvoiceListView onDataLoaded={() => {}} />
      </Container>
    </Page>
  );
};

InvoiceList.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    topContainer: PropTypes.string
  }).isRequired,
};

InvoiceList.defaultProps = {};

export default InvoiceList;
