import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  titleContainer: {
    marginBottom: 16
  },
  backBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    color: '#3E8AFF',
    cursor: 'pointer',
    paddingLeft: 0,
    marginBottom: 16,
    textTransform: 'none'
  },
  icon: {
    marginRight: 12
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    marginLeft: 16
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
    marginBottom: 24
  },
  accordionHeading: {
    color: '#334D6E',
    fontWeight: 500
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: '#334D6E',
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  }
}));

export default useStyles;
