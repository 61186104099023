import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    fontSize: '11px!important',
    fontWeight: 500,
    color: 'rgba(51, 77, 110, 0.5)',
    padding: '16px 20px 9px'
  },
  cardContent: {
    padding: 0
  },
  inputContainer: {
    padding: '0px 20px'
  },
  formContainer: {
    padding: '16px 20px'
  },
  formInput: {
    paddingTop: '13px!important',
    paddingBottom: '12px!important',
  },
  formInputLast: {
    paddingTop: '13px!important',
    paddingBottom: '0px!important',
  },
  saveBtnContainer: {
    paddingBottom: 28,
    paddingTop: 9
  },
  saveBtn: {
    width: 177,
    height: 41,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    textTransform: 'none'
  },
  iconWrapper: {
    position: "absolute",
    right: 15.47,
    width: 18.33,
    height: 12.5,
  },
  visibleIcon: {
    color: '#C2CFE0'    
  },
  strengthBarWrapper: {
    width: '100%',
    paddingTop: '6px!important'
  }
}));

export default useStyles;
