/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useRef, useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button, Grid, InputAdornment, SvgIcon, TextField, Tooltip, Typography,
  IconButton, useMediaQuery
} from '@material-ui/core';
import LogsIcon from '@material-ui/icons/CardTravel';
import AddCircle from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import ImportIcon from '@material-ui/icons/SystemUpdateAlt';
import ExportIcon from '@material-ui/icons/OpenInBrowser';

import Page from 'src/Shared/components/Page/Page';
import CostCentersTable from 'src/Dimensions/components/CostCentersTable/CostCentersTable';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import authService from 'src/Shared/utils/services/authService';
import {
  axiosHeaders,
  isActionPermitted, permissions, validateToken
} from 'src/Shared/utils/helpers';
import CostCenterModal from 'src/Dimensions/components/CostCentersModal/CostCenterModal';
import DimensionExportPopper from 'src/Dimensions/components/DimensionExportPopper/DimensionExportPopper';
import DimensionImportModal from 'src/Dimensions/components/DimensionImportModal/DimensionImportModal';

import { fetchCostCenters } from 'src/Dimensions/store/actions/costcenter';
import Axios from 'axios';
import FileSaver from 'file-saver';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function ListCostCenters() {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { costCenters, total, loading } = useSelector((state) => state.costcenter);

  const [query, setQuery] = useState('');

  const [costCenterModalOpen, setCostCenterModalOpen] = useState(false);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);

  const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
  const exportBtnRef = useRef(null);

  const [importModalOpen, setImportModalOpen] = useState(false);

  const enabled = true;
  const isTwinfield = user.accountingSystem === 'twinfield';

  const maxWidth = useMediaQuery('(min-width: 830px)');
  const maxWidth2 = useMediaQuery('(min-width: 530px)');

  const onCostCenterModalClose = () => {
    setCostCenterModalOpen(false);
    setTimeout(() => setSelectedCostCenter(null), 150);
  };

  const gotoItem = (code) => {
    if (!isTwinfield) {
      setSelectedCostCenter(code);
      setCostCenterModalOpen(true);
    }
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(user.companyID));
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user.permissions)) {
      getAllCostCenters();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => {
    if (!validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setSelectedCostCenter(null);
    setCostCenterModalOpen(true);
  };

  const handleQueryChange = (e) => {
    e.persist();
    setQuery(e.target.value);
  };

  const exportOptionsClose = (event) => {
    if (exportBtnRef.current && exportBtnRef.current.contains(event.target)) {
      return;
    }
    setExportOptionsOpen(false);
  };

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  const handleDimensionExport = async (format) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}costcenters/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user.company} Cost Centers.${format}`;
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      setExportOptionsOpen(false);
    } catch (error) {
      enqueueSnackbar(t('DIMENSION_EXPORT_FAIL'), {
        variant: 'error',
        persist: false
      });
    }
  };

  const searchBar = (
    <Grid className={clsx(classes.searchBar, 'search-bar')}>
      <Tooltip title={t('DASHBOARD_SEARCH_COST_CENTER_SEARCH_TOOLTIP')}>
        <TextField
          className={classes.queryField}
          onChange={handleQueryChange}
          placeholder={ready && t('DASHBOARD_SEARCH_COST_CENTER_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: '#C2CFE0' }}
                >
                  <SearchIcon style={{ color: '#C2CFE0' }} />
                </SvgIcon>
              </InputAdornment>
            ),
            classes: {
              input: classes.searchInput,
              root: classes.searchBarInput
            }
          }}
        />
      </Tooltip>
    </Grid>
  );

  const logsList = (
    <Tooltip title={t('COST_CENTERS_LIST_VIEW_LOGS_TOOLTIP')}>
      <IconButton
        className={classes.logsIconContainer}
        onClick={() => history.push(`/dimensions/logs/costcenters/company/${user.companyID}`)}
      >
        <LogsIcon
          className={classes.logsIcon}
          style={{ fontSize: '1.8rem' }}
        />
      </IconButton>
    </Tooltip>
  );

  const titleText = (
    <Typography className={classes.title}>
      {ready && t('DASHBOARD_COST_CENTERS')}
    </Typography>
  );

  const addButton = (
    <Tooltip
      title={isActionPermitted(permissions.dimensionAdd, user.permissions)
        ? ready && t('COST_CENTERS_ADD_NEW_TOOLTIP')
        : ready && t('COST_CENTERS_ADD_NEW_TOOLTIP_PERMISSION')}
    >
      <Button
        color="secondary"
        type="submit"
        variant="contained"
        className={classes.addBtn}
        onClick={handleAdd}
        disabled={!isActionPermitted(permissions.dimensionAdd, user.permissions)}
      >
        <SvgIcon
          fontSize="small"
          className={classes.actionIcon}
        >
          <AddCircle />
        </SvgIcon>
        {ready && t('COST_CENTERS_ADD_NEW')}
      </Button>
    </Tooltip>
  );

  const importBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : ''}
    >
      <span>
        <Button
          variant="outlined"
          className={classes.importExportBtn}
          onClick={() => setImportModalOpen(true)}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            className={classes.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ImportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_IMPORT_BTN')}
        </Button>
      </span>
    </Tooltip>
  );

  const exportBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : costCenters.length === 0
          ? ready && t('CC_NO_DATA_TO_EXPORT')
          : ''}
    >
      <span>
        <Button
          ref={exportBtnRef}
          variant="outlined"
          className={classes.importExportBtn}
          onClick={() => { setExportOptionsOpen(true); }}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions) || costCenters.length === 0}
        >
          <SvgIcon
            fontSize="small"
            className={classes.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ExportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_EXPORT_BTN')}
        </Button>
        <DimensionExportPopper
          exportOptionsOpen={exportOptionsOpen}
          exportBtnRef={exportBtnRef}
          exportOptionsClose={exportOptionsClose}
          t={t}
          onOptionClick={handleDimensionExport}
        />
      </span>
    </Tooltip>
  );

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <Page
      className={classes.root}
      title={ready && t('COST_CENTER_LIST_PAGE_TITLE')}
    >
      <Grid className={clsx(classes.header, classes.headerMargin)}>
        {
          maxWidth ? (
            <Grid className={classes.header}>
              {titleText}
              {searchBar}
              {importBtn}
              {exportBtn}
              {
                !isTwinfield && (
                  <>
                    {enabled && logsList}
                    {addButton}
                  </>
                )
              }
            </Grid>
          ) : maxWidth2 ? (
            <Grid className={classes.headerColumn}>
              {titleText}
              <Grid className={classes.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              <Grid className={classes.importExportRow}>
                {importBtn}
                {exportBtn}
                {
                  !isTwinfield && addButton
                }
              </Grid>
            </Grid>
          ) : (
            <Grid className={classes.headerColumn}>
              {titleText}
              <Grid className={classes.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              <Grid className={classes.importExportRow}>
                {importBtn}
                {exportBtn}
              </Grid>
              {
                !isTwinfield && addButton
              }
            </Grid>
          )
        }
      </Grid>
      <CostCentersTable
        costCenters={costCenters}
        getAllCostCenters={getAllCostCenters}
        total={total}
        query={query}
        isTwinfield={isTwinfield}
        gotoItem={gotoItem}
      />
      <CostCenterModal
        costCenterModalOpen={costCenterModalOpen}
        selectedCostCenter={selectedCostCenter}
        handleClose={onCostCenterModalClose}
        getAllCostCenters={getAllCostCenters}
      />
      <DimensionImportModal
        dimensionImportModalOpen={importModalOpen}
        handleClose={handleImportModalClose}
        dimension="cc"
        fetchDimenstions={getAllCostCenters}
      />
    </Page>
  );
}

export default ListCostCenters;
