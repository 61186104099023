import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './style';

const LoadingScreenCustomText = ({ text }) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box width={400}>
        <Typography
          align="center"
          variant="subtitle1"
        >
          {text}
        </Typography>
        <LinearProgress />
      </Box>
    </div>
  );
};

LoadingScreenCustomText.defaultProps = {
  text: '',
};

LoadingScreenCustomText.propTypes = {
  text: PropTypes.string,
};

export default LoadingScreenCustomText;
