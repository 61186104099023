import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  axiosHeaders, isActionPermitted, isShowManageFields, permissions
} from 'src/Shared/utils/helpers';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { glAccountData } from 'src/config';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import {
  Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip
} from '@material-ui/core';
import {
  SettingsOutlined as SettingsIcon,
  AddBox as PlusIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import ConfigContext from 'src/Contexts';
import ManageFields from 'src/Shared/components/ManageFields/ManageFields';
import useStyles from './style';

const GLAccountsStandard = (props) => {
  const { API } = useContext(ConfigContext);
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    glAccount,
    handleClose,
    className,
    hasError,
    years,
    endYears,
    handleChangeAutocomplete,
    getLabel,
    vatCodes,
    onSuccess,
    newlyAddedVal,
    newlyAddingProp,
    handleEditVATCode,
    handleAddVATCode,
    ...rest
  } = props;
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [glaFields, setGlaFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const getGLAFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/gla/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setGlaFields(response.data.data.filter((f) => f.isAvailable));
          setLoading(false);
        } else {
          setGlaFields([]);
          setLoading(false);
        }
      }
    } catch (error) {
      setGlaFields([]);
      setLoading(false);
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => {
    getGLAFields();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFieldAvailable = (field) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } });
    }
  }, [newlyAddedVal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form
        className={clsx(classes.root, className)}
        {...rest}
      >
        {loading ? (<LoadingScreen />) : (
          <Card style={{ boxShadow: 'none' }}>
            <CardContent style={{ padding: '20px 0px 0px 0px' }}>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12} key="name">
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && (ready && t(errors.name))}
                    label={ready && t('GLA_NAME')}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                    className={classes.inputField}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} key="vat">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Autocomplete
                      fullWidth
                      name="vat"
                      onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'vat', v, handleChange)}
                      value={getLabel(vatCodes, values.vat)}
                      options={vatCodes}
                      getOptionLabel={
                            (option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)
                        }
                      getOptionSelected={(option) => option.code === (values.vat ? values.vat : ' ')}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label={ready && t('GLA_VAT')}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {values.vat !== glAccountData.vat[0].code && (
                                  <InputAdornment
                                    position="end"
                                    onClick={() => handleEditVATCode(values.vat)}
                                    className={classes.editIconContainer}
                                  >
                                    <EditIcon className={classes.editDimensionIcon} />
                                  </InputAdornment>
                                )}
                                <InputAdornment position="end" className={classes.plusIconContainer}>
                                  <PlusIcon className={classes.plusIcon} onClick={() => handleAddVATCode('vat')} />
                                </InputAdornment>
                              </>
                            ),
                          }}
                          className={classes.selectStyle}
                        />
                      )}
                    />
                    <FormHelperText error>
                      {touched.vat && errors.vat && ready ? t(errors.vat) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {
                  glaFields.map((f) => {
                    if (!isFieldAvailable(f)) {
                      return null;
                    }
                    switch (f.key) {
                      case 'furtherAnalysis':
                        return (
                          <Grid item lg={6} md={6} xs={12} key={f.key}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                classes={{
                                  select: classes.selectStyle
                                }}
                              >
                                {
                                  glAccountData.furtherAnalysis && glAccountData.furtherAnalysis.length > 0
                                      && glAccountData.furtherAnalysis.map((s) => {
                                        if (s) {
                                          return (
                                            <MenuItem key={s.code} value={s.code}>
                                              {t(s.name)}
                                            </MenuItem>
                                          );
                                        }

                                        return null;
                                      })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'matchable':
                        return (
                          <Grid item lg={6} md={6} xs={12} key={f.key}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                classes={{
                                  select: classes.selectStyle
                                }}
                              >
                                <MenuItem value="yes">
                                  {t('GLA_MATCHABLE_MATCHABLE')}
                                </MenuItem>
                                <MenuItem value="no">
                                  {t('GLA_MATCHABLE_NOT_MATCHABLE')}
                                </MenuItem>
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'performanceType':
                        return (
                          <Grid item lg={6} md={6} xs={12} key={f.key}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                classes={{
                                  select: classes.selectStyle
                                }}
                              >
                                {
                                  glAccountData.performanceType && glAccountData.performanceType.length > 0
                                      && glAccountData.performanceType.map((s) => {
                                        if (s) {
                                          return (
                                            <MenuItem key={s.code} value={s.code}>
                                              {t(s.name)}
                                            </MenuItem>
                                          );
                                        }

                                        return null;
                                      })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'vatType':
                        return (
                          <Grid item lg={6} md={6} xs={12} key={f.key}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id={`${f.key}-label`}>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</InputLabel>
                              <Select
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                value={values[f.key]}
                                onChange={handleChange}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                classes={{
                                  select: classes.selectStyle
                                }}
                              >
                                {
                                  glAccountData.vatType && glAccountData.vatType.length > 0
                                      && glAccountData.vatType.map((s) => {
                                        if (s) {
                                          return (
                                            <MenuItem key={s.code} value={s.code}>
                                              {t(s.name)}
                                            </MenuItem>
                                          );
                                        }

                                        return null;
                                      })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      default:
                        return (
                          <Tooltip title={(!glAccount || f.key !== 'code') ? '' : t('DIMENSION_CODE_CANNOT_EDIT')}>
                            <Grid item lg={6} md={6} xs={12} key={f.key}>
                              <TextField
                                error={Boolean(touched[f.key] && errors[f.key])}
                                fullWidth
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                label={ready && t(f.label)}
                                name={f.key}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required={f.isRequired}
                                value={values[f.key]}
                                variant="outlined"
                                className={classes.inputField}
                                disabled={glAccount && f.key === 'code'}
                              />
                            </Grid>
                          </Tooltip>
                        );
                    }
                  })
                }
                {errors.submit && (
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                  </Grid>
                )}
                {isShowManageFields(user, 'applyParentFieldsGLA') && (
                  <Grid mt={2} item xs={12}>
                    <Button
                      variant="outlined"
                      className={classes.manageFieldsBtn}
                      onClick={() => setShowManageFields(true)}
                    >
                      <SettingsIcon className={classes.btnIcon} />
                      {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user.permissions)}
                      onClick={handleSubmit}
                    >
                      {ready && t('GLAS_SAVE_CHANGES')}
                    </Button>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={handleClose}
                      className={classes.yearField}
                    >
                      {ready && t('GLAS_CANCEL')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </form>
      <ManageFields
        open={showManageFields}
        entity="gla"
        fields={glaFields}
        fetchFields={getGLAFields}
        closeModal={handleCloseManageFields}
        parameterCompany={user.companyID}
      />
    </>
  );
};

GLAccountsStandard.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  touched: PropTypes.bool,
  values: PropTypes.object,
  glAccount: PropTypes.object,
  gLAccountModalOpen: PropTypes.bool,
  selectedGLA: PropTypes.string,
  handleClose: PropTypes.func,
  className: PropTypes.string,
  hasError: PropTypes.func,
  years: PropTypes.array,
  endYears: PropTypes.array,
  handleChangeAutocomplete: PropTypes.func,
  getLabel: PropTypes.func,
  vatCodes: PropTypes.array,
  onSuccess: PropTypes.func,
  newlyAddedVal: PropTypes.string,
  newlyAddingProp: PropTypes.string,
  handleAddVATCode: PropTypes.func,
  handleEditVATCode: PropTypes.func,
};

export default GLAccountsStandard;
