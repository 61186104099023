import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const BackToProcysConfirmation = ({
  openConfirmation,
  onClose,
  handleStatusChange
}) => {
  const classes = useStyles();
  const { t, ready: tReady } = useTranslation();

  return (
    <Modal
      className={classes.modal}
      open={openConfirmation}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openConfirmation}>
        <div className={classes.paperModal}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {tReady && t('BRAND_BACK_TO_PROCYS_CONF_TITLE')}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Grid className={classes.deleteMessageText}>
            <Typography variant="caption">
              {tReady && t('BRAND_BACK_TO_PROCYS_CONF_MESSAGE')}
            </Typography>
          </Grid>
          <Grid className={classes.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                className={classes.closeBtn}
                onClick={onClose}
              >
                {tReady && t('BRAND_BACK_TO_PROCYS_CONF_CANCEL')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitBtn}
                onClick={() => handleStatusChange('cancelled')}
              >
                {tReady && t('BRAND_BACK_TO_PROCYS_CONFIRM')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

BackToProcysConfirmation.propTypes = {
  openConfirmation: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired
};

export default BackToProcysConfirmation;
