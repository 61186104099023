/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  Box, Card, IconButton, Typography, SvgIcon, Table, TableBody,
  TableCell, TableHead, TablePagination, TableRow, Grid, Avatar, Button, Modal,
  Backdrop, Fade, Hidden, Menu, MenuItem, Tooltip, useMediaQuery, Link
} from '@material-ui/core';
import {
  AddCircle as PlusCircleIcon
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert } from '@material-ui/lab';

import { ERRORS, userEditOptions } from 'src/config';
// import SimpleSelect from 'src/Shared/components/SimpleSelect/SimpleSelect';
// import SortTableCell from 'src/Shared/components/SortTableCell/SortTableCell';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import {
  applySort, applyPagination, applyUserFilters, axiosHeaders, validateToken, isActionPermitted, permissions,
  isUserManageOptionValid, getLocalisedErrorString, userEditOptionLabels, axiosDeleteHeaders, isBranded
} from 'src/Shared/utils/helpers';
import authService from 'src/Shared/utils/services/authService';
import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import UserCreateForm from 'src/User/components/UserCreateForm/UserCreateForm';
import UserInviteForm from 'src/User/components/UserInviteForm/UserInviteForm';
import UserEditForm from 'src/User/components/UserEditForm/UserEditForm';
import ConfigContext from 'src/Contexts';
import useStyles from './style';
import NewUserInviteLinkPopup from '../NewUserInviteLinkPopup/NewUserInviteLinkPopup';

function Results({
  className, history, updateUser, ...rest
}) {
  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { t, ready } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const [users, setUsers] = useState([]);

  const [addUserOpen, setAddUserOpen] = useState(false);
  const [inviteUserOpen, setInviteUserOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query] = useState('');
  const [sortBy] = useState('');
  const [sortOrder] = useState('');
  const [filters] = useState({
    status: null
  });

  const [menuUserID, setMenuUserID] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [roles, setRoles] = useState([]);

  const [userInviteLinkOpen, setUserInviteLinkOpen] = useState(false);
  const [userInviteLink, setUserInviteLink] = useState('');

  const maxWidth = useMediaQuery('(max-width: 470px)');
  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  // Usually query is done on backend with indexing solutions
  const sortedUsers = applySort(users, sortBy, sortOrder);
  const filteredUsers = applyUserFilters(sortedUsers, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);

  const handleOpenMenu = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuUserID(id);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setMenuUserID(null);
    setMenuAnchorEl(null);
  };

  const getAllUsers = async () => {
    setLoading(true);
    if (user && user.companyID) {
      // let sortby = sortBy;
      // if (sortBy === 'name') {
      //   sortby = 'email';
      // }
      try {
        // NOTE: Not going with pagination in V1
        // const url = `${API.usersByCompany}/${user.companyID}?${users && users.length > 0 ? `fromId=${users[users.length - 1].id}` : ''}&numOfRecords=${limit}&sortBy=${sortby || 'id'}&sortOrder=${sortOrder || 'ASC'}`;
        const url = `${API.getUsersByCompanies}${encodeURIComponent(user.companyID)}`;
        const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setUsers(response.data.data.map((u) => ({ ...u, name: `${u.firstname} ${u.lastname}` })));
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const closeUserInviteLinkPopup = () => {
    setUserInviteLinkOpen(false);
    getAllUsers();
  };

  const onEditUser = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    setEditUser(u);
    setEditUserOpen(true);
  };

  const addNewUser = async (event, body) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    if ((user.usersCount + 1) > user.maxUsers) {
      enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT'), {
        variant: 'error',
        persist: true,
        style: { maxWidth: 400 }
      });
      return;
    }

    try {
      const response = await Axios.post(
        `${API.userProfile}`,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (isBranded(user.brand)) {
          setUserInviteLink(response.data.data.link);
          setUserInviteLinkOpen(true);
        } else {
          enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_SUCCESS', { email: body.email }), {
            variant: 'success',
            persist: true,
            style: { maxWidth: 400 }
          });
          getAllUsers();
        }
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        persist: true,
        style: { maxWidth: 400 }
      });
    }
  };

  const revokeUserInvitation = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    try {
      const response = await Axios.post(
        `${API.revokeUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          const updatedUser = {
            ...user,
            usersCount: user.usersCount - 1
          };
          updateUser(updatedUser);
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_REVOKE_SUCCESS', { email: u.email }), {
          variant: 'success',
          persist: true,
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        persist: true,
        style: { maxWidth: 400 }
      });
    }
  };

  const removeUser = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    try {
      const response = await Axios.post(
        `${API.removeUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          const updatedUser = {
            ...user,
            usersCount: user.usersCount - 1
          };
          updateUser(updatedUser);
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_REMOVE_SUCCESS', { email: u.email }), {
          variant: 'success',
          persist: true,
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        persist: true,
        style: { maxWidth: 400 }
      });
    }
  };

  const restoreUser = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    if ((user.usersCount + 1) > user.maxUsers) {
      enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT'), {
        variant: 'error',
        persist: true,
        style: { maxWidth: 400 }
      });
      return;
    }

    try {
      const response = await Axios.post(
        `${API.restoreUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          const updatedUser = {
            ...user,
            usersCount: user.usersCount + 1
          };
          updateUser(updatedUser);
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_RESTORE_SUCCESS', { email: u.email }), {
          variant: 'success',
          persist: true,
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        persist: true,
        style: { maxWidth: 400 }
      });
    }
  };

  const deleteUser = async (event, u) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMenu(event);

    try {
      const response = await Axios.delete(
        `${API.deleteUser}`,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), { email: u.email })
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_DELETE_SUCCESS', { email: u.email }), {
          variant: 'success',
          persist: true,
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t), {
        variant: 'error',
        persist: true,
        style: { maxWidth: 400 }
      });
    }
  };

  const handleUserManageMenuClick = (event, u, option) => {
    switch (option) {
      case 'change':
        onEditUser(event, u);
        break;

      case 'resend':
        addNewUser(event, u);
        break;

      case 'revoke':
        revokeUserInvitation(event, u);
        break;

      case 'remove':
        removeUser(event, u);
        break;

      case 'restore':
        restoreUser(event, u);
        break;

      case 'delete':
        deleteUser(event, u);
        break;

      default:
        break;
    }
  };

  const addUserCallback = async () => {
    await getAllUsers();
    if (!paginatedUsers || paginatedUsers.length === 0) {
      history.push('/account?feedback-first-user=success', { tab: 2 });
    } else {
      history.push('/account', { tab: 2 });
    }
  };

  const getAllRoles = async () => {
    try {
      const url = `${API.getUserRoles}`;
      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setRoles(response.data.data);
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
    getAllRoles();
  }, []); // eslint-disable-line

  // const handleStatusChange = (event) => {
  //   event.persist();
  //   let value = null;
  //   if (event.target.value !== 'All') {
  //     value = event.target.value;
  //   }

  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     status: value
  //   }));
  // };

  // const handleQueryChange = (event) => {
  //   event.persist();
  //   setQuery(event.target.value);
  // };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  // const handleOrderByColumn = (column) => {
  //   if (sortBy === column) {
  //     if (sortOrder === 'asc') {
  //       setSortOrder('desc');
  //       return true;
  //     }
  //     setSortOrder('asc');
  //     return true;
  //   }
  //   setSortBy(column);
  //   setSortOrder('asc');
  //   return true;
  // };

  const handleAddNewUser = () => {
    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setAddUserOpen(true);
  };

  // const handleInviteNewUser = () => {
  //   if (!validateToken()) {
  //     enqueueSnackbar('Login session Inactive, Please login again!', {
  //       variant: 'error',
  //       persist: true
  //     });
  //     setTimeout(() => {
  //       authService.logout(LOGIN_PANEL_URL);
  //     }, 2000);
  //     return;
  //   }

  //   setInviteUserOpen(true);
  // };

  const isNotRemoveCurrentUser = (option, u) => !(u.email === user.email && option === 'remove');

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <>
      <Grid container className={classes.header}>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.headerTitle}>
            {ready && t('ACCOUNT_SETTINGS_USERS')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.headerActions}>
          {/* {
            user && (
              <Tooltip title={t('ACCOUNT_SETTINGS_USERS_INVITE_USER_TOOLTIP')}>
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.actionInvite}
                  onClick={handleInviteNewUser}
                >
                  <SvgIcon
                    fontSize="small"
                    className={classes.actionIcon}
                  >
                    <PlusCircleIcon />
                  </SvgIcon>
                  {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_USER')}
                </Button>
              </Tooltip>
            )
          } */}
          {
            user && (
              <Tooltip
                title={
                  user.usersCount >= user.maxUsers // eslint-disable-line
                    ? t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT')
                    : isActionPermitted(permissions.userAdd, user.permissions)
                      ? t('ACCOUNT_SETTINGS_USERS_ADD_TOOLTIP')
                      : t('ACCOUNT_SETTINGS_USERS_ADD_TOOLTIP_PERMISSION')
                }
              >
                <span>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={user.usersCount >= user.maxUsers || !isActionPermitted(permissions.userAdd, user.permissions)}
                    className={classes.action}
                    onClick={handleAddNewUser}
                  >
                    <SvgIcon
                      fontSize="small"
                      className={classes.actionIcon}
                    >
                      <PlusCircleIcon />
                    </SvgIcon>
                    {ready && t('ACCOUNT_SETTINGS_USERS_ADD_USER')}
                  </Button>
                </span>
              </Tooltip>
            )
          }
        </Grid>
      </Grid>
      {
        user.usersCount >= user.maxUsers && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.addUserDisabled}
        >
          <Alert severity="warning" className={classes.warningBanner}>
            <Typography>
              {ready && t('ACCOUNT_SETTINGS_ADD_USER_DISABLED')}
              <strong>
                <Link href="/account/subscription">
                  {ready && t('ACCOUNT_SETTINGS_ADD_USER_UPDATE_LINK')}
                </Link>
              </strong>
            </Typography>
          </Alert>
        </Grid>
        )
      }
      {
        paginatedUsers && paginatedUsers.length > 0
          ? (
            <Card
              className={clsx(classes.root, className)}
              {...rest}
            >
              <PerfectScrollbar>
                <Box minWidth="100%">
                  <Table size="small">
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.headerStyle}>
                          {ready && t('ACCOUNT_SETTINGS_USERS_USER')}
                        </TableCell>
                        {/* <SortTableCell
                          columnLabel="User"
                          column="name"
                          sortBy={sortBy}
                          sortOrder={sortOrder}
                          handleOrderByColumn={handleOrderByColumn}
                        /> */}
                        <Hidden only={['xs', 'sm']}>
                          <TableCell className={classes.headerStyle}>
                            {ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')}
                          </TableCell>
                        </Hidden>
                        <Hidden only={['xs', 'sm', 'md']}>
                          <TableCell className={classes.headerStyle}>
                            {ready && t('ACCOUNT_SETTINGS_USERS_ROLE')}
                          </TableCell>
                        </Hidden>
                        <TableCell className={classes.headerStyle}>
                          {ready && t('ACCOUNT_SETTINGS_USERS_STATUS')}
                        </TableCell>
                        <TableCell className={classes.headerStyle} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedUsers.map((u) => (
                        <TableRow
                          hover
                          key={u.id}
                          className={classes.pointer}
                        >
                          <TableCell className={classes.cellStyle}>
                            <Box
                              display="flex"
                              alignItems="center"
                            >
                              <Avatar
                                alt="user"
                                className={classes.userImage}
                                src={u.avatar}
                              />
                              <Box ml={2} mr={2} display="flex" flexDirection="column">
                                <Typography
                                  color="inherit"
                                    // component={RouterLink}
                                  to="#"
                                  variant="h6"
                                  className={classes.cellStyle}
                                >
                                  {u.name}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <Hidden only={['xs', 'sm']}>
                            <TableCell className={classes.cellStyle}>
                              {u.email}
                            </TableCell>
                          </Hidden>
                          <Hidden only={['xs', 'sm', 'md']}>
                            <TableCell className={classes.cellStyle}>
                              <Grid className={classes.roleLabel}>
                                {u.userRole}
                              </Grid>
                            </TableCell>
                          </Hidden>
                          <TableCell className={classes.cellStyle}>
                            <Hidden only={['xs']}>
                              <Grid className={clsx(classes[u.status.toLowerCase()], classes.statusLabel)}>
                                {u.status}
                              </Grid>
                            </Hidden>
                            <Hidden smUp>
                              <Grid className={clsx(classes[`${u.status.toLowerCase()}Dot`], classes.statusDot)} />
                            </Hidden>
                          </TableCell>
                          <TableCell width={56} align="center">
                            <Tooltip
                              title={isActionPermitted(permissions.userLock, user.permissions)
                                  && isActionPermitted(permissions.userPermissionManage, user.permissions)
                                  && isActionPermitted(permissions.userRoleManage, user.permissions)
                                ? ''
                                : t('ACCOUNT_SETTINGS_USERS_EDIT_TOOLTIP_PERMISSION')}
                            >
                              <span>
                                <IconButton
                                  disabled={!isActionPermitted(permissions.userLock, user.permissions)
                                      || !isActionPermitted(permissions.userPermissionManage, user.permissions)
                                      || !isActionPermitted(permissions.userRoleManage, user.permissions)}
                                  onClick={(e) => handleOpenMenu(e, u.id)}
                                >
                                  <SvgIcon style={{ display: 'flex' }}>
                                    <MoreVertIcon className={classes.moreIcon} />
                                  </SvgIcon>
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Menu
                              id="user-actions"
                              anchorEl={menuAnchorEl}
                              keepMounted
                              open={Boolean(menuAnchorEl) && menuUserID === u.id}
                              onClose={handleCloseMenu}
                            >
                              {userEditOptions.map((option) => {
                                if (isUserManageOptionValid(option, u.status.toLowerCase()) && isNotRemoveCurrentUser(option, u)) {
                                  return (
                                    <MenuItem key={option} onClick={(e) => handleUserManageMenuClick(e, u, option)}>
                                      {userEditOptionLabels[option]}
                                    </MenuItem>
                                  );
                                }
                                return null;
                              })}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <div className={classes.caption}>
                {
                  !maxWidth && `${filteredUsers.length} user${filteredUsers.length !== 1 ? 's' : ''} available`
                }
                <TablePagination
                  component="div"
                  classes={{
                    root: classes.paginationComp,
                    toolbar: classes.paginationToolbar,
                    caption: classes.paginationCaption,
                    selectRoot: classes.paginationCaption,
                    select: classes.paginationMenu,
                    menuItem: classes.paginationMenu,
                    actions: classes.paginationActions,
                  }}
                  count={filteredUsers.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
                />
              </div>
            </Card>
          )
          : <EmptyText str="ACCOUNT_SETTINGS_USERS_NO_USERS" />
      }
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={addUserOpen}
        onClose={() => setAddUserOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={addUserOpen}>
          <div className={classes.halfPaper}>
            <Grid className={classes.modalHeader}>
              <Typography className={classes.headerTitle}>
                {ready && t('ACCOUNT_SETTINGS_USERS_ADD_NEW_USER')}
              </Typography>
              <IconButton onClick={() => setAddUserOpen(false)}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
            <UserCreateForm
              user={user}
              handleClose={() => setAddUserOpen(false)}
              updateUser={updateUser}
              onGetAllUsers={addUserCallback}
            />
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="invite-user-modal-title"
        aria-describedby="invite-user-modal-description"
        className={classes.modal}
        open={inviteUserOpen}
        onClose={() => setInviteUserOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={inviteUserOpen}>
          <div className={classes.paper}>
            <Grid className={classes.modalHeader}>
              <Typography className={classes.headerTitle}>
                {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_NEW_USER')}
              </Typography>
              <IconButton onClick={() => setInviteUserOpen(false)}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
            <UserInviteForm user={user} handleClose={() => setInviteUserOpen(false)} onGetAllUsers={getAllUsers} />
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={editUserOpen}
        onClose={() => setEditUserOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editUserOpen}>
          <div className={classes.paper}>
            <Grid className={classes.modalHeader}>
              <Typography className={classes.headerTitle}>
                {t('ACCOUNT_SETTINGS_USERS_MANAGE_USER_PERMISSIONS')}
              </Typography>
              <IconButton onClick={() => setEditUserOpen(false)}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
            <UserEditForm
              user={user}
              roles={roles}
              editUser={editUser}
              handleClose={() => setEditUserOpen(false)}
              onGetAllUsers={getAllUsers}
            />
          </div>
        </Fade>
      </Modal>
      <NewUserInviteLinkPopup
        isOpen={userInviteLinkOpen}
        handleClose={closeUserInviteLinkPopup}
        link={userInviteLink}
      />
    </>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  updateUser: PropTypes.func
};

Results.defaultProps = {
  users: []
};

export default withRouter(Results);
