import { createContext } from 'react';

const ConfigContext = createContext({
  APP_VERSION: '',
  ENVIRONMENT: '',
  BRAND_NAME: 'Procys',
  BRAND_DOMAIN: 'procys.com',
  IS_BRAND_PROCYS: true,
  SUPPORT_EMAIL: 'support@procys.com',
  BRAND_URL: 'https://www.procys.com',
  LOGIN_PANEL_URL: 'https://login.procys.com/',
  API: {
    verifyToken: 'https://auth.procys.com/v1/auth/tokens/validate',
    getStatsByUser: 'https://app-api.procys.com/v1/users/stats',
    getAppStats: 'https://app-api.procys.com/v1/users/stats/db',

    getDashboardStats: 'https://provider.procys.com/v1/statistics/dashboard',

    getAttachment: 'https://app-api.procys.com/v1/files/attachments/',
    getInvoiceImage: 'https://app-api.procys.com/v1/files/documents/',
    getInvImgByCompAndTrans: 'https://app-api.procys.com/v1/files/invoices/images/company/',
    getInvoiceImageByTrans: 'https://app-api.procys.com/v1/files/invoices/images/transaction/',
    getAllInvoices: 'https://app-api.procys.com/v1/documents/company',
    getArchivedInvoices: 'https://app-api.procys.com/v1/documents/archived/company',
    getInvoiceByID: 'https://app-api.procys.com/v1/documents/assistant-id/',
    updateInvoice: 'https://app-api.procys.com/v1/documents/',
    uploadDocument: 'https://app-api.procys.com/v1/documents',
    bulkUpload: 'https://app-api.procys.com/v1/documents/bulk',
    documentStatus: 'https://app-api.procys.com/v1/documents/status',
    bulkStatus: 'https://app-api.procys.com/v1/documents/bulk/status',
    deleteDuplicates: 'https://app-api.procys.com/v1/documents/delete/duplicates',
    documentOwner: 'https://app-api.procys.com/v1/documents/owner',
    bulkDocumentOwner: 'https://app-api.procys.com/v1/documents/bulk/owner',
    getXmlInvoice: 'https://app-api.procys.com/v1/documents/xml/',
    invoices: 'https://app-api.procys.com/v1/invoices/',
    updateIncludeLinesInExport: 'https://app-api.procys.com/v1/documents/lines/export',
    bulkDownload: 'https://app-api.procys.com/v1/documents/bulk/download',
    getAllInvoiceIds: 'https://app-api.procys.com/v1/documents/id/company',
    getAllArchivedInvoiceIds: 'https://app-api.procys.com/v1/documents/archived/id/company',
    processNoneDuplicate: 'https://app-api.procys.com/v1/documents/assistant/resend',

    exportUBL: 'https://app-api.procys.com/v1/documents/export/ubl/',
    exportCSV: 'https://app-api.procys.com/v1/documents/export/csv/',
    exportExcel: 'https://app-api.procys.com/v1/documents/export/excel/',
    export: 'https://app-api.procys.com/v1/documents/export/',
    exportBulk: 'https://app-api.procys.com/v1/documents/export/bulk/',
    exportToEmail: 'https://app-api.procys.com/v1/documents/export/email/',
    exportFieldMapping: 'https://app-api.procys.com/v1/documents/export/mapping/',

    userProfile: 'https://auth.procys.com/v1/external/user-details',
    updateUserByCreator: 'https://auth.procys.com/v1/external/users/creator',
    usersByCompany: 'https://auth.procys.com/v1/external/users/companies',
    userProfileImage: 'https://auth.procys.com/v1/external/users/profile-image',
    userAvatarImage: 'https://auth.procys.com/v1/external/users/avatar-image',
    changePassword: 'https://auth.procys.com/v1/auth/change-password',
    getLanguages: 'https://provider.procys.com/v1/admin/languages',
    getLanguageJSON: 'https://provider.procys.com/v1/admin/languages/json',
    getAllRoles: 'https://provider.procys.com/v1/admin/roles',
    getUserRoles: 'https://auth.procys.com/v1/users/roles',
    revokeUser: 'https://auth.procys.com/v1/users/add/revoke',
    removeUser: 'https://auth.procys.com/v1/users/remove',
    restoreUser: 'https://auth.procys.com/v1/users/restore',
    deleteUser: 'https://auth.procys.com/v1/users/delete',

    userCards: 'https://app-api.procys.com/v1/subscriptions/cards/customers',
    changeDefaultPaymentMethod: 'https://app-api.procys.com/v1/subscriptions/cards/change',
    subscriptionDetails: 'https://app-api.procys.com/v1/subscriptions/customers',
    userInvoices: 'https://app-api.procys.com/v1/subscriptions/invoices',

    suppliers: 'https://app-api.procys.com/v1/suppliers',
    suppliersV2: 'https://app-api.procys.com/v2/suppliers',
    findSuppliers: 'https://app-api.procys.com/v1/suppliers/find/all',
    offices: 'https://app-api.procys.com/v1/offices',
    vatGroups: 'https://app-api.procys.com/v1/vatcodes',
    glAccounts: 'https://app-api.procys.com/v1/glaccounts',
    costCenters: 'https://app-api.procys.com/v1/costcenters',
    projects: 'https://app-api.procys.com/v1/projects',
    dimensions: 'https://app-api.procys.com/v1/dimensions/',
    processes: 'https://app-api.procys.com/v1/processes/',

    costCentersByCompany: 'https://app-api.procys.com/v1/dimensions/costcenters/companies',
    costCenterByCode: 'https://app-api.procys.com/v1/dimensions/costcenters/code',
    projectsByCompany: 'https://app-api.procys.com/v1/dimensions/projects/companies',
    projectByCode: 'https://app-api.procys.com/v1/dimensions/projects/code',
    vatCodesByCompany: 'https://app-api.procys.com/v1/dimensions/vatcodes/companies',
    vatCodeByCode: 'https://app-api.procys.com/v1/dimensions/vatcodes/code',
    glAccountsByCompany: 'https://app-api.procys.com/v1/dimensions/glaccounts/companies',
    glAccountByCode: 'https://app-api.procys.com/v1/dimensions/glaccounts/code',
    glAYearsByCompany: 'https://app-api.procys.com/v1/dimensions/glaccounts/years/companies',
    glAPeriodsByCompany: 'https://app-api.procys.com/v1/dimensions/glaccounts/periods/companies',
    getDimensionLogsByCompany: 'https://provider.procys.com/v1/dimensions/',

    inviteUser: 'https://app-api.procys.com/v1/company-users/invite',
    inviteUserCallback: 'https://app-api.procys.com/v1/company-users/invite/email-confirmation/',
    getCompaniesByUser: 'https://app-api.procys.com/v1/company-users/users/',
    getUsersByCompanies: 'https://app-api.procys.com/v1/company-users/companies/',
    getProfileImage: 'https://auth.procys.com/v1/external/users/avatar/',

    company: 'https://auth.procys.com/v1/companies',
    changeCompany: 'https://app-api.procys.com/v1/companies/change',
    deleteCompany: 'https://auth.procys.com/v1/companies',
    companyAndUser: 'https://auth.procys.com/v1/company-and-user',
    companyAndUserDetails: 'https://auth.procys.com/v1/company-and-user/details',

    getLogsByCompany: 'https://provider.procys.com/v1/admin/logs/invoice/company',
    getLogsByInvoice: 'https://provider.procys.com/v1/admin/logs/invoice',
    getInvoiceLogByID: 'https://provider.procys.com/v1/admin/logs/invoices',
    getUploadLogsByCompany: 'https://provider.procys.com/v1/admin/logs/invoice/upload/company',
    getExportLogsByCompany: 'https://app-api.procys.com/v1/documents/export/list',
    downloadExportedFile: 'https://app-api.procys.com/v1/documents/export/file',

    getSupplierLogsByCompany: 'https://provider.procys.com/v1/admin/logs/supplier/company',
    getLogsBySuppierCode: 'https://provider.procys.com/v1/admin/logs/supplier/code',
    getSupplierLogByID: 'https://provider.procys.com/v1/admin/logs/supplier',

    updateCompanySubscription: 'https://provider.procys.com/v1/admin/companies/subscriptions/change',
    updateSubscription: 'https://app-api.procys.com/v1/subscriptions/customers',
    cancelSubscriptionChange: 'https://app-api.procys.com/v1/subscriptions/cancel',
    buyExtraInvoicesPackage: 'https://app-api.procys.com/v1/subscriptions/customers/extra',
    subscriptionUpgradeContact: 'https://app-api.procys.com/v1/subscriptions/customers/contact',

    integrations: 'https://app-api.procys.com/v1/integrations',
    sendAppRequest: 'https://app-api.procys.com/v1/integrations/request',
    sendNotifiRequest: 'https://app-api.procys.com/v1/integrations/notify',
    savedDetailsTf: 'https://app-api.procys.com/v1/integrations/twinfield',
    savedDetailsBc: 'https://app-api.procys.com/v1/integrations/bc/manage',
    savedDetailsGStock: 'https://app-api.procys.com/v1/integrations/gstock',
    savedDetailsQuickbooks: 'https://app-api.procys.com/v1/integrations/quickbooks',
    savedDetailsHolded: 'https://app-api.procys.com/v1/integrations/holded',
    savedDetailsSFTP: 'https://app-api.procys.com/v1/integrations/sftp',
    testSFTPConnection: 'https://app-api.procys.com/v1/integrations/sftp/connection/',
    quickbooksOAuth2Callback: 'https://app-api.procys.com/v1/integrations/quickbooks/oauth2/callback',
    checkQuickbooksConnection: 'https://app-api.procys.com/v1/integrations/quickbooks/connection',
    checkGStockConnection: 'https://provider.docunecta.net/v1/integrations/gstock/connection',
    checkHoldedConnection: 'https://app-api.procys.com/v1/integrations/holded/connection',
    syncQuickbooks: 'https://app-api.procys.com/v1/integrations/quickbooks/vendor',
    syncSFTP: 'https://app-api.procys.com/v1/integrations/sftp/dimension/sync',
    syncHolded: 'https://app-api.procys.com/v1/integrations/sync-dimensions/holded',
    syncGstock: 'https://app-api.procys.com/v1/integrations/gstock/sync',
    getAPIDetails: 'https://app-api.procys.com/v1/integrations/api/assistant/manage',
    saveEmailBc: 'https://app-api.procys.com/v1/integrations/bc/email',
    saveEmailReceive: 'https://app-api.procys.com/v1/integrations/email/receive/allowed',
    saveEmailDeliver: 'https://app-api.procys.com/v1/integrations/email/deliver/allowed',
    saveDeliverFormat: 'https://app-api.procys.com/v1/integrations/email/deliver/format',
    getTokenPairFromAccessToken: 'https://auth.procys.com/v1/auth/tokens/access-token',
    getTokenPairFromRefreshToken: 'https://auth.procys.com/v1/auth/tokens/refresh-token',
    getDeliverFormats: 'https://app-api.procys.com/v1/integrations/email/deliver/format',

    fieldsRequest: 'https://auth.procys.com/v1/fields/request',
    fieldsCustomReceivers: 'https://auth.procys.com/v1/fields/receivers/custom',
    fields: 'https://auth.procys.com/v1/fields',

    selectDocumentType: 'https://app-api.procys.com/v1/companies/documents/',

    feedbackLogs: 'https://provider.procys.com/v1/logs/feedback',

    splitDocument: 'https://app-api.procys.com/v1/documents/upload/split',
    aiSplit: 'https://app-api.procys.com/v1/documents/upload/split/ai/',
    bulkSplit: 'https://app-api.procys.com/v1/documents/upload/split/bulk',

    brand: 'https://provider.procys.com/v1/brands',
    brandLogo: 'https://provider.procys.com/v1/brands/logo/',
    brandFavicon: 'https://provider.procys.com/v1/brands/favicon/',
    brandStatus: 'https://provider.procys.com/v1/brands/status',

    customisations: 'https://app-api.procys.com/v1/companies/customisations',
    updateCommsLang: 'https://app-api.procys.com/v1/companies/comms-language',

    qbTerms: 'https://app-api.procys.com/v1/custom/objects/qb-terms/companies',
    partnerStats: 'https://app-api.procys.com/v1/partners/customers',
  }
});

export default ConfigContext;
