import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const DeleteDocumentModal = ({
  openDeleteConf,
  onCancelDelete,
  onDeleteInvoice,
}) => {
  const classes = useStyles();
  const { t, ready: tReady } = useTranslation();

  return (
    <Modal
      className={classes.modal}
      open={openDeleteConf}
      onClose={onCancelDelete}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openDeleteConf}>
        <div className={classes.paperModal}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {tReady && t('INVOICE_DELETE_INVOICE')}
            </Typography>
            <IconButton onClick={onCancelDelete}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Grid className={classes.deleteMessageText}>
            <Typography variant="caption">
              {tReady && t('INVOICE_DELETE_CONFIRMATION_MESSAGE')}
            </Typography>
          </Grid>
          <Grid className={classes.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                className={classes.closeBtn}
                onClick={onCancelDelete}
              >
                {tReady && t('INVOICE_DELETE_CANCEL')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitBtn}
                onClick={() => onDeleteInvoice()}
              >
                {tReady && t('INVOICE_DELETE_CONFIRM')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

DeleteDocumentModal.propTypes = {
  openDeleteConf: PropTypes.bool.isRequired,
  onCancelDelete: PropTypes.func.isRequired,
  onDeleteInvoice: PropTypes.func.isRequired,
};

export default DeleteDocumentModal;
