import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: 20
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    maxWidth: 1000,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#FFFFFF'
  },
  headerStyle: {
    fontSize: 28,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px',
    paddingBottom: 20
  },
  halfPaper: {
    width: '90%',
    maxWidth: 420,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  warnContainer: {
    padding: 16,
    backgroundColor: '#FEF4E9',
    marginBottom: 16
  },
  warnTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#614621',
    paddingBottom: 8
  },
  warnText: {
    fontSize: 14,
    color: '#4E4B48'
  },
  warnBtn: {
    textTransform: 'none',
    marginTop: 8
  },
  description: {
    fontSize: 14,
    color: '#334D6E'
  },
  requestContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24
  },
  requestTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#334D6E'
  },
  requestText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
    marginBottom: 24
  },
  requestBtn: {
    textTransform: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
  },
  modalBody: {
    maxWidth: 600,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },
  header: {
    marginBottom: 24,
    color: '#334D6E',
    textAlign: 'center'
  },
  noPadding: {
    padding: '0px 16px',
    color: '#334D6E',
    fontSize: 13
  },
  closeBtn: {
    padding: '2!important',
  },
  fieldStyle: {
    paddingBottom: 24
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16
  },
  cancelBtn: {
    color: '#334D6E',
    border: '1px #e4e4e4 solid',
    textTransform: 'none',
    marginLeft: 24
  },
}));

export default useStyles;
