const useStyles = (theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: '40%',
    paddingTop: 80,
  },
  logo: {
    marginRight: 10
  },
  loadingIndicator: {
    marginTop: 30
  }
});

export default useStyles;
