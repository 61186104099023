import * as actionTypes from './actionTypes';

export const fetchGlasStart = () => ({
  type: actionTypes.FETCH_GLAS_START
});

export const fetchGlasSuccess = (glas, total) => ({
  type: actionTypes.FETCH_GLAS_SUCCESS,
  glas,
  total
});

export const fetchGlasFail = (error) => ({
  type: actionTypes.FETCH_GLAS_FAIL,
  error
});

export const fetchGlas = (companyID, search) => ({
  type: actionTypes.FETCH_GLAS,
  companyID,
  search
});

export const fetchGlaListStart = () => ({
  type: actionTypes.FETCH_GLA_LIST_START
});

export const fetchGlaListSuccess = (glas, total) => ({
  type: actionTypes.FETCH_GLA_LIST_SUCCESS,
  glas,
  total
});

export const fetchGlaListFail = (error) => ({
  type: actionTypes.FETCH_GLA_LIST_FAIL,
  error
});

export const fetchGlaList = (companyID, page, limit, search, loadFullScreen) => ({
  type: actionTypes.FETCH_GLA_LIST,
  companyID,
  page,
  limit,
  search,
  loadFullScreen
});
