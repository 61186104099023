import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid, Menu, TextField, Typography
} from '@material-ui/core';
import useStyles from './style';

const CustomPeriodFilter = (props) => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (`0${currentDate.getMonth() + 1}`).slice(-2);
  const day = (`0${currentDate.getDate()}`).slice(-2);
  const hours = (`0${currentDate.getHours()}`).slice(-2);
  const minutes = (`0${currentDate.getMinutes()}`).slice(-2);

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  let timeZone = new Date().toString().split(' ')[5];
  timeZone = `${timeZone.slice(0, timeZone.length - 2)}:${timeZone.slice(timeZone.length - 2)}`;

  const classes = useStyles();
  const { anchorElCustomPeriod, handleCustomPeriodClose, handleCustomPeriodApply } = props;
  const { ready, t } = useTranslation();
  const [from, setFrom] = useState(formattedDateTime);
  const [to, setTo] = useState(formattedDateTime);

  return (
    <Menu
      open={Boolean(anchorElCustomPeriod)}
      anchorEl={anchorElCustomPeriod}
      onClose={handleCustomPeriodClose}
      transformOrigin={{
        horizontal: 'left',
      }}
      classes={{
        paper: classes.filterMenu
      }}
    >
      <Grid className={classes.itemRow}>
        <TextField
          id="datetime-local"
          label={t('DASHBOARD_INVOICES_FROM')}
          type="datetime-local"
          value={from}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setFrom(e.target.value)}
        />
      </Grid>
      <Grid className={classes.itemRow}>
        <TextField
          id="datetime-local"
          label={t('DASHBOARD_INVOICES_TO')}
          type="datetime-local"
          value={to}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setTo(e.target.value)}
        />
      </Grid>
      <Grid className={classes.itemRow}>
        <Typography className={classes.text}>{timeZone}</Typography>
        <Button
          variant="outlined"
          className={classes.cancelButtonSup}
          type="submit"
          onClick={handleCustomPeriodClose}
        >
          {ready && t('DASHBOARD_INVOICES_CANCEL')}
        </Button>
        <Button
          className={classes.applyButtonSup}
          variant="contained"
          color="secondary"
          onClick={() => handleCustomPeriodApply(from, to)}
        >
          {ready && t('DASHBOARD_INVOICES_APPLY')}
        </Button>
      </Grid>
    </Menu>
  );
};

CustomPeriodFilter.propTypes = {
  anchorElCustomPeriod: PropTypes.object,
  handleCustomPeriodClose: PropTypes.func,
  handleCustomPeriodApply: PropTypes.func
};

export default CustomPeriodFilter;
