import React from 'react';
import PropTypes from 'prop-types';
import {
  Document as DocumentPDF,
  Page as PagePDF,
} from 'react-pdf';
import {
  Box, Button, Grid, TextField
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import RotateIcon from '@material-ui/icons/RotateRightOutlined';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import FileSaver from 'file-saver';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';

import useStyles from './style';

function PdfView({
  t, ready, fileResponse, fetchingFile, imgName, canOpenInNewTab, setNumPages
}) {
  const classes = useStyles();

  const [noPages, setNoPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rotateAngle, setRotateAngle] = React.useState(0);
  const [gotoPage, setGotoPage] = React.useState(1);
  const [scale, setScale] = React.useState(1);

  const nextPage = () => {
    if (noPages > pageNumber) {
      setPageNumber(pageNumber + 1);
      setGotoPage(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      setGotoPage(pageNumber - 1);
    }
  };

  const zoomIn = () => {
    setScale(scale + 0.1);
  };

  const zoomOut = () => {
    if (scale > 1) {
      setScale(scale - 0.1);
    }
  };

  // Move between pages with arrow keys and
  // Zoom in, out with minus, equal(plus) keys
  document.onkeydown = (e) => {
    if (e.code === 'ArrowRight') {
      nextPage();
    }
    if (e.code === 'ArrowLeft') {
      prevPage();
    }
    if (e.code === 'Equal') {
      zoomIn();
    }
    if (e.code === 'Minus') {
      zoomOut();
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNoPages(numPages);
    if (setNumPages) {
      setNumPages(numPages);
    }
  }

  const onClickGotoPage = (event) => {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();

      if (gotoPage <= noPages && gotoPage >= 1) {
        setPageNumber(parseInt(gotoPage, 10));
        return;
      }
      setPageNumber(1);
      setGotoPage(1);
    }
  };

  return (
    <>
      {
        noPages && (
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.topBar}>
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <TextField
                    type="number"
                    variant="outlined"
                    className={classes.searchInput}
                    defaultValue={pageNumber}
                    value={gotoPage}
                    onChange={(e) => setGotoPage(e.target.value)}
                    onKeyPress={(event) => onClickGotoPage(event)}
                  />
                  <>{`/${noPages}`}</>
                  {
                    noPages && noPages > 1 && (
                      <>
                        <Button className={classes.buttonCls} disabled={pageNumber === 1} onClick={() => prevPage()}>
                          <NavigateBeforeIcon />
                        </Button>
                        <Button className={classes.buttonCls} disabled={pageNumber === noPages} onClick={() => nextPage()}>
                          <NavigateNextIcon />
                        </Button>
                      </>
                    )
                  }
                </Grid>
              </Grid>
              <Grid style={{ flex: 1 }} />
              <Grid>
                <Button className={classes.buttonCls} onClick={() => setRotateAngle(rotateAngle + 90)}>
                  <RotateIcon />
                </Button>
                <Button className={classes.buttonCls} onClick={() => zoomIn()}>
                  <ZoomInIcon />
                </Button>
                <Button className={classes.buttonCls} onClick={() => zoomOut()}>
                  <ZoomOutIcon />
                </Button>
                <Button
                  className={classes.buttonCls}
                  onClick={() => FileSaver.saveAs(fileResponse, imgName)}
                >
                  <DownloadIcon />
                </Button>
                {canOpenInNewTab && (
                  <Button
                    className={classes.buttonCls}
                    onClick={() => window.open(`/files/image/${imgName}`)}
                  >
                    <OpenInNewIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        )
      }
      <Grid container direction="row" alignItems="center" justifyContent="center" style={{ overflow: 'scroll' }}>
        <Grid item xs={12}>
          <Grid className={scale === 1 ? classes.pdfContainer : classes.pdfContainerZoom}>
            <Box
              display="flex"
              justifyContent="center"
              className={classes.pdfBackground}
            >
              <DocumentPDF
                onLoadProgress={() => (<LoadingScreenText />)}
                file={fileResponse}
                error={ready && t('INVOICE_EDIT_INVOICE_PREVIEW_FAILED')}
                onLoadSuccess={onDocumentLoadSuccess}
                className={classes.documentStyle}
                rotate={rotateAngle}
                noData={fetchingFile
                  ? `${ready && t('INVOICE_EDIT_INVOICE_PREVIEW_LOADING')}...`
                  : `${ready && t('INVOICE_EDIT_INVOICE_PREVIEW_FAILED')}`}
              >
                <PagePDF scale={scale} pageNumber={pageNumber} />
              </DocumentPDF>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

PdfView.propTypes = {
  t: PropTypes.func,
  ready: PropTypes.bool,
  fileResponse: PropTypes.string,
  fetchingFile: PropTypes.bool,
  imgName: PropTypes.string,
  canOpenInNewTab: PropTypes.bool,
  setNumPages: PropTypes.func
};

export default PdfView;
