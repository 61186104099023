import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  confirmSpreadBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px',
  },
  spreadInvoiceContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex',
    marginBottom: 16
  },
  inputItem: {
    padding: '8px 0px 16px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    }
  },
}));

export default useStyles;
