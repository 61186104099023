import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import {
  Grid, Typography, Link, Card, Button
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Axios from 'axios';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from './style';
import BrandCompanyInfo from '../BrandCompanyInfo/BrandCompanyInfo';
import ConnectionSettings from '../ConnectionSettings/ConnectionSettings';
import BackToProcysConfirmation from '../BackToProcysConfirmation/BackToProcysConfirmation';

function BrandSettings({ className, ...rest }) {
  const { API, BRAND_URL } = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  const [brandDetails, setBrandDetails] = useState({
    commercialName: '',
    domain: '',
    supportEmail: '',
  });
  const [openBackToProcysConfirmation, setOpenBackToProcysConfirmation] = useState(false);

  const fetchBrandDetails = async () => {
    try {
      const response = await Axios.get(
        API.brand,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (response.data.success) {
        if (response.data.data) {
          const { status } = response.data?.data;
          setIsEditable(status !== 'active');
          setBrandDetails(response.data.data);
        } else {
          setIsEditable(true);
        }
      }
    } catch (error) {
      setIsEditable(false);
      setBrandDetails({
        commercialName: '',
        domain: '',
        supportEmail: '',
      });
    }
  };

  useEffect(() => {
    fetchBrandDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const closeBackToProcysConfirmation = () => {
    setOpenBackToProcysConfirmation(false);
  };

  const handleStatusChange = async (status) => {
    try {
      const response = await Axios.post(
        API.brandStatus,
        {
          status,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (response.data.success) {
        enqueueSnackbar(t('BRANDING_INITIATION_SUCCESS'), {
          variant: 'success',
          persist: true,
        });
        fetchBrandDetails();
        closeBackToProcysConfirmation();
      }
    } catch (error) {
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'BRANDING_INITIATION_FAILURE', t),
        t
      ), {
        variant: 'error',
        persist: true
      });
    }
  };

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={4}
      {...rest}
    >
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className={classes.cardContainer}
      >
        <Card className={clsx(classes.card, className)}>
          <Typography className={classes.headerTitle}>
            {ready && t('ACCOUNT_SETTINGS_BRAND_TITLE')}
          </Typography>
          <Typography className={classes.descriptionText}>
            {ready && (
              <Trans i18nKey="ACCOUNT_SETTINGS_BRAND_INSTRUCTION">
                <Link className={classes.descriptionLink} href={BRAND_URL} rel="noreferrer" target="_blank">Here you can read the instructions</Link>
                on how to setup your own brand for Procys app.
              </Trans>
            )}
          </Typography>
          <BrandCompanyInfo isEditable={isEditable} brandDetails={brandDetails} fetchBrandDetails={fetchBrandDetails} />
          <ConnectionSettings brandDetails={brandDetails} />
          <Grid className={classes.footer}>
            {brandDetails.status === 'active' && (
              <Button
                variant="outlined"
                className={classes.backToProcysBtn}
                onClick={() => setOpenBackToProcysConfirmation(true)}
              >
                {ready && t('BRAND_SETTINGS_BACK_TO_PROCYS')}
              </Button>
            )}
            {(brandDetails.status === 'active') && (
              <Button
                variant="outlined"
                className={classes.backToProcysBtn}
                onClick={() => handleStatusChange('cancelled')}
              >
                {ready && t('BRAND_SETTINGS_EDIT')}
              </Button>
            )}
            {!brandDetails.status && (
              <Button
                color="secondary"
                variant="contained"
                className={classes.saveBtn}
                disabled={!isEditable}
                onClick={() => handleStatusChange('active')}
              >
                {ready && t('BRAND_SETTINGS_BRAND_MY_APP')}
              </Button>
            )}
            {brandDetails.status !== 'active' && (
              <Button
                color="secondary"
                variant="contained"
                className={classes.saveBtn}
                disabled={!isEditable}
                onClick={() => handleStatusChange('active')}
              >
                {ready && t('BRAND_SETTINGS_SAVE')}
              </Button>
            )}
          </Grid>
        </Card>
      </Grid>
      <BackToProcysConfirmation
        openConfirmation={openBackToProcysConfirmation}
        onClose={closeBackToProcysConfirmation}
        handleStatusChange={handleStatusChange}
      />
    </Grid>
  );
}

BrandSettings.propTypes = {
  className: PropTypes.string
};

export default BrandSettings;
