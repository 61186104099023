import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Button, Grid
} from '@material-ui/core';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from '../style';

const GStockIntegration = () => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [savedID, setSavedID] = useState(false);
  const [savedSecret, setSavedSecret] = useState(false);

  const [loading, setLoading] = useState(false);
  const [appError, setAppError] = useState(false);

  const [clientID, setClientID] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const getSavedDetails = async () => {
    // setLoading(true);
    try {
      const response = await Axios.get(
        `${API.savedDetailsGStock}/${user.companyID}`, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setClientID(response.data.data.clientId);
        setClientSecret(response.data.data.clientSecret);
        // setLoading(false);
      } else {
        setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
      }
    } catch (error) {
      setLoading(false);
      setAppError(t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'));
    }
  };

  useEffect(() => {
    getSavedDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveNewDetails = async (id, secret, num) => {
    // setLoading(true);
    try {
      const response = await Axios.post(
        `${API.savedDetailsGStock}/${user.companyID}`,
        {
          clientID: id,
          clientSecret: secret
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (num === 0) {
          setSavedID(true);
        } else {
          setSavedSecret(true);
        }
        // setLoading(false);
      } else {
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_SAVE_DETAILS'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_SAVE_DETAILS'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onCheckConnection = async () => {
    // setLoading(true);
    try {
      const response = await Axios.get(
        `${API.checkGStockConnection}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('INTEGRATIONS_GSTOCK_CONN_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        // setLoading(false);
      } else {
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('SOMETHING_WENT_WRONG'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onSync = async () => {
    setLoading(true);
    try {
      await Axios.get(
        API.syncGstock,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      setTimeout(() => {
        enqueueSnackbar(
          t('INTEGRATIONS_SYNC_STARTED_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }, 500);
    } catch (error) {
      setLoading(false);
      let errMsg = t('SOMETHING_WENT_WRONG');
      if (error?.response?.data) {
        errMsg = getLocalisedErrorString(error.response.data.i18n, t);
      }
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errMsg, t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  if (appError) {
    return (
      <Grid className={classes.errorContainer}>
        <span className={classes.labelTextError}>
          {appError}
        </span>
      </Grid>
    );
  }

  return (
    <Grid className={classes.detailsContainer}>
      <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>{ready && t('INTEGRATIONS_GSTOCK_CLIENT_ID')}</span>
          {savedID && <Grid className={classes.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          value={clientID}
          className={classes.inputField}
          onChange={(e) => setClientID(e.target.value)}
          onBlur={(e) => saveNewDetails(e.target.value, clientSecret, 0)}
        />
      </Grid>
      <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>{ready && t('INTEGRATIONS_GSTOCK_CLIENT_SECRET')}</span>
          {savedSecret && <Grid className={classes.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          value={clientSecret}
          className={classes.inputField}
          onChange={(e) => setClientSecret(e.target.value)}
          onBlur={(e) => saveNewDetails(clientID, e.target.value, 1)}
        />
      </Grid>
      <Grid className={classes.checkContainer}>
        <Button
          variant="outlined"
          className={classes.checkConnectionBtn}
          onClick={onCheckConnection}
          disabled={loading}
        >
          {ready && t('INTEGRATIONS_CHECK_CONNECTION')}
        </Button>
        <Button
          variant="outlined"
          className={classes.syncBtn}
          onClick={onSync}
          disabled={loading}
        >
          {ready && t('INTEGRATIONS_QUICKBOOKS_SYNC')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default GStockIntegration;
