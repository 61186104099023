import { all, takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'src/Dashboard/store/actions/actionTypes';
import { getInvoiceStatsByUserSaga, getAppInvoiceStatsSaga } from 'src/Dashboard/store/sagas/dashboard';

export function* watchDashboard() {
  yield all([
    takeEvery(actionTypes.INVOICE_STATS_BY_USER, getInvoiceStatsByUserSaga),
    takeEvery(actionTypes.INVOICE_APP_STATS_BY_USER, getAppInvoiceStatsSaga),
  ]);
}

export function* watchDummy() {
  // To be removed..
}
