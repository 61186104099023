import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from 'src/Auth/store/actions/account';
import { axiosHeaders } from 'src/Shared/utils/helpers';

export function* updateProfileSaga(action) {
  yield put(actions.updateProfileStart());
  const data = {
    email: action.user.email,
    firstName: action.user.firstname,
    lastName: action.user.lastname,
    phoneNumber: action.user.phone,
    city: action.user.city,
    country: action.user.country,
  };
  try {
    const response = yield axios.put(
      `${window.config.backend.login_service}v1/external/user-details?&application=procys`,
      data,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    yield put(actions.updateProfileSuccess(response.data.data));
    window.location.reload();
  } catch (error) {
    yield put(actions.updateProfileFail(error.response.data.message));
  }
}

export function* updateProfileImageSaga(action) {
  yield put(actions.updateProfileImageStart());
  const data = {
    image: action.image
  };
  try {
    yield axios.post(
      `${window.config.backend.login_service}v1/external/users/profile-image`,
      data,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    yield put(actions.updateProfileImageSuccess());
  } catch (error) {
    if (error.response && error.response.data) {
      yield put(actions.updateProfileImageFail(error.response.data.message));
    } else {
      yield put(actions.updateProfileImageFail(error.message));
    }
  }
}

export function* updatePasswordSaga(action) {
  yield put(actions.updatePasswordStart());
  const data = {
    oldPassword: action.oldPassword,
    newPassword: action.newPassword
  };
  try {
    yield axios.post(`${window.config.backend.login_service}v1/auth/change-password`, data);
    yield put(actions.updatePasswordSuccess());
  } catch (error) {
    yield put(actions.updatePasswordFail(error.response.data.message));
  }
}
