import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Tooltip
} from '@material-ui/core';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function UserEditForm({
  className,
  user,
  roles,
  handleClose,
  editUser,
  onGetAllUsers,
  ...rest
}) {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const [userRoles, setUserRoles] = useState([]);

  const fetchActiveUserRoles = async () => {
    const response = await Axios.get(`${API.getUserRoles}?status=active`,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
    if (response.data.data) {
      setUserRoles(response.data.data);
    }
  };

  useEffect(() => {
    fetchActiveUserRoles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={{
        id: editUser.id,
        email: editUser.email,
        firstname: editUser.firstname,
        lastname: editUser.lastname,
        userRole: editUser.userRole || '',
        status: editUser.status || '',
      }}
      validationSchema={Yup.object().shape({
        status: Yup.string().max(255),
        firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
        lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          // Make API request
          const body = {
            id: values.id.toString(),
            role: values.userRole,
            status: values.status,
            firstname: values.firstname,
            lastname: values.lastname,
            company: user.companyID,
          };
          const response = await Axios.put(
            API.updateUserByCreator,
            body,
            axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
          );
          if (response.data.success) {
            onGetAllUsers();
            handleClose();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_EDIT_SUCCESS'), {
              variant: 'success',
              persist: true
            });
          } else {
            setSubmitting(false);
          }
        } catch (error) {
          setErrors({
            submit: appendContactSupport(
              window.config.support_email,
              getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_USERS_EDIT_FAILURE', t),
              t
            )
          });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        touched
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Grid className={classes.userForm}>
            <Grid
              className={classes.fieldContainer}
            >
              <TextField
                fullWidth
                label={ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')}
                name="email"
                onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                required
                value={values.email}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid
              className={classes.fieldContainer}
            >
              <TextField
                error={Boolean(touched.firstname && errors.firstname)}
                fullWidth
                helperText={touched.firstname && errors.firstname}
                label={ready && t('ACCOUNT_SETTINGS_USERS_FIRSTNAME')}
                name="firstname"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.firstname}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.fieldContainer}
            >
              <TextField
                error={Boolean(touched.lastname && errors.lastname)}
                fullWidth
                helperText={touched.lastname && errors.lastname}
                label={ready && t('ACCOUNT_SETTINGS_USERS_LASTNAME')}
                name="lastname"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.lastname}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.fieldContainer}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="user-role-label">{ready && t('ACCOUNT_SETTINGS_USERS_USER_ROLE')}</InputLabel>
                <Select
                  labelId="user-role-label"
                  id="user-role"
                  defaultValue={values.userRole}
                  onChange={handleChange}
                  name="userRole"
                  label={ready && t('ACCOUNT_SETTINGS_USERS_USER_ROLE')}
                  classes={{
                    select: classes.selectStyle
                  }}
                >
                  {
                    userRoles && userRoles.length > 0 && userRoles.map((r) => (
                      <MenuItem key={r.id} value={r.role} className={classes.menuItemCapitalized}>
                        {r.role}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              className={classes.fieldContainer}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="user-status-label">{ready && t('ACCOUNT_SETTINGS_USERS_USER_STATUS')}</InputLabel>
                <Select
                  labelId="user-status-label"
                  id="user-status"
                  defaultValue={values.status}
                  onChange={handleChange}
                  name="status"
                  label={ready && t('ACCOUNT_SETTINGS_USERS_USER_STATUS')}
                  classes={{
                    select: classes.selectStyle
                  }}
                  disabled
                >
                  <MenuItem value="Active">
                    {t('ACCOUNT_SETTINGS_USERS_ACTIVE')}
                  </MenuItem>
                  <MenuItem value="Inactive">
                    {t('ACCOUNT_SETTINGS_USERS_INACTIVE')}
                  </MenuItem>
                  <MenuItem value="Invited">
                    {t('ACCOUNT_SETTINGS_USERS_INVITED')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {t(errors.submit)}
                </FormHelperText>
              </Box>
            )}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                className={classes.closeBtn}
                disabled={isSubmitting}
                onClick={handleClose}
              >
                {ready && t('ACCOUNT_SETTINGS_USERS_CANCEL')}
              </Button>
              <Tooltip
                title={
                  ((values.email === user.email) && user.userRole !== values.userRole)
                    ? t('ACCOUNT_SETTINGS_USERS_CANNOT_CHANGE_ROLE') : ''
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submitBtn}
                    type="submit"
                    disabled={isSubmitting || ((values.email === user.email) && user.userRole !== values.userRole)}
                  >
                    {ready && t('ACCOUNT_SETTINGS_USERS_CONFIRM')}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

UserEditForm.propTypes = {
  className: PropTypes.string,
  ready: PropTypes.bool,
  roles: PropTypes.array,
  user: PropTypes.object.isRequired,
  editUser: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  onGetAllUsers: PropTypes.func.isRequired,
  t: PropTypes.func,
};

export default UserEditForm;
