import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, Card, Grid, TablePagination,
  Tooltip, IconButton, useMediaQuery, TableHead
} from '@material-ui/core';
import {
  CardTravel as LogsIcon
} from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import { isActionPermitted, permissions } from 'src/Shared/utils/helpers';
import useStyles from './style';

const VatCodesTable = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const canEditDimension = isActionPermitted(permissions.dimensionEdit, user.permissions);

  const {
    vatCodes, limit, setLimit, page, setPage, total, gotoItem, getAllVatCodes
  } = props;

  const { ready, t } = useTranslation();

  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    getAllVatCodes(newPage, limit);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
    getAllVatCodes(0, event.target.value);
  };

  const paginationTab = () => (
    <TablePagination
      component="div"
      classes={{
        caption: classes.paginationCaption,
        selectRoot: classes.paginationCaption,
        menuItem: classes.paginationCaption,
        actions: classes.paginationActions,
      }}
      count={total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
    />
  );

  const gotoLogs = (event, code) => {
    event.stopPropagation();
    event.preventDefault();
    history.push({
      pathname: `/dimensions/logs/vatcodes/code/${code}`,
      state: { code }
    });
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        !isActionPermitted(permissions.dimensionRead, user.permissions)
          ? (
            <EmptyText str="VAT_CODE_LIST_NO_PERMISSION" />
          )
          : vatCodes && vatCodes.length > 0 ? (
            <Card
              className={classes.cardCont}
            >
              <Box minWidth="100%">
                <div className={classes.bulkOperations}>
                  <div className={classes.bulkActions}>
                    {paginationTab()}
                  </div>
                </div>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}</TableCell>
                      <TableCell className={classes.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_CODE')}</TableCell>
                      <TableCell className={classes.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_TYPE')}</TableCell>
                      <Hidden smDown>
                        <TableCell />
                      </Hidden>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vatCodes.map((vatCode) => (
                      <TableRow
                        hover={canEditDimension}
                        key={vatCode.code}
                        className={canEditDimension ? classes.pointer : classes.tableRow}
                        onClick={() => canEditDimension && gotoItem(vatCode)}
                      >
                        <TableCell align="left" className={classes.creditorNameStyle}>
                          {vatCode.name}
                        </TableCell>
                        <TableCell className={classes.todayText}>
                          {vatCode.code}
                        </TableCell>
                        <TableCell className={classes.todayText}>
                          {vatCode.type || '-'}
                        </TableCell>
                        <Hidden smDown>
                          <TableCell width={102} align="center" className={classes.currencyText}>
                            <Tooltip title={t('VAT_CODE_LIST_VIEW_LOGS_TOOLTIP')}>
                              <IconButton
                                onClick={(e) => gotoLogs(e, vatCode.code)}
                              >
                                <LogsIcon
                                  className={classes.logsIcon}
                                  style={{ fontSize: '1.8rem' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  className={classes.bottomPagi}
                >
                  {paginationTab()}
                </Grid>
              </Box>
            </Card>
          ) : (
            <EmptyText str="NO_VAT_CODES" />
          )
      }
    </>
  );
};

VatCodesTable.propTypes = {
  vatCodes: PropTypes.array,
  total: PropTypes.number,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  gotoItem: PropTypes.func,
  getAllVatCodes: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default VatCodesTable;
