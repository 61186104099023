/* eslint-disable */
import * as actionTypes from 'src/Dashboard/store/actions/actionTypes';

export const getInvoiceStatsByUserStart = () => {
  return {
    type: actionTypes.INVOICE_STATS_BY_USER_START
  };
};

export const getInvoiceStatsByUserSuccess = (invoices, overviewStats) => {
  return {
    type: actionTypes.INVOICE_STATS_BY_USER_SUCCESS,
    invoices,
    overviewStats
  };
};

export const getInvoiceStatsByUserFail = (error) => {
  return {
    type: actionTypes.INVOICE_STATS_BY_USER_FAIL,
    error
  };
};

export const getInvoiceStatsByUser = (payload) => {
  return {
    type: actionTypes.INVOICE_STATS_BY_USER,
    payload
  };
};

export const getInvoiceAppStatsByUserStart = () => {
  return {
    type: actionTypes.INVOICE_APP_STATS_BY_USER_START
  };
};

export const getInvoiceAppStatsByUserSuccess = (invoices, overviewStats) => {
  return {
    type: actionTypes.INVOICE_APP_STATS_BY_USER_SUCCESS,
    invoices,
    overviewStats
  };
};

export const getInvoiceAppStatsByUserFail = (error) => {
  return {
    type: actionTypes.INVOICE_APP_STATS_BY_USER_FAIL,
    error
  };
};

export const getInvoiceAppStatsByUser = (payload) => {
  return {
    type: actionTypes.INVOICE_APP_STATS_BY_USER,
    payload
  };
};
