import React, {
  useRef, useState, useEffect, useContext
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
  Grid, Typography, IconButton, Select, MenuItem, Popper, Grow, Paper, List, ListItem, ListItemText, Link, Card, Button, Tooltip
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useSnackbar } from 'notistack';
import i18n from 'i18next';

import authService from 'src/Shared/utils/services/authService';
import Axios from 'axios';
import {
  appendContactSupport, axiosHeaders, getLocalisedErrorString, validateToken
} from 'src/Shared/utils/helpers';
import ManageExportFieldMappings from 'src/Invoices/components/ManageExportFieldMappings/ManageExportFieldMappings';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function UserPreference({ className, ...rest }) {
  const {
    BRAND_NAME, BRAND_URL, API, LOGIN_PANEL_URL
  } = useContext(ConfigContext);
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const interfaceOptions = [
    { label: 'ACCOUNT_SETTINGS_APP_DEFAULT_INTERFACE', key: 'standard_interface' },
    { label: 'ACCOUNT_SETTINGS_TWINFIELD_INTERFACE', key: 'twinfield_interface' }
  ];

  const commsLangOptions = [
    { label: 'ACCOUNT_SETTINGS_COMMS_ENGLISH', key: 'en' },
    { label: 'ACCOUNT_SETTINGS_COMMS_SPANISH', key: 'es' }
  ];

  const [interfacePreference, setInterfacePreference] = useState(user.interfacePreference || interfaceOptions[0].key);
  const [commsLanguage, setCommsLanguage] = useState(user.commsLanguage || commsLangOptions[0].key);

  const [fields, setFields] = useState([]);
  const [lineFields, setLineFields] = useState([]);
  const [supplierFields, setSupplierFields] = useState([]);
  const [excelExportFieldMapping, setExcelExportFieldMapping] = useState([]);
  const [datExportFieldMapping, setDatExportFieldMapping] = useState([]);
  const [csvExportFieldMapping, setCsvExportFieldMapping] = useState([]);

  const [interfaceTooltipOpen, setInterfaceTooltipOpen] = useState(false);
  const [interfaceTooltipOpen2, setInterfaceTooltip2Open] = useState(false);
  const annoInterfaceTooltipRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.split('-')[0]);
  const [languages, setLanguages] = useState([]);
  const [showExportFieldMapping, setShowExportFieldMapping] = useState(false);

  const isPassportOrID = user.documentType === 'id';
  const isParentDocTypeSame = user.documentType === user.parentDocType;

  const fetchLanguages = async () => {
    const response = await Axios.get(API.getLanguages);
    if (response) {
      const data = response.data.success && response.data.data ? response.data.data : [];
      const someLangs = [];
      for (let i = 0; i < data.length;) {
        someLangs.push({
          code: data[i].language,
          label: data[i].label,
          shortLabel: data[i].language.toUpperCase()
        });
        if (data[i].language === i18n.language.split('-')[0]) {
          setSelectedLanguage(data[i].language);
        }
        i += 1;
      }
      setLanguages(someLangs);
    }
  };

  const getFields = async () => {
    const docType = isPassportOrID ? 'id' : 'invoice';
    try {
      const response = await Axios.get(
        `${API.fields}/${docType}/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setFields(response.data.data);
        } else {
          setFields([]);
        }
      }
    } catch (error) {
      setFields([]);
    }
  };

  const getLineFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/invoice-line/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setLineFields(response.data.data);
        } else {
          setLineFields([]);
        }
      }
    } catch (error) {
      setLineFields([]);
    }
  };

  const getSupplierFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/supplier/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setSupplierFields(response.data.data);
        } else {
          setSupplierFields([]);
        }
      }
    } catch (error) {
      setSupplierFields([]);
    }
  };

  const getExcelExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}excel`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setExcelExportFieldMapping(response.data.data);
          return;
        }
      }
      setExcelExportFieldMapping([]);
    } catch (error) {
      setExcelExportFieldMapping([]);
    }
  };

  const getDatExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}dat`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDatExportFieldMapping(response.data.data);
          return;
        }
      }
      setDatExportFieldMapping([]);
    } catch (error) {
      setDatExportFieldMapping([]);
    }
  };

  const getCsvExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}csv`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCsvExportFieldMapping(response.data.data);
          return;
        }
      }
      setCsvExportFieldMapping([]);
    } catch (error) {
      setCsvExportFieldMapping([]);
    }
  };

  const handleCloseExportFieldMapping = async () => {
    setShowExportFieldMapping(false);
  };

  useEffect(() => {
    fetchLanguages();
    getFields();
    getLineFields();
    getSupplierFields();
    getExcelExportFieldMapping();
    getDatExportFieldMapping();
    getCsvExportFieldMapping();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setInterfaceTooltipOpen(false);
  };

  const handleOpen = () => {
    setInterfaceTooltipOpen(true);
  };

  const handleClose2 = () => {
    setInterfaceTooltip2Open(false);
  };

  const handleOpen2 = () => {
    setInterfaceTooltip2Open(true);
  };

  const onChangeInterfacePreference = async (event) => {
    setInterfacePreference(event.target.value);
    setLoading(true);
    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }
    try {
      const response = await Axios.put(
        `${API.userProfile}?&application=procys`,
        {
          country: user.country || '',
          email: user.email || '',
          firstname: user.firstname || '',
          lastname: user.lastname || '',
          phone: user.phone || '',
          city: user.city || '',
          interfacePreference: event.target.value
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('INTERFACE_PREFERENCE_UPDATE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 2000);
      } else {
        setLoading(false);
        enqueueSnackbar(t('INTERFACE_PREFERENCE_UPDATE_FAILURE'), {
          variant: 'error',
          persist: true
        });
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(e?.response?.data?.i18n || 'INTERFACE_PREFERENCE_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
        persist: true
      });
    }
  };

  const onChangeCommsLanguagPreference = async (event) => {
    setCommsLanguage(event.target.value);
    setLoading(true);
    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }
    try {
      const response = await Axios.put(
        `${API.updateCommsLang}`,
        {
          company: user.companyID,
          language: event.target.value,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('COMMS_LANGUAGE_UPDATE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(t('COMMS_LANGUAGE_UPDATE_FAILURE'), {
          variant: 'error',
          persist: true
        });
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(e?.response?.data?.i18n || 'COMMS_LANGUAGE_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleLangSelect = (e) => {
    setSelectedLanguage(e.target.value);
    setLoading(true);
    i18n.changeLanguage(e.target.value, ((err) => {
      if (err) {
        setLoading(false);
        enqueueSnackbar(t('USER_PREFERENCE_LANGUAGE_CHANGE_FAILURE'), {
          variant: 'error',
          persist: true
        });
        return;
      }
      enqueueSnackbar(t('USER_PREFERENCE_LANGUAGE_CHANGE_SUCCESS'), {
        variant: 'success',
        persist: true
      });
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 1000);
    }));
  };

  const handleManageExport = () => {
    setShowExportFieldMapping(true);
  };

  const isAllExportsManagedByParent = () => {
    if (!user.isSubCompany) {
      return false;
    }
    if (isParentDocTypeSame && user.customisations.includes('applyParentMappingsExcel')
      && user.customisations.includes('applyParentMappingsCsv')
      && user.customisations.includes('applyParentMappingsDat')) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Grid item xs={12} sm={12} spacing={4} className={classes.formInputRow}>
          {!isPassportOrID && (
            <Grid item xs={12} sm={6} className={classes.formInputLeft}>
              <Grid className={classes.annoInterfaceLabelContainer}>
                <Typography variant="h5" className={classes.annoInterfaceLabel}>
                  {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE')}
                </Typography>
                <IconButton
                  ref={annoInterfaceTooltipRef}
                  className={classes.annoInterfaceHelp}
                  onMouseEnter={handleOpen}
                  onMouseLeave={handleClose}
                >
                  <HelpOutlineIcon className={classes.interfaceIcon} />
                </IconButton>
              </Grid>
              <Select
                disabled={loading}
                onChange={onChangeInterfacePreference}
                className={classes.formInputSelect}
                value={interfacePreference}
                variant="outlined"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {
                interfaceOptions.map((ip) => (
                  <MenuItem className={classes.inputText} key={ip.key} value={ip.key}>
                    {ready && t(ip.label, { brand: BRAND_NAME })}
                  </MenuItem>
                ))
              }
              </Select>
            </Grid>
          )}
          <Grid item xs={12} sm={6} className={isPassportOrID ? classes.formInputLeft : classes.formInputRight}>
            <Grid className={classes.annoInterfaceLabelContainer}>
              <Typography variant="h5" className={classes.annoInterfaceLabel}>
                {ready && t('ACCOUNT_SETTINGS_COMMS_LANGUAGE')}
              </Typography>
              <Tooltip title={t('ACCOUNT_SETTINGS_COMMS_LANGUAGE_TOOLTIP')}>
                <IconButton
                  className={classes.annoInterfaceHelp}
                >
                  <HelpOutlineIcon className={classes.interfaceIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Select
              disabled={loading}
              onChange={onChangeCommsLanguagPreference}
              className={classes.formInputSelect}
              value={commsLanguage}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
            >
              {
                commsLangOptions.map((cl) => (
                  <MenuItem className={classes.inputText} key={cl.key} value={cl.key}>
                    {ready && t(cl.label)}
                  </MenuItem>
                ))
              }
            </Select>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.formInput}
          spacing={4}
        >
          <Grid className={classes.annoInterfaceLabelContainer}>
            <Typography variant="h5" className={classes.annoInterfaceLabel}>
              {ready && t('USER_PREFERENCE_LANGUAGE_LABEL')}
            </Typography>
            <Tooltip title={t('ACCOUNT_SETTINGS_BROWSER_UI_LANGUAGE_TOOLTIP')}>
              <IconButton
                className={classes.annoInterfaceHelp}
              >
                <HelpOutlineIcon className={classes.interfaceIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Select
            disabled={loading}
            onChange={handleLangSelect}
            className={classes.formInputSelect}
            value={selectedLanguage}
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            {
              languages.map((l) => (
                <MenuItem className={classes.inputText} key={l.code} value={l.code}>
                  {l.label}
                </MenuItem>
              ))
            }
          </Select>
        </Grid>
        {!isAllExportsManagedByParent() && (
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.formInput}
            spacing={4}
          >
            <Grid className={classes.annoInterfaceLabelContainer}>
              <Typography variant="h5" className={classes.annoInterfaceLabel}>
                {ready && t('MANAGE_XLSX_EXPORT_FIELD_NAMES')}
              </Typography>
            </Grid>
            <Button
              variant="outlined"
              className={classes.manageExportBtn}
              onClick={handleManageExport}
            >
              {ready && t('MANAGE_EXPORT_MAPPINGS')}
            </Button>
          </Grid>
        )}
      </Card>
      <Popper
        open={interfaceTooltipOpen || interfaceTooltipOpen2}
        anchorEl={annoInterfaceTooltipRef.current}
        transition
        style={{ zIndex: 111111 }}
        onMouseEnter={handleOpen2}
        onMouseLeave={handleClose2}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.arPaper} variant="outlined">
              <Typography className={classes.arSubTitle}>
                {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_TITLE', { brand: BRAND_NAME })}
              </Typography>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FiberManualRecordIcon className={classes.bulletPoint} />
                  <ListItemText
                    disableTypography
                    primary={(
                      <Typography className={classes.listItemText}>
                        {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_LINE_01', { brand: BRAND_NAME })}
                      </Typography>
                    )}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FiberManualRecordIcon className={classes.bulletPoint} />
                  <ListItemText
                    disableTypography
                    primary={(
                      <Typography className={classes.listItemText}>
                        {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_LINE_02')}
                      </Typography>
                    )}
                  />
                </ListItem>
              </List>
              <Typography className={classes.listItemTextWithBt}>
                {ready && (
                  <Trans i18nKey="ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_LINE_03">
                    You can read more in
                    <Link href={BRAND_URL} rel="noreferrer" target="_blank">this</Link>
                    article.
                  </Trans>
                )}
              </Typography>
              <Typography className={classes.listItemText}>
                {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_LINE_04')}
              </Typography>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ManageExportFieldMappings
        exportFieldMappingsOpen={showExportFieldMapping}
        onModalClose={handleCloseExportFieldMapping}
        loading={loading}
        fields={fields}
        lineFields={lineFields}
        supplierFields={supplierFields}
        excelFieldMapping={excelExportFieldMapping}
        datFieldMapping={datExportFieldMapping}
        csvFieldMapping={csvExportFieldMapping}
      />
    </>
  );
}

UserPreference.propTypes = {
  className: PropTypes.string
};

export default UserPreference;
