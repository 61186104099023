import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { integrationNames } from 'src/config';

import EmailIntegration from 'src/Integrations/components/ManageIntegration/integrations/Email';
import ExportEmailIntegration from 'src/Integrations/components/ManageIntegration/integrations/ExportEmail';
import TwinfieldIntegration from 'src/Integrations/components/ManageIntegration/integrations/Twinfield';
import BCIntegration from 'src/Integrations/components/ManageIntegration/integrations/BC';
import APIIntegration from 'src/Integrations/components/ManageIntegration/integrations/API';
import AssistantAPIIntegration from 'src/Integrations/components/ManageIntegration/integrations/AssistantAPI';
import GStockIntegration from 'src/Integrations/components/ManageIntegration/integrations/GStock';
import QuickbooksIntegration from 'src/Integrations/components/ManageIntegration/integrations/Quickbooks';
import Holded from 'src/Integrations/components/ManageIntegration/integrations/Holded';
import SFTPIntegration from 'src/Integrations/components/ManageIntegration/integrations/SFTP';

import ConfigContext from 'src/Contexts';
import useStyles from './style';

const ManageIntegration = (props) => {
  const { IS_BRAND_PROCYS, BRAND_DOMAIN } = useContext(ConfigContext);
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const {
    integration, deliverFormats, exportEmailPrefFormat, savedExportEmailFormat, savePrefFormat
  } = props;

  const getTwinfieldIntegrationData = () => {};

  const renderIntegrationDetails = () => {
    switch (integration.name) {
      case integrationNames.emailReceiver:
        return (
          <EmailIntegration />
        );
      case integrationNames.api:
        return (
          <APIIntegration />
        );
      case integrationNames.businessCentral:
        return (
          <BCIntegration />
        );
      case integrationNames.twinfield:
        getTwinfieldIntegrationData();
        return (
          <TwinfieldIntegration />
        );
      case integrationNames.emailSender:
        return (
          <ExportEmailIntegration
            deliverFormats={deliverFormats}
            prefFormat={exportEmailPrefFormat}
            savePrefFormat={savePrefFormat}
            savedFormat={savedExportEmailFormat}
          />
        );
      case integrationNames.assistantAPI:
        return (
          <AssistantAPIIntegration />
        );
      case integrationNames.quickbooks:
        return (
          <QuickbooksIntegration />
        );
      case integrationNames.holded:
        return (
          <Holded />
        );
      case integrationNames.gstock:
        return (
          <GStockIntegration />
        );
      case integrationNames.sftpReceiver:
        return (
          <SFTPIntegration
            type={integrationNames.sftpReceiver}
            deliverFormats={deliverFormats}
            isIntegrationActive={integration.active}
          />
        );
      case integrationNames.sftpSender:
        return (
          <SFTPIntegration
            type={integrationNames.sftpSender}
            deliverFormats={deliverFormats}
            isIntegrationActive={integration.active}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Grid className={classes.manageContainer}>
      {
        IS_BRAND_PROCYS && integration?.link && (
          <Grid style={{ display: 'flex' }}>
            <Grid style={{ width: 56 }} />
            <a // eslint-disable-line
              href={integration?.link?.replace('{{BRAND_DOMAIN}}', BRAND_DOMAIN)}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.fileLink}
            >
              {
                integration.name === integrationNames.api || integration.name === integrationNames.assistantAPI // eslint-disable-line
                  ? ready && t('INTEGRATIONS_API_DOC')
                  : integration.name !== integrationNames.businessCentral
                    ? ready && t('INTEGRATIONS_SETUP_GUIDE')
                    : ready && t('INTEGRATIONS_INSTRUCTIONS')
              }
            </a>
          </Grid>
        )
      }
      {renderIntegrationDetails()}
    </Grid>
  );
};

ManageIntegration.propTypes = {
  integration: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    byZapier: PropTypes.bool,
    available: PropTypes.bool,
    integrated: PropTypes.bool,
    active: PropTypes.bool,
    link: PropTypes.string,
    img: PropTypes.string
  }),
  deliverFormats: PropTypes.array,
  exportEmailPrefFormat: PropTypes.string,
  savedExportEmailFormat: PropTypes.bool,
  savePrefFormat: PropTypes.func,
};

export default ManageIntegration;
