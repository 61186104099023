import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: '24px 40px',
  },
  bulkOperations: {
    position: 'relative',
    minHeight: 46,
    boxShadow: 'inset 0px -1px 0px rgba(151, 151, 151, 0.25)',
    borderRadius: '4px 4px 0px 0px',
  },
  bulkActions: {
    paddingLeft: 16,
    paddingRight: 4,
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bulkText: {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(51,77,110,0.5)',
    marginLeft: 15,
  },
  bulkDelete: {
    color: '#C2CFE0'
  },
  bulkEdit: {
    marginLeft: theme.spacing(2),
    color: '#C2CFE0'
  },
  actionSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardCont: {
    border: '1px solid #DDDDDD',
    borderRadius: 4,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    marginRight: 24
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  headerSpace: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12
  },
  headerMargin: {
    marginBottom: 12
  },
  pointer: {
    height: 64,
    cursor: 'pointer'
  },
  checkbox: {},
  checkboxIcon: {
    width: 16,
    height: 16,
    border: '2px solid #D5D5D5',
    borderRadius: 3,
  },
  checkCell: {
    alignItems: 'center'
  },
  bottomPagi: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  addBtn: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 0,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    height: 36,
    minWidth: 336
  },
  importExportBtn: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 0,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
    minWidth: 108,
    height: 36,
    marginRight: 8,
  },
  importExportIcon: {
    marginRight: theme.spacing(1)
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  searchInput: {
    '&::placeholder': {
      color: '#90A0B7',
      fontSize: 14,
      lineHeight: '21px'
    }
  },
  searchBar: {
    flex: 1,
    marginRight: 24
  },
  searchBarInput: {
    height: 36
  },
  queryField: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    border: '0px solid rgba(194, 207, 224, 1)',
    borderRadius: 4,
    margin: '0px',
    width: '100%',
    minWidth: 120
  },
  logsIconContainer: {
    marginRight: '10px'
  },
  logsIcon: {
    color: '#546e7a'
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: 8
  },
  importExportRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: 8
  }
}));

export default useStyle;
