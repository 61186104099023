import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import {
  Button, Grid, InputAdornment, SvgIcon, TextField, Tooltip, Typography,
  IconButton, useMediaQuery
} from '@material-ui/core';
import LogsIcon from '@material-ui/icons/CardTravel';
import AddCircle from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';

import Page from 'src/Shared/components/Page/Page';
import ProjectsTable from 'src/Dimensions/components/ProjectsTable/ProjectsTable';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import authService from 'src/Shared/utils/services/authService';
import {
  axiosHeaders, isActionPermitted, permissions, validateToken
} from 'src/Shared/utils/helpers';

import ConfigContext from 'src/Contexts';
import useStyles from './style';

function ListProjects() {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [projects, setProjects] = useState([]);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState('');

  const enabled = true;
  const isTwinfield = user.accountingSystem === 'twinfield';

  const maxWidth = useMediaQuery('(min-width: 730px)');

  const getAllProjects = async () => {
    setLoading(true);
    setFetching(true);
    try {
      const response = await Axios.get(
        `${API.projectsByCompany}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setProjects(response.data.data);
          setTotal(response.data.total);
        }
      }
      setLoading(false);
      setFetching(false);
    } catch (err) {
      setLoading(false);
      setFetching(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user.permissions)) {
      getAllProjects();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => {
    if (!validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    history.push('/dimensions/projects/new');
  };

  const handleQueryChange = (e) => {
    e.persist();
    setQuery(e.target.value);
  };

  const searchBar = (
    <Grid className={clsx(classes.searchBar, 'search-bar')}>
      <Tooltip title={t('DASHBOARD_SEARCH_PROJECT_SEARCH_TOOLTIP')}>
        <TextField
          className={classes.queryField}
          onChange={handleQueryChange}
          placeholder={ready && t('DASHBOARD_SEARCH_PROJECT_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: '#C2CFE0' }}
                >
                  <SearchIcon style={{ color: '#C2CFE0' }} />
                </SvgIcon>
              </InputAdornment>
            ),
            classes: {
              input: classes.searchInput,
              root: classes.searchBarInput
            }
          }}
        />
      </Tooltip>
    </Grid>
  );

  const logsList = (
    <Tooltip title={t('PROJECTS_LIST_VIEW_LOGS_TOOLTIP')}>
      <IconButton
        className={classes.logsIconContainer}
        onClick={() => history.push(`/dimensions/logs/projects/company/${user.companyID}`)}
      >
        <LogsIcon
          className={classes.logsIcon}
          style={{ fontSize: '1.8rem' }}
        />
      </IconButton>
    </Tooltip>
  );

  const titleText = (
    <Typography className={classes.title}>
      {ready && t('DASHBOARD_PROJECTS')}
    </Typography>
  );

  const addButton = (
    <Tooltip
      title={isActionPermitted(permissions.dimensionAdd, user.permissions)
        ? ready && t('PROJECTS_ADD_NEW_TOOLTIP')
        : ready && t('PROJECTS_ADD_NEW_TOOLTIP_PERMISSION')}
    >
      <span>
        <Button
          color="secondary"
          type="submit"
          variant="contained"
          className={classes.addBtn}
          onClick={handleAdd}
          disabled={!isActionPermitted(permissions.dimensionAdd, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <AddCircle />
          </SvgIcon>
          {ready && t('PROJECTS_ADD_NEW')}
        </Button>
      </span>
    </Tooltip>
  );

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <Page
      className={classes.root}
      title={ready && t('PROJECTS_LIST_PAGE_TITLE')}
    >
      <Grid className={clsx(classes.header, classes.headerMargin)}>
        {
          maxWidth ? (
            <Grid className={classes.header}>
              {titleText}
              {searchBar}
              {
                !isTwinfield && (
                  <>
                    {enabled && logsList}
                    {addButton}
                  </>
                )
              }
            </Grid>
          ) : (
            <Grid className={classes.headerColumn}>
              {titleText}
              <Grid className={classes.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              {
                !isTwinfield && addButton
              }
            </Grid>
          )
        }
      </Grid>
      <ProjectsTable
        projects={projects}
        getAllProjects={getAllProjects}
        total={total}
        fetching={fetching}
        query={query}
        isTwinfield={isTwinfield}
      />
    </Page>
  );
}

export default ListProjects;
