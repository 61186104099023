/* eslint-disable import/prefer-default-export */
import {
  applyMiddleware, createStore, compose, combineReducers
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer as formReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';

import authReducer from 'src/Auth/store/reducers/auth';
import accountReducer from 'src/Auth/store/reducers/account';
import dashboardReducer from 'src/Dashboard/store/reducers/dashboard';
import subscriptionReducer from 'src/User/store/reducers/subscription';
import glaReducer from 'src/Dimensions/store/reducers/gla';
import vatcodeReducer from 'src/Dimensions/store/reducers/vatcode';
import costCenterReducer from 'src/Dimensions/store/reducers/costcenter';

import { watchAuth, watchAccount } from 'src/Auth/store/sagas';
import { watchDashboard } from 'src/Dashboard/store/sagas';
import watchSubscription from 'src/User/store/sagas';

import { ENABLE_REDUX_LOGGER } from 'src/config';
import { watchGla, watchCostCenter, watchVatCode } from './Dimensions/store/sagas';

export function configureStore(preloadedState = {}) {
  const composeEnhancers = (process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

  const loggerMiddleware = createLogger();

  const rootReducer = combineReducers({
    auth: authReducer,
    account: accountReducer,
    dashboard: dashboardReducer,
    subscription: subscriptionReducer,
    gla: glaReducer,
    vatcode: vatcodeReducer,
    costcenter: costCenterReducer,
    form: formReducer
  });

  const sagaMiddleware = createSagaMiddleware();

  if (ENABLE_REDUX_LOGGER) {
    sagaMiddleware.push(loggerMiddleware);
  }

  const composedEnhancers = composeEnhancers(applyMiddleware(thunk, sagaMiddleware));

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  sagaMiddleware.run(watchAuth);
  sagaMiddleware.run(watchAccount);
  sagaMiddleware.run(watchDashboard);
  sagaMiddleware.run(watchSubscription);
  sagaMiddleware.run(watchVatCode);
  sagaMiddleware.run(watchGla);
  sagaMiddleware.run(watchCostCenter);

  return store;
}
