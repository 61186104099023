import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: 600,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  halfPaper: {
    width: '90%',
    maxWidth: 500,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  primaryTextColor: {
    color: '#334D6E',
  },
  btnGroup: {
    width: '100%',
    marginTop: 10,
  },
  groupBtn: {
    color: '#334D6E',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
  },
  fieldContainerFirst: {
    marginTop: 10
  },
  link: {
    textDecoration: 'underline',
    color: '#3E8AFF',
    cursor: 'pointer',
  },
  disabledLink: {
    textDecoration: 'underline',
    color: '#334D6E',
  },
  addUserDisabled: {
    marginBottom: 10,
    marginTop: 10,
  },
  addUserDisabledText: {
    fontSize: 11,
  },
  warningBanner: {
    width: '100%',
    paddingLeft: 20,
  },
  userForm: {
    width: '100%'
  },
  cancelBtn: {
    width: 108,
    height: 42,
    textTransform: 'none',
    color: '#334D6E'
  },
  loadingAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    width: 108,
    height: 42,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    textTransform: 'none',
    marginLeft: theme.spacing(2),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  validateButton: {
    width: 108,
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
  },
}));

export default useStyles;
