import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  titleContainer: {
    marginBottom: 16
  },
  backBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    color: '#3E8AFF',
    cursor: 'pointer',
    paddingLeft: 0,
    marginBottom: 16,
    textTransform: 'none'
  },
  icon: {
    marginRight: 12
  },
  cardCont: {
    border: '1px solid #DDDDDD',
    borderRadius: 4,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    marginLeft: 16
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
    marginBottom: 24
  },
  accordionHeading: {
    color: '#334D6E',
    fontWeight: 500
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: '#334D6E',
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  checkBox: {},
  checkboxIcon: {
    margin: 3,
    width: 18,
    height: 18,
    border: '2px solid #D5D5D5',
    borderRadius: 3,
  },
  todayText: {
    paddingTop: 10,
    fontSize: 16,
    color: '#334D6E',
    lineHeight: '24px'
  },
  tableCell: {
    height: 400,
    padding: 10
  },
  addBtn: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 0,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)'
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paperModal: {
    width: 600,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  modalDeleteHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deleteMessageText: {
    color: '#334D6E',
    paddingBottom: 16
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px',
    marginBottom: 20
  },
  modalText: {
    marginBottom: 20
  },
  modalCheckbox: {
    marginBottom: 20,
  },
  modelCheckboxLabel: {
    width: 100,
  },
  submitBtn: {
    marginRight: 10,
  },
  deleteBtn: {
    marginRight: 10,
    backgroundColor: '#ff4444',
    '&:hover': {
      backgroundColor: '#CC0000',
    }
  }
}));

export default useStyles;
