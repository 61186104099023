/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as actionTypes from 'src/Dimensions/store/actions/actionTypes';

const initialState = {
  costCenters: [],
  total: 0,
  loading: false,
  error: null
};

const fetchCostCentersStart = (state) => (
  produce(state, (draft) => {
    draft.loading = true;
    draft.error = null;
  })
);
const fetchCostCentersSuccess = (state, action) => (
  produce(state, (draft) => {
    draft.costCenters = action.costcenters;
    draft.total = action.total;
    draft.loading = false;
    draft.error = null;
  })
);
const fetchCostCentersFail = (state, action) => (
  produce(state, (draft) => {
    draft.loading = false;
    draft.error = action.error;
  })
);

const costCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COSTCENTERS_START: return fetchCostCentersStart(state);
    case actionTypes.FETCH_COSTCENTERS_SUCCESS: return fetchCostCentersSuccess(state, action);
    case actionTypes.FETCH_COSTCENTERS_FAIL: return fetchCostCentersFail(state, action);

    default: {
      return state;
    }
  }
};

export default costCenterReducer;
