import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Grid, Typography
} from '@material-ui/core';
import {
  InsertInvitationOutlined as PeriodsIcon,
  DateRangeOutlined as YearIcon
} from '@material-ui/icons';

import Page from 'src/Shared/components/Page/Page';
import useStyles from './style';

function Settings() {
  const classes = useStyles();
  const history = useHistory();
  const { ready, t } = useTranslation();

  return (
    <Page
      className={classes.root}
      title={ready && t('ACCOUNT_SETTINGS_COMPANY_SETTINGS')}
    >
      <Grid container className={classes.header}>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.headerTitle}>
            {ready && t('ACCOUNT_SETTINGS_COMPANY_SETTINGS')}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.settingsContainer}>
        <Grid className={classes.settingItem} onClick={() => history.push('/dimensions/settings/gla/years')}>
          <YearIcon className={classes.settingsIcon} />
          <Typography align="center">{ready && t('COMP_SETTINGS_GLA_YEARS')}</Typography>
        </Grid>
        <Grid className={classes.settingItem} onClick={() => history.push('/dimensions/settings/gla/periods')}>
          <PeriodsIcon className={classes.settingsIcon} />
          <Typography align="center">{ready && t('COMP_SETTINGS_GLA_PERIODS')}</Typography>
        </Grid>
      </Grid>
    </Page>
  );
}

export default Settings;
