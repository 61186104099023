import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 30
  },
  topContainer: {
    paddingLeft: 40,
    paddingRight: 40
  }
}));

export default useStyles;
