import * as actionTypes from 'src/Dimensions/store/actions/actionTypes';

export const fetchVatCodesStart = () => ({
  type: actionTypes.FETCH_VATCODES_START
});

export const fetchVatCodesSuccess = (vatcodes, total) => ({
  type: actionTypes.FETCH_VATCODES_SUCCESS,
  vatcodes,
  total
});

export const fetchVatCodesFail = (error) => ({
  type: actionTypes.FETCH_VATCODES_FAIL,
  error
});

export const fetchVatCodes = (companyID, page, limit, search) => ({
  type: actionTypes.FETCH_VATCODES,
  companyID,
  search
});
export const fetchVatCodeListStart = () => ({
  type: actionTypes.FETCH_VATCODE_LIST_START
});

export const fetchVatCodeListSuccess = (vatcodes, total) => ({
  type: actionTypes.FETCH_VATCODE_LIST_SUCCESS,
  vatcodes,
  total
});

export const fetchVatCodeListFail = (error) => ({
  type: actionTypes.FETCH_VATCODE_LIST_FAIL,
  error
});

export const fetchVatCodeList = (companyID, page, limit, search, loadFullScreen) => ({
  type: actionTypes.FETCH_VATCODE_LIST,
  companyID,
  page,
  limit,
  search,
  loadFullScreen
});
