import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
    fontSize: 14,
    color: '#334D6E'
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  container: {
    maxWidth: 1000
  },
  planContainer: {
    padding: 20
  },
  subsTitle: {
    fontSize: 32,
    fontWeight: 500,
    color: theme.palette.secondary.main
  },
  description: {
    fontSize: 14,
    color: '#334D6E',
    paddingBottom: 8
  },
  extraDescription: {
    marginTop: 16,
    fontSize: 14,
    color: '#334D6E',
    paddingBottom: 8
  },
  descTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: '#334D6E',
    paddingTop: 16,
    paddingBottom: 8
  },
  progressContainer: {
    paddingTop: 8,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  progressBackgroundRound: {
    backgroundColor: '#E0E0E0',
    height: 24,
    borderRadius: 20
  },
  progressBackground: {
    backgroundColor: '#E0E0E0',
    // maxWidth: 400,
    // width: 400,
    height: 24,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20
  },
  progressIndicator: {
    height: 24,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20
  },
  extraProgressBackground: {
    marginLeft: 2,
    backgroundColor: '#E0E0E0',
    height: 24,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20
  },
  extraProgressIndicator: {
    height: 24,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20
    // maxWidth: 400
  },
  progressText: {
    marginLeft: 8,
    fontSize: 12
  },
  summaryTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E',
    paddingBottom: 8,
  },
  summaryValue: {
    fontSize: 20,
    fontWeight: 500,
    color: '#334D6E',
  },
  summaryEmpTitle: {
    fontSize: 16,
    color: theme.palette.secondary.main
  },
  summaryEmpValue: {
    fontSize: 24,
    color: theme.palette.secondary.main
  },
  progressIndicator0: {
    backgroundColor: '#4CAF50',
  },
  progressIndicator50: {
    backgroundColor: '#FDD835',
  },
  progressIndicator80: {
    backgroundColor: '#FF9800',
  },
  progressIndicator100: {
    backgroundColor: '#F44336',
  },
  usageValue: {
    fontSize: 36,
    // fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#334D6E'
    // color: theme.palette.secondary.main
  },
  plusSymbol: {
    // color: '#E53935',
    color: '#334D6E',
    paddingLeft: 8,
    paddingRight: 8
  },
  extraUsage: {
    // color: '#E53935'
    color: '#334D6E'
  },
  usageDescription: {
    fontSize: 12,
    color: '#9E9E9E',
    // fontStyle: 'italic',
    paddingLeft: 8,
    textAlign: 'left',
    marginTop: 16
  },
  actionContainer: {
    paddingTop: 16,
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 520
  },
  changeBtn: {
    marginLeft: 16,
    textTransform: 'none'
  },
  extraBtn: {
    color: '#334D6E',
    textTransform: 'none'
  },
  cancelBtn: {
    color: '#334D6E',
    border: '1px #e4e4e4 solid',
    textTransform: 'none'
  },
  warningText: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E',
    alignItems: 'center',
    lineHeight: '28px'
  },
  warningSymbol: {
    fontSize: 26,
    fontWeight: 500,
    color: '#F44336',
    paddingRight: 16
  },
  planName: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  planFunctions: {
    paddingLeft: 60,
    columns: 2,
    '-webkit-columns': 2,
    '-moz-columns': 2
  },
  sliderContainer: {
    width: 500,
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: 16
  },
  sliderComponent: {
    paddingLeft: 20,
    paddingRight: 20
  },
  sliderLabelsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  sliderLimitContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 90
  },
  sliderLimitContainerS: {
    display: 'flex',
    flexDirection: 'column',
    width: 150
  },
  sliderLimitContainerB: {
    display: 'flex',
    flexDirection: 'column',
    width: 113
  },
  sliderLimitContainerC: {
    display: 'flex',
    flexDirection: 'column',
    width: 145
  },
  sliderRail: {
    height: 16,
    borderRadius: 16
  },
  sliderTrack: {
    height: 16,
    borderRadius: 16
  },
  sliderThumb: {
    height: 26,
    width: 26,
    border: '3px solid white'
  },
  sliderMark: {
    height: 6,
    width: 6,
    borderRadius: 6,
    top: 18,
    color: 'white'
  },
  limitText: {
    fontSize: 13,
    color: '#334D6E',
  },
  selectedLimitText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E',
  },
  labelText: {
    fontSize: 13,
    fontWeight: 500,
    color: '#334D6E'
  },
  selectedLabelText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
  },
  modalBody: {
    width: 800,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40
  },
  feedbackText: {
    borderRadius: 0,
    padding: 1,
  },
  cancelSubs: {
    fontWeight: '600',
    textTransform: 'capitalize'
  },
  cancelSubsHeader: {
    marginBottom: 25,
    color: '#334D6E'
  },
  unsubscribeCheckbox: {
    marginTop: 10,
    marginBottom: 10,
    color: '#334D6E',
  },
  msg: {
    color: '#334D6E'
  },
  secondaryMsg: {
    marginTop: 30,
    marginBottom: 15,
    color: '#334D6E',
  },
  backBtn: {
    fontWeight: '600',
    color: '#334D6E',
    textTransform: 'capitalize'
  },
  noPadding: {
    padding: 0
  },
  closeBtn: {
    padding: '2!important',
  },
  circle: {
    width: 12,
    height: 12,
    border: '1px solid #334D6E',
    borderRadius: 12
  },
  selectedCircle: {
    width: 12,
    height: 12,
    border: '1px solid',
    borderRadius: 12,
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main
  },
  tabsWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  tabContainer: {
    width: 200,
    fontWeight: '500!important',
    maxWidth: '240px!important'
  },
  tabTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#334D6E',
  },
  tabText: {
    fontWeight: 600
  },
  bodyContainer: {
    paddingTop: 20
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  prevBtn: {
    color: '#334D6E',
    border: '1px #e4e4e4 solid',
    textTransform: 'none'
  },
  fieldsContainer: {
    paddingTop: 24,
    paddingBottom: 24
  },
  paymentTabContainer: {
    paddingTop: 24
  },
  btn: {
    textTransform: 'none'
  },
  fieldStyle: {
    maxWidth: 400
  },
  btnGroup: {
    paddingTop: 16
  },
  groupBtn: {
    color: '#334D6E',
    textTransform: 'none'
  },
  activeText: {
    fontSize: 13,
    color: '#33ab9f'
  },
  card: {
    width: '100%',
    border: '1px solid #33ab9f',
    borderRadius: 8,
    padding: 16
  },
  activeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 12
  },
  makeActiveContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 8
  },
  makeActiveBtn: {
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0
  },
  checkIcon: {
    fontSize: 16,
    color: '#33ab9f',
    paddingRight: 2
  },
  cardType: {
    width: 30,
    marginLeft: 8,
    marginRight: 12
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 8
  },
  cardFooterText: {
    fontSize: 14,
    color: '#334D6E'
  },
  cardNumber: {
    fontSize: 18,
    color: '#334D6E'
  },
  subscriptionsSelect: {
    width: 300,
    paddingTop: 8,
    paddingBottom: 16
  },
  selectLabel: {
    paddingLeft: 16
  },
  summaryContainer: {
    maxWidth: 600,
    paddingTop: 24,
    paddingBottom: 24
  },
  summaryLabel: {
    fontSize: 16,
    color: '#334D6E'
  },
  summaryAmount: {
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E'
  },
  totalLabel: {
    fontSize: 20,
    color: '#33ab9f'
  },
  totalAmount: {
    textAlign: 'right',
    fontSize: 20,
    fontWeight: 500,
    color: '#33ab9f'
  },
  hrLine: {
    width: '90%',
    height: 2,
    backgroundColor: '#e4e4e4',
    marginTop: 8,
    marginBottom: 8
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    minWidth: 100,
    height: 40,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    textTransform: 'none',
  },
  cardAddFrame: {
    width: '100%',
    height: 800
  },
  redirectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 20,
    paddingTop: 20
  },
  redirectMessage: {
    paddingBottom: 24
  },
  newPageLink: {
    color: '#3e8aff',
    paddingLeft: 4,
    paddingRight: 4,
    cursor: 'pointer'
  },
  cardActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  changePlan: {
    color: '#334D6E',
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0
  },
  addCardContainer: {
    width: '100%',
    color: '#334D6E',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 20
  },
  normalText: {
    color: '#334D6E',
    paddingBottom: 8
  },
  addCardBtn: {
    color: '#334D6E',
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default useStyles;
