import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    width: 'min-content',
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: theme.spacing(2)
  },
  itemLeaf: {
    display: 'flex',
    width: 'min-content',
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: theme.spacing(2)
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    height: 60,
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    border: '0px solid white',
    borderBottomWidth: 3,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: '#334D6E',
    fontSize: 20
  },
  title: {
    marginRight: 8,
    whiteSpace: 'nowrap',
    fontWeight: 500,
    color: '#334D6E',
    fontSize: 14
  },
  active: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.main
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

export default useStyles;
