import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Container
} from '@material-ui/core';

import Page from 'src/Shared/components/Page/Page';
import Results from 'src/Invoices/components/InvoiceLogsListResults/InvoiceLogsListResults';
import useStyles from './style';

const LogsListView = (props) => {
  const {
    classes, match,
  } = props;
  const { ready, t } = useTranslation();

  const [invoiceID, setInvoiceID] = useState(null);

  useEffect(() => {
    const id = match && match.params ? match.params.id : 0;
    if (id) {
      setInvoiceID(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page
      className={classes.root}
      title={ready && t('DOCUMENT_LOGS_TITLE')}
    >
      <Container maxWidth={false} className={classes.topContainer}>
        <Results
          {...props}
          invoiceID={invoiceID}
        />
      </Container>
    </Page>
  );
};

LogsListView.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    topContainer: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    companyID: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
};

LogsListView.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = () => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(withRouter(LogsListView));
