import { makeStyles, alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  integrationBox: {
    flexDirection: 'column',
    border: '1px solid #DFDDE4',
    borderRadius: 8,
    marginTop: 16,
    padding: 16
  },
  integrationHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  integrationImage: {
    width: 40
  },
  integrationTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#334D6E'
  },
  integrationDesc: {
    fontSize: 14,
    color: '#334D6E',
    minHeight: 42
  },
  integrationBody: {
    flex: 1,
    padding: '0px 16px'
  },
  manageBtn: {
    fontSize: 14,
    color: '#334D6E',
    marginTop: 8,
    textTransform: 'none'
  },
  connectBtn: {
    textTransform: 'none'
  },
  disableBtn: {
    textTransform: 'none',
    color: '#FFFFFF'
  },
  zapierConnection: {
    fontSize: 11,
    color: '#EC5929',
    marginLeft: 16,
    position: 'relative',
    top: -2
  },
  comingSoon: {
    backgroundColor: '#EC5929',
    color: '#ffffff',
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 8px 2px',
    position: 'relative',
    top: -8
  },
  beta: {
    backgroundColor: '#3E8AFF',
    color: '#ffffff',
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 8px 2px',
    position: 'relative',
    top: -8
  },
  tryForFree: {
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3),
    color: theme.palette.statusbg.toreview,
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 8px 2px',
    position: 'relative',
    top: -8
  },
  moreItem: {
    fontSize: 14,
    color: '#334D6E',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    minWidth: 100,
    height: 40,
    textTransform: 'none',
  },
  connectLinkBtn: {
    background: '#DFDFDF',
    color: '#334D6E',
    textTransform: 'none'
  },
  launchIcon: {
    paddingRight: 8
  },
  btnText: {
    paddingLeft: 12,
    paddingRight: 12
  }
}));

export default useStyles;
