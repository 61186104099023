import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Axios from 'axios';
import EditSupplierForm from 'src/Suppliers/components/EditSupplierForm/EditSupplierForm';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';

import { axiosHeaders, isActionPermitted, permissions } from 'src/Shared/utils/helpers';
import { useTranslation } from 'react-i18next';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const EditSupplierModal = (props) => {
  const {
    editSupplierOpen,
    setEditSupplierOpen,
    supplierId,
    handleEditSupplierClose,
    getAllSuppliers,
    isInvoiceEditForm,
    renderInvoiceXML,
    renderInvoicePDF,
    scrollXMLViewBy,
    company,
  } = props;
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [supplier, setSupplier] = useState(null);
  const [loading, setLoading] = useState(false);

  const [xmlSelectedValue, setXmlSelectedValue] = useState('');
  const [textFieldSelectedValue, _setTextFieldSelectedValue] = useState('');
  const textFieldSelectedValueRef = useRef(textFieldSelectedValue);
  const setTextFieldSelectedValue = (val) => {
    textFieldSelectedValueRef.current = val;
    _setTextFieldSelectedValue(val);
  };
  const [textFieldSelectedKey, setTextFieldSelectedKey] = useState('');

  const getSupplier = async () => {
    if (!editSupplierOpen) {
      return;
    }
    try {
      setLoading(true);
      const response = await Axios.get(
        `${API.suppliersV2}/${supplierId}/${company}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSupplier(response.data.data);
        setLoading(false);
      } else {
        setEditSupplierOpen(false);
        setLoading(false);
      }
    } catch (error) {
      setEditSupplierOpen(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user.permissions) && supplierId && supplierId !== '-1') {
      getSupplier();
    }
  }, [supplierId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!supplier) {
    return null;
  }

  return (
    <Modal
      className={classes.modal}
      open={editSupplierOpen}
      onClose={handleEditSupplierClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={editSupplierOpen}>
        <div className={(user.interfacePreference !== 'twinfield_interface' && !isInvoiceEditForm) ? classes.halfPaper : classes.paper}>
          {loading ? (<LoadingScreen />) : (
            <>
              <Grid className={classes.modalHeader}>
                <Typography className={classes.headerTitle}>
                  {`${t('INVOICE_EDIT_FORM_SUPPLIER')} - ${supplier.creditorName ? supplier.creditorName : ''} (${supplier.creditorCode})`}
                </Typography>
                <IconButton onClick={handleEditSupplierClose}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
              {isInvoiceEditForm ? (
                <Grid container>
                  <Grid item xs={12} md={4} className={classes.leftContainer}>
                    <EditSupplierForm
                      supplier={supplier}
                      handleEditSupplierClose={handleEditSupplierClose}
                      getAllSuppliers={getAllSuppliers}
                      isInvoiceEditForm={isInvoiceEditForm}
                      xmlSelectedValue={xmlSelectedValue}
                      textFieldSelectedKey={textFieldSelectedKey}
                      textFieldSelectedValueRef={textFieldSelectedValueRef}
                      setTextFieldSelectedKey={setTextFieldSelectedKey}
                      setTextFieldSelectedValue={setTextFieldSelectedValue}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.invoiceContainer}>
                    {renderInvoiceXML
                      ? renderInvoiceXML(xmlSelectedValue, setXmlSelectedValue, textFieldSelectedValue, scrollXMLViewBy, false, textFieldSelectedKey)
                      : renderInvoicePDF()}
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <EditSupplierForm
                    supplier={supplier}
                    handleEditSupplierClose={handleEditSupplierClose}
                    getAllSuppliers={getAllSuppliers}
                    isInvoiceEditForm={isInvoiceEditForm}
                    xmlSelectedValue={xmlSelectedValue}
                    textFieldSelectedKey={textFieldSelectedKey}
                    textFieldSelectedValueRef={textFieldSelectedValueRef}
                    setTextFieldSelectedKey={setTextFieldSelectedKey}
                    setTextFieldSelectedValue={setTextFieldSelectedValue}
                  />
                </Grid>
              )}
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

EditSupplierModal.propTypes = {
  editSupplierOpen: PropTypes.bool,
  setEditSupplierOpen: PropTypes.func,
  supplierId: PropTypes.string,
  handleEditSupplierClose: PropTypes.func,
  getAllSuppliers: PropTypes.func,
  renderInvoiceXML: PropTypes.func,
  isInvoiceEditForm: PropTypes.bool,
  scrollXMLViewBy: PropTypes.func,
  renderInvoicePDF: PropTypes.func,
  company: PropTypes.string,
};

EditSupplierModal.defaultProps = {};

export default EditSupplierModal;
