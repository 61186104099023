/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import i18n from 'i18next';

import {
  Box, Checkbox, Link, SvgIcon, Table, TableBody, TableCell, TableRow, Typography,
  Avatar, Hidden, Tooltip, Grid, IconButton, Menu, MenuItem,
  Button, TableHead, Select
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HelpCenterIcon from '@material-ui/icons/HelpOutlineRounded';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import LabelStatus from 'src/Shared/components/LabelStatus/LabelStatus';
import LabelStatusDot from 'src/Shared/components/LabelStatusDot/LabelStatusDot';
import {
  appendContactSupport, axiosDeleteHeaders, axiosHeaders, formatAmountInvoicesTable, formatDateCustomFormat,
  getInvoiceStatus, getInvoiceStatusKey, getLocalisedErrorString, getPermissionByInvoiceStatus, i18nByStatus,
  isActionPermitted, truncateFileName, truncateString
} from 'src/Shared/utils/helpers';
import {
  invoiceStatusCode, invoiceStatusCodesList, invoiceStatuses, invoiceStatusesKeys, kbLinks
} from 'src/config';
import useStyles from 'src/Dashboard/components/InvoicesListResults/style';
import { isStatusChangeValid } from 'src/Shared/utils/invoiceStateChangeValidations';
import DeleteDocumentModal from 'src/Invoices/components/DeleteDocumentModal/DeleteDocumentModal';
import ConfigContext from 'src/Contexts';
import SplitMenu from '../../SplitMenu/SplitMenu';
import DuplicateMenu from '../../DuplicateMenu/DuplicateMenu';

const InvoicesTable = ({
  paginatedInvoices, selectedInvoices, handleSelectOneInvoice, status, getInvoices, getArchivedInvoices,
  filters, query, time, page, limit, total, supplierFilter, ownerFilter, isToValidateStatusAllowed, onSelectMenuItem,
  handleOpenMenu, handleCloseMenu, menuAnchorEl, menuAssistantID, deleteInvoiceID, setDeleteInvoiceID,
  openDeleteConf, setOpenDeleteConf, startTime, endTime, failedToExport, users, setLoading, loading,
  sortDirection, sortField, handleSort
}) => {
  const { API, IS_BRAND_PROCYS, BRAND_NAME } = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [position, setPosition] = useState({ x: undefined, y: undefined });
  const [invoices, setInvoices] = useState(paginatedInvoices);
  const [containsFailedToExport, setContainsFailedToExport] = useState(paginatedInvoices.some(
    (invoice) => invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason
  ));
  const { ready, t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [rcX, setRCX] = useState(0);
  const [rcY, setRCY] = useState(0);
  const [rcKey, setRCKey] = useState(null);
  const [rcShow, setRCShow] = useState(false);

  const [duplicateMenuAnchorEl, setDuplicateMenuAnchorEl] = useState(null);
  const [duplicateMenuAnchorId, setDuplicateMenuAnchorId] = useState(null);
  const [duplicateMenuOpen, setDuplicateMenuOpen] = useState(false);
  const [duplicateDocument, setDuplicateDocument] = useState({});

  const [splitMenuAnchorEl, setSplitMenuAnchorEl] = useState(null);
  const [splitMenuAnchorId, setSplitMenuAnchorId] = useState(null);
  const [splitMenuOpen, setSplitMenuOpen] = useState(false);
  const [documentToSplit, setDocumentToSplit] = useState({});

  const isPassportOrID = user.documentType === 'id';
  const dateFieldRule = user.fieldRules?.find((fr) => fr.field === 'documentDate');
  const dateFieldFormat = dateFieldRule?.format || 'DD-MM-YYYY';

  const onContextMenuClose = () => {
    setRCShow(false);
    document.oncontextmenu = () => true;
  };

  useEffect(() => {
    document.addEventListener('click', onContextMenuClose);

    return () => {
      document.removeEventListener('click', onContextMenuClose);
    };
  }, []);

  const onCancelDelete = () => {
    setOpenDeleteConf(false);
    setDeleteInvoiceID(null);
    setLoading(false);
  };

  const handleDuplicateMenuOpen = (invoice) => {
    setDuplicateDocument(invoice);
    setDuplicateMenuOpen(true);
    setDuplicateMenuAnchorEl(null);
  };

  const handleDuplicateMenuClose = () => {
    setDuplicateMenuOpen(false);
    setDuplicateDocument({});
  };

  const onDeleteInvoice = async () => {
    try {
      const body = {
        company: user.companyID,
        IDs: [deleteInvoiceID.toString()]
      };
      const response = await Axios.delete(
        API.updateInvoice,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body)
      );
      if (response.data.success) {
        // Fetch invoices list and stats.
        if (status === invoiceStatuses.archived || status === invoiceStatusesKeys.archived) {
          getArchivedInvoices();
        } else {
          getInvoices();
        }
        enqueueSnackbar(t('INVOICE_DELETE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        setOpenDeleteConf(false);
        handleDuplicateMenuClose();
        setLoading(false);
      }
    } catch (error) {
      if (status === invoiceStatuses.archived || status === invoiceStatusesKeys.archived) {
        getArchivedInvoices();
      } else {
        getInvoices();
      }
      setLoading(false);
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_STATUS_CHANGE_FAILED', t),
        t
      ), {
        variant: 'error',
        persist: true
      });
    }
  };

  const today = new Date().setHours(0, 0, 0, 0) / 1000;

  useEffect(() => {
    const filteredinvoices = [];
    if (status === invoiceStatuses.all) {
      setInvoices(paginatedInvoices);
    } else {
      paginatedInvoices.forEach((i) => {
        if (getInvoiceStatusKey(i.status) === status) {
          filteredinvoices.push(i);
        }
      });

      setInvoices(filteredinvoices);
    }
    setContainsFailedToExport(paginatedInvoices.some(
      (invoice) => invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason
    ));
  }, [paginatedInvoices, status]);

  const gotoLogs = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    history.push(`/documents/${id}/logs`);
  };

  const getContextMenu = (e, invoice) => {
    document.oncontextmenu = () => false;
    e.preventDefault();

    if (invoice.status >= 16 && invoice.assistantID >= 0) {
      let posX = e.pageX - 16;
      if (posX + 250 > window.innerWidth) {
        posX -= 200;
      }

      setRCY(e.pageY - 448);
      setRCX(posX);
      setRCShow(true);
      setRCKey(invoice.assistantID);
    }
  };

  const isSelected = (id) => selectedInvoices.some((i) => i.id === id);

  const calculateInvoicePage = (count) => (page * limit) + count + 1;

  const handleSplitMenuOpen = (invoice) => {
    setDocumentToSplit(invoice);
    setSplitMenuOpen(true);
    setSplitMenuAnchorEl(null);
  };

  const handleSplitMenuClose = () => {
    setSplitMenuOpen(false);
    setDocumentToSplit({});
  };

  const handleProcessDocument = async (invoice, action) => {
    setLoading(true);
    if (action === 'delete') {
      setDeleteInvoiceID(invoice.id);
      setOpenDeleteConf(true);
    } else {
      try {
        const body = {
          IDs: [parseInt(invoice.id, 10)]
        };
        const response = await Axios.post(
          API.processNoneDuplicate,
          body,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          getInvoices();
          setLoading(false);
          enqueueSnackbar(t('DOCUMENT_SENT_TO_PROCESS_SUCCESS'), {
            variant: 'success',
            persist: true
          });
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(t('DOCUMENT_SENT_TO_PROCESS_FAILED'), {
          variant: 'error',
          persist: true
        });
      }
    }
  };

  const handleDocumentClick = (invoice, i) => {
    if (loading) {
      return;
    }
    if (invoice.outOfLimit) {
      history.push('/account/subscription');
    } else if (invoice.status >= 16 && invoice.assistantID) {
      history.push(
        `/documents/${user.customisations.includes('cdcDocumentView')
          ? 'cdc/' : ''}${invoice.assistantID}/edit?${status !== null
          ? `status=${status}` : ''}${!filters.recent.isNan
          ? `&filters=${filters.recent}` : ''}${query !== ''
          ? `&query=${query}` : ''}${time !== ''
          ? `&time=${time}` : ''}${page !== null
          ? `&page=${page}` : ''}${limit
          ? `&limit=${limit}` : ''}${sortField
          ? `&sort_by=${sortField}&order=${sortDirection}` : ''}${supplierFilter
          ? `&supplier_filter=${supplierFilter}` : ''}${ownerFilter
          ? `&owner_filter=${ownerFilter}` : ''}${startTime !== ''
          ? `&start_time=${startTime}` : ''}${endTime !== ''
          ? `&end_time=${endTime}` : ''}${failedToExport
          ? '&failed_to_export=true' : ''}${selectedInvoices.length
          ? '&documents_selected=true' : ''}&document_page=${calculateInvoicePage(i)}&total=${total}`
      );
    }
  };

  const openInNewTab = () => {
    let pre = '';
    if (user.customisations.includes('cdcDocumentView')) {
      pre = 'cdc/';
    }
    window.open(`/documents/${pre}${rcKey}/edit`, '_blank');
  };

  const handleChangeOwner = async (owner, id) => {
    try {
      const body = {
        assistantID: parseInt(id, 10),
        owner
      };

      const response = await Axios.post(
        API.documentOwner,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getInvoices();
        enqueueSnackbar(t('PROCYS_OWNER_CHANGE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('PROCYS_OWNER_CHANGE_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleDocumentSplit = async (invoice, splitType) => {
    setLoading(true);
    try {
      const body = {
        documentID: invoice.id.toString(),
        splitType,
        pageCount: '1',
        pageRange: [],
      };

      const response = await Axios.post(
        API.splitDocument,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        getInvoices();
        enqueueSnackbar(t('SPLIT_DOCUMENT_SUCCESS'), {
          variant: 'success',
          persist: true
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('SPLIT_DOCUMENT_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleSplitMenuClick = (e, id) => {
    setSplitMenuAnchorEl(e.currentTarget);
    setSplitMenuAnchorId(id);
  };

  const handleDuplicateMenuClick = (e, id) => {
    setDuplicateMenuAnchorEl(e.currentTarget);
    setDuplicateMenuAnchorId(id);
  };

  const duplicateRequestButtons = (invoice) => (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        className={classes.checkNSplitBtn}
        variant="contained"
        color="secondary"
        disabled={loading}
        onClick={() => handleDuplicateMenuOpen(invoice)}
      >
        {t('INVOICE_DUPLICATE_VIEW')}
      </Button>
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        disabled={loading}
        onClick={() => handleProcessDocument(invoice, 'proceed')}
      >
        {t('INVOICE_DUPLICATE_NO_DUPLICATE')}
      </Button>
      <Button
        variant="outlined"
        className={classes.deleteDuplicateBtn}
        disabled={loading}
        onClick={() => handleProcessDocument(invoice, 'delete')}
      >
        {t('INVOICE_DUPLICATE_DELETE')}
      </Button>
    </Box>
  );

  const splitRequestButtons = (invoice) => (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        className={classes.checkNSplitBtn}
        variant="contained"
        color="secondary"
        disabled={loading}
        onClick={() => handleSplitMenuOpen(invoice)}
      >
        {t('INVOICE_SPLIT_REQUEST_CHECK_N_SPLIT')}
      </Button>
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        disabled={loading}
        onClick={() => handleDocumentSplit(invoice, 'no')}
      >
        {t('INVOICE_SPLIT_REQUEST_DONT_SPLIT')}
      </Button>
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        disabled={loading}
        onClick={() => handleDocumentSplit(invoice, 'fixed')}
      >
        {t('INVOICE_SPLIT_REQUEST_SPLIT_PER_PAGE')}
      </Button>
    </Box>
  );

  const renderSplitRequiredRow = (invoice, isInvoiceSelected) => (
    <TableRow
      className={classes.pointer}
    >
      <TableCell
        padding="normal"
        className={classes.checkCell}
      >
        <Checkbox
          checked={isInvoiceSelected}
          value={isInvoiceSelected}
          className={classes.checkbox}
          icon={<Grid className={classes.checkboxIcon} />}
          onClick={(event) => !invoice.outOfLimit && handleSelectOneInvoice(event, null, invoice.status, invoice.id)}
          disabled={invoice.outOfLimit || loading}
        />
      </TableCell>
      <Hidden mdDown>
        <TableCell width={140} className={classes.statusCell}>
          <LabelStatus
            className={classes.statusElementSelected}
            color={getInvoiceStatusKey(200)}
          >
            <Typography className={classes.statusText}>
              {ready && t(getInvoiceStatus(200))}
            </Typography>
          </LabelStatus>
        </TableCell>
      </Hidden>
      <Hidden only={['xs', 'lg', 'xl']}>
        <TableCell>
          <LabelStatusDot
            color={getInvoiceStatusKey(200)}
          />
        </TableCell>
      </Hidden>
      <Hidden mdDown>
        <TableCell colSpan={7}>
          <Grid className={classes.splitRequestContainer}>
            <Typography className={classes.splitRequestText}>{t('INVOICE_SPLIT_REQUEST', { document: truncateFileName(invoice.fileName, 44) })}</Typography>
            {splitRequestButtons(invoice)}
          </Grid>
        </TableCell>
      </Hidden>
      <Hidden only={['xs', 'sm', 'lg', 'xl']}>
        <TableCell colSpan={5}>
          <Typography className={classes.splitRequestText}>{t('INVOICE_SPLIT_REQUEST', { document: truncateFileName(invoice.fileName, 44) })}</Typography>
        </TableCell>
      </Hidden>
      <Hidden only={['xs', 'md', 'lg', 'xl']}>
        <TableCell colSpan={3}>
          <Typography className={classes.splitRequestText}>{t('INVOICE_SPLIT_REQUEST', { document: truncateFileName(invoice.fileName, 44) })}</Typography>
        </TableCell>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <TableCell colSpan={2}>
          <Typography className={classes.splitRequestText}>{t('INVOICE_SPLIT_REQUEST', { document: truncateFileName(invoice.fileName, 44) })}</Typography>
        </TableCell>
      </Hidden>
      <Hidden mdDown>
        <TableCell width={156} align="center">
          {(IS_BRAND_PROCYS || BRAND_NAME === 'Extrable') && (
            <Link onClick={() => window.open(kbLinks[BRAND_NAME]?.replace('__LANG__', i18n.language.split('-')[0] !== 'en' ? `/${i18n.language.split('-')[0]}` : ''), '_blank')}>
              <Typography className={classes.splitRequestHelp}>{t('INVOICE_SPLIT_REQUEST_HELP')}</Typography>
            </Link>
          )}
        </TableCell>
      </Hidden>
      <TableCell width={76} align="right">
        <Grid className={classes.actionCell}>
          <Hidden only={['xs', 'lg', 'xl']}>
            {(IS_BRAND_PROCYS || BRAND_NAME === 'Extrable') && (
              <Tooltip title={t('DASHBOARD_NAV_KB_LINK')}>
                <IconButton className={classes.kbIcon} onClick={() => window.open(kbLinks[BRAND_NAME]?.replace('__LANG__', i18n.language.split('-')[0] !== 'en' ? `/${i18n.language.split('-')[0]}` : ''), '_blank')}>
                  <HelpCenterIcon />
                </IconButton>
              </Tooltip>
            )}
          </Hidden>
          <IconButton disabled={loading} onClick={(e) => handleSplitMenuClick(e, invoice.id)}>
            <SvgIcon style={{ display: 'flex' }}>
              <MoreVertIcon className={classes.moreIcon} />
            </SvgIcon>
          </IconButton>
          <Menu
            id="invoice-actions"
            anchorEl={splitMenuAnchorEl}
            keepMounted
            open={Boolean(splitMenuAnchorEl) && splitMenuAnchorId === invoice.id}
            onClose={() => setSplitMenuAnchorEl(null)}
          >
            <Hidden only={['lg', 'xl']}>
              <MenuItem
                key="checkNSplit"
                onClick={() => handleSplitMenuOpen(invoice)}
              >
                {ready && t('INVOICE_SPLIT_REQUEST_CHECK_N_SPLIT')}
              </MenuItem>
              <MenuItem
                key="dontSplit"
                onClick={() => handleDocumentSplit(invoice, 'no')}
              >
                {ready && t('INVOICE_SPLIT_REQUEST_DONT_SPLIT')}
              </MenuItem>
              <MenuItem
                key="splitPerPage"
                onClick={() => handleDocumentSplit(invoice, 'fixed')}
              >
                {ready && t('INVOICE_SPLIT_REQUEST_SPLIT_PER_PAGE')}
              </MenuItem>
            </Hidden>
            <MenuItem
              key="delete"
              onClick={(e) => { onSelectMenuItem(e, 900, null, invoice.id, {}); setSplitMenuAnchorEl(null); }}
            >
              {ready && t('INVOICE_DELETE_INVOICE')}
            </MenuItem>
          </Menu>
        </Grid>
      </TableCell>
    </TableRow>
  );

  const renderDuplicateRow = (invoice, isInvoiceSelected) => (
    <TableRow
      className={classes.pointer}
    >
      <TableCell
        padding="normal"
        className={classes.checkCell}
      >
        <Checkbox
          checked={isInvoiceSelected}
          value={isInvoiceSelected}
          className={classes.checkbox}
          icon={<Grid className={classes.checkboxIcon} />}
          onClick={(event) => !invoice.outOfLimit && handleSelectOneInvoice(event, null, invoice.status, invoice.id)}
          disabled={invoice.outOfLimit || loading}
        />
      </TableCell>
      <Hidden mdDown>
        <TableCell width={140} className={classes.statusCell}>
          <LabelStatus
            className={classes.statusElementSelected}
            color={getInvoiceStatusKey(201)}
          >
            <Typography className={classes.statusText}>
              {ready && t(getInvoiceStatus(201))}
            </Typography>
          </LabelStatus>
        </TableCell>
      </Hidden>
      <Hidden only={['xs', 'lg', 'xl']}>
        <TableCell>
          <LabelStatusDot
            color={getInvoiceStatusKey(201)}
          />
        </TableCell>
      </Hidden>
      <Hidden mdDown>
        <TableCell colSpan={8}>
          <Grid className={classes.splitRequestContainer}>
            <Typography className={classes.splitRequestText}>{t('INVOICE_DUPLICATE_MSG', { document: truncateFileName(invoice.fileName, 44) })}</Typography>
            {duplicateRequestButtons(invoice)}
          </Grid>
        </TableCell>
      </Hidden>
      <Hidden only={['xs', 'sm', 'lg', 'xl']}>
        <TableCell colSpan={5}>
          <Typography className={classes.splitRequestText}>{t('INVOICE_DUPLICATE_MSG', { document: truncateFileName(invoice.fileName, 44) })}</Typography>
        </TableCell>
      </Hidden>
      <Hidden only={['xs', 'md', 'lg', 'xl']}>
        <TableCell colSpan={3}>
          <Typography className={classes.splitRequestText}>{t('INVOICE_DUPLICATE_MSG', { document: truncateFileName(invoice.fileName, 44) })}</Typography>
        </TableCell>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <TableCell colSpan={2}>
          <Typography className={classes.splitRequestText}>{t('INVOICE_DUPLICATE_MSG', { document: truncateFileName(invoice.fileName, 44) })}</Typography>
        </TableCell>
      </Hidden>
      {/* <Hidden mdDown>
        <TableCell width={156} align="center">
          {(IS_BRAND_PROCYS || BRAND_NAME === 'Extrable') && (
            <Link onClick={() => window.open(kbLinks[BRAND_NAME], '_blank')}>
              <Typography className={classes.splitRequestHelp}>{t('INVOICE_SPLIT_REQUEST_HELP')}</Typography>
            </Link>
          )}
        </TableCell>
      </Hidden> */}
      <TableCell width={76} align="right">
        <Grid className={classes.actionCell}>
          {/* <Hidden only={['xs', 'lg', 'xl']}>
            {(IS_BRAND_PROCYS || BRAND_NAME === 'Extrable') && (
              <Tooltip title={t('DASHBOARD_NAV_KB_LINK')}>
                <IconButton className={classes.kbIcon} onClick={() => window.open(kbLinks[BRAND_NAME], '_blank')}>
                  <HelpCenterIcon />
                </IconButton>
              </Tooltip>
            )}
          </Hidden> */}
          <IconButton disabled={loading} onClick={(e) => handleDuplicateMenuClick(e, invoice.id)}>
            <SvgIcon style={{ display: 'flex' }}>
              <MoreVertIcon className={classes.moreIcon} />
            </SvgIcon>
          </IconButton>
          <Menu
            id="invoice-actions"
            anchorEl={duplicateMenuAnchorEl}
            keepMounted
            open={Boolean(duplicateMenuAnchorEl) && duplicateMenuAnchorId === invoice.id}
            onClose={() => setDuplicateMenuAnchorEl(null)}
          >
            <Hidden only={['lg', 'xl']}>
              <MenuItem
                key="checkDuplicate"
                onClick={() => handleDuplicateMenuOpen(invoice)}
              >
                {ready && t('INVOICE_DUPLICATE_VIEW')}
              </MenuItem>
              <MenuItem
                key="notDuplicate"
                onClick={() => handleProcessDocument(invoice, 'proceed')}
              >
                {ready && t('INVOICE_DUPLICATE_NO_DUPLICATE')}
              </MenuItem>
              <MenuItem
                key="deleteDuplicate"
                onClick={() => handleProcessDocument(invoice, 'delete')}
              >
                {ready && t('INVOICE_DUPLICATE_DELETE')}
              </MenuItem>
            </Hidden>
          </Menu>
        </Grid>
      </TableCell>
    </TableRow>
  );

  return (
    <Box minWidth="100%">
      <Table size="small">
        {
          invoices?.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell />
                <Hidden mdDown>
                  <TableCell align="left" className={classes.headerName}>{ready && t('INVOICE_LIST_TABLE_HEADER_STATUS')}</TableCell>
                </Hidden>
                <Hidden only={['xs', 'lg', 'xl']}>
                  <TableCell>{t('INVOICE_LIST_TABLE_HEADER_STATUS')}</TableCell>
                </Hidden>
                {!user.customisations.includes('dontIdentifyDuplicateDoc') && (<TableCell className={classes.noPadding} />)}
                <Hidden mdDown>
                  <TableCell>
                    <Grid className={classes.sortableTableCell}>
                      {t('INVOICE_LIST_TABLE_HEADER_DOCUMENT_NAME')}
                      <IconButton onClick={() => handleSort('file_name')}>
                        <SvgIcon>
                          {sortDirection === 'asc' && sortField === 'file_name'
                            ? <ArrowDropUpIcon className={sortField === 'file_name' ? classes.sortIconActive : classes.sortIcon} />
                            : <ArrowDropDownIcon className={sortField === 'file_name' ? classes.sortIconActive : classes.sortIcon} />}
                        </SvgIcon>
                      </IconButton>
                    </Grid>
                  </TableCell>
                </Hidden>
                <TableCell className={classes.headerName}>
                  <Grid className={classes.sortableTableCell}>
                    {ready && t('INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER')}
                    <IconButton onClick={() => handleSort('number')}>
                      <SvgIcon>
                        {sortDirection === 'asc' && sortField === 'number'
                          ? <ArrowDropUpIcon className={sortField === 'number' ? classes.sortIconActive : classes.sortIcon} />
                          : <ArrowDropDownIcon className={sortField === 'number' ? classes.sortIconActive : classes.sortIcon} />}
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                </TableCell>
                {!isPassportOrID && (
                <Hidden mdDown>
                  <TableCell>
                    <Grid className={classes.sortableTableCell}>
                      {t('INVOICE_LIST_TABLE_HEADER_DOCUMENT_DATE')}
                      <IconButton onClick={() => handleSort('date')}>
                        <SvgIcon>
                          {sortDirection === 'asc' && sortField === 'date'
                            ? <ArrowDropUpIcon className={sortField === 'date' ? classes.sortIconActive : classes.sortIcon} />
                            : <ArrowDropDownIcon className={sortField === 'date' ? classes.sortIconActive : classes.sortIcon} />}
                        </SvgIcon>
                      </IconButton>
                    </Grid>
                  </TableCell>
                </Hidden>
                )}
                {/* Enabled only for Destinia company and sub-companies */}
                {
                  user.companyID === '3194130882' || (user.parent && user.parent === '3194130882')
                    ? (
                      <Hidden mdDown>
                        <TableCell className={classes.headerName}>{ready && t('INVOICE_LIST_TABLE_HEADER_REFERENCIA')}</TableCell>
                      </Hidden>
                    ) : null
                }
                {!isPassportOrID && (
                <Hidden smDown>
                  <TableCell className={classes.headerName}>
                    <Grid className={classes.sortableTableCell}>
                      {ready && t('INVOICE_LIST_TABLE_HEADER_AMOUNT')}
                      <IconButton onClick={() => handleSort('amount')}>
                        <SvgIcon>
                          {sortDirection === 'asc' && sortField === 'amount'
                            ? <ArrowDropUpIcon className={sortField === 'amount' ? classes.sortIconActive : classes.sortIcon} />
                            : <ArrowDropDownIcon className={sortField === 'amount' ? classes.sortIconActive : classes.sortIcon} />}
                        </SvgIcon>
                      </IconButton>
                    </Grid>
                  </TableCell>
                </Hidden>
                )}
                {!isPassportOrID && (
                <Hidden smDown>
                  <TableCell className={classes.headerName}>{ready && t('INVOICE_LIST_TABLE_HEADER_SUPPLIER')}</TableCell>
                </Hidden>
                )}
                {isPassportOrID && (
                <Hidden smDown>
                  <TableCell className={classes.headerName}>{ready && t('INVOICE_LIST_TABLE_HEADER_NAME')}</TableCell>
                </Hidden>
                )}
                {isPassportOrID && (
                <Hidden smDown>
                  <TableCell className={classes.headerName}>{ready && t('INVOICE_LIST_TABLE_HEADER_NATIONALITY')}</TableCell>
                </Hidden>
                )}
                <Hidden mdDown>
                  <TableCell className={classes.headerName}>{ready && t('INVOICE_LIST_TABLE_HEADER_OWNED_BY')}</TableCell>
                </Hidden>
                <Hidden xsDown>
                  <TableCell align="center" className={classes.headerName}>
                    <Grid className={classes.sortableTableCell}>
                      {ready && t('INVOICE_LIST_TABLE_HEADER_DATE')}
                      <IconButton onClick={() => handleSort('uploaded_date')}>
                        <SvgIcon>
                          {sortDirection === 'asc' && sortField === 'uploaded_date'
                            ? <ArrowDropUpIcon className={sortField === 'uploaded_date' ? classes.sortIconActive : classes.sortIcon} />
                            : <ArrowDropDownIcon className={sortField === 'uploaded_date' ? classes.sortIconActive : classes.sortIcon} />}
                        </SvgIcon>
                      </IconButton>
                    </Grid>
                  </TableCell>
                </Hidden>
                <TableCell />
              </TableRow>
            </TableHead>
          )
        }
        <TableBody>
          {invoices.map((invoice, i) => {
            const isInvoiceSelected = isSelected(invoice.id);
            const isInvoiceToday = invoice.appUploadedDate >= today;

            if (invoice.status === 200 && !invoice.outOfLimit) {
              return renderSplitRequiredRow(invoice, isInvoiceSelected);
            }

            if (invoice.status === 201 && !invoice.outOfLimit) {
              return renderDuplicateRow(invoice, isInvoiceSelected);
            }

            return (
              <Tooltip
                key={invoice.id}
                disableHoverListener={!invoice.outOfLimit && invoice.status >= 16}
                title={
                  invoice.outOfLimit // eslint-disable-line
                    ? ready && t(user.affiliate ? 'INVOICE_STATUS_OUT_OF_LIMIT_AFFILIATE_TOOLTIP' : 'INVOICE_STATUS_OUT_OF_LIMIT_TOOLTIP')
                    : invoice.status < 16
                      ? ready && t('INVOICE_STATUS_PROCESSING_TOOLTIP') : ''
                }
                onMouseMove={(e) => setPosition({ x: e.pageX, y: e.pageY })}
                PopperProps={{
                  anchorEl: {
                    clientHeight: 0,
                    clientWidth: 0,
                    getBoundingClientRect: () => ({
                      top: position.y,
                      left: position.x,
                      right: position.x,
                      bottom: position.y,
                      width: 0,
                      height: 0,
                    })
                  }
                }}
              >
                <TableRow
                  hover
                  key={invoice.id}
                  className={classes.pointer}
                  selected={isInvoiceSelected}
                  style={isInvoiceToday ? { backgroundColor: '#F9FAFC' } : {}}
                  onContextMenu={(e) => getContextMenu(e, invoice)}
                >
                  <TableCell
                    padding="normal"
                    className={classes.checkCell}
                  >
                    <Checkbox
                      disabled={loading}
                      checked={isInvoiceSelected}
                      value={isInvoiceSelected}
                      className={classes.checkbox}
                      icon={<Grid className={classes.checkboxIcon} />}
                      onClick={(event) => !invoice.outOfLimit
                        && handleSelectOneInvoice(event, invoice.assistantID, invoice.status, invoice.id, invoice.exportedToGstock, invoice.isDuplicate)}
                    />
                  </TableCell>
                  <Hidden mdDown>
                    <TableCell onClick={() => handleDocumentClick(invoice, i)} className={classes.statusCell} width={containsFailedToExport ? 175 : 140}>
                      <Grid className={classes.statusContainerRow}>
                        {(invoice.status === invoiceStatusCode.toexport
                          && (invoice.isDuplicate, invoice.exportFailReason))
                          && <span className={classes.exportFailedRow}>!</span>}
                        <LabelStatus
                          className={classes.statusElementSelected}
                          color={
                            getInvoiceStatusKey(invoice.outOfLimit ? 999
                              : (invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason)
                                ? 100 : invoice.status)
                          }
                        >
                          <Typography className={classes.statusText}>
                            {ready && (invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason)
                              ? t('DOCUMENT_FAILED_TO_EXPORT')
                              : ready && t(getInvoiceStatus(invoice.outOfLimit ? 999 : invoice.status))}
                          </Typography>
                        </LabelStatus>
                      </Grid>
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'lg', 'xl']}>
                    <TableCell onClick={() => handleDocumentClick(invoice, i)}>
                      <Grid className={classes.statusContainerRow}>
                        {(invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason) && <span className={classes.exportFailedRow}>!</span>}
                        <LabelStatusDot
                          color={
                            getInvoiceStatusKey(invoice.outOfLimit ? 999
                              : (invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason)
                                ? 100 : invoice.status)
                          }
                        />
                      </Grid>
                    </TableCell>
                  </Hidden>
                  {!user.customisations.includes('dontIdentifyDuplicateDoc') && (
                    <TableCell className={classes.noPadding}>
                      {invoice.isDuplicate && <Tooltip title={t('INVOICE_TABLE_DUPLICATE_TOOLTIP')}><span className={classes.duplicateFlag} /></Tooltip>}
                    </TableCell>
                  )}
                  <Hidden mdDown>
                    <TableCell
                      onClick={() => handleDocumentClick(invoice, i)}
                      className={isInvoiceToday ? classes.todayText : ''}
                      style={{
                        fontSize: 14,
                        lineHeight: '22px',
                        color: invoice.outOfLimit ? '#9E9E9E' : '#000000'
                      }}
                    >
                      {truncateFileName(invoice.fileName, 15)}
                    </TableCell>
                  </Hidden>
                  <TableCell
                    onClick={() => handleDocumentClick(invoice, i)}
                    className={isInvoiceToday ? classes.todayText : ''}
                    style={{
                      fontSize: 14,
                      lineHeight: '22px',
                      color: invoice.outOfLimit ? '#9E9E9E' : '#000000'
                    }}
                  >
                    {truncateString(invoice.documentNumber, 15)}
                  </TableCell>
                  {!isPassportOrID && (
                  <Hidden mdDown>
                    <TableCell onClick={() => handleDocumentClick(invoice, i)}>{formatDateCustomFormat(invoice.date, dateFieldFormat)}</TableCell>
                  </Hidden>
                  )}
                  {/* Enabled only for Destinia company and sub-companies */}
                  {
                    user.companyID === '3194130882' || (user.parent && user.parent === '3194130882')
                      ? (
                        <Hidden mdDown>
                          <TableCell
                            onClick={() => handleDocumentClick(invoice, i)}
                            className={isInvoiceToday ? classes.todayText : ''}
                            style={{
                              fontSize: 13,
                              lineHeight: '24px',
                              color: invoice.outOfLimit ? '#9E9E9E' : '#000000'
                            }}
                          >
                            {truncateString(invoice.referenceNumber, 15)}
                          </TableCell>
                        </Hidden>
                      ) : null
                  }
                  {!isPassportOrID && (
                  <Hidden smDown>
                    <TableCell
                      onClick={() => handleDocumentClick(invoice, i)}
                      className={classes.currencyText}
                      style={{ fontSize: 14, lineHeight: '25.5px', color: invoice.outOfLimit ? '#9E9E9E' : '#000000' }}
                    >
                      {invoice.currency}
                      {' '}
                      {invoice.amount && formatAmountInvoicesTable(invoice.amount, user.monetaryDecimalSeparator)}
                      {!invoice.currency && !invoice.amount && '-'}
                    </TableCell>
                  </Hidden>
                  )}
                  {!isPassportOrID && (
                  <Hidden smDown>
                    <TableCell
                      onClick={() => handleDocumentClick(invoice, i)}
                      style={{ fontSize: 14, lineHeight: '22.5px', color: invoice.outOfLimit ? '#9E9E9E' : '#000000' }}
                    >
                      {truncateString(invoice.supplier || '', 16)}
                    </TableCell>
                  </Hidden>
                  )}
                  {isPassportOrID && (
                  <Hidden smDown>
                    <TableCell
                      onClick={() => handleDocumentClick(invoice, i)}
                      style={{ fontSize: 14, lineHeight: '22.5px', color: invoice.outOfLimit ? '#9E9E9E' : '#000000' }}
                    >
                      {truncateString(invoice.name || '', 16)}
                    </TableCell>
                  </Hidden>
                  )}
                  {isPassportOrID && (
                  <Hidden smDown>
                    <TableCell
                      onClick={() => handleDocumentClick(invoice, i)}
                      style={{ fontSize: 14, lineHeight: '22.5px', color: invoice.outOfLimit ? '#9E9E9E' : '#000000' }}
                    >
                      {truncateString(invoice.nationality || '', 16)}
                    </TableCell>
                  </Hidden>
                  )}
                  <Hidden mdDown>
                    {
                      invoice.owner ? (
                        <TableCell id={`owner-${invoice.id}`}>
                          <Select
                            disabled={invoice.outOfLimit}
                            onChange={(e) => handleChangeOwner(e.target.value, invoice.assistantID)}
                            className={classes.formInputSelect}
                            classes={{
                              root: classes.selectRoot,
                              icon: classes.selectIcon,
                            }}
                            value={users.find((u) => u.email === invoice.owner)?.email || ''}
                            variant="outlined"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left'
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            {users.map((u) => (
                              <MenuItem key={u.email} value={u.email}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Avatar
                                    alt={u.firstname && u.firstname[0]}
                                    className={classes.userImage}
                                    src={
                                      u.avatar && u.avatar.indexOf('data:image') >= 0
                                        ? u.avatar : `${API.getProfileImage}${u.avatar}`
                                    }
                                  />
                                  <Box ml={2} marginLeft="8px">
                                    <Link
                                      variant="subtitle2"
                                      color="textPrimary"
                                      component={RouterLink}
                                      underline="none"
                                      to="#"
                                      className={classes.usernameText}
                                      style={{ color: '#000000' }}
                                    >
                                      {truncateString(`${u.firstname} ${u.lastname}`, 12)}
                                    </Link>
                                  </Box>
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      ) : <TableCell />
                    }
                  </Hidden>
                  <Hidden xsDown>
                    {
                      isInvoiceToday ? (
                        <TableCell
                          onClick={() => handleDocumentClick(invoice, i)}
                          className={isInvoiceToday ? classes.todayText : ''}
                          align="center"
                          style={{ fontSize: 13, lineHeight: '22.5px', color: invoice.outOfLimit ? '#9E9E9E' : '#000000' }}
                        >
                          {moment.unix(invoice.appUploadedDate).format('hh:mm')}
                        </TableCell>
                      ) : (
                        <TableCell
                          onClick={() => handleDocumentClick(invoice, i)}
                          className={classes.timeBox}
                          align="center"
                          style={{ fontSize: 13, lineHeight: '22.5px', color: invoice.outOfLimit ? '#9E9E9E' : '#000000' }}
                        >
                          {moment.unix(invoice.appUploadedDate).format('MMM DD, YYYY')}
                        </TableCell>
                      )
                    }
                  </Hidden>
                  <TableCell width={76} align="center">
                    <Grid className={classes.actionCell}>
                      <Tooltip
                        title={!isActionPermitted(getPermissionByInvoiceStatus(invoice.status), user.permissions)
                          ? ready && t(`INVOICE_STATUS_CHANGE_TOOLTIP_NO_PERMISSION_${i18nByStatus(status)}`)
                          : ''}
                      >
                        <span style={{ display: 'flex' }}>
                          <IconButton
                            onClick={(e) => handleOpenMenu(e, invoice.assistantID)}
                            disabled={
                              !isActionPermitted(getPermissionByInvoiceStatus(invoice.status), user.permissions)
                              || getInvoiceStatusKey(invoice.status) === invoiceStatusesKeys.all
                              || getInvoiceStatusKey(invoice.status) === invoiceStatusesKeys.uploading
                              || invoice.outOfLimit
                            }
                          >
                            <SvgIcon style={{ display: 'flex' }}>
                              <MoreVertIcon className={classes.moreIcon} />
                            </SvgIcon>
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Menu
                        id="invoice-actions"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl) && menuAssistantID === invoice.assistantID}
                        onClose={handleCloseMenu}
                      >
                        {
                          [...invoiceStatusCodesList, 'logs'].map((s) => {
                            if (
                              getInvoiceStatus(invoice.status) !== getInvoiceStatus(s)
                                && isStatusChangeValid(invoice.status, s, isToValidateStatusAllowed)
                            ) {
                              return (
                                <MenuItem
                                  key={s}
                                  onClick={(e) => onSelectMenuItem(e, s, invoice.assistantID, invoice.id, invoice)}
                                >
                                  {ready && t(s === 501 ? t('INVOICE_EDIT_FORM_REVIEW') : getInvoiceStatus(s))}
                                </MenuItem>
                              );
                            }
                            if (s === 'logs') {
                              return (
                                <MenuItem
                                  key={s}
                                  onClick={(e) => gotoLogs(e, invoice.assistantID)}
                                >
                                  {ready && t('INVOICE_EDIT_FORM_DOCUMENT_LOGS')}
                                </MenuItem>
                              );
                            }
                            return null;
                          })
                        }
                        {
                          ((invoice.status > 16 && invoice.status <= 100) || invoice.status === 600) && (
                          <MenuItem
                            key={900}
                            onClick={(e) => onSelectMenuItem(e, 900, invoice.assistantID, invoice.id, invoice)}
                          >
                            {ready && t(getInvoiceStatus(900))}
                          </MenuItem>
                          )
                        }
                        <Hidden smUp>
                          <MenuItem onClick={(e) => gotoLogs(e, invoice.assistantID)}>
                            {ready && t('INVOICES_TABLE_VIEW_LOGS')}
                          </MenuItem>
                        </Hidden>
                      </Menu>
                    </Grid>
                  </TableCell>
                </TableRow>
              </Tooltip>
            );
          })}
        </TableBody>
      </Table>
      {
        rcShow && (
          <Menu
            open={rcShow}
            anchorEl={document.getElementById('root')}
            style={{
              top: rcY,
              left: rcX,
              position: 'absolute'
            }}
          >
            <MenuItem onClick={() => openInNewTab()}>
              {ready && t('OPEN_LINK_IN_NEW_TAB')}
            </MenuItem>
          </Menu>
        )
      }
      <DeleteDocumentModal
        openDeleteConf={openDeleteConf}
        onCancelDelete={onCancelDelete}
        onDeleteInvoice={onDeleteInvoice}
      />
      <SplitMenu
        splitMenuOpen={splitMenuOpen}
        handleClose={handleSplitMenuClose}
        invoice={documentToSplit}
        getInvoices={getInvoices}
      />
      <DuplicateMenu
        duplicateMenuOpen={duplicateMenuOpen}
        handleClose={handleDuplicateMenuClose}
        invoice={duplicateDocument}
        handleProcessDocument={handleProcessDocument}
      />
    </Box>
  );
};

InvoicesTable.propTypes = {
  paginatedInvoices: PropTypes.array,
  selectedInvoices: PropTypes.array,
  handleSelectOneInvoice: PropTypes.func,
  status: PropTypes.string,
  time: PropTypes.string,
  getInvoices: PropTypes.func,
  getArchivedInvoices: PropTypes.func,
  query: PropTypes.string,
  filters: PropTypes.shape({
    recent: PropTypes.number
  }),
  page: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  supplierFilter: PropTypes.bool,
  ownerFilter: PropTypes.bool,
  isToValidateStatusAllowed: PropTypes.bool,
  onSelectMenuItem: PropTypes.func,
  handleOpenMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  menuAnchorEl: PropTypes.object,
  menuAssistantID: PropTypes.object,
  deleteInvoiceID: PropTypes.string,
  setDeleteInvoiceID: PropTypes.func,
  openDeleteConf: PropTypes.string,
  setOpenDeleteConf: PropTypes.func,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  failedToExport: PropTypes.bool,
  users: PropTypes.array,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  sortDirection: PropTypes.object,
  sortField: PropTypes.string,
  handleSort: PropTypes.func,
};

export default InvoicesTable;
