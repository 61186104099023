import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: 0
  },
  searchBar: {
    flex: 3,
    marginRight: 24,
    marginTop: 12,
    marginBottom: 12,
  },
  searchBarInput: {
    height: 36,
    width: '100%',
  },
  searchInput: {
    '&::placeholder': {
      color: '#90A0B7',
      fontSize: 14,
      lineHeight: '21px'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px',
    marginRight: 24
  },
  filterBtn: {
    fontSize: 13,
    color: '#90A0B7',
    textTransform: 'none'
  },
  filterBtnSelected: {
    fontSize: 13,
    color: '#3E8EFF',
    textTransform: 'none',
    borderRadius: 8,
    border: '1px solid #3E8EFF',
    padding: '4px 6px',
  },
  filterIcon: {
    paddingRight: 16
  },
  filterMenu: {
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 8%)',
    border: '1px solid #DDDDDD'
  },
  filterMenuItem: {
    fontSize: 13,
    color: '#192A3E',
    overflow: 'scroll',
    paddingTop: 0,
    paddingBottom: 0
  },
  selectedFilterMenuItem: {
    color: theme.palette.status.toreview
  },
  captionTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8
  },
  captionText: {
    height: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: 'rgba(51, 77, 110, 0.5)',
    paddingLeft: 24
  },
}));

export default useStyles;
