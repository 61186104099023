import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
  },
  paperModal: {
    maxWidth: 500,
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
  },
  modalBody: {
    minWidth: 360,
    maxWidth: 500,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },
  header: {
    marginBottom: 16,
    color: '#334D6E',
    textAlign: 'center'
  },
  noPadding: {
    padding: '0px 16px',
    color: '#334D6E',
    fontSize: 13
  },
  closeBtn: {
    padding: '8px!important',
  },
  fieldStyle: {
    paddingBottom: 24
  },
  applyToAllContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 16
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 16
  },
  reqButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16
  },
  cancelBtn: {
    color: '#334D6E',
    border: '1px #e4e4e4 solid',
    textTransform: 'none',
    marginLeft: 24
  },
  checkBoxLabel: {
    fontSize: 14,
    color: '#334D6E'
  },
  checkBox: {
    color: '#3E8AFF',
    paddingBottom: 0,
    paddingTop: 0
  },
  checkboxContainer: {
    marginRight: 8,
    marginBottom: 8
  },
  requestBtn: {
    color: '#334D6E',
    border: '1px #e4e4e4 solid',
    textTransform: 'none',
    marginRight: 16
  },
  description: {
    fontSize: 13,
    color: '#334D6E',
    marginBottom: 16
  },
  fieldsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 240,
    overflow: 'scroll'
  },
  sendBtn: {
    textTransform: 'none'
  }
}));

export default useStyles;
