import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Grid, Typography
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import useStyles from './style';

const OverviewStats = ({
  totalErrored, totalUploading, totalDigitized, totalRejected, totalArchived,
  totalToreview, totalTovalidate, totalToexport, totalExported
}) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const isToValidateStatusAllowed = user.customisations.includes('toValidateStatus');
  const { ready, t } = useTranslation();
  const [menuValue, setMenuValue] = React.useState(user.role !== 'company' ? 0 : 1);

  const handleChangeDisplay = (e, a) => {
    setMenuValue(a);
  };

  return (
    <Grid className={classes.summaryStats}>
      <Grid className={classes.topLine}>
        {
          user.role === 'company' && (
            <Grid className={classes.overviewText}>
              {ready && t('DASHBOARD_OVERVIEW')}
            </Grid>
          )
        }
        {
          user.role !== 'company' && (
            <Grid className={classes.overviewTabs}>
              <ToggleButtonGroup
                color="primary"
                value={menuValue}
                exclusive
                onChange={handleChangeDisplay}
              >
                <ToggleButton value={0} className={classes.toggleBtn}>{ready && t('OVERVIEW_STATUS_APPROVAL_SHORT')}</ToggleButton>
                <ToggleButton value={1} className={classes.toggleBtn}>{ready && t('OVERVIEW_STATUS_SUMMARY_SHORT')}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )
        }
        {/* <Grid className={classes.overviewActions}>
          <Grid>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              // onClick={handleClick}
              className={classes.menuBtn}
            >
              {ready && t('DASHBOARD_OVERVIEW_ALLTIME')}
              <SvgIcon fontSize="small">
                <ExpandMoreIcon className={classes.moreIcon} />
              </SvgIcon>
            </Button>
          </Grid>
        </Grid> */}
      </Grid>
      {
        user.role === 'company' && (
          <Grid className={classes.statsCompany}>
            <Grid className={classes.statsColumn}>
              {
                menuValue === 1 && (
                  <Grid>
                    <Typography className={classes.dullText}>
                      {ready && t('DASHBOARD_OVERVIEW_TOTAL')}
                    </Typography>
                    <Typography className={classes.overviewNumber}>
                      {totalDigitized + totalErrored + totalArchived}
                    </Typography>
                  </Grid>
                )
              }
              {
                menuValue === 1 && (
                  <Grid>
                    <Typography className={classes.dullText}>
                      {ready && t('DASHBOARD_OVERVIEW_DIGITIZED')}
                    </Typography>
                    <Typography className={classes.overviewNumber}>{totalDigitized}</Typography>
                  </Grid>
                )
              }
            </Grid>
            <Grid className={classes.statsColumn}>
              {
                menuValue === 1 && (
                  <Grid>
                    <Typography className={classes.dullText}>
                      {ready && t('DASHBOARD_OVERVIEW_UPLOAD')}
                    </Typography>
                    <Typography className={classes.overviewNumber}>{totalUploading}</Typography>
                  </Grid>
                )
              }
              {
                menuValue === 1 && (
                  <Grid>
                    <Grid className={classes.errorLabel}>
                      <ReportProblemOutlinedIcon className={classes.errorIcon} />
                      <Typography className={classes.redText}>
                        {ready && t('DASHBOARD_OVERVIEW_ERRORED')}
                      </Typography>
                    </Grid>
                    <Typography className={classes.overviewErrorNumber}>{totalErrored}</Typography>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        )
      }
      {
        user.role !== 'company' && (
          <Grid className={classes.stats}>
            <Grid className={classes.statsColumn}>
              {
                menuValue === 1 && (
                  <>
                    <Grid>
                      <Typography className={classes.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_TOTAL')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>
                        {totalDigitized + totalErrored + totalArchived}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid className={classes.errorLabel}>
                        <ReportProblemOutlinedIcon className={classes.errorIcon} />
                        <Typography className={classes.redText}>
                          {ready && t('DASHBOARD_OVERVIEW_ERRORED')}
                        </Typography>
                      </Grid>
                      <Typography className={classes.overviewErrorNumber}>{totalErrored}</Typography>
                    </Grid>
                  </>
                )
              }
              {
                menuValue === 0 && (
                  <>
                    <Grid>
                      <Typography className={classes.blueText}>
                        {ready && t('DASHBOARD_OVERVIEW_REVIEW')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>{totalToreview}</Typography>
                    </Grid>
                    <Grid className={`${classes.statsColumn}`}>
                      <Grid>
                        <Typography className={classes.darkGreenText}>
                          {ready && t('DASHBOARD_OVERVIEW_EXPORTED')}
                        </Typography>
                        <Typography className={classes.overviewNumber}>{totalExported}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              }
            </Grid>
            <Grid className={classes.statsColumn}>
              {
                menuValue === 1 && (
                  <>
                    <Grid>
                      <Typography className={classes.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_UPLOAD')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>{totalUploading}</Typography>
                    </Grid>
                    <Grid>
                      <Typography className={classes.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_ARCHIVED')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>{totalArchived}</Typography>
                    </Grid>
                  </>
                )
              }
              {
                menuValue === 0 && (
                  <>
                    {isToValidateStatusAllowed && (
                      <Grid>
                        <Typography className={classes.yellowText}>
                          {ready && t('DASHBOARD_OVERVIEW_VALIDATE')}
                        </Typography>
                        <Typography className={classes.overviewNumber}>{totalTovalidate}</Typography>
                      </Grid>
                    )}
                    <Grid>
                      <Typography className={classes.orangeText}>
                        {ready && t('DASHBOARD_OVERVIEW_REJECTED')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>{totalRejected}</Typography>
                    </Grid>
                    {!isToValidateStatusAllowed && (<Grid className={classes.placeholder} />)}
                  </>
                )
              }
            </Grid>
            <Grid className={classes.statsColumn}>
              {
                menuValue === 1 && (
                  <>
                    <Grid>
                      <Typography className={classes.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_DIGITIZED')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>{totalDigitized}</Typography>
                    </Grid>
                    <Grid className={classes.hidden}>
                      <Typography className={classes.dullText}>
                        {ready && t('DASHBOARD_OVERVIEW_DIGITIZED')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>{totalDigitized}</Typography>
                    </Grid>
                  </>
                )
              }
              {
                menuValue === 0 && (
                  <>
                    <Grid>
                      <Typography className={classes.greenText}>
                        {ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>{totalToexport}</Typography>
                    </Grid>
                    <Grid className={classes.hidden}>
                      <Typography className={classes.greenText}>
                        {ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')}
                      </Typography>
                      <Typography className={classes.overviewNumber}>{totalToexport}</Typography>
                    </Grid>
                  </>
                )
              }
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
};

OverviewStats.propTypes = {
  totalUploading: PropTypes.number,
  totalDigitized: PropTypes.number,
  totalToreview: PropTypes.number,
  totalTovalidate: PropTypes.number,
  totalToexport: PropTypes.number,
  totalExported: PropTypes.number,
  totalRejected: PropTypes.number,
  totalArchived: PropTypes.number,
  totalErrored: PropTypes.number,
};

OverviewStats.defaultProps = {};

export default OverviewStats;
