import * as actionTypes from 'src/Auth/store/actions/actionTypes';

export const updateProfileStart = () => ({
  type: actionTypes.UPDATE_PROFILE_START
});

export const updateProfileSuccess = (user) => ({
  type: actionTypes.UPDATE_PROFILE_SUCCESS,
  user
});

export const updateProfileFail = (error) => ({
  type: actionTypes.UPDATE_PROFILE_FAIL,
  error
});

export const updateProfile = (user) => ({
  type: actionTypes.UPDATE_PROFILE,
  user
});

export const updateProfileImageStart = () => ({
  type: actionTypes.UPDATE_PROFILE_IMAGE_START
});

export const updateProfileImageSuccess = () => ({
  type: actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS
});

export const updateProfileImageFail = (error) => ({
  type: actionTypes.UPDATE_PROFILE_IMAGE_START,
  error
});

export const updateProfileImage = (image) => ({
  type: actionTypes.UPDATE_PROFILE_IMAGE,
  image
});

export const updatePasswordStart = () => ({
  type: actionTypes.UPDATE_PASSWORD_START
});

export const updatePasswordSuccess = () => ({
  type: actionTypes.UPDATE_PASSWORD_SUCCESS
});

export const updatePasswordFail = (error) => ({
  type: actionTypes.UPDATE_PASSWORD_START,
  error
});

export const updatePassword = (oldPassword, newPassword) => ({
  type: actionTypes.UPDATE_PASSWORD,
  oldPassword,
  newPassword
});
