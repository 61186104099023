import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop, Button, Checkbox, Fade, FormControlLabel, Grid, IconButton, MenuItem, Modal, Select, SvgIcon, TextField, Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import PlusCircleIcon from '@material-ui/icons/AddCircle';
import ReorderIcon from '@material-ui/icons/Reorder';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  dataFormatLabels, esLineFieldMapping, esLineFields, esLineLabelsForExport, exportMappingEntities, invoiceFieldMapping,
  invoiceLinesFieldMapping, vatLineFieldMapping, vatLineFields, vatLineLabelsForExport, supplierFieldMapping
} from 'src/config';
import Axios from 'axios';
import { appendContactSupport, axiosHeaders } from 'src/Shared/utils/helpers';
import { useSnackbar } from 'notistack';
import ConfigContext from 'src/Contexts';
import { useSelector } from 'react-redux';
import useStyles from './style';

const linkToDocument = {
  key: 'linkToDocument',
  label: 'LINK_TO_DOCUMENT',
  isAvailable: true
};

const linkToDocumentPdf = {
  key: 'linkToDocumentPdf',
  label: 'LINK_TO_DOCUMENT_PDF',
  isAvailable: true
};

const documentOwner = {
  key: 'documentOwner',
  label: 'DOCUMENT_OWNER',
  isAvailable: true
};

const companyCode = {
  key: 'companyCode',
  label: 'INTEGRATION_COMPANY_CODE',
  isAvailable: true
};

const metaDataFields = [
  'LINK_TO_DOCUMENT',
  'LINK_TO_DOCUMENT_PDF',
  'DOCUMENT_OWNER'
];

const ManageExportFieldMappings = props => {
  const {
    format,
    exportFieldMappingsOpen,
    onModalClose,
    loading,
    fields,
    lineFields,
    supplierFields,
    excelFieldMapping,
    datFieldMapping,
    csvFieldMapping
  } = props;
  const { API } = useContext(ConfigContext);

  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(state => state.auth);

  const [dataFormat, setDataFormat] = useState(format?.key || 'excel');
  const [isEdited, setIsEdited] = useState(!!format);

  const [mappings, setMappings] = useState([]);
  const [headerMappings, setHeaderMappings] = useState(
    mappings.filter(m => m.entity === 'header')
  );
  const [lineMappings, setLineMappings] = useState(
    mappings.filter(m => m.entity === 'line')
  );
  const [vatMappings, setVatMappings] = useState(
    mappings.filter(m => m.entity === 'vat')
  );
  const [esMappings, setEsMappings] = useState(
    mappings.filter(m => m.entity === 'es')
  );
  const [supplierMappings, setSupplierMappings] = useState(
    mappings.filter(m => m.entity === 'supplier')
  );

  const [isApplyToAll, setIsApplyToAll] = useState(false);

  const isPassportOrID = user.documentType === 'id';
  const isParentDocTypeSame = user.documentType === user.parentDocType;

  useEffect(() => {
    if (dataFormat) {
      setIsApplyToAll(user.customisations?.some((c) => c === `applyParentMappings${dataFormat[0].toUpperCase() + dataFormat.slice(1)}`));
    }
  }, [dataFormat, user]);

  useEffect(() => setDataFormat(format ? format.key : 'excel'), [format]);

  useEffect(() => {
    setHeaderMappings(mappings.filter((m) => m.entity === 'header'));
    setLineMappings(mappings.filter((m) => m.entity === 'line'));
    setVatMappings(mappings.filter((m) => m.entity === 'vat'));
    setEsMappings(mappings.filter((m) => m.entity === 'es'));
    setSupplierMappings(mappings.filter((m) => m.entity === 'supplier'));
  }, [mappings]);

  useEffect(() => {
    let isFieldsEmpty = false;
    if (format?.key === 'excel' || dataFormat === 'excel') {
      setMappings(excelFieldMapping);
      if (excelFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    } else if (format?.key === 'dat' || dataFormat === 'dat') {
      setMappings(datFieldMapping);
      if (datFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    } else if (format?.key === 'csv' || dataFormat === 'csv') {
      setMappings(csvFieldMapping);
      if (csvFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    }
    if (isFieldsEmpty) {
      let newFields = fields.filter((f) => f.isAvailable && f.isActive).map((f) => ({
        procysField: invoiceFieldMapping[f.key],
        field: t(f.label),
        dataType: 'string',
        format: '',
        entity: 'header'
      }));
      if (!isPassportOrID) {
        newFields = [...newFields,...supplierFields.filter(f => f.isAvailable && f.isActive).map(f => ({
          procysField: supplierFieldMapping[f.key],
          field: t(f.label),
          dataType: 'string',
          format: '',
          entity: 'supplier'
        }))];
        newFields = [...newFields, ...lineFields.filter((f) => f.isAvailable && f.isActive).map((f) => ({
          procysField: invoiceLinesFieldMapping[f.key],
          field: t(f.label),
          dataType: 'string',
          format: '',
          entity: 'line'
        }))];
        newFields = [...newFields, ...vatLineFields.map((f) => ({
          procysField: vatLineFieldMapping[f.key],
          field: t(vatLineLabelsForExport[f.key]),
          dataType: 'string',
          format: '',
          entity: 'vat'
        }))];
        newFields = [...newFields, ...esLineFields.map((f) => ({
          procysField: esLineFieldMapping[f.key],
          field: t(esLineLabelsForExport[f.key]),
          dataType: 'string',
          format: '',
          entity: 'es'
        }))];
      }
      setMappings(newFields);
    }
  }, [excelFieldMapping, datFieldMapping, csvFieldMapping, format, dataFormat, fields, lineFields, supplierFields, isPassportOrID, t]);

  const handleFormatChange = event => {
    if (isEdited) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPING_SAVE_BEFORE_FORMAT_CHANGE'), {
        variant: 'error'
      });
      return;
    }
    setDataFormat(event.target.value);
  };

  const handleAddMapping = entity => {
    switch (entity) {
      case 'header':
        setHeaderMappings([
          ...headerMappings,
          {
            id: headerMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'supplier':
        setSupplierMappings([
          ...supplierMappings,
          {
            id: supplierMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'line':
        setLineMappings([
          ...lineMappings,
          {
            id: lineMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'vat':
        setVatMappings([
          ...vatMappings,
          {
            id: vatMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'es':
        setEsMappings([
          ...esMappings,
          {
            id: esMappings.length.toString(),
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      default:
    }
  };

  const removeMapping = (index, entity) => {
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          newMappings.splice(index, 1);
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier': 
        {
          const newMappings = [...supplierMappings];
          newMappings.splice(index, 1);
          setSupplierMappings(newMappings);
        }
        break
      case 'line':
        {
          const newMappings = [...lineMappings];
          newMappings.splice(index, 1);
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          newMappings.splice(index, 1);
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          newMappings.splice(index, 1);
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const handleChange = (i, prop, val, entity) => {
    setIsEdited(true);
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          newMappings[i][prop] = val;
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier':
        {
          const newMappings = [...supplierMappings];
          newMappings[i][prop] = val;
          setSupplierMappings(newMappings);
        }
        break;
      case 'line':
        {
          const newMappings = [...lineMappings];
          newMappings[i][prop] = val;
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          newMappings[i][prop] = val;
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          newMappings[i][prop] = val;
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const handleReorder = (source, destination, entity) => {
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier':
        {
          const newMappings = [...supplierMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setSupplierMappings(newMappings);
        }
        break;
      case 'line':
        {
          const newMappings = [...lineMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const handleSaveMappings = async () => {
    const hasEmpty = [
      ...headerMappings,
      ...supplierMappings,
      ...lineMappings,
      ...vatMappings,
      ...esMappings
    ].some(m => !m.procysField || !m.field);
    if (hasEmpty) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_EMPTY'), {
        variant: 'error',
        persist: true
      });
      return;
    }
    let reqBody = {
      mappings: [
        ...headerMappings,
        ...supplierMappings,
        ...lineMappings,
        ...vatMappings,
        ...esMappings
      ]
    };
    if (dataFormat === 'dat') {
      reqBody = {
        mappings: [...headerMappings, ...supplierMappings]
      };
    }
    try {
      const response = await Axios.post(
        `${API.exportFieldMapping}${dataFormat}`,
        reqBody,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setIsEdited(false);
        enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        onModalClose(true, format);
      } else {
        enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_FAILED'), {
          variant: 'error',
          persist: true
        });
      }
    } catch (error) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_FAILED'), {
        variant: 'error',
        persist: true
      });
    }
  };

  const onChangeApplyToAll = async e => {
    e.persist();
    const { checked } = e.target;
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        {
          customisation: `applyParentMappings${dataFormat[0].toUpperCase() + dataFormat.slice(1)}`,
          enabled: checked
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t(checked ? 'EXPORT_MAPPINGS_APPLY_TO_ALL_SUCCESS' : 'EXPORT_MAPPINGS_UNAPPLY_TO_ALL_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 3000
          }
        );
        setIsApplyToAll(checked);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(
        window.config.support_email,
        t('EXPORT_MAPPINGS_APPLY_TO_ALL_FAILURE'),
        t
      );
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || error.response.data.message);
      }

      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errorMessage, t),
        {
          variant: 'error',
          autoHideDuration: 3000
        }
      );
    }
  };

  const data = {
    title: {
      header: 'EXPORT_FIELD_MAPPINGS_HEADER_FIELDS',
      supplier: 'EXPORT_FIELD_MAPPINGS_SUPPLIER_FIELDS',
      line: 'EXPORT_FIELD_MAPPINGS_LINE_FIELDS',
      vat: 'EXPORT_FIELD_MAPPINGS_VAT_LINE_FIELDS',
      es: 'EXPORT_FIELD_MAPPINGS_ES_LINE_FIELDS'
    },
    addBtn: {
      header: 'EXPORT_FIELD_MAPPINGS_ADD_HEADER_FIELD',
      supplier: 'EXPORT_FIELD_MAPPINGS_ADD_SUPPLIER_FIELD',
      line: 'EXPORT_FIELD_MAPPINGS_ADD_LINE_FIELD',
      vat: 'EXPORT_FIELD_MAPPINGS_ADD_VAT_FIELD',
      es: 'EXPORT_FIELD_MAPPINGS_ADD_ES_FIELD'
    },
    mappings: {
      header: headerMappings,
      supplier: supplierMappings,
      line: lineMappings,
      vat: vatMappings,
      es: esMappings
    },
    fields: {
      header: [...fields, linkToDocument, linkToDocumentPdf, documentOwner, companyCode],
      supplier: supplierFields,
      line: lineFields,
      vat: vatLineFields,
      es: esLineFields
    },
    fieldMapping: {
      header: invoiceFieldMapping,
      supplier: supplierFieldMapping,
      line: invoiceLinesFieldMapping,
      vat: vatLineFieldMapping,
      es: esLineFieldMapping
    }
  };

  const mappingContent = () => {
    if (
      user.isSubCompany &&
      user.parent &&
      isParentDocTypeSame &&
      ((dataFormat === 'excel' && user.customisations.includes('applyParentMappingsExcel')) ||
        (dataFormat === 'csv' && user.customisations.includes('applyParentMappingsCsv')) ||
        (dataFormat === 'dat' && user.customisations.includes('applyParentMappingsDat')))
    ) {
      return (
        <Grid className={classes.managedByParentContainer}>
          <Typography className={classes.managedByParent}>
            {t('EXPORT_FIELD_MAPPINGS_MANAGED_BY_PARENT', {
              dataFormat: dataFormatLabels[dataFormat]
            })}
          </Typography>
        </Grid>
      );
    }
    return exportMappingEntities.map((entity) => {
      if (isPassportOrID && entity !== 'header') return null;
      if (dataFormat === 'dat' && (entity === 'line' || entity === 'vat' || entity === 'es')) return null;
      return (
        <Grid className={classes.entityContainer} key={entity}>
          <Grid className={classes.entityTitleContainer}>
            <Typography className={classes.entityTitle}>
              {t(data.title[entity])}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              className={classes.action}
              onClick={() => handleAddMapping(entity)}
            >
              <SvgIcon fontSize="small" className={classes.actionIcon}>
                <PlusCircleIcon />
              </SvgIcon>
              {ready && t(data.addBtn[entity])}
            </Button>
          </Grid>
          <DragDropContext onDragEnd={(e) => handleReorder(e?.source?.index, e?.destination?.index, entity)}
          >
            <Droppable droppableId="droppable" direction="vertical">
              {droppableProvided => (
                <Grid
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {data.mappings[entity].map((item, i) => {
                    const { procysField, field } = item;
                    if (metaDataFields.includes(procysField)) return null;
                    return (
                      <Draggable
                        key={procysField ? `${procysField}_${i}` : i.toString()}
                        draggableId={
                          procysField ? `${procysField}_${i}` : i.toString()
                        }
                        index={i}
                      >
                        {draggableProvided => (
                          <Grid
                            container
                            className={classes.modalItemRow}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                          >
                            <Grid columns={1} {...draggableProvided.dragHandleProps} className={classes.dragIconContainer}>
                              <ReorderIcon />
                            </Grid>
                            <Grid item columns={5} className={classes.inputItem} >
                              <TextField
                                value={field}
                                onChange={(e) => handleChange(i, 'field', e.target.value, entity)}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                            <Grid item columns={5} className={classes.inputItem}>
                              <Select
                                id={`${entity}-procys-field`}
                                variant="outlined"
                                onChange={(e) => handleChange(i, 'procysField', e.target.value, entity)}
                                className={classes.selectInput}
                                value={ready && procysField}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                  },
                                  getContentAnchorEl: null
                                }}
                              >
                                {data.fields[entity].filter((f) => f.isAvailable).map(f => (
                                    <MenuItem className={classes.inputText} key={f.key} value={data.fieldMapping[entity][f.key]}>
                                      {ready && t(f.label)}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>
                            <Grid item columns={1}>
                              <IconButton onClick={() => removeMapping(i, entity)}>
                                <CloseIcon className={classes.closeIcon} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    );
                  })}
                  {entity === 'header' && (
                    <Grid className={classes.metaFieldsContainer}>
                      {data.mappings[entity].map((item, i) => {
                        const { procysField, field } = item;
                        if (metaDataFields.includes(procysField)) {
                          return (
                            <Draggable
                              key={procysField ? `${procysField}_${i}` : i.toString()}
                              draggableId={procysField ? `${procysField}_${i}` : i.toString()}
                              index={i}
                            >
                              {(draggableProvided) => (
                                <Grid
                                  container
                                  className={classes.modalItemRow}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  <Grid columns={1} {...draggableProvided.dragHandleProps} className={classes.dragIconContainer}>
                                    <ReorderIcon />
                                  </Grid>
                                  <Grid item columns={5} className={classes.inputItem}>
                                    <TextField
                                      value={field}
                                      onChange={(e) => handleChange(i, 'field', e.target.value, entity)}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item columns={5} className={classes.inputItem}>
                                    <Select
                                      id={`${entity}-procys-field`}
                                      variant="outlined"
                                      onChange={(e) => handleChange(i, 'procysField', e.target.value, entity)}
                                      className={classes.selectInput}
                                      value={ready && procysField}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left'
                                        },
                                        transformOrigin: {
                                          vertical: 'top',
                                          horizontal: 'left'
                                        },
                                        getContentAnchorEl: null
                                      }}
                                    >
                                      {data.fields[entity].filter((f) => f.isAvailable).map((f) => (
                                        <MenuItem className={classes.inputText} key={f.key} value={data.fieldMapping[entity][f.key]}>
                                          {ready && t(f.label)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                  <Grid item columns={1}>
                                    <IconButton onClick={() => removeMapping(i, entity)}>
                                      <CloseIcon className={classes.closeIcon}/>
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              )}
                            </Draggable>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Grid>
                  )}
                  {droppableProvided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      );
    });
  };

  return (
    <Modal
      className={classes.modal}
      open={exportFieldMappingsOpen}
      onClose={() => onModalClose()}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={exportFieldMappingsOpen}>
        <div className={classes.halfPaper}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {t('EXPORT_FIELD_MAPPINGS_MODAL_TITLE')}
            </Typography>
            <IconButton onClick={() => onModalClose()} disabled={loading}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Grid className={classes.modalContainer}>
            <Grid className={classes.formatChangeContainer}>
              <Typography className={classes.dataFormatText}>
                {t('EXPORT_FIELD_MAPPING_DATA_FORMAT')}
              </Typography>
              <Select
                id="format-change"
                variant="outlined"
                onChange={e => handleFormatChange(e)}
                className={classes.dataFormatSelect}
                value={dataFormat}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {['excel', 'dat', 'csv'].map(f => (
                  <MenuItem className={classes.inputText} key={f} value={f}>
                    {dataFormatLabels[f]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {mappingContent()}
          </Grid>
          {!user.isSubCompany && (
            <Grid className={classes.applyToAllContainer}>
              <FormControlLabel
                key="apply-to-all"
                value="top"
                control={
                  <Checkbox
                    className={classes.checkBox}
                    onChange={e => onChangeApplyToAll(e)}
                    checked={isApplyToAll}
                    disabled={loading}
                  />
                }
                label={ready && t('EXPORT_MAPPINGS_APPLY_TO_ALL')}
                labelPlacement="end"
                classes={{
                  root: classes.checkboxContainer,
                  label: classes.checkBoxLabel
                }}
              />
            </Grid>
          )}
          <Grid className={classes.modalFooter}>
            <Button
              variant="outlined"
              className={classes.cancelBtn}
              onClick={() => onModalClose()}
            >
              {ready && t('EXPORT_FIELD_MAPPINGS_CANCEL')}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              className={classes.saveBtn}
              onClick={handleSaveMappings}
            >
              {ready && t('EXPORT_FIELD_MAPPINGS_SAVE')}
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

ManageExportFieldMappings.propTypes = {
  format: PropTypes.object,
  exportFieldMappingsOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fields: PropTypes.array,
  lineFields: PropTypes.array,
  supplierFields: PropTypes.array,
  excelFieldMapping: PropTypes.array,
  datFieldMapping: PropTypes.array,
  csvFieldMapping: PropTypes.array
};

export default ManageExportFieldMappings;
