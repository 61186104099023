import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  menuItemBtn: {
    color: '#334D6E',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
