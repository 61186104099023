import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    fontSize: '11px!important',
    fontWeight: 500,
    color: 'rgba(51, 77, 110, 0.5)',
    padding: '16px 20px 13px',
    lineHeight: '16.5px'
  },
  cardContent: {
    padding: '10px!important'
  },
  formContainer: {
    padding: '16px 20px'
  },
  formInput: {
    paddingTop: '13px!important',
    paddingBottom: '13px!important',
  },
  updateBtnContainer: {
    paddingBottom: 28,
    paddingTop: 9
  },
  updateBtn: {
    width: 99,
    height: 41,
    textTransform: 'none'
  },
}));

export default useStyles;
