import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, FormHelperText, Grid, TextField
} from '@material-ui/core';

import Axios from 'axios';
import { ERRORS } from 'src/config';
import { axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function UserInviteForm({
  className,
  user,
  handleClose,
  history,
  onGetAllUsers,
  ...rest
}) {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const auth = useSelector((state) => state.auth);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')).max(255).required(t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')),
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          if (auth.user.maxUsers <= auth.user.usersCount) {
            setStatus({ success: false });
            setErrors({
              submit: ERRORS['Users count exceeded for your company. Please upgrade your subscription']
                || t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT')
            });
            setSubmitting(false);
          }
          const body = {
            email: values.email,
            company: auth && auth.user && auth.user.companyID ? auth.user.companyID : ''
          };
          const response = await Axios.post(
            `${API.inviteUser}`,
            body,
            axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
          );
          if (response.data.success) {
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_SUCCESS', { email: values.email }), {
              variant: 'success',
              persist: true,
              style: { maxWidth: 400 }
            });
            handleClose();
            onGetAllUsers();
            history.push('/account', { tab: 2 });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({
            submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Grid className={classes.userForm}>
            <Grid
              className={classes.fieldContainer}
            >
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')}
                name="email"
                onBlur={handleBlur}
                onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                className={classes.closeBtn}
                disabled={isSubmitting}
                onClick={handleClose}
              >
                {ready && t('ACCOUNT_SETTINGS_USERS_CANCEL')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitBtn}
                type="submit"
                disabled={isSubmitting}
              >
                {ready && t('ACCOUNT_SETTINGS_USERS_INVITE_USER')}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

UserInviteForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  handleClose: PropTypes.func.isRequired,
  onGetAllUsers: PropTypes.func.isRequired,
};

export default withRouter(UserInviteForm);
