/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as actionTypes from 'src/Dimensions/store/actions/actionTypes';

const initialState = {
  vatCodes: [],
  vatCodeList: [],
  total: 0,
  loading: false,
  error: null
};

const fetchVatCodesStart = (state) => (
  produce(state, (draft) => {
    draft.loading = true;
    draft.error = null;
  })
);
const fetchVatCodesSuccess = (state, action) => (
  produce(state, (draft) => {
    draft.vatCodes = action.vatcodes;
    draft.total = action.total;
    draft.loading = false;
    draft.error = null;
  })
);
const fetchVatCodesFail = (state, action) => (
  produce(state, (draft) => {
    draft.loading = false;
    draft.error = action.error;
  })
);

const fetchVatCodeListStart = (state) => (
  produce(state, (draft) => {
    draft.loading = true;
    draft.error = null;
  })
);
const fetchVatCodeListSuccess = (state, action) => (
  produce(state, (draft) => {
    draft.vatCodeList = action.vatcodes;
    draft.total = action.total;
    draft.loading = false;
    draft.error = null;
  })
);
const fetchVatCodeListFail = (state, action) => (
  produce(state, (draft) => {
    draft.loading = false;
    draft.error = action.error;
  })
);

const vatcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VATCODES_START: return fetchVatCodesStart(state);
    case actionTypes.FETCH_VATCODES_SUCCESS: return fetchVatCodesSuccess(state, action);
    case actionTypes.FETCH_VATCODES_FAIL: return fetchVatCodesFail(state, action);

    case actionTypes.FETCH_VATCODE_LIST_START: return fetchVatCodeListStart(state);
    case actionTypes.FETCH_VATCODE_LIST_SUCCESS: return fetchVatCodeListSuccess(state, action);
    case actionTypes.FETCH_VATCODE_LIST_FAIL: return fetchVatCodeListFail(state, action);

    default: {
      return state;
    }
  }
};

export default vatcodeReducer;
