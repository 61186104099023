/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useRef, useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button, Grid, InputAdornment, SvgIcon, TextField, Tooltip, Typography,
  IconButton, useMediaQuery
} from '@material-ui/core';
import LogsIcon from '@material-ui/icons/CardTravel';
import AddCircle from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import ImportIcon from '@material-ui/icons/SystemUpdateAlt';
import ExportIcon from '@material-ui/icons/OpenInBrowser';

import Page from 'src/Shared/components/Page/Page';
import GLAccountsTable from 'src/Dimensions/components/GLAccountsTable/GLAccountsTable';
import authService from 'src/Shared/utils/services/authService';
import {
  axiosHeaders,
  isActionPermitted, permissions, validateToken
} from 'src/Shared/utils/helpers';

import GLAccountModal from 'src/Dimensions/components/GLAccountModal/GLAccountModal';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import DimensionExportPopper from 'src/Dimensions/components/DimensionExportPopper/DimensionExportPopper';
import DimensionImportModal from 'src/Dimensions/components/DimensionImportModal/DimensionImportModal';
import { fetchGlaList } from 'src/Dimensions/store/actions/gla';
import { fetchVatCodes } from 'src/Dimensions/store/actions/vatcode';
import Axios from 'axios';
import FileSaver from 'file-saver';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function ListGLAccounts() {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { glaList, total, loading } = useSelector((state) => state.gla);

  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [query, setQuery] = useState('');

  const [glaYears, setGlaYears] = useState([]);
  const [glaPeriods, setGlaPeriods] = useState([]);
  const [gLAccountModalOpen, setGLAccountModalOpen] = useState(false);
  const [selectedGLA, setSelectedGLA] = useState(null);

  const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
  const exportBtnRef = useRef(null);

  const [importModalOpen, setImportModalOpen] = useState(false);

  const enabled = true;
  const isTwinfield = user.accountingSystem === 'twinfield';

  const maxWidth = useMediaQuery('(min-width: 960px)');
  const maxWidth2 = useMediaQuery('(min-width: 630px)');

  const onGLAModalClose = () => {
    setGLAccountModalOpen(false);
    setTimeout(() => setSelectedGLA(null), 150);
  };

  const gotoItem = (gla) => {
    if (!isTwinfield) {
      setSelectedGLA(gla);
      setGLAccountModalOpen(true);
    }
  };

  const getAllGLAccounts = async (page = 0, limit = 10, search = query, loadFullScreen) => {
    dispatch(fetchGlaList(user.companyID, page, limit, search, loadFullScreen));
  };

  const getVatCodes = () => {
    dispatch(fetchVatCodes(user.companyID));
  };

  const getGLAYears = async () => {
    try {
      const response = await Axios.get(
        `${API.glAYearsByCompany}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setGlaYears(response.data.data);
      }
    } catch (err) {
      //
    }
  };

  const getGLAPeriods = async () => {
    try {
      const response = await Axios.get(
        `${API.glAPeriodsByCompany}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setGlaPeriods(response.data.data);
      }
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user.permissions)) {
      getAllGLAccounts();
      getVatCodes();
      getGLAYears();
      getGLAPeriods();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => {
    if (!validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setSelectedGLA(null);
    setGLAccountModalOpen(true);
  };

  const handleQueryChange = (e) => {
    e.persist();
    setPageNo(0);
    setQuery(e.target.value);
    getAllGLAccounts(0, pageLimit, e.target.value, false);
  };

  const exportOptionsClose = (event) => {
    if (exportBtnRef.current && exportBtnRef.current.contains(event.target)) {
      return;
    }
    setExportOptionsOpen(false);
  };

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  const handleDimensionExport = async (format) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}glaccounts/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user.company} General Ledger Accounts.${format}`;
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      setExportOptionsOpen(false);
    } catch (error) {
      enqueueSnackbar(t('DIMENSION_EXPORT_FAIL'), {
        variant: 'error',
        persist: false
      });
    }
  };

  const searchBar = (
    <Grid className={clsx(classes.searchBar, 'search-bar')}>
      <Tooltip title={t('DASHBOARD_SEARCH_GLA_SEARCH_TOOLTIP')}>
        <TextField
          className={classes.queryField}
          onChange={handleQueryChange}
          placeholder={ready && t('DASHBOARD_SEARCH_GLA_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: '#C2CFE0' }}
                >
                  <SearchIcon style={{ color: '#C2CFE0' }} />
                </SvgIcon>
              </InputAdornment>
            ),
            classes: {
              input: classes.searchInput,
              root: classes.searchBarInput
            }
          }}
        />
      </Tooltip>
    </Grid>
  );

  const logsList = (
    <Tooltip title={t('GLAS_LIST_VIEW_LOGS_TOOLTIP')}>
      <IconButton
        className={classes.logsIconContainer}
        // TODO Need to handle the gla type properly here
        onClick={() => history.push(`/dimensions/logs/glaccounts/pnl/company/${user.companyID}`)}
      >
        <LogsIcon
          className={classes.logsIcon}
          style={{ fontSize: '1.8rem' }}
        />
      </IconButton>
    </Tooltip>
  );

  const titleText = (
    <Typography className={classes.title}>
      {ready && t('DASHBOARD_GLAS')}
    </Typography>
  );

  const addButton = (
    <Tooltip
      title={isActionPermitted(permissions.dimensionAdd, user.permissions)
        ? ready && t('GLAS_ADD_NEW_TOOLTIP')
        : ready && t('GLAS_ADD_NEW_TOOLTIP_PERMISSION')}
    >
      <span>
        <Button
          color="secondary"
          type="submit"
          variant="contained"
          className={classes.addBtn}
          onClick={handleAdd}
          disabled={!isActionPermitted(permissions.dimensionAdd, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <AddCircle />
          </SvgIcon>
          {ready && t('GLAS_ADD_NEW')}
        </Button>
      </span>
    </Tooltip>
  );

  const importBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : ''}
    >
      <span>
        <Button
          variant="outlined"
          className={classes.importExportBtn}
          onClick={() => setImportModalOpen(true)}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            className={classes.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ImportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_IMPORT_BTN')}
        </Button>
      </span>
    </Tooltip>
  );

  const exportBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : glaList.length === 0
          ? ready && t('GLA_NO_DATA_TO_EXPORT')
          : ''}
    >
      <span>
        <Button
          ref={exportBtnRef}
          variant="outlined"
          className={classes.importExportBtn}
          onClick={() => { setExportOptionsOpen(true); }}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions) || glaList.length === 0}
        >
          <SvgIcon
            fontSize="small"
            className={classes.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ExportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_EXPORT_BTN')}
        </Button>
        <DimensionExportPopper
          exportOptionsOpen={exportOptionsOpen}
          exportBtnRef={exportBtnRef}
          exportOptionsClose={exportOptionsClose}
          t={t}
          onOptionClick={handleDimensionExport}
        />
      </span>
    </Tooltip>
  );

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <Page
      className={classes.root}
      title={ready && t('GLA_LIST_PAGE_TITLE')}
    >
      <Grid className={clsx(classes.header, classes.headerMargin)}>
        {
          maxWidth ? (
            <Grid className={classes.header}>
              {titleText}
              {searchBar}
              {importBtn}
              {exportBtn}
              {
                !isTwinfield && (
                  <>
                    {enabled && logsList}
                    {addButton}
                  </>
                )
              }
            </Grid>
          ) : maxWidth2 ? (
            <Grid className={classes.headerColumn}>
              {titleText}
              <Grid className={classes.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              <Grid className={classes.importExportRow}>
                {importBtn}
                {exportBtn}
                {
                  !isTwinfield && addButton
                }
              </Grid>
            </Grid>
          ) : (
            <Grid className={classes.headerColumn}>
              {titleText}
              <Grid className={classes.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              <Grid className={classes.importExportRow}>
                {importBtn}
                {exportBtn}
              </Grid>
              {
                !isTwinfield && addButton
              }
            </Grid>
          )
        }
      </Grid>
      <GLAccountsTable
        glAccounts={glaList}
        getAllGLAccounts={getAllGLAccounts}
        total={total}
        query={query}
        isTwinfield={isTwinfield}
        limit={pageLimit}
        setLimit={setPageLimit}
        page={pageNo}
        setPage={setPageNo}
        gotoItem={gotoItem}
      />
      <GLAccountModal
        gLAccountModalOpen={gLAccountModalOpen}
        selectedGLA={selectedGLA}
        handleClose={onGLAModalClose}
        getAllGLAccounts={getAllGLAccounts}
        getVatCodes={getVatCodes}
        glaYears={glaYears}
        glaPeriods={glaPeriods}
      />
      <DimensionImportModal
        dimensionImportModalOpen={importModalOpen}
        handleClose={handleImportModalClose}
        dimension="gla"
        fetchDimenstions={getAllGLAccounts}
      />
    </Page>
  );
}

export default ListGLAccounts;
