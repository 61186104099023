import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  pdfjs
} from 'react-pdf';

import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import authService from 'src/Shared/utils/services/authService';
import { isMobile } from 'react-device-detect';
import PdfView from 'src/Invoices/components/PdfView/PdfView';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function InvoiceDisplay() {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [fileResponse, setFileResponse] = useState(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const [imgName, setImgName] = React.useState('');

  const match = useRouteMatch();

  const getImage = async (url) => {
    setFetchingFile(true);
    try {
      const response = await Axios.get(
        `${url}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
      localStorage.removeItem('PROCYS_PERSIST_URL');
      setFetchingFile(false);
    } catch (err) {
      if (document?.referrer?.indexOf('login/') >= 0) {
        localStorage.removeItem('PROCYS_PERSIST_URL');
      } else {
        localStorage.setItem('PROCYS_PERSIST_URL', window.location.href);
      }
      setFetchingFile(false);
      setTimeout(() => { window.location.href = '/dashboard'; }, 2000);
    }
  };

  React.useEffect(() => {
    if (match?.params?.id) {
      setImgName(`${match.params.id}`);
      getImage(`${API.getInvoiceImage}${match.params.id}`);
    } else if (match?.params?.company && match?.params?.transaction) {
      setImgName(`${match.params.transaction}`);
      getImage(`${API.getInvImgByCompAndTrans}${match.params.company}/transaction/${match.params.transaction}`);
    } else if (match?.params?.transaction) {
      setImgName(`${match.params.transaction}`);
      getImage(`${API.getInvoiceImageByTrans}${match.params.transaction}`);
    } else {
      localStorage.removeItem('PROCYS_PERSIST_URL');
      authService.logout(LOGIN_PANEL_URL);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!fileResponse) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      {isMobile
        ? (
          <PdfView
            t={t}
            ready={ready}
            fileResponse={fileResponse}
            fetchingFile={fetchingFile}
            imgName={imgName}
          />
        )
        : <iframe src={fileResponse} title="pdf" className={classes.pdfFrame} />}
    </div>
  );
}

export default InvoiceDisplay;
