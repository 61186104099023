import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import useStyles from './style';

const NewUserInviteLinkPopup = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isOpen, handleClose, link } = props;

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    copy(link, {
      onCopy: setCopied(true)
    });
  };

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Grid className={classes.paper}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {t('USER_INVITE_LINK_TITLE')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Typography variant="caption" className={classes.primaryTextColor}>
            {t('USER_INVITE_LINK_INSTRUCTION')}
          </Typography>
          <Grid className={classes.detailRow}>
            <input
              fullWidth
              type="text"
              value={link}
              className={classes.inputField}
              disabled
            />
            <IconButton onClick={copyToClipboard}>
              <CopyIcon />
            </IconButton>
            {copied && <CheckIcon className={classes.checkIcon} />}
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

NewUserInviteLinkPopup.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  link: PropTypes.string
};

export default NewUserInviteLinkPopup;
