import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { setUserData } from 'src/Auth/store/actions/auth';

import Page from 'src/Shared/components/Page/Page';
import Results from 'src/User/components/Results/Results';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import useStyles from './style';

const UserListView = (props) => {
  const {
    classes, updateUser, t, tReady
  } = props;

  return (
    <Page
      className={classes.root}
      title={tReady && t('ACCOUNT_SETTINGS_USERS_LIST_PAGE_TITLE')}
    >
      <Results updateUser={updateUser} />
    </Page>
  );
};

UserListView.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string
  }).isRequired,
  updateUser: PropTypes.func,
  t: PropTypes.func,
  tReady: PropTypes.bool
};

UserListView.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (payload) => dispatch(setUserData(payload))
});

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), (withStyles(useStyles)))(UserListView);
