import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Backdrop, Button, CircularProgress, Grid, Modal, Paper, Tooltip, Typography
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import { appendContactSupport, axiosHeaders } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const SelectDocument = ({
  open
}) => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [documentType, setDocumentType] = useState('invoice');
  const [loading, setLoading] = useState(false);

  const documentTypes = [
    {
      key: 'invoice',
      title: 'DOCUMENT_TYPE_INVOICE',
      tooltip: '',
      points: [
        'DOCUMENT_TYPE_INVOICE_POINT_1',
        'DOCUMENT_TYPE_INVOICE_POINT_2',
      ],
      isBeta: false,
      isAvailable: true
    },
    {
      key: 'purchase_order',
      title: 'DOCUMENT_TYPE_PURCHASE_ORDER',
      tooltip: '',
      points: [
        'DOCUMENT_TYPE_PURCHASE_ORDER_POINT_1',
        'DOCUMENT_TYPE_PURCHASE_ORDER_POINT_2',
      ],
      isBeta: false,
      isAvailable: true
    },
    {
      key: 'id',
      title: 'DOCUMENT_TYPE_ID',
      tooltip: '',
      points: [
        'DOCUMENT_TYPE_ID_POINT_1',
        'DOCUMENT_TYPE_ID_POINT_2',
      ],
      isBeta: true,
      isAvailable: true
    },
  ];

  const confirmDocumentType = async () => {
    try {
      setLoading(true);

      const response = await Axios.get(
        `${API.selectDocumentType}${documentType}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('DOCUMENT_TYPE_SELECT_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('DOCUMENT_TYPE_SELECT_FAILED'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );

      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paperModal}>
        <Grid>
          <Grid className={classes.modalBody}>
            <Typography
              variant="h2"
              className={classes.header}
            >
              {ready && t('SELECT_DOCUMENT_TYPE_TITLE')}
            </Typography>
            <Typography
              variant="caption"
              className={classes.caption}
            >
              {ready && t('SELECT_DOCUMENT_TYPE_CAPTION')}
            </Typography>
            <Grid container spacing={3} className={classes.listContainer}>
              {
                documentTypes.map((item) => {
                  if (!item.isAvailable) {
                    return null;
                  }

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.listItemWrapper}
                      key={item.key}
                      onClick={() => setDocumentType(item.key)}
                    >
                      <Tooltip title={t(item.tooltip)}>
                        <Grid className={documentType === item.key ? classes.listItemSelected : classes.listItem}>
                          <Typography className={documentType === item.key ? classes.listItemTitleSelected : classes.listItemTitle}>
                            {ready && t(item.title)}
                            {item.isBeta && <span className={classes.beta}>{ready && t('BETA_RELEASE')}</span>}
                          </Typography>
                          <Grid>
                            {
                              item.points.map((point) => (
                                <Grid className={documentType === item.key ? classes.itemPointSelected : classes.itemPoint}>
                                  <CheckCircle className={documentType === item.key ? classes.listItemIconSelected : classes.listItemIcon} />
                                  <Typography className={documentType === item.key ? classes.listItemPointSelected : classes.listItemPoint}>
                                    {ready && t(point)}
                                  </Typography>
                                </Grid>
                              ))
                            }
                          </Grid>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  );
                })
              }
            </Grid>
          </Grid>
          <Grid className={classes.buttonContainer}>
            {
              loading ? (
                <Grid className={classes.action}>
                  <CircularProgress size={20} />
                </Grid>
              ) : (
                <Button
                  className={classes.sendBtn}
                  variant="contained"
                  color="secondary"
                  onClick={confirmDocumentType}
                >
                  {ready && t('DOCUMENT_TYPE_SELECT_CONFIRM')}
                </Button>
              )
            }
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

SelectDocument.defaultProps = {
  open: false,
};

SelectDocument.propTypes = {
  open: PropTypes.bool
};

export default SelectDocument;
