import { put } from 'redux-saga/effects';
import axios from 'axios';

import authService from 'src/Shared/utils/services/authService';
import * as actions from 'src/Dashboard/store/actions/dashboard';

export function* getInvoiceStatsByUserSaga() {
  yield put(actions.getInvoiceStatsByUserStart());

  try {
    const response = yield axios.request({
      method: 'GET',
      url: `${window.config.backend.procys_service}v1/users/stats`,
      headers: {
        Authorization: `bearer ${authService.getAccessToken()}`
      }
    });
    const userInvoices = response.data.data;
    const overviewStats = {
      userStats: [],
      labels: [],
      dataDigitized: [],
      dataUploaded: [],
      dataUploading: [],
      dataToreview: [],
      dataTovalidate: [],
      dataToexport: [],
      dataExported: [],
      dataRejected: [],
      dataArchived: [],
      dataErrored: [],

      digitizedTot: 0,
      uploadedTot: 0,
      uploadingTot: 0,
      toreviewTot: 0,
      tovalidateTot: 0,
      toexportTot: 0,
      exportedTot: 0,
      rejectedTot: 0,
      archivedTot: 0,
      erroredTot: 0
    };

    if (userInvoices && userInvoices.length > 0) {
      const dataDigitized = [];
      const dataUploaded = [];
      const dataUploading = [];
      const dataToreview = [];
      const dataTovalidate = [];
      const dataToexport = [];
      const dataExported = [];
      const dataRejected = [];
      const dataArchived = [];
      const dataErrored = [];
      const labels = [];
      const userStats = [];
      let digitizedTot = 0;
      let uploadedTot = 0;
      let uploadingTot = 0;
      let toreviewTot = 0;
      let tovalidateTot = 0;
      let toexportTot = 0;
      let exportedTot = 0;
      let rejectedTot = 0;
      let archivedTot = 0;
      let erroredTot = 0;

      userInvoices.sort((a, b) => new Date(b.date) - new Date(a.date)).reverse();
      for (let i = 0; i < userInvoices.length; i++) {
        labels.push(userInvoices[i].date);
        let dayDigitized = 0;
        let dayUploaded = 0;
        let dayUploading = 0;
        let dayToreview = 0;
        let dayTovalidate = 0;
        let dayToexport = 0;
        let dayExported = 0;
        let dayRejected = 0;
        let dayArchived = 0;
        let dayErrored = 0;

        for (let j = 0; j < userInvoices[i].stats.length; j++) {
          if (userInvoices[i].stats[j] && userInvoices[i].stats[j].user) {
            userStats.push({
              date: userInvoices[i].date,
              user: userInvoices[i].stats[j].user,
              stats: userInvoices[i].stats[j].userStats
            });

            dayDigitized += userInvoices[i].stats[j].userStats.processed || 0;
            dayUploaded += userInvoices[i].stats[j].userStats.uploaded || 0;
            dayUploading += userInvoices[i].stats[j].userStats.inProgress || 0;
            dayToreview += userInvoices[i].stats[j].userStats.toreview || 0;
            dayTovalidate += userInvoices[i].stats[j].userStats.tovalidate || 0;
            dayToexport += userInvoices[i].stats[j].userStats.toexport || 0;
            dayExported += userInvoices[i].stats[j].userStats.exported || 0;
            dayRejected += userInvoices[i].stats[j].userStats.rejected || 0;
            dayArchived += userInvoices[i].stats[j].userStats.archived || 0;
            dayErrored += userInvoices[i].stats[j].userStats.error || 0;
          }
        }

        dataDigitized.push(dayDigitized);
        dataUploaded.push(dayUploaded);
        dataUploading.push(dayUploading);
        dataToreview.push(dayToreview);
        dataTovalidate.push(dayTovalidate);
        dataToexport.push(dayToexport);
        dataExported.push(dayExported);
        dataRejected.push(dayRejected);
        dataArchived.push(dayArchived);
        dataErrored.push(dayErrored);

        digitizedTot += dayDigitized;
        uploadedTot += dayUploaded;
        uploadingTot += dayUploading;
        toreviewTot += dayToreview;
        tovalidateTot += dayTovalidate;
        toexportTot += dayToexport;
        exportedTot += dayExported;
        rejectedTot += dayRejected;
        archivedTot += dayArchived;
        erroredTot += dayErrored;
      }

      overviewStats.userStats = userStats;
      overviewStats.labels = labels;
      overviewStats.dataDigitized = dataDigitized;
      overviewStats.dataUploaded = dataUploaded;
      overviewStats.dataUploading = dataUploading;
      overviewStats.dataToreview = dataToreview;
      overviewStats.dataTovalidate = dataTovalidate;
      overviewStats.dataToexport = dataToexport;
      overviewStats.dataExported = dataExported;
      overviewStats.dataRejected = dataRejected;
      overviewStats.dataArchived = dataArchived;
      overviewStats.dataErrored = dataErrored;

      overviewStats.digitizedTot = digitizedTot;
      overviewStats.uploadedTot = uploadedTot;
      overviewStats.uploadingTot = uploadingTot;
      overviewStats.toreviewTot = toreviewTot;
      overviewStats.tovalidateTot = tovalidateTot;
      overviewStats.toexportTot = toexportTot;
      overviewStats.exportedTot = exportedTot;
      overviewStats.rejectedTot = rejectedTot;
      overviewStats.archivedTot = archivedTot;
      overviewStats.erroredTot = erroredTot;
    }

    yield put(actions.getInvoiceStatsByUserSuccess(response.data.data, overviewStats));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = error.message;
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.getInvoiceStatsByUserFail(errorMessage));
  }
}

export function* getAppInvoiceStatsSaga() {
  yield put(actions.getInvoiceAppStatsByUserStart());

  try {
    const response = yield axios.request({
      method: 'GET',
      url: `${window.config.backend.procys_service}v1/users/stats/db`,
      headers: {
        Authorization: `bearer ${authService.getAccessToken()}`
      }
    });
    const userInvoices = response.data.data;
    const overviewStats = {
      userStats: [],
      labels: [],
      dataUploading: [],
      dataDigitized: [],
      dataToreview: [],
      dataTovalidate: [],
      dataToexport: [],
      dataExported: [],
      dataRejected: [],
      dataArchived: [],
      dataErrored: [],
      dataExportedAmount: [],

      uploadingTot: 0,
      digitizedTot: 0,
      toreviewTot: 0,
      tovalidateTot: 0,
      toexportTot: 0,
      exportedTot: 0,
      rejectedTot: 0,
      archivedTot: 0,
      erroredTot: 0
    };

    if (userInvoices && userInvoices.length > 0) {
      const dataUploading = [];
      const dataDigitized = [];
      const dataToreview = [];
      const dataTovalidate = [];
      const dataToexport = [];
      const dataExported = [];
      const dataRejected = [];
      const dataArchived = [];
      const dataErrored = [];
      const dataExportedAmount = [];
      const labels = [];
      const userStats = [];
      let uploadingTot = 0;
      let digitizedTot = 0;
      let toreviewTot = 0;
      let tovalidateTot = 0;
      let toexportTot = 0;
      let exportedTot = 0;
      let rejectedTot = 0;
      let archivedTot = 0;
      let erroredTot = 0;

      userInvoices.sort((a, b) => new Date(b.date) - new Date(a.date)).reverse();
      for (let i = 0; i < userInvoices.length; i++) {
        labels.push(userInvoices[i].date);
        let dayUploading = 0;
        let dayDigitized = 0;
        let dayToreview = 0;
        let dayTovalidate = 0;
        let dayToexport = 0;
        let dayExported = 0;
        let dayRejected = 0;
        let dayArchived = 0;
        let dayErrored = 0;
        let dayExportedAmount = 0;

        for (let j = 0; j < userInvoices[i].stats.length; j++) {
          if (userInvoices[i].stats[j] && userInvoices[i].stats[j].user) {
            userStats.push({
              date: userInvoices[i].date,
              user: userInvoices[i].stats[j].user,
              stats: userInvoices[i].stats[j].userStats
            });

            dayUploading += userInvoices[i].stats[j].userStats.uploading || 0;
            dayDigitized += userInvoices[i].stats[j].userStats.digitized || 0;
            dayToreview += userInvoices[i].stats[j].userStats.toreview || 0;
            dayTovalidate += userInvoices[i].stats[j].userStats.tovalidate || 0;
            dayToexport += userInvoices[i].stats[j].userStats.toexport || 0;
            dayExported += userInvoices[i].stats[j].userStats.exported || 0;
            dayRejected += userInvoices[i].stats[j].userStats.rejected || 0;
            dayArchived += userInvoices[i].stats[j].userStats.archived || 0;
            dayErrored += userInvoices[i].stats[j].userStats.error || 0;
            dayExportedAmount += userInvoices[i].stats[j].userStats.amount || 0;
          }
        }

        dataUploading.push(dayUploading);
        dataDigitized.push(dayDigitized);
        dataToreview.push(dayToreview);
        dataTovalidate.push(dayTovalidate);
        dataToexport.push(dayToexport);
        dataExported.push(dayExported);
        dataRejected.push(dayRejected);
        dataArchived.push(dayArchived);
        dataErrored.push(dayErrored);
        dataExportedAmount.push(dayExportedAmount);

        uploadingTot += dayUploading;
        digitizedTot += dayDigitized;
        toreviewTot += dayToreview;
        tovalidateTot += dayTovalidate;
        toexportTot += dayToexport;
        exportedTot += dayExported;
        rejectedTot += dayRejected;
        archivedTot += dayArchived;
        erroredTot += dayErrored;
      }

      overviewStats.userStats = userStats;
      overviewStats.labels = labels;
      overviewStats.dataUploading = dataUploading;
      overviewStats.dataDigitized = dataDigitized;
      overviewStats.dataToreview = dataToreview;
      overviewStats.dataTovalidate = dataTovalidate;
      overviewStats.dataToexport = dataToexport;
      overviewStats.dataExported = dataExported;
      overviewStats.dataRejected = dataRejected;
      overviewStats.dataArchived = dataArchived;
      overviewStats.dataErrored = dataErrored;
      overviewStats.dataExportedAmount = dataExportedAmount;

      overviewStats.uploadingTot = uploadingTot;
      overviewStats.digitizedTot = digitizedTot;
      overviewStats.toreviewTot = toreviewTot;
      overviewStats.tovalidateTot = tovalidateTot;
      overviewStats.toexportTot = toexportTot;
      overviewStats.exportedTot = exportedTot;
      overviewStats.rejectedTot = rejectedTot;
      overviewStats.archivedTot = archivedTot;
      overviewStats.erroredTot = erroredTot;
    }

    yield put(actions.getInvoiceAppStatsByUserSuccess(response.data.data, overviewStats));
  } catch (error) {
    let errorMessage = null;
    if (error.response === undefined) {
      errorMessage = error.message;
    } else {
      errorMessage = error.response.data.message;
    }
    yield put(actions.getInvoiceAppStatsByUserFail(errorMessage));
  }
}

export function* dummyFunc() {
  // To be removed..
}
