import React from 'react';
import { Container } from '@material-ui/core';

import Page from 'src/Shared/components/Page/Page';

import { useTranslation } from 'react-i18next';
import useStyles from './style';

function CustomerInvoice() {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  return (
    <Page
      className={classes.root}
      title={ready && t('CUSTOMER_INVOICE_PAGE_TITLE')}
    >
      <Container maxWidth={false}>
        <iframe
          title="Chargify Invoice"
          src="frame-src 'self' https://www.chargifypay.com/invoice/inv_9qnbxzbq9xkn8?token=49kmsbwd4vsykx4dk35nw7d3"
          width="100%"
          height="600"
        />
      </Container>
    </Page>
  );
}

export default CustomerInvoice;
