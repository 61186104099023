import React, {
  createRef, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Card, Grid, Tabs, Tab, Typography, TextField, Button, MenuItem, FormControl, InputLabel, Select, CircularProgress
} from '@material-ui/core';
import CheckCircleICon from '@material-ui/icons/CheckCircleOutline';

import Page from 'src/Shared/components/Page/Page';
import SubCompanyAccessModal from 'src/Shared/components/SubCompanyAccessModal/SubCompanyAccessModal';
import {
  appendContactSupport, axiosHeaders, getLocalisedErrorString, getMaskedCurrencyText, isActionPermitted, permissions, validateEmail
} from 'src/Shared/utils/helpers';
import images from 'src/Shared/assets/images';
import { subscriptionTypes } from 'src/config';
import LoadingScreenCustomText from 'src/Shared/components/LoadingScreenCustomText/LoadingScreenCustomText';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function Checkout(props) {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const { t, ready } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [cards, setCards] = useState([]);
  const [defaultCard, setDefaultCard] = useState();
  const [addingCard, setAddingCard] = useState(false);
  const [activatingCard, setActivatingCard] = useState(false);
  const [fetchingCard, setFetchingCard] = useState(true);

  const [subTypes, setSubTypes] = useState(subscriptionTypes);
  const [selectedSubscription, setSelectedSubscription] = useState(subscriptionTypes.starter);
  const [type, setType] = useState(0);

  const [email, setEmail] = useState(user.email);
  const [emailErr, setEmailErr] = useState('');

  const [subscription, setSubscription] = useState({
    name: 'Business',
    invLimit: 200,
    invUsed: 260,
    usersLimit: 10,
    usersUsed: 4,
    totalInvoices: 12000,
    totalPayment: '135050',
    nextBillingAmount: 2999,
    currPrice: 0,
    ecCharged: 0,
    startDate: '2021-10-12T14:48:12-05:00',
    endDate: '2022-04-12T14:48:12-05:00',
    ecPrice: 0,
  });

  const intervalID = createRef();

  const getSubscription = async (isCustomFound) => {
    setLoading(true);
    try {
      const response = await Axios.get(API.subscriptionDetails, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data && response.data.data.length > 0) {
        const subs = response.data.data[0];
        let balanceFromChargify = subs.balanceInCents;
        if (!balanceFromChargify) {
          balanceFromChargify = 0;
        }

        let currentSubsPrice = subs.currPrice;
        if (!currentSubsPrice) {
          currentSubsPrice = 0;
        }

        const subsObj = {
          name: subs.product.name,
          selfServiceUrl: subs.selfServiceUrl,
          invLimit: subs.invoicesLimit || 20,
          invPurchased: subs.extraPurchased || 0,
          invUsed: subs.invoicesUsed || 0,
          usersLimit: subs.usersLimit || 1,
          usersUsed: subs.usersUsed || 1,
          totalInvoices: subs.totalInvoices || 0,
          totalPayment: subs.totalRevenueinCents || 0,
          nextBillingAmount: balanceFromChargify + currentSubsPrice || 0,
          startDate: subs.activatedAt,
          endDate: subs.currentPeriodEndsAt,
          ecLimit: subs.ecLimit || 0,
          ecPrice: subs.ecPrice || 0,
          currPrice: subs.currPrice || 0,
          ecCharged: subs.ecCharged || 0
        };
        let currSubs = subscriptionTypes.free;
        subsObj.level = subscriptionTypes.free.level;

        if (subs.product.name.toLowerCase().indexOf('starter') >= 0) {
          currSubs = subscriptionTypes.starter;
          subsObj.level = subscriptionTypes.starter.level;
        }

        if (subs.product.name.toLowerCase().indexOf('business') >= 0) {
          currSubs = subscriptionTypes.business;
          subsObj.level = subscriptionTypes.business.level;
        }

        if (subs.product.name.toLowerCase().indexOf('custom') >= 0
          || subs.product.name.toLowerCase().indexOf('enterprise') >= 0
          || subs.product.name.toLowerCase().indexOf('increment') >= 0) {
          currSubs = subscriptionTypes.enterprise;
          subsObj.level = subscriptionTypes.enterprise.level;

          if (!subs.invoicesLimit || subs.invoicesLimit <= 0) {
            subsObj.invLimit = 0;
          }
        }

        if (!subs.ecLimit) {
          subsObj.ecLimit = currSubs.ecLimit;
        }
        if (!subs.ecPrice) {
          subsObj.ecPrice = currSubs.ecPrice;
        }

        if (response.data.data.length >= 2) {
          const customSub = response.data.data[1];
          const salesCustom = {
            level: 99,
            label: 'Enterprise',
            name: 'Enterprise Plan',
            invoices: customSub.invoicesLimit,
            users: customSub.usersLimit,
            price: customSub.currPrice,
            ecLimit: customSub.ecLimit,
            ecPrice: customSub.ecPrice,
          };
          setSubTypes({
            ...subTypes,
            salesCustom
          });

          if (isCustomFound) {
            setSelectedSubscription(salesCustom);
          }
        }

        setSubscription(subsObj);
      } else {
        history.push('/account/subscription');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      history.push('/account/subscription');
    }
  };

  const getAllCards = async (id) => {
    setFetchingCard(true);
    try {
      const response = await Axios.get(
        API.userCards,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        let defCard = null;
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].id === id) {
            defCard = response.data.data[i];
          }
        }

        if (defCard) {
          setCards([defCard]);
        }
      }
      setFetchingCard(false);
      setActivatingCard(false);
      setAddingCard(false);
    } catch (error) {
      setFetchingCard(false);
      setActivatingCard(false);
      setAddingCard(false);
    }
  };

  const getAllCardsInterval = async () => {
    try {
      const response = await Axios.get(
        API.userCards,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        return response.data.data.length;
      }
      return 0;
    } catch (error) {
      return 0;
    }
  };

  const paymentProfileMethod = async () => {
    try {
      const response = await Axios.get(
        API.subscriptionDetails,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getAllCards(response.data.data[0].creditCard.id);
        setDefaultCard(response.data.data[0].creditCard.id);
      } else {
        getAllCards(null);
      }
    } catch (error) {
      setActivatingCard(false);
      setAddingCard(false);
    }
  };

  useEffect(() => {
    if (!isActionPermitted(permissions.subscriptionsUpgrade, user.permissions)
    || !isActionPermitted(permissions.subscriptionsDowngrade, user.permissions)
    || !isActionPermitted(permissions.subscriptionsBuyExtra, user.permissions)) {
      enqueueSnackbar(
        t('SUBSCRIPTION_NO_PERMISSION'),
        {
          variant: 'error',
          persist: true
        }
      );

      history.push('/account/subscription');
    }

    const search = history.location.search.substring(1);
    const paramsAr = search.split('&');

    let isCustomFound = false;
    for (let i = 0; i < paramsAr.length; i++) {
      const paramParts = paramsAr[i].split('=');
      if (paramParts.length >= 2 && paramParts[0] === 'type') {
        const isTypeExtra = paramParts[1] === '1';
        setType(isTypeExtra ? 1 : 0);

        if (!isTypeExtra) {
          setSelectedSubscription(
            props.location.state && props.location.state.selectedSubscription ? props.location.state.selectedSubscription : subscriptionTypes.starter
          );
        }
      }

      if (paramParts.length >= 2 && paramParts[0] === 'subscription' && paramParts[1] === 'custom') {
        isCustomFound = true;
      }
    }

    getSubscription(isCustomFound);
    paymentProfileMethod();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBuyExtraInvoices = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(API.buyExtraInvoicesPackage, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success) {
        enqueueSnackbar(
          t('SUBSCRIPTION_BUY_EXTRA_PACKAGE_SUCCESS', { ecLimit: subscription.ecLimit || 25 }),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setTimeout(() => {
          window.location = '/account/subscription';
          // setLoading(false);
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_BUY_EXTRA_PACKAGE_FAILED', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const handleUpgradeSubscription = async (to) => {
    try {
      const body = {
        action: 'upgrade',
        toSubscription: to
      };

      const response = await Axios.put(
        API.updateSubscription,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('SUBSCRIPTION_UPGRADE_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setTimeout(() => {
          if (subscription.totalPayment === 0) {
            window.location = '/account/subscription?feedback-first-payment=success';
          } else {
            window.location = '/account/subscription';
          }
          // setLoading(false);
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'SUBSCRIPTION_UPGRADE_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const handleDowngradeSubscription = async (to) => {
    try {
      const body = {
        action: 'downgrade',
        toSubscription: to,
        immediately: false
      };

      const response = await Axios.put(
        API.updateSubscription,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('SUBSCRIPTION_DOWNGRADE_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setTimeout(() => {
          window.location = '/account/subscription';
          // setLoading(false);
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_DOWNGRADE_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onSubscribe = (to) => {
    setLoading(true);
    if (selectedSubscription.level > subscription.level) {
      handleUpgradeSubscription(to);
      return;
    }

    handleDowngradeSubscription(to);
  };

  const activateCard = async (cardID) => {
    setActivatingCard(true);
    try {
      const response = await Axios.post(
        API.changeDefaultPaymentMethod,
        { paymentProfileID: cardID.toString() },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        paymentProfileMethod();
        enqueueSnackbar(t('PROCYS_CHANGE_DEFAULT_PAYMENT_METHOD_SUCCESS'), {
          variant: 'success',
          persist: true
        });
      } else {
        setActivatingCard(false);
      }
    } catch (error) {
      enqueueSnackbar(appendContactSupport(window.config.support_email, t('PROCYS_CHANGE_DEFAULT_PAYMENT_METHOD_FAILURE'), t), {
        variant: 'error',
        persist: true
      });
      setActivatingCard(false);
    }
  };

  const onChangeSubscription = (v) => {
    const subsTypes = Object.keys(subTypes);
    for (let i = 0; i < subsTypes.length; i++) {
      if (subTypes[subsTypes[i]].name === v) {
        setSelectedSubscription(subTypes[subsTypes[i]]);
      }
    }
  };

  const handleChangeField = (value, prop, setter, errSetter) => {
    if (!value) {
      errSetter(t('INTEGRATIONS_PROP_CANNOT_BE_EMPTY', { prop }));
    } else {
      errSetter('');
    }

    setter(value);
  };

  const handleGotoPayment = () => {
    const emailResponse = validateEmail(email);

    if (!emailResponse.isValid) {
      setEmailErr(emailResponse.errorMessage);
      return;
    }

    setTabIndex(2);
  };

  const handleGotoSummary = () => {
    setTabIndex(3);
  };

  const onStartInterval = () => {
    intervalID.current = setInterval(async () => {
      const cardCount = await getAllCardsInterval();
      if (cardCount > cards.length) {
        paymentProfileMethod();
        clearInterval(intervalID.current);
      }
    }, 5000);
  };

  const onNavigateToAddCard = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const width = screenWidth > 720 ? 720 : screenWidth - 20;
    const height = screenHeight > 700 ? 700 : screenHeight - 20;

    const excessH = screenHeight - height - 100;
    const top = excessH > 0 ? excessH / 2 : 10;

    const excessW = screenWidth - width;
    const left = excessW > 0 ? excessW / 2 : 10;

    window.open(subscription.selfServiceUrl, 'ProcysBillingInformation', `height=${height},width=${width},top=${top},left=${left}`);
    onStartInterval();
  };

  const renderCheckoutBtn = () => (
    loading ? (
      <Grid className={classes.action}>
        <CircularProgress size={20} />
      </Grid>
    ) : (
      <Button
        variant="contained"
        color="secondary"
        className={classes.btn}
        onClick={type === 0 ? () => onSubscribe(selectedSubscription.label.toLowerCase()) : handleBuyExtraInvoices}
      >
        {ready && t(type === 0 ? t('SUBSCRIPTION_SUBSCRIBE') : t('SUBSCRIPTION_BUY'))}
      </Button>
    )
  );

  const renderFooter = (prevIndex, nextAction) => (
    <Grid className={classes.footerContainer}>
      {
        prevIndex >= 0 ? (
          <Button disabled={loading || addingCard || activatingCard} className={classes.prevBtn} onClick={() => setTabIndex(prevIndex)}>
            {ready && t('SUBSCRIPTION_PREVIOUS')}
          </Button>
        ) : (
          <Button className={classes.prevBtn} onClick={() => history.push('/account/subscription')}>
            {ready && t('SUBSCRIPTION_BACK_TO_SUBSCRIPTIONS')}
          </Button>
        )
      }
      {
        nextAction
          ? (
            <Button
              disabled={loading || addingCard || activatingCard}
              variant="contained"
              color="secondary"
              className={classes.btn}
              onClick={nextAction}
            >
              {ready && t('SUBSCRIPTION_NEXT')}
            </Button>
          ) : (
            renderCheckoutBtn()
          )
      }
    </Grid>
  );

  const subscriptionDetails = () => (
    <Grid>
      <Typography className={classes.tabTitle}>
        {ready && t('SUBSCRIPTION_CONFIGURE_YOUR_PLAN')}
      </Typography>
      <Grid className={classes.wrapper}>
        <Grid container className={classes.summaryContainer}>
          <FormControl className={classes.subscriptionsSelect}>
            <InputLabel className={classes.selectLabel}>{t('SUBSCRIPTION_SELECTED_SUBSCRIPTION')}</InputLabel>
            <Select
              value={selectedSubscription.name}
              label={ready && t('SUBSCRIPTION_SELECTED_SUBSCRIPTION')}
              onChange={(e) => onChangeSubscription(e.target.value)}
              variant="outlined"
              className={classes.cardFooterText}
            >
              {Object.keys(subTypes).map((s) => subTypes[s].level !== subTypes.enterprise.level
                && subTypes[s].level !== subTypes.free.level && (
                <MenuItem
                  key={subTypes[s].label}
                  className={classes.cardFooterText}
                  value={subTypes[s].name}
                >
                  {t(subTypes[s].i18n)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid item sm={8} xs={12} className={classes.summaryLabel}>
            {ready && t('SUBSCRIPTION_FEE', { subscription: t(selectedSubscription.i18n) })}
          </Grid>
          <Grid item sm={4} xs={12} className={classes.summaryAmount}>
            &euro;
            {` ${getMaskedCurrencyText(selectedSubscription.price ? selectedSubscription.price.toString() : '0')}`}
          </Grid>
          <Grid item sm={8} xs={12} className={classes.summaryLabel}>
            {ready && t('SUBSCRIPTION_TAX')}
            {
              user.taxApplicable && (
                <span>{` (${user.vatNumber || t('SUBSCRIPTION_NO_VAT')})`}</span>
              )
            }
          </Grid>
          {
            user.taxApplicable
              ? (
                <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                  &euro;
                  {` ${getMaskedCurrencyText((Math.round(((selectedSubscription.price ? selectedSubscription.price : 0) * 21) / 100)).toString())}`}
                </Grid>
              ) : (
                <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                  -
                </Grid>
              )
          }
        </Grid>
      </Grid>
      {renderFooter(-1, () => setTabIndex(1))}
    </Grid>
  );

  const extraPackDetails = () => (
    <Grid>
      <Typography className={classes.tabTitle}>
        {ready && t('SUBSCRIPTION_PACKAGE_DETAILS')}
      </Typography>
      <Grid className={classes.wrapper}>
        <Grid container className={classes.summaryContainer}>
          <Grid item sm={8} xs={12} className={classes.summaryLabel}>
            {ready && t('SUBSCRIPTION_SUBSCRIPTION')}
          </Grid>
          <Grid item sm={4} xs={12} className={classes.summaryAmount}>
            {` ${t(subscription.i18n)}`}
          </Grid>
          <Grid item sm={8} xs={12} className={classes.summaryLabel}>
            {ready && t('SUBSCRIPTION_NO_OF_INVOICES')}
          </Grid>
          <Grid item sm={4} xs={12} className={classes.summaryAmount}>
            {` ${subscription.ecLimit} invoices`}
          </Grid>
          <Grid item sm={8} xs={12} className={classes.summaryLabel}>
            {ready && t('SUBSCRIPTION_PACKAGE_FEE')}
          </Grid>
          <Grid item sm={4} xs={12} className={classes.summaryAmount}>
            &euro;
            {` ${getMaskedCurrencyText(subscription.ecPrice.toString())}`}
          </Grid>
          <Grid item sm={8} xs={12} className={classes.summaryLabel}>
            {ready && t('SUBSCRIPTION_TAX')}
            {
              user.taxApplicable && (
                <span>{` (${user.vatNumber || t('SUBSCRIPTION_NO_VAT')})`}</span>
              )
            }
          </Grid>
          {
            user.taxApplicable
              ? (
                <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                  &euro;
                  {` ${getMaskedCurrencyText((Math.round((subscription.ecPrice * 21) / 100)).toString())}`}
                </Grid>
              ) : (
                <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                  -
                </Grid>
              )
          }
        </Grid>
      </Grid>
      {renderFooter(-1, () => setTabIndex(1))}
    </Grid>
  );

  const userDetails = () => (
    <Grid>
      <Typography className={classes.tabTitle}>
        {ready && t('SUBSCRIPTION_CUSOTMER_INFO')}
      </Typography>
      <Grid className={classes.fieldsContainer}>
        <TextField
          className={classes.fieldStyle}
          fullWidth
          label={ready && t('SUBSCRIPTION_EMAIL')}
          name="email"
          onChange={(e) => handleChangeField(e.target.value.toLowerCase(), t('SUBSCRIPTION_EMAIL'), setEmail, setEmailErr)}
          type="text"
          value={email}
          variant="outlined"
          required
          error={Boolean(emailErr)}
          helperText={emailErr}
        />
      </Grid>
      {renderFooter(0, handleGotoPayment)}
    </Grid>
  );

  const paymentDetails = () => (
    <Grid>
      <Typography className={classes.tabTitle}>
        {ready && t('SUBSCRIPTION_PAYMENT_METHOD')}
      </Typography>
      <Grid className={classes.paymentTabContainer}>
        {
          addingCard || activatingCard || fetchingCard
            ? (<LoadingScreenCustomText text={fetchingCard ? ready && t('SUBSCRIPTION_LOADING_CARD') : activatingCard ? ready && t('SUBSCRIPTION_ACTIVATING_CARD') : ready && t('SUBSCRIPTION_ADDING_CARD')} />) //eslint-disable-line
            : (
              <>
                <Grid container spacing={3}>
                  {
                    cards && cards.length > 0 && cards.map((c) => (
                      <Grid item md={4} sm={6} xs={12} key={c.id}>
                        <Grid className={classes.card}>
                          <Typography className={classes.cardNumber}>{c.maskedCardNumber}</Typography>
                          <Grid className={classes.cardFooter}>
                            <Typography className={classes.cardFooterText}>{`${c.firstName || ''} ${c.lastName || ''}`}</Typography>
                            <Typography className={classes.cardFooterText}>
                              {
                                ready && `${c.expirationMonth.toString().length !== 2
                                  ? `0${c.expirationMonth}`
                                  : c.expirationMonth}/${c.expirationYear}`
                              }
                            </Typography>
                            {c.cardType === 'master' && <img src={images.master} alt="" className={classes.cardType} />}
                            {c.cardType === 'visa' && <img src={images.visa} alt="" className={classes.cardType} />}
                            {c.cardType === 'american_express' && <img src={images.amex} alt="" className={classes.cardType} />}
                          </Grid>
                        </Grid>
                        <Grid className={classes.cardActionsContainer}>
                          {
                            <Grid className={classes.makeActiveContainer}>
                              <Button
                                variant="outlined"
                                className={classes.changePlan}
                                onClick={onNavigateToAddCard}
                              >
                                {ready && t('SUBSCRIPTION_CHANGE_CARD')}
                              </Button>
                            </Grid>
                          }
                          {
                            defaultCard === c.id
                              ? (
                                <Grid className={classes.activeContainer}>
                                  <CheckCircleICon className={classes.checkIcon} />
                                  <Typography className={classes.activeText}>Active</Typography>
                                </Grid>
                              )
                              : (
                                <Grid className={classes.makeActiveContainer}>
                                  <Button variant="outlined" className={classes.makeActiveBtn} onClick={() => activateCard(c.id)}>
                                    {ready && t('SUBSCRIPTION_AVTIVATE_CARD')}
                                  </Button>
                                </Grid>
                              )
                          }
                        </Grid>
                      </Grid>
                    ))
                  }
                  {
                    (!cards || cards.length === 0) && (
                      <Grid className={classes.addCardContainer}>
                        <Typography className={classes.normalText}>{ready && t('SUBSCRIPTION_NO_CARD')}</Typography>
                        <Button variant="outlined" className={classes.addCardBtn} onClick={onNavigateToAddCard}>{ready && t('SUBSCRIPTION_ADD_CARD')}</Button>
                      </Grid>
                    )
                  }
                </Grid>
              </>
            )
          }
      </Grid>
      {renderFooter(1, handleGotoSummary)}
    </Grid>
  );

  const summaryDetails = () => (
    <Grid>
      <Typography className={classes.tabTitle}>
        {ready && t('SUBSCRIPTION_SUMMARY')}
      </Typography>
      {
        type === 0 && (
          <Grid className={classes.wrapper}>
            <Grid container className={classes.summaryContainer}>
              <Grid item sm={8} xs={12} className={classes.summaryLabel}>
                {ready && t('SUBSCRIPTION_SUBSCRIPTION_FEE', { subscription: t(selectedSubscription.i18n) })}
              </Grid>
              <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                &euro;
                {` ${getMaskedCurrencyText((selectedSubscription.price ? selectedSubscription.price.toString() : '0').toString())}`}
              </Grid>
              <Grid item sm={8} xs={12} className={classes.summaryLabel}>
                {ready && t('SUBSCRIPTION_TAX')}
                {
                  user.taxApplicable && (
                    <span>{` (${user.vatNumber || t('SUBSCRIPTION_NO_VAT')})`}</span>
                  )
                }
              </Grid>
              {
                user.taxApplicable
                  ? (
                    <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                      &euro;
                      {` ${getMaskedCurrencyText((Math.round(
                        ((selectedSubscription.price ? selectedSubscription.price : 0) * 21) / 100
                      )).toString())}`}
                    </Grid>
                  ) : (
                    <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                      -
                    </Grid>
                  )
              }
              <Grid item xs={12} className={classes.hrLine} />
              <Grid item sm={8} xs={12} className={classes.totalLabel}>
                {ready && t('SUBSCRIPTION_TOTAL')}
              </Grid>
              <Grid item sm={4} xs={12} className={classes.totalAmount}>
                &euro;
                {
                  ` ${getMaskedCurrencyText((
                    (selectedSubscription.price ? selectedSubscription.price : 0) + (user.taxApplicable ? Math.round(
                      ((selectedSubscription.price ? selectedSubscription.price : 0) * 21) / 100
                    ) : '')).toString())}`
                }
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {
        type === 1 && (
          <Grid className={classes.wrapper}>
            <Grid container className={classes.summaryContainer}>
              <Grid item sm={8} xs={12} className={classes.summaryLabel}>
                {ready && t('SUBSCRIPTION_EX_INV_FEE')}
              </Grid>
              <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                &euro;
                {` ${getMaskedCurrencyText(subscription.ecPrice.toString())}`}
              </Grid>
              <Grid item sm={8} xs={12} className={classes.summaryLabel}>
                {ready && t('SUBSCRIPTION_TAX')}
                {
                  user.taxApplicable && (
                    <span>{` (${user.vatNumber || t('SUBSCRIPTION_NO_VAT')})`}</span>
                  )
                }
              </Grid>
              {
                user.taxApplicable
                  ? (
                    <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                      &euro;
                      {` ${getMaskedCurrencyText((Math.round((subscription.ecPrice * 21) / 100)).toString())}`}
                    </Grid>
                  ) : (
                    <Grid item sm={4} xs={12} className={classes.summaryAmount}>
                      -
                    </Grid>
                  )
              }
              <Grid item xs={12} className={classes.hrLine} />
              <Grid item sm={8} xs={12} className={classes.totalLabel}>
                {ready && t('SUBSCRIPTION_TOTAL')}
              </Grid>
              <Grid item sm={4} xs={12} className={classes.totalAmount}>
                &euro;
                {` ${getMaskedCurrencyText(
                  (parseInt(subscription.ecPrice, 10) + (user.taxApplicable ? Math.round((subscription.ecPrice * 21) / 100) : '')).toString()
                )}`}
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {renderFooter(2)}
    </Grid>
  );

  if ((user.isSubCompany || user.isPartnerSubCompany) && user.parent) {
    return <SubCompanyAccessModal openModal parentName={user.parentName} />;
  }

  return (
    <Page
      className={classes.root}
      title={ready && t('CHECKOUT_PAGE_TITLE')}
    >
      <Grid container spacing={3} className={classes.wrapper}>
        <Grid item xs={12} className={classes.container}>
          <Card className={classes.planContainer}>
            <Tabs
              value={tabIndex}
              classes={{
                flexContainer: classes.tabsWrapper,
              }}
            >
              <Tab
                className={classes.tabContainer}
                icon={<Grid className={tabIndex === 0 ? classes.selectedCircle : classes.circle} />}
                label={ready && t('CHECKOUT_CONFIGURE')}
              />
              <Tab
                className={classes.tabContainer}
                icon={<Grid className={tabIndex === 1 ? classes.selectedCircle : classes.circle} />}
                label={ready && t('CHECKOUT_DETAILS')}
              />
              <Tab
                className={classes.tabContainer}
                icon={<Grid className={tabIndex === 2 ? classes.selectedCircle : classes.circle} />}
                label={ready && t('CHECKOUT_PAYMENT')}
              />
              <Tab
                className={classes.tabContainer}
                icon={<Grid className={tabIndex === 3 ? classes.selectedCircle : classes.circle} />}
                label={ready && t('CHECKOUT_CONFIRM')}
              />
            </Tabs>
            <Grid className={classes.bodyContainer}>
              {
                tabIndex === 0 && type === 0 && subscriptionDetails()
              }
              {
                tabIndex === 0 && type === 1 && extraPackDetails()
              }
              {
                tabIndex === 1 && userDetails()
              }
              {
                tabIndex === 2 && paymentDetails()
              }
              {
                tabIndex === 3 && summaryDetails()
              }
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}

Checkout.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedSubscription: PropTypes.shape()
    })
  })
};

export default Checkout;
