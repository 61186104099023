import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  halfPaper: {
    width: '90%',
    maxWidth: '680px',
    minWidth: '380px',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  applyToAllContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 16
  },
  modalFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 16px',
  },
  saveBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    margin: '6px 0px 6px 16px'
  },
  cancelBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
    margin: '6px 0px 6px 0px'
  },
  formatChangeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 16px',
  },
  dataFormatText: {
    fontSize: 15,
    fontWeight: 600,
    color: '#334D6E',
    marginRight: 16,
  },
  dataFormatSelect: {
    color: '#334D6E',
    fontSize: '13px',
    lineHeight: '20px',
    width: '30%',
  },
  managedByParentContainer: {
    padding: '16px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  managedByParent: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
  },
  entityContainer: {
    padding: '8px 0px',
  },
  entityTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
  },
  entityTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#334D6E',
  },
  action: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    padding: '24px 16px',
  },
  noDataText: {
    color: '#334D6E',
    fontSize: '15px',
    fontWeight: 500,
  },
  modalItemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputItem: {
    width: '45%',
    padding: '8px 8px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    }
  },
  selectInput: {
    color: '#334D6E',
    fontSize: '13px',
    lineHeight: '20px',
    width: '100%',
  },
  inputText: {
    color: '#334D6E',
    fontSize: '13px',
    lineHeight: '20px'
  },
  dragIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkBoxLabel: {
    fontSize: 14,
    color: '#334D6E'
  },
  checkBox: {
    color: '#3E8AFF',
    paddingBottom: 0,
    paddingTop: 0
  },
  checkboxContainer: {
    marginRight: 8,
    marginBottom: 8
  },
  metaFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    borderTop: '1px solid #E1E4E8',
    paddingTop: 16,
  },
}));

export default useStyles;
