/* eslint-disable no-confusing-arrow */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useSelector } from 'react-redux';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, Container,
  FormControl, FormHelperText, Grid, TextField
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import {
  projectFields, ERRORS, supplierData
} from 'src/config';
import Page from 'src/Shared/components/Page/Page';
import Header from 'src/Dimensions/components/Header/Header';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import {
  axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions, validateToken
} from 'src/Shared/utils/helpers';
import authService from 'src/Shared/utils/services/authService';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function EditProject({
  className,
  history,
  match,
  ...rest
}) {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [costCenters, setCostCenters] = useState(supplierData.defaultCostCenters);

  const getProjectByCode = async (code) => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.projectByCode}/${encodeURIComponent(code)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setProject(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCostCenters = async () => {
    try {
      const response = await Axios.get(
        `${API.costCentersByCompany}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setCostCenters([...supplierData.defaultCostCenters, ...response.data.data]);
        }
      }
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user.permissions)) {
      if (user.accountingSystem === 'twinfield') {
        window.location.href = '/dimensions/projects';
      }

      const code = match && match.params && match.params.code ? match.params.code : '';
      if (code !== '' && code !== 'new') {
        getProjectByCode(code);
      }

      getCostCenters();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const gotoProjectsList = () => {
    history.push('/dimensions/projects');
  };

  const hasError = (fields, errors) => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        // Returning true until Twinfield integration
        return false;
      }
    }

    return false;
  };

  const handleChangeAutocomplete = (e, prop, values, handleChange) => {
    if (values && values.code) {
      handleChange({ target: { name: prop, value: values.code } });
      return;
    }

    handleChange(e, prop);
  };

  const getLabel = (entities, code) => {
    for (let i = 0; i < entities?.length; i++) {
      if (code === entities[i].code) {
        let namePart = '';
        if (entities[i].name) {
          namePart = ` | ${entities[i].name}`;
        }
        return `${entities[i].code}${namePart}`;
      }
    }

    return code;
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Page
      className={classes.root}
      title="Project"
    >
      <Container maxWidth="xl">
        <Grid className={classes.titleContainer}>
          <Button className={classes.backBtn} onClick={gotoProjectsList}>
            <ArrowBackIcon className={classes.icon} />
            {ready && t('PROJECTS_BACK_TO_PROJECTS_LIST')}
          </Button>
        </Grid>
        <Header title={project?.name ? `Project - ${project ? project.name : ''} (${project?.code})` : ready && t('PROJECT_ADD_NEW_PROJECT')} />
        <Formik
          initialValues={{
            code: project?.code || '',
            name: project?.name || '',
            costCenter: project?.costCenter || '',
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .max(255)
              .required('PROJECT_CODE_REQUIRED')
              .matches('^\\S*$', 'PROJECT_CODE_SPACES_NOT_ALLOWED'),
            name: Yup.string()
              .max(80, 'PROJECT_NAME_MAX_VALIDATION')
              .required('PROJECT_NAME_REQUIRED'),
          })}
          onSubmit={async (values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {
              if (!validateToken()) {
                enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                  variant: 'error',
                  persist: true
                });
                setTimeout(() => {
                  authService.logout(LOGIN_PANEL_URL);
                }, 2000);
                return;
              }

              const body = {
                code: values.code,
                name: values.name,
                costCenter: values.costCenter
              };

              const url = `${API.projectsByCompany}/${encodeURIComponent(user.companyID)}`;
              let response = null;
              let snackText = '';

              if (project && project.name) {
                response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                snackText = 'PROJECTS_UPDATE_SUCCESS';
              } else {
                response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                snackText = 'PROJECTS_ADD_SUCCESS';
              }
              if (response?.data?.success) {
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar(ready && t(snackText), {
                  variant: 'success',
                  persist: true,
                  style: { maxWidth: 400 }
                });
                gotoProjectsList();
              }
            } catch (error) {
              setStatus({ success: false });
              setSubmitting(false);
              if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
                const errParts = error.response.data.i18n.split('__TFMSG__');
                setErrors({
                  [errParts[0]]: errParts[1]
                });
              } else {
                setErrors({
                  submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
                });
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardContent style={{ padding: 32 }}>
                  <Grid container spacing={3}>
                    {
                      projectFields.map((m) => (
                        <Accordion
                          key={m.title}
                          className={classes.accordionGroup}
                          defaultExpanded
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={m.title}
                            id={m.title}
                          >
                            <Grid className={classes.accordionHeading}>
                              {ready && t(m.title)}
                              {
                                hasError(m.fields, errors) && (
                                  <Chip
                                    color="error"
                                    variant="default"
                                    size="small"
                                    label="Error"
                                    className={classes.errorChip}
                                  />
                                )
                              }
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={3}>
                              {
                                m.fields.map((f) => {
                                  switch (f.key) {
                                    case 'costCenter':
                                      return (
                                        <Grid item lg={4} md={6} xs={12} key={f.key}>
                                          <FormControl variant="outlined" className={classes.formControl}>
                                            <Autocomplete
                                              fullWidth
                                              name="costCenter"
                                              onChange={(e, v) => handleChangeAutocomplete(e.target.value, 'costCenter', v, handleChange)}
                                              value={getLabel(costCenters, values.costCenter)}
                                              options={costCenters}
                                              getOptionLabel={
                                                (option) => option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option
                                              }
                                              getOptionSelected={(option) => option.code === (values.costCenter ? values.costCenter : ' ')}
                                              renderInput={(params) => (
                                                <TextField
                                                  fullWidth
                                                  {...params}
                                                  label={ready && t(f.label)}
                                                  variant="outlined"
                                                  InputProps={{
                                                    ...params.InputProps
                                                  }}
                                                  className={classes.selectStyle}
                                                />
                                              )}
                                            />
                                            <FormHelperText error>
                                              {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                            </FormHelperText>
                                          </FormControl>
                                        </Grid>
                                      );
                                    default:
                                      return (
                                        <Grid item lg={4} md={6} xs={12} key={f.key}>
                                          <TextField
                                            error={Boolean(touched[f.key] && errors[f.key])}
                                            fullWidth
                                            helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                            label={ready && t(f.label)}
                                            name={f.key}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            required={f.required}
                                            value={values[f.key]}
                                            variant="outlined"
                                            className={classes.inputField}
                                          />
                                        </Grid>
                                      );
                                  }
                                })
                              }
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))
                    }
                    <Grid
                      item
                      xs={12}
                    >
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user.permissions)}
                          onClick={handleSubmit}
                        >
                          {ready && t('PROJECTS_SAVE_CHANGES')}
                        </Button>
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={gotoProjectsList}
                          className={classes.yearField}
                        >
                          {ready && t('PROJECTS_CANCEL')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
}

EditProject.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  project: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  })
};

export default withRouter(EditProject);
