import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

import {
  Box, Card, Grid, Hidden, IconButton, Table, TableBody, TableCell, TableHead, TablePagination,
  TableRow, TextField, Typography, useMediaQuery
} from '@material-ui/core';
import { VisibilityOutlined, KeyboardReturn } from '@material-ui/icons';

import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import { axiosHeaders, formatAmountInvoicesTable } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import { useSelector } from 'react-redux';
import useStyles from './style';

function Invoices(props) {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { header, pageLimit, parentPadding } = props;
  const { t, ready } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [movePage, setMovePage] = useState(1);
  const [limit, setLimit] = useState(pageLimit || 10);

  const maxWidth = useMediaQuery('(max-width: 470px)');
  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const getAllInvoices = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        API.userInvoices,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setInvoices(response.data.data);
        setTotalPages((Math.floor(response.data.data.length / limit) + 1));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllInvoices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    setMovePage(newPage + 1);
    // getAllInvoices(newPage, limit);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setTotalPages((Math.floor(invoices.length / event.target.value) + 1));
    // getAllInvoices(page, event.target.value);
  };

  const moveToPage = (toPage) => {
    if (toPage < 1) {
      return;
    }

    if (totalPages >= toPage) {
      setPage(toPage);
    }
  };

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <>
      <Grid
        className={clsx(classes.header, parentPadding ? classes.componentPadding : '')}
      >
        <Typography className={classes.headerTitle}>
          {ready && t(header || 'ACCOUNT_SETTINGS_BILLING_INVOICES_MY_INVOICES')}
        </Typography>
      </Grid>
      {
        invoices && invoices.length > 0
          ? (
            <Card className={clsx(classes.root)}>
              <PerfectScrollbar>
                <Box minWidth="100%">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.columnName}>
                          #
                        </TableCell>
                        <Hidden smDown>
                          <TableCell className={classes.columnName}>
                            {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_01')}
                          </TableCell>
                        </Hidden>
                        <Hidden xsDown>
                          <TableCell className={classes.columnName}>
                            {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_02')}
                          </TableCell>
                        </Hidden>
                        <TableCell className={classes.columnName}>
                          {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_02')}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoices.slice((page - 1) * limit, (page) * limit).map((invoice) => (
                        <TableRow key={invoice.sequenceNumber} className={classes.row}>
                          <TableCell className={classes.cellText}>
                            <Grid className={classes.cardNumberRow}>
                              {`DOC-${invoice.number}`}
                            </Grid>
                          </TableCell>
                          <Hidden smDown>
                            <TableCell className={classes.cellText}>
                              {invoice.issueDate}
                            </TableCell>
                          </Hidden>
                          <Hidden xsDown>
                            <TableCell className={classes.cellText}>
                              {invoice.dueDate}
                            </TableCell>
                          </Hidden>
                          <TableCell className={classes.cellBoldText}>
                            {`${invoice.currency} ${invoice.totalAmount
                                && formatAmountInvoicesTable(invoice.totalAmount, user.monetaryDecimalSeparator)}`}
                          </TableCell>
                          {/* <Hidden xsDown>
                            <TableCell className={classes.cellText}>
                              <Grid className={invoice.status === 'paid' ? classes.paidLabel : classes.defaultLabel}>
                                {invoice.status}
                              </Grid>
                            </TableCell>
                          </Hidden> */}
                          {/* <Hidden smUp>
                            <TableCell className={classes.cellText}>
                              <Grid className={clsx(classes.statusDot, invoice.status === 'paid' ? classes.paidDot : classes.defaultDot)} />
                            </TableCell>
                          </Hidden> */}
                          <TableCell>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              className={classes.actionContainer}
                            >
                              <IconButton onClick={() => window.open(invoice.publicURL, '_blank')}>
                                <VisibilityOutlined />
                              </IconButton>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <div className={classes.caption}>
                {
                  !maxWidth && invoices.length !== 1
                    ? ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COUNT', { count: invoices.length })
                    : ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COUNT_ONE', { count: invoices.length })
                }
                <Grid>
                  {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_MOVE_TO_PAGE')}
                  <TextField
                    className={classes.searchInput}
                    type="number"
                    variant="outlined"
                    value={movePage}
                    onChange={(e) => setMovePage(e.target.value)}
                    onKeyUp={(e) => e && e.key === 'Enter' && moveToPage(movePage)}
                  />
                  {`/${totalPages}`}
                  <IconButton className={classes.moveButton} onClick={() => moveToPage(movePage)}>
                    <KeyboardReturn className={classes.moveIcon} />
                  </IconButton>
                </Grid>
                <TablePagination
                  component="div"
                  classes={{
                    root: classes.paginationComp,
                    toolbar: classes.paginationToolbar,
                    caption: classes.paginationCaption,
                    selectRoot: classes.paginationCaption,
                    select: classes.paginationMenu,
                    menuItem: classes.paginationMenu,
                    actions: classes.paginationActions,
                  }}
                  count={invoices.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page - 1}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
                />
              </div>
            </Card>
          )
          : <EmptyText str="ACCOUNT_SETTINGS_INVOICES_NO_INVOICES" />
      }
    </>
  );
}

Invoices.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  parentPadding: PropTypes.bool,
  header: PropTypes.string,
  pageLimit: PropTypes.number
};

export default withRouter(Invoices);
