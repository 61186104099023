/* eslint-disable no-param-reassign */

import { updateObject } from 'src/Shared/utils/helpers';
import * as actionTypes from 'src/User/store/actions/actionType';

const initialState = {
  user: null,
  subscription: null,
  error: null,
};

const updateSubscriptionStart = (state) => updateObject(state, { loading: true });

const updateSubscriptionSuccess = (state, action) => {
  state.user.subscription = action.subscription;

  return updateObject(state, {
    user: state.user,
    subscription: action.subscription,
    error: null
  });
};

const updateSubscriptionFail = (state, action) => updateObject(
  state, { subscription: null, error: action.error }
);

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SUBSCRIPTION_START: return updateSubscriptionStart(state, action);
    case actionTypes.UPDATE_SUBSCRIPTION_SUCCESS: return updateSubscriptionSuccess(state, action);
    case actionTypes.UPDATE_SUBSCRIPTION_FAIL: return updateSubscriptionFail(state, action);

    default: {
      return state;
    }
  }
};

export default subscriptionReducer;
