import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles(() => ({
  canvas0: {
    position: 'absolute',
    width: '100%',
    zIndex: 99,
    outline: 'none',
    left: 0,
    top: 0,
  },
  canvas1: {
    position: 'absolute',
    width: '100%',
    zIndex: 100,
    outline: 'none',
    left: 0,
    top: 0,
  },
  canvas2: {
    position: 'absolute',
    width: '100%',
    zIndex: 101,
    outline: 'none',
    left: 0,
    top: 0,
  },
  container: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '100%'
  }
}));

export default style;
