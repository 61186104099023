import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from 'src/Dimensions/store/actions/actionTypes';
import fetchCostCentersSaga from './costcenter';
import { fetchGlaListSaga, fetchGlasSaga } from './gla';
import { fetchVatCodesSaga, fetchVatCodeListSaga } from './vatcode';

export function* watchVatCode() {
  yield all([
    takeEvery(actionTypes.FETCH_VATCODES, fetchVatCodesSaga),
    takeEvery(actionTypes.FETCH_VATCODE_LIST, fetchVatCodeListSaga)
  ]);
}

export function* watchGla() {
  yield all([
    takeEvery(actionTypes.FETCH_GLAS, fetchGlasSaga),
    takeEvery(actionTypes.FETCH_GLA_LIST, fetchGlaListSaga)
  ]);
}

export function* watchCostCenter() {
  yield all([
    takeEvery(actionTypes.FETCH_COSTCENTERS, fetchCostCentersSaga)
  ]);
}
