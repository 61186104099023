import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener, Fade, Grid, Grow, IconButton, MenuItem, MenuList, Modal, Paper, Popper, TextField, Typography
} from '@material-ui/core';
import {
  Close as CloseIcon,
  ArrowDropDown
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const ExportToEmailModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    sendEmailModalOpen,
    handleSendEmailModalClose,
    emails,
    setEmails,
    dataFormatOptionsToEmailRef,
    handleDataformatOptionsToEmailToggle,
    getDeliverFormat,
    selectedDataFormatToEmail,
    deliverFormats,
    dataFormatOptionsToEmailOpen,
    handleDataformatOptionsToEmailClose,
    handleDataFormatToEmailClick,
    handleSendToEmailClick
  } = props;
  return (
    <Modal
      className={classes.modal}
      open={sendEmailModalOpen}
      onClose={() => handleSendEmailModalClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={sendEmailModalOpen}>
        <div className={classes.halfPaper}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {t('EXPORT_TO_EMAIL_MODAL_TITLE')}
            </Typography>
            <IconButton onClick={() => handleSendEmailModalClose()}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Grid>
            <Grid className={classes.inputField}>
              <Typography variant="h5" className={classes.primaryTextColor}>
                {t('EXPORT_TO_EMAIL_MODAL_EMAIL')}
              </Typography>
              <TextField
                fullWidth
                name="lineDescription"
                value={emails}
                variant="outlined"
                className={classes.inputField}
                onChange={(e) => setEmails(e.target.value)}
              />
              <Typography variant="caption" className={classes.primaryTextColor}>
                {t('EXPORT_TO_EMAIL_MODAL_INSTRUCTION')}
              </Typography>
            </Grid>
            <Grid className={classes.fieldContainer}>
              <Typography variant="h5" className={classes.primaryTextColor}>
                {t('EXPORT_TO_EMAIL_MODAL_DATA_FORMAT')}
              </Typography>
              <ButtonGroup variant="outlined" ref={dataFormatOptionsToEmailRef} aria-label="split button" className={classes.btnGroup}>
                <Button className={classes.groupBtn} onClick={handleDataformatOptionsToEmailToggle}>
                  {getDeliverFormat(selectedDataFormatToEmail, deliverFormats).label}
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <Popper
                open={dataFormatOptionsToEmailOpen}
                anchorEl={dataFormatOptionsToEmailRef.current}
                role={undefined}
                transition
                style={{ zIndex: 111111, width: '90%', maxWidth: 450, }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleDataformatOptionsToEmailClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {deliverFormats.map((option) => !option.dontExportViaEmail && (
                            <MenuItem
                              key={option.key}
                              selected={option.key === selectedDataFormatToEmail}
                              onClick={() => handleDataFormatToEmailClick(option)}
                              className={classes.groupBtn}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
          <Grid className={classes.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                className={classes.cancelButton}
                type="submit"
                onClick={() => handleSendEmailModalClose()}
              >
                {t('DASHBOARD_INVOICES_CANCEL')}
              </Button>
              <Button
                className={classes.validateButton}
                variant="contained"
                color="secondary"
                onClick={() => handleSendToEmailClick()}
              >
                {t('DASHBOARD_INVOICES_SEND')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

ExportToEmailModal.propTypes = {
  sendEmailModalOpen: PropTypes.bool,
  handleSendEmailModalClose: PropTypes.func,
  emails: PropTypes.string,
  setEmails: PropTypes.func,
  dataFormatOptionsToEmailRef: PropTypes.object,
  handleDataformatOptionsToEmailToggle: PropTypes.func,
  getDeliverFormat: PropTypes.func,
  selectedDataFormatToEmail: PropTypes.string,
  deliverFormats: PropTypes.array,
  dataFormatOptionsToEmailOpen: PropTypes.bool,
  handleDataformatOptionsToEmailClose: PropTypes.func,
  handleDataFormatToEmailClick: PropTypes.func,
  handleSendToEmailClick: PropTypes.func,
};

export default ExportToEmailModal;
