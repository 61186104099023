import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    paddingLeft: 40,
    paddingRight: 40,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  loadingText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backToProcysBtn: {
    fontSize: 13,
    color: '#334D6E',
    textTransform: 'none',
    marginLeft: 8,
    padding: '3px 8px',
    margin: 8
  }
}));

export default useStyles;
