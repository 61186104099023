import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { useTranslation } from 'react-i18next';
import { Box, LinearProgress, Typography } from '@material-ui/core';

import useStyles from './style';

function LoadingScreenText() {
  const classes = useStyles();
  const { ready, t } = useTranslation();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box width={400}>
        <Typography
          align="center"
          variant="subtitle1"
        >
          {ready && t('LOADING_PAGE_TEXT')}
        </Typography>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default LoadingScreenText;
