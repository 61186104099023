import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {},
  snackAction: {
    color: 'white'
  },
  userForm: {
    width: '100%'
  },
  fieldContainer: {
    marginTop: 25
  },
  submitBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    textTransform: 'none',
    background: '#3E8AFF',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    borderRadius: 4
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    color: '#A0AEC1',
    textTransform: 'none',
    marginRight: 24
  },
  moreButton: {
    textTransform: 'none'
  },
  formControl: {
    width: '100%'
  },
  inputFieldHighlighted: {
    backgroundColor: '#d7e7fc'
  },
  selectStyle: {
    color: '#334D6E',
    fontSize: 13,
    lineHeight: '20px',
  },
  selectStyleHighlighted: {
    color: '#334D6E',
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#d7e7fc'
  },
  mainContainer: {
    height: '95%',
  },
  invoiceContainer: {
    padding: '0px',
    backgroundColor: 'gray'
  },
  leftContainer: {
    padding: '0px 20px 10px 0px'
  },
  fieldsWrapper: {
    maxHeight: 907,
    overflow: 'scroll'
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: '#3E8AFF',
    cursor: 'pointer'
  },
  plusIconContainer: {
    marginRight: -60
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: '96%',
    maxHeight: '94%',
    height: '90%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modalHeaderLeft: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  modalHeaderRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
  },
  manageFieldsBtnContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px',
    minWidth: 145,
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
}));

export default useStyles;
