import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, Card, Grid, TablePagination,
  Tooltip, IconButton, useMediaQuery, TableHead
} from '@material-ui/core';
import {
  CardTravel as LogsIcon
} from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import { isActionPermitted, permissions } from 'src/Shared/utils/helpers';
import useStyles from './style';

const SuppliersTable = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const canEditDimension = isActionPermitted(permissions.dimensionEdit, user.permissions);

  const {
    suppliers, getAllSuppliers, total, fetching, limit, setLimit, tfError, handleEditSupplierOpen, query, page, setPage
  } = props;

  const { ready, t } = useTranslation();

  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    getAllSuppliers(newPage, limit, query, false);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
    getAllSuppliers(0, event.target.value, query, false);
  };

  const paginationTab = () => (
    <TablePagination
      component="div"
      classes={{
        caption: classes.paginationCaption,
        selectRoot: classes.paginationCaption,
        menuItem: classes.paginationCaption,
        actions: classes.paginationActions,
      }}
      count={total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
    />
  );

  const gotoLogs = (event, code) => {
    event.stopPropagation();
    event.preventDefault();
    history.push({
      pathname: `/dimensions/logs/suppliers/code/${code}`,
      state: { code }
    });
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        !isActionPermitted(permissions.dimensionRead, user.permissions)
          ? (
            <EmptyText str={'You do not have sufficient permission to view Suppliers.'
          + ' Please contact your company admin to provide you the required permissions.'}
            />
          )
          : suppliers && suppliers.length > 0 ? (
            <Card
              className={classes.cardCont}
            >
              {
                fetching
                  ? <LoadingScreenText />
                  : (
                    <Box minWidth="100%">
                      <div className={classes.bulkOperations}>
                        <div className={classes.bulkActions}>
                          {paginationTab()}
                        </div>
                      </div>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}</TableCell>
                            <TableCell className={classes.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_CODE')}</TableCell>
                            <Hidden smDown>
                              <TableCell />
                            </Hidden>
                            <Hidden smDown>
                              <TableCell className={classes.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_COUNTRY')}</TableCell>
                            </Hidden>
                            <Hidden smDown>
                              <TableCell />
                            </Hidden>
                            <Hidden smDown>
                              <TableCell />
                            </Hidden>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {suppliers.map((supplier) => (
                            <TableRow
                              hover={canEditDimension}
                              key={supplier.creditorCode}
                              className={canEditDimension ? classes.pointer : classes.tableRow}
                              onClick={() => canEditDimension && handleEditSupplierOpen(supplier.creditorCode)}
                            >
                              <TableCell align="left" className={classes.creditorNameStyle}>
                                {supplier.creditorName}
                              </TableCell>
                              <TableCell className={classes.todayText}>
                                {supplier.creditorCode}
                              </TableCell>
                              <Hidden smDown>
                                <TableCell className={classes.currencyText}>
                                  {supplier.currency}
                                </TableCell>
                              </Hidden>
                              <Hidden smDown>
                                <TableCell className={classes.currencyText}>
                                  {supplier.country?.toUpperCase()}
                                </TableCell>
                              </Hidden>
                              <Hidden smDown>
                                <TableCell className={classes.currencyText}>
                                  {supplier.status}
                                </TableCell>
                              </Hidden>
                              {/* <TableCell width={56} align="center">
                                <IconButton>
                                  <SvgIcon style={{ display: 'flex' }}>
                                    <MoreVertIcon className={classes.moreIcon} />
                                  </SvgIcon>
                                </IconButton>
                              </TableCell> */}
                              <Hidden smDown>
                                <TableCell width={102} align="center" className={classes.currencyText}>
                                  <Tooltip title={t('SUPPLIER_LIST_VIEW_LOGS_TOOLTIP')}>
                                    <IconButton onClick={(e) => gotoLogs(e, supplier.creditorCode)}>
                                      <LogsIcon
                                        className={classes.logsIcon}
                                        style={{ fontSize: '1.8rem' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </Hidden>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        className={classes.bottomPagi}
                      >
                        {paginationTab()}
                      </Grid>
                    </Box>
                  )
              }
            </Card>
          ) : (
            <EmptyText str={tfError ? 'TF_CONNECTION_ERROR' : 'NO_SUPPLIERS'} />
          )
      }
    </>
  );
};

SuppliersTable.propTypes = {
  suppliers: PropTypes.array,
  getAllSuppliers: PropTypes.func,
  setLimit: PropTypes.func,
  limit: PropTypes.number,
  total: PropTypes.number,
  fetching: PropTypes.bool,
  tfError: PropTypes.bool,
  handleEditSupplierOpen: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  query: PropTypes.string,
  page: PropTypes.number,
  setPage: PropTypes.func
};

export default SuppliersTable;
