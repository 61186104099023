import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'gray',
    flexDirection: 'column',
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
    width: '100%',
    justifyContent: 'space-between'
  },
  fileName: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 16
  },
  buttonCls: {
    cursor: 'pointer',
    marginRight: '2px',
    minWidth: 48,
    color: '#ffffff'
  },
  errorContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  errorText: {
    color: '#4E342E',
    fontWeight: '500',
    fontSize: 16
  },
  pdfFrame: {
    width: '100%',
    height: '100%',
    borderWidth: 0
  },
  docContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  imageDisplay: {
  },
  excelTable2007: {
    backgroundColor: '#ffffff',
    border: '1px solid #B0CBEF',
    borderWidth: '1px 0px 0px 1px',
    fontSize: 13,
    fontFamily: 'Calibri',
    fontWeight: 100,
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    padding: '10px',
    '& TH': {
      backgroundColor: '#E4ECF7',
      backgroundRepeat: 'repeat-x',
      fontWeight: 'bold',
      fontSize: '14px',
      border: '1px solid #9EB6CE',
      borderWidth: '0px 1px 1px 0px',
      height: '17px',
      lineHeight: '17px',
      textAlign: 'center',
      paddingLeft: '4px',
      paddingRight: '4px !important'
    },
    '& TD': {
      padding: '0px 4px 0px 2px',
      border: '1px solid #D0D7E5',
      borderWidth: '0px 1px 1px 0px'
    },
    '& TD B': {
      border: '0px',
      fontWeight: 'bold'
    },
    '& TD.heading': {
      backgroundColor: '#E4ECF7',
      textAlign: 'center',
      border: '1px solid #9EB6CE',
      borderWidth: '0px 1px 1px 0px',
      fontWeight: 'bold'
    }
  },
  heading: {
    backgroundColor: '#E4ECF7',
    textAlign: 'center',
    border: '1px solid #9EB6CE',
    borderWidth: '0px 1px 1px 0px',
    fontWeight: 'bold'
  }
}));

export default useStyles;
