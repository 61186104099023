import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import {
  Box, Container, Grid, Typography, Button, Avatar, IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  Person as UserIcon,
  SubscriptionsOutlined as SubscriptionIcon,
  PeopleAlt as UsersIcon
} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';

import Page from 'src/Shared/components/Page/Page';
import General from 'src/Auth/components/General/General';
import UserList from 'src/User/containers/UserList/UserList';
import CompaniesList from 'src/User/containers/CompaniesList/CompaniesList';
import MyPlan from 'src/User/containers/MyPlan/MyPlan';

import * as actions from 'src/Auth/store/actions/account';
import * as authActions from 'src/Auth/store/actions/auth';
import authService from 'src/Shared/utils/services/authService';
import { axiosHeaders, validateToken } from 'src/Shared/utils/helpers';
import images from 'src/Shared/assets/images';
import BrandSettings from 'src/Auth/components/BrandSettings/BrandSettings';
import PartnerStats from 'src/Auth/components/PartnerStats/PartnerStats';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const tabs = [
  {
    value: 'user',
    label: 'ACCOUNT_SETTINGS_MY_PROFILE',
    icon: UserIcon,
    subtitle: 'ACCOUNT_SETTINGS_MY_PROFILE_SUBTITLE'
  },
  {
    value: 'subscription',
    label: 'ACCOUNT_SETTINGS_PLAN_N_BILLING',
    icon: SubscriptionIcon,
    subtitle: 'ACCOUNT_SETTINGS_PLAN_N_BILLING_SUBTITLE'
  },
  {
    value: 'users',
    label: 'ACCOUNT_SETTINGS_USERS',
    icon: UsersIcon,
    subtitle: 'ACCOUNT_SETTINGS_USERS_SUBTITLE'
  },
  {
    value: 'companies',
    label: 'ACCOUNT_SETTINGS_COMPANIES',
    icon: UsersIcon,
    subtitle: 'ACCOUNT_SETTINGS_COMPANIES_SUBTITLE'
  },
  {
    value: 'brand',
    label: 'ACCOUNT_SETTINGS_BRAND_SETTINGS',
    icon: UsersIcon,
    subtitle: 'ACCOUNT_SETTINGS_BRAND_SETTINGS_SUBTITLE'
  },
  {
    value: 'partner_stats',
    label: 'ACCOUNT_SETTINGS_PARTNER_STATS',
    icon: UsersIcon,
    subtitle: 'ACCOUNT_SETTINGS_PARTNER_STATS_SUBTITLE'
  },
];

class AccountView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedImage: '',
      currentTab: props.location && props.location.state && props.location.state.tab
        ? tabs[props.location.state.tab].value : tabs[0].value
    };
  }

  handleTabsChange = (value) => {
    this.setState({ currentTab: value });
  };

  handleUploadClick = (event) => {
    const { LOGIN_PANEL_URL, API } = this.context;
    const { files } = event.target;
    const {
      user, onSetUserData, enqueueSnackbar, t
    } = this.props;

    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    let file = '';
    if (files && files.length > 0) {
      [file] = files;

      if (file.size > 1 * 1024 * 1024) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_PROFILE_PIC_MAX_SIZE'), {
          variant: 'error',
          persist: true
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async (f) => {
        if (reader.result) {
          const image = new Image();
          image.src = f.target.result;
          image.onload = async () => {
            if (image.width > 800 && image.height > 800) {
              enqueueSnackbar(t('ACCOUNT_SETTINGS_PROFILE_PIC_MAX_DIMENSIONS'), {
                variant: 'error',
                persist: true
              });
              return;
            }

            const data = new FormData();
            data.append('image', files[0]);

            try {
              const response = await Axios.post(
                API.userAvatarImage,
                data,
                axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
              );
              if (response.data.success) {
                onSetUserData({
                  ...user,
                  avatar: reader.result
                });
              }
            } catch (error) {
              if (error.response && error.response.data) {
                // this.setState({ errorMsg: error.response.data.message });
              } else {
                // this.setState({ errorMsg: error.message });
              }
            }
          };
        }
      };
      reader.readAsDataURL(file);

      const url = URL.createObjectURL(file);
      this.setState({ uploadedImage: url });
    }
  };

  render() {
    const { currentTab, uploadedImage } = this.state;
    const {
      classes, user, t, tReady,
    } = this.props;

    return (
      <Page
        className={classes.root}
        title={tReady && t('ACCOUNT_SETTINGS_MY_ACCOUNT')}
      >
        <Grid>
          <Grid
            className={classes.banner}
            style={{ backgroundImage: `url(${images.profileBanner})` }}
          />
          <Grid className={classes.profileDetails}>
            <IconButton
              component="label"
              className={classes.avatarContainer}
            >
              <Avatar
                className={classes.avatar}
                src={uploadedImage || user.avatar}
              />
              <input
                accept=".png, .jpg, .jpeg"
                type="file"
                onChange={this.handleUploadClick}
                style={{ display: 'none' }}
                required
              />
              <IconButton component="span" className={classes.editIconContainer}>
                <EditIcon className={classes.editIcon} />
              </IconButton>
            </IconButton>
            <Typography className={classes.nameText}>
              {`${user.firstname} ${user.lastname}`}
            </Typography>
            <Typography className={classes.companyText}>
              {user.company}
            </Typography>
            <Grid className={classes.userType}>
              <Typography variant="inherit">
                {user.role === 'company' && 'Admin'}
                {user.role === 'user' && user.userRole}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.tabsSection}>
            {
              tabs.map((tab) => {
                if (!user.isCreator && (tab.value === 'billing' || tab.value === 'invoice')) {
                  return null;
                }
                if (user.customisations.includes('hideBilling') && tab.value === 'subscription') {
                  return null;
                }
                if ((user.isSubCompany || user.isPartnerSubCompany) && tab.value === 'brand') {
                  return null;
                }
                if (user.isSubCompany && tab.value === 'partner_stats') {
                  return null;
                }

                return (
                  <Grid className={classes.tab} key={tab.value}>
                    <Button
                      key={tab.value}
                      onClick={() => this.handleTabsChange(tab.value)}
                      className={currentTab === tab.value
                        ? classes.selectedTabButton : classes.tabButton}
                    >
                      <Grid className={classes.tabLabelContainer}>
                        <Typography align="center" className={currentTab === tab.value ? classes.selectedTabLabel : classes.tabLabel}>
                          {tReady && t(tab.label)}
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                );
              })
            }
          </Grid>
        </Grid>
        <Container maxWidth="xl">
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Box>
                {currentTab === 'user' && <General />}
                {currentTab === 'subscription' && <MyPlan isChild />}
                {currentTab === 'users' && <UserList />}
                {currentTab === 'companies' && <CompaniesList />}
                {
                  user.userRole === 'admin' && !user.isSubCompany && !user.isPartnerSubCompany
                  && currentTab === 'brand' && <BrandSettings />
                }
                {
                  user.userRole === 'admin' && !user.isSubCompany
                  && currentTab === 'partner_stats' && <PartnerStats />
                }
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}

AccountView.contextType = ConfigContext;

AccountView.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    tabContainer: PropTypes.string,
    tabLabelContainer: PropTypes.string,
    tabButton: PropTypes.string,
    selectedTabButton: PropTypes.string,
    tabLabel: PropTypes.string,
    selectedTabLabel: PropTypes.string,
    tabSubtitle: PropTypes.string,
    icon: PropTypes.string,
    selectedIcon: PropTypes.string,
    banner: PropTypes.string,
    profileDetails: PropTypes.string,
    avatar: PropTypes.string,
    avatarContainer: PropTypes.string,
    editIconContainer: PropTypes.string,
    editIcon: PropTypes.string,
    nameText: PropTypes.string,
    companyText: PropTypes.string,
    userType: PropTypes.string,
    tab: PropTypes.string,
    tabsSection: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    isCreator: PropTypes.bool,
    isSubCompany: PropTypes.bool,
    isPartnerSubCompany: PropTypes.bool,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    role: PropTypes.string,
    avatar: PropTypes.string,
    company: PropTypes.string,
    userRole: PropTypes.string,
    accountingSystem: PropTypes.string,
    customisations: PropTypes.arrayOf(PropTypes.string)
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      tab: PropTypes.number
    })
  }),
  onSetUserData: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  t: PropTypes.func,
  tReady: PropTypes.bool
};

AccountView.defaultProps = {
  t: () => {},
  tReady: false
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateProfileImage: (user) => dispatch(actions.updateProfileImage(user)),
  onSetUserData: (user) => dispatch(authActions.setUserData(user))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(withSnackbar(AccountView));
