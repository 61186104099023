import React from 'react';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PasswordStrengthBar from 'react-password-strength-bar';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import useStyles from './style';

const PasswordStrengthComponent = (props) => {
  const { password, passwordHelper } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const renderText = (text, colorHex) => (
    <span className={classes.passwordHelper}>
      <span
        color="inherit"
        className={classes.scoreWordsStyle}
        style={{ color: colorHex }}
      >
        {text}
      </span>
      <span className={classes.helperText}>
        {passwordHelper && <AlternateEmailIcon className={classes.helperIcon} />}
        {passwordHelper}
      </span>
    </span>
  );

  return (
    <PasswordStrengthBar
      password={password}
      className={classes.strengthBar}
      shortScoreWord={renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_SHORT'))}
      scoreWords={[
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_SHORT')),
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_WEAK'), '#ef4836'),
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_ALMOST_READY'), '#f6b44d'),
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_GOOD'), '#2b90ef'),
        renderText(t('ACCOUNT_SETTINGS_PASSWORD_STRENGTH_STRONG'), '#25c281')
      ]}
    />
  );
};

PasswordStrengthComponent.propTypes = {
  password: PropTypes.string,
  passwordHelper: PropTypes.string
};

export default PasswordStrengthComponent;
