const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60
  },
  container: {
    padding: 0
  },
  loadingIndicator: {
    marginTop: 30
  },
  failTitle: {
    fontSize: 20
  },
  failMessage: {
    fontSize: 13,
    marginTop: 16,
    color: '#EB5757'
  },
  successTitle: {
    fontSize: 20
  },
  successMessage: {
    fontSize: 13,
    marginTop: 16
  },
  action: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    marginTop: 16
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

export default useStyles;
