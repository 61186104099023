import * as actionTypes from './actionTypes';

export const fetchCostCentersStart = () => ({
  type: actionTypes.FETCH_COSTCENTERS_START
});

export const fetchCostCentersSuccess = (costcenters, total) => ({
  type: actionTypes.FETCH_COSTCENTERS_SUCCESS,
  costcenters,
  total
});

export const fetchCostCentersFail = (error) => ({
  type: actionTypes.FETCH_COSTCENTERS_FAIL,
  error
});

export const fetchCostCenters = (companyID) => ({
  type: actionTypes.FETCH_COSTCENTERS,
  companyID
});
