import React from 'react';
import PropTypes from 'prop-types';

import {
  ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper
} from '@material-ui/core';

import { dimensionExportOptions } from 'src/config';

import useStyles from './style';

const DimensionExportPopper = (props) => {
  const classes = useStyles();

  const {
    exportOptionsOpen, exportBtnRef, exportOptionsClose, onOptionClick, t
  } = props;
  return (
    <Popper
      open={exportOptionsOpen}
      anchorEl={exportBtnRef.current}
      role={undefined}
      transition
      style={{ zIndex: 111111 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={exportOptionsClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {dimensionExportOptions.map((option) => (
                  <MenuItem
                    key={option.key}
                    onClick={() => onOptionClick(option.key)}
                    className={classes.menuItemBtn}
                  >
                    {t(option.label)}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

DimensionExportPopper.propTypes = {
  exportOptionsOpen: PropTypes.bool,
  exportOptionsClose: PropTypes.func,
  exportBtnRef: PropTypes.object,
  onOptionClick: PropTypes.func,
  t: PropTypes.func
};

export default DimensionExportPopper;
