import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  form: {},
  inputLabel: {
    color: '#334D6E',
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
  },
  snackAction: {
    color: 'white'
  },
  userForm: {
    width: '100%'
  },
  fieldContainer: {
    marginTop: 15
  },
  submitBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    textTransform: 'none',
    background: '#3E8AFF',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    borderRadius: 4
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    color: '#A0AEC1',
    textTransform: 'none',
    marginRight: 24
  },
  rowItem: {
    marginTop: 10,
  },
  rowItemComp: {
    width: '100%'
  },
  btnGroup: {
    width: '100%',
  },
  groupBtn: {
    color: '#334D6E',
    textTransform: 'capitalize',
    width: '100%',
    justifyContent: 'space-between',
  },
  optionTitle: {
    color: '#334D6E',
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 10,
    textTransform: 'capitalize',
  },
  optionDescription: {
    color: '#334D6E',
    fontSize: 14,
    width: '100%',
    maxWidth: 500
  },
  menuItem: {
    color: '#334D6E',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
    whiteSpace: 'break-spaces',
    maxWidth: 500
  }
}));

export default useStyles;
