import { makeStyles, alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actionSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    color: '#D5D5D5'
  },
  checkboxExportFailFilter: {
    color: '#D5D5D5',
    marginLeft: 18,
  },
  checkboxIcon: {
    width: 16,
    height: 16,
    border: '2px solid #D5D5D5',
    borderRadius: 3,
  },
  checkboxLabel: {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(51,77,110,0.5)',
  },
  bulkText: {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(51,77,110,0.5)',
    marginLeft: 15,
  },
  bulkTextLink: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    marginLeft: 6,
    cursor: 'pointer'
  },
  ublButton: {
    width: 150,
    height: 36,
    textTransform: 'none',
    marginLeft: 10,
    fontWeight: 500
  },
  bulkDelete: {
    color: '#F7685B',
  },
  deleteDuplicates: {
    color: theme.palette.statusbg.tovalidate,
  },
  bulkDownload: {
    color: '#334D6E',
    marginLeft: 8,
  },
  bulkArchieve: {
    color: '#4E342E',
  },
  groupBtn: {
    color: '#334D6E',
    textTransform: 'none',
    width: '100%',
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    borderTopWidth: 1,
    border: '0px solid #E1E4E8',
  },
  digitized: {
    color: theme.palette.status.digitized,
    backgroundColor: alpha(theme.palette.statusbg.digitized, 0.3)
  },
  toreview: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  reviewed: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  tovalidate: {
    color: theme.palette.status.tovalidate,
    backgroundColor: alpha(theme.palette.statusbg.tovalidate, 0.3)
  },
  toexport: {
    color: theme.palette.status.toexport,
    backgroundColor: alpha(theme.palette.statusbg.toexport, 0.3)
  },
  exported: {
    color: theme.palette.status.exported,
    backgroundColor: alpha(theme.palette.statusbg.exported, 0.3)
  },
  rejected: {
    color: theme.palette.status.rejected,
    backgroundColor: alpha(theme.palette.statusbg.rejected, 0.3)
  },
  archived: {
    color: theme.palette.status.archived,
    backgroundColor: alpha(theme.palette.statusbg.archived, 0.3)
  },
  outoflimit: {
    color: theme.palette.status.outoflimit,
    backgroundColor: alpha(theme.palette.statusbg.outoflimit, 0.3)
  },
  errored: {
    color: theme.palette.status.errored,
    backgroundColor: alpha(theme.palette.statusbg.errored, 0.3)
  },
  dontSplitBtn: {
    height: 36,
    textTransform: 'none',
    marginLeft: 10,
    fontSize: 13,
    minWidth: 132,
    color: '#334D6E',
  },
  formInputSelect: {
    width: 136,
    color: '#334D6E',
    fontSize: 24,
    fontWeight: 600,
    marginLeft: 10,
    height: 36
  },
  userImage: {
    flexShrink: 0,
    height: 28,
    width: 28,
    fontWeight: 500
  },
  usernameText: {
    fontSize: 15,
    lineHeight: '42.5px',
    fontWeight: 400,
    color: '#334D6E !important'
  },
  assignUserText: {
    fontSize: 15,
    lineHeight: '22.5px',
    fontWeight: 500,
    color: '#707683',
    marginTop: 2
  },
  formInputSelected: {
    width: 240,
    color: '#334D6E',
    fontSize: 24,
    fontWeight: 600,
    marginLeft: 10,
    height: 36
  },
}));

export default useStyles;
