/* eslint-disable no-confusing-arrow */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useSelector } from 'react-redux';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, Container,
  FormHelperText, Grid, TextField, Tooltip
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';

import {
  ERRORS, glAccountData, glaYearsFields, userRoles
} from 'src/config';
import Page from 'src/Shared/components/Page/Page';
import Header from 'src/Dimensions/components/Header/Header';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import { axiosHeaders, getLocalisedErrorString, validateToken } from 'src/Shared/utils/helpers';
import authService from 'src/Shared/utils/services/authService';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function EditGLAYear({
  className,
  history,
  match,
  ...rest
}) {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [beginYearFirst, setBeginYearFirst] = useState('');
  const [beginYearLast, setBeginYearLast] = useState('');
  const [endYearFirst, setEndYearFirst] = useState('');
  const [endYearLast, setEndYearLast] = useState('');
  const [loading, setLoading] = useState(false);

  const getYearsByCompany = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.glAYearsByCompany}/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setBeginYearFirst(response.data.data.beginFirst);
        setBeginYearLast(response.data.data.beginLast);
        setEndYearFirst(response.data.data.endFirst);
        setEndYearLast(response.data.data.endLast);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.accountingSystem === 'twinfield') {
      window.location.href = '/account';
    }

    getYearsByCompany();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const gotoCompanySettings = () => {
    history.push('/dimensions/settings');
  };

  const hasError = (fields, errors) => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        // Returning true until Twinfield integration
        return false;
      }
    }

    return false;
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Page
      className={classes.root}
      title="Settings - GLA Years"
    >
      <Container maxWidth="xl">
        <Grid className={classes.titleContainer}>
          <Button className={classes.backBtn} onClick={gotoCompanySettings}>
            <ArrowBackIcon className={classes.icon} />
            {ready && t('BACK_TO_COMPANY_SETTINGS')}
          </Button>
        </Grid>
        <Header title={`Settings - ${ready && t('GENERAL_LEDGER_ACCOUNT_YEARS')}`} />
        <Formik
          initialValues={{
            beginFirst: beginYearFirst || glAccountData.year[0].code,
            beginLast: beginYearLast || glAccountData.year[0].code,
            endFirst: endYearFirst || glAccountData.endYear[0].code,
            endLast: endYearLast || glAccountData.endYear[0].code,
            company: user.companyID,
          }}
          validationSchema={Yup.object().shape({
            beginFirst: Yup.number()
              .required('GLA_YEARS_START_REQUIRED')
              .integer()
              .min(1950, 'GLA_YEARS_VALID_YEAR')
              .lessThan(Yup.ref('beginLast'), 'GLA_YEARS_LESS_THAN_BEGIN_LAST'),
            beginLast: Yup.number()
              .required('GLA_YEARS_END_REQUIRED')
              .integer()
              .min(1950, 'GLA_YEARS_VALID_YEAR')
              .moreThan(Yup.ref('beginFirst'), 'GLA_YEARS_MORE_THAN_BEGIN_FIRST'),
            endFirst: Yup.number()
              .required('GLA_YEARS_START_REQUIRED')
              .integer()
              .min(1950, 'GLA_YEARS_VALID_YEAR')
              .lessThan(Yup.ref('endLast'), 'GLA_YEARS_LESS_THAN_END_LAST'),
            endLast: Yup.number()
              .required('GLA_YEARS_END_REQUIRED')
              .integer()
              .min(1950, 'GLA_YEARS_VALID_YEAR')
              .moreThan(Yup.ref('endFirst'), 'GLA_YEARS_MORE_THAN_END_FIRST'),
          })}
          onSubmit={async (values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {
              if (!validateToken()) {
                enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                  variant: 'error',
                  persist: true
                });
                setTimeout(() => {
                  authService.logout(LOGIN_PANEL_URL);
                }, 2000);
                return;
              }

              const body = {
                company: values.company,
                beginFirst: values.beginFirst.toString(),
                beginLast: values.beginLast.toString(),
                endFirst: values.endFirst.toString(),
                endLast: values.endLast.toString(),
              };

              const url = `${API.glAYearsByCompany}/${encodeURIComponent(values.company)}`;
              const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
              if (response?.data?.success) {
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar(ready && t('GLA_YEARS_UPDATE_SUCCESS'), {
                  variant: 'success',
                  persist: true,
                  style: { maxWidth: 400 }
                });
                gotoCompanySettings();
              }
            } catch (error) {
              setStatus({ success: false });
              setSubmitting(false);
              if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
                const errParts = error.response.data.i18n.split('__TFMSG__');
                setErrors({
                  [errParts[0]]: errParts[1]
                });
              } else {
                setErrors({
                  submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
                });
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setErrors,
            touched,
            values
          }) => (
            <form
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardContent style={{ padding: 32 }}>
                  <Grid container spacing={3}>
                    {
                      glaYearsFields.map((m) => (
                        <Accordion
                          key={m.title}
                          className={classes.accordionGroup}
                          defaultExpanded
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={m.title}
                            id={m.title}
                          >
                            <Grid className={classes.accordionHeading}>
                              {ready && t(m.title)}
                              {
                                hasError(m.fields, errors) && (
                                  <Chip
                                    color="error"
                                    variant="default"
                                    size="small"
                                    label="Error"
                                    className={classes.errorChip}
                                  />
                                )
                              }
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={3}>
                              {
                                m.fields.map((f) => {
                                  switch (f.key) {
                                    default:
                                      return (
                                        <Grid item lg={4} md={6} xs={12} key={f.key}>
                                          <TextField
                                            error={Boolean(touched[f.key] && errors[f.key])}
                                            fullWidth
                                            helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                            label={ready && t(f.label)}
                                            name={f.key}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onClick={() => setErrors({})}
                                            required={f.required}
                                            value={values[f.key]}
                                            variant="outlined"
                                            className={classes.inputField}
                                            type="number"
                                          />
                                        </Grid>
                                      );
                                  }
                                })
                              }
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))
                    }
                    <Grid
                      item
                      xs={12}
                    >
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <Box mt={2} display="flex" justifyContent="flex-end">
                        <Tooltip title={user.userRole.toLowerCase() !== userRoles.super ? ready && t('GLA_YEARS_PERMISSIONS') : ''}>
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={user.userRole.toLowerCase() !== userRoles.super ? () => {} : handleSubmit}
                          >
                            {ready && t('GLA_YEARS_SAVE_CHANGES')}
                          </Button>
                        </Tooltip>
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={gotoCompanySettings}
                          className={classes.yearField}
                        >
                          {ready && t('GLA_YEARS_CANCEL')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
}

EditGLAYear.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      company: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  costCenter: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  })
};

export default withRouter(EditGLAYear);
