import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import useStyles from './style';

function Header({ className, title, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Typography className={classes.headerStyle}>
        {title}
      </Typography>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

export default Header;
