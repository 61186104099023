import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from 'src/Dimensions/store/actions/costcenter';
import { axiosHeaders } from 'src/Shared/utils/helpers';

function* fetchCostCentersSaga(action) {
  yield put(actions.fetchCostCentersStart());
  try {
    const response = yield axios.get(
      `${window.config.backend.procys_service}v1/dimensions/costcenters/companies/${action.companyID}`,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    yield put(actions.fetchCostCentersSuccess(response.data.data, response.data.total));
  } catch (error) {
    yield put(actions.fetchCostCentersFail(error.response.data.message));
  }
}

export default fetchCostCentersSaga;
