import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, Container, Typography, useTheme, useMediaQuery
} from '@material-ui/core';

import Page from 'src/Shared/components/Page/Page';
import useStyles from './style';

function Error404() {
  const { t, ready } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page
      className={classes.root}
      title={t('404_TITLE')}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          {ready && t('404_PAGE_TITLE')}
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          color="textSecondary"
        >
          {ready && t('404_PAGE_DESCRIPTION')}
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="Under development"
            className={classes.image}
            src="/static/images/undraw_page_not_found_su7k.svg"
          />
        </Box>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <Button
            color="secondary"
            component={RouterLink}
            to="/"
            variant="outlined"
          >
            {ready && t('404_PAGE_BACK_HOME')}
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default Error404;
