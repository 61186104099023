import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { invoiceStatusesKeys, documentTypes } from 'src/config';
import useStyles from './style';

function LabelStatus({
  className,
  color,
  children,
  style,
  ...rest
}) {
  const classes = useStyles();

  return (
    <span
      className={
        clsx(classes.root, {
          [classes[color]]: color
        }, className)
      }
      {...rest}
    >
      {children}
    </span>
  );
}

LabelStatus.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf([
    invoiceStatusesKeys.uploading, invoiceStatusesKeys.digitized, invoiceStatusesKeys.toreview,
    invoiceStatusesKeys.tovalidate, invoiceStatusesKeys.toexport, invoiceStatusesKeys.exported,
    invoiceStatusesKeys.rejected, invoiceStatusesKeys.archived, invoiceStatusesKeys.errored,
    invoiceStatusesKeys.outOfLimit, invoiceStatusesKeys.splitRequest,
    invoiceStatusesKeys.possibleDuplicate, invoiceStatusesKeys.possibleDuplicate,
    `INVOICE_EDIT_FORM_INVOICE_TYPE_${documentTypes.invoice}`, `INVOICE_EDIT_FORM_INVOICE_TYPE_${documentTypes.creditNote}`,
    `INVOICE_EDIT_FORM_INVOICE_TYPE_${documentTypes.attachment}`, `INVOICE_EDIT_FORM_INVOICE_TYPE_${documentTypes.purchaseOrder}`,
    invoiceStatusesKeys.possibleDuplicate, invoiceStatusesKeys.duplicate,
  ])
};

LabelStatus.defaultProps = {
  className: '',
  color: 'secondary'
};

export default LabelStatus;
