import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, Card, Grid, TablePagination,
  Tooltip, IconButton, useMediaQuery, MenuItem, Menu
} from '@material-ui/core';
import {
  CardTravel as LogsIcon
} from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import { applyProjectFilters, isActionPermitted, permissions } from 'src/Shared/utils/helpers';
import useStyles from './style';

const ProjectsTable = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const canEditDimension = isActionPermitted(permissions.dimensionEdit, user.permissions);

  const {
    projects, fetching, query, isTwinfield
  } = props;

  const { ready, t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [rcX, setRCX] = useState(0);
  const [rcY, setRCY] = useState(0);
  const [rcKey, setRCKey] = useState(null);
  const [rcShow, setRCShow] = useState(false);

  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const filteredProjects = applyProjectFilters(projects, query);

  const onContextMenuClose = () => {
    setRCShow(false);
    document.oncontextmenu = () => true;
  };

  useEffect(() => {
    document.addEventListener('click', onContextMenuClose);

    return () => {
      document.removeEventListener('click', onContextMenuClose);
    };
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const paginationTab = () => (
    <TablePagination
      component="div"
      classes={{
        caption: classes.paginationCaption,
        selectRoot: classes.paginationCaption,
        menuItem: classes.paginationCaption,
        actions: classes.paginationActions,
      }}
      count={filteredProjects.length}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
    />
  );

  const gotoLogs = (event, code) => {
    event.stopPropagation();
    event.preventDefault();
    history.push({
      pathname: `/dimensions/logs/projects/code/${code}`,
      state: { code }
    });
  };

  const getContextMenu = (e, code) => {
    document.oncontextmenu = () => false;
    e.preventDefault();

    let posX = e.pageX - 16;
    if (posX + 250 > window.innerWidth) {
      posX -= 200;
    }

    setRCY(e.pageY - 448);
    setRCX(posX);
    setRCShow(true);
    setRCKey(code);
  };

  const gotoItem = (code) => {
    if (!isTwinfield) {
      history.push(`/dimensions/projects/${code}`);
    }
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        !isActionPermitted(permissions.dimensionRead, user.permissions)
          ? (
            <EmptyText str="PROJECTS_LIST_NO_PERMISSION" />
          )
          : filteredProjects && filteredProjects.length > 0 ? (
            <Card
              className={classes.cardCont}
            >
              {
                fetching
                  ? <LoadingScreenText />
                  : (
                    <Box minWidth="100%">
                      <div className={classes.bulkOperations}>
                        <div className={classes.bulkActions}>
                          {paginationTab()}
                        </div>
                      </div>
                      <Table size="small">
                        <TableBody>
                          {filteredProjects.slice(limit * page, limit * (page + 1)).map((project) => (
                            <TableRow
                              hover={canEditDimension}
                              key={project.code}
                              className={canEditDimension ? classes.pointer : classes.tableRow}
                              onClick={() => canEditDimension && gotoItem(project.code)}
                              onContextMenu={(e) => getContextMenu(e, project.code)}
                            >
                              <TableCell align="left" className={classes.creditorNameStyle}>
                                {project.name}
                              </TableCell>
                              <TableCell className={classes.todayText}>
                                {project.code}
                              </TableCell>
                              <TableCell className={classes.todayText}>
                                {project.costCenter || '-'}
                              </TableCell>
                              <Hidden smDown>
                                <TableCell width={102} align="center" className={classes.currencyText}>
                                  <Tooltip title={t('PROJECT_LIST_VIEW_LOGS_TOOLTIP')}>
                                    <IconButton
                                      onClick={(e) => gotoLogs(e, project.code)}
                                    >
                                      <LogsIcon
                                        className={classes.logsIcon}
                                        style={{ fontSize: '1.8rem' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </Hidden>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        className={classes.bottomPagi}
                      >
                        {paginationTab()}
                      </Grid>
                    </Box>
                  )
              }
              {
              rcShow && !isTwinfield && (
                <Menu
                  open={rcShow}
                  anchorEl={document.getElementById('root')}
                  style={{
                    top: rcY,
                    left: rcX,
                    position: 'absolute'
                  }}
                >
                  <MenuItem onClick={() => window.open(`/dimensions/projects/${rcKey}`, '_blank')}>
                    {ready && t('OPEN_LINK_IN_NEW_TAB')}
                  </MenuItem>
                </Menu>
              )
            }
            </Card>
          ) : (
            <EmptyText str="NO_PROJECTS" />
          )
      }
    </>
  );
};

ProjectsTable.propTypes = {
  projects: PropTypes.array,
  fetching: PropTypes.bool,
  isTwinfield: PropTypes.bool,
  query: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default ProjectsTable;
