import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import Page from 'src/Shared/components/Page/Page';
import CompaniesResults from 'src/User/components/CompaniesResults/CompaniesResults';

import useStyles from './style';

const CompaniesListView = (props) => {
  const { classes, t, tReady } = props;

  return (
    <Page
      className={classes.root}
      title={tReady && t('COMPANIES_LIST_PAGE_TITLE')}
    >
      <CompaniesResults />
    </Page>
  );
};

CompaniesListView.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
  }).isRequired,
  t: PropTypes.func,
  tReady: PropTypes.bool
};

CompaniesListView.defaultProps = {};

export default compose(withTranslation(), withStyles(useStyles))(CompaniesListView);
