/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as actionTypes from 'src/Auth/store/actions/actionTypes';

const initialState = {
  user: null,
  loading: false,
  error: null
};

const updateProfileStart = (state) => (
  produce(state, (draft) => {
    draft.loading = true;
    draft.error = null;
  })
);

const updateProfileSuccess = (state, action) => (
  produce(state, (draft) => {
    // NOTE: Do not update user
    draft.user = action.user;
    draft.loading = false;
    draft.error = null;
  })
);

const updateProfileFail = (state, action) => (
  produce(state, (draft) => {
    // NOTE: Do not update user
    draft.loading = false;
    draft.error = action.error;
  })
);

const updateProfileImageStart = (state) => (
  produce(state, (draft) => {
    draft.loading = true;
    draft.error = null;
  })
);

const updateProfileImageSuccess = (state) => (
  produce(state, (draft) => {
    // NOTE: Do not update user
    draft.loading = false;
    draft.error = null;
  })
);

const updateProfileImageFail = (state, action) => (
  produce(state, (draft) => {
    // NOTE: Do not update user
    draft.loading = false;
    draft.error = action.error;
  })
);

const updatePasswordStart = (state) => (
  produce(state, (draft) => {
    draft.loading = true;
    draft.error = null;
  })
);

const updatePasswordSuccess = (state) => (
  produce(state, (draft) => {
    // NOTE: Do not update user
    draft.loading = false;
    draft.error = null;
  })
);

const updatePasswordFail = (state, action) => (
  produce(state, (draft) => {
    // NOTE: Do not update user
    draft.loading = false;
    draft.error = action.error;
  })
);

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROFILE_START: return updateProfileStart(state, action);
    case actionTypes.UPDATE_PROFILE_SUCCESS: return updateProfileSuccess(state, action);
    case actionTypes.UPDATE_PROFILE_FAIL: return updateProfileFail(state, action);

    case actionTypes.UPDATE_PROFILE_IMAGE_START: return updateProfileImageStart(state, action);
    case actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS: return updateProfileImageSuccess(state, action);
    case actionTypes.UPDATE_PROFILE_IMAGE_FAIL: return updateProfileImageFail(state, action);

    case actionTypes.UPDATE_PASSWORD_START: return updatePasswordStart(state, action);
    case actionTypes.UPDATE_PASSWORD_SUCCESS: return updatePasswordSuccess(state, action);
    case actionTypes.UPDATE_PASSWORD_FAIL: return updatePasswordFail(state, action);

    default: {
      return state;
    }
  }
};

export default accountReducer;
