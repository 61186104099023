import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from 'src/Auth/store/actions/actionTypes';
import logoutSaga from 'src/Auth/store/sagas/auth';
import {
  updateProfileSaga, updateProfileImageSaga, updatePasswordSaga
} from 'src/Auth/store/sagas/account';

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.LOGOUT, logoutSaga)
  ]);
}

export function* watchAccount() {
  yield all([
    takeEvery(actionTypes.UPDATE_PROFILE, updateProfileSaga),
    takeEvery(actionTypes.UPDATE_PROFILE_IMAGE, updateProfileImageSaga),
    takeEvery(actionTypes.UPDATE_PASSWORD, updatePasswordSaga)
  ]);
}
