import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import moment from 'moment';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import { useLocation, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  Button, Grid, Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosNewIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosOutlined';

import Page from 'src/Shared/components/Page/Page';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';

import authService from 'src/Shared/utils/services/authService';
import { axiosHeaders, validateToken } from 'src/Shared/utils/helpers';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const InvoiceLogView = (props) => {
  const {
    classes, enqueueSnackbar, match, history
  } = props;
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const { search } = useLocation();
  const { t, ready } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [fetchingLog, setFetchingLog] = useState(true);
  const [paramsID, setParamsID] = useState(null);

  const [log, setLog] = useState(null);

  let req; // Request;
  let resp; // Response;

  const getInvoiceLogByID = async (id, invoiceID) => {
    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLog(true);

    try {
      const url = `${API.getInvoiceLogByID}/${id}?assistantId=${invoiceID ? `${invoiceID}` : ''}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data) {
            setLog(response.data.data);
          } else {
            history.push(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
          }
        } else {
          history.push(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
        }
      } else {
        history.push(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
      }
    } catch (error) {
      history.push(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
    }

    setFetchingLog(false);
    setLoading(false);
  };

  useEffect(() => {
    const id = match && match.params ? match.params.id : 0;

    const searchText = search.substr(1);
    let invoiceID = '';

    if (searchText.length > 0 && searchText.indexOf('=') > 0) {
      const searchParts = searchText.split('=');
      if (searchParts.length >= 2 && searchParts[0] === 'invoiceID') {
        invoiceID = searchParts[1]; // eslint-disable-line
      }
    }

    if (id && id > 0) {
      setParamsID(invoiceID);
      getInvoiceLogByID(id, invoiceID);
    } else {
      history.push(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const gotoLogsList = () => {
    history.push(`/documents${paramsID ? `/${paramsID}` : ''}/logs`);
  };

  if (loading || fetchingLog) {
    return <LoadingScreenText />;
  }

  try {
    req = JSON.stringify(JSON.parse(log.request), null, 2);
  } catch (err) {
    req = log.request;
  }

  try {
    resp = JSON.stringify(JSON.parse(log.response), null, 2);
  } catch (err) {
    resp = log.response;
  }

  return (
    <Page
      className={classes.root}
      title={ready && t('INVOICE_LOGS_PAGE_TITLE')}
    >
      {
        log && ready && (
          <>
            <Grid className={classes.titleContainer}>
              <Grid>
                <Button className={classes.backBtn} onClick={gotoLogsList}>
                  <ArrowBackIcon className={classes.icon} />
                  {t('LOGS_VIEW_BACK_TO_OVERVIEW')}
                </Button>
              </Grid>
              <Typography className={classes.assistantID}>{`${t('LOGS_VIEW_PROCYS_NUMBER')}: ${log.assistantID}`}</Typography>
              <Grid className={classes.titleBox}>
                <Typography className={classes.title}>{`${t('LOGS_VIEW_INVOICE_NUMBER')}: ${log.invoiceID}`}</Typography>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid className={classes.arrowBtn}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    component="label"
                    disabled={log.nextId === undefined || log.nextId === null}
                    className={classes.action}
                    onClick={() => getInvoiceLogByID(log.nextId, paramsID)}
                  >
                    <ArrowBackIosNewIcon fontSize="small" />
                  </Button>
                </Grid>
                <Grid className={classes.arrowBtn}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    component="label"
                    disabled={log.prevId === undefined || log.prevId === null}
                    className={classes.action}
                    onClick={() => getInvoiceLogByID(log.prevId, paramsID)}
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.topContainer}>
              <Grid className={classes.logViewContainer}>
                <Grid className={classes.labelsContainer}>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>#</Typography>
                    <Typography className={classes.value}>{log.id || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_NUMBER')}</Typography>
                    <Typography className={classes.value}>{log.invoiceID || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOGS_VIEW_PROCYS_NUMBER')}</Typography>
                    <Typography className={classes.value}>{log.assistantID || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_COMPANY')}</Typography>
                    <Typography className={classes.value}>{log.company || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_MEDIUM')}</Typography>
                    <Typography className={classes.value}>{log.medium || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_ACTION')}</Typography>
                    <Typography className={classes.value}>{log.action || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_MODIFIED_BY')}</Typography>
                    <Typography className={classes.value}>
                      {
                        log.firstname && log.lastname
                          ? `${log.firstname} ${log.lastname}`
                          : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_MODIFIED_AT')}</Typography>
                    <Typography className={classes.value}>
                      {
                        log.timestamp
                          ? moment.unix(log.timestamp / 1000000000).format('DD/MM/YYYY hh:mm:ss')
                          : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_URL')}</Typography>
                    <Typography className={classes.value}>{log.url || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_REQUEST')}</Typography>
                    <Typography className={classes.value}>{<pre className={classes.xmlView}>{req}</pre> || '-'}</Typography>
                  </Grid>
                  <Grid className={classes.itemContainer}>
                    <Typography className={classes.label}>{t('LOG_VIEW_INVOICE_RESPONSE')}</Typography>
                    <Typography className={classes.value}>{<pre className={classes.xmlView}>{resp}</pre> || '-'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
    </Page>
  );
};

InvoiceLogView.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    topContainer: PropTypes.string,
    logViewContainer: PropTypes.string,
    labelsContainer: PropTypes.string,
    itemContainer: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    titleContainer: PropTypes.string,
    backBtn: PropTypes.string,
    icon: PropTypes.string,
    assistantID: PropTypes.string,
    subtitle: PropTypes.string,
    titleBox: PropTypes.string,
    title: PropTypes.string,
    infoBox: PropTypes.string,
    chipLabel: PropTypes.string,
    chipAvatar: PropTypes.string,
    userChip: PropTypes.string,
    xmlView: PropTypes.string,
    action: PropTypes.string,
    arrowBtn: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    company: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  enqueueSnackbar: PropTypes.func,
};

InvoiceLogView.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = () => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(withSnackbar(withRouter(InvoiceLogView)));
