/* eslint-disable */
import * as actionTypes from 'src/Dashboard/store/actions/actionTypes';
import { updateObject } from 'src/Shared/utils/helpers';

const initialiState = {
  invoices: null,
  overviewStats: {
    userStats: [],
    labels: [],
    dataDigitized: [],
    dataUploaded: [],
    dataUploading: [],
    dataToreview: [],
    dataTovalidate: [],
    dataToexport: [],
    dataExported: [],
    dataErrored: [],

    digitizedTot: 0,
    uploadedTot: 0,
    uploadingTot: 0,
    toreviewTot: 0,
    tovalidateTot: 0,
    toexportTot: 0,
    exportedTot: 0,
    erroredTot: 0
  },
  loading: false,
  error: ''
};

const getInvoiceStatsByUserStart = (state) => {
  return updateObject(state, {
    loading: false,
    invoices: null,
    error: ''
  });
};

const getInvoiceStatsByUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    invoices: action.invoices,
    overviewStats: action.overviewStats,
    error: ''
  });
};

const getInvoiceStatsByUserFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    invoices: null,
    overviewStats: {
      userStats: [],
      labels: [],
      dataDigitized: [],
      dataUploaded: [],
      dataUploading: [],
      dataToreview: [],
      dataTovalidate: [],
      dataToexport: [],
      dataExported: [],
      dataErrored: [],

      digitizedTot: 0,
      uploadedTot: 0,
      uploadingTot: 0,
      toreviewTot: 0,
      tovalidateTot: 0,
      toexportTot: 0,
      exportedTot: 0,
      erroredTot: 0
    },
    error: action.error
  });
};

const getInvoiceAppStatsByUserStart = (state) => {
  return updateObject(state, {
    loading: false,
    invoices: null,
    error: ''
  });
};

const getInvoiceAppStatsByUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    invoices: action.invoices,
    overviewStats: action.overviewStats,
    error: ''
  });
};

const getInvoiceAppStatsByUserFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    invoices: null,
    overviewStats: {
      userStats: [],
      labels: [],
      dataDigitized: [],
      dataUploaded: [],
      dataUploading: [],
      dataToreview: [],
      dataTovalidate: [],
      dataToexport: [],
      dataExported: [],
      dataErrored: [],

      digitizedTot: 0,
      uploadedTot: 0,
      uploadingTot: 0,
      toreviewTot: 0,
      tovalidateTot: 0,
      toexportTot: 0,
      exportedTot: 0,
      erroredTot: 0
    },
    error: action.error
  });
};

const reducer = (state = initialiState, action) => {
  switch (action.type) {
    case actionTypes.INVOICE_STATS_BY_USER_START:
      return getInvoiceStatsByUserStart(state);
    case actionTypes.INVOICE_STATS_BY_USER_SUCCESS:
      return getInvoiceStatsByUserSuccess(state, action);
    case actionTypes.INVOICE_STATS_BY_USER_FAIL:
      return getInvoiceStatsByUserFail(state, action);
    case actionTypes.INVOICE_APP_STATS_BY_USER_START:
      return getInvoiceAppStatsByUserStart(state);
    case actionTypes.INVOICE_APP_STATS_BY_USER_SUCCESS:
      return getInvoiceAppStatsByUserSuccess(state, action);
    case actionTypes.INVOICE_APP_STATS_BY_USER_FAIL:
      return getInvoiceAppStatsByUserFail(state, action);

    default:
      return state;
  }
};

export default reducer;
