import React, { useContext, useEffect, useState } from 'react';
import { matchPath, useLocation, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';
import compose from 'recompose/compose';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import AnnounceKit from 'announcekit-react';
import i18n from 'i18next';

import {
  AppBar, Toolbar, ListSubheader, List, Box, Avatar, Grid, MenuItem, Button, Menu,
  SvgIcon, Typography, useMediaQuery, IconButton, Tooltip, Badge,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import PublishIcon from '@material-ui/icons/PublishOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SuppliersIcon from '@material-ui/icons/PeopleAltOutlined';
import GLAIcon from '@material-ui/icons/MenuBook';
import VATCodeIcon from '@material-ui/icons/PollOutlined';
import CostCenterIcon from '@material-ui/icons/Money';
import HelpCenterIcon from '@material-ui/icons/HelpOutlineRounded';
// import ProjectsIcon from '@material-ui/icons/NextWeekOutlined';
// import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import * as actions from 'src/Auth/store/actions/auth';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import NavItem from 'src/Shared/layouts/DashboardLayout/NavBar/NavItem/NavItem';
import { navConfig, navConfigCompany } from 'src/Shared/layouts/DashboardLayout/config';
import { externalLinks } from 'src/config';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function renderNavItems({ items, toggleMenu, ...rest }) {
  return (
    <List disablePadding style={{ display: 'flex', flexDirection: 'row' }}>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, toggleMenu, ...rest }), // eslint-disable-line
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  toggleMenu,
  depth = 0
}) {
  const key = item.title + depth;
  const open = matchPath(pathname, { path: item.href, exact: false });

  if (item.items) {
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        open={Boolean(open)}
        toggleMenu={(e) => toggleMenu(e)}
      />
    );
  }

  return acc;
}

function TopBar({
  onLogout, history
  // overviewStats,
}) {
  const {
    BRAND_DOMAIN, IS_BRAND_PROCYS, API, APP_VERSION, ENVIRONMENT
  } = useContext(ConfigContext);
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const navigations = navConfig;

  const matches = useMediaQuery('(min-width: 1160px)');
  // const matchesSmaller = useMediaQuery('(min-width: 1300px)');
  const matchesSubs = useMediaQuery('(min-width: 1160px)');
  const matchesNavMin = useMediaQuery('(min-width: 900px)');
  const matchesNavMax = useMediaQuery('(max-width: 899px)');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElDim, setAnchorElDim] = React.useState(null);

  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(user.companyID);
  const [openOfficeMenu, setOpenOfficeMenu] = useState(false);
  const [anchorElPropover, setAnchorElPopover] = useState(null);

  const isPassportOrID = user.documentType === 'id';

  const handlePopoverOpen = (event) => {
    setOpenOfficeMenu(true);
    setAnchorElPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenOfficeMenu(false);
    setAnchorElPopover(null);
  };

  const changeOffice = async (name) => {
    try {
      const response = await Axios.get(
        `${API.changeCompany}/${encodeURIComponent(name)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_CHANGE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        if (window.location.pathname.indexOf('documents/') >= 0) {
          window.location.href = '/documents';
        } else {
          window.location.reload();
        }
      }
    } catch (e) {
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(e?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_CHANGE_FAILURE', t),
        t
      ), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleSelectOffice = (office) => {
    // CHECK THIS
    if (office.companyID !== selectedOffice) {
      setSelectedOffice(office.companyID);
      changeOffice(office.companyID);
    }
    handlePopoverClose();
  };

  const getOffices = async () => {
    try {
      const response = await Axios.get(
        `${API.getCompaniesByUser}${user.email}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          const officesResp = response.data.data;
          officesResp.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            return -1;
          });

          const listOffices = [];
          for (let i = 0; i < officesResp.length; i++) {
            const office = officesResp[i];
            if (office?.companyID?.toString() !== user.companyID && office.isMember) {
              listOffices.push(office);
            }
          }

          setOffices(listOffices);
        }
      }
    } catch (e) {
      //
    }
  };

  useEffect(() => {
    if (user && user.role === 'user') {
      getOffices();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDimMenu = (event) => {
    setAnchorElDim(event ? event.currentTarget : null);
  };

  const handleNavigateChild = (href) => {
    toggleDimMenu();

    // if (href.indexOf('glaccounts') >= 0) {
    //   window.location.href = href;
    //   return;
    // }

    history.push(href);
  };

  const handleNavigate = (href) => {
    handleClose();
    history.push(href);
  };

  const clickOnSubscription = () => {
    if (((user.isSubCompany || user.isPartnerSubCompany) && user.parent) || user.customisations.includes('hideBilling')) {
      return;
    }
    history.push('/account/subscription');
  };

  const getLogoClass = () => {
    if (IS_BRAND_PROCYS) {
      return 'procys';
    }

    if (BRAND_DOMAIN === 'docuindexa.net') {
      return 'brandLogoWithTop';
    }

    return 'brandLogo';
  };

  const renderDropdownNav = (href, title) => (
    <MenuItem
      className={window.location.href.indexOf(href) >= 0 ? classes.menuActionContainerSelected : classes.menuActionContainer}
      onClick={() => handleNavigate(href)}
    >
      {ready && t(title)}
    </MenuItem>
  );

  const renderDropdownNavChild = (href, title, icon) => (
    <MenuItem
      className={window.location.href.indexOf(href) >= 0 ? classes.menuActionContainerSelected : classes.menuActionContainer}
      onClick={() => handleNavigateChild(href)}
    >
      {icon}
      {ready && t(title)}
    </MenuItem>
  );

  const renderSubStatItem = (icon, stat) => (
    <Grid className={classes.headerSubStats}>
      {icon}
      <Typography className={classes.subStatValue}>
        {stat}
      </Typography>
    </Grid>
  );

  const renderProfileMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      elevation={0}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        paper: classes.menuContainer
      }}
    >
      <Grid
        className={classes.userInfoMenuitem}
        onClick={() => handleNavigate('/account')}
      >
        {
          user.avatar
            ? (
              <img
                alt="user"
                className={classes.avatar}
                src={user.avatar}
              />
            )
            : <Avatar className={classes.avatar} />
        }
        <Grid>
          <Typography className={classes.menuName}>
            {`${user?.firstname} ${user?.lastname}`}
          </Typography>
          <Typography className={classes.menuEmail}>
            {user?.email}
          </Typography>
          {
            user.role === 'user' && (
              <MenuItem
                className={classes.menuActionContainerTop}
                onClick={() => handleNavigate('/account')}
              >
                {ready && t('DASHBOARD_NAV_ACCOUNT_SETTING')}
              </MenuItem>
            )
          }
        </Grid>
      </Grid>
      <Grid
        className={classes.menuCompanyContainer}
        aria-label="offices"
        aria-controls="office-menu"
        aria-haspopup="true"
        onClick={handlePopoverOpen}
      >
        {user.role !== 'company' && <ChevronLeftIcon className={classes.moreIcon} />}
        <Grid className={classes.companyItems}>
          <Typography noWrap className={classes.menuCompanyName}>
            {user?.company}
          </Typography>
          <Typography className={classes.menuCompany}>
            {user?.companyID && `${ready && t('TOPBAR_USERMENU_ID')}: ${user?.companyID}`}
          </Typography>
          <Typography className={classes.menuCompany}>
            {user?.vatNumber && `${ready && t('TOPBAR_USERMENU_VAT')}: ${user?.vatNumber}`}
          </Typography>
          {
            user.role === 'user' && (
              <Typography className={classes.menuCompany}>
                {`${ready && t('TOPBAR_USERMENU_OFFICE')}: ${selectedOffice}`}
              </Typography>
            )
          }
          {/* <Typography className={classes.menuCompany}>
            {user?.ocrEmail && `${ready && t('TOPBAR_USERMENU_OCR_EMAIL')}: ${user?.ocrEmail}`}
          </Typography> */}
        </Grid>
      </Grid>
      {
        offices && offices.length >= 1 && (
          <Menu
            id="office-menu"
            anchorEl={anchorElPropover}
            keepMounted
            open={openOfficeMenu}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{
              paper: classes.paper,
              list: classes.officeMenuPadding
            }}
          >
            {
              offices.map((office, i) => {
                if (office?.companyID?.toString() !== user.companyID && office.isMember) {
                  return (
                    <MenuItem
                      key={office.name}
                      className={
                        i === 0
                          ? classes.menuActionContainerTop
                          : classes.menuActionContainer
                      }
                      onClick={() => handleSelectOffice(office)}
                    >
                      {office.name}
                    </MenuItem>
                  );
                }

                return null;
              })
            }
          </Menu>
        )
      }
      {matchesNavMax && user.role === 'user' && renderDropdownNav('/dashboard', 'DASHBOARD_NAV_DASHBOARD')}
      {matchesNavMax && user.role === 'user' && renderDropdownNav('/documents', 'DASHBOARD_NAV_INVOICES')}
      {matchesNavMax && user.role === 'user' && !isPassportOrID && renderDropdownNav('/dimensions/suppliers', 'DASHBOARD_NAV_SUPPLIERS')}
      {matchesNavMax && user.role === 'user' && renderDropdownNav('/integrations', 'DASHBOARD_NAV_APPS')}
      {user.role === 'user' && !user.customisations.includes('hideBilling') && renderDropdownNav('/account/subscription', 'DASHBOARD_NAV_PLAN_N_BILLING')}
      <Grid className={classes.logoutMenuItem}>
        <Typography className={classes.menuLogout} onClick={onLogout}>
          {ready && t('DASHBOARD_ACTION_LOGOUT')}
        </Typography>
        <Typography className={classes.version}>
          {APP_VERSION}
        </Typography>
      </Grid>
    </Menu>
  );

  const renderDimensionsMenu = () => (
    <Menu
      id="dimensions-menu"
      anchorEl={anchorElDim}
      elevation={0}
      getContentAnchorEl={null}
      open={Boolean(anchorElDim)}
      onClose={() => toggleDimMenu()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        paper: classes.menuContainer,
        list: classes.menuList
      }}
    >
      {
        !isPassportOrID && renderDropdownNavChild('/dimensions/suppliers', 'DASHBOARD_NAV_SUPPLIERS', <SuppliersIcon className={classes.subMenuIcon} />)
      }
      {/* {
        user.accountingSystem !== 'twinfield'
          && renderDropdownNavChild('/dimensions/glaccounts/bas', 'DASHBOARD_NAV_GLACCOUNTS_BAL', <GLAIcon className={classes.subMenuIcon} />)
      } */}
      {
        !isPassportOrID && user.interfacePreference !== 'twinfield_interface'
          && renderDropdownNavChild('/dimensions/glaccounts', 'DASHBOARD_NAV_GLACCOUNTS_PNL', <GLAIcon className={classes.subMenuIcon} />)
      }
      {
        !isPassportOrID && user.interfacePreference !== 'twinfield_interface'
          && renderDropdownNavChild('/dimensions/vatcodes', 'DASHBOARD_NAV_VAT_CODES', <VATCodeIcon className={classes.subMenuIcon} />)
      }
      {
        !isPassportOrID && user.interfacePreference !== 'twinfield_interface'
          && renderDropdownNavChild('/dimensions/costcenters', 'DASHBOARD_NAV_COST_CENTERS', <CostCenterIcon className={classes.subMenuIcon} />)
      }
      {/* {
        user.accountingSystem !== 'twinfield'
          && renderDropdownNavChild('/dimensions/projects', 'DASHBOARD_NAV_PROJECTS', <ProjectsIcon className={classes.subMenuIcon} />)
      } */}
      {/* {
        user.accountingSystem !== 'twinfield'
          && renderDropdownNavChild('/dimensions/settings', 'DASHBOARD_NAV_DIM_SETTINGS', <SettingsIcon className={classes.subMenuIcon} />)
      } */}
    </Menu>
  );

  const renderAnnounceKitNotifications = () => ENVIRONMENT !== 'prod' && (
    <AnnounceKit widget="https://announcekit.co/widgets/v2/qewhO">
      <IconButton className={classes.notificationIcon}>
        <NotificationsNoneIcon />
      </IconButton>
    </AnnounceKit>
  );

  // i18n.language.split('-')[0] !== 'en' ? i18n.language.split('-')[0] : ''

  const renderKBLink = () => IS_BRAND_PROCYS && (
    <Tooltip title={t('DASHBOARD_NAV_KB_LINK')}>
      <IconButton
        className={classes.kbIcon}
        onClick={() => window.open(
          externalLinks.knoldgeBase.replace('__LANG__', i18n.language.split('-')[0] !== 'en' ? `/${i18n.language.split('-')[0]}` : ''), '_blank'
        )}
      >
        <HelpCenterIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <AppBar
      className={classes.root}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.navContainer}>
          {
            matchesNavMax && (
              <>
                <IconButton aria-controls="menu-icon" aria-haspopup="true" onClick={handleClick}>
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
                {renderProfileMenu()}
                {renderKBLink()}
                {renderAnnounceKitNotifications()}
              </>
            )
          }
          <Badge
            classes={{
              badge: `${classes.badgeTopBar}
              ${user.subscription.toLowerCase() === 'free' && classes.badgeTopBeta}
              ${user.subscription.toLowerCase() === 'starter' && classes.badgeTopBronze}
              ${user.subscription.toLowerCase() === 'business' && classes.badgeTopSilver}
              ${user.subscription.toLowerCase() === 'custom' && classes.badgeTopGold}
              `,
              root: classes.logoBadgeRoot,
              anchorOriginBottomLeftRectangle: matchesNavMax
                ? classes.anchorOriginBottomLeftRectangleMax
                : classes.anchorOriginBottomLeftRectangle
              // Depricated, but the new property doesn't work
            }}
            color="error"
            badgeContent={user.subscription}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <img
              src={`${API.brandLogo}${BRAND_DOMAIN}`}
              alt=""
              className={classes[getLogoClass()]}
            />
          </Badge>
          {
            user.role === 'company' && navConfigCompany.map((config) => (
              <List
                key={config.subheader}
                className={classes.parentList}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {ready && t(config.subheader)}
                  </ListSubheader>
                )}
              >
                {renderNavItems({ items: config.items, pathname: location.pathname })}
              </List>
            ))
          }
          {
            matchesNavMin && user.role === 'user' && navigations.map((config) => (
              <List
                key={config.subheader}
                className={classes.parentList}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {ready && t(config.subheader)}
                  </ListSubheader>
                )}
              >
                {
                  renderNavItems({
                    items: config.items.filter((item) => !(item.href === '/dimensions' && isPassportOrID)),
                    pathname: location.pathname,
                    toggleMenu: (e) => toggleDimMenu(e)
                  })
                }
                {renderDimensionsMenu()}
              </List>
            ))
          }
        </div>
        {
          // location.pathname === '/dashboard' &&
          <Grid style={{ flex: 1 }} />
        }
        {
          location.pathname !== '/dashboard' && matches && user.role === 'user' && (
            <Grid className={classes.subscriptionContainer}>
              <Tooltip
                title="Now you are logged in to a sub-company, please log in to a main company to go to my plan page."
                disableHoverListener={!((user.isSubCompany || user.isPartnerSubCompany) && user.parent)}
              >
                <Typography
                  className={classes.subscriptionName}
                  component={Button}
                  onClick={clickOnSubscription}
                >
                  {user.subscription}
                </Typography>
              </Tooltip>
              {renderSubStatItem(
                <Tooltip title={
                  user.maxInvoices > 0
                    ? `${user.invoicesCount || 0} documents uploaded out of the invoice limit of ${user.maxInvoices || 0}`
                    : `${user.invoicesCount || 0} documents uploaded`
                  }
                >
                  <button type="button" className={classes.subscriptionName}>
                    <Grid container direction="row">
                      <ReceiptIcon className={classes.subsIcon} />
                      <span>
                        {
                          user.maxInvoices > 0
                            ? `${user.invoicesCount || 0}/${user.maxInvoices || 0}`
                            : `${user.invoicesCount || 0}`
                        }
                      </span>
                    </Grid>
                  </button>
                </Tooltip>
              )}
              {renderSubStatItem(
                <Tooltip title={
                  `${user.usersCount || 0} users signed up out of the subscription limit of ${user.maxUsers || 0}`
                  }
                >
                  <button type="button" className={classes.subscriptionName}>
                    <Grid container direction="row">
                      <PeopleIcon className={classes.subsIcon} />
                      <span>
                        {`${user.usersCount || 0}/${user.maxUsers || 0}`}
                      </span>
                    </Grid>
                  </button>
                </Tooltip>
              )}
            </Grid>
          )
        }
        {
          location.pathname === '/dashboard' && matchesSubs && user.role === 'user' && (
            <Grid className={classes.subscriptionContainer}>
              <Tooltip
                title="Now you are logged in to a sub-company, please log in to a main company to go to my plan page."
                disableHoverListener={!((user.isSubCompany || user.isPartnerSubCompany) && user.parent)}
              >
                <Typography
                  className={classes.subscriptionName}
                  component={Button}
                  onClick={clickOnSubscription}
                >
                  {user.subscription}
                </Typography>
              </Tooltip>
              {renderSubStatItem(
                <Tooltip title={
                  user.maxInvoices > 0
                    ? `${user.invoicesCount || 0} documents uploaded out of the invoice limit of ${user.maxInvoices || 0}`
                    : `${user.invoicesCount || 0} documents uploaded`
                  }
                >
                  <button type="button" className={classes.subscriptionName}>
                    <Grid container direction="row">
                      <ReceiptIcon className={classes.subsIcon} />
                      <span>
                        {
                          user.maxInvoices > 0
                            ? `${user.invoicesCount || 0}/${user.maxInvoices || 0}`
                            : `${user.invoicesCount || 0}`
                        }
                      </span>
                    </Grid>
                  </button>
                </Tooltip>
              )}
              {renderSubStatItem(
                <Tooltip title={
                  `${user.usersCount || 0} users signed up out of the subscription limit of ${user.maxUsers || 0}`
                  }
                >
                  <button type="button" className={classes.subscriptionName}>
                    <Grid container direction="row">
                      <PeopleIcon className={classes.subsIcon} />
                      <span>
                        {`${user.usersCount || 0}/${user.maxUsers || 0}`}
                      </span>
                    </Grid>
                  </button>
                </Tooltip>
              )}
            </Grid>
          )
        }
        <Box className={classes.rightContainer}>
          {/* {
            matchesSmaller && location.pathname !== '/dashboard' && (
              <Grid className={classes.headerStatsContainer}>
                {renderStatItem(
                  <ErrorOutlineIcon className={classes.statIconError} />,
                  overviewStats.erroredTot || 0
                )}
                {renderStatItem(
                  <PublishIcon className={classes.statIcon} />,
                  overviewStats.uploadingTot || 0
                )}
                {renderStatItem(
                  <BsDownload className={classes.statIcon} />,
                  overviewStats.digitizedTot || 0
                )}
                {renderStatItem(
                  <VisibilityIcon className={classes.statIcon} />,
                  overviewStats.toreviewTot || 0
                )}
                {renderStatItem(
                  <AiOutlinePlayCircle className={classes.statIcon} />,
                  overviewStats.tovalidateTot || 0
                )}
                {renderStatItem(
                  <BsCheckBox className={classes.statIcon} />,
                  overviewStats.toexportTot || 0
                )}
                {renderStatItem(
                  <BsUpload className={classes.statIcon} />,
                  overviewStats.exportedTot || 0
                )}
              </Grid>
            )
          } */}
          {/* <IconButton>
            <Badge color="primary" variant="dot" overlap="circle" className={classes.badge}>
              <NotificationsNoneIcon className={classes.notificationIcon} />
            </Badge>
          </IconButton> */}
          {
            matchesNavMin && (
              <>
                {renderKBLink()}
                {renderAnnounceKitNotifications()}
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  classes={{
                    label: classes.profileBtn
                  }}
                >
                  <Grid className={classes.profileBtnRoot}>
                    <Grid className={classes.btnRow}>
                      {
                        user.avatar
                          ? (
                            <img
                              alt="user"
                              className={classes.avatar}
                              src={user.avatar}
                            />
                          )
                          : <Avatar className={classes.avatar} />
                      }
                      <SvgIcon fontSize="small">
                        <ExpandMoreIcon className={classes.icon} />
                      </SvgIcon>
                    </Grid>
                    <Typography noWrap className={classes.companyName}>{user.company}</Typography>
                  </Grid>
                </Button>
                {renderProfileMenu()}
              </>
            )
          }
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  overviewStats: PropTypes.shape({
    erroredTot: PropTypes.number,
    uploadingTot: PropTypes.number,
    digitizedTot: PropTypes.number,
    toreviewTot: PropTypes.number,
    tovalidateTot: PropTypes.number,
    toexportTot: PropTypes.number,
    exportedTot: PropTypes.number,
  }),
  history: PropTypes.shape(),
  onLogout: PropTypes.func,
};

renderNavItems.propTypes = {
  items: PropTypes.array,
  toggleMenu: PropTypes.func,
};

const mapStateToProps = (state) => ({
  overviewStats: state.dashboard.overviewStats,
  loading: state.dashboard.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.logout()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(TopBar));
