import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: 0
  },
  headerStyle: {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(51, 77, 110, 0.5)',
    align: 'center'
  },
  searchBar: {
    flex: 3,
    marginRight: 24,
    marginTop: 12,
    marginBottom: 12,
  },
  searchBarInput: {
    height: 36,
    width: '100%',
  },
  searchInput: {
    '&::placeholder': {
      color: '#90A0B7',
      fontSize: 14,
      lineHeight: '21px'
    }
  },
  arrow: {
    paddingLeft: 4
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px',
    marginRight: 24
  },
  action: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)'
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  checkbox: {
    width: 16,
    height: 16,
    color: '#D5D5D5',
    paddingLeft: 17
  },
  paginationCaption: {
    fontSize: 13,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  paginationMenu: {
    fontSize: 13,
    color: '#334D6E'
  },
  paginationToolbar: {
    paddingRight: 15
  },
  caption: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: 'rgba(51, 77, 110, 0.5)',
    paddingLeft: 24
  },
  paginationComp: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  paginationActions: {
    marginLeft: 0
  },
}));

export default useStyles;
