/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useRef, useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button, Grid, InputAdornment, SvgIcon, TextField, Tooltip, Typography,
  IconButton, useMediaQuery
} from '@material-ui/core';
import LogsIcon from '@material-ui/icons/CardTravel';
import AddCircle from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import ImportIcon from '@material-ui/icons/SystemUpdateAlt';
import ExportIcon from '@material-ui/icons/OpenInBrowser';

import Page from 'src/Shared/components/Page/Page';
import SuppliersTable from 'src/Suppliers/components/SuppliersTable/SuppliersTable';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import {
  appendContactSupport,
  axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions
} from 'src/Shared/utils/helpers';

import CreateSupplierModal from 'src/Suppliers/components/CreateSupplierModal/CreateSupplierModal';
import EditSupplierModal from 'src/Suppliers/components/EditSupllierModal/EditSupplierModal';
import DimensionImportModal from 'src/Dimensions/components/DimensionImportModal/DimensionImportModal';
import DimensionExportPopper from 'src/Dimensions/components/DimensionExportPopper/DimensionExportPopper';
import { fetchGlas } from 'src/Dimensions/store/actions/gla';
import { fetchVatCodes } from 'src/Dimensions/store/actions/vatcode';
import { fetchCostCenters } from 'src/Dimensions/store/actions/costcenter';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function SuppliersList() {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [pageNo, setPageNo] = useState(0);

  const [tfError, setTFError] = useState(false);
  const [addSupplierOpen, setAddSupplierOpen] = useState(false);

  const [editSupplierOpen, setEditSupplierOpen] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState(-1);

  const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
  const exportBtnRef = useRef(null);

  const [importModalOpen, setImportModalOpen] = useState(false);

  const maxWidth = useMediaQuery('(min-width: 830px)');
  const maxWidth2 = useMediaQuery('(min-width: 530px)');

  const getAllGLAccounts = async () => {
    dispatch(fetchGlas(user.companyID));
  };

  const getAllVatGroups = () => {
    dispatch(fetchVatCodes(user.companyID));
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(user.companyID));
  };

  const getAllSuppliers = async (page = 0, limit = 10, search = query, loadFullScreen = true) => {
    if (loadFullScreen) {
      setLoading(true);
    }
    setFetching(true);
    setTFError(false);
    try {
      const response = await Axios.get(
        `${API.findSuppliers}/${user.companyID}?page=${page + 1}&limit=${limit}&search=${search}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setSuppliers(response.data.data);
          setTotal(response.data.total);
        } else {
          setSuppliers([]);
          setTotal(0);
        }
      }
      setLoading(false);
      setFetching(false);
    } catch (error) {
      setTFError(true);
      setLoading(false);
      setFetching(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user.permissions)) {
      getAllSuppliers();
      getAllGLAccounts();
      getAllVatGroups();
      getAllCostCenters();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSupplierCreated = () => {
    getAllSuppliers();
    setAddSupplierOpen(false);
  };

  const handleEditSupplierOpen = (id) => {
    setSelectedSupplierId(id);
    setEditSupplierOpen(true);
  };

  const handleEditSupplierClose = () => {
    setSelectedSupplierId(-1);
    setEditSupplierOpen(false);
  };

  const handleQueryChange = (e) => {
    e.persist();
    setQuery(e.target.value);
    setPageNo(0);
    getAllSuppliers(0, pageLimit, e.target.value, false);
  };

  const exportOptionsClose = (event) => {
    if (exportBtnRef.current && exportBtnRef.current.contains(event.target)) {
      return;
    }
    setExportOptionsOpen(false);
  };

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  const handleDimensionExport = async (format) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}suppliers/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user.company} Suppliers.${format}`;
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      setExportOptionsOpen(false);
    } catch (error) {
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'DIMENSION_EXPORT_FAIL', t),
        t
      ), {
        variant: 'error',
        persist: true
      });
    }
  };

  const searchBar = (
    <Grid className={clsx(classes.searchBar, 'search-bar')}>
      <Tooltip title={t('DASHBOARD_SEARCH_SUPPLIER_SEARCH_TOOLTIP')}>
        <TextField
          className={classes.queryField}
          onChange={handleQueryChange}
          placeholder={ready && t('DASHBOARD_SEARCH_SUPPLIER_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: '#C2CFE0' }}
                >
                  <SearchIcon style={{ color: '#C2CFE0' }} />
                </SvgIcon>
              </InputAdornment>
            ),
            classes: {
              input: classes.searchInput,
              root: classes.searchBarInput
            }
          }}
          disabled={tfError}
        />
      </Tooltip>
    </Grid>
  );

  const logsList = (
    <Tooltip title={t('SUPPLIER_LIST_VIEW_LOGS_TOOLTIP')}>
      <IconButton
        className={classes.logsIconContainer}
        onClick={() => history.push(`/dimensions/logs/suppliers/company/${user.companyID}`)}
      >
        <LogsIcon
          className={classes.logsIcon}
          style={{ fontSize: '1.8rem' }}
        />
      </IconButton>
    </Tooltip>
  );

  const titleText = (
    <Typography className={classes.title}>
      {ready && t('DASHBOARD_SUPPLIERS')}
    </Typography>
  );

  const addButton = (
    <Tooltip
      title={isActionPermitted(permissions.dimensionAdd, user.permissions)
        ? ready && t('SUPPLIERS_ADD_A_NEW_SUPPLIER')
        : 'You do not have permissions to add Suppliers'}
    >
      <span>
        <Button
          color="secondary"
          type="submit"
          variant="contained"
          className={classes.addBtn}
          onClick={() => setAddSupplierOpen(true)}
          disabled={tfError || !isActionPermitted(permissions.dimensionAdd, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <AddCircle />
          </SvgIcon>
          {ready && t('SUPPLIERS_ADD_NEW_SUPPLIER')}
        </Button>
      </span>
    </Tooltip>
  );

  const importBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : ''}
    >
      <span>
        <Button
          variant="outlined"
          className={classes.importExportBtn}
          onClick={() => setImportModalOpen(true)}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            className={classes.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ImportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_IMPORT_BTN')}
        </Button>
      </span>
    </Tooltip>
  );

  const exportBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : suppliers.length === 0
          ? ready && t('SUPPLIER_NO_DATA_TO_EXPORT')
          : ''}
    >
      <span>
        <Button
          ref={exportBtnRef}
          variant="outlined"
          className={classes.importExportBtn}
          onClick={() => { setExportOptionsOpen(true); }}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions) || suppliers.length === 0}
        >
          <SvgIcon
            fontSize="small"
            className={classes.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ExportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_EXPORT_BTN')}
        </Button>
        <DimensionExportPopper
          exportOptionsOpen={exportOptionsOpen}
          exportBtnRef={exportBtnRef}
          exportOptionsClose={exportOptionsClose}
          t={t}
          onOptionClick={handleDimensionExport}
        />
      </span>
    </Tooltip>
  );

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <Page
      className={classes.root}
      title={t('SUPPLIERS_LIST_TITLE')}
    >
      <Grid className={clsx(classes.header, classes.headerMargin)}>
        {
          maxWidth ? (
            <Grid className={classes.header}>
              {titleText}
              {searchBar}
              {importBtn}
              {exportBtn}
              {logsList}
              {addButton}
            </Grid>
          ) : maxWidth2 ? (
            <Grid className={classes.headerColumn}>
              {titleText}
              <Grid className={classes.actionRow}>
                {searchBar}
                {logsList}
              </Grid>
              <Grid className={classes.importExportRow}>
                {importBtn}
                {exportBtn}
                {addButton}
              </Grid>
            </Grid>
          ) : (
            <Grid className={classes.headerColumn}>
              {titleText}
              <Grid className={classes.actionRow}>
                {searchBar}
                {logsList}
              </Grid>
              <Grid className={classes.importExportRow}>
                {importBtn}
                {exportBtn}
              </Grid>
              {addButton}
            </Grid>
          )
        }
      </Grid>
      <SuppliersTable
        suppliers={suppliers}
        getAllSuppliers={getAllSuppliers}
        total={total}
        fetching={fetching}
        limit={pageLimit}
        setLimit={(l) => setPageLimit(l)}
        tfError={tfError}
        handleEditSupplierOpen={handleEditSupplierOpen}
        query={query}
        page={pageNo}
        setPage={setPageNo}
      />
      <CreateSupplierModal
        addSupplierOpen={addSupplierOpen}
        handleClose={() => setAddSupplierOpen(false)}
        onSupplierCreated={onSupplierCreated}
      />
      <EditSupplierModal
        editSupplierOpen={editSupplierOpen}
        setEditSupplierOpen={setEditSupplierOpen}
        supplierId={selectedSupplierId}
        handleEditSupplierClose={handleEditSupplierClose}
        getAllSuppliers={getAllSuppliers}
        company={user.companyID}
      />
      <DimensionImportModal
        dimensionImportModalOpen={importModalOpen}
        handleClose={handleImportModalClose}
        dimension="supplier"
        fetchDimenstions={getAllSuppliers}
      />
    </Page>
  );
}

export default SuppliersList;
