import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, TableHead, Typography, Grid
} from '@material-ui/core';

import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import useStyles from 'src/Dashboard/components/InvoicesListResults/style';

const InvoiceUploadLogsTable = ({
  paginatedLogs
}) => {
  const classes = useStyles();
  const { ready, t } = useTranslation();

  const today = new Date().setHours(0, 0, 0, 0) / 1000;

  const getUploadError = (error) => {
    if (error.includes('INVALID_FILE_TYPE')) {
      const p = error.split('__');
      return t(`UPLOAD_ERROR_${p[0]}`, { fileType: p[1] });
    }
    return t(`UPLOAD_ERROR_${error}`);
  };

  return (
    <Box minWidth="100%">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnName}>
              {ready && t('UPLOAD_LOGS_DOC_NAME')}
            </TableCell>
            <TableCell width="140px" align="center" className={classes.columnName} style={{ padding: '6px' }}>
              {ready && t('UPLOAD_LOGS_DOC_STATUS')}
            </TableCell>
            <Hidden xsDown>
              <TableCell align="center" className={classes.columnName}>
                {ready && t('UPLOAD_LOGS_DOC_MEDIUM')}
              </TableCell>
            </Hidden>
            <Hidden mdDown>
              <TableCell className={classes.columnName}>
                {ready && t('UPLOAD_LOGS_DOC_COMMENTS')}
              </TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="left" className={classes.columnName}>
                {ready && t('UPLOAD_LOGS_DOC_DATE')}
              </TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            paginatedLogs && paginatedLogs.length > 0
              ? paginatedLogs.map((log) => {
                const isInvoiceLogToday = log.timestamp / 1000000000 >= today;

                return (
                  <TableRow
                    hover
                    key={log.id}
                    style={isInvoiceLogToday ? { backgroundColor: '#F9FAFC', height: 64 } : { height: 64 }}
                  >
                    <TableCell
                      className={isInvoiceLogToday ? classes.todayText : ''}
                      style={{ fontSize: 13, lineHeight: '24px' }}
                    >
                      {log.fileName}
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell width="140px" align="center" style={{ padding: '6px' }}>
                        <Grid style={{
                          backgroundColor: log.status ? '#dcfae5' : '#fabfb9',
                          borderRadius: 20,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '4px 0px'
                        }}
                        >
                          <Typography style={{
                            color: log.status ? '#0cab3c' : '#ed2b18',
                            fontSize: '13px',
                            fontWeight: 600
                          }}
                          >
                            {log.status ? t('SUCCEEDED') : t('FAILED')}
                          </Typography>
                        </Grid>
                      </TableCell>
                    </Hidden>
                    <Hidden only={['lg', 'xl']}>
                      <TableCell align="center" style={{ padding: '6px' }}>
                        <Grid style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxSizing: 'border-box',
                        }}
                        >
                          <span style={{
                            backgroundColor: log.status ? '#dcfae5' : '#fabfb9',
                            border: '1px solid #E1E4E8',
                            borderRadius: 50,
                            height: 29,
                            width: 29
                          }}
                          />
                        </Grid>
                      </TableCell>
                    </Hidden>
                    <Hidden xsDown>
                      <TableCell
                        align="center"
                        className={isInvoiceLogToday ? classes.todayText : ''}
                        style={{ fontSize: 16, lineHeight: '24px' }}
                      >
                        {log.medium}
                      </TableCell>
                    </Hidden>
                    <Hidden mdDown>
                      <TableCell
                        className={isInvoiceLogToday ? classes.todayText : ''}
                        style={{ fontSize: 16, lineHeight: '24px' }}
                      >
                        {log.error && getUploadError(log.error)}
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell
                        className={isInvoiceLogToday ? classes.todayText : classes.timeBox}
                        align="left"
                        style={{ fontSize: 15, lineHeight: '22.5px' }}
                      >
                        {moment.unix(log.createdAt).format(isInvoiceLogToday ? 'hh:mm' : 'YYYY-MM-DD HH:mm')}
                      </TableCell>
                    </Hidden>
                  </TableRow>
                );
              })
              : <EmptyText str="INVOICE_LOGS_LIST_VIEW_NO_LOGS" />
          }
        </TableBody>
      </Table>
    </Box>
  );
};

InvoiceUploadLogsTable.propTypes = {
  paginatedLogs: PropTypes.array,
};

export default InvoiceUploadLogsTable;
