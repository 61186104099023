import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from 'src/Dimensions/store/actions/gla';
import { axiosHeaders } from 'src/Shared/utils/helpers';

export function* fetchGlasSaga(action) {
  yield put(actions.fetchGlasStart());
  const search = action.search || '';
  try {
    const response = yield axios.get(
      `${window.config.backend.procys_service}v1/dimensions/glaccounts/companies/${action.companyID}?search=${search}`,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    yield put(actions.fetchGlasSuccess(response.data.data, response.data.total));
  } catch (error) {
    yield put(actions.fetchGlasFail(error.response.data.message));
  }
}

export function* fetchGlaListSaga(action) {
  const { loadFullScreen } = action;
  if (loadFullScreen) {
    yield put(actions.fetchGlaListStart());
  }
  const page = action.page || 0;
  const limit = action.limit || 10;
  const search = action.search || '';
  try {
    const response = yield axios.get(
      `${window.config.backend.procys_service}v1/dimensions/glaccounts/companies/${action.companyID}?page=${page + 1}&limit=${limit}&search=${search}`,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    yield put(actions.fetchGlaListSuccess(response.data.data, response.data.total));
  } catch (error) {
    yield put(actions.fetchGlaListFail(error.response.data.message));
  }
}
