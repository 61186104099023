import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from 'src/User/store/actions/actionType';
import updatetSubscriptionSaga from 'src/User/store/sagas/subscription';

export default function* watchSubscription() {
  yield all([
    takeEvery(actionTypes.UPDATE_SUBSCRIPTION, updatetSubscriptionSaga)
  ]);
}
