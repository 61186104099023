import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0px 10px',
    color: theme.palette.text.highlight2,
    fontSize: 14
  },
  option: {
    fontSize: 14
  },
  selectLabel: {
    color: theme.palette.text.caption,
    fontSize: 14
  }
}));

export default useStyles;
