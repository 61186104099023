import jwtDecode from 'jwt-decode';
import Axios from 'axios';
import i18next from 'i18next';

class AuthService {
  handleAuthentication() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginInWithToken = async (verifyTokenUrl, data) => {
    try {
      const response = await Axios.post(verifyTokenUrl, data);
      return response.data;
    } catch (err) {
      return err;
    }
  }

  logout = (loginPanelUrl) => {
    this.setSession(null);
    window.location.href = `${loginPanelUrl}?language=${i18next?.language?.split('-')[0] || 'en'}`;
  }

  setSession = (accessToken, role, userRef) => {
    if (accessToken) {
      localStorage.setItem('PROCYS_accessToken', accessToken);
      localStorage.setItem('PROCYS_role', role);
      if (userRef) {
        localStorage.setItem('PROCYS_userRef', userRef);
      } else {
        localStorage.removeItem('PROCYS_userRef');
      }
    } else {
      localStorage.removeItem('PROCYS_accessToken');
      localStorage.removeItem('PROCYS_role');
      localStorage.removeItem('PROCYS_userRef');
      window.sessionStorage.removeItem('selectedSuppliers');
      window.sessionStorage.removeItem('selectedInvoices');
      window.sessionStorage.removeItem('suppliers');
    }
  }

  getAccessToken = () => localStorage.getItem('PROCYS_accessToken');

  getUserRole = () => localStorage.getItem('PROCYS_role');

  getUserRef = () => localStorage.getItem('PROCYS_userRef');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
