import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
// import Axios from 'axios';

import {
  Grid, IconButton
} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';

// import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
// import { appendContactSupport, axiosHeaders, validateEmail } from 'src/Shared/utils/helpers';
import { useTranslation } from 'react-i18next';
import useStyles from '../style';

const EmailIntegration = () => {
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  const { ready, t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  // const [usedEmails, setUsedEmails] = useState(user?.receiveEmails?.join(', ') || '');
  // const [saved, setSaved] = useState(false);
  const [coppied, setCoppied] = useState(false);
  // const [loading, setLoading] = useState(false);

  // const [iUsedEmails, setIUsedEmails] = useState(user.receiveEmails || []);

  const copyToClipboard = () => {
    copy(user.ocrEmail, {
      onCopy: setCoppied(true)
    });
  };

  // const saveNewDetails = async () => {
  //   const uEmails = usedEmails.split(',');

  //   const fullList = [];
  //   const addedEmails = [];
  //   const removedEmails = [];
  //   for (let i = 0; i < uEmails.length; i++) {
  //     if (uEmails[i] && uEmails[i].trim()) {
  //       fullList.push(uEmails[i].trim());
  //     }

  //     let isNew = true;
  //     for (let j = 0; j < iUsedEmails.length; j++) {
  //       if (uEmails[i] && iUsedEmails[j] && uEmails[i].trim() === iUsedEmails[j].trim()) {
  //         isNew = false;
  //         break;
  //       }
  //     }

  //     if (isNew && uEmails[i] && uEmails[i].trim()) {
  //       const resp = validateEmail(uEmails[i].trim());
  //       if (!resp.isValid) {
  //         enqueueSnackbar(
  //           resp.errorMessage,
  //           {
  //             variant: 'error',
  //             autoHideDuration: 5000
  //           }
  //         );
  //         return;
  //       }
  //       addedEmails.push(uEmails[i].trim());
  //     }
  //   }

  //   for (let i = 0; i < iUsedEmails.length; i++) {
  //     let isDeleted = true;
  //     for (let j = 0; j < uEmails.length; j++) {
  //       if (iUsedEmails[i] && uEmails[j] && iUsedEmails[i].trim() === uEmails[j].trim()) {
  //         isDeleted = false;
  //         break;
  //       }
  //     }

  //     if (isDeleted) {
  //       removedEmails.push(iUsedEmails[i].trim());
  //     }
  //   }

  //   if (addedEmails.length <= 0 && removedEmails.length <= 0) {
  //     return;
  //   }

  //   try {
  //     const response = await Axios.post(
  //       `${API.saveEmailReceive}`,
  //       {
  //         new: addedEmails,
  //         old: removedEmails
  //       },
  //       axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
  //     );
  //     if (response.data.success) {
  //       setSaved(true);
  //       setIUsedEmails(fullList);
  //     } else {
  //       enqueueSnackbar(
  //         appendContactSupport(window.config.support_email, 'Details could not be saved. Something went wrong.'),
  //         {
  //           variant: 'error',
  //           autoHideDuration: 5000
  //         }
  //       );
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     enqueueSnackbar(
  //       appendContactSupport(window.config.support_email, 'Details could not be saved. Something went wrong.'),
  //       {
  //         variant: 'error',
  //         autoHideDuration: 5000
  //       }
  //     );
  //   }
  // };

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <Grid className={classes.detailsContainer}>
      <Grid className={classes.detailRow}>
        <span className={classes.labelText}>{ready && t('INTEGRATIONS_EMAIL_FORWARDING_EMAIL')}</span>
        <input
          fullWidth
          type="text"
          value={user.ocrEmail}
          className={classes.inputField}
          disabled
        />
        <IconButton onClick={copyToClipboard}>
          <CopyIcon />
        </IconButton>
        {coppied && <CheckIcon className={classes.checkIcon} />}
      </Grid>
      {/* <Grid className={classes.detailRow}>
        <Grid className={classes.labelWrapper}>
          <span className={classes.labelText}>Allowed emails</span>
          {saved && <Grid className={classes.savedChip}>Saved</Grid>}
        </Grid>
        <input
          fullWidth
          type="text"
          value={usedEmails}
          className={classes.inputField}
          onChange={(e) => setUsedEmails(e.target.value)}
          onBlur={(e) => saveNewDetails(e.target.value)}
        />
      </Grid> */}
    </Grid>
  );
};

export default EmailIntegration;
