import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootSubcompany: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paperModal: {
    width: 600,
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  descriptionMessageText: {
    color: '#334D6E',
    fontSize: 12,
    paddingTop: 16
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 16
  }
}));

export default useStyles;
