/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { colors, createTheme, responsiveFontSizes } from '@material-ui/core';

import { THEMES } from 'src/config';
import typography from 'src/Shared/assets/theme/typography';
import { softShadows, strongShadows } from 'src/Shared/assets/theme/shadows';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#03a9f4'
      },
      secondary: {
        main: '#3E8AFF',
        green: '#00e676'
      },
      text: {
        primary: '#000000',
        secondary: '#464e59',
        title: '#192A3E',
        caption: '#6A707E',
        success: '#009688',
        fail: '#ff0000',
        highlight: '#03a9f4',
        highlight2: '#3E8AFF'
      },
      line: {
        gray: '#EBEFF2',
        primary: '#3E8AFF'
      },
      icon: {
        white: '#ffffff',
        gray: '#C2CFE0',
        primary: '#03a9f4'
      },
      button: {
        primary: '#109CF1',
        action: '#FBA102'
      },
      status: {
        digitized: 'rgba(51, 77, 110, 1)',
        toreview: '#3E8AFF',
        reviewed: '#3E8AFF',
        tovalidate: '#F5B140',
        toexport: '#3CB573',
        exported: '#2A7E50',
        rejected: '#EF6C00',
        archived: '#4E342E',
        outoflimit: '#FFFFFF',
        errored: '#F7685B',
        splitRequest: '#8f8f8f',
        possibleDuplicate: '#ffc107'
      },
      statusbg: {
        digitized: '#E1E4E8',
        toreview: '#3E8AFF',
        reviewed: '#3E8AFF',
        tovalidate: '#FFB946',
        toexport: '#2ED47A',
        exported: '#2A7E50',
        rejected: '#EF6C00',
        archived: '#4E342E',
        outoflimit: '#424242',
        errored: '#F7685B',
        splitRequest: '#8f8f8f',
        possibleDuplicate: '#ffc107'
      },
      iType: {
        invoice: '#EF9A9A',
        purchaseOrder: '#33c9dc',
        creditNote: '#A5D6A7',
        attachment: '#CFD8DC'
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#3E8AFF',
        green: '#33eb91'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
        title: '#e6e5e8',
        caption: '#adb0bb',
        success: '#00ff00',
        fail: '#ff0000',
        highlight: '#03a9f4',
        highlight2: '#64a1ff'
      },
      line: {
        gray: '#EBEFF2',
        primary: '#03a9f4'
      },
      icon: {
        white: '#ffffff',
        gray: '#C2CFE0',
        primary: '#03a9f4'
      },
      button: {
        primary: '#109CF1',
        action: '#FBA102'
      },
      status: {
        digitized: '#334D6E',
        toreview: '#3E8AFF',
        reviewed: '#3E8AFF',
        tovalidate: '#F5B140',
        toexport: '#3CB573',
        exported: '#2A7E50',
        rejected: '#EF6C00',
        archived: '#4E342E',
        outoflimit: '#FFFFFF',
        errored: '#F7685B',
        splitRequest: '#8f8f8f',
        possibleDuplicate: '#ffc107'
      },
      statusbg: {
        digitized: '#E1E4E8',
        toreview: '#3E8AFF',
        reviewed: '#3E8AFF',
        tovalidate: '#FFB946',
        toexport: '#2ED47A',
        exported: '#2A7E50',
        rejected: '#EF6C00',
        archived: '#4E342E',
        outoflimit: '#424242',
        errored: '#F7685B',
        splitRequest: '#8f8f8f',
        possibleDuplicate: '#ffc107'
      },
      iType: {
        invoice: '#EF9A9A',
        creditNote: '#A5D6A7',
        attachment: '#CFD8DC'
      }
    },
    shadows: strongShadows
  }
];

export function createAppTheme(settings = {}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction }
    )
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
