import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  form: {},
  snackAction: {
    color: 'white'
  },
  userForm: {
    width: '100%'
  },
  fieldContainer: {
    marginTop: 25
  },
  submitBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    textTransform: 'none',
    background: '#3E8AFF',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    borderRadius: 4
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    color: '#A0AEC1',
    textTransform: 'none',
    marginRight: 24
  }
}));

export default useStyles;
