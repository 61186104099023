import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    maxWidth: '90%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 32px',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // maxWidth: 620,
  },
  header: {
    marginBottom: 8,
    color: '#334D6E',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 22,
  },
  caption: {
    marginBottom: 16,
    color: '#334D6E',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 500,
    maxWidth: 480,
    lineHeight: '16px',
  },
  noPadding: {
    padding: '0px 16px',
    color: '#334D6E',
    fontSize: 13
  },
  closeBtn: {
    padding: '8px!important',
  },
  fieldStyle: {
    paddingBottom: 24
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 16
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxHeight: 240,
    overflow: 'scroll'
  },
  listItemWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItem: {
    border: '1px #e4e4e4 solid',
    borderRadius: 8,
    padding: 16,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 196,
  },
  listItemTitle: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: 8,
    lineHeight: '22px',
    height: 44,
    color: '#334D6E',
  },
  itemPoint: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
  },
  listItemIcon: {
    color: theme.palette.status.toexport,
    fontSize: 12,
    marginTop: 3,
    marginRight: 4,
    marginLeft: -2
  },
  listItemPoint: {
    fontSize: 12,
    color: '#334D6E',
  },
  listItemSelected: {
    border: '1px #e4e4e4 solid',
    borderRadius: 8,
    padding: 16,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 196,
    backgroundColor: '#3E8AFF',
  },
  listItemTitleSelected: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: 8,
    lineHeight: '22px',
    height: 44,
    color: '#FFFFFF',
  },
  itemPointSelected: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
  },
  listItemIconSelected: {
    color: '#FFFFFF',
    fontSize: 12,
    marginTop: 3,
    marginRight: 4,
    marginLeft: -2
  },
  listItemPointSelected: {
    fontSize: 12,
    color: '#FFFFFF',
  },
  sendBtn: {
    textTransform: 'none'
  },
  beta: {
    backgroundColor: '#EC5929',
    color: '#ffffff',
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 8px 2px',
    position: 'relative',
    top: -6
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    minWidth: 166,
    height: 42,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    textTransform: 'none',
    padding: '6px 8px'
  },
}));

export default useStyles;
