/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Hidden, Card, TablePagination, Grid
} from '@material-ui/core';

import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import { getDocumentType } from 'src/Shared/utils/helpers';
import useStyles from './style';

function PartnerStatsTable({
  loading, stats, maxWidth, total, page, limit, maxWidthRows, handlePageChange, handleLimitChange
}) {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <Card
      className={classes.root}
    >
      <PerfectScrollbar>
        <Box minWidth="100%">
          <Table size="small">
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell className={classes.headerStyle} align="left">
                  {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_COMPANY_NAME')}
                </TableCell>
                {/* <Hidden only={['xs', 'sm']}>
                  <TableCell className={classes.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_COMPANY_ID')}
                  </TableCell>
                </Hidden> */}
                <Hidden only={['xs', 'sm']}>
                  <TableCell className={classes.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  <TableCell className={classes.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_CONNECTED_TO')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm']}>
                  <TableCell className={classes.headerStyle} align="center">
                    {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_DOC_TYPE')}
                  </TableCell>
                </Hidden>
                <TableCell className={classes.headerStyle} align="center">
                  {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_DOCUMENTS_USED')}
                </TableCell>
                <Hidden only={['xs']}>
                  <TableCell className={classes.headerStyle} align="center">
                    {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_PAGES_PROCESSED')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs']}>
                  <TableCell className={classes.headerStyle} align="center">
                    {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_USERS')}
                  </TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              {stats.map((c) => (
                <TableRow
                  hover
                  key={c.id}
                  className={classes.pointer}
                >
                  <TableCell className={classes.cellStyle} align="left">
                    {c.name}
                    {c.isTest && ' *'}
                  </TableCell>
                  {/* <Hidden only={['xs', 'sm']}>
                    <TableCell className={classes.cellStyle} align="left">
                      {c.companyID}
                    </TableCell>
                  </Hidden> */}
                  <Hidden only={['xs', 'sm']}>
                    <TableCell className={classes.cellStyle} align="left">
                      <Grid className={classes.centerCell}>
                        <Hidden only={['xs']}>
                          <Grid className={clsx(classes[c.isSubCompany ? 'sub' : 'main'], classes.statusLabel)}>
                            {c.isSubCompany ? 'subsidiary' : 'main'}
                          </Grid>
                        </Hidden>
                        <Hidden smUp>
                          <Grid className={clsx(classes[`${c.isSubCompany ? 'sub' : 'main'}Dot`], classes.statusDot)} />
                        </Hidden>
                      </Grid>
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'sm', 'md']}>
                    <TableCell className={classes.cellStyle} align="left">
                      {c.parentName}
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'sm']}>
                    <TableCell className={classes.cellStyle} align="center">
                      {t(getDocumentType(c.documentType)) || '-'}
                    </TableCell>
                  </Hidden>
                  <TableCell className={classes.cellStyle} align="center">
                    {c.documentsUsed || '-'}
                  </TableCell>
                  <Hidden only={['xs']}>
                    <TableCell className={classes.cellStyle} align="center">
                      {c.pagesProcessed || '-'}
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs']}>
                    <TableCell className={classes.cellStyle} align="center">
                      {c.usersUsed || '-'}
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <div className={classes.caption}>
        {
          !maxWidth && `${total} compan${total !== 1 ? 'ies' : 'y'} available`
        }
        <TablePagination
          component="div"
          classes={{
            root: classes.paginationComp,
            toolbar: classes.paginationToolbar,
            caption: classes.paginationCaption,
            selectRoot: classes.paginationCaption,
            select: classes.paginationMenu,
            menuItem: classes.paginationMenu,
            actions: classes.paginationActions,
          }}
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50]}
          labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
        />
      </div>
    </Card>
  );
}

PartnerStatsTable.propTypes = {
  loading: PropTypes.bool,
  stats: PropTypes.array,
  maxWidth: PropTypes.bool,
  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  maxWidthRows: PropTypes.bool,
  handlePageChange: PropTypes.func,
  handleLimitChange: PropTypes.func
};

export default PartnerStatsTable;
