import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import {
  Container
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Page from 'src/Shared/components/Page/Page';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import Results from 'src/Dimensions/components/DimensionLogsListResults/DimensionLogsListResults';

import authService from 'src/Shared/utils/services/authService';
import { axiosHeaders, validateToken } from 'src/Shared/utils/helpers';
import { translateMapLogs } from 'src/config';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const DimensionLogsListView = (props) => {
  const {
    classes, user, enqueueSnackbar, match, history
  } = props;
  const {
    dim, type, code
  } = match.params;
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [fetchingLogs, setFetchingLogs] = useState(true);

  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);

  const getLogs = async (page = 0, limit = 10000, search = '', filter = '') => {
    if (!validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLogs(true);

    try {
      const url = `${API.getDimensionLogsByCompany}${dim}/company/${user.companyID}${code !== undefined
        ? `/${code}` : ''}?${type ? `type=${type}` : ''}&page=${page + 1}&limit=${limit}&order=desc${search && search !== ''
        ? `&search=${search}` : ''}${filter && filter !== '' ? `&filter=${filter}` : ''}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setLogs(response.data.data);
            setTotal(response.data.total);
          } else {
            setLogs([]);
            setTotal(0);
          }
        }
      }
    } catch (error) {
      //
    }

    setFetchingLogs(false);
    setLoading(false);
  };

  const getDimLogsByID = async (id) => {
    if (!validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLogs(true);

    try {
      const url = `${API.getDimensionLogsByCompany}/${dim}/${id}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setLogs(response.data.data);
            setTotal(response.data.total);
          } else {
            setLogs([]);
            setTotal(0);
            history.push(
              `${API.getDimensionLogsByCompany}${dim}/company/${encodeURIComponent(user.companyID)}`
            );
            getLogs(0, 10);
          }
        }
      }
    } catch (error) {
      history.push(
        `${API.getDimensionLogsByCompany}${dim}/company/${encodeURIComponent(user.companyID)}`
      );
    }

    setFetchingLogs(false);
    setLoading(false);
  };

  useEffect(() => {
    const id = match && match.params ? match.params.id : 0;

    if (id && id > 0) {
      getDimLogsByID(id);
    } else {
      getLogs(0, 10);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dimTitle = t(translateMapLogs[dim]);

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <Page
      className={classes.root}
      title={`${dimTitle}${type ? ` - ${type}` : ''} ${ready && t('LOG_LIST')}`}
    >
      <Container maxWidth={false} className={classes.topContainer}>
        {
          logs && (
            <Results
              {...props}
              logs={logs}
              total={total}
              getLogs={
                (p, l, s, f) => getLogs(p, l, s, f)
              }
              fetchingLogs={fetchingLogs}
              code={code}
            />
          )
        }
      </Container>
    </Page>
  );
};

DimensionLogsListView.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    topContainer: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    companyID: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      dim: PropTypes.string,
      type: PropTypes.string,
      code: PropTypes.string,
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  enqueueSnackbar: PropTypes.func,
};

DimensionLogsListView.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = () => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(withSnackbar(withRouter(DimensionLogsListView)));
