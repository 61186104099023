import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  Backdrop, Button, Fade, Grid, Modal, Typography
} from '@material-ui/core';

import Page from 'src/Shared/components/Page/Page';
import useStyles from './style';

const SubCompanyAccessModal = ({
  openModal, parentName
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, ready } = useTranslation();

  const navigateToDashboard = () => {
    history.push('/dashboard');
  };

  return (
    <Page
      className={classes.rootSubcompany}
      title={t('MY_PLAN_PAGE_TITLE')}
    >
      <Modal
        className={classes.modal}
        open={openModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paperModal}>
            <Grid className={classes.modalHeader}>
              <Typography className={classes.headerTitle}>
                {ready && t('SUB_COMPANY_ACCESS_HEADER_BILLING')}
              </Typography>
            </Grid>
            <Grid className={classes.descriptionMessageText}>
              {t('SUB_COMPANY_ACCESS_DESCRIPTION_BILLING', { var: parentName })}
            </Grid>
            <Grid className={classes.actionContainer}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitBtn}
                onClick={navigateToDashboard}
              >
                {ready && t('SUB_COMPANY_ACCESS_GO_TO_DASHBOARD')}
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </Page>
  );
};

SubCompanyAccessModal.propTypes = {
  openModal: PropTypes.bool,
  parentName: PropTypes.string
};

export default SubCompanyAccessModal;
