/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as actionTypes from 'src/Dimensions/store/actions/actionTypes';

const initialState = {
  glAccounts: [],
  glaList: [],
  total: 0,
  loading: false,
  error: null
};

const fetchGlasStart = (state) => (
  produce(state, (draft) => {
    draft.loading = true;
    draft.error = null;
  })
);
const fetchGlasSuccess = (state, action) => (
  produce(state, (draft) => {
    draft.glAccounts = action.glas;
    draft.total = action.total;
    draft.loading = false;
    draft.error = null;
  })
);
const fetchGlasFail = (state, action) => (
  produce(state, (draft) => {
    draft.loading = false;
    draft.error = action.error;
  })
);

const fetchGlaListStart = (state) => (
  produce(state, (draft) => {
    draft.loading = true;
    draft.error = null;
  })
);
const fetchGlaListSuccess = (state, action) => (
  produce(state, (draft) => {
    draft.glaList = action.glas;
    draft.total = action.total;
    draft.loading = false;
    draft.error = null;
  })
);
const fetchGlaListFail = (state, action) => (
  produce(state, (draft) => {
    draft.loading = false;
    draft.error = action.error;
  })
);

const glaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GLAS_START: return fetchGlasStart(state);
    case actionTypes.FETCH_GLAS_SUCCESS: return fetchGlasSuccess(state, action);
    case actionTypes.FETCH_GLAS_FAIL: return fetchGlasFail(state, action);

    case actionTypes.FETCH_GLA_LIST_START: return fetchGlaListStart(state);
    case actionTypes.FETCH_GLA_LIST_SUCCESS: return fetchGlaListSuccess(state, action);
    case actionTypes.FETCH_GLA_LIST_FAIL: return fetchGlaListFail(state, action);

    default: {
      return state;
    }
  }
};

export default glaReducer;
