import React, {
  useState, useEffect, createRef, useContext
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Backdrop,
  Button, CircularProgress, Container, Grid, Typography
} from '@material-ui/core';

import Page from 'src/Shared/components/Page/Page';
import { getMaskedText } from 'src/Shared/utils/helpers';
import { statsPageRefreshTimer } from 'src/config';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function StatsDashboardView() {
  const {
    BRAND_NAME, BRAND_DOMAIN, BRAND_URL, API
  } = useContext(ConfigContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(null);
  const [timeSaved, setTimeSaved] = useState(null);

  const intervalID = createRef();

  const assignValues = (value) => {
    const iTotal = value;
    const sTotal = getMaskedText(iTotal?.toString(), 3);

    const iTime = Math.round((iTotal * 2.5) / 60);
    const sTime = getMaskedText(iTime.toString(), 3);

    setTotal(sTotal);
    setTimeSaved(sTime);
  };

  const assignPrevValue = () => {
    const lTotal = localStorage.getItem('PROCYS_APP_STATS_DASHBOARD_TOTAL');
    if (!lTotal || lTotal === 'null') {
      setTotal(0);
      setTimeSaved(0);
    } else {
      assignValues(lTotal);
    }
  };

  const getDashboardStats = async () => {
    const lTotal = localStorage.getItem('PROCYS_APP_STATS_DASHBOARD_TOTAL');
    if (!lTotal || lTotal === 'null') {
      setLoading(true);
    }

    try {
      const response = await axios.get(API.getDashboardStats);
      if (response.data.success) {
        assignValues(response.data.data.total);
        localStorage.setItem('PROCYS_APP_STATS_DASHBOARD_TOTAL', response.data.data.total);
      } else {
        assignPrevValue();
      }
      setLoading(false);
    } catch (error) {
      assignPrevValue();
      setLoading(false);
    }
  };

  const onStartInterval = () => {
    intervalID.current = setInterval(() => {
      getDashboardStats();
    }, statsPageRefreshTimer);
  };

  useEffect(() => {
    getDashboardStats();
    onStartInterval();

    return () => {
      clearInterval(intervalID);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Page
        className={classes.root}
        title={`${BRAND_NAME} Statistics`}
      >
        <Container className={classes.container}>
          <Grid className={classes.innerContainer}>
            <Button onClick={() => window.open(BRAND_URL, '_blank')} className={classes.logoBtn}>
              <img src={`${API.brandLogo}${BRAND_DOMAIN}`} alt="" className={classes.logo} />
            </Button>
            {
              !loading && (
                <>
                  <Typography className={classes.countLabel}>Invoices processed</Typography>
                  <Typography className={classes.countStat}>{total}</Typography>
                  <Typography className={classes.timeLabel}>Time saved (hours)</Typography>
                  <Typography className={classes.timeStat}>{timeSaved}</Typography>
                </>
              )
            }
          </Grid>
          <Grid className={classes.copyrightContainer}>
            <Typography>
              Copyright © 2022
              {' '}
              <a
                href={BRAND_URL}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.copyrightLink}
              >
                {BRAND_NAME}
              </a>
              , All rights reserved.
            </Typography>
          </Grid>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </Page>
    </>
  );
}

StatsDashboardView.propTypes = {
  stats: PropTypes.shape({
    total: PropTypes.number,
  })
};

export default StatsDashboardView;
