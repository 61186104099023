import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import authService from 'src/Shared/utils/services/authService';
import * as actions from 'src/Auth/store/actions/auth';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import ConfigContext from 'src/Contexts';

function AuthRoute({
  component: Component, render, onSetUserData, ...rest
}) {
  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);
  const auth = useSelector((state) => state.auth);

  if (!authService.isAuthenticated()) {
    authService.logout(LOGIN_PANEL_URL);
  }

  useEffect(() => {
    const checkAuth = async () => {
      if (authService.isAuthenticated()) {
        const data = {
          token: authService.getAccessToken(),
          role: authService.getUserRole(),
          userRef: authService.getUserRef()
        };
        const response = await authService.loginInWithToken(API.verifyToken, data);
        if (response.success && response.data) {
          onSetUserData(response.data);
          authService.setSession(response.data.accessToken, response.data.role, response.data.email);
          if (response.data.avatarName && response.data.avatarName !== '') {
            onSetUserData({
              ...response.data,
              avatar: `${API.getProfileImage}${response.data.avatarName}`,
            });
          }
        } else {
          authService.logout(LOGIN_PANEL_URL);
        }
      } else {
        authService.logout(LOGIN_PANEL_URL);
      }
    };

    if (!auth.user) {
      checkAuth();
    }
  }, [auth.user, onSetUserData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (auth?.user) {
      const _hsq = window._hsq = window._hsq || []; // eslint-disable-line
      _hsq.push(['identify', {
        email: auth.user.email,
        companyID: auth.user.companyID
      }]);
    }
  }, [auth.user]);

  if (!auth.user) {
    return <LoadingScreen />;
  }

  return render ? render({ ...rest }) : <Component {...rest} />;
}

AuthRoute.propTypes = {
  component: PropTypes.any,
  render: PropTypes.func,
  onSetUserData: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetUserData: (user) => dispatch(actions.setUserData(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
