import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import 'src/Shared/assets/theme/css/prism.css';
import 'src/Shared/assets/theme/mixins/chartjs';
import 'src/Shared/assets/theme/mixins/prismjs';
import 'src/i18n';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/Shared/utils/context/SettingsContext';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/Shared/utils/settings';
import App from 'src/App';

enableES5();

const store = configureStore();
const settings = restoreSettings();

const app = (
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </Provider>
);

window.onload = () => {
  ReactDOM.render(app, document.getElementById('root'));
};

serviceWorker.unregister();
