import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from 'src/User/store/actions/subscription';

export default function* updatetSubscriptionSaga(action) {
  yield put(actions.updateSubscriptionStart());

  try {
    yield axios.request({
      method: 'put',
      url: `${window.config.backend.provider_service}v1/admin/companies/subscriptions/change`,
      data: {
        subscription: action.subscription
      },
      headers: {
        Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
      }
    });
    yield put(actions.updateSubscriptionSuccess(action.subscription));
  } catch (error) {
    yield put(actions.updateSubscriptionFail(error.response));
  }
}
