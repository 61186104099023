/* eslint-disable max-len, comma-spacing */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import {
  Container, Grid, SvgIcon, Typography
} from '@material-ui/core';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import Page from 'src/Shared/components/Page/Page';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
// import Header from 'src/Dashboard/components/Header/Header';
// import MostRecentInvoices from 'src/Dashboard/containers/MostRecentInvoices/MostRecentInvoices';
// import TotalInvoices from 'src/Dashboard/components/TotalInvoices/TotalInvoices';
// import CompletedInvoices from 'src/Dashboard/components/CompletedInvoices/CompletedInvoices';
// import PendingInvoices from 'src/Dashboard/components/PendingInvoices/PendingInvoices';
// import ErroredInvoices from 'src/Dashboard/components/ErroredInvoices/ErroredInvoices';

import Results from 'src/Dashboard/components/Results/Results';
import OverviewStats from 'src/Dashboard/components/OverviewStats/OverviewStats';
import InvoicesProcessed from 'src/Dashboard/containers/InvoicesProcessed/InvoicesProcessed';
import * as actions from 'src/Dashboard/store/actions/dashboard';
import { fetchVatCodes } from 'src/Dimensions/store/actions/vatcode';
import { fetchGlas } from 'src/Dimensions/store/actions/gla';
import { fetchCostCenters } from 'src/Dimensions/store/actions/costcenter';
import InvoiceListView from 'src/Invoices/components/InvoiceListView/InvoiceListView';
import SelectDocument from 'src/Shared/components/SelectDocument/SelectDocument';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function DashboardView({
  overviewStats, onGetAppInvoiceStats, onGetInvoiceStatsByUser, history
}) {
  const { BRAND_NAME } = useContext(ConfigContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ready } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (user && user.role === 'company') {
      onGetInvoiceStatsByUser();
    } else {
      onGetAppInvoiceStats();
    }
    if (user && user.companyID) {
      dispatch(fetchVatCodes(user.companyID));
      dispatch(fetchGlas(user.companyID));
      dispatch(fetchCostCenters(user.companyID));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {
        user.role !== 'company' && !dataLoaded && <LoadingScreenText />
      }
      {
        !user.documentType && <SelectDocument open />
      }
      <Page
        className={classes.root}
        title={BRAND_NAME}
      >
        <Container
          maxWidth={false}
          className={classes.container}
          style={{ display: user.role !== 'company' && !dataLoaded && 'none' }}
        >
          <Grid container spacing={4} className={classes.topContainer}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <OverviewStats
                    totalErrored={overviewStats && overviewStats.erroredTot ? overviewStats.erroredTot : 0}
                    totalUploading={overviewStats && overviewStats.uploadingTot ? overviewStats.uploadingTot : 0}
                    totalDigitized={overviewStats && overviewStats.digitizedTot ? overviewStats.digitizedTot : 0}
                    totalToreview={overviewStats && overviewStats.toreviewTot ? overviewStats.toreviewTot : 0}
                    totalTovalidate={overviewStats && overviewStats.tovalidateTot ? overviewStats.tovalidateTot : 0}
                    totalToexport={overviewStats && overviewStats.toexportTot ? overviewStats.toexportTot : 0}
                    totalExported={overviewStats && overviewStats.exportedTot ? overviewStats.exportedTot : 0}
                    totalRejected={overviewStats && overviewStats.rejectedTot ? overviewStats.rejectedTot : 0}
                    totalArchived={overviewStats && overviewStats.archivedTot ? overviewStats.archivedTot : 0}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Grid className={classes.addWidget}>
                    <SvgIcon>
                      <AddCircleIcon className={classes.addWidgetIcon} />
                    </SvgIcon>
                    <Typography className={classes.addWidgetText}>
                      Add custom widget
                    </Typography>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InvoicesProcessed
                    digitized={overviewStats && overviewStats.dataDigitized ? overviewStats.dataDigitized : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    uploading={overviewStats && overviewStats.dataUploading ? overviewStats.dataUploading : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    toreview={overviewStats && overviewStats.dataToreview ? overviewStats.dataToreview : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    tovalidate={overviewStats && overviewStats.dataTovalidate ? overviewStats.dataTovalidate : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    toexport={overviewStats && overviewStats.dataToexport ? overviewStats.dataToexport : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    exported={overviewStats && overviewStats.dataExported ? overviewStats.dataExported : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    rejected={overviewStats && overviewStats.dataRejected ? overviewStats.dataRejected : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    errored={overviewStats && overviewStats.dataErrored ? overviewStats.dataErrored : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    amountExported={overviewStats && overviewStats.dataExportedAmount ? overviewStats.dataExportedAmount : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    iLabels={overviewStats && overviewStats.labels ? overviewStats.labels : [0,0,0,0,0,0,0,0,0,0,0,0]}
                    user={user}
                  />
                </Grid>
                {/* {
                  user.role === 'user' && (
                    <Grid item xs={12}>
                      <Charges />
                    </Grid>
                  )
                } */}
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Grid container spacing={2} style={{paddingBottom: 24}}>
                <Grid item xs={6} sm={3} md={6}>
                  <TotalInvoices count={totalUploaded} />
                </Grid>
                <Grid item xs={6} sm={3} md={6}>
                  <CompletedInvoices count={totalProcessed} />
                </Grid>
                <Grid item xs={6} sm={3} md={6}>
                  <PendingInvoices count={totalInprogress} />
                </Grid>
                <Grid item xs={6} sm={3} md={6}>
                  <ErroredInvoices count={totalErrored} />
                </Grid>
              </Grid>
              <MostRecentInvoices
                processedFS={totalProcessed || 0}
                totalFS={totalUploaded || 0}
              />
            </Grid> */}
          </Grid>
          {user.brand === 'schubec' && !user.integrations.includes('api')
            && (
            <Grid className={classes.banner}>
              <SvgIcon fontSize="13">
                <PriorityHighIcon className={classes.priorityIcon} />
              </SvgIcon>
              <Typography className={classes.bannerText}>
                {ready && (
                <Trans i18nKey="PARTNER_CONNECT_API_BANNER">
                  Connect to our API to improve your experience with us. You can work with settings
                  {' '}
                  <span className={classes.connectAPILink} onClick={() => history.push('/integrations')}>here</span>
                </Trans>
                )}
              </Typography>
            </Grid>
            )}
          {user.role === 'company' && overviewStats.userStats && <Results invoices={overviewStats.userStats.reverse()} />}
          {user.role === 'user' && <InvoiceListView onDataLoaded={() => setDataLoaded(true)} />}
        </Container>
      </Page>
    </>
  );
}

DashboardView.propTypes = {
  overviewStats: PropTypes.shape({
    userStats: PropTypes.array,
    dataDigitized: PropTypes.array,
    dataUploading: PropTypes.array,
    dataErrored: PropTypes.array,
    dataToreview: PropTypes.array,
    dataTovalidate: PropTypes.array,
    dataToexport: PropTypes.array,
    dataExported: PropTypes.array,
    dataRejected: PropTypes.array,
    dataArchived: PropTypes.array,
    labels: PropTypes.array,
    dataExportedAmount: PropTypes.array,
    digitizedTot: PropTypes.number,
    uploadingTot: PropTypes.number,
    erroredTot: PropTypes.number,
    toreviewTot: PropTypes.number,
    tovalidateTot: PropTypes.number,
    toexportTot: PropTypes.number,
    exportedTot: PropTypes.number,
    rejectedTot: PropTypes.number,
    archivedTot: PropTypes.number,
  }),
  onGetInvoiceStatsByUser: PropTypes.func,
  onGetAppInvoiceStats: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  // error: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userInvoices: state.dashboard.invoices,
  overviewStats: state.dashboard.overviewStats,
  loading: state.dashboard.loading,
  error: state.dashboard.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInvoiceStatsByUser: (payload) => dispatch(actions.getInvoiceStatsByUser(payload)),
  onGetAppInvoiceStats: (payload) => dispatch(actions.getInvoiceAppStatsByUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
