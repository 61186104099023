import React from 'react';
import PropTypes from 'prop-types';

import {
  Close as CloseIcon,
  ArrowDropDown
} from '@material-ui/icons';
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener, Fade, Grid, Grow, IconButton, MenuItem, MenuList, Modal, Paper, Popper, Tooltip, Typography
} from '@material-ui/core';

import { isActionPermitted, permissions } from 'src/Shared/utils/helpers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import useStyles from './style';

const ChooseValidatorModal = (props) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { ready, t } = useTranslation();

  const {
    chooseValidatorModalOpen,
    handleChooseValidatorModalClose,
    validatorOptionsRef,
    handleValidatorOptionsToggle,
    selectedValidator,
    validatorOptionsOpen,
    handleValidatorOptionsClose,
    users,
    handleValidatorClick,
    handleAddUserClick,
    statusChangeLoading,
    onSendToValidate
  } = props;
  return (
    <Modal
      className={classes.modal}
      open={chooseValidatorModalOpen}
      onClose={() => handleChooseValidatorModalClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={chooseValidatorModalOpen}>
        <div className={classes.halfPaper}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {ready && t('CHOOSE_VALIDATOR_MODAL_TITLE')}
            </Typography>
            <IconButton onClick={() => handleChooseValidatorModalClose()}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Grid>
            <Typography variant="caption" className={classes.primaryTextColor}>
              {ready && t('CHOOSE_VALIDATOR_MODAL_SUB_TITLE')}
            </Typography>
            <Grid className={classes.fieldContainerFirst}>
              <Typography variant="h5" className={classes.primaryTextColor}>
                {ready && t('CHOOSE_VALIDATOR_MODAL_VALIDATOR')}
              </Typography>
              <ButtonGroup variant="outlined" ref={validatorOptionsRef} aria-label="split button" className={classes.btnGroup}>
                <Button className={classes.groupBtn} onClick={handleValidatorOptionsToggle}>
                  {`${selectedValidator?.firstname} ${selectedValidator?.lastname}`}
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <Popper
                open={validatorOptionsOpen}
                anchorEl={validatorOptionsRef.current}
                role={undefined}
                transition
                style={{
                  zIndex: 111111, width: '90%', maxWidth: 500, padding: '0px 28px',
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleValidatorOptionsClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {users.map((option) => (
                            <MenuItem
                              key={option.id}
                              selected={option.id === selectedValidator.id}
                              onClick={() => handleValidatorClick(option)}
                              className={classes.groupBtn}
                            >
                              {`${option.firstname} ${option.lastname}`}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
            <Grid className={classes.fieldContainerFirst}>
              <Typography variant="caption" className={classes.primaryTextColor}>
                {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_1')}
                <Tooltip title={!isActionPermitted(permissions.userAdd, user.permissions)
                  ? ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_PERMISSION') : ''}
                >
                  <Typography
                    variant="caption"
                    className={isActionPermitted(permissions.userAdd, user.permissions) ? classes.link : classes.disabledLink}
                    onClick={() => isActionPermitted(permissions.userAdd, user.permissions) && handleAddUserClick()}
                  >
                    {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_2')}
                  </Typography>
                </Tooltip>
                {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_3')}
              </Typography>
            </Grid>
            {
              user.usersCount >= user.maxUsers && (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className={classes.addUserDisabled}
              >
                <Alert severity="warning" className={classes.warningBanner}>
                  <Typography className={classes.addUserDisabledText}>
                    {ready && t('ACCOUNT_SETTINGS_ADD_USER_DISABLED')}
                    <strong>
                      <Link href="/account/subscription">
                        {ready && t('ACCOUNT_SETTINGS_ADD_USER_UPDATE_LINK')}
                      </Link>
                    </strong>
                  </Typography>
                </Alert>
              </Grid>
              )
            }
          </Grid>
          <Grid className={classes.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                className={classes.cancelBtn}
                onClick={() => handleChooseValidatorModalClose()}
              >
                {ready && t('CHOOSE_VALIDATOR_MODAL_CANCEL')}
              </Button>
              {statusChangeLoading ? (
                <Grid className={classes.loadingAction}>
                  <CircularProgress size={20} />
                </Grid>
              ) : (
                <Button
                  className={classes.validateButton}
                  variant="contained"
                  color="secondary"
                  onClick={(e) => onSendToValidate(e)}
                >
                  {ready && t('CHOOSE_VALIDATOR_MODAL_SEND')}
                </Button>
              )}
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

ChooseValidatorModal.propTypes = {
  chooseValidatorModalOpen: PropTypes.bool,
  handleChooseValidatorModalClose: PropTypes.func,
  validatorOptionsRef: PropTypes.object,
  handleValidatorOptionsToggle: PropTypes.func,
  selectedValidator: PropTypes.object,
  validatorOptionsOpen: PropTypes.bool,
  handleValidatorOptionsClose: PropTypes.func,
  users: PropTypes.array,
  handleValidatorClick: PropTypes.func,
  handleAddUserClick: PropTypes.func,
  statusChangeLoading: PropTypes.bool,
  onSendToValidate: PropTypes.func,
};

export default ChooseValidatorModal;
