import * as React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';

import useStyles from './style';

const SnackbarCloseButton = ({ snackbarKey }) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose className={classes.icon} />
    </IconButton>
  );
};

SnackbarCloseButton.propTypes = {
  snackbarKey: PropTypes.oneOfType(PropTypes.string, PropTypes.number)
};

export default SnackbarCloseButton;
