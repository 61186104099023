import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 20,
    color: '#ffffff'
  }
}));

export default useStyles;
