/* eslint-disable no-nested-ternary */
import React, {
  useState, useContext
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Switch, Tooltip, Typography
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import LaunchIcon from '@material-ui/icons/LaunchOutlined';

import ManageIntegration from 'src/Integrations/components/ManageIntegration/ManageIntegration';
import {
  docunectaIntegrations, integrationNames, procysIntegrations
} from 'src/config';
import { isActionPermitted, permissions } from 'src/Shared/utils/helpers';
import { useTranslation } from 'react-i18next';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const IntegrationBox = (props) => {
  const { BRAND_NAME } = useContext(ConfigContext);
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { ready, t } = useTranslation();

  const {
    integration, sendNotifyMeRequest, connectRequest, processing, processingAppName, deleteApp,
    deliverFormats, exportEmailPrefFormat, savedExportEmailFormat, savePrefFormat
  } = props;

  const freeUser = user?.subscription && user.subscription.toLowerCase().indexOf('free') >= 0;

  const [manageOpen, setManageOpen] = useState(false);
  const [openMoreEl, setOpenMoreEl] = useState(null);

  const [isActive, setIsActive] = useState(integration?.active);

  const noAllowedForFree = freeUser && integration.name !== integrationNames.emailReceiver;

  const updateCheckBoxStatus = () => {
    setIsActive((prevState) => !prevState);
  };

  // useEffect(() => {
  //   setListener(
  //     {
  //       onConnectorLinked: (connector, flowId) => {
  //         const selectedIntegration = integrations.find((item) => item.flowId === flowId);
  //         connectRequest(selectedIntegration.name, true, selectedIntegration, () => { setIsActive(true); }, true);
  //       },
  //       onConnectorUnlinked: (connector, flowId) => {
  //         const selectedIntegration = integrations.find((item) => item.flowId === flowId);
  //         connectRequest(selectedIntegration.name, false, selectedIntegration, () => { setIsActive(false); }, true);
  //       },
  //       onTargetLinked: (connector, flowId) => {
  //         const selectedIntegration = integrations.find((item) => item.flowId === flowId);
  //         connectRequest(selectedIntegration.name, true, selectedIntegration, () => { setIsActive(true); }, true);
  //       },
  //     }
  //   );
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenMoreEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenMoreEl(null);
    setManageOpen(false);
  };

  const onChangeActiveStatus = () => {
    connectRequest(integration.name, !integration.active, integration, updateCheckBoxStatus, false);
  };

  const deleteIntegration = (event, name) => {
    if (integration.active) {
      updateCheckBoxStatus();
    }
    deleteApp(name);
    handleCloseMenu(event);
  };

  const onConnect = () => {
    if (
      integration.name === integrationNames.onedrive // OneDrive
      || integration.name === integrationNames.dropbox // DropBox
      || integration.name === integrationNames.googleDrive // GoogleDrive
    ) {
      if (!integration.active) {
        connectRequest(integration.name, true, integration, () => {}, false);
      }
      window.open(integration.zapierLink, '_blank');
      return;
    }

    setManageOpen(true);

    // Connect automatically only for [email, api, split-document]
    if (integration.name === integrationNames.emailReceiver || integrationNames.api
      || integration.name === integrationNames.splitDocument || integration.name === integrationNames.assistantAPI
      || integration.name === integrationNames.quickbooks || integration.name === integrationNames.holded
      || integration.name === integrationNames.sftpReceiver || integration.name === integrationNames.sftpSender) {
      connectRequest(integration.name, !integration.active, integration, updateCheckBoxStatus, false);
    }
  };

  const onManageClick = () => {
    setManageOpen(true);
  };

  const isIntegrationNotAllowed = !integration.isAllowed
    || (procysIntegrations.includes(integration.name) && BRAND_NAME !== 'Procys')
    || (docunectaIntegrations.includes(integration.name) && !(BRAND_NAME === 'Docunecta' || BRAND_NAME === 'Docuindexa'))
    || (integration.name === integrationNames.sftpReceiver && !user.customisations.includes('enableSFTPReceiverApp'));
    // || (integration.name === 'quickbooks' && ENVIRONMENT === 'prod');

  const renderZapierConnectBtn = (
    <Button
      variant="contained"
      color="default"
      className={noAllowedForFree ? classes.disableBtn : classes.connectLinkBtn}
      onClick={noAllowedForFree ? () => {} : onConnect}
      disabled={
        !isActionPermitted(permissions.appsInstall, user.permissions) || !isActionPermitted(permissions.appsView, user.permissions)
      }
    >
      <LaunchIcon className={classes.launchIcon} />
      {integration.integrated ? t('APPS_MANAGE') : t('APPS_CONNECT')}
    </Button>
  );

  const renderNotifyMeButton = () => (
    processing && processingAppName === integration.name
      ? (
        <Grid className={classes.action}>
          <CircularProgress size={20} />
        </Grid>
      )
      : (
        <Tooltip title={freeUser ? t('INTEGRATIONS_SUBSCRIBE_TO_USE') : !isActionPermitted(permissions.appsView, user.permissions)
          ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INTERACT')
          : !isActionPermitted(permissions.appsInstall, user.permissions)
            ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INSTALL')
            : ''}
        >
          <span>
            <Button
              variant="outlined"
              className={classes.connectBtn}
              disabled={freeUser || !isActionPermitted(permissions.appsView, user.permissions)}
              onClick={sendNotifyMeRequest}
            >
              <span className={classes.btnText}>{ready && t('INTEGRATIONS_NOTIFY_ME')}</span>
            </Button>
          </span>
        </Tooltip>
      )
  );

  const renderStatus = () => {
    if (processing && processingAppName === integration.name) {
      return (
        <Grid className={classes.action}>
          <CircularProgress size={20} />
        </Grid>
      );
    }

    if (!integration.byZapier && (integration.integrated || manageOpen)) {
      return (
        <Grid>
          <Switch
            checked={isActive}
            onChange={onChangeActiveStatus}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <IconButton onClick={handleOpenMenu}>
            <MoreIcon />
          </IconButton>
          <Menu
            id="card-actions"
            anchorEl={openMoreEl}
            keepMounted
            open={Boolean(openMoreEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              className={classes.moreItem}
              onClick={(e) => deleteIntegration(e, integration.name)}
            >
              {t('INTEGRATIONS_DELETE')}
            </MenuItem>
          </Menu>
        </Grid>
      );
    }

    return (
      <Tooltip title={noAllowedForFree ? t('INTEGRATIONS_SUBSCRIBE_TO_USE') : !isActionPermitted(permissions.appsView, user.permissions)
        ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INTERACT')
        : !isActionPermitted(permissions.appsInstall, user.permissions)
          ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INSTALL')
          : ''}
      >
        <span>
          {
            integration.byZapier
              ? renderZapierConnectBtn
              : (
                <Button
                  variant="contained"
                  color={noAllowedForFree ? 'default' : 'secondary'}
                  className={noAllowedForFree ? classes.disableBtn : classes.connectBtn}
                  onClick={noAllowedForFree ? () => {} : onConnect}
                  disabled={
                    !isActionPermitted(permissions.appsInstall, user.permissions) || !isActionPermitted(permissions.appsView, user.permissions)
                  }
                >
                  <span className={classes.btnText}>{ready && t('INTEGRATIONS_CONNECT')}</span>
                </Button>
              )
          }
        </span>
      </Tooltip>
    );
  };

  if (isIntegrationNotAllowed) {
    return null;
  }

  if (user.brand === 'schubec' && integration.name === 'assistantapi') {
    return null;
  }

  return (
    <Grid key={integration.id} className={classes.integrationBox}>
      <Grid className={classes.integrationHeader}>
        <img className={classes.integrationImage} alt="int" src={integration.img} />
        <Grid className={classes.integrationBody}>
          <Typography className={classes.integrationTitle}>
            {ready && t(integration.title)}
            {integration.isBeta && <span className={classes.beta}>{ready && t('BETA_RELEASE')}</span>}
            {integration.byZapier && <span className={classes.zapierConnection}>{ready && t('INTEGRATIONS_BY_ZAPIER')}</span>}
            {!integration.available && <span className={classes.comingSoon}>{ready && t('INTEGRATIONS_COMING_SOON')}</span>}
            {freeUser && integration.free && <span className={classes.tryForFree}>{ready && t('INTEGRATIONS_TRY_FOR_FREE')}</span>}
          </Typography>
          <Typography className={classes.integrationDesc}>
            {ready && t(integration.description, { brand: BRAND_NAME })}
          </Typography>
        </Grid>
        <Grid>
          {
            integration.available
              ? renderStatus()
              : renderNotifyMeButton()
          }
        </Grid>
      </Grid>
      {
        !integration.byZapier && integration.integrated && !manageOpen && !integration.noManage && (
          <Grid style={{ display: 'flex' }}>
            <Grid style={{ width: 56 }} />
            <Tooltip title={!isActionPermitted(permissions.appsView, user.permissions)
              ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INTERACT')
              : !isActionPermitted(permissions.appsManage, user.permissions)
                ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_MANAGE')
                : ''}
            >
              <span>
                <Button
                  variant="outlined"
                  className={classes.manageBtn}
                  onClick={onManageClick}
                  disabled={
                    !isActionPermitted(permissions.appsManage, user.permissions) || !isActionPermitted(permissions.appsView, user.permissions)
                  }
                >
                  {ready && t('INTEGRATIONS_MANAGE')}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        )
      }
      {
        manageOpen && (
          <ManageIntegration
            integration={integration}
            deliverFormats={deliverFormats}
            exportEmailPrefFormat={exportEmailPrefFormat}
            savePrefFormat={savePrefFormat}
            savedExportEmailFormat={savedExportEmailFormat}
          />
        )
      }
    </Grid>
  );
};

IntegrationBox.propTypes = {
  integration: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    zapierLink: PropTypes.string,
    byZapier: PropTypes.bool,
    available: PropTypes.bool,
    integrated: PropTypes.bool,
    active: PropTypes.bool,
    free: PropTypes.bool,
    isAllowed: PropTypes.bool,
    isBeta: PropTypes.bool,
    img: PropTypes.string,
    appName: PropTypes.string,
    isTarget: PropTypes.bool,
    flowId: PropTypes.string,
    noManage: PropTypes.bool,
  }),
  connectRequest: PropTypes.func,
  sendNotifyMeRequest: PropTypes.func,
  deleteApp: PropTypes.func,
  processing: PropTypes.bool,
  processingAppName: PropTypes.string,
  deliverFormats: PropTypes.array,
  exportEmailPrefFormat: PropTypes.string,
  savedExportEmailFormat: PropTypes.bool,
  savePrefFormat: PropTypes.func,
};

export default IntegrationBox;
