import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop, Fade, Grid, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow,
  Typography, SvgIcon, TextField, Box, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PlusCircleIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { axiosHeaders } from 'src/Shared/utils/helpers';
import { useSnackbar } from 'notistack';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const SupplierProducts = (props) => {
  const {
    supplierProductsOpen,
    handleClose,
    products,
    updateProducts,
    supplier,
  } = props;
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const inventoryFields = [{
    key: 'article',
    label: 'SUPPLIER_PRODUCTS_TABLE_INNER_CODE'
  }, {
    key: 'description',
    label: 'SUPPLIER_PRODUCTS_TABLE_INNER_CODE_DESCR'
  }, {
    key: 'articleClient',
    label: 'SUPPLIER_PRODUCTS_TABLE_CLIENT_CODE'
  }, {
    key: 'descriptionClient',
    label: 'SUPPLIER_PRODUCTS_TABLE_CLIENT_CODE_DESCR'
  }, {
    key: 'unitMeasurement',
    label: 'SUPPLIER_PRODUCTS_TABLE_UNIT_MEASUREMENT'
  }];

  const [supplierProducts, setSupplierProducts] = useState(products?.length > 0 ? products : [{
    article: '',
    description: '',
    articleClient: '',
    descriptionClient: '',
    unitMeasurement: '',
  }]);

  useEffect(() => setSupplierProducts(products?.length > 0 ? products : [{
    article: '',
    description: '',
    articleClient: '',
    descriptionClient: '',
    unitMeasurement: '',
  }]), [products]);

  const handleAddProduct = () => {
    const newProduct = {
      article: '',
      description: '',
      articleClient: '',
      descriptionClient: '',
      unitMeasurement: '',
    };
    setSupplierProducts([...supplierProducts, newProduct]);
  };

  const handleDeleteProduct = (i) => {
    const newProducts = [...supplierProducts];
    newProducts.splice(i, 1);
    setSupplierProducts(newProducts);
  };

  const handleChange = (value, index, key) => {
    const newProducts = [...supplierProducts];
    newProducts[index][key] = value;
    setSupplierProducts(newProducts);
  };

  const closeProductsModal = () => {
    setSupplierProducts(products?.length > 0 ? products : [{
      article: '',
      description: '',
      articleClient: '',
      descriptionClient: '',
      unitMeasurement: '',
    }]);
    handleClose();
  };

  const handleSave = async () => {
    const error = supplierProducts.some((product) => {
      if (!product.article?.trim() && !product.description?.trim() && !product.articleClient?.trim()
        && !product.descriptionClient?.trim() && !product.unitMeasurement?.trim()) {
        return true;
      }
      return false;
    });
    if (error) {
      enqueueSnackbar(ready && t('SUPPLIER_PRODUCTS_ALL_EMPTY'), {
        variant: 'error',
        persist: true,
      });
      return;
    }
    if (supplier) {
      try {
        const body = {
          ...supplier,
          inventories: supplierProducts
        };

        const response = await Axios.put(
          `${API.suppliers}`,
          body,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );

        if (response.data.success) {
          enqueueSnackbar(ready && t('SUPPLIER_PRODUCTS_UPDATE_SUCCESS'), {
            variant: 'success',
            persist: true,
          });
          updateProducts(supplierProducts);
          closeProductsModal();
        } else {
          enqueueSnackbar(ready && t('SUPPLIER_PRODUCTS_UPDATE_FAILED'), {
            variant: 'error',
            persist: true,
          });
        }
        return;
      } catch (err) {
        enqueueSnackbar(ready && t('SUPPLIER_PRODUCTS_UPDATE_FAILED'), {
          variant: 'error',
          persist: true,
        });
        return;
      }
    }
    updateProducts(supplierProducts);
    closeProductsModal();
  };

  return (
    <Modal
      className={classes.modal}
      open={supplierProductsOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={supplierProductsOpen}>
        <div className={classes.paper}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {ready && t('SUPPLIER_PRODUCTS_TITLE')}
            </Typography>
            <IconButton onClick={() => closeProductsModal()}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {inventoryFields.map((field) => (
                  <TableCell className={classes.tableHeadCell} key={field.key} align="center">
                    {ready && t(field.label)}
                  </TableCell>
                ))}
                <TableCell className={classes.tableHeadCell}>
                  <Grid className={classes.actionBtnContainer}>
                    <IconButton
                      onClick={() => handleAddProduct()}
                      className={classes.iconButton}
                    >
                      <PlusCircleIcon className={classes.addIcon} />
                    </IconButton>
                    <IconButton onClick={() => {}} className={classes.iconButton}>
                      <SettingsIcon className={classes.settingsIcon} />
                    </IconButton>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierProducts.map((product, i) => (
                <TableRow key={product.article}>
                  {inventoryFields.map((field) => (
                    <TableCell className={classes.tableBodyCell} key={`${product.article}_${field.key}`}>
                      <TextField
                        id={`inventory_${i}_${field.key}`}
                        fullWidth
                        value={product[field.key]}
                        variant="outlined"
                        className={classes.inputStyle}
                        classes={{
                          root: classes.inputRoot,
                        }}
                        onChange={(e) => handleChange(e.target.value, i, field.key)}
                      />
                    </TableCell>
                  ))}
                  <TableCell className={classes.tableBodyCell}>
                    <Grid className={classes.actionBtnContainer}>
                      <IconButton onClick={() => handleDeleteProduct(i)}>
                        <SvgIcon style={{ display: 'flex' }}>
                          <DeleteIcon className={classes.deleteIcon} />
                        </SvgIcon>
                      </IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid className={classes.modalFooter}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => closeProductsModal()}
                className={classes.cancelBtn}
              >
                {ready && t('SUPPLIER_PRODUCTS_CANCEL')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.saveBtn}
                onClick={() => handleSave()}
                // disabled={isSubmitting}
              >
                {ready && t('SUPPLIER_PRODUCTS_SAVE')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

SupplierProducts.propTypes = {
  supplierProductsOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  products: PropTypes.array,
  updateProducts: PropTypes.func,
  supplier: PropTypes.object,
};

export default SupplierProducts;
