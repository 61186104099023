import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  splitScreenBtn: {
    height: 36,
    width: 36,
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    padding: 0,
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 4,
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  splitScreenIcon: {
    height: 15,
    width: 15,
    color: '#334D6E',
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: 7,
    backgroundColor: '#e0e0e0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -8,
    marginTop: -5
  },
  count: {
    color: '#334D6E',
    fontSize: 8,
  },
  attachmentsContainer: {
    maxWidth: '200px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 8
  },
  attachmentTitle: {
    fontSize: 15,
    color: '#334D6E',
    fontWeight: '500'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 0px 2px 0px'
  },
  dotIcon: {
    color: '#334D6E',
    fontSize: 13
  },
  attachmentLabel: {
    fontSize: 13,
    color: '#3E8AFF',
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: '0px 0px 0px 5px'
  },
}));

export default useStyles;
