import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    fontSize: 14,
    color: '#334D6E'
  },
  parentPadding: {
    padding: theme.spacing(3)
  },
  planContainer: {
    padding: 20
  },
  subsTitle: {
    fontSize: 32,
    fontWeight: 500,
    color: theme.palette.secondary.main
  },
  description: {
    fontSize: 14,
    color: '#334D6E',
    paddingBottom: 8,
  },
  extraDescription: {
    marginTop: 16,
    fontSize: 14,
    color: '#334D6E',
    paddingBottom: 8,
    width: 600
  },
  descTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: '#334D6E',
    paddingTop: 16,
    paddingBottom: 8
  },
  progressContainer: {
    paddingTop: 8,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  progressBackgroundRound: {
    backgroundColor: '#E0E0E0',
    height: 24,
    borderRadius: 20,
    overflow: 'hidden'
  },
  progressBackground: {
    backgroundColor: '#E0E0E0',
    // maxWidth: 400,
    // width: 400,
    height: 24,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20
  },
  progressIndicator: {
    height: 24,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20
  },
  extraProgressBackground: {
    marginLeft: 2,
    backgroundColor: '#E0E0E0',
    height: 24,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20
  },
  extraProgressIndicator: {
    height: 24,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20
    // maxWidth: 400
  },
  progressText: {
    marginLeft: 8,
    fontSize: 12
  },
  summaryTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E',
    paddingBottom: 8,
  },
  summaryValue: {
    fontSize: 20,
    fontWeight: 500,
    color: '#334D6E',
  },
  summaryEmpTitle: {
    fontSize: 16,
    color: theme.palette.secondary.main
  },
  summaryEmpValue: {
    fontSize: 24,
    color: theme.palette.secondary.main
  },
  progressIndicator0: {
    backgroundColor: '#4CAF50',
  },
  progressIndicator50: {
    backgroundColor: '#FDD835',
  },
  progressIndicator80: {
    backgroundColor: '#FF9800',
  },
  progressIndicator100: {
    backgroundColor: '#F44336',
  },
  usageValue: {
    fontSize: 36,
    // fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#334D6E'
    // color: theme.palette.secondary.main
  },
  plusSymbol: {
    // color: '#E53935',
    color: '#334D6E',
    paddingLeft: 8,
    paddingRight: 8
  },
  extraUsage: {
    // color: '#E53935'
    color: '#334D6E'
  },
  usageDescription: {
    fontSize: 12,
    color: '#334D6E',
    paddingLeft: 8,
    textAlign: 'left',
    marginTop: 16
  },
  actionContainer: {
    paddingTop: 16,
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 520
  },
  changeBtn: {
    textTransform: 'none'
  },
  extraBtn: {
    color: '#334D6E',
    textTransform: 'none'
  },
  cancelBtn: {
    color: '#334D6E',
    border: '1px #e4e4e4 solid',
    textTransform: 'none',
    marginLeft: 24
  },
  buyExtraBtn: {
    color: '#334D6E',
    border: '1px #e4e4e4 solid',
    textTransform: 'none'
  },
  warningText: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E',
    alignItems: 'center',
    lineHeight: '28px'
  },
  warningSymbol: {
    fontSize: 26,
    fontWeight: 500,
    color: '#F44336',
    paddingRight: 16
  },
  planName: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  planFunctions: {
    paddingLeft: 60,
    columns: 2,
    '-webkit-columns': 2,
    '-moz-columns': 2,
    maxWidth: 700
  },
  sliderContainer: {
    width: 500,
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: 16
  },
  sliderComponent: {
    paddingLeft: 20,
    paddingRight: 20
  },
  sliderLabelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12
  },
  sliderLimitContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 90
  },
  sliderLimitContainerS: {
    display: 'flex',
    flexDirection: 'column',
    width: 150
  },
  sliderLimitContainerB: {
    display: 'flex',
    flexDirection: 'column',
    width: 113
  },
  sliderLimitContainerC: {
    display: 'flex',
    flexDirection: 'column',
    width: 145
  },
  sliderRail: {
    height: 16,
    borderRadius: 16,
    marginTop: 4
  },
  sliderTrack: {
    height: 16,
    borderRadius: 16,
    marginTop: 4
  },
  sliderThumb: {
    height: 36,
    width: 36,
    border: '3px solid white'
  },
  sliderMark: {
    height: 6,
    width: 6,
    borderRadius: 6,
    top: 18,
    color: 'white',
    marginTop: 4
  },
  limitText: {
    fontSize: 13,
    color: '#334D6E',
  },
  selectedLimitText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E',
  },
  labelText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#334D6E'
  },
  selectedLabelText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E'
  },
  paper: {
    maxHeight: '94%',
    overflow: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    maxHeight: 760,
  },
  modalBody: {
    maxWidth: 600,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    maxHeight: 760,
  },
  feedbackText: {
    borderRadius: 0,
    padding: 1,
  },
  cancelSubs: {
    fontWeight: '600',
    textTransform: 'capitalize'
  },
  cancelSubsHeader: {
    marginBottom: 24,
    color: '#334D6E',
    textAlign: 'center'
  },
  unsubscribeCheckbox: {
    marginTop: 10,
    marginBottom: 10,
    color: '#334D6E',
  },
  msg: {
    color: '#334D6E'
  },
  secondaryMsg: {
    marginTop: 30,
    marginBottom: 15,
    color: '#334D6E',
  },
  backBtn: {
    fontWeight: '600',
    color: '#334D6E',
    textTransform: 'capitalize'
  },
  noPadding: {
    padding: '0px 16px',
    color: '#334D6E',
    fontSize: 13
  },
  closeBtn: {
    padding: '2!important',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fieldStyle: {
    paddingBottom: 24
  },
  vatMessage: {
    fontSize: 12,
    color: '#334D6E',
    fontStyle: 'italic'
  },
  cancelContainer: {
    marginBottom: 16
  },
  warningBanner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  noCardAddedContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 0px'
  },
  priorityIcon: {
    color: '#FF1100'
  },
  noCardText: {
    fontSize: 16,
    color: '#334D6E',
    fontWeight: 500,
  }
}));

export default useStyles;
