import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    // paddingBottom: 20
  },
  headerStyle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  }
}));

export default useStyles;
