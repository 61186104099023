import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: 0
  },
  statusField: {
    flexBasis: 200
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 16,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  userImage: {
    flexShrink: 0,
    height: 20,
    width: 20
  },
  bulkUBL: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.button.action,
    padding: '3px 8px'
  },
  bulkDelete: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.fail
  },
  filterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '24px 0px 8px'
  },
  circularProgressIcon: {
    width: 20,
    height: 20
  },
  arrowCell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  pointer: {
    cursor: 'pointer'
  },
  arrow: {
    paddingLeft: 4
  },
  paginationCaption: {
    fontSize: 13
  },
  headerCellFirst: {
    color: '#323C47',
    fontSize: 15,
    fontWeight: 500,
    padding: '16px 24px!important'
  },
  headerCell: {
    color: '#323C47',
    fontSize: 15,
    fontWeight: 500,
    padding: '16px!important'
  },
  userCell: {
    color: '#707683',
    fontSize: 13,
    fontWeight: 400,
    paddingRight: '16px!important'
  },
  timeCell: {
    color: '#707683',
    fontSize: 13,
    fontWeight: 400,
    textAlign: 'right',
    paddingRight: '16px!important'
  }
}));

export default useStyles;
