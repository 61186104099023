import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const DeleteLineModal = (props) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const {
    deleteLineOpen,
    deleteLine,
    onDeleteLineConfirm,
    onDeleteLineClose,
  } = props;
  return (
    <Modal
      className={classes.modal}
      open={deleteLineOpen}
      onClose={onDeleteLineClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={deleteLineOpen}>
        <div className={classes.paper}>
          <Grid className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>
              {ready && t('INVOICE_EDIT_FORM_DELETE_INVOICE_LINE')}
            </Typography>
            <IconButton onClick={onDeleteLineClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <Grid className={classes.deleteMessageText}>
            <Typography variant="caption">
              {t('INVOICE_LINE_DELETE_CONFIRMATION_MESSAGE')}
            </Typography>
          </Grid>
          <Grid className={classes.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                className={classes.closeBtn}
                onClick={onDeleteLineClose}
              >
                {ready && t('INVOICE_EDIT_FORM_DELETE_LINE_CANCEL')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitBtn}
                onClick={() => onDeleteLineConfirm(deleteLine)}
              >
                {ready && t('INVOICE_EDIT_FORM_DELETE_LINE')}
              </Button>
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

DeleteLineModal.propTypes = {
  deleteLineOpen: PropTypes.bool,
  deleteLine: PropTypes.object,
  onDeleteLineConfirm: PropTypes.func,
  onDeleteLineClose: PropTypes.func,
};

export default DeleteLineModal;
