import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Grid, Typography
} from '@material-ui/core';

import Page from 'src/Shared/components/Page/Page';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function InvoiceImageNotFoundView() {
  const {
    BRAND_NAME, BRAND_DOMAIN, BRAND_URL, API
  } = useContext(ConfigContext);
  const classes = useStyles();
  const [company, setCompany] = useState('');
  const [transactionNumber, setTransactionNumber] = useState('');

  useEffect(() => {
    const { search } = window.location;

    if (search && search.indexOf('&') > 0) {
      const params = search.split('&');
      if (params.length > 0) {
        for (let i = 0; i < params.length; i++) {
          if (params[i].indexOf('tx=') >= 0) {
            const parts = params[i].split('=');

            if (parts.length > 0) {
              setTransactionNumber(parts[1]);
            }
          }

          if (params[i].indexOf('company=') >= 0) {
            const parts = params[i].split('=');

            if (parts.length > 0) {
              setCompany(parts[1]);
            }
          }
        }
      }
    }
  }, []);

  return (
    <>
      <Page
        className={classes.root}
        title={`${BRAND_NAME} Statistics`}
      >
        <Container className={classes.container}>
          <Grid className={classes.innerContainer}>
            <Button onClick={() => window.open(BRAND_URL, '_blank')} className={classes.logoBtn}>
              <img src={`${API.brandLogo}${BRAND_DOMAIN}`} alt="" className={classes.logo} />
            </Button>
            <Typography className={classes.message}>
              {
                transactionNumber && company
                  ? `PDF Invoice Image Not Available for Transaction Number(${transactionNumber}) for the Company(${company}).`
                  : 'PDF Invoice Image Not Available.'
              }
            </Typography>
          </Grid>
          <Grid className={classes.copyrightContainer}>
            <Typography>
              Copyright © 2022
              {' '}
              <a
                href={BRAND_URL}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.copyrightLink}
              >
                {BRAND_NAME}
              </a>
              , All rights reserved.
            </Typography>
          </Grid>
        </Container>
      </Page>
    </>
  );
}

InvoiceImageNotFoundView.propTypes = {
  stats: PropTypes.shape({
    total: PropTypes.number,
  })
};

export default InvoiceImageNotFoundView;
