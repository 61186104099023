/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  alpha, useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

function Chart({
  className,
  dataDigitized,
  dataUploading,
  dataToreview,
  dataTovalidate,
  dataToexport,
  dataExported,
  dataRejected,
  dataError,
  dataAmountExported,
  labels,
  ...rest
}) {
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const theme = useTheme();

  const data = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);

    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.2));
    gradient.addColorStop(0.9, 'rgba(255,255,255,0)');
    gradient.addColorStop(1, 'rgba(255,255,255,0)');

    return {
      datasets: [
        {
          label: `${ready && t('DASHBOARD_OVERVIEW_DIGITIZED')} ${ready && t('DASHBOARD_CHART_INVOICES')}`,
          data: dataDigitized,
          amountData: dataAmountExported,
          backgroundColor: gradient,
          borderColor: '#109CF1',
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: theme.palette.secondary.main
        },
        {
          label: `${ready && t('DASHBOARD_OVERVIEW_UPLOADING')} ${ready && t('DASHBOARD_CHART_INVOICES')}`,
          data: dataUploading,
          amountData: dataAmountExported,
          backgroundColor: gradient,
          borderColor: '#0b6da8',
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#0b6da8'
        },
        {
          label: `${ready && t('DASHBOARD_OVERVIEW_REVIEW')} ${ready && t('DASHBOARD_CHART_INVOICES')}`,
          data: dataToreview,
          amountData: dataAmountExported,
          backgroundColor: gradient,
          borderColor: '#4dabf5',
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#4dabf5'
        },
        {
          label: `${ready && t('DASHBOARD_OVERVIEW_VALIDATE')} ${ready && t('DASHBOARD_CHART_INVOICES')}`,
          data: dataTovalidate,
          amountData: dataAmountExported,
          backgroundColor: gradient,
          borderColor: '#4dabf5',
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#4dabf5'
        },
        {
          label: `${ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')} ${ready && t('DASHBOARD_CHART_INVOICES')}`,
          data: dataToexport,
          amountData: dataAmountExported,
          backgroundColor: gradient,
          borderColor: '#4dabf5',
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#4dabf5'
        },
        {
          label: `${ready && t('DASHBOARD_OVERVIEW_EXPORTED')} ${ready && t('DASHBOARD_CHART_INVOICES')}`,
          data: dataExported,
          amountData: dataAmountExported,
          backgroundColor: gradient,
          borderColor: '#4dabf5',
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#4dabf5'
        },
        {
          label: `${ready && t('DASHBOARD_OVERVIEW_REJECTED')} ${ready && t('DASHBOARD_CHART_INVOICES')}`,
          data: dataRejected,
          amountData: dataAmountExported,
          backgroundColor: gradient,
          borderColor: '#4dabf5',
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#4dabf5'
        },
        {
          label: `${ready && t('DASHBOARD_OVERVIEW_ERRORED')} ${ready && t('DASHBOARD_CHART_INVOICES')}`,
          data: dataError,
          amountData: dataAmountExported,
          backgroundColor: gradient,
          borderColor: '#3577ab',
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#3577ab'
        }
      ],
      labels
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: false,
            labelString: t('DASHBOARD_CHART_Y_AXIS_LABEL')
          },
          gridLines: {
            borderDash: [16],
            borderDashOffset: [2],
            color: '#90A0B7',
            drawBorder: false,
            zeroLineBorderDash: [16],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: '#90A0B7'
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 7,
            precision: 0,
            callback: (value) => (value > 0 ? `${value}` : value)
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      bodySpacing: 12,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => {},
        label: () => {},
        afterLabel: (tooltipItem, d) => [
          `${ready && t('DASHBOARD_CHART_INVOICES')} ${
            tooltipItem.datasetIndex === 0 ? ready && t('DASHBOARD_OVERVIEW_DIGITIZED')
              : tooltipItem.datasetIndex === 1 ? ready && t('DASHBOARD_OVERVIEW_UPLOADING')
                : tooltipItem.datasetIndex === 2 ? ready && t('DASHBOARD_OVERVIEW_REVIEW')
                  : tooltipItem.datasetIndex === 3 ? ready && t('DASHBOARD_OVERVIEW_VALIDATE')
                    : tooltipItem.datasetIndex === 4 ? ready && t('DASHBOARD_OVERVIEW_TO_EXPORT')
                      : tooltipItem.datasetIndex === 5 ? ready && t('DASHBOARD_OVERVIEW_EXPORTED')
                        : tooltipItem.datasetIndex === 6 ? ready && t('DASHBOARD_OVERVIEW_REJECTED')
                          : t('DASHBOARD_OVERVIEW_ERRORED')
          }: ${tooltipItem.yLabel}`,
          `${ready && t('DASHBOARD_CHART_TOTAL_AMOUNT_EXPORTED')} ${d.datasets[tooltipItem.datasetIndex].amountData[tooltipItem.index]}`,
        ]
      }
    }
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Line
        data={data}
        options={options}
      />
    </div>
  );
}

Chart.propTypes = {
  className: PropTypes.string,
  dataDigitized: PropTypes.array,
  dataUploading: PropTypes.array,
  dataToreview: PropTypes.array,
  dataTovalidate: PropTypes.array,
  dataToexport: PropTypes.array,
  dataExported: PropTypes.array,
  dataRejected: PropTypes.array,
  dataError: PropTypes.array,
  dataAmountExported: PropTypes.array,
  labels: PropTypes.array.isRequired
};

export default Chart;
