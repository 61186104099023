import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  halfPaper: {
    width: '52%',
    maxHeight: '90%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  fieldContainerFirst: {
    marginTop: 10
  },
  primaryTextColor: {
    color: '#334D6E',
  },
  userForm: {
    width: '100%'
  },
  cancelBtn: {
    minWidth: 140,
    height: 42,
    textTransform: 'none',
    color: '#334D6E',
    fontSize: 12,
    padding: '0px 0px'
  },
  actionBtn: {
    minWidth: 140,
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
    fontSize: 12,
    padding: '0px 0px'
  },
}));

export default useStyles;
