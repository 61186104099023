const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3)
  },
  container: {
    paddingTop: 30,
    paddingLeft: 16,
    paddingRight: 16
  },
  tabContainer: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  tabButton: {
    width: '100%',
    height: '100%',
    padding: '16px 16px',
    transition: 'none',
    borderRadius: 0,
    minWidth: 40
  },
  selectedTabButton: {
    border: '0px solid',
    borderBottomWidth: 3,
    borderBottomColor: theme.palette.line.primary,
    width: '100%',
    height: '100%',
    padding: '16px 16px',
    transition: 'none',
    borderRadius: 0,
    minWidth: 40
  },
  tabLabelContainer: {},
  tabLabel: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    color: '#334D6E'
  },
  selectedTabLabel: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    color: theme.palette.text.highlight2
  },
  tabSubtitle: {
    fontSize: 11,
    textTransform: 'none',
    color: theme.palette.text.secondary
  },
  icon: {
    color: theme.palette.icon.gray
  },
  selectedIcon: {
    color: theme.palette.icon.primary
  },
  banner: {
    height: 140,
    backgroundColor: '#3E8AFF'
  },
  profileDetails: {
    height: 151,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingBottom: 10
  },
  tabsSection: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    filter: 'drop-shadow(0px 2px 0px rgba(51, 77, 110, 0.1))'
  },
  tab: {
    height: '100%',
    margin: '0px 16px'
  },
  nameText: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px',
    marginBottom: 0
  },
  companyText: {
    fontSize: 12,
    fontWeight: 400,
    color: '#334D6E',
    lineHeight: '17px',
    marginBottom: 10
  },
  userType: {
    maxWidth: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(62, 138, 255, 0.3)',
    borderRadius: 20,
    padding: '4px 12px',
    fontSize: 14,
    fontWeight: 600,
    color: '#3E8AFF',
    lineHeight: '21px'
  },
  avatar: {
    height: 100,
    width: 100
  },
  avatarContainer: {
  },
  editIconContainer: {
    position: 'absolute',
    top: 80,
    right: 12,
    backgroundColor: 'white',
    width: 24,
    height: 24,
    padding: 0,
    zIndex: 2,
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)'
  },
  editIcon: {
    width: '0.9rem'
  }
});

export default useStyles;
