import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
  axiosHeaders, getLocalisedErrorString, isActionPermitted, isShowManageFields, permissions, validateToken
} from 'src/Shared/utils/helpers';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import authService from 'src/Shared/utils/services/authService';
import { ERRORS } from 'src/config';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import {
  Box, Button, Card, CardContent, FormHelperText, Grid, TextField, Tooltip
} from '@material-ui/core';
import {
  SettingsOutlined as SettingsIcon,
} from '@material-ui/icons';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import ConfigContext from 'src/Contexts';
import ManageFields from 'src/Shared/components/ManageFields/ManageFields';
import useStyles from './style';

const CostCenterStandard = (props) => {
  const {
    costCenter,
    getAllCostCenters,
    handleClose,
    onSuccess,
    className,
    ...rest
  } = props;

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [ccFields, setCCFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCCFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/cc/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCCFields(response.data.data.filter((f) => f.isAvailable));
          setLoading(false);
        } else {
          setCCFields([]);
          setLoading(false);
        }
      }
    } catch (error) {
      setCCFields([]);
      setLoading(false);
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => {
    getCCFields();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFieldAvailable = (field) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Formik
        initialValues={{
          code: costCenter?.code || '',
          name: costCenter?.name || '',
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string()
            .max(255)
            .matches('^\\S*$', 'COST_CENTER_CODE_SPACES_NOT_ALLOWED'),
          name: Yup.string()
            .max(80, 'COST_CENTER_NAME_MAX_VALIDATION')
            .required('COST_CENTER_NAME_REQUIRED')
        })}
        onSubmit={async (values, {
          resetForm,
          setErrors,
          setStatus,
          setSubmitting
        }) => {
          try {
            if (!validateToken()) {
              enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                variant: 'error',
                persist: true
              });
              setTimeout(() => {
                authService.logout(LOGIN_PANEL_URL);
              }, 2000);
              return;
            }

            const body = {
              code: values.code,
              name: values.name,
            };

            const url = `${API.costCentersByCompany}/${encodeURIComponent(user.companyID)}`;
            let response = null;
            let snackText = '';

            if (costCenter && costCenter.name) {
              response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
              snackText = 'COST_CENTERS_UPDATE_SUCCESS';
            } else {
              response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
              snackText = 'COST_CENTERS_ADD_SUCCESS';
            }
            if (response?.data?.success) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar(ready && t(snackText), {
                variant: 'success',
                persist: true,
                style: { maxWidth: 400 }
              });
              getAllCostCenters();
              handleClose();
              if (onSuccess) {
                onSuccess(response.data.id);
              }
            }
          } catch (error) {
            setStatus({ success: false });
            setSubmitting(false);
            if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
              const errParts = error.response.data.i18n.split('__TFMSG__');
              setErrors({
                [errParts[0]]: errParts[1]
              });
            } else {
              setErrors({
                submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
              });
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setErrors,
          touched,
          values
        }) => (
          <form
            className={clsx(classes.root, className)}
            {...rest}
          >
            {loading ? (<LoadingScreen />) : (
              <Card style={{ boxShadow: 'none' }}>
                <CardContent style={{ padding: '20px 0px 0px 0px' }}>
                  <Grid container spacing={3}>
                    <Grid item lg={ccFields.length ? 6 : 12} md={ccFields.length ? 6 : 12} xs={12} key="name">
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && (ready && t(errors.name))}
                        label={ready && t('COST_CENTER_NAME')}
                        name="name"
                        onClick={() => setErrors({})}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                        className={classes.inputField}
                      />
                    </Grid>
                    {
                    ccFields.map((f) => {
                      if (!isFieldAvailable(f)) {
                        return null;
                      }
                      switch (f.key) {
                        default:
                          return (
                            <Tooltip key={f.key} title={(!costCenter || f.key !== 'code') ? '' : t('DIMENSION_CODE_CANNOT_EDIT')}>
                              <Grid item lg={6} md={6} xs={12} key={f.key}>
                                <TextField
                                  error={Boolean(touched[f.key] && errors[f.key])}
                                  fullWidth
                                  helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                  label={ready && t(f.label)}
                                  name={f.key}
                                  onClick={() => setErrors({})}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  required={f.isRequired}
                                  value={values[f.key]}
                                  variant="outlined"
                                  className={classes.inputField}
                                  disabled={costCenter && f.key === 'code'}
                                />
                              </Grid>
                            </Tooltip>
                          );
                      }
                    })
                  }
                    <Grid
                      item
                      xs={12}
                    >
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      {isShowManageFields(user, 'applyParentFieldsCC') && (
                        <Grid mt={2} item xs={12}>
                          <Button
                            variant="outlined"
                            className={classes.manageFieldsBtn}
                            onClick={() => setShowManageFields(true)}
                          >
                            <SettingsIcon className={classes.btnIcon} />
                            {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                          </Button>
                        </Grid>
                      )}
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user.permissions)}
                          onClick={handleSubmit}
                        >
                          {ready && t('COST_CENTERS_SAVE_CHANGES')}
                        </Button>
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={handleClose}
                          className={classes.yearField}
                        >
                          {ready && t('COST_CENTERS_CANCEL')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </form>
        )}
      </Formik>
      <ManageFields
        open={showManageFields}
        entity="cc"
        fields={ccFields}
        fetchFields={getCCFields}
        closeModal={handleCloseManageFields}
        parameterCompany={user.companyID}
      />
    </>
  );
};

CostCenterStandard.propTypes = {
  handleClose: PropTypes.func,
  getAllCostCenters: PropTypes.func,
  hasError: PropTypes.func,
  onSuccess: PropTypes.func,
  className: PropTypes.string,
  costCenter: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  })
};

export default CostCenterStandard;
