import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box, Card, CardContent, Divider, Typography, Grid, Hidden
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Chart from 'src/Dashboard/containers/InvoicesProcessed/components/Chart/Chart';
import SimpleSelect from 'src/Shared/components/SimpleSelect/SimpleSelect';
import { filterProcessedInvoices } from 'src/Shared/utils/helpers';
import {
  invoiceFilterOptions, invoiceFilterOptionsNew, timelyFilterOptions
} from 'src/config';
import useStyles from './style';

function Charges({
  className, uploading, digitized, toreview, tovalidate, toexport, exported, errored, rejected, amountExported,
  iLabels, user, ...rest
}) {
  const classes = useStyles();
  const [dateFilter, setDateFilter] = useState(0);
  const [invoiceFilter, setInvoiceFilter] = useState(0);
  const { ready, t } = useTranslation();

  const [res, setRes] = useState(null);

  const isToValidateStatusAllowed = user.customisations.includes('toValidateStatus');

  const newInvoiceFilterOptionsNew = isToValidateStatusAllowed ? invoiceFilterOptionsNew : invoiceFilterOptionsNew.filter((o) => o.value !== 3);

  useEffect(() => {
    const result = filterProcessedInvoices(
      dateFilter, iLabels, uploading, digitized, toreview,
      tovalidate, toexport, exported, errored, rejected, amountExported, t
    );
    setRes(result);
  }, [iLabels]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (iLabels && iLabels.length > 0) {
      const result = filterProcessedInvoices(
        dateFilter, iLabels, uploading, digitized, toreview,
        tovalidate, toexport, exported, errored, rejected, amountExported, t
      );
      setRes(result);
    }
  }, [dateFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid container className={classes.header}>
        <Grid item xs={12} sm={4} className={classes.titleRow}>
          <Typography className={classes.headerText}>
            {ready && t('DASHBOARD_CHART_INVOICES')}
          </Typography>
          <Hidden smUp>
            <Grid className={classes.select}>
              <SimpleSelect
                label={ready && t('DASHBOARD_CHART_STATUS')}
                filter={invoiceFilter}
                filterOptions={user.role === 'company' ? invoiceFilterOptions : newInvoiceFilterOptionsNew}
                setFilter={(e) => setInvoiceFilter(e.target.value)}
              />
            </Grid>
          </Hidden>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={8} className={classes.select}>
            <SimpleSelect
              label={ready && t('DASHBOARD_CHART_STATUS')}
              filter={invoiceFilter}
              filterOptions={user.role === 'company' ? invoiceFilterOptions : newInvoiceFilterOptionsNew}
              setFilter={(e) => setInvoiceFilter(e.target.value)}
            />
            <SimpleSelect
              label={ready && t('DASHBOARD_CHART_SHOW')}
              filter={dateFilter}
              filterOptions={timelyFilterOptions}
              setFilter={(e) => setDateFilter(e.target.value)}
            />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} className={classes.select}>
            <SimpleSelect
              label={ready && t('DASHBOARD_CHART_SHOW')}
              filter={dateFilter}
              filterOptions={timelyFilterOptions}
              setFilter={(e) => setDateFilter(e.target.value)}
            />
          </Grid>
        </Hidden>
      </Grid>
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box
            height={205.39}
            minWidth="100%"
          >
            {
              res && res.labels && res.labels.length > 0
                ? (
                  <Chart
                    className={classes.chart}
                    dataDigitized={invoiceFilter === 0 ? res.digitized : null}
                    dataUploading={invoiceFilter === 1 ? res.uploading : null}
                    dataToreview={invoiceFilter === 2 ? res.toreview : null}
                    dataTovalidate={invoiceFilter === 3 ? res.tovalidate : null}
                    dataToexport={invoiceFilter === 4 ? res.toexport : null}
                    dataExported={invoiceFilter === 5 ? res.exported : null}
                    dataError={invoiceFilter === 6 ? res.errored : null}
                    dataRejected={invoiceFilter === 7 ? res.rejected : null}
                    dataAmountExported={res.amountExported}
                    labels={res.labels}
                  />
                )
                : (
                  <Chart
                    className={classes.chart}
                    dataDigitized={invoiceFilter === 0 ? digitized.slice(0, 12) : null}
                    dataUploading={invoiceFilter === 1 ? uploading.slice(0, 12) : null}
                    dataToreview={invoiceFilter === 2 ? toreview.slice(0, 12) : null}
                    dataTovalidate={invoiceFilter === 3 ? tovalidate.slice(0, 12) : null}
                    dataToexport={invoiceFilter === 4 ? toexport.slice(0, 12) : null}
                    dataExported={invoiceFilter === 5 ? exported.slice(0, 12) : null}
                    dataError={invoiceFilter === 6 ? errored.slice(0, 12) : null}
                    dataRejected={invoiceFilter === 7 ? rejected.slice(0, 12) : null}
                    dataExportedAmount={amountExported.slice(0, 12)}
                    labels={iLabels.slice(0, 12)}
                  />
                )
            }
          </Box>
        </PerfectScrollbar>
      </CardContent>
      {/* <Typography align="center" className={classes.bottomLabel}>Total: 4568</Typography> */}
    </Card>
  );
}

Charges.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    role: PropTypes.string,
    companyID: PropTypes.string,
    customisations: PropTypes.array
  }),
  digitized: PropTypes.array,
  uploading: PropTypes.array,
  processed: PropTypes.array,
  toreview: PropTypes.array,
  tovalidate: PropTypes.array,
  toexport: PropTypes.array,
  exported: PropTypes.array,
  rejected: PropTypes.array,
  errored: PropTypes.array,
  iLabels: PropTypes.array,
  amountExported: PropTypes.array,
};

export default Charges;
