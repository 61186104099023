import { alpha } from '@material-ui/core';

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  },
  statusElementSelected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 12px',
    border: '1px solid #E1E4E8',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 24,
    maxWidth: 140,
    cursor: 'pointer',
    marginRight: 0
  },
  statusTextDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusText: {
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    whiteSpace: 'nowrap'
  },
  moreIcon: {
    cursor: 'pointer',
    color: '#334d6f',
    marginLeft: -8,
    width: 18
  },
  halfPaper: {
    width: '90%',
    maxWidth: 500,
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  INVOICE_EDIT_FORM_INVOICE_TYPE_INVOICE: {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.iType.invoice, 1)
  },
  INVOICE_EDIT_FORM_INVOICE_TYPE_CREDIT_INVOICE: {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.iType.creditNote, 1)
  },
  INVOICE_EDIT_FORM_INVOICE_TYPE_ATTACHMENT: {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.iType.attachment, 1)
  },
  primaryTextColor: {
    color: '#334D6E',
  },
  fieldContainerFirst: {
    marginTop: 10
  },
  btnGroup: {
    width: '100%',
    marginTop: 10,
  },
  link: {
    textDecoration: 'underline',
    color: '#3E8AFF',
    cursor: 'pointer',
  },
  disabledLink: {
    textDecoration: 'underline',
    color: '#334D6E',
  },
  addUserDisabled: {
    marginBottom: 10,
    marginTop: 10,
  },
  addUserDisabledText: {
    fontSize: 11,
  },
  warningBanner: {
    width: '100%',
    paddingLeft: 20,
  },
  cancelBtn: {
    width: 108,
    height: 42,
    textTransform: 'none',
    color: '#334D6E'
  },
  validateButton: {
    width: 108,
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
  },
  dropdownBtn: {
    color: '#334D6E',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
  },
});

export default useStyles;
