import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #DDDDDD',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
  },
  chart: {
    height: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 6px 16px 16px'
  },
  headerText: {
    color: theme.palette.text.title,
    fontWeight: 500,
    fontSize: 15
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  bottomLabel: {
    paddingTop: 28,
    paddingBottom: 6,
    fontSize: 9,
    fontWeight: 'bold'
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export default useStyles;
