import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Avatar, Grid, Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useStyles from './style';

const InvoiceJiraItem = ({
  invoice, filters, query, time, page, jiraLimit, index, total, startTime, endTime
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const calculateInvoicePage = (count) => (page * jiraLimit) + count + 1;

  const handleClick = () => {
    if (invoice.outOfLimit) {
      history.push('/account/subscription');
    } else if (invoice.status >= 16 && invoice.assistantID) {
      if (user.customisations.includes('cdcDocumentView')) {
        history.push(`/documents/cdc/${invoice.assistantID}/edit?viewJira=true${!filters.recent.isNan
          ? `&filters=${filters.recent}` : ''}${query !== '' ? `&query=${query}` : ''}${(startTime !== '' && endTime !== '')
          ? `&start_time=${startTime}&end_time=${endTime}` : time !== '' && `&time=${time}`}${page !== null
          ? `&page=${page}` : ''}${jiraLimit ? `&limit=${jiraLimit}` : ''}&document_page=${calculateInvoicePage(index)}&total=${total}`);
      } else {
        history.push(
          `/documents/${invoice.assistantID}/edit?viewJira=true${!filters.recent.isNan
            ? `&filters=${filters.recent}` : ''}${query !== ''
            ? `&query=${query}` : ''}${(startTime !== '' && endTime !== '')
            ? `&start_time=${startTime}&end_time=${endTime}` : time !== '' && `&time=${time}`}${page !== null
            ? `&page=${page}` : ''}${jiraLimit
            ? `&limit=${jiraLimit}` : ''}&document_page=${calculateInvoicePage(index)}&total=${total}`
        );
      }
    }
  };

  return (
    <Grid
      className={classes.root}
      onClick={() => handleClick()}
    >
      <Grid className={classes.header}>
        <Typography className={classes.invoiceNumber}>
          {invoice.documentNumber}
        </Typography>
      </Grid>
      <Grid className={classes.body}>
        <Typography className={classes.dateText}>
          {moment.unix(invoice.appUploadedDate).format('MM/DD/YYYY')}
        </Typography>
        {
          invoice.appUserImage && (
            <Avatar
              alt="user"
              className={classes.avatar}
              src={invoice.appUserImage}
            />
          )
        }
      </Grid>
    </Grid>
  );
};

InvoiceJiraItem.propTypes = {
  invoice: PropTypes.object,
  filters: PropTypes.shape({
    recent: PropTypes.number
  }),
  query: PropTypes.string,
  time: PropTypes.string,
  page: PropTypes.number,
  jiraLimit: PropTypes.number,
  index: PropTypes.number,
  total: PropTypes.number,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
};

export default InvoiceJiraItem;
