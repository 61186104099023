import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  pointer: {
    height: 64,
  },
  headerStyle: {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(51, 77, 110, 0.5)',
    align: 'center'
  },
  cellStyle: {
    fontSize: 15,
    color: '#334D6E'
  },
  row: {
    height: 48
  },
  caption: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: 'rgba(51, 77, 110, 0.5)',
    paddingLeft: 24
  },
  paginationComp: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  paginationCaption: {
    fontSize: 13,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  paginationMenu: {
    fontSize: 13,
    color: '#334D6E'
  },
  paginationToolbar: {
    paddingRight: 15
  },
  paginationActions: {
    marginLeft: 0
  },
  statusLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    textTransform: 'capitalize',
    width: 96,
    fontSize: 14,
    fontWeight: 600,
    color: '#3CB573',
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
  statusDot: {
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    width: 29,
    cursor: 'pointer',
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
  sub: {
    color: '#ffc400',
    backgroundColor: 'rgba(255, 196, 0, 0.3)'
  },
  main: {
    color: '#3CB573',
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
  subDot: {
    backgroundColor: 'rgba(255, 196, 0, 0.3)'
  },
  mainDot: {
    backgroundColor: 'rgba(46, 212, 122, 0.3)'
  },
}));

export default useStyles;
