import React, {
  useEffect, useState, useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

import {
  Grid, Button, Container, Typography
} from '@material-ui/core';

import Page from 'src/Shared/components/Page/Page';
import ConfigContext from 'src/Contexts';
import { axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import useStyles from './style';

const QuickbooksOAuth2Callback = () => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const verifyCode = async () => {
    const queryVal = new URLSearchParams(window.location.search);
    const code = queryVal.get('code');
    const state = queryVal.get('state');
    const realmID = queryVal.get('realmId');

    try {
      const response = await Axios.post(
        `${API.quickbooksOAuth2Callback}`,
        {
          code,
          state,
          realmID
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setErrorMessage('');
        setError(false);
        setLoading(false);
        setTimeout(() => {
          history.push('/integrations');
        }, [2000]);
      } else {
        setErrorMessage(t('QUICKBOOKS_VERIFYING_CODE_ERROR'));
        setError(true);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.data) {
        setErrorMessage(getLocalisedErrorString(err.response.data.i18n, t));
      } else {
        setErrorMessage(t('QUICKBOOKS_VERIFYING_CODE_ERROR'));
      }
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ready) {
      verifyCode();
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  const onBackToProcys = () => {
    history.push('/integrations');
  };

  return (
    <Page
      className={classes.root}
      title={ready && t('QUICKBOOKS_OAUTH2_CALLBACK_PAGE_TITLE')}
    >
      <Container className={classes.container}>
        {loading && (
          <Typography className={classes.loadingText}>{ready && t('QUICKBOOKS_VERIFYING_CODE')}</Typography>
        )}
        {!loading && !error && (
          <Typography className={classes.loadingText}>{ready && t('QUICKBOOKS_VERIFYING_CODE_SUCCESS')}</Typography>
        )}
        {!loading && error && (
          <Grid className={classes.errorContainer}>
            <Typography className={classes.loadingText}>{ready && errorMessage}</Typography>
            <Button
              variant="outlined"
              className={classes.backToProcysBtn}
              onClick={onBackToProcys}
              disabled={loading}
            >
              {ready && t('QUICKBOOKS_VERIFYING_CODE_BACK_TO_PROCYS')}
            </Button>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

QuickbooksOAuth2Callback.propTypes = {
};

QuickbooksOAuth2Callback.defaultProps = {};

export default QuickbooksOAuth2Callback;
