import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TopBar from 'src/Shared/layouts/DashboardLayout/TopBar/TopBar';
import useStyles from './style';

function DashboardLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={clsx(classes.content, 'hide-scroll')}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
