import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Button, Card, Grid, Slider, Tooltip, Typography, useMediaQuery,
  Modal, Paper, Checkbox, TextField, Backdrop, FormControlLabel, IconButton, SvgIcon
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close, PriorityHigh } from '@material-ui/icons';

import Page from 'src/Shared/components/Page/Page';
import Invoices from 'src/Auth/components/Invoices/Invoices';
import Billing from 'src/Auth/components/Billing/Billing';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import SubCompanyAccessModal from 'src/Shared/components/SubCompanyAccessModal/SubCompanyAccessModal';
import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import {
  appendContactSupport, axiosHeaders, getLocalisedErrorString, getMaskedCurrencyText, getMaskedText, getSubscriptionTitle,
  isActionPermitted, permissions, sendFeedback, subscriptionLabel
} from 'src/Shared/utils/helpers';
import {
  subscriptionTypes, subscriptionTypesAr
} from 'src/config';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

function MyPlan(props) {
  const { BRAND_NAME, API } = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const { t, ready } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const { isChild } = props;

  const shrink = useMediaQuery('(max-width: 660px)');
  const shrinkSm = useMediaQuery('(max-width: 480px)');

  const [openModal, setModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelImmediately, setCancelImmediately] = useState(false);

  const [openContactModal, setContactModalOpen] = useState(false);
  const [contactEmail, setContactEmail] = useState(user.email);
  const [contactPhone, setContactPhone] = useState(user.companyPhone || '');
  const [contactCompanyName, setContactCompanyName] = useState(user.company || '');
  const [contactFirstname, setContactFirstname] = useState(user.firstname || '');
  const [contactDetails, setContactDetails] = useState('');
  const [contactEmailErr, setContactEmailErr] = useState('');
  const [contactPhoneErr, setContactPhoneErr] = useState('');

  const [indicatorMaxWidth, setIndicatorMaxWidth] = useState(shrink ? shrinkSm ? 120 : 150 : 360); // eslint-disable-line
  const [exIndicatorMaxWidth, setExIndicatorMaxWidth] = useState(shrink ? shrinkSm ? 40 : 50 : 100); // eslint-disable-line

  const [selectedSubscription, setSelectedSubscription] = useState(subscriptionTypes.starter);
  const [customSubscription, setCustomSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subsError, setSubsError] = useState('');
  const [defaultCard, setDefaultCard] = useState(null);

  const [subscription, setSubscription] = useState({
    name: 'Business',
    invLimit: 200,
    invUsed: 260,
    usersLimit: 10,
    usersUsed: 4,
    totalInvoices: 12000,
    totalPayment: '135050',
    nextBillingAmount: 2999,
    currPrice: 0,
    ecCharged: 0,
    startDate: '2021-10-12T14:48:12-05:00',
    endDate: '2022-04-12T14:48:12-05:00'
  });

  const getSubscription = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(API.subscriptionDetails, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data && response.data.data.length > 0) {
        const subs = response.data.data[0];
        setDefaultCard(subs.creditCard.id);
        let balanceFromChargify = subs.balanceInCents;
        if (!balanceFromChargify) {
          balanceFromChargify = 0;
        }

        let currentSubsPrice = subs.currPrice;
        if (!currentSubsPrice) {
          currentSubsPrice = 0;
        }

        const subsObj = {
          id: subs.id,
          name: subs.product.name,
          i18n: subscriptionTypes[Object.keys(subscriptionTypes).find((o) => subs.product.name.toLowerCase().includes(o))]?.i18n || 'MY_PLAN_ENTERPRISE',
          selfServiceUrl: subs.selfServiceUrl,
          invLimit: subs.invoicesLimit || 20,
          invPurchased: subs.extraPurchased || 0,
          ecCarried: subs.ecCarried || 0,
          invUsed: subs.invoicesUsed || 0,
          usersLimit: subs.usersLimit || 1,
          usersUsed: subs.usersUsed || 1,
          totalInvoices: subs.totalInvoices || 0,
          totalPayment: subs.totalRevenueinCents || 0,
          nextBillingAmount: balanceFromChargify + currentSubsPrice || 0,
          startDate: subs.activatedAt,
          endDate: subs.currentPeriodEndsAt,
          ecLimit: subs.ecLimit || 0,
          ecPrice: subs.ecPrice || 0,
          currPrice: subs.currPrice || 0,
          price: subs.currPrice || 0,
          ecCharged: subs.ecCharged || 0,
          toDowngrade: subs.toDowngrade,
          downgradeSubscription: subs.downgradeSubscription
        };
        let currSubs = subscriptionTypes.free;
        subsObj.level = subscriptionTypes.free.level;
        subsObj.value = subscriptionTypes.free.value;
        // let nextSubs = subscriptionTypes.starter;

        if (subs.product.name.toLowerCase().indexOf('trial') >= 0) {
          // nextSubs = subscriptionTypes.business;
          currSubs = subscriptionTypes.free;
          subsObj.level = -1;
          subsObj.value = 0;
        }

        if (subs.product.name.toLowerCase().indexOf('starter') >= 0) {
          // nextSubs = subscriptionTypes.business;
          currSubs = subscriptionTypes.starter;
          subsObj.level = subscriptionTypes.starter.level;
          subsObj.value = subscriptionTypes.starter.value;
        }

        if (subs.product.name.toLowerCase().indexOf('business') >= 0) {
          // nextSubs = subscriptionTypes.enterprise;
          currSubs = subscriptionTypes.business;
          subsObj.level = subscriptionTypes.business.level;
          subsObj.value = subscriptionTypes.business.value;
        }

        if (subs.product.name.toLowerCase().indexOf('custom') >= 0
          || subs.product.name.toLowerCase().indexOf('enterprise') >= 0
          || subs.product.name.toLowerCase().indexOf('increment') >= 0) {
          // nextSubs = subscriptionTypes.enterprise;
          currSubs = subscriptionTypes.enterprise;
          subsObj.level = subscriptionTypes.enterprise.level;
          subsObj.value = subscriptionTypes.enterprise.value;

          if (!subs.invoicesLimit || subs.invoicesLimit <= 0) {
            subsObj.invLimit = 0;
          }

          setCustomSubscription(subsObj);
        } else if (response.data.data.length >= 2) {
          const customSub = response.data.data[1];
          const salesCustom = {
            level: subscriptionTypes.enterprise.level,
            value: subscriptionTypes.enterprise.value,
            label: 'Enterprise',
            name: 'Enterprise Plan',
            invoices: customSub.invoicesLimit,
            users: customSub.usersLimit,
            price: customSub.currPrice,
            ecLimit: customSub.ecLimit,
            ecPrice: customSub.ecPrice,
          };
          setCustomSubscription(salesCustom);
        }

        if (!subs.ecLimit) {
          subsObj.ecLimit = currSubs.ecLimit;
        }
        if (!subs.ecPrice) {
          subsObj.ecPrice = currSubs.ecPrice;
        }

        setSelectedSubscription(subsObj);
        setSubscription(subsObj);
      } else {
        setSubsError(appendContactSupport(window.config.support_email, t('SUBSCRIPTION_RETRIEVE_DETAILS_FAILURE'), t));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSubsError(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_RETRIEVE_DETAILS_FAILURE', t),
        t
      ));
    }
  };

  useEffect(() => {
    getSubscription();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shrinkSm) {
      setIndicatorMaxWidth(120);
      setExIndicatorMaxWidth(40);
      return;
    }

    if (shrink) {
      setIndicatorMaxWidth(220);
      setExIndicatorMaxWidth(60);
      return;
    }

    setIndicatorMaxWidth(360);
    setExIndicatorMaxWidth(100);
  }, [shrink, shrinkSm]);

  const getIndicatorColor = (used, limit) => {
    const usage = used / limit;

    if (usage < 0.5) {
      return classes.progressIndicator0;
    }

    if (usage < 0.8) {
      return classes.progressIndicator50;
    }

    if (usage < 1.0) {
      return classes.progressIndicator80;
    }

    if (usage >= 1.0) {
      return classes.progressIndicator100;
    }

    return classes.progressIndicator0;
  };

  const handleCancelSubscription = async () => {
    try {
      const body = {
        action: 'cancel',
        toSubscription: 'free',
        reason: cancelReason,
        immediately: cancelImmediately
      };

      const response = await Axios.put(
        API.updateSubscription,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('CANCEL_SUBSCRIPTION_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setModalOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'CANCEL_SUBSCRIPTION_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const sendContactForm = async () => {
    if (!contactEmail) {
      setContactEmailErr(t('PROCYS_VALIDATE_EMAIL_EMPTY'));
      return;
    }

    if (!contactPhone) {
      setContactPhoneErr(t('SUBSCRIPTION_PHONE_REQUIRED'));
      return;
    }

    try {
      const body = {
        email: contactEmail,
        phone: contactPhone,
        company: contactCompanyName,
        firstname: contactFirstname,
        details: contactDetails
      };

      const response = await Axios.post(
        API.subscriptionUpgradeContact,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('SUBSCRIPTION_SUCCESS_TO_SEND'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setContactModalOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_FAILED_TO_SEND', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onChangeSlider = (value) => {
    switch (value) {
      case subscriptionTypesAr[0].value:
        setSelectedSubscription(subscriptionTypes.free);
        break;
      case subscriptionTypesAr[1].value:
        setSelectedSubscription(subscriptionTypes.starter);
        break;
      case subscriptionTypesAr[2].value:
        setSelectedSubscription(subscriptionTypes.business);
        break;
      case subscriptionTypesAr[3].value:
        setSelectedSubscription(customSubscription || subscriptionTypes.enterprise);
        break;

      default:
        break;
    }
  };

  const handleChangeContactFormFields = (value, prop, setter, errSetter) => {
    if (!value) {
      errSetter(t('INTEGRATIONS_PROP_CANNOT_BE_EMPTY', { prop }));
    } else {
      errSetter('');
    }

    setter(value);
  };

  const getBulletStyle = (index) => {
    if (selectedSubscription.level < index) {
      return {
        textDecoration: 'line-through',
        color: '#9E9E9E',
        maxWidth: 300
      };
    }

    return {
      maxWidth: 300
    };
  };

  const cancelSubscriptionChange = async () => {
    setLoading(true);
    try {
      const response = await Axios.post(
        API.cancelSubscriptionChange,
        { id: subscription && subscription.id ? subscription.id.toString() : '' },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getSubscription();
        enqueueSnackbar(
          t('SUBSCRIPTION_CANCEL_CHANGE_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_CANCEL_CHANGE_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const canChangePlan = (selectedSub, curSub) => {
    if (selectedSub?.level > curSub?.level || !curSub.level) {
      return isActionPermitted(permissions.subscriptionsUpgrade, user.permissions);
    } if (selectedSub?.level < curSub?.level) {
      return isActionPermitted(permissions.subscriptionsDowngrade, user.permissions);
    }
    return false;
  };

  const getTooltip = (selectedSub, curSub) => {
    if ((selectedSub?.level > curSub?.level || !curSub.level) && !isActionPermitted(permissions.subscriptionsUpgrade, user.permissions)) {
      return t('SUBSCRIPTION_UPGRADE_PERMISSION');
    } if (selectedSub?.level < curSub?.level && !isActionPermitted(permissions.subscriptionsDowngrade, user.permissions)) {
      return t('SUBSCRIPTION_DOWNGRADE_PERMISSION');
    }
    return '';
  };

  const handleChangePlanClick = () => {
    sendFeedback(API.feedbackLogs, 'change plan clicked', user.email, user.companyID);
    history.push('/account/subscription/checkout?type=0', { selectedSubscription });
  };

  const cancelModal = (
    <Modal
      open={openModal}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={() => setModalOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          className={classes.modalBody}
        >
          <Typography
            variant="h2"
            className={classes.cancelSubsHeader}
          >
            {ready && t('UNSUBSCRIBE_HEADER')}
          </Typography>
          <Typography className={classes.msg}>
            {ready && t('UNSUBSCRIBE_MSG_ONE') + moment(subscription.endDate).format('MMM DD, YYYY')}
          </Typography>
          <Typography className={classes.secondaryMsg}>
            {ready && t('UNSUBSCRIBE_MSG_TWO')}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="filled"
            label={ready && t('CANCEL_SUBSCRIPTION_FEEDBACK_TEXTFIELD')}
            InputProps={{
              style: {
                paddingTop: 30,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
              },
              classes: {
                input: classes.noPadding
              }
            }}
            onChange={(e) => setCancelReason(e.target.value)}
          />
          <FormControlLabel
            label={ready && t('UNSUBSCRIBE_CHECKBOX')}
            className={classes.unsubscribeCheckbox}
            control={<Checkbox value={cancelImmediately} onChange={(e) => setCancelImmediately(e.target.checked)} />}
          />
          <Grid container spacing={2}>
            <Grid item={4}>
              <Button
                className={classes.cancelSubs}
                variant="contained"
                color="secondary"
                onClick={handleCancelSubscription}
              >
                {ready && t('CANCEL_SUBSCRIPTION_BUTTON')}
              </Button>
            </Grid>
            <Grid item={4}>
              <Button
                variant="outlined"
                className={classes.backBtn}
                onClick={() => setModalOpen(false)}
              >
                {ready && t('SUBSCRIPTION_BACK_BUTTON')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );

  const contactModal = (
    <Modal
      open={openContactModal}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={() => setContactModalOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          className={classes.modalBody}
        >
          <Typography
            variant="h2"
            className={classes.cancelSubsHeader}
          >
            {t('MY_PLAN_CONTACT_US')}
          </Typography>
          <TextField
            className={classes.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_PHONE_NO')}
            name="contactPhone"
            onChange={(e) => handleChangeContactFormFields(e.target.value, t('SUBSCRIPTION_PHONE_NO'), setContactPhone, setContactPhoneErr)}
            type="text"
            value={contactPhone}
            variant="outlined"
            required
            error={Boolean(contactPhoneErr)}
            helperText={contactPhoneErr}
          />
          <TextField
            className={classes.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_EMAIL')}
            name="contactEmail"
            onChange={(e) => handleChangeContactFormFields(e.target.value, t('SUBSCRIPTION_EMAIL'), setContactEmail, setContactEmailErr)}
            type="text"
            value={contactEmail}
            variant="outlined"
            required
            error={Boolean(contactEmailErr)}
            helperText={contactEmailErr}
          />
          <TextField
            className={classes.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_COMPANY_NAME')}
            name="contactCompanyName"
            onChange={(e) => setContactCompanyName(e.target.value)}
            type="text"
            value={contactCompanyName}
            variant="outlined"
          />
          <TextField
            className={classes.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_FIRST_NAME')}
            name="contactFirstname"
            onChange={(e) => setContactFirstname(e.target.value)}
            type="text"
            value={contactFirstname}
            variant="outlined"
          />
          <TextField
            className={classes.fieldStyle}
            fullWidth
            multiline
            rows={4}
            label={ready && t('SUBSCRIPTION_DETAILS')}
            name="contactDetails"
            onChange={(e) => setContactDetails(e.target.value)}
            type="text"
            value={contactDetails}
            variant="outlined"
          />
          <Grid className={classes.buttonContainer}>
            <Button
              className={classes.cancelSubs}
              variant="contained"
              color="secondary"
              onClick={sendContactForm}
            >
              {t('SUBSCRIPTION_SEND')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );

  const PlanDetails = () => (
    <>
      <Typography className={classes.subsTitle}>{user?.brand === 'schubec' ? subscription.name : t(subscription.i18n)}</Typography>
      <Typography className={classes.description}>
        {
          ready && (subscription.invLimit > 0
            ? t('MY_PLAN_PROCESSED_COUNT', {
              processed: subscription.invUsed > subscription.invLimit ? subscription.invLimit : subscription.invUsed,
              limit: subscription.invLimit - subscription.invPurchased
            })
            : t('MY_PLAN_PROCESSED_COUNT_CUSTOM', { processed: subscription.invUsed })
          )
        }
        {
          ready && subscription.invPurchased + subscription.ecCarried > 0
            && t('MY_PLAN_PROCESSED_COUNT_PURCHASED', {
              processed: subscription.invUsed - subscription.invLimit >= 0 ? subscription.invUsed - subscription.invLimit : 0,
              purchased: subscription.invPurchased + subscription.ecCarried
            })
        }
      </Typography>
      {defaultCard ? (
        <Grid className={classes.progressContainer}>
          <Tooltip title={t('MY_PLAN_LIMITS')}>
            <Grid
              className={subscription.invPurchased + subscription.ecCarried > 0 ? classes.progressBackground : classes.progressBackgroundRound}
              style={{ width: indicatorMaxWidth }}
            >
              <Grid
                className={clsx(
                  classes.progressIndicator,
                  subscription.invLimit > 0
                    ? getIndicatorColor(subscription.invUsed, subscription.invLimit)
                    : classes.progressIndicator0
                )}
                style={{
                  width: indicatorMaxWidth * (subscription.invUsed / subscription.invLimit),
                  maxWidth: indicatorMaxWidth
                }}
              />
            </Grid>
          </Tooltip>
          {
          subscription.invPurchased + subscription.ecCarried > 0 && (
            <Tooltip title={t('MY_PLAN_EXTRA_DOC_LIMITS')}>
              <Grid className={classes.extraProgressBackground} style={{ width: exIndicatorMaxWidth }}>
                {
                  subscription.invUsed - subscription.invLimit > 0 && (
                    <Grid
                      className={
                        clsx(
                          classes.extraProgressIndicator,
                          getIndicatorColor(
                            subscription.invUsed - subscription.invLimit >= 0
                              ? subscription.invUsed - subscription.invLimit : 0, subscription.invPurchased + subscription.ecCarried
                          )
                        )
                      }
                      style={{
                        width:
                          exIndicatorMaxWidth
                          * ((subscription.invUsed - subscription.invLimit >= 0 ? subscription.invUsed - subscription.invLimit : 0)
                          / (subscription.invPurchased + subscription.ecCarried)),
                        maxWidth: exIndicatorMaxWidth
                      }}
                    />
                  )
                }
              </Grid>
            </Tooltip>
          )
        }
          <Typography className={classes.progressText}>
            {
            subscription.invLimit > 0
              ? `${subscription.invUsed > subscription.invLimit
                ? subscription.invLimit : subscription.invUsed}/${subscription.invLimit - subscription.invPurchased} ${ready && t('MY_PLAN_DOCS')}`
              : `${subscription.invUsed} ${ready && t('MY_PLAN_DOCS')}`
          }
            <br />
            {
            subscription.invPurchased > 0
              && `${subscription.invUsed - subscription.invLimit >= 0
                ? subscription.invUsed - subscription.invLimit : 0}/${subscription.invPurchased + subscription.ecCarried} ${t('MY_PLAN_EXTRA_DOCS')}`
          }
          </Typography>
        </Grid>
      ) : (
        <Grid className={classes.noCardAddedContainer}>
          <SvgIcon fontSize="13">
            <PriorityHigh className={classes.priorityIcon} />
          </SvgIcon>
          <Typography className={classes.noCardText}>
            {ready && t('MY_PLAN_NO_CARD_ADDED', { brand: BRAND_NAME })}
          </Typography>
        </Grid>
      )}
      <Typography className={classes.description}>
        {ready && t('MY_PLAN_RENEW_AT')}
        {' '}
        <strong>{moment(subscription.endDate).format('DD MMM, YYYY')}</strong>
        .
      </Typography>
      {
        subscription.totalInvoices > 0 && (
          <Typography
            className={classes.description}
            style={{ maxWidth: 760 }}
          >
            <Trans i18nKey="MY_PLAN_SAVED_TIME">
              You&apos;ve processed
              <strong>{{ docs: getMaskedText(subscription.totalInvoices.toString(), 3) }}</strong>
              documents with
              {{ brand: BRAND_NAME }}
              for the entire period, thereby saving
              <strong>{{ minutes: getMaskedText((Math.round((subscription.totalInvoices * 2.5 * 100)) / 100).toString(), 3) }}</strong>
              minutes of manual work. Thank you for using us!
            </Trans>
          </Typography>
        )
      }
      {
        subscription.invLimit > 0 && subscription.invUsed >= subscription.invLimit && (
          <>
            <Typography className={classes.warningText}>
              <span className={classes.warningSymbol}>!</span>
              {ready && t('MY_PLAN_LIMIT_REACHED')}
            </Typography>
            <Typography className={classes.description}>
              {
                ready && (
                  <>
                    {
                      subscription.name.toLowerCase().indexOf('free') >= 0
                        && t('MY_PLAN_PLEASE_UPGRADE')
                    }
                    {
                      subscription.name.toLowerCase().indexOf('starter') >= 0
                        && t('MY_PLAN_PLEASE_UPGRADE_STARTER')
                    }
                    {
                      subscription.name.toLowerCase().indexOf('business') >= 0
                        && t('MY_PLAN_PLEASE_UPGRADE_BUSINESS')
                    }
                    {
                      (subscription.name.toLowerCase().indexOf('custom') >= 0 || subscription.name.toLowerCase().indexOf('enterprise') >= 0)
                        && t('MY_PLAN_PLEASE_UPGRADE_CUSTOM', { limit: subscription.ecLimit, price: subscription.ecPrice })
                    }
                  </>
                )
              }
            </Typography>
          </>
        )
      }
    </>
  );

  const ManagePlanSection = () => (
    <>
      <Typography className={classes.descTitle}>{ready && t('MY_PLAN_MANAGE_TIME')}</Typography>
      <Typography className={classes.description}>
        {subscription.invLimit > 0
          ? (
            <Trans i18nKey="MY_PLAN_CURRENT_PLAN_LIMITS">
              Now you have
              <span className={classes.planName}>{{ subscription: subscription.name }}</span>
              with the limit of
              {{ limit: subscription.invLimit }}
              documents and
              {{ userLimit: subscription.usersLimit }}
              users.
              Increase your limit by choosing new plan.
            </Trans>
          )
          : (
            <Trans i18nKey="MY_PLAN_CURRENT_PLAN_LIMITS_CUSTOM">
              Now you have
              <span className={classes.planName}>{{ subscription: subscription.name }}</span>
              with the limit of
              {{ userLimit: subscription.usersLimit }}
              users.
              Increase your limit by contacting us.
            </Trans>
          )}
      </Typography>
      <Grid className={classes.sliderContainer}>
        <Grid className={classes.sliderLabelsContainer}>
          <Grid className={classes.sliderLimitContainer}>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('free') >= 0 ? classes.selectedLimitText : classes.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_DOCS', { docs: subscriptionTypes.free.invoices })}
            </Typography>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('free') >= 0 ? classes.selectedLimitText : classes.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_USERS', { users: subscriptionTypes.free.users })}
            </Typography>
          </Grid>
          <Grid className={classes.sliderLimitContainerS}>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('starter') >= 0 ? classes.selectedLimitText : classes.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_DOCS', { docs: subscriptionTypes.starter.invoices })}
            </Typography>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('starter') >= 0 ? classes.selectedLimitText : classes.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_USERS', { users: subscriptionTypes.starter.users })}
            </Typography>
          </Grid>
          <Grid className={classes.sliderLimitContainerB}>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('business') >= 0 ? classes.selectedLimitText : classes.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_DOCS', { docs: subscriptionTypes.business.invoices })}
            </Typography>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('business') >= 0 ? classes.selectedLimitText : classes.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_USERS', { users: subscriptionTypes.business.users })}
            </Typography>
          </Grid>
          <Grid className={classes.sliderLimitContainerC}>
            <Typography
              align="center"
              className={
                (selectedSubscription.name.toLowerCase().indexOf('custom') >= 0 || selectedSubscription.name.toLowerCase().indexOf('enterprise') >= 0)
                  ? classes.selectedLimitText : classes.limitText
              }
            >
              {ready && t('MY_PLAN_NO_OF_DOCS', { docs: subscriptionTypes.enterprise.invoices })}
            </Typography>
            <Typography
              align="center"
              className={
                (selectedSubscription.name.toLowerCase().indexOf('custom') >= 0 || selectedSubscription.name.toLowerCase().indexOf('enterprise') >= 0)
                  ? classes.selectedLimitText : classes.limitText
              }
            >
              {ready && t('MY_PLAN_NO_OF_USERS', { users: subscriptionTypes.enterprise.users })}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.sliderComponent}>
          <Slider
            aria-label="Restricted values"
            value={selectedSubscription.value}
            valueLabelDisplay="off"
            marks={subscriptionTypesAr}
            step={null}
            onChange={(e, v) => onChangeSlider(v)}
            classes={{
              rail: classes.sliderRail,
              track: classes.sliderTrack,
              thumb: classes.sliderThumb,
              mark: classes.sliderMark
            }}
          />
        </Grid>
        <Grid className={classes.sliderLabelsContainer}>
          <Grid className={classes.sliderLimitContainer}>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('free') >= 0 ? classes.selectedLabelText : classes.labelText}
            >
              {ready && t(subscriptionLabel[subscriptionTypes.free.label])}
            </Typography>
          </Grid>
          <Grid className={classes.sliderLimitContainerS}>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('starter') >= 0 ? classes.selectedLabelText : classes.labelText}
            >
              {ready && t(subscriptionLabel[subscriptionTypes.starter.label])}
            </Typography>
          </Grid>
          <Grid className={classes.sliderLimitContainerB}>
            <Typography
              align="center"
              className={selectedSubscription.name.toLowerCase().indexOf('business') >= 0 ? classes.selectedLabelText : classes.labelText}
            >
              {ready && t(subscriptionLabel[subscriptionTypes.business.label])}
            </Typography>
          </Grid>
          <Grid className={classes.sliderLimitContainerC}>
            <Typography
              align="center"
              className={
                (selectedSubscription.name.toLowerCase().indexOf('custom') >= 0 || selectedSubscription.name.toLowerCase().indexOf('enterprise') >= 0)
                  ? classes.selectedLabelText : classes.labelText
              }
            >
              {ready && t(subscriptionLabel[subscriptionTypes.enterprise.label])}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.description}>
        <Trans i18nKey="MY_PLAN_INCLUDES">
          <span className={classes.planName}>{{ plan: selectedSubscription.name }}</span>
          also includes:
        </Trans>
      </Typography>
      <Grid className={classes.description}>
        <ul className={classes.planFunctions}>
          <li style={getBulletStyle(1)}>
            {ready && t('MY_PLAN_FUNC_01')}
          </li>
          <li style={getBulletStyle(1)}>
            {ready && t('MY_PLAN_FUNC_02')}
          </li>
          <li style={getBulletStyle(2)}>
            {ready && t('MY_PLAN_FUNC_03')}
          </li>
          <li style={getBulletStyle(3)}>
            {ready && t('MY_PLAN_FUNC_04')}
          </li>
        </ul>
      </Grid>
      {
        (selectedSubscription.level === 1 || selectedSubscription.level === 2
          || (selectedSubscription.level === 3 && customSubscription && customSubscription.price > 0)) && (
          <>
            <Typography className={classes.usageValue}>
              &euro;
              {` ${getMaskedCurrencyText(selectedSubscription.price.toString())}`}
              <span className={classes.usageDescription}>
                {ready && t('MY_PLAN_PER_MONTH')}
              </span>
            </Typography>
            <Typography className={classes.vatMessage}>
              {ready && t('MY_PLAN_VAT_NOT_INCLUDED')}
            </Typography>
          </>
        )
      }
    </>
  );

  const SubscriptionActions = () => (
    <Grid className={classes.actionContainer}>
      {
        selectedSubscription.name.toLowerCase().indexOf('custom') >= 0
        || selectedSubscription.name.toLowerCase().indexOf('enterprise') >= 0
        || user.customisations.includes('hideManagePlanSection')
          ? (
            <Tooltip title={
                !isActionPermitted(permissions.subscriptionsUpgrade, user.permissions)
                  ? t('SUBSCRIPTION_UPGRADE_PERMISSION')
                  : ''
                }
            >
              <span>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.changeBtn}
                  onClick={() => setContactModalOpen(true)}
                  disabled={!isActionPermitted(permissions.subscriptionsUpgrade, user.permissions)}
                >
                  {ready && t('MY_PLAN_CONTACT_US')}
                </Button>
              </span>
            </Tooltip>
          )
          : (
            <Tooltip title={getTooltip(selectedSubscription, subscription)}>
              <span>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ textTransform: 'none' }}
                  className={subscription.name.toLowerCase().indexOf('free') < 0 ? classes.changeBtn : ''}
                  disabled={selectedSubscription.name.toLowerCase().indexOf(subscription.name.toLowerCase()) >= 0
                    || !canChangePlan(selectedSubscription, subscription)}
                  onClick={
                    selectedSubscription.name.toLowerCase().indexOf(subscription.name.toLowerCase()) >= 0
                      ? () => {}
                      : () => handleChangePlanClick()
                  }
                >
                  {
                    selectedSubscription.name.toLowerCase().indexOf(subscription.name.toLowerCase()) >= 0
                      ? ready && t('MY_PLAN_CURRENT_PLAN')
                      : ready && t('MY_PLAN_CHANGE_PLAN')
                  }
                </Button>
              </span>
            </Tooltip>
          )
      }
      {
        (subscription.name.toLowerCase().indexOf('free') < 0 && subscription.name.toLowerCase().indexOf('trial') < 0) && (
          <Tooltip title={isActionPermitted(permissions.subscriptionsCancel, user.permissions) ? ''
            : t('SUBSCRIPTION_CANCEL_PERMISSION')}
          >
            <span>
              <Button
                className={classes.cancelBtn}
                onClick={() => setModalOpen(true)}
                disabled={!isActionPermitted(permissions.subscriptionsCancel, user.permissions)}
              >
                {ready && t('MY_PLAN_CANCEL_PLAN')}
              </Button>
            </span>
          </Tooltip>
        )
      }
    </Grid>
  );

  const BuyExtraDocs = () => (
    subscription.name.toLowerCase().indexOf('free') < 0 && subscription?.ecLimit > 0
    && !user.customisations.includes('hideManagePlanSection')
    && (
      <>
        <Typography className={classes.extraDescription}>
          <Trans i18nKey="MY_PLAN_EXTRA_PACKAGE">
            You can buy additional packages of
            <strong>{{ limit: subscription.ecLimit }}</strong>
            documents for
            <strong>
              &euro;
              {{ price: getMaskedCurrencyText(subscription.ecPrice) }}
            </strong>
            .
          </Trans>
        </Typography>
        <Tooltip title={isActionPermitted(permissions.subscriptionsBuyExtra, user.permissions) ? ''
          : t('SUBSCRIPTION_BUY_EXTRA_PERMISSION')}
        >
          <span>
            <Button
              className={classes.buyExtraBtn}
              onClick={() => history.push('/account/subscription/checkout?type=1')}
              disabled={!isActionPermitted(permissions.subscriptionsBuyExtra, user.permissions)}
            >
              {ready && t('MY_PLAN_EXTRA_PACKAGE_BTN', { limit: subscription.ecLimit })}
            </Button>
          </span>
        </Tooltip>
      </>
    )
  );

  if (loading) {
    return <LoadingScreenText />;
  }

  if ((user.isSubCompany || user.isPartnerSubCompany) && user.parent) {
    return <SubCompanyAccessModal openModal parentName={user.parentName} />;
  }

  if (subsError) {
    return <EmptyText str={subsError} />;
  }

  return (
    <Page
      className={clsx(classes.root, !isChild ? classes.parentPadding : '')}
      title={ready && t('MY_PLAN_PAGE_TITLE')}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {
            subscription.toDowngrade && (
              <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.cancelContainer}
              >
                <Alert
                  severity="warning"
                  classes={{
                    message: classes.warningBanner
                  }}
                  className={classes.warningBanner}
                >
                  <span>
                    {ready && t('MY_PLAN_CHANGE_TO')}
                    <strong>{` ${ready && t(getSubscriptionTitle(subscription.downgradeSubscription))} `}</strong>
                    {ready && t('MY_PLAN_CHANGE_ON', { date: moment(subscription.endDate).format('DD MMM, YYYY') })}
                  </span>
                  <Button className={classes.cancelBtn} onClick={cancelSubscriptionChange}>
                    {ready && t('MY_PLAN_CHANGE_CANCEL')}
                  </Button>
                </Alert>
              </Grid>
            )
          }
          <Card className={classes.planContainer}>
            {ready && t('MY_PLAN_TITLE')}
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                {PlanDetails()}
                {user.brand !== 'schubec' && !user.customisations.includes('hideManagePlanSection') && <ManagePlanSection />}
                <SubscriptionActions />
                <BuyExtraDocs />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.planContainer}>
          <Card>
            <Billing header="PLAN_CARDS_HEADER_CARDS" pageLimit={5} parentPadding subscription={subscription} />
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.planContainer}>
          <Card>
            <Invoices header="PLAN_INVOICES_HEADER_INVOICES" pageLimit={5} parentPadding />
          </Card>
        </Grid>
      </Grid>
      {cancelModal}
      {contactModal}
    </Page>
  );
}

MyPlan.propTypes = {
  isChild: PropTypes.bool
};

export default MyPlan;
