import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop, Grid, Modal, Typography, IconButton, Button, Box, CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import PdfView from 'src/Invoices/components/PdfView/PdfView';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const DuplicateMenu = (props) => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const {
    duplicateMenuOpen, handleClose, invoice, handleProcessDocument,
  } = props;

  const [loading, setLoading] = useState(false);
  const [fileResponse, setFileResponse] = useState(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const [imgName, setImgName] = useState('');

  const closeDuplicateMenu = () => {
    handleClose();
  };

  const getImage = async (name) => {
    if (!name) {
      return;
    }
    setLoading(true);
    setFetchingFile(true);
    try {
      const response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
      setFetchingFile(false);
      setLoading(false);
    } catch (err) {
      setFileResponse(null);
      setFetchingFile(false);
      setLoading(false);
    }
  };

  const cleanUp = () => {
    setFileResponse(null);
    setImgName('');
  };

  useEffect(() => {
    const nameParts = invoice?.documentFile?.split('.');
    let imageName = '';
    if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
      imageName = `${nameParts[0]}-display.pdf`;
      setImgName(`${nameParts[0]}-display.pdf`);
    } else {
      imageName = invoice.documentFile;
      setImgName(invoice.documentFile);
    }

    getImage(imageName);

    return cleanUp;
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUnmarkDuplicate = async () => {
    handleProcessDocument(invoice, 'proceed');
    closeDuplicateMenu();
  };

  const renderInvoicePDF = () => (
    <>
      <Box hidden={!isMobile} style={{ height: '100%' }}>
        <PdfView
          t={t}
          ready={ready}
          fileResponse={fileResponse}
          fetchingFile={fetchingFile}
          imgName={imgName}
          canOpenInNewTab
        />

      </Box>
      <Box hidden={isMobile} style={{ height: '100%' }}>
        <iframe src={fileResponse} title="pdf" className={classes.pdfFrame} />
      </Box>
    </>
  );

  const duplicateRequestButton = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        disabled={loading}
        onClick={() => handleUnmarkDuplicate(invoice)}
      >
        {t('INVOICE_DUPLICATE_NO_DUPLICATE')}
      </Button>
      <Button
        variant="outlined"
        className={classes.deleteDuplicateBtn}
        disabled={loading}
        onClick={() => handleProcessDocument(invoice, 'delete')}
      >
        {t('INVOICE_DUPLICATE_DELETE')}
      </Button>
    </Box>
  );

  return (
    <>
      <Modal
        className={classes.modal}
        open={duplicateMenuOpen}
        onClose={() => closeDuplicateMenu()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <Grid container className={classes.modalHeader}>
            <Grid item className={classes.modalHeaderLeft}>
              <Typography className={classes.headerTitle}>
                {ready && t('DUPLICATE_MENU_TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={1} md={8} className={classes.modalHeaderRight}>
              <IconButton onClick={() => closeDuplicateMenu()}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container className={classes.modalContainer}>
            <Grid item xs={12} md={4} className={classes.leftContainer}>
              <Grid className={classes.optionsContainer}>
                <Grid className={classes.lineRow}>
                  <Grid className={classes.documentNameContainer}>
                    <Typography className={classes.documentNameLabel}>{t('DUPLICATE_MENU_DOCUMENT_NAME')}</Typography>
                    <Typography className={classes.documentName}>{invoice.fileName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.modalLeftFooter}>
                {duplicateRequestButton()}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className={classes.invoiceContainer}>
              {renderInvoicePDF()}
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

DuplicateMenu.propTypes = {
  duplicateMenuOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleProcessDocument: PropTypes.func,
  invoice: PropTypes.object,
};

export default DuplicateMenu;
