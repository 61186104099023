import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect, useSelector } from 'react-redux';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box, Grid, Typography
} from '@material-ui/core';
import { Container, Draggable } from 'react-smooth-dnd';
import { useTranslation } from 'react-i18next';

import LabelStatus from 'src/Shared/components/LabelStatus/LabelStatus';
import InvoiceJiraItem from 'src/Invoices/components/InvoiceJiraItem/InvoicejiraItem';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/Shared/utils/helpers';
import { isDropAllowed, isStatusChangeAble } from 'src/Shared/utils/invoiceStateChangeValidations';
import {
  invoiceStatusCode, invoiceStatusesKeys
} from 'src/config';
import * as actions from 'src/Dashboard/store/actions/dashboard';
import ConfigContext from 'src/Contexts';
import useStyles from '../style';

const InvoicesJira = ({
  paginatedInvoices, onGetAppInvoiceStats, getInvoices, filters, query, time, page, jiraLimit, total, startTime, endTime
}) => {
  const { API } = useContext(ConfigContext);
  const dashboard = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { ready, t } = useTranslation();

  const isToValidateStatusAllowed = user.customisations.includes('toValidateStatus');
  const [toreview, setToreview] = useState([]);
  const [reviewed, setReviewed] = useState([]);
  const [tovalidate, setTovalidate] = useState([]);
  const [toexport, setToexport] = useState([]);
  const [exported, setExported] = useState([]);

  const [dragDisabled, setDragDisabled] = useState(false);

  useEffect(() => {
    const torAr = [];
    const rvdAr = [];
    const tovAr = [];
    const toeAr = [];
    const expAr = [];

    paginatedInvoices.forEach((i) => {
      if (i.status === invoiceStatusCode.toreview || i.status === invoiceStatusCode.digitized) {
        torAr.push(i);
      }
      if (i.status === invoiceStatusCode.reviewed) {
        rvdAr.push(i);
      }
      if (i.status === invoiceStatusCode.tovalidate) {
        tovAr.push(i);
      }
      if (i.status === invoiceStatusCode.toexport) {
        toeAr.push(i);
      }
      if (i.status === invoiceStatusCode.exported) {
        expAr.push(i);
      }
    });

    setToreview(torAr);
    setReviewed(rvdAr);
    setTovalidate(tovAr);
    setToexport(toeAr);
    setExported(expAr);
  }, [paginatedInvoices]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDropItem = async (ar, res, setter, code) => {
    const { removedIndex, addedIndex, payload } = res;
    if (removedIndex === null && addedIndex === null) {
      setter(ar);
      return;
    }

    // TODO - TEMP fix - Find a way to disable the drop column when drag started
    const err = isDropAllowed(payload?.status, code);
    if (err) {
      getInvoices();
      onGetAppInvoiceStats();
      enqueueSnackbar(appendContactSupport(window.config.support_email, t(err), t), {
        variant: 'error',
        persist: true
      });
      return;
    }

    const result = Array.from(ar);
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]; // eslint-disable-line
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    setter(result);

    if (code === payload?.status) {
      return;
    }

    if (addedIndex != null && addedIndex >= 0) {
      setDragDisabled(true);
      try {
        const body = {
          assistantID: payload.assistantID,
          toStatus: code.toString()
        };

        const response = await Axios.post(
          API.documentStatus,
          body,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          // Fetch invoices list and stats.
          getInvoices();
          onGetAppInvoiceStats();
          // enqueueSnackbar(t('INVOICE_STATUS_CHANGE_SUCCESS'), {
          //   variant: 'success',
          //   persist: true
          // });
        }
        setDragDisabled(false);
      } catch (error) {
        setDragDisabled(false);
        getInvoices();
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_STATUS_CHANGE_FAILED', t),
          t
        ),
        {
          variant: 'error',
          persist: true
        });
      }
    }
  };

  const getPayload = (ar, i) => ar[i];

  const renderJiraColumn = (status, statusText, ar, arSetter, code) => (
    <Grid item xs={12} sm={6} md={3} className={classes.jiraColumn}>
      <Grid className={classes.columnHeader}>
        <LabelStatus
          className={classes.statusElementSelected}
          color={status}
        >
          <Typography className={classes.statusText}>
            {ready && t(statusText)}
          </Typography>
        </LabelStatus>
        <Typography className={classes.statItem}>
          {ar.length}
        </Typography>
      </Grid>
      <Grid className={clsx(
        classes.columnBody,
        `${status}-wrapper`,
        'hide-scroll',
        `${!isStatusChangeAble(code, user.userRole) ? 'dd-overlay-disabled' : 'dd-overlay-enabled'}`
      )}
      >
        <Container
          groupName={isStatusChangeAble(code, user.userRole) ? 'dragAndDrop' : `oops-${status}`}
          behaviour={dragDisabled || !isStatusChangeAble(code, user.userRole) ? 'contain' : 'move'}
          onDrop={(e) => onDropItem(ar, e, arSetter, code)}
          getChildPayload={(i) => getPayload(ar, i)}
          dropPlaceholder={{
            animationDuration: 500,
            showOnTop: true,
            className: 'drop-preview'
          }}
        >
          {
            ar && ar.length > 0 && ar.map((i, index) => (
              <Draggable key={i.assistantID}>
                <InvoiceJiraItem
                  invoice={i}
                  filters={filters}
                  query={query}
                  time={time}
                  page={page}
                  jiraLimit={jiraLimit}
                  index={index}
                  total={total}
                  startTime={startTime}
                  endTime={endTime}
                />
              </Draggable>
            ))
          }
        </Container>
      </Grid>
    </Grid>
  );

  return (
    <Box minWidth="100%">
      <Grid container spacing={3} className={classes.jiraContainer}>
        {renderJiraColumn(
          invoiceStatusesKeys.toreview, 'DASHBOARD_INVOICES_JIRA_REVIEW',
          toreview, setToreview, invoiceStatusCode.toreview, dashboard.overviewStats.toreviewTot
        )}
        {!isToValidateStatusAllowed && renderJiraColumn(
          invoiceStatusesKeys.reviewed, 'DASHBOARD_INVOICES_JIRA_REVIEWED',
          reviewed, setReviewed, invoiceStatusCode.reviewed, dashboard.overviewStats.reviewedTot
        )}
        {isToValidateStatusAllowed && renderJiraColumn(
          invoiceStatusesKeys.tovalidate, 'DASHBOARD_INVOICES_JIRA_VALIDATE',
          tovalidate, setTovalidate, invoiceStatusCode.tovalidate, dashboard.overviewStats.tovalidateTot
        )}
        {renderJiraColumn(
          invoiceStatusesKeys.toexport, 'DASHBOARD_INVOICES_JIRA_TO_EXPORT',
          toexport, setToexport, invoiceStatusCode.toexport, dashboard.overviewStats.toexportTot
        )}
        {renderJiraColumn(
          invoiceStatusesKeys.exported, 'DASHBOARD_INVOICES_JIRA_EXPORTED',
          exported, setExported, invoiceStatusCode.exported, dashboard.overviewStats.exportedTot
        )}
      </Grid>
    </Box>
  );
};

InvoicesJira.propTypes = {
  paginatedInvoices: PropTypes.array,
  onGetAppInvoiceStats: PropTypes.func,
  getInvoices: PropTypes.func,
  filters: PropTypes.shape({
    recent: PropTypes.number
  }),
  query: PropTypes.string,
  time: PropTypes.string,
  page: PropTypes.number,
  jiraLimit: PropTypes.number,
  total: PropTypes.number,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onGetAppInvoiceStats: (payload) => dispatch(actions.getInvoiceAppStatsByUser(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesJira);
