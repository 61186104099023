import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Security from 'src/Auth/components/Security/Security';
import GeneralSettings from 'src/Auth/components/GeneralSettings/GeneralSettings';
import useStyles from './style';
import UserPreference from '../UserPreference/UserPreference';

function General({ className, ...rest }) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={4}
      {...rest}
    >
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
      >
        <GeneralSettings user={user} />
        <UserPreference />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
      >
        <Security />
      </Grid>
    </Grid>
  );
}

General.propTypes = {
  className: PropTypes.string
};

export default General;
