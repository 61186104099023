import React, { Suspense } from 'react';
import {
  Switch, Redirect, Route
} from 'react-router-dom';
import DashboardLayout from 'src/Shared/layouts/DashboardLayout/DashboardLayout';
import LoadingScreen from 'src/Shared/components/LoadingScreen/LoadingScreen';
import AuthRoute from 'src/Auth/components/AuthRoute/AuthRoute';

// const VerifyLogin = lazy(() => import('src/Auth/containers/VerifyLogin/VerifyLogin'));
// const Dashboard = lazy(() => import('src/Dashboard/containers/Dashboard/Dashboard'));
// const StatsDashboard = lazy(() => import('src/Dashboard/containers/StatsDashboard/StatsDashboard'));
// const InvoiceImage404 = lazy(() => import('src/Dashboard/containers/InvoiceImageNotFound/InvoiceImageNotFound'));

// const InvoiceImage = lazy(() => import('src/Invoices/containers/InvoiceDisplay/InvoiceDisplay'));
// const Attachment = lazy(() => import('src/Invoices/containers/DocumentDisplay/DocumentDisplay'));

// const Account = lazy(() => import('src/Auth/containers/Account/Account'));
// const UserCreate = lazy(() => import('src/User/containers/UserCreate/UserCreate'));
// const UserInvite = lazy(() => import('src/User/containers/InviteUserCallback/InviteUserCallback'));
// const CustomerInvoice = lazy(() => import('src/User/containers/CustomerInvoice/CustomerInvoice'));
// const MyPlan = lazy(() => import('src/User/containers/MyPlan/MyPlan'));
// const SubscriptionCheckout = lazy(() => import('src/User/containers/Checkout/Checkout'));

// const InvoiceList = lazy(() => import('src/Invoices/containers/InvoiceList/InvoiceList'));
// const LogsList = lazy(() => import('src/Invoices/containers/LogsList/LogsList'));
// const InvoiceLogView = lazy(() => import('src/Invoices/containers/InvoiceLog/InvoiceLog'));
// const InvoiceEditXML = lazy(() => import('src/Invoices/containers/InvoiceEditXML/InvoiceEditXML'));
// const InvoiceEditXMLAI = lazy(() => import('src/Invoices/containers/InvoiceEditXMLAI/InvoiceEditXMLAI'));
// const CDCDocumentEdit = lazy(() => import('src/Invoices/containers/CDCDocumentEdit/CDCDocumentEdit'));
// const ExportFileDownload = lazy(() => import('src/Invoices/containers/DownloadExportFile/DownloadExportFile'));

// const Suppliers = lazy(() => import('src/Suppliers/containers/SuppliersList/SuppliersList'));

// const CostCenters = lazy(() => import('src/Dimensions/containers/ListCostCenters/ListCostCenters'));
// const Projects = lazy(() => import('src/Dimensions/containers/ListProjects/ListProjects'));
// const EditProject = lazy(() => import('src/Dimensions/containers/EditProject/EditProject'));
// const VatCodes = lazy(() => import('src/Dimensions/containers/ListVatCodes/ListVatCodes'));
// const GLAccounts = lazy(() => import('src/Dimensions/containers/ListGLAccounts/ListGLAccounts'));
// const EditGLAYears = lazy(() => import('src/Dimensions/containers/EditGLAYears/EditGLAYears'));
// const EditGLAPeriods = lazy(() => import('src/Dimensions/containers/EditGLAPeriods/EditGLAPeriods'));
// const DimensionLogsCodeView = lazy(() => import('src/Dimensions/containers/LogList/DimensionLogsList'));
// const DimensionLogsCompanyView = lazy(() => import('src/Dimensions/containers/LogList/DimensionLogsList'));
// const SingleDimensionLogView = lazy(() => import('src/Dimensions/containers/DimensionLogView/DimensionLogView'));

// const DimensionSettings = lazy(() => import('src/Dimensions/containers/Settings/Settings'));

// const Integrations = lazy(() => import('src/Integrations/containers/Apps/Apps'));
// const QBOAuth2Callback = lazy(() => import('src/Integrations/containers/QuickbooksOAuth2Callback/QuickbooksOAuth2Callback'));

// const Error404 = lazy(() => import('src/Shared/components/Error404/Error404'));

import VerifyLogin from 'src/Auth/containers/VerifyLogin/VerifyLogin';
import Dashboard from 'src/Dashboard/containers/Dashboard/Dashboard';
import StatsDashboard from 'src/Dashboard/containers/StatsDashboard/StatsDashboard';
import InvoiceImage404 from 'src/Dashboard/containers/InvoiceImageNotFound/InvoiceImageNotFound';

import InvoiceImage from 'src/Invoices/containers/InvoiceDisplay/InvoiceDisplay';
import Attachment from 'src/Invoices/containers/DocumentDisplay/DocumentDisplay';

import Account from 'src/Auth/containers/Account/Account';
import UserCreate from 'src/User/containers/UserCreate/UserCreate';
import UserInvite from 'src/User/containers/InviteUserCallback/InviteUserCallback';
import CustomerInvoice from 'src/User/containers/CustomerInvoice/CustomerInvoice';
import MyPlan from 'src/User/containers/MyPlan/MyPlan';
import SubscriptionCheckout from 'src/User/containers/Checkout/Checkout';

import InvoiceList from 'src/Invoices/containers/InvoiceList/InvoiceList';
import LogsList from 'src/Invoices/containers/LogsList/LogsList';
import InvoiceLogView from 'src/Invoices/containers/InvoiceLog/InvoiceLog';
import InvoiceEditXML from 'src/Invoices/containers/InvoiceEditXML/InvoiceEditXML';
import CDCDocumentEdit from 'src/Invoices/containers/CDCDocumentEdit/CDCDocumentEdit';
import ExportFileDownload from 'src/Invoices/containers/DownloadExportFile/DownloadExportFile';

import Suppliers from 'src/Suppliers/containers/SuppliersList/SuppliersList';

import CostCenters from 'src/Dimensions/containers/ListCostCenters/ListCostCenters';
import Projects from 'src/Dimensions/containers/ListProjects/ListProjects';
import EditProject from 'src/Dimensions/containers/EditProject/EditProject';
import VatCodes from 'src/Dimensions/containers/ListVatCodes/ListVatCodes';
import GLAccounts from 'src/Dimensions/containers/ListGLAccounts/ListGLAccounts';
import EditGLAYears from 'src/Dimensions/containers/EditGLAYears/EditGLAYears';
import EditGLAPeriods from 'src/Dimensions/containers/EditGLAPeriods/EditGLAPeriods';
import DimensionLogsCodeView from 'src/Dimensions/containers/LogList/DimensionLogsList';
// import DimensionLogsCompanyView from 'src/Dimensions/containers/LogList/DimensionLogsList';
import SingleDimensionLogView from 'src/Dimensions/containers/DimensionLogView/DimensionLogView';

import DimensionSettings from 'src/Dimensions/containers/Settings/Settings';

import Integrations from 'src/Integrations/containers/Apps/Apps';
import QBOAuth2Callback from 'src/Integrations/containers/QuickbooksOAuth2Callback/QuickbooksOAuth2Callback';

import Error404 from 'src/Shared/components/Error404/Error404';

function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route exact path="/404" component={Error404} />
        <Route exact path="/404/invoice/image" component={InvoiceImage404} />
        <Route exact path="/login" component={VerifyLogin} />
        <Route exact path="/statistics/dashboard" component={StatsDashboard} />
        <Route exact path="/account/users/invite/:token" component={UserInvite} />
        <Route exact path="/integrations/quickbooks/oauth2/callback" component={QBOAuth2Callback} />

        {/* Secure URL for accessing invoice files and attachments */}
        <Route exact path="/files/image/:id" component={InvoiceImage} />
        <Route exact path="/files/transaction/:transaction/" component={InvoiceImage} />
        <Route exact path="/files/company/:company/transaction/:transaction/" component={InvoiceImage} />
        <Route exact path="/files/attachment/:name" component={Attachment} />
        <Route exact path="/files/export/:filename" component={ExportFileDownload} />
        {/* Secure URL for accessing invoice files */}

        <AuthRoute
          path="/"
          render={(props) => (
            <DashboardLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Redirect exact from="/" to="/dashboard" />
                  <Route exact path="/dashboard" component={Dashboard} />

                  <Route exact path="/account" component={Account} />
                  <Route exact path="/account/users" component={UserCreate} />
                  <Route exact path="/account/invoices/:id" component={CustomerInvoice} />
                  <Route exact path="/account/subscription" component={MyPlan} />
                  <Route exact path="/account/subscription/checkout" component={SubscriptionCheckout} />

                  <Route exact path="/invoices" component={InvoiceList} />
                  <Route exact path="/invoices/logs" component={LogsList} />
                  <Route exact path="/invoices/:id/logs/" component={LogsList} />
                  <Route exact path="/invoices/logs/:id" component={InvoiceLogView} />
                  <Route exact path="/invoices/:id/edit" component={InvoiceEditXML} />

                  <Route exact path="/documents" component={InvoiceList} />
                  <Route exact path="/documents/logs" component={LogsList} />
                  <Route exact path="/documents/:id/logs/" component={LogsList} />
                  <Route exact path="/documents/logs/:id" component={InvoiceLogView} />
                  <Route exact path="/documents/:id/edit" component={InvoiceEditXML} />
                  <Route exact path="/documents/cdc/:id/edit" component={CDCDocumentEdit} />

                  <Route exact path="/dimensions/suppliers" component={Suppliers} />
                  {/* <Route exact path="/suppliers/logs/code/:code" component={SupplierLogView} /> */}
                  {/* <Route exact path="/suppliers/logs/code/:code/:id" component={SingleSupLogCompView} /> */}
                  {/* <Route exact path="/suppliers/logs/company/:company" component={SupplierLogCompanyView} /> */}
                  {/* <Route exact path="/suppliers/logs/company/:company/:id" component={SingleSupLogView} /> */}

                  <Route exact path="/dimensions/costcenters" component={CostCenters} />
                  <Route exact path="/dimensions/projects" component={Projects} />
                  <Route exact path="/dimensions/projects/:code" component={EditProject} />
                  <Route exact path="/dimensions/vatcodes" component={VatCodes} />
                  <Route exact path="/dimensions/glaccounts" component={GLAccounts} />
                  <Route exact path="/dimensions/glaccounts/bas" component={GLAccounts} />
                  <Route exact path="/dimensions/glaccounts/pnl" component={GLAccounts} />
                  <Route exact path="/dimensions/logs/:dim/:id" component={SingleDimensionLogView} />
                  <Route exact path="/dimensions/logs/:dim/:type?/code/:code" component={DimensionLogsCodeView} />
                  <Route exact path="/dimensions/logs/:dim/:type?/company/:company" component={DimensionLogsCodeView} />

                  <Route exact path="/dimensions/settings" component={DimensionSettings} />
                  <Route exact path="/dimensions/settings/gla/years" component={EditGLAYears} />
                  <Route exact path="/dimensions/settings/gla/periods" component={EditGLAPeriods} />

                  <Route exact path="/integrations" component={Integrations} />
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            </DashboardLayout>
          )}
        />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

export default Routes;
