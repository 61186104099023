/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box, Card, Link, Table, TableBody, TableCell, TableHead, TablePagination, TableRow,
  Grid, Avatar
} from '@material-ui/core';

import {
  dateFilterOptions, userTypeFilterOptions
} from 'src/config';
import SimpleSelect from 'src/Shared/components/SimpleSelect/SimpleSelect';
import { applyPagination } from 'src/Shared/utils/helpers';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

function Results({ className, invoices, ...rest }) {
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [dateFilter, setDateFilter] = useState(0);
  const [filters, setFilters] = useState({
    type: null,
    status: null
  });

  const handleTypeChange = (event) => {
    event.persist();
    let value = null;
    if (event.target.value !== 'All') {
      value = event.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      type: value
    }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const paginatedInvoices = applyPagination(invoices, page, limit);

  const filterOptions = (
    <Grid className={classes.filterContainer}>
      <SimpleSelect
        label="Date:"
        filter={dateFilter}
        filterOptions={dateFilterOptions}
        setFilter={(e) => setDateFilter(e.target.value)}
      />
      <SimpleSelect
        label="Users:"
        filter={filters.type || 'All'}
        filterOptions={userTypeFilterOptions}
        setFilter={handleTypeChange}
      />
    </Grid>
  );

  return (
    <Grid>
      {filterOptions}
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth="100%">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell}>
                    {ready && t('DASHBOARD_OVERVIEW_ERRORED')}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {ready && t('INVOICE_STATUS_UPLOADING')}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {ready && t('INVOICE_STATUS_DIGITIZED')}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInvoices.map((invoice) => {
                  const isInvoiceSelected = invoices.includes(invoice.id);
                  return (
                    <TableRow
                      hover
                      key={`${invoice.date}${invoice.user.firstname || 'Anonymous User'}${invoice.user.lastname || ''}`}
                      className={classes.pointer}
                      selected={isInvoiceSelected}
                      onClick={() => {}}
                    >
                      <TableCell className={classes.headerCell}>
                        {invoice && invoice.stats && invoice.stats.error ? invoice.stats.error : 0}
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        {/* {
                          invoice && invoice.stats && invoice.stats.inProgress && invoice.stats.uploaded
                            ? invoice.stats.inProgress + invoice.stats.uploaded
                            : invoice && invoice.stats && invoice.stats.inProgress && !invoice.stats.uploaded
                              ? invoice.stats.inProgress
                              : invoice && invoice.stats && !invoice.stats.inProgress && invoice.stats.uploaded
                                ? invoice.stats.uploaded
                                : 0
                        } */}
                        {invoice && invoice.stats && invoice.stats.inProgress ? invoice.stats.inProgress : 0}
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        {invoice && invoice.stats && invoice.stats.processed ? invoice.stats.processed : 0}
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          {
                            invoice && invoice.user && invoice.user.avatar
                              ? (
                                <Avatar
                                  alt="user"
                                  className={classes.userImage}
                                  src={invoice.user.avatar}
                                />
                              )
                              : <Avatar className={classes.userImage} />
                          }
                          <Box ml={2}>
                            <Link
                              variant="subtitle2"
                              color="textPrimary"
                              component={RouterLink}
                              underline="none"
                              to="#"
                              className={classes.userCell}
                            >
                              {
                                invoice && invoice.user
                                  && `${invoice.user.firstname || 'Anonymous User'} ${invoice.user.lastname || ''}`
                              }
                            </Link>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.timeCell}>
                        {moment(new Date(invoice.date)).format('MMM DD, YYYY')}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          classes={{
            caption: classes.paginationCaption,
            selectRoot: classes.paginationCaption,
            menuItem: classes.paginationCaption,
          }}
          count={invoices.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </Grid>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  invoices: PropTypes.array
};

Results.defaultProps = {
  invoices: []
};

export default Results;
