/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Box, IconButton, Typography, SvgIcon, Table, TableBody,
  TableCell, TableHead, TableRow, Grid, Avatar,
  Hidden, Menu, MenuItem, Tooltip, Card, TablePagination
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';

import {
  permissions, isActionPermitted, getDocumentType
} from 'src/Shared/utils/helpers';
import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import useStyles from './style';

function CompaniesTable({
  className, rest, loading, menuCompanyID, menuAnchorEl, handleOpenMenu, handleCloseMenu, companies, onEditCompany,
  onSwitchToCompany, onDeleteCompany, maxWidth, total, page, limit, maxWidthRows, handlePageChange, handleLimitChange
}) {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth="100%">
          <Table size="small">
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell className={classes.headerStyle} align="left">
                  {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
                </TableCell>
                <Hidden only={['xs', 'sm']}>
                  <TableCell className={classes.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  <TableCell className={classes.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_CONNECTED_TO')}
                  </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                  <TableCell className={classes.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_DOC_TYPE')}
                  </TableCell>
                </Hidden>
                <TableCell className={classes.headerStyle} align="left">
                  {ready && t('ACCOUNT_SETTINGS_COMPANIES_INVOICE_LIMIT')}
                </TableCell>
                <Hidden only={['xs', 'sm', 'md']}>
                  <TableCell className={classes.headerStyle} align="left">
                    {ready && t('ACCOUNT_SETTINGS_COMPANIES_OWNER')}
                  </TableCell>
                </Hidden>
                <TableCell className={classes.headerStyle} />
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((c) => (
                <TableRow
                  hover
                  key={c.id}
                  className={classes.pointer}
                >
                  <TableCell className={classes.cellStyle} align="left">
                    {c.name}
                  </TableCell>
                  <Hidden only={['xs', 'sm']}>
                    <TableCell className={classes.cellStyle} align="left">
                      <Grid className={classes.centerCell}>
                        <Hidden only={['xs']}>
                          <Grid className={clsx(classes[c.isSubCompany ? 'sub' : 'main'], classes.statusLabel)}>
                            {c.isSubCompany ? 'subsidiary' : 'main'}
                          </Grid>
                        </Hidden>
                        <Hidden smUp>
                          <Grid className={clsx(classes[`${c.isSubCompany ? 'sub' : 'main'}Dot`], classes.statusDot)} />
                        </Hidden>
                      </Grid>
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'sm', 'md']}>
                    <TableCell className={classes.cellStyle} align="left">
                      {c.parentName}
                    </TableCell>
                  </Hidden>
                  <Hidden only={['xs', 'sm', 'md']}>
                    <TableCell className={classes.cellStyle} align="left">
                      {t(getDocumentType(c.documentType))}
                    </TableCell>
                  </Hidden>
                  <TableCell className={classes.cellStyle} align="left">
                    {c.invoicesLimit ? `${c.invoicesUsed || 0}/${c.invoicesLimit || 0}` : c.invoicesUsed || '-'}
                  </TableCell>
                  <Hidden only={['xs', 'sm', 'md']}>
                    <TableCell className={classes.cellStyle} align="left">
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Avatar
                          alt="user"
                          className={classes.userImage}
                          src={c.avatar}
                        />
                        <Box ml={2} mr={2} display="flex" flexDirection="column">
                          <Typography
                            color="inherit"
                          // component={RouterLink}
                            to="#"
                            variant="h6"
                            className={classes.cellStyle}
                          >
                            {`${c.firstname} ${c.lastname}`}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </Hidden>
                  <TableCell width={56} align="center">
                    <IconButton
                      onClick={(e) => handleOpenMenu(e, c.id)}
                    >
                      <SvgIcon style={{ display: 'flex' }}>
                        <MoreVertIcon className={classes.moreIcon} />
                      </SvgIcon>
                    </IconButton>
                    <Menu
                      id="user-actions"
                      anchorEl={menuAnchorEl}
                      keepMounted
                      open={Boolean(menuAnchorEl) && menuCompanyID === c.id}
                      onClose={handleCloseMenu}
                    >
                      {
                        <Tooltip
                          title={isActionPermitted(permissions.companyManage, user.permissions)
                            ? ''
                            : t('ACCOUNT_SETTINGS_COMPANIES_EDIT_PERMISSION')}
                        >
                          <span>
                            <MenuItem onClick={(e) => onEditCompany(e, c)} disabled={!isActionPermitted(permissions.companyManage, user.permissions)}>
                              {ready && t('ACCOUNT_SETTINGS_COMPANIES_EDIT_COMPANY')}
                            </MenuItem>
                          </span>
                        </Tooltip>
                      }
                      {
                        c?.companyID?.toString() !== user?.companyID && c?.isMember && (
                        <MenuItem onClick={(e) => onSwitchToCompany(e, c)}>
                          {ready && t('ACCOUNT_SETTINGS_COMPANIES_SWITCH_TO_COMPANY')}
                        </MenuItem>
                        )
                      }
                      {
                        c?.companyID?.toString() !== user?.companyID && c?.canManageCompany && c?.isSubCompany && (
                          <MenuItem onClick={(e) => onDeleteCompany(e, c)}>
                            {ready && t('ACCOUNT_SETTINGS_COMPANIES_DELETE_BTN')}
                          </MenuItem>
                        )
                      }
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <div className={classes.caption}>
        {
          !maxWidth && `${total} compan${total !== 1 ? 'ies' : 'y'} available`
        }
        <TablePagination
          component="div"
          classes={{
            root: classes.paginationComp,
            toolbar: classes.paginationToolbar,
            caption: classes.paginationCaption,
            selectRoot: classes.paginationCaption,
            select: classes.paginationMenu,
            menuItem: classes.paginationMenu,
            actions: classes.paginationActions,
          }}
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
        />
      </div>
    </Card>
  );
}

CompaniesTable.propTypes = {
  className: PropTypes.string,
  rest: PropTypes.object,
  loading: PropTypes.bool,
  menuCompanyID: PropTypes.object,
  menuAnchorEl: PropTypes.object,
  handleOpenMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  onEditCompany: PropTypes.func,
  onSwitchToCompany: PropTypes.func,
  onDeleteCompany: PropTypes.func,
  companies: PropTypes.array,
  maxWidth: PropTypes.bool,
  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  maxWidthRows: PropTypes.bool,
  handlePageChange: PropTypes.func,
  handleLimitChange: PropTypes.func
};

export default CompaniesTable;
