const useStyles = () => ({
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
  },
  backdrop: {
    zIndex: 1111,
    color: '#ffffff',
  }
});

export default useStyles;
