/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as actionTypes from 'src/Auth/store/actions/actionTypes';

const initialState = {
  user: null,
  loading: false,
  error: null
};

const logoutComplete = (state) => (
  produce(state, (draft) => {
    draft.user = null;
    draft.loading = false;
    draft.error = null;
  })
);

const silentLogin = (state, action) => (
  produce(state, (draft) => {
    draft.user = action.user;
    draft.loading = false;
    draft.error = null;
  })
);

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT_COMPLETE: return logoutComplete(state, action);
    case actionTypes.SILENT_LOGIN: return silentLogin(state, action);

    default: {
      return state;
    }
  }
};

export default authReducer;
