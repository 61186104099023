import { makeStyles, alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  none: {},
  root: {
    border: '1px solid #DDDDDD',
    borderRadius: 4,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
  },
  primaryTextColor: {
    color: '#334D6E',
  },
  searchBar: {
    flex: 1
  },
  queryField: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    border: '0px solid rgba(194, 207, 224, 1)',
    borderRadius: 4,
    margin: '0px',
    width: '100%',
    minWidth: 120
  },
  supplierFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  supplierSearch: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    border: '0px solid rgba(194, 207, 224, 1)',
    borderRadius: 4,
    margin: '0px',
    padding: '8px',
    width: '100%',
    minWidth: 100
  },
  supplierFilterActions: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  supplierFilterText: {
    color: '#334D6E',
    fontSize: 12,
    marginRight: '10px',
  },
  supplierFilterActionText: {
    color: '#334D6E',
    fontSize: 12,
    marginRight: '10px',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  supplierFilterActionTextHidden: {
    color: '#334D6E',
    fontSize: 12,
    marginRight: '10px',
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'none'
  },
  supplierLabel: {
    color: '#334D6E',
    fontSize: '13px'
  },
  supplierLabelRoot: {
    marginRight: '0px',
  },
  supplierBtnContainer: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '8px'
  },
  statusField: {
    flexBasis: 200
  },
  breakLine: {
    border: '0px solid rgba(151, 151, 151, 0.25)',
    borderBottomWidth: 0
  },
  bulkOperations: {
    position: 'relative',
    minHeight: 46,
    boxShadow: 'inset 0px -1px 0px rgba(151, 151, 151, 0.25)',
    borderRadius: '4px 4px 0px 0px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  bulkActions: {
    paddingLeft: 16,
    paddingRight: 4,
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actionSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  sendToEmailButton: {
    width: 140,
    height: 36,
    backgroundColor: theme.palette.button.action,
    marginLeft: 10,
    textTransform: 'none'
  },
  ublButton: {
    width: 140,
    height: 36,
    marginLeft: 10,
    textTransform: 'none'
  },
  userImage: {
    flexShrink: 0,
    height: 36,
    width: 36,
    fontWeight: 500
  },
  bulkUBL: {
    marginLeft: 2,
    backgroundColor: 'white',
    padding: '3px 4px',
    fontSize: 13,
    fontWeight: 400,
    color: '#C2CFE0',
    border: 'none',
    boxShadow: 'none',
    minWidth: 30
  },
  bulkDelete: {
    color: '#F7685B',
    marginLeft: 8,
  },
  bulkArchieve: {
    color: '#4E342E',
    marginLeft: 8,
  },
  bulkEdit: {
    marginLeft: theme.spacing(2),
    color: '#C2CFE0'
  },
  filterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  circularProgressIcon: {
    width: 20,
    height: 20
  },
  arrowCell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  pointer: {
    height: 64,
    cursor: 'pointer',
    textDecoration: 'none'
  },
  checkboxRoot: {
    padding: '2px',
    marginRight: '6px'
  },
  checkbox: {
    color: '#D5D5D5'
  },
  checkboxIcon: {
    width: 16,
    height: 16,
    border: '2px solid #D5D5D5',
    borderRadius: 3,
  },
  arrow: {
    paddingLeft: 4
  },
  paginationCaption: {
    fontSize: 13,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerMargin: {
    marginBottom: 12
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: 12
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  headerSpace: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  statusContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    marginRight: 24
  },
  statusElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 12px',
    opacity: '0.5',
    border: '1px solid #E1E4E8',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    marginTop: 4,
    marginBottom: 4,
    marginRight: 12
  },
  statusElementSelected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 12px',
    border: '1px solid #E1E4E8',
    boxSizing: 'border-box',
    borderRadius: 20,
    height: 29,
    cursor: 'pointer',
    marginTop: 4,
    marginBottom: 4,
    marginRight: 12,
    maxWidth: 160
  },
  statusElementContainsFailedToExport: {
    border: '1px solid #F7685B !important',
  },
  statusText: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    whiteSpace: 'nowrap'
  },
  statusTextSelected: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    whiteSpace: 'nowrap'
  },
  exportFailed: {
    backgroundColor: theme.palette.statusbg.errored,
    color: '#fff',
    fontSize: 9,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 5px 1px',
    position: 'relative',
    fontWeight: 500,
    height: 13,
  },
  exportFailedRow: {
    backgroundColor: theme.palette.statusbg.errored,
    color: '#fff',
    fontSize: 9,
    borderRadius: 20,
    marginRight: 8,
    padding: '0px 5px 1px',
    position: 'relative',
    fontWeight: 500,
    height: 13,
  },
  digitized: {
    color: theme.palette.status.digitized,
    backgroundColor: alpha(theme.palette.statusbg.digitized, 0.3)
  },
  toreview: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  reviewed: {
    color: theme.palette.status.toreview,
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3)
  },
  tovalidate: {
    color: theme.palette.status.tovalidate,
    backgroundColor: alpha(theme.palette.statusbg.tovalidate, 0.3)
  },
  toexport: {
    color: theme.palette.status.toexport,
    backgroundColor: alpha(theme.palette.statusbg.toexport, 0.3)
  },
  exported: {
    color: theme.palette.status.exported,
    backgroundColor: alpha(theme.palette.statusbg.exported, 0.3)
  },
  rejected: {
    color: theme.palette.status.rejected,
    backgroundColor: alpha(theme.palette.statusbg.rejected, 0.3)
  },
  archived: {
    color: theme.palette.status.archived,
    backgroundColor: alpha(theme.palette.statusbg.archived, 0.3)
  },
  errored: {
    color: theme.palette.status.errored,
    backgroundColor: alpha(theme.palette.statusbg.errored, 0.3)
  },
  possibleDuplicate: {
    color: theme.palette.status.possibleDuplicate,
    backgroundColor: alpha(theme.palette.statusbg.possibleDuplicate, 0.3)
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    minWidth: 166,
    height: 42,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    textTransform: 'none',
    padding: '6px 8px'
  },
  loadingAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    width: 108,
    height: 42,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    textTransform: 'none',
    marginLeft: theme.spacing(2),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  searchInput: {
    padding: '4px !important',
    '&::placeholder': {
      color: '#90A0B7',
      fontSize: '13px',
      lineHeight: '21px'
    }
  },
  searchBarInput: {
    paddingLeft: '8px'
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    paddingRight: 16
  },
  viewsContainer: {
  },
  viewIcon: {
    color: '#C2CFE0'
  },
  viewIconSelected: {
    color: '#3E8EFF'
  },
  filterBtn: {
    fontSize: 13,
    color: '#90A0B7',
    textTransform: 'none'
  },
  filterBtnSelected: {
    fontSize: 13,
    color: '#3E8EFF',
    textTransform: 'none',
    borderRadius: 8,
    border: '1px solid #3E8EFF',
    padding: '4px 6px',
    marginRight: '6px'
  },
  todayText: {
    color: '#323C47',
    fontWeight: 600
  },
  bulkText: {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(51,77,110,0.5)',
    marginLeft: 15,
  },
  snackbarContainer: {
    maxWidth: '75%'
  },
  bulkUploadSuccessText: {
    fontSize: 14,
  },
  bulkUploadSuccessLink: {
    fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#FFF'
  },
  currencyText: {
    fontWeight: 500
  },
  usernameText: {
    fontSize: 13,
    lineHeight: '22.5px',
    fontWeight: 400,
    color: '#707683'
  },
  moreIcon: {
    height: 16,
    color: '#C2CFE0',
    marginLeft: theme.spacing(2)
  },
  checkCell: {
    alignItems: 'center'
  },
  jiraContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  jiraColumn: {
  },
  columnHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  columnBody: {
    padding: 20,
    maxHeight: 700,
    backgroundColor: 'rgba(194, 207, 224, 0.1)',
    borderRadius: 8,
    overflow: 'scroll'
  },
  statItem: {
    fontSize: 14,
    fontWeight: 600,
    color: 'rgba(129, 142, 155, 1)'
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  filterMenu: {
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 8%)',
    border: '1px solid #DDDDDD'
  },
  filterMenuRoot: {
    maxHeight: 300,
    overflow: 'scroll'
  },
  filterMenuItem: {
    fontSize: 13,
    color: '#192A3E',
    overflow: 'scroll',
    paddingTop: 0,
    paddingBottom: 0
  },
  selectedFilterMenuItem: {
    color: theme.palette.status.toreview
  },
  actionCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 64,
    justifyContent: 'flex-end',
  },
  logsIcon: {
    height: 16,
    color: '#C2CFE0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: 600,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  halfPaper: {
    width: '90%',
    maxWidth: 500,
    maxHeight: '96%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px'
  },
  deleteMessageText: {
    color: '#334D6E',
    paddingTop: 16
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    color: '#A0AEC1',
    textTransform: 'none',
    marginRight: 24
  },
  fieldContainer: {
    marginTop: 25
  },
  inputField: {
    marginTop: 10,
    color: '#334D6E',
  },
  groupBtn: {
    color: '#334D6E',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
  },
  btnGroup: {
    width: '100%',
    marginTop: 10,
  },
  cancelButton: {
    width: 108,
    height: 42,
    textTransform: 'none',
    color: '#334D6E'
  },
  cancelButtonSup: {
    width: '40%',
    height: 32,
    textTransform: 'none',
    color: '#334D6E'
  },
  validateButton: {
    width: 108,
    height: 42,
    textTransform: 'none',
    marginLeft: 10,
  },
  applyButtonSup: {
    width: '40%',
    height: 32,
    textTransform: 'none',
    marginLeft: 10,
  },
  userForm: {
    width: '100%'
  },
  headerName: {
    color: '#192A3E'
  },
  submitBtn: {
    marginRight: 8,
    marginLeft: 8
  },
  captionBody: {
    display: 'flex',
    flexDirection: 'column'
  },
  ulStyle: {
    marginLeft: 24,
    fontSize: 13,
    marginTop: 8,
    marginBottom: 8
  },
  fieldContainerFirst: {
    marginTop: 10
  },
  link: {
    textDecoration: 'underline',
    color: '#3E8AFF',
    cursor: 'pointer',
  },
  disabledLink: {
    textDecoration: 'underline',
    color: '#334D6E',
  },
  addUserDisabled: {
    marginBottom: 10,
    marginTop: 10,
  },
  addUserDisabledText: {
    fontSize: 11,
  },
  warningBanner: {
    width: '100%',
    paddingLeft: 20,
  },
  splitRequestContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  splitRequestText: {
    fontSize: 13,
    fontWeight: 500,
  },
  checkNSplitBtn: {
    height: 28,
    textTransform: 'none',
    fontSize: 12,
    minWidth: 132,
    marginLeft: 8,
  },
  dontSplitBtn: {
    height: 28,
    textTransform: 'none',
    marginLeft: 10,
    fontSize: 13,
    minWidth: 132,
    color: '#334D6E',
  },
  splitRequestHelp: {
    fontSize: 13,
    color: '#000',
    fontWeight: 500,
    textDecoration: 'underline',
  },
  kbIcon: {
    color: '#90A0B7'
  },
  formInputSelect: {
    maxWidth: '100%',
    color: '#334D6E',
    fontWeight: 600,
  },
  selectRoot: {
    padding: '8px !important',
    borderWidth: 0
  },
  selectIcon: {
    position: 'unset'
  },
  statusCell: {
    padding: '0px !important'
  },
  fileNameContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  deleteDuplicateBtn: {
    height: 28,
    textTransform: 'none',
    marginLeft: 10,
    fontSize: 13,
    minWidth: 132,
    color: '#FFFFFF',
    background: '#F7685B',
    '&:hover': {
      background: '#d94a3d',
    }
  },
  duplicateFlag: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E1E4E8',
    boxSizing: 'border-box',
    borderRadius: 40,
    height: 20,
    width: 20,
    margin: '0px 0px 0px 0px',
    backgroundColor: alpha(theme.palette.statusbg.tovalidate, 0.3),
    color: theme.palette.statusbg.tovalidate,
  },
  noPadding: {
    padding: '0px !important',
    width: 20
  },
  sortIconActive: {
    height: 16,
    color: '#3E8AFF'
  },
  sortIcon: {
    height: 16,
    color: '#334D6E'
  },
  sortableTableCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }
}));

export default useStyles;
