import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    backgroundColor: 'gray'
  },
  pdfFrame: {
    width: '100%',
    height: '100%',
    borderWidth: 0
  }
}));

export default useStyles;
