import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Backdrop, Button, Checkbox, FormControlLabel, Grid, IconButton, Modal, Paper, TextField, Tooltip, Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { appendContactSupport, axiosHeaders } from 'src/Shared/utils/helpers';
import {
  docunectaInvoiceFields, docunectaInvoiceLineFields
} from 'src/config';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const ManageFields = ({
  open, entity, fields, fetchFields, closeModal, parameterCompany
}) => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const [selectedFields, setSelectedFields] = useState([]);

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [fieldName, setFieldName] = useState('');
  const [details, setDetails] = useState('');
  const [fieldNameErr, setFieldNameErr] = useState('');
  const companyID = parameterCompany || user.companyID;

  const [isApplyToAll, setIsApplyToAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const entityCustomisations = {
    invoice: 'applyParentFieldsHeaders',
    'invoice-line': 'applyParentFieldsLines',
    'invoice-line-display': 'applyParentFieldsLinesDisplay',
    supplier: 'applyParentFieldsSupplier',
    gla: 'applyParentFieldsGLA',
    vat: 'applyParentFieldsVAT',
    cc: 'applyParentFieldsCC',
  };

  useEffect(() => {
    setSelectedFields(fields.filter((f) => f.isActive).map((f) => f.key));
  }, [fields]);

  useEffect(() => {
    setIsApplyToAll(user.customisations?.some((c) => c === entityCustomisations[entity]));
  }, [entity]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveFieldsSelection = async () => {
    try {
      // TEMP
      const data = [];
      for (let i = 0; i < selectedFields.length; i++) {
        data.push({ name: selectedFields[i] });
      }

      const response = await Axios.post(
        `${API.fields}/${entity}/${companyID}`,
        { fields: data },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        fetchFields();
        enqueueSnackbar(
          t('SAVE_FIELDS_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        closeModal(true);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('SAVE_FIELDS_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const sendFieldRequest = async () => {
    if (!fieldName) {
      setFieldNameErr('FIELD_REQUEST_FIELD_EMPTY');
      return;
    }

    try {
      const body = {
        field: fieldName,
        description: details
      };

      const response = await Axios.post(
        `${API.fieldsRequest}/${entity}/${companyID}`, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('REQUEST_FIELD_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setRequestModalOpen(false);
        closeModal();
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('REQUEST_FIELD_API_ERROR'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const handleChangeFields = (value, prop, setter, errSetter) => {
    if (!value) {
      errSetter(`${prop} can not be empty`);
    } else {
      errSetter('');
    }

    setter(value);
  };

  const changeSelected = (field, selected) => {
    if (selected) {
      if (selectedFields?.length > 0) {
        setSelectedFields([...selectedFields, field]);
      } else {
        setSelectedFields([field]);
      }
    } else {
      const newArray = selectedFields?.filter((f) => f !== field);
      setSelectedFields(newArray);
    }
  };

  const isFieldChecked = (field) => {
    if (user.isDocunecta) {
      if (entity === 'invoice') {
        return docunectaInvoiceFields.includes(field.key) || (selectedFields?.includes(field.key));
      }
      if (entity === 'invoice-line') {
        return docunectaInvoiceLineFields.includes(field.key) || (selectedFields?.includes(field.key));
      }
    }
    return field?.isMandatory || (selectedFields?.includes(field.key));
  };

  const isFieldDisabled = (field) => {
    if (user.isDocunecta) {
      if (entity === 'invoice') {
        return docunectaInvoiceFields.includes(field.key);
      }
      if (entity === 'invoice-line') {
        return docunectaInvoiceLineFields.includes(field.key);
      }
    }
    return field?.isMandatory;
  };

  const onChange = (e, field) => {
    if (user.isDocunecta) {
      if ((entity === 'invoice' && docunectaInvoiceFields.includes(field.key))
        || (entity === 'invoice-line' && docunectaInvoiceLineFields.includes(field.key))) {
        return;
      }
    } else if (field?.isMandatory) {
      return;
    }
    changeSelected(field.key, e.target.checked);
  };

  const getTooltipTitle = (field) => {
    if (user.isDocunecta) {
      if ((entity === 'invoice' && docunectaInvoiceFields.includes(field.key))
        || (entity === 'invoice-line' && docunectaInvoiceLineFields.includes(field.key))) {
        return `${t(field.label)} ${t('MANAGE_FIELDS_CANNOT_REMOVE_TOOLTIP')}`;
      }
    } else if (field?.isMandatory) {
      return `${t(field.label)} ${t('MANAGE_FIELDS_CANNOT_REMOVE_TOOLTIP')}`;
    }
    return '';
  };

  const onChangeApplyToAll = async (e) => {
    e.persist();
    const { checked } = e.target;
    setLoading(true);
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        { customisation: entityCustomisations[entity], enabled: checked },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        fetchFields();
        enqueueSnackbar(
          t(checked ? 'MANAGE_FIELDS_APPLY_TO_ALL_SUCCESS' : 'MANAGE_FIELDS_UNAPPLY_TO_ALL_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 3000
          }
        );
        setLoading(false);
        setIsApplyToAll(checked);
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('MANAGE_FIELDS_APPLY_TO_ALL_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || error.response.data.message);
      }

      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errorMessage, t),
        {
          variant: 'error',
          autoHideDuration: 3000
        }
      );
      setLoading(false);
    }
  };

  const renderRequestField = () => (
    <Modal
      open={requestModalOpen}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paperModal}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={() => setRequestModalOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          className={classes.modalBody}
        >
          <Typography
            variant="h2"
            className={classes.header}
          >
            {ready && t('REQUEST_FIELD')}
          </Typography>
          <Typography className={classes.description}>
            {ready && t('REQUEST_FIELD_DESCRIPTION')}
          </Typography>
          <TextField
            className={classes.fieldStyle}
            fullWidth
            label={ready && t('REQUEST_FIELD_TITLE')}
            name="fieldName"
            onChange={(e) => handleChangeFields(e.target.value, 'Title', setFieldName, setFieldNameErr)}
            type="text"
            value={fieldName}
            variant="outlined"
            required
            error={Boolean(fieldNameErr)}
            helperText={t(fieldNameErr)}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="filled"
            label={ready && t('REQUEST_FIELD_DETAILS')}
            placeholder={ready && t('REQUEST_FIELD_DETAILS')}
            InputProps={{
              style: {
                paddingTop: 30,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
              },
              classes: {
                input: classes.noPadding
              }
            }}
            onChange={(e) => setDetails(e.target.value)}
          />
          <Grid className={classes.reqButtonContainer}>
            <Button
              className={classes.sendBtn}
              variant="contained"
              color="secondary"
              onClick={sendFieldRequest}
            >
              {ready && t('REQUEST_FIELD_SEND')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );

  return (
    <Modal
      open={open}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paperModal}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={() => closeModal()}
          >
            <Close fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          className={classes.modalBody}
        >
          <Typography
            variant="h2"
            className={classes.header}
          >
            {ready && t('MANAGE_FIELDS')}
          </Typography>
          <Grid className={classes.fieldsListContainer}>
            {
              fields.map((field) => {
                if (!field.isAvailable) {
                  return null;
                }

                return (
                  <Tooltip title={getTooltipTitle(field)} key={field.key}>
                    <FormControlLabel
                      key={field.key}
                      value="top"
                      control={(
                        <Checkbox
                          className={classes.checkBox}
                          onChange={(e) => onChange(e, field)}
                          checked={isFieldChecked(field)}
                          disabled={isFieldDisabled(field)}
                        />
                      )}
                      label={ready && t(field.label)}
                      labelPlacement="end"
                      classes={{
                        root: classes.checkboxContainer,
                        label: classes.checkBoxLabel
                      }}
                    />
                  </Tooltip>
                );
              })
            }
          </Grid>
          {!user.isSubCompany && (
            <Grid className={classes.applyToAllContainer}>
              <FormControlLabel
                key="apply-to-all"
                value="top"
                control={(
                  <Checkbox
                    className={classes.checkBox}
                    onChange={(e) => onChangeApplyToAll(e)}
                    checked={isApplyToAll}
                    disabled={loading}
                  />
                )}
                label={ready && t('MANAGE_FIELDS_APPLY_TO_ALL')}
                labelPlacement="start"
                classes={{
                  root: classes.checkboxContainer,
                  label: classes.checkBoxLabel
                }}
              />
            </Grid>
          )}
          <Grid className={classes.buttonContainer}>
            <Button
              className={classes.requestBtn}
              variant="outlined"
              onClick={() => setRequestModalOpen(true)}
            >
              {ready && t('REQUEST_FIELD')}
            </Button>
            <Button
              className={classes.sendBtn}
              variant="contained"
              color="secondary"
              onClick={saveFieldsSelection}
            >
              {ready && t('MANAGE_FIELD_SAVE')}
            </Button>
          </Grid>
        </Grid>
        {renderRequestField()}
      </Paper>
    </Modal>
  );
};

ManageFields.defaultProps = {
  open: false,
  fetchFields: () => {}
};

ManageFields.propTypes = {
  entity: PropTypes.oneOf('invoice', 'purchase-order', 'invoice-type', 'supplier', 'gla', 'vat', 'cc'),
  fields: PropTypes.array,
  open: PropTypes.bool,
  fetchFields: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  parameterCompany: PropTypes.string
};

export default ManageFields;
