import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  },
  cardContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    padding: theme.spacing(2),
    width: '80%',
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#334D6E',
    lineHeight: '36px',
    marginBottom: theme.spacing(2),
  },
  descriptionLink: {
    fontSize: 14,
    fontWeight: 400,
    color: '#3E8AFF',
    textDecoration: 'underline',
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#334D6E',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0px 24px'
  },
  backToProcysBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
    margin: '6px 10px 6px 0px'
  },
  saveBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    margin: '6px 0px 6px 12px'
  },
}));

export default useStyles;
